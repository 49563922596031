.boat-maintenance-checklists-view-form {
  @include d-flex-space-between;
  align-items: center;
  gap: 0 1.6rem;
  margin-bottom: 2.4rem;

  .boat-maintenance-checklists-search {
    margin-bottom: 0;
  }

  @include tablet {
    flex-direction: column;
    align-items: flex-start;
    gap: 2.4rem 0;
  }
}

.boat-maintenance-checklists-view-form-search {
  width: 100%;
  max-width: 32.9rem;

  @include tablet {
    max-width: unset;
  }
}

.boat-maintenance-checklists-view-form-filters {
  @include d-align-items-center;
  flex-shrink: 0;
  gap: 0 1.6rem;

  &__title {
    font-size: $base-font-size;
    font-weight: 500;
    color: $seaz-text;
  }

  app-chips-checkbox-select {
    @include d-align-items-center;
  }

  app-chips-checkbox-item {
    @include indent-not-last-of-child(0 0.8rem 0 0);
  }

  .chips-item {
    @include d-align-items-center;
    border: 1px solid $seaz-head-arrow;
    border-radius: 4rem;
    background-color: $light-color;
    padding: 1rem;
    cursor: pointer;
    transition: box-shadow 0.3s;

    &:hover {
      box-shadow: 0 2px 2px rgb(43 28 54 / 16%);
    }

    app-seazone-icon {
      margin-right: 1rem;
    }

    &.selected {
      box-shadow: 0 0 0 2px #432791;
      -moz-box-shadow: 0 0 0 2px #432791;
      border-color: initial;
    }
  }

  @include tablet {
    flex-direction: column;
    align-items: flex-start;
    flex-shrink: initial;
    gap: 0.4rem 0;
  }
}
