.branch-supplier {
  padding: 0 2.4rem;

  &__header {
    @include d-flex-space-between;
    align-items: center;
    margin: 4.8rem 0;
  }

  margin-bottom: 7rem;

  &__list {
    display: flex;
    flex-direction: column;
  }

  @include tablet {
    &__header {
      flex-direction: column;
      align-items: flex-start;
      margin: 3rem 0;
    }

    .supplier-card__footer {
      flex-direction: column;
      align-items: flex-start;
    }
  }

  .search-card__about-person-info {
    display: flex;
    margin-top: 1.2rem;

    .supplier-card__about-person-services .search-card__location {
      margin: 0 2.8rem 0 0;
    }
  }

  @include tablet {
    .search-card__about-person-info {
      display: flex;
      flex-direction: column;

      .supplier-card__about-person-services .search-card__location {
        margin: 1.2rem 0 0 0;
      }

      .supplier-card__contact-name {
        justify-content: flex-start;
      }
    }
  }

  @include mobile {
    .supplier-card__footer,
    .group-action-buttons-container {
      align-items: center;
    }
  }
}

.branch-supplier-count {
  display: flex;
  align-items: center;
  font-size: 2rem;
  font-weight: 500;
  color: $seaz-text;

  &__value {
    display: block;
    min-width: 2.4rem;
    height: 2.6rem;
    box-sizing: border-box;
    font-size: 1.2rem;
    text-align: center;
    line-height: 2.6rem;
    color: $clr-primary-and-accent;
    padding: 0 0.6rem;
    margin-left: 0.8rem;
    background-color: $seaz-head-arrow;
    border-radius: 50%;
  }
}

.branch-supplier-header-action {
  display: flex;
  align-items: center;

  &__btn {
    &:not(:last-of-type) {
      margin-right: 1.2rem;
    }
  }

  &__btn-icon {
    @include d-flex-center-content;
  }

  @include tablet {
    margin-top: 1.6rem;
  }

  @include mobile {
    flex-direction: column;
    width: 100%;

    &__btn {
      width: 100%;

      &:not(:last-of-type) {
        margin: 0 0 1.2rem;
      }
    }
  }
}

.search-card-branch-actions {
  display: flex;
  flex-direction: row;
  &__btn {
    &:not(:last-of-type) {
      margin-right: 1.2rem;
    }
  }

  .change-status {
    display: flex;
    flex-direction: row-reverse;
    justify-content: center;
    align-items: center;

    .form-field__label {
      margin-left: 0.8rem;
      &.checked {
        color: $seaz-alarm;
      }

      @include mobile {
        width: 4rem;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: clip;
      }
    }
    .form__toggle-input:checked + label:before {
      border-color: $seaz-alarm;
      background-color: $seaz-alarm;
    }

    .form__toggle-input:checked + label:after {
      border-color: $seaz-alarm;
    }
  }

  .supplier-status {
    &__wrapper {
      display: flex;
      width: 100%;
      justify-content: center;
      align-items: center;
      color: $clr-light-grey;
      margin-right: 1.2rem;
    }
  }

  @include tablet {
    margin-top: 1.2rem;
  }
}
