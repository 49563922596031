.overview-statistic {
  display: flex;
  box-sizing: border-box;
  &__wrapper {
    position: relative;
    padding-top: 1.2rem;
    .overview-statistic__scrollable .controls-container {
      top: -2rem;
    }
  }
  &__scrollable {
    overflow: auto;
    max-width: 100%;
  }

  &__title {
    font-size: 2.4rem;
    font-weight: bold;
    line-height: 2.4rem;
    color: $clr-primary-and-accent;

    @include tablet {
      margin-bottom: 2rem;
    }
  }

  &__header {
    border-bottom: 1px solid #e0e0e0;
    padding-bottom: 2.4rem;
    margin-bottom: 3rem;
    min-height: 5.6rem;

    &--title {
      font-size: 2.4rem;
      font-weight: 500;
      line-height: 2.4rem;
      color: $clr-primary-and-accent;
    }

    &--sub-title {
      color: $seaz-document-count;
      margin-top: 0.2rem;
    }
  }

  &__col {
    border: 0.5px solid #e7e7eb;
    box-sizing: border-box;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.04);
    border-radius: 1.6rem;
  }

  &__total {
    width: 100%;
    max-width: 28.8rem;
    margin-right: 1.6rem;
    padding: 2rem 0 0;
    background-color: #fff;
  }

  &__container {
    width: 100%;
    padding: 2.4rem;
    background-color: #fff;
    @media screen and (max-width: 1300px) {
      width: auto;
      flex-grow: 1;
    }
  }

  &__card-wrapper {
    position: relative;
    padding: 0.5rem 0 0;
  }
  &__card-box {
    display: flex;
    align-self: baseline;
    max-width: 89rem;
    padding-bottom: 1rem;
    @media screen and (max-width: 1300px) {
      max-width: 100%;
    }
  }
  &__card-scrollable {
    @media screen and (min-width: 1301px) {
      overflow: auto;
      max-width: 100%;
    }
  }

  &__card-box-item {
    width: 100%;
    max-width: 44.8rem;
    margin: 0 auto;
    &:not(:last-of-type) {
      margin-right: 2rem;
    }
  }

  &__no_data {
    width: 100%;
    padding: 0 2.4rem;
    display: flex;
    justify-content: center;
    flex-direction: column;
  }
}

.seaz-charter-utc__tooltip.all-inclusive {
  &.top,
  &.bottom {
    .tooltip::before {
      transform: translateX(-50%);
    }
  }
}
