.systems-tabs {
  @include d-align-items-center;
  width: 100%;
  max-width: 89.8rem;
  margin-right: 1.6rem;
  overflow-x: auto;

  @include laptop {
    order: 1;
    max-width: 100%;
    margin: 1.6rem 0 0;
  }
}

.systems-tab {
  display: block;
  width: 100%;
  min-width: 16rem;
  max-width: 22.4rem;
  font-size: $base-font-size;
  font-weight: 500;
  line-height: 2rem;
  letter-spacing: 0.1em;
  text-transform: uppercase;
  color: $seaz-document-count;
  padding: 1.7rem 1rem;
  border: 1px solid $seaz-document-count;

  &:first-of-type {
    border-radius: 8px 0 0 8px;
  }

  &:last-of-type {
    border-radius: 0 8px 8px 0;
  }

  &.seaz-yaht__navigation_item__active {
    text-decoration: none;
    border: 1px solid $clr-primary-and-accent;
    color: $clr-primary-and-accent;
  }

  @include desktop {
    min-width: auto;
    max-width: initial;
    font-size: 1.3rem;
    text-transform: none;
    border: none;
    border-bottom: 2px solid $seaz-document-count;
    padding: 0.6rem 0.8rem;
    white-space: nowrap;

    &:first-of-type {
      border-radius: 0;
    }

    &:last-of-type {
      border-radius: 0;
    }

    &.seaz-yaht__navigation_item__active {
      text-decoration: none;
      border: none;
      border-bottom: 2px solid $clr-primary-and-accent;
      color: $clr-primary-and-accent;
    }
  }
}
