.outstanding-payment-form {
  .stepper__body {
    border-top: 1px solid $seaz-head-arrow;
    padding-top: 2.4rem;
  }

  &__title {
    font-size: 2.4rem;
    font-weight: 500;
    color: $clr-main;
    line-height: 1.4;
    margin-bottom: 2.4rem;

    &--text {
      display: block;
      font-size: 1.6rem;
      font-weight: 500;
      line-height: 1.9rem;
      color: $clr-light-grey;
      margin-bottom: 0.8rem;
    }
  }

  &__btn-container {
    display: flex;
    width: 50%;
  }

  &__btn {
    width: 100%;
    background-color: $clr-green;
    border-color: $clr-green;

    &--out {
      display: flex;
      justify-content: center;
      align-items: center;
      background-color: initial;
      color: $clr-green;

      app-seazone-icon {
        margin-right: 1.2rem;
        transform: rotateZ(180deg);
      }
    }

    &--cancel {
      color: $seaz-alarm;
      border-color: $seaz-alarm;
    }

    &:not(:last-of-type) {
      margin-right: 1.3rem;
    }
  }

  &__btn-container-in-top {
    display: flex;
    margin-bottom: 2.4rem;

    &--btn {
      width: auto;
    }
  }

  &__bank-btn-container {
    width: 50%;
    margin-top: 2.4rem;

    .outstanding-payment-form__btn-container {
      width: 100%;
      margin-top: 1.6rem;
    }
  }

  @include tablet {
    &__btn-container {
      width: 100%;
    }

    &__bank-btn-container {
      width: 100%;
    }
  }

  @include mobile {
    &__btn-container-in-top {
      flex-direction: column;

      &--btn {
        width: 100%;
      }
    }

    &__btn-container {
      flex-direction: column;
    }

    &__btn {
      &:not(:last-of-type) {
        margin: 0 0 1.3rem;
      }
    }
  }
}

.outstanding-form-container {
  width: 50%;

  @include tablet {
    width: 100%;
  }
}

.outstanding-form {
  .form__row {
    margin-bottom: 4.8rem;
  }

  &__row {
    flex-direction: column;

    input {
      background-color: inherit;
    }
  }

  &__checkbox-container {
    @include d-align-items-center;
  }

  &__checkbox {
    margin-top: 2rem;

    .boat__form-checkbox {
      margin-bottom: 0;
    }

    .form__checkbox-label-text {
      font-size: 1.4rem;
      color: $seaz-text;
      padding-left: 0.8rem;
    }
  }

  app-seazone-text-area .form-field--wrapper.form-field__area .form-field--label {
    padding-top: 0;
  }

  .form__union:before {
    display: none;
  }

  &__subcategory {
    flex-direction: column;

    &--row {
      width: 100%;

      &:not(:last-of-type) {
        margin-bottom: 4.8rem;
      }
    }
  }

  &__cash {
    display: flex;
    width: 100%;
    margin: 4.8rem 0;

    .form__row {
      align-items: flex-end;
      &:not(:last-of-type) {
        .form__col {
          margin-right: 1.2rem;
        }
      }
    }
  }

  @include desktop {
    &__cash {
      flex-direction: column;

      .form__row {
        &:not(:last-of-type) {
          .form__col {
            margin-right: 0;
          }
        }
      }

      .form__row .form__col:not(:last-child) {
        margin-right: 1rem;
      }
    }
  }

  @include tablet {
    .form__row {
      margin-bottom: 2.4rem;
    }

    &__cash {
      margin: 2.4rem 0;
    }

    &__subcategory {
      &--row {
        &:not(:last-of-type) {
          margin-bottom: 2.4rem;
        }
      }
    }
  }
}

.outstanding-payment-form-info {
  display: flex;
  align-items: center;
  width: 50%;
  background-color: #fdefc8;
  padding: 0.8rem 1.2rem;
  border-radius: 0.4rem;
  margin-bottom: 2.4rem;

  &__content {
    margin-left: 1.2rem;
  }

  &__text {
    line-height: 1.6rem;
    color: $waiting-dark;
  }

  @include tablet {
    width: 100%;
  }
}
