.seaz-outstanding {
  margin-top: 4.5rem;

  &__header {
    @include d-flex-space-between;
    align-items: center;
  }

  &__chart {
    margin-top: 4rem;

    .title-h3 {
      font-weight: 500;
    }
  }

  &__table-box {
    margin-top: 4rem;
  }

  &__label-badges {
    min-height: 3.4rem;
    margin: 1.6rem 0;
  }

  &__btn-box {
    display: flex;
    justify-content: flex-end;
    padding: 1.6rem 0;
  }

  &__download-btn {
    display: flex;
    justify-content: center;
    align-items: center;
    min-width: 30.5rem;

    &:not(:last-of-type) {
      margin-right: 2.5rem;
    }

    svg {
      margin-right: 1.8rem;
    }
  }

  @include tablet {
    &__header {
      flex-direction: column;
      align-items: flex-start;

      .title-h1 {
        margin-bottom: 1.2rem;
      }
    }

    &__btn-box {
      display: flex;
      justify-content: center;
      flex-direction: column;
    }

    &__download-btn {
      min-width: 100%;

      &:not(:last-of-type) {
        margin: 0 0 1.6rem 0;
      }
    }
  }

  @include mobile {
    margin-top: 2.4rem;

    &__header {
      &--btn {
        width: 100%;
      }
    }

    &__chart {
      margin-top: 3.2rem;
    }
  }
}

.outstanding-edit-modal {
  border-radius: 0;

  .outstanding-form-container {
    width: 100%;
  }

  .outstanding-form {
    .form__row {
      margin-bottom: 2.4rem;
    }
  }

  .outstanding-form__subcategory--row:not(:last-of-type) {
    margin-bottom: 2.4rem;
  }

  .form__additional-fields {
    margin-left: 1rem;
  }

  .accordion {
    margin-top: 4.8rem;
    padding-top: 2.4rem;
    border-top: 1px solid $seaz-head-arrow;
  }

  .accordion__label {
    display: flex;
    justify-content: space-between;
    margin-bottom: 2.4rem;
  }

  .accordion__btn {
    padding: 1rem;
    border-radius: 50%;

    svg {
      width: 1.2rem;
      height: 0.8rem;
    }
  }

  .accordion .accordion__btn {
    transform: rotateZ(180deg);
  }

  .accordion.active .accordion__btn {
    transform: rotateZ(0deg);
  }

  .outstanding-form__cash {
    margin: 0;
  }
}

.outstanding-view-modal {
  app-overlay {
    max-width: 80.8rem;
  }
}
