.boat-maintenance-dashboard-map-info {
  @include box-grid(repeat(4, 1fr), initial, initial, 1.8rem 0.8rem);
  margin-top: 2.4rem;

  &__title,
  &__text {
    display: block;
    font-size: 1.2rem;
    line-height: 1.4rem;
  }

  &__title {
    color: $seaz-document-count;
    margin-bottom: 0.2rem;
  }

  &__text {
    font-weight: 500;
    color: $seaz-text;
  }

  @include tablet {
    @include box-grid(repeat(2, 1fr), initial, initial, 1.8rem 0.8rem);
  }
}

.boat-maintenance-dashboard-map {
  height: calc(100% - 12.4rem);
  margin-top: 2.4rem;

  app-boat-map {
    display: block;
    width: 100%;
    height: 100%;
    min-height: 57.5rem;
  }

  .map-container > div {
    border-radius: 1.2rem;
  }

  @include large-md-screen {
    height: auto;

    app-boat-map {
      height: 40.7rem;
    }
  }
}
