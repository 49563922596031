.edit-supplier-profile {
  padding: 0 1rem 2.4rem;

  .seaz-profile__header {
    padding: 8rem 2.4rem 5.4rem;
  }

  .seaz-profile__navigation_list {
    flex: 0 1 100%;
  }
  .seaz-profile__navigation_item,
  .seaz-profile__navigation_link {
    width: 100%;
  }

  .seaz-profile__navigation_item {
    &:first-of-type {
      border-radius: 0.8rem 0 0 0.8rem;
    }

    &:last-of-type {
      border-radius: 0 0.8rem 0.8rem 0;
    }
  }

  .seaz-profile__navigation_link {
    justify-content: center;
  }

  .seaz-profile__navigation app-scroll-controls .controls-container button {
    height: 5rem;
  }

  .seaz-profile__navigation app-scroll-controls .controls-container button:nth-child(1) {
    left: -5px;
  }

  .seaz-profile__navigation app-scroll-controls .controls-container button:nth-child(2) {
    right: -5px;
  }

  .controls-container button:first-of-type {
    border-radius: 0.8rem 0 0 0.8rem;
  }

  .controls-container button:last-of-type {
    border-radius: 0 0.8rem 0.8rem 0;
  }

  @media screen and (max-width: 880px) {
    .seaz-profile__navigation_list,
    .seaz-profile__navigation_item,
    .seaz-profile__navigation_link {
      width: auto;
    }
  }

  @include tablet {
    padding: 0 1rem;

    .seaz-profile__header {
      width: 100%;
      padding: 3rem 0 1rem;
    }

    .seaz-profile__navigation_list {
      margin: 0 3rem;
    }
  }

  @include mobile {
    .seaz-profile__navigation_item {
      &:first-of-type {
        border-radius: 0;
      }

      &:last-of-type {
        border-radius: 0;
      }
    }
  }
}

.supplier-edit {
  &__wrap {
    width: 100%;
    max-width: 126.4rem;
    margin: 0 auto;
    padding: 0 3.2rem;
  }

  &__content {
    width: 100%;
    max-width: 126rem;
    margin: 0 auto;
    padding: 0 3.2rem;
    box-sizing: border-box;

    &--bank-item:nth-of-type(2n-1) {
      .seaz-edit__bank_row {
        background-color: $clr-white;
        @include mobile {
          background-color: #fafafb;
        }
      }
    }
    .foot-note {
      margin-top: 4rem;
      margin-bottom: 0.8rem;

      @include mobile {
        margin-top: 2rem;
      }
    }
  }

  &__row {
    width: 100%;
  }

  &__footer {
    display: flex;
    justify-content: flex-end;
    margin-top: 4.8rem;
    margin-bottom: 6rem;
  }

  @include laptop {
    &__wrap,
    &__content {
      padding: 0 1.1rem;
    }
  }

  @include tablet {
    &__add-btn {
      width: 100%;
      justify-content: center;
    }

    .seaz-crew__btn_box {
      width: 100%;
    }

    .seaz-crew__edit_head_row {
      padding: 3rem 0 1.7rem;
    }

    &__bank-row {
      width: 750px;
      max-width: 750px;
    }

    &__bank-content {
      overflow-x: scroll;
    }
  }
}

.modal-small.supplier-modal.seaz-modal {
  width: 100%;
  border-radius: 1.6rem;
}

.supplier-modal {
  padding: 1rem;

  .seaz-modal__document_form {
    width: 100%;
  }

  .seaz-modal__document_row {
    width: 100%;
  }

  .seaz-modal__document_content {
    padding: 0;
  }

  .modal-close {
    top: 4rem;
  }

  @media screen and (max-width: 1200px) {
    .seaz-modal__document_content {
      width: 100%;
    }
  }
}

.supplier-modal-content {
  &__header-row {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 2.4rem;
  }

  &__action-content {
    display: flex;
    align-items: center;
    margin-right: 3rem;
  }

  .form-field--content-suffix {
    app-seazone-icon svg {
      width: 2rem;
      height: 2rem;
    }
  }

  .label-plug {
    width: 100%;
    max-width: 14rem;
    margin-right: 2rem;
  }

  .form__checkbox-label-text {
    color: $seaz-text;
  }

  .seaz-uploader__document_label {
    margin-right: 2rem;
  }

  .yaht-edit__btn_box {
    width: 100%;
  }

  .seaz-uploader__document_file span {
    word-break: break-all;
  }

  .seaz__btn_small-outlined {
    border-radius: 0.4rem;
  }

  .seaz-uploader__document_box_drop {
    border-radius: 0.8rem;
  }

  &__btn-box {
    display: flex;
    justify-content: flex-end;
    width: 100%;
    margin: 0 auto;
    border-top: 1px solid $seaz-head-arrow;
    padding: 3rem 0;
  }

  &__button {
    &:not(:last-of-type) {
      margin-right: 1.2rem;
    }
  }

  @include tablet {
    .label-plug {
      display: none;
    }

    &__btn-box {
      flex-direction: column;
    }

    &__button {
      &:not(:last-of-type) {
        margin: 0 0 1.2rem;
      }
    }
  }

  @include mobile {
    &__action-content {
      margin-right: 2rem;
    }

    .form__checkbox-label {
      width: auto;
    }

    .form__checkbox-label-text {
      padding-left: 0.5rem;
    }
  }
}

.supplier-edit-form {
  &__description {
    font-size: 1.6rem;
    line-height: 1.9rem;
    color: $clr-light-grey;
    margin-bottom: 2.4rem;

    &.for-general-tab {
      color: $seaz-text;
      margin-bottom: 2.4rem;
    }

    &.for-tags {
      margin-bottom: 1.6rem;
    }

    &.for-additional-photos {
      padding-top: 3rem;
      margin-bottom: 1.9rem;

      span {
        display: block;
      }
    }

    &.for-services {
      padding-top: 2.4rem;

      &.added {
        margin: 1.4rem 0 2.4rem 0;
      }
    }
  }

  .seaz-crew__edit_head_row {
    padding-top: 0;
  }

  &__group {
    margin-top: 4.8rem;
    padding-bottom: 6.4rem;
    border-bottom: 1px solid $seaz-border;
  }

  &__row {
    width: 70.8rem;

    &--fixed-size {
      app-seazone-input {
        width: auto;
      }

      .form-field--content {
        max-width: 41.3rem;
        min-width: 41.3rem;
      }
    }
  }

  &__checkbox-group {
    .form__checkbox-label:before {
      border: 0.2rem solid $seaz-mulberry;
    }

    .form__checkbox-input:checked + label:before {
      background-color: $seaz-mulberry;
    }

    .form__checkbox-input:disabled:checked ~ label:before {
      background-color: rgba(53, 31, 111, 0.5);
    }

    .form__checkbox-label-text {
      font-size: 1.4rem;
      color: $seaz-text;
    }
  }

  @include tablet {
    .seaz-crew__edit_head_row {
      padding: 3rem 0 0;
    }

    &__group {
      margin-top: 3rem;
      padding-bottom: 3rem;
    }

    .seaz-crew__edit_head_row .seaz-crew__edit_title {
      padding-bottom: 0;
    }

    &__row {
      width: 100%;

      &--fixed-size {
        app-seazone-input {
          width: 100%;
        }

        .form-field--content {
          width: 100%;
          max-width: initial;
          min-width: initial;
        }
      }
    }
  }

  @include mobile {
    &__media-uploader {
      .seaz-uploader__choose {
        margin-top: 1rem;
      }
    }

    &__field-row {
      margin-bottom: 3.2rem;
    }
  }

  &__description {
    &.for-general-tab {
      color: $seaz-text;
      margin-bottom: 1rem;
    }

    &.for-tags {
      margin-bottom: 1rem;
    }

    &.for-additional-photos {
      padding-top: 1rem;
      margin-bottom: 1rem;
    }

    &.for-services {
      padding-top: 1rem;

      &.added {
        padding-top: 1rem;
      }
    }
  }
}

.supplier-edit-form-field-container {
  &__row {
    display: flex;
    align-items: center;
    width: 100%;
  }

  app-phone-number .wrapper-rounded p.form__block-text,
  &__plug {
    display: flex;
    align-items: center;
    width: 16rem;
    margin-right: 2rem;
    font-size: 1.4rem;
    color: $seaz-text;
  }

  .form-field--label {
    max-width: 16rem;
    font-size: 1.4rem;
  }

  &__form-label {
    flex-shrink: 0;
    line-height: 3.6rem;

    &--top-indent {
      padding-top: 0.8rem;
    }
  }

  &__form-label,
  .working-time__label {
    width: 16rem;
    font-size: 1.4rem;
    margin-right: 2rem;
    color: $seaz-text;
  }

  &__btn {
    margin-left: 1.6rem;
  }

  @include tablet {
    app-phone-number .wrapper-rounded p.form__block-text,
    &__plug {
      max-width: 7rem;
      margin-right: 1rem;
    }

    .form-field--label {
      width: 7rem;
      max-width: 7rem;
      margin-right: 1rem;
    }

    &__form-label,
    .working-time__label {
      width: 7rem;
      margin-right: 1rem;
    }

    &__form-label {
      line-height: 1.6rem;
    }
  }

  @include mobile {
    &__row {
      align-items: flex-end;
    }

    &__form-label {
      line-height: 1.1;
    }

    app-phone-number {
      .wrapper-rounded p.form__block-text {
        max-width: 100%;
        margin: 0 0 0.8rem 0;
      }

      .form__block.form__block--row {
        flex-direction: column;
      }

      .phone-control-wrapper {
        margin-bottom: 0;
      }
    }

    .form-field--label {
      width: 100%;
      max-width: 100%;
    }

    &__form-label,
    .working-time__label {
      width: 100%;
      margin: 0 0 0.8rem;
    }

    .seaz-red-close {
      margin-bottom: 0.8rem;
    }

    .form__checkbox-label-text {
      padding-left: 1rem;
    }

    &__plug {
      display: none;
    }
  }
}

.supplier-bank-details {
  .seaz-crew__btn_box {
    padding: 3rem 0 3rem;
  }

  @include tablet {
    .form-field--wrapper__row,
    .form__row,
    .seaz-uploader__document {
      flex-direction: column;
    }

    .wrapper-rounded.form-field--wrapper__row .form-field--label,
    .seaz-uploader__document_label {
      margin-right: 0;
      margin-bottom: 0.8rem;
    }

    .seaz-modal__document_uploader {
      padding-bottom: 0;
    }
  }
}

.bank-details-modal {
  &__row {
    display: grid;
    grid-template-columns: 15rem 1fr;
    grid-gap: 0 2.4rem;
    align-items: baseline;

    &:not(:last-of-type) {
      margin-bottom: 2.4rem;
    }
  }

  &__title {
    line-height: 1.6rem;
    color: $seaz-document-count;
  }

  &__text {
    color: $seaz-text;
    line-height: 1.6rem;
  }

  @include mobile {
    &__row {
      grid-template-columns: 1fr;
      grid-gap: 0;
    }

    &__title {
      margin-bottom: 1.6rem;
    }
  }
}

.supplier-reorganize {
  display: flex;
  height: 100%;
  flex-direction: column;

  &__modal {
    max-width: 63rem;
    font-family: Roboto, sans-serif;
  }

  &__title {
    font-style: normal;
    font-weight: 500;
    font-size: 2.4rem;
    line-height: 2.8rem;
    color: #351f73;
  }

  &__items {
    max-width: 100%;
    min-height: 60px;
    display: block;
    background: white;
    overflow: auto;
    margin: 3rem 0;
    border-bottom: 1px solid #e0e0e0;

    &--title {
      padding: 1.2rem 0;
      font-style: normal;
      font-weight: 500;
      font-size: 1.6rem;
      line-height: 1.9rem;
      color: $clr-light-grey;

      &.overline {
        border-top: 1px solid #e0e0e0;
      }
    }
  }

  &__item {
    height: 6.5rem;
    color: rgba(0, 0, 0, 0.87);
    display: flex;
    flex-direction: row;
    align-items: center;
    box-sizing: border-box;
    cursor: move;
    background: white;
    font-family: Roboto, 'Helvetica Neue', sans-serif;
    font-size: 14px;

    &--img {
      margin: 0 1.8rem;
      width: 4.1rem;
      height: 4.1rem;
      filter: invert(17%) sepia(24%) saturate(4663%) hue-rotate(238deg) brightness(86%) contrast(105%);
    }

    &--text {
      font-style: normal;
      font-weight: 500;
      font-size: 2rem;
      line-height: 2.4rem;

      &.role {
        color: $clr-light-grey;
      }

      &.service {
        color: #351f73;
      }
    }
  }

  &__buttons {
    display: flex;
    flex-direction: row;
    justify-content: right;

    & .seaz__btn_rounded {
      margin-left: 1.2rem;
    }
  }

  @include mobile {
    &__item {
      &--img {
        margin: 0 1.8rem;
        width: 3.1rem;
        height: 3.1rem;
      }

      &--text {
        font-size: 1.4rem;
        line-height: 1.2;
      }
    }
  }
}

.supplier-edit-bank-details {
  @include tablet {
    .seaz-edit__bank_row {
      flex-direction: column;
      align-items: flex-start;
    }

    .seaz-edit__bank_row--left,
    .seaz-edit__bank--account {
      flex-direction: column;
    }

    .seaz-edit__bank_btns {
      margin-top: 1.6rem;
    }

    .seaz-edit__bank_label {
      padding-bottom: 1.2rem;
    }
  }
}

.contact-person-add-row {
  align-items: flex-start;

  .supplier-edit-form-field-container__plug {
    flex-shrink: 0;
  }

  @include mobile {
    flex-direction: column;

    .supplier-edit-form-field-container__plug {
      display: flex;
      width: 100%;
      max-width: 100%;
      margin-bottom: 0.8rem;
    }
  }
}

.contact-person-add-wrap {
  display: flex;
  flex-direction: column;
  width: 100%;
  &.center {
    align-items: center;
  }
  .seaz__btn_rounded {
    width: 9.6rem;
  }
}

.contact-person-add-container {
  display: flex;
  align-items: flex-start;
  width: 100%;

  app-supplier-contact-person-preview {
    display: block;
    width: 100%;
  }

  .branches-modal-item {
    background-color: $light-color;
  }

  .seaz-red-close {
    @include d-flex-center-content;
  }
}
