.boat-assignee-settings {
  width: 100%;
  max-width: 57rem;
  margin: 3.5rem auto 0;
  box-sizing: border-box;

  .btn-save {
    background-color: $green-grey;
    border-color: $green-grey;
  }
}

.boat-assignee-form {
  margin-top: 4.7rem;
  font-size: $base-font-size;
  line-height: 1.6rem;

  &__row {
    @include d-flex-space-between;
    align-items: center;
    margin-bottom: 1.2rem;
  }
}

.boat-assignee-form-container {
  max-height: 40rem;
  overflow-y: auto;
  @include custom-scroll-bar;
  padding-right: 0.3rem;

  @include mobile {
    max-height: 35rem;
  }
}

.boat-assignee-form-item {
  padding: 1.2rem 1.6rem;
  transition: background-color 0.3s;
  border-radius: 0.4rem;
  cursor: pointer;

  &:hover {
    background-color: $clr-ashy-grey;
  }
}

.boat-assignee-form-title {
  font-size: 1.6rem;
  font-weight: 500;
  line-height: 1.9rem;
  color: $seaz-document-count;
  margin-bottom: 1.2rem;
  padding-left: 1.6rem;
}

.boat-assignee-form-person {
  @include d-align-items-center;
  color: $seaz-text;
  word-break: break-word;
  margin-right: 1.2rem;
}

.boat-assignee-form-person-logo {
  width: 2.4rem;
  min-width: 2.4rem;
  height: 2.4rem;
  border-radius: 50%;
  margin-right: 1rem;

  &__img {
    display: block;
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 50%;
  }
}

.boat-assignee-form-role {
  @include d-align-items-center;

  &__name {
    color: $profile-month;
    text-align: right;
  }
}

.boat-assignee-form-role-action {
  position: relative;
  width: 2rem;
  height: 2rem;
  background-color: #fff;
  margin-left: 1.2rem;
  border-radius: 50%;

  input[type='radio'] {
    -moz-appearance: radio !important;
    cursor: pointer;
  }

  .input-checked:before {
    content: '';
    display: block;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 100%;
    height: 100%;
    border-radius: 50%;
    border: 0.2rem solid #4f194a;
    background-color: #fff;
    cursor: pointer;
  }

  input[type='radio']:checked + .input-checked:after {
    content: '';
    display: block;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 1.3rem;
    height: 1.3rem;
    border-radius: 50%;
    background-color: #4f194a;
  }
}

.boat-assignee-form-footer {
  @include footer-with-border-left-btn(3rem 0 0, 0);
  border: none;

  &__btn {
    @include indent-not-last-of-type(0 1.2rem 0 0);
  }
}
