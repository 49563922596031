.business-cards-wrapper {
  position: relative;
  width: 100%;
  max-width: 37.5rem;
  margin: 2.4rem auto 0;
}

.business-cards-logo {
  display: block;
  width: 20rem;
  height: 2.6rem;
  margin: 0 auto;
}

.business-card {
  padding: 0 1.6rem 11rem;
  margin-top: 2rem;

  .info-rounded-box {
    padding: 2.4rem;
  }

  &__roles {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    margin-top: 1.6rem;
  }

  &__roles-chips {
    margin-bottom: 0.8rem;

    &:not(:last-of-type) {
      margin-right: 0.8rem;
    }
  }

  &__roles-icon {
    border: none;
    filter: invert(17%) sepia(24%) saturate(4663%) hue-rotate(238deg) brightness(86%) contrast(105%);
  }

  &__go-btn {
    display: block;
    width: 100%;
    margin-top: 1.6rem;
  }

  .person-info-common {
    margin-bottom: 0;
  }

  &__contacts {
    margin-top: 2.4rem;
    padding: 0 2.2rem;
  }

  .person-info-contacts__container {
    padding-bottom: 0;
  }

  .person-info-contacts__social-box {
    justify-content: center;
  }

  .crew-search-card__header--status-box {
    flex-wrap: wrap;
    margin-top: 1.6rem;

    .seaz-cv__badges_status {
      margin-left: 0;
    }
  }

  .crew-search-card__header--status {
    margin-bottom: 0.7rem;
  }

  .seaz-cv__badges_status_job {
    min-width: 15.6rem;
  }

  .seaz-cv__badges_status {
    min-width: 9.7rem;
  }

  &__person-status {
    margin-top: 1.6rem;
    text-align: center;
  }

  .search-card__person-status {
    margin-bottom: 0;
  }

  .search-card__person-age {
    white-space: nowrap;
  }

  .search-card__info {
    align-items: center;
    justify-content: center;
    text-transform: uppercase;
  }

  @include tablet {
    .search-card__person-status {
      font-size: 2.4rem;
    }

    .crew-search-card__header--status .seaz-cv__badges_status {
      margin: 0;
    }

    .crew-search-card__header--status {
      &:not(:last-child) {
        margin: 0 0.7rem 0.7rem 0;
      }
    }

    .crew-search-card__header--status-box {
      flex-direction: row;
    }
  }

  @media screen and (max-width: 600px) {
    .seaz-search__card_photo {
      margin-bottom: 0;
    }
  }

  @include mobile {
    .crew-search-card__header--status .seaz-cv__badges_status {
      max-width: initial;
    }
  }
}

.business-cards-button-box {
  @include d-align-items-center;
  justify-content: space-between;
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  width: 100%;
  max-width: 37.5rem;
  margin: 0 auto;
  padding: 1.6rem;
  background-color: $light-color;
}

.business-cards-button-save {
  width: 21rem;
  white-space: nowrap;
}

.business-cards-button-share {
  width: 11.5rem;
  padding: 1rem 1rem;
  margin-left: 1rem;
}

.business-card-info-list {
  width: 100%;
  margin-top: 1.6rem;
}

.business-card-info {
  display: flex;
  align-items: center;
  width: 100%;

  &:not(:last-of-type) {
    margin-bottom: 1.3rem;
  }

  &__col {
    width: 100%;
  }

  .info-crew__title {
    margin-bottom: 0;
  }
}

.qr-code {
  @include d-flex-center-content;
}

.supplier-business-card-share-modal {
  max-width: 36rem;
  .seaz__btn_rounded {
    width: 100%;
  }
}
