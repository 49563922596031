.referral {
  &__title {
    color: $seaz-mulberry;
  }

  &__description {
    margin-top: 2.4rem;
    color: $seaz-text;
  }

  &__field {
    width: 100%;
    max-width: 57.7rem;
    margin-top: 2.4rem;
  }

  app-seazone-form-field .seazone__form-input {
    &:disabled,
    &:read-only {
      color: $seaz-text;
    }
  }

  @include tablet {
    &__field {
      margin-top: 1.2rem;
    }
  }
}
