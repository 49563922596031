app-outstanding-table-data {
  display: table-row-group;
}

.table-wrapper {
  position: relative;

  .controls-container {
    top: -3rem !important;
    right: -0.7rem !important;
    z-index: 7;
  }
}

.outstanding-table {
  width: 100%;
  margin: 0 auto;
  border-collapse: separate;
  padding-bottom: 1rem;

  &__row {
    &.last-outstanding {
      border-radius: 1.2rem 0 0 1.2rem;
      outline: solid 2px #46a6a4;
      border-collapse: collapse;
      outline-offset: -2px;
    }

    .outstanding-table__last-cell-content.last-outstanding {
      border: solid 2px $clr-green;
      border-left: 0;
    }

    &:nth-of-type(2n + 1) {
      .outstanding-table__cell,
      .outstanding-table__last-cell-content {
        background-color: $clr-ashy-grey;
      }

      .outstanding-table__cell--right {
        background-color: $light-color;
      }
    }

    &:nth-of-type(2n) {
      .outstanding-table__cell,
      .outstanding-table__last-cell-content {
        background-color: $light-color;
      }
    }
  }

  .label-field {
    display: block;
    font-size: 1.2rem;
    color: $profile-month;
    text-align: left;
    margin-bottom: 0.2rem;
  }

  &__header-cell {
    position: relative;
    width: 100%;
    min-width: 16rem;
    // vertical-align: bottom;
    vertical-align: top;
    padding: 0.8rem 0.8rem 3rem;

    &--status {
      min-width: 11rem;
    }

    &--edit-data {
      min-width: 14rem;
    }

    &--currency {
      min-width: 22rem;
    }
    &--invoices {
      min-width: 18rem;
    }

    //&--text-field {
    //  .form-field--content {
    //    padding: 1rem 1.2rem !important;
    //  }
    //}

    &:first-of-type {
      width: 2.6rem;
      min-width: 0;
      vertical-align: top;
    }

    &:last-of-type {
      background-color: $light-color;
      text-align: right;
      z-index: 5;

      &:before {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        width: 1rem;
        height: 100%;
        background: linear-gradient(90deg, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.06) 100%);
      }
    }

    app-seazone-form-field .form-field--wrapper.wrapper-rounded .form-field--label {
      font-size: 1.2rem;
      color: $profile-month;
    }

    @include mobile {
      .form__row {
        flex-direction: row;
      }
      .form__col {
        margin-bottom: 0;
      }
    }
  }

  &__cell-outer {
    position: relative;
  }

  &__filter {
    position: absolute;
    top: 0;
    left: 0;
    width: 100% !important;
    height: 100%;
    min-height: 6.2rem;
    display: block;

    &--group {
      top: 0.2rem;
    }
  }

  &__cell {
    vertical-align: top;
    padding: 0.8rem;

    &:first-of-type {
      min-width: 0;
      border-radius: 1.2rem 0 0 1.2rem;
    }

    &--right {
      position: sticky;
      right: 0;
      min-width: 15rem;
    }
  }

  &__last-cell-content {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border-radius: 0 1.2rem 1.2rem 0;
    padding: 0.8rem;

    &:before {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      width: 1rem;
      height: 100%;
      background: linear-gradient(90deg, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.06) 100%);
    }
  }

  &__text {
    font-size: 1.4rem;
    line-height: 1.6rem;
    color: $seaz-text;
    margin-bottom: 0.2rem;

    &--currency {
      font-weight: bold;
    }
  }

  &__cut-text {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    max-width: 20rem;
  }

  &__sub-text {
    font-size: 1.2rem;
    line-height: 1.4rem;
    color: $seaz-about-text;
  }

  @include tablet {
    &__cell {
      &--right {
        position: sticky;
        right: 0;
        max-width: 4.3rem;
        min-width: 4.3rem;
      }
    }
  }
}

.outstanding-table-checkbox {
  span {
    display: none;
  }

  .form__checkbox-label,
  .form__checkbox-label:before {
    width: 2.4rem;
    height: 2.4rem;
  }

  .form__checkbox-label:before {
    border: 0.2rem solid $clr-grey;
  }

  .form__checkbox-input:checked + label:before {
    background-color: $seaz-mulberry;
    border-color: $seaz-mulberry;
  }

  &__checked-all {
    padding-top: 0.3rem;
  }
}

.colored-select {
  margin-bottom: 0.5rem;
}

.outstanding-table-actions {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: 1.2rem;

  &__text,
  &__menu {
    display: none;
  }

  &__text {
    font-family: $base-font;
    font-size: 1.6rem;
    font-weight: 500;
    line-height: 1.9rem;
    color: $clr-primary-and-accent;
    margin-left: 1.3rem;
  }

  &__container {
    display: flex;
    align-items: center;
  }

  &__button {
    display: flex;
    width: 100%;
    height: 2.4rem;

    &:not(:last-child) {
      margin-right: 0.9rem;
    }
  }

  @include tablet {
    margin-left: 0.5rem;

    &__text,
    &__menu {
      display: block;
    }

    &__container {
      display: none;
    }

    &__button {
      align-items: center;

      &:not(:last-child) {
        margin: 0 0 1.7rem 0;
      }
    }
  }
}

.colored-select-outstanding-table {
  .colored-select-option-item {
    word-break: break-word;
  }
}
