//media
$mobile-screen: 480px;
$mobile-big-screen: 504px;
$tablet-small-screen: 560px;
$tablet-md-screen: 600px;
$tablet-m-screen: 792px;
$tablet-screen: 768px;
$tablet-screen-big: 800px;
$laptop-screen: 1024px;
$desktop-screen: 1200px;
$desktop-m-screen: 1280px;
$desktop-l-screen: 1300px;
$large-screen: 1440px;
$large-md-screen: 1500px;
$extra-large-screen: 1612px;
$extra-large-m-screen: 1750px;
$huge-screen: 1920px;

@mixin mobile-big {
  @media screen and (max-width: #{$mobile-big-screen}) {
    @content;
  }
}

@mixin mobile {
  @media screen and (max-width: #{$mobile-screen}) {
    @content;
  }
}
@mixin tablet-small {
  @media screen and (max-width: #{$tablet-small-screen}) {
    @content;
  }
}

@mixin tablet-md {
  @media screen and (max-width: #{$tablet-md-screen}) {
    @content;
  }
}

@mixin tablet-m {
  @media screen and (max-width: #{$tablet-m-screen}) {
    @content;
  }
}

@mixin tablet {
  @media screen and (max-width: #{$tablet-screen}) {
    @content;
  }
}

@mixin tablet-big {
  @media screen and (max-width: #{$tablet-screen-big}) {
    @content;
  }
}

@mixin laptop {
  @media screen and (max-width: #{$laptop-screen}) {
    @content;
  }
}

@mixin desktop-m {
  @media screen and (max-width: #{$desktop-m-screen}) {
    @content;
  }
}

@mixin desktop-l {
  @media screen and (max-width: #{$desktop-l-screen}) {
    @content;
  }
}

@mixin desktop {
  @media screen and (max-width: #{$desktop-screen}) {
    @content;
  }
}

@mixin large {
  @media screen and (max-width: #{$large-screen}) {
    @content;
  }
}

@mixin extra-large-m-screen {
  @media screen and (max-width: #{$extra-large-m-screen}) {
    @content;
  }
}

@mixin extra-large {
  @media screen and (max-width: #{$extra-large-screen}) {
    @content;
  }
}

@mixin large-md-screen {
  @media screen and (max-width: #{$large-md-screen}) {
    @content;
  }
}

@mixin huge {
  @media screen and (max-width: #{$huge-screen}) {
    @content;
  }
}

@mixin hover-effect-only-web-devices {
  @media (hover: hover) and (pointer: fine) {
    @content;
  }
}
