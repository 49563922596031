.boat-maintenance-alerts {
  @include box-grid(1fr, initial, initial, 2.4rem);
  background-color: $m-orange;
  border: 0.5px solid $seaz-alert-info-bg;
  border-radius: 24px;
  padding: 1.6rem;

  @include large-md-screen {
    @include box-grid(1fr 1fr, initial, initial, 2.4rem);
  }

  @include laptop {
    @include box-grid(1fr, initial, initial, 1.6rem);
  }
}

.boat-maintenance-alerts-info-item {
  padding: 1.1rem 1.6rem;

  @include indent-not-last-of-type(0 0 1.6rem 0);

  &__text {
    word-break: break-word;
  }
}

.boat-maintenance-alerts-list {
  &__header {
    @include d-flex-space-between;
    align-items: center;
    margin-bottom: 1.6rem;
  }

  &__title {
    text-transform: uppercase;
  }

  @include mobile {
    &__header {
      flex-direction: column;
      align-items: flex-start;

      .switch {
        width: 100%;
        margin-top: 1.6rem;
      }
    }
  }
}

.boat-maintenance-alerts-chart {
  .switch {
    width: calc(50% - 0.8rem);
    @include mobile {
      width: 100%;
    }
  }
  .switch__btn-text {
    font-size: 1.2rem;
    text-transform: uppercase;
    font-weight: 500;
    display: inline-block;
  }
}

.boat-maintenance-alerts-chart-item {
  @include d-align-items-center;
  width: 100%;

  &__col {
    width: 50%;

    &--fleet {
      width: 60%;
    }
  }

  &__unassigned {
    .boat-maintenance-alerts-chart-item-info {
      background: linear-gradient(0deg, #fbe7e8, #fbe7e8), #f5dadb;
      @include tablet {
        margin-bottom: 0.8rem;
      }
    }
    .boat-maintenance-alerts-chart-item-info__text {
      color: $seaz-alarm;
    }
  }

  @include indent-not-last-of-type(0 0 1.2rem 0);

  @include tablet {
    flex-direction: column;
    align-items: flex-start;

    &__col {
      width: 100%;
    }
  }
}

.fleet-stats-chart-container {
  max-height: 24.7rem;
  overflow-y: auto;
  padding-right: 1rem;
  @include custom-scroll-bar;
}

.boat-maintenance-alerts-chart-item-info__text,
.maintenance-alerts-chart-item__text {
  &.desktop {
    @include tablet {
      display: none;
    }
  }
  &.mobile {
    display: none;
    @include tablet {
      @include multiline-truncation(1);
    }
  }
}

.boat-maintenance-alerts-chart-item-info {
  flex-shrink: 0;
  margin-right: 1.6rem;
  padding: 0.6rem 0.8rem;

  &__fleet-info {
    @include d-align-items-center;

    .boat-maintenance-alerts-chart-item-info__text {
      text-align: left;
    }
  }

  &__text {
    color: $clr-purple;

    &--marker {
      display: none;
    }

    &--left {
      text-align: left;
    }

    &--right {
      text-align: right;
    }
  }

  &__img {
    display: block;
    border-radius: 0.8rem;
    margin-right: 0.8rem;
  }

  @include tablet {
    background-color: initial;
    margin-right: 0;
    padding: 0.6rem 0;
  }
}

.bold {
  .boat-maintenance-alerts-chart-item-info {
    &__text {
      font-weight: 700;
    }
  }
}

.dot {
  .boat-maintenance-alerts-chart-item-info {
    &__text {
      &--marker {
        display: inline-block;
        padding: 0 0.8rem;
      }
    }
  }
}

.boat-maintenance-dashboard-stats-chart {
  .dashboard-stats-chart-container {
    height: 17rem;
    overflow-y: auto;
    padding-right: 1rem;
    @include custom-scroll-bar;
  }
}
