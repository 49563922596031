.crew-search-card {
  margin-bottom: 4.1rem;

  &__header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 2.4rem;

    &--status-box {
      display: flex;
    }

    &--status {
      &:not(:last-child) {
        margin-right: 0.7rem;
      }

      .seaz-cv__badges_status_job,
      .seaz-cv__badges_status,
      .offerred-status {
        min-height: initial;
        border-radius: 2.4rem;
        font-size: 1.2rem;
        font-weight: 500;
        line-height: 1.6rem;
        text-transform: uppercase;
        padding: 0.4rem 1.2rem;
      }

      .offerred-status {
        margin-right: 0;
      }

      .seaz-cv__badges_status_job.looking-job {
        border: 1px solid $seaz-edit-hover;
      }

      .seaz-cv__badges_status {
        background-color: inherit;
        color: $seaz-edit-hover;
        margin-left: 0.7rem;
        border: 1px solid $seaz-edit-hover;
      }
    }

    &--status-info {
      @include d-align-items-center;
      justify-content: flex-end;
      font-size: 1.2rem;
      color: $profile-month;

      .search-matching-label {
        margin-left: 1.2rem;
      }

      @include large {
        flex-direction: column;

        .search-matching-label {
          margin: 1.2rem 0 0;
        }
      }
    }

    &--status-last-actions {
      margin-right: 3.2rem;
    }

    @include large {
      align-items: flex-start;
    }
  }

  &__couple-sign {
    margin-left: 0.4rem;
  }

  &__footer {
    display: flex;
    justify-content: space-between;
    background-color: $clr-bg;
    border-radius: 0 0 1.6rem 1.6rem;
    padding: 1.6rem 2.4rem;
    margin-top: 2.4rem;

    .seaz-cv__info_btns_download {
      background-color: $clr-primary-and-accent;
    }
  }

  &__footer-btn-group {
    display: flex;
    align-items: center;

    &--middle {
      margin-left: auto;
    }

    &--change-btn {
      button:not(:last-of-type) {
        margin-right: 1.2rem;
      }
    }
  }

  &__footer-user-action-btn {
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    width: 4rem;
    height: 4rem;
    box-sizing: border-box;
    margin-right: 0.8rem;
    border-radius: 50%;

    &--note {
      display: flex;
      justify-content: center;
      align-items: center;
      background-color: $seaz-edit-hover;

      .seaz-search__card_controls--new {
        top: 1px;
      }
    }

    &--cover-l {
      background-color: $clr-green;
    }
  }

  &__offer-btn {
    letter-spacing: 0.1rem;
    text-transform: uppercase;
  }

  .seaz__btn_outlined,
  .seaz-btn-primary-small {
    margin-right: 1.2rem;
  }

  .seaz-btn-primary-small-favorite.active {
    background-color: $seaz-edit-hover;
  }
  .seaz-btn-primary-small-relevant.active {
    background-color: $seaz-alarm;
  }

  &__rating {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 0.7rem;

    &--icon {
      margin-right: 1.4rem;

      svg {
        width: 1.3rem;
        height: 1.3rem;
      }
    }

    &--star {
      padding-top: 0.1rem;

      svg {
        width: 1.3rem;
        height: 1.3rem;
      }
    }
  }

  @include laptop {
    &__header {
      &--status-info {
        display: flex;
        flex-direction: column;
        align-items: flex-end;

        .search-matching-label {
          margin: 1.2rem 0 0;
        }
      }

      &--status-last-actions {
        margin: 0 0 1rem 0;
      }
    }
  }

  @include tablet {
    &__header {
      align-items: flex-start;
      padding: 1.6rem;

      &--status-box {
        flex-direction: column;
      }

      &--status {
        &:not(:last-child) {
          margin: 0 0 0.7rem 0;
        }

        .seaz-cv__badges_status_job,
        .seaz-cv__badges_status,
        .offerred-status {
          width: 100%;
          text-align: center;
        }

        .seaz-cv__badges_status {
          margin: 0.7rem 0 0 0;
        }
      }
    }

    &__rating {
      &--icon {
        margin-right: 0.5rem;
      }
    }

    .seaz-search__card_photo {
      width: 8.8rem;
      height: 8.8rem;
    }

    .seaz-search__card_position {
      width: 3.2rem;
      height: 3.2rem;
    }

    &__footer {
      flex-wrap: wrap;
      padding: 1.6rem;
    }

    .seaz__btn_outlined {
      margin-right: 0;
    }

    &__person-info {
      display: block;

      &--partner {
        display: block;
      }

      &--sign {
        margin: 0 0.4rem 0 0;
      }
    }

    .seaz-btn-primary-small {
      &:last-of-type {
        margin-right: 0;
      }
    }

    &__footer-btn-group {
      &:last-of-type {
        width: 100%;
        flex-direction: column;
        margin-top: 1.2rem;
      }

      &--change-btn {
        button:not(:last-of-type) {
          margin: 0 0 1.2rem 0;
        }
      }
    }

    .seaz__btn_outlined {
      margin: 0 0 1.2rem 0;
    }

    &__offer-btn {
      width: 100%;
    }

    .couple.crew-search-card__couple {
      justify-content: flex-start;
      margin-top: 0.4rem;
    }
  }

  .crew-id-box {
    align-items: center;
  }

  @include mobile {
    &__header {
      flex-direction: column;

      &--status-box {
        width: 100%;
      }

      &--status-info {
        align-items: center;
        width: 100%;
        margin-top: 1.4rem;

        .search-matching-label {
          width: 100%;
          margin: 1.2rem 0 0;
        }

        .label-box {
          display: block;
          text-align: center;
        }
      }

      &--status {
        .seaz-cv__badges_status_job,
        .seaz-cv__badges_status {
          width: 100%;
          min-width: 100%;
          max-width: 20rem;
        }

        .seaz-cv__badges_status_job {
          max-width: 17rem;
        }
      }

      &__rating {
        &--icon {
          margin-right: 0.5rem;
        }
      }

      &--status-last-actions {
        margin: 0 0 0.7rem 0;
      }
    }
  }
}

.info-crew {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-gap: 1.6rem 1rem;
  margin-top: 1.6rem;

  &__row {
    border-left: 1px solid $seaz-bg;
    padding-left: 2.4rem;

    &:nth-of-type(1),
    &:nth-of-type(4),
    &:nth-of-type(7) {
      border-left: 0;
      padding-left: 0;
    }
  }

  &__title {
    font-size: 1.4rem;
    line-height: 2rem;
    color: $profile-month;
    margin-bottom: 0.8rem;
  }

  &__info-text {
    font-size: 1.6rem;
    font-weight: 500;
    word-break: break-word;
    color: $seaz-text;
  }

  &__info-empty {
    display: flex;
    app-seazone-icon {
      width: 1.8rem;
    }
  }

  &__verified {
    display: inline-block;
    position: relative;
    height: fit-content;
    padding: 3px;
    background-color: #3aae27;
    border-radius: 0.4rem;
    margin-right: 0.1rem;
  }

  &__greeny-badge {
    display: flex;
    align-items: center;
    width: max-content;
    background-color: $greeny-bg;
    padding: 0.6rem;
    border-radius: 0.4rem;

    app-seazone-icon {
      margin-right: 0.7rem;
    }

    &--text {
      font-size: 1.2rem;
      color: $seaz-success;
    }
  }

  &__salary {
    &--val {
      font-size: 2rem;
      font-weight: 500;
      line-height: 1.4;
      color: $seaz-text;
    }

    &--period {
      color: $profile-month;
    }
  }

  &__info-label {
    display: flex;
    align-items: center;

    &--icon {
      margin-right: 0.8rem;
    }

    &--img {
      width: 2.5rem;
      object-fit: cover;
    }
  }

  &__contract-badge {
    display: flex;
    align-items: center;

    div {
      background-color: $seaz-bg;
      border-radius: 50%;
      padding: 0.5rem;

      &:not(:last-of-type) {
        margin-right: 0.4rem;
      }
    }
  }

  &__boat-wrap {
    @include d-align-items-center;

    .info-crew__info-text {
      white-space: nowrap;
    }
  }

  @include laptop {
    &__row {
      padding-left: 1.5rem;
    }
  }

  @include tablet {
    grid-template-columns: 1fr;
    grid-gap: 1.2rem 0;
    border-top: 1px solid $clr-ashy-grey;
    margin-top: 1.2rem;
    padding-top: 1.2rem;

    &__row {
      display: flex;
      align-items: center;
      text-align: left;
      padding-left: 0;
      border-left: 0;
    }

    &__col {
      width: 50%;
    }

    &__title {
      margin-bottom: 0;
    }

    &__salary {
      &--val {
        font-size: 1.6rem;
      }
    }
  }

  @include mobile {
    &__col {
      width: 45%;
    }
  }
}

.job-offer-card {
  .seaz__btn_outlined-red,
  .seaz__btn_mulberry,
  .seaz__btn_outlined--blue.capital {
    font-size: 1.4rem;
    font-weight: 500;
    line-height: 2rem;
    letter-spacing: 0.1rem;
    text-transform: uppercase;
    border-radius: 0.4rem;
    padding: 1rem 2rem;
  }

  @include tablet {
    .seaz__btn_outlined-red,
    .seaz__btn_mulberry,
    .seaz__btn_outlined--blue.capital {
      width: 100%;
    }
  }
}

@include mobile {
  .job-offer-right-col {
    width: auto;
  }
}

.crew-search-card-info-box {
  margin-top: 1.6rem;
  border-top: 1px solid $seaz-bg;

  &__item {
    @include d-align-items-center;
    font-size: 1.6rem;
    color: $seaz-text;
    padding-top: 1.6rem;
    word-break: break-word;
  }

  &__icon {
    display: block;
    margin-right: 1.3rem;
  }

  @include mobile {
    margin-top: 1.2rem;
    border-top: none;
  }
}
