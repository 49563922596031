.seaz-title-h1 {
  font: 700 4rem/100% $base-font;
  color: $clr-primary-and-accent;
  word-break: break-word;

  @include tablet {
    font-size: 2.5rem;
  }
}

.seaz-title-h2 {
  font: 500 3rem/145% $base-font;
  color: $clr-primary-and-accent;
  word-break: break-word;

  @include tablet {
    font-size: 2rem;
  }

  &--uppercase {
    text-transform: uppercase;
  }
}

.seaz-title-h3 {
  font: 500 2.4rem/1.4 $base-font;
  color: $clr-primary-and-accent;
  word-break: break-word;

  @include tablet {
    font-size: 2rem;
  }
}

.seaz-title-h4 {
  font: 500 2rem/1.4 $base-font;
  color: $seaz-primary-and-accent;
  word-break: break-word;

  @include tablet {
    font-size: 1.8rem;
  }
}

.seaz-subtitle {
  font: 400 1.4rem/2rem $base-font;
  color: $seaz-text;
  word-break: break-word;
  margin-top: 1.2rem;
}
