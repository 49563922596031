.fleets-map {
  &__title {
    text-transform: uppercase;
  }
}

.fleet-map-form {
  margin-top: 1.6rem;

  &__chips {
    @include d-align-items-center;
    flex-wrap: wrap;
    margin-top: 1.6rem;

    .selected {
      outline: 2px solid #00b9ff;
    }
  }

  .location-autocomplete-selected {
    app-location-autocomplete {
      display: block;
      width: 100%;
    }
  }
}

.fleets-search-map {
  position: relative;
  width: 100%;
  height: 40.7rem;
  margin-top: 0.8rem;
  border-radius: 1.2rem;

  app-search-map {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border-radius: 1.2rem;

    .map-container {
      & > div {
        border-radius: 1.2rem;
      }
    }
  }

  //.map-info__header {
  //  font-size: 1.6rem;
  //  font-weight: 700;
  //  line-height: 1.9rem;
  //
  //  span {
  //    &:last-of-type {
  //      color: $clr-grey;
  //    }
  //  }
  //}

  .info-window__content {
    p {
      @include d-align-items-center;
      font-size: 1.6rem;
      font-weight: 500;
      line-height: 1.9rem;
      color: $seaz-text;

      &:not(:last-of-type) {
        margin-right: 1.9rem;
      }
    }
  }

  .info-window__content {
    @include d-align-items-center;
  }
}

.boat-map-modal {
  .map-info__header {
    font-size: 1.6rem;
    font-weight: 500;
    color: $clr-primary-and-accent;
    justify-content: space-between;
    line-height: 1.9rem;

    span {
      &:last-of-type {
        color: $clr-grey;
      }
    }

    &--last-update {
      font-size: 1.2rem;
      font-weight: 400;
    }
  }

  .info-window__content {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    row-gap: 0.8rem;
    column-gap: 0.8rem;
    &--item {
      display: flex;
      flex-direction: column;
      flex: 1 1 calc(50% - 0.8rem);
      font-size: 1.2rem;
      &__title {
        color: $clr-grey;
        font-weight: 400;
      }
      &__description {
        font-weight: 500;
      }
    }
  }

  @include mobile {
    .info-window__header {
      flex-direction: column;
      font-size: 1.2rem;
    }
  }
}
