.boat {
  padding: 0 0 4.4rem;

  &__wrap-btn {
    margin: 2rem 0;
  }

  &__wrap-btns {
    justify-content: space-between;
    margin-bottom: 2.4rem;

    .main-btn_icon-left {
      @include mobile {
        padding-left: 0.8rem;
        width: 4rem;
        font-size: 1.6rem;
        .main-btn__icon_left {
          position: initial;
        }
        .main-btn__text {
          display: none;
        }
      }
    }
  }

  &__wrap-title {
    @include tablet {
      flex-wrap: wrap;
    }
  }

  &__wrap-select {
    @include tablet {
      margin-bottom: 0.7rem;
    }
  }

  &__top-btn {
    border-radius: 3rem;
    padding: 0.8rem 1.1rem;
    text-transform: none;
    background: #46a6a4;
    color: white;
    transition: $tr_03;

    &-icon {
      margin-right: 1rem;
    }

    &:hover {
      background-color: #55b5b3;
      border-color: #55b5b3;
    }

    &:focus,
    &:active {
      background-color: #3f9795;
      border-color: #3f9795;
    }
  }

  &__wrap-card {
    padding: 1.1rem 1.6rem 1.1rem 1.1rem;
    border-radius: 0.8rem;
    margin-bottom: 1.2rem;
    @include mobile {
      padding: 0.9rem;
      .card__head span {
        display: none;
      }
    }

    .card__wrap-img {
      margin-right: 1.1rem;
      border: 0.25rem solid $clr-purple;
      padding: 0.25rem;
      width: 8rem;
      height: 8rem;
      @include mobile {
        width: 3.7rem;
        height: 3.7rem;
        margin-right: 0.5rem;
      }
    }

    .card__upload {
      color: #a9a9a9;
    }
  }

  &__btn-icon {
    padding: 0.8rem;
    margin-left: 1.6rem;
    width: 4rem;
    height: 4rem;
    @include mobile {
      margin-left: 1rem;
    }

    i {
      font-size: 2rem;
    }
  }

  &__tabs {
    padding-top: 5rem;
    margin-bottom: 3.5rem;
    @include mobile {
      padding-top: 3rem;
      margin-bottom: 1.5rem;
    }

    .tabs__item {
      max-width: 16.4rem;
      width: 100%;
    }

    .tabs__link {
      padding: 0 18% 1rem;
      @include tablet {
        padding: 0 0.5rem 1rem;
      }
      @include mobile {
        font-size: 1.3rem;
        line-height: 2rem;
      }
    }

    &.small {
      padding-top: 1.3rem;
      margin-bottom: 1.2rem;
    }
  }

  &__child-tabs {
    position: fixed;
  }

  &__form-title,
  &__title {
    margin-bottom: 2.6rem;
    @include mobile {
      margin-bottom: 1.3rem;
    }

    &--profile {
      margin-top: 1.6rem;
      margin-bottom: 3rem;
    }
  }

  &__card {
    border-radius: 0 0 1.6rem 1.6rem;
    overflow: hidden;

    &-wrap {
      margin-bottom: 3rem;
      flex-wrap: wrap;
      @include tablet {
        flex-direction: column;
      }
      @include tablet-small {
        margin-bottom: 2rem;
        .profile__flag img {
          width: 1.7rem;
          height: 1rem;
        }
        .profile__title {
          font-size: 1.4rem;
          line-height: 1.6rem;
        }
        .team__avatar {
          width: 3rem;
          height: 3rem;
        }
        .profile__wrap-img {
          width: 5.6rem;
          height: 5.6rem;
          margin-right: 1.2rem;
        }
      }
    }

    @include mobile {
      border-radius: 0;
    }
  }

  &__form-text {
    font: 1.4rem/2.4rem $base-font;
    letter-spacing: 0.015rem;
    margin-bottom: 2.5rem;

    @include mobile {
      font-size: 1.2rem;
    }

    div {
      color: #a9a9a9;
    }
  }

  &__form-checkbox {
    margin-bottom: 1.2rem;

    .form__checkbox-input:checked + label::before {
      background-color: #46a6a4;
      border-color: #46a6a4;
    }

    .form__checkbox-input:disabled:checked ~ label:before {
      border: none;
    }
  }

  &__form-file {
    .upload-file__wrap {
      padding: 3.2rem 1rem;
    }
  }

  &__form-list {
    margin: 1.5rem 0 5rem;
    @include mobile {
      margin: 2.5rem 0;
    }
  }

  &__date {
    display: flex;
    margin-bottom: 1.7rem;

    &-text:not(:last-child) {
      margin-right: 3.8rem;
    }
  }

  &__upload .upload-file__wrap {
    min-height: 25rem;
  }

  &__wrap-title {
    margin: 1.8rem 0 1.1rem;
  }

  &__text {
    font: 1.4rem/1.6rem $base-font;

    &_crew {
      margin-top: 2.6rem;
      word-break: break-word;
    }
  }

  &__form {
    .user-note {
      margin-top: 1rem;
    }
  }
}

.plug-label-details {
  @include plug-label(14rem, 0 7rem 0 0);

  @include tablet-big {
    @include plug-label(14rem, 0 2rem 0 0);
  }
}

.boat-finance-title {
  margin-bottom: 0;
}

.finance-search {
  margin-bottom: 2rem;
  display: flex;
  align-items: flex-end;

  .finance-sort {
    max-width: 23rem;
    margin-left: 1.2rem;
  }

  @include tablet {
    flex-direction: column;
    .finance-sort {
      margin-left: 0;
      max-width: initial;
      width: 100%;
    }
  }
}

.split-expense {
  &__crew_item {
    border-bottom: 1px solid #e7e7eb;
    padding-bottom: 28px;
    padding-top: 24px;

    &:last-child {
      border-bottom: 0;
    }

    app-amount-form {
      .form__row {
        display: flex;
        align-items: flex-end;
        @include laptop {
          flex-wrap: wrap;
          width: 100%;
        }

        @include mobile {
          align-items: baseline;
          width: 100%;
        }
      }

      .form__col {
        width: 47%;
        margin-right: 3%;
        @include laptop {
          margin-right: 6%;
          &:nth-of-type(2n) {
            margin-right: 0;
          }
        }
        @include mobile {
          width: 100%;
          margin-right: 0;
        }
      }
    }
  }
}

.boat-settings {
  &__title_row {
    align-items: center;
    @include mobile-big {
      align-items: flex-start;
    }

    .main-btn_icon-left {
      @include mobile-big {
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
      }
    }
  }

  &__tabs {
    display: flex;
    flex-grow: 1;

    .boat__tabs {
      margin-bottom: 0;
      width: 100%;
    }

    &-wrap {
      max-width: 100%;
      overflow: auto;
      display: flex;
      margin-bottom: 3.5rem;
    }

    .tabs__link {
      width: 100%;
      padding: 0 1rem 1rem;
    }

    .tabs__item {
      max-width: none;
      // width: 236px;
    }
  }

  &__title {
    align-items: center;
    justify-content: space-between;
    @include tablet-small {
      flex-direction: column;
      align-items: baseline;
    }

    .form__col {
      @include tablet-small {
        width: 100%;
        margin: 1rem 0;
      }

      .main-btn {
        @include tablet-small {
          width: 48%;
        }
      }
    }

    &.form__row {
      margin-bottom: 1.2rem;
    }

    &_btns {
      display: flex;
      justify-content: flex-end;

      .main-btn {
        margin-right: 1rem;
      }
    }
  }

  &__lists {
    width: 100%;
  }

  &__accordion {
    .accordion {
      width: 100%;
      margin-bottom: 2.4rem;
      border: 1px solid #e7e7eb;
      border-radius: 12px;

      &.active {
        .accordion__label_right {
          &::before {
            content: '';
            width: 1px;
            height: 2.4rem;
            background-color: #c2bcd4;
            display: block;
            margin-right: 2.2rem;
          }
        }

        .accordion__btn_box {
          bottom: 5px;
          transform: rotate(0deg);
        }

        .accordion__content {
          display: block;
        }
      }
    }

    .accordion__label {
      display: flex;
      padding: 1.4rem 1.6rem;
      align-items: center;
      justify-content: space-between;

      .boat-settings__edit::before {
        background-image: url('/assets/img/edit.svg');
      }
    }

    .accordion__label_text {
      color: #4f194a;
      font-size: 2.4rem;
      font-weight: bold;
      line-height: 2.5rem;
    }

    .accordion__label_right {
      display: flex;
      align-items: center;
      height: 2.4rem;
      margin: 0 0 0 auto;
    }

    .accordion__btn_box {
      border: 10px solid transparent;
      border-bottom: 10px solid #4d4e67;
      position: relative;
      cursor: pointer;
      transform: rotate(180deg);
      bottom: -3px;
    }

    .accordion__btn {
      display: none;
    }

    .accordion__content {
      display: none;
    }
  }

  &__edit {
    cursor: pointer;

    &::before {
      content: '';
      background-image: url('/assets/img/edit-hide.svg');
      background-position: center;
      background-repeat: no-repeat;
      background-size: cover;
      width: 2rem;
      height: 2rem;
      display: block;
      margin-right: 2.3rem;
    }
  }

  &__toggle {
    margin-right: 2.4rem;
  }

  &__subcategory {
    display: flex;
    padding: 20px 16px;
    border-radius: 12px;
    background-color: #fafafb;
    align-items: center;
    justify-content: space-between;
    color: #a9a9a9;

    &.active {
      color: #4f194a;

      .boat-settings__edit::before {
        background-image: url('/assets/img/edit.svg');
      }
    }
  }

  &__subcategory_btns {
    display: flex;
    align-items: center;
  }
}

app-boat-sub-category-item:nth-of-type(2n) {
  .boat-settings__subcategory {
    background-color: #fff;
  }
}

.add-category {
  &__title {
    font-size: 3.4rem;
    line-height: 40px;
    font-weight: 500;
    padding-bottom: 2.4rem;
    word-break: break-all;
    color: #351f6f;
    @include mobile {
      margin-top: 2rem;
    }
  }

  &__form {
    span {
      color: #351f6f;
      font-size: 1.2rem;
      padding-bottom: 1rem;
    }

    .add-category__additional {
      display: block;
      color: #4d4e67;
      font-weight: 500;
      padding-bottom: 0.9rem;
      font-size: 2rem;
    }

    .form__radio-container {
      display: flex;

      app-seazone-radio-item {
        margin-right: 1rem;
      }
    }

    app-seazone-input {
      padding-bottom: 2.8rem;
    }

    .form__checkbox {
      margin-bottom: 1rem;
    }

    .form__checkbox-label-text {
      padding-bottom: 0;
    }

    .form {
      padding-left: 3rem;
      box-sizing: border-box;
    }
  }

  &__form_btns {
    display: flex;
    justify-content: space-between;
    margin: 0 0 0 auto;
    width: 50%;

    .form__block {
      width: 48%;
      @include mobile {
        width: 100%;
        margin-bottom: 1rem;
      }
    }

    @include mobile {
      width: 100%;
      // margin-top: 2rem;
    }
  }
}

.settings-field {
  &__col {
    display: flex;
    justify-content: flex-end;
  }

  &__row {
    display: flex;
    align-items: center;
    background-color: #fff;
    color: #a9a9a9;
    padding: 2rem 0.8rem;
    justify-content: space-between;

    &.active {
      color: #351f6f;

      .boat-settings__edit::before {
        background-image: url('/assets/img/edit.svg');
      }
    }
  }

  &__content {
    app-manage-fields-list {
      width: 100%;
    }
  }

  &__row_name {
    width: 40%;
    @include mobile {
      padding-right: 1rem;
      box-sizing: border-box;
    }
  }

  &__row_type,
  &__row_status {
    width: 25%;
    @include mobile {
      padding-right: 1rem;
      box-sizing: border-box;
    }
  }

  &__row_controls {
    width: fit-content;
    display: flex;

    &::before {
      content: '';
      width: 1px;
      height: 2.4rem;
      background-color: #c2bcd4;
      display: block;
      margin-right: 2.2rem;
      @include mobile {
        margin-right: 1rem;
      }
    }

    div {
      justify-content: flex-end;
    }
  }

  &__row_controls {
    div {
      display: flex;
      align-items: center;
    }
  }

  &__content {
    app-manage-field-item:nth-of-type(2n) {
      .settings-field__row {
        background-color: #fafafb;
        color: #351f6f;
      }
    }
  }
}

.field-modal {
  &__form {
    .field-modal__col {
      width: 100%;
    }
  }

  &__radio {
    span {
      padding-bottom: 1rem;
      font-size: 1.2rem;
      line-height: 1.4rem;
      color: #351f6f;
    }

    .form__radio-container {
      display: flex;

      .form__radio {
        display: flex;
        align-items: center;
        margin-right: 1rem;

        .form__radio-label {
          height: 18px;
        }

        .form__radio-label-text {
          padding-bottom: 0;
        }
      }
    }
  }

  &__form_btns {
    display: flex;
    justify-content: space-between;
    margin: 0 0 0 auto;
    width: 50%;
    @include mobile {
      flex-direction: column;
      width: 100%;
    }

    .form__col {
      @include mobile {
        margin: 0 0 1rem 0;
      }
    }
  }
}

.setting-card {
  &__title {
    display: flex;
    align-items: center;
    justify-content: space-between;

    .form__col {
      width: auto;
      @include mobile {
        margin-bottom: 2rem;
        width: 100%;
      }

      .main-btn {
        @include mobile {
          width: 100%;
        }
      }
    }

    &_btn {
      display: flex;
      justify-content: flex-end;
    }

    &.form__row_general {
      @include tablet-big {
        flex-direction: row;
      }
      @include mobile {
        flex-direction: column;
        align-items: baseline;
      }
    }
  }

  &__toggle {
    @include d-align-items-center;
    margin-bottom: 2.1rem;

    &--text {
      margin: 0 1.4rem 0 1.5rem;
    }
  }

  &__content {
    border: 1px solid #e7e7eb;
    border-radius: 12px;

    &_list {
      width: 100%;

      app-boat-card-item:nth-of-type(2n),
      app-bank-account-item:nth-of-type(2n) {
        .setting-card__item {
          background-color: #fff;
        }
      }
    }
  }

  &__item {
    display: flex;
    padding: 2rem 1.6rem;
    justify-content: space-between;
    align-items: center;
    background-color: #fafafb;
    color: #a9a9a9;
    border-radius: 12px;

    .boat-settings__edit::before {
      background-image: url('/assets/img/edit-hide.svg');
    }

    &.active {
      color: #4f194a;

      .boat-settings__edit::before {
        background-image: url('/assets/img/edit.svg');
      }
    }
  }

  &__item_controls {
    display: flex;
    align-items: center;
  }

  @include mobile {
    &__toggle {
      padding-left: 0.3rem;
    }
  }
}

.modal-edit {
  &__row {
    margin-top: 1rem;

    &.form__col {
      width: 100%;
    }
  }
}

.settings-management__list {
  @include mobile {
    border: 1px solid #e7e7eb;
    border-radius: 12px;
  }

  .settings-management {
    &__item {
      .card__wrap {
        @include mobile {
          align-items: baseline;
          flex-direction: column;
        }
      }

      .card__wrap-img {
        @include mobile {
          width: 9.4rem;
          height: 9.4rem;
          margin-bottom: 1.6rem;
        }
      }

      .card__title {
        @include mobile {
          // font-weight: 300;
          margin-bottom: 0.8rem;
        }
      }

      .card__col_btns {
        @include mobile {
          flex-direction: column;
        }

        .card__btn:not(:last-child) {
          @include mobile {
            margin-right: 0;
          }
        }
      }

      .card__title_position {
        @include mobile {
          font-weight: 300;
        }
      }

      .card__info_row {
        @include tablet-small {
          flex-wrap: wrap;
        }
        @include mobile {
          flex-direction: column;
        }
      }

      .card__phone {
        @include mobile {
          margin-bottom: 0.8rem;
        }
      }
    }
  }
}

.setting-exclude__box {
  .settings-management {
    &__item {
      .card__wrap-img {
        @include mobile {
          width: 10.7rem;
          height: 10.7rem;
          margin-right: 1.6rem;
        }
      }

      .card__title {
        @include mobile {
          font-size: 1.8rem;
          margin-bottom: 0.8rem;
        }
      }

      .card__status_row {
        @include mobile {
          align-items: center;
          flex-wrap: wrap;
        }

        .card__crew {
          @include mobile {
            margin-bottom: 1rem;
          }
        }

        app-crew-status {
          @include mobile {
            margin-bottom: 1rem;
          }
        }
      }

      .card__info_row {
        @include mobile {
          flex-wrap: wrap;
        }
      }

      .boat__wrap-card {
        @include mobile {
          padding: 0;
        }
      }
    }
  }
}

.settings-management {
  &__item {
    .card__wrap {
      align-items: center;
    }

    .card__wrap-img {
      border: none;
    }

    .card__title_position {
      padding-bottom: 1.2rem;
      color: #46a6a4;
      font-size: 1.4rem;
      font-weight: bold;
      line-height: 1.6rem;
    }

    .card__crew {
      background-color: #e7e7eb;
      border-radius: 29px;
      padding: 0.5rem 0.8rem;
      color: #351f6f;
      margin-right: 1rem;
    }

    .card__status {
      display: inline-flex;
      align-items: center;
      width: fit-content;

      &--user {
        @media screen and (max-width: 1024px) {
          margin-top: 1.2rem;
        }
      }
    }

    .card-status__row .card__status {
      .status-icon {
        position: relative;

        &::before {
          content: '';
          background-image: url('/assets/img/on-boat.svg');
          display: block;
          width: 1.4rem;
          height: 1.4rem;
          background-position: center;
          background-repeat: no-repeat;
          background-size: contain;
        }
      }

      .icon-anchor {
        display: none;
      }
    }

    .card__status_row {
      display: flex;
      align-items: center;
    }

    .card__info_row {
      display: flex;
      padding-top: 1.5rem;
    }

    .card__phone {
      display: flex;
      align-items: center;
      margin-right: 2.6rem;

      span {
        margin-left: 0.9rem;
      }

      &::before {
        content: '';
        background-image: url('/assets/img/card-phone.svg');
        width: 1.3rem;
        height: 1.3rem;
        display: block;
        background-size: contain;
        background-repeat: no-repeat;
      }
    }

    .card__email {
      display: flex;
      align-items: center;

      span {
        margin-left: 0.9rem;
      }

      &::before {
        content: '';
        background-image: url('/assets/img/card-email.svg');
        width: 1.5rem;
        height: 1.2rem;
        display: block;
        background-size: contain;
        background-repeat: no-repeat;
      }
    }

    &_title {
      padding-bottom: 1.2rem;
      color: #4d4e67;
    }
  }

  &__form {
    .accordion {
      border: 1px solid #e7e7eb;
      border-radius: 12px;
    }

    .accordion__label .form__checkbox-label-text {
      color: #4f194a;
      font-size: 2.4rem;
      font-weight: 700;
      line-height: 2.5rem;
    }
  }
}

.boat-settings__title_btns.form__col {
  @include mobile {
    flex-direction: column;
  }

  .main-btn {
    @include mobile {
      width: 100%;
      margin-right: 0;
      margin-bottom: 1rem;
    }
  }
}

.settings-management__item:nth-of-type(2n + 1) {
  .boat__wrap-card {
    background-color: #fafafb;
  }
}

.settings-management__item {
  .card__row-right {
    display: flex;
    align-items: center;
  }
}

.settings-user {
  &__col {
    display: flex;
    justify-content: flex-end;
  }

  &__affilate-btn {
    padding: 0.8rem;
  }
}

.boat-settings {
  &__accordion {
    .accordion__content {
      .form__checkbox {
        padding: 1.9rem;
        background-color: #fafafb;
      }

      app-seazone-checkbox:nth-of-type(2n) {
        .form__checkbox {
          background-color: #fff;
        }
      }
    }

    .card-statements__header-card--tooltip {
      margin-left: 1.3rem;
    }
  }
}

.card-statements__warning-tooltip {
  .tooltip__text {
    font-size: 1.2rem;
    letter-spacing: 0.01em;
  }
}

.exclude__btns_box {
  width: 21rem;
  @include mobile {
    width: 100%;
  }
}

.setting-permission {
  &__header {
    @include d-align-items-center;
    align-items: center;
    margin: 6.4rem 0 3.2rem;

    app-seazone-checkbox {
      margin-left: 3.3rem;

      .form__checkbox-label-text {
        padding-left: 1.1rem;
        color: $seaz-mulberry;
      }
    }

    @include tablet {
      flex-direction: column;
      align-items: flex-start;

      app-seazone-checkbox {
        margin: 1.6rem 0 0 0;
      }
    }
  }

  &__title {
    color: #4d4e67;
  }

  &__form {
    .form {
      border: 1px solid #e7e7eb;
      border-radius: 12px;

      &__checkbox-label-text {
        color: $seaz-mulberry;
        font-size: 1.4rem;
        line-height: 2.5rem;
      }
    }

    &_btns {
      width: 21rem;
      margin-top: 3.2rem;

      @include tablet {
        width: 100%;
        background-color: $light-color;
      }

      @include mobile {
        .form__col:not(:last-child) {
          margin-right: 0;
        }

        .form__col {
          margin-bottom: 1.6rem;
        }
      }
    }
  }

  &__checkbox {
    &_list {
      .form__checkbox {
        padding: 1.9rem;
      }

      app-seazone-checkbox:nth-of-type(2n) {
        .form__checkbox {
          background-color: #fafafb;
        }
      }
    }

    &_title {
      color: $clr-main;
      font-size: 2.4rem;
      font-weight: 700;
    }

    &_all {
      color: $seaz-mulberry;
      font-size: 2rem;
      font-weight: 500;
      line-height: 2.5rem;
      @include tablet {
        margin: 1rem 0;
      }

      &--box {
        padding-bottom: 1rem;

        .form__checkbox-label {
          height: 2.4rem;
          width: 2.4rem;

          &:before {
            height: 2.4rem;
            width: 2.4rem;
          }
        }
      }
    }
  }

  .boat-fleet-users-permission,
  .fleet-users-permission-group {
    width: 49%;
    max-height: 100%;
  }

  .fleet-users-permission-title {
    margin-right: 2rem;

    @include tablet {
      margin-right: 0.8rem;
    }
  }

  @include tablet {
    .fleet-users-permission-title__checkbox {
      flex-shrink: 0;
      margin: 0 0 0 0.8rem;
    }
  }
}

.department {
  &__title_row {
    &.form__row {
      position: absolute;
      right: 15px;
      display: inline-flex;
      justify-content: flex-end;
      @media screen and (max-width: 660px) {
        position: relative;
        right: inherit;
        width: 100%;
        margin-top: 1rem;
        .main-btn {
          width: 100%;
        }
      }
    }
  }

  &__switch {
    display: inline-flex;

    &.tabs__header.switch {
      @media screen and (max-width: 660px) {
        width: 100%;
      }
    }
  }

  &__item {
    display: flex;
    color: #a9a9a9;
    padding: 2rem 0.8rem;
    align-items: center;
    justify-content: space-between;
    background-color: #fafafb;

    .boat-settings__edit::before {
      background-image: url('/assets/img/edit-hide.svg');
    }

    &.active {
      color: #351f6f;

      .boat-settings__edit::before {
        background-image: url('/assets/img/edit.svg');
      }
    }

    &_controls {
      display: flex;
    }
  }

  &__list {
    div:nth-of-type(2n) {
      .department__item {
        background-color: #fff;
      }
    }

    div:first-of-type {
      .department__item {
        margin-top: 2rem;
      }
    }
  }

  &-position {
    &__item {
      display: flex;
      justify-content: space-between;
      padding: 1.6rem;
      align-items: flex-start;

      &_col.form__col {
        width: 80%;
        @include mobile {
          width: 100%;
          margin-bottom: 1.2rem;
        }
      }

      &_col-right.form__col {
        width: 20%;
        @include mobile {
          width: 100%;
        }
      }

      h3 {
        padding-bottom: 1.6rem;
        font-weight: 500;
        font-size: 1.6rem;
        color: #4f194a;
      }

      &_col {
        div {
          color: #a9a9a9;
          padding-bottom: 0.5rem;

          &:last-child {
            @include mobile {
              &:after {
                content: '';
              }
            }
          }

          @include mobile {
            line-height: 1.6rem;
            padding-bottom: 0;
            display: inline-block;
            &:after {
              content: ',';
              display: inline-block;
              padding-right: 4px;
            }
          }
        }
      }
    }

    &__list {
      @include mobile {
        border: 1px solid #e7e7eb;
        display: block;
        box-sizing: border-box;
        margin-top: 2.4rem;
        border-radius: 1.2rem;
      }

      app-departments-positions-item:nth-of-type(2n) {
        .department-position__item {
          background-color: #fafafb;
        }
      }
    }
  }
}

.financial-data {
  &__table {
    .controls-container {
      display: block;
    }

    .table__col-inner {
      max-width: 150px;
    }

    &_small {
      app-filter-group-container {
        max-width: 150px;
        width: 100%;
        @media screen and (max-width: 1500px) {
          width: 80px;
        }
      }

      app-seazone-select {
        width: 100%;
        @media screen and (max-width: 1605px) {
          width: 60px;
        }
      }

      app-seazone-form-field {
        max-width: 180px;
        @media screen and (max-width: 1585px) {
          max-width: 103px;
        }
        @include large {
          max-width: 75px;
        }
      }

      padding: 1.2rem 0.4rem;
    }

    &_joined {
      max-width: 200px;
      @media screen and (max-width: 1420px) {
        max-width: 180px;
      }
      @media screen and (max-width: 1380px) {
        max-width: 150px;
      }

      label {
        line-height: 24px;
        color: #351f6f;
        font: 1.2rem/2.4rem $base-font;
      }

      .form__col:not(:last-child) {
        margin-right: 0.7rem;
      }

      @include mobile {
        .form__row {
          flex-direction: row;

          .form__col {
            width: 50%;
            margin-bottom: 0;
          }
        }
        .form-field__content-outline {
          height: 4.8rem;
        }
      }
    }

    &_large {
      app-filter-group-container,
      app-seazone-form-field {
        max-width: 220px;
        min-width: 100%;
        @media screen and (max-width: 1675px) {
          max-width: 150px;
        }
        @media screen and (max-width: 1490px) {
          max-width: 130px;
        }
      }
    }

    &_medium {
      app-seazone-form-field {
        max-width: 103px;
      }

      .form__block {
        max-width: 90px;
        width: 90px;
        @media screen and (max-width: 1345px) {
          max-width: 70px;
          width: 70px;
        }
      }
    }

    &_amount {
      .table__col-btn-sort:before {
        right: -12px;
        color: #a9a9a9;
      }

      .table__col-btn-sort {
        padding: 0 0.3rem;
        color: #4d4e67;
      }
    }

    &_btn {
      &.table__col {
        vertical-align: bottom;
      }

      .form__btn {
        width: 4.8rem;
        padding: 1.1rem 1.2rem;
        @include mobile {
          width: 4rem;
          padding: 0.8rem 1.2rem;
        }

        .icon-update {
          font-size: 2rem;
        }
      }
    }

    .wrap-table__scroll {
      overflow: auto;
      overflow-y: hidden;
    }

    .select-content {
      text-overflow: ellipsis;
    }

    .table__col-inner-multiple {
      display: flex;
      justify-content: center;

      app-date-control {
        width: auto;
      }
    }

    .picker-toggle {
      right: auto !important;

      .icon-mdi_calendar-edit::before {
        color: #351f6f;
      }
    }

    .financial-data__currency {
      .table__col-inner {
        display: inline;
        margin-right: 0.4rem;
      }
    }

    .financial-data__category {
      font-size: 1.1rem;
      color: #4d4e67;

      &_name {
        color: #4f194a;
        font-size: 1.4rem;
      }
    }

    .financial-data__btns {
      .table__col-btn i {
        color: #351f6f;
      }
    }

    .select-value {
      text-overflow: ellipsis;
      overflow: hidden;
      white-space: nowrap;
      display: block;
    }
  }

  &__form {
    .accordion {
      .group {
        padding-top: 1rem;
        display: none;

        .form__checkbox {
          padding: 1.1rem 2.4rem;
        }
      }

      &.active {
        .group {
          display: block;
        }

        .form__checkbox::after {
          transform: rotate(-180deg);
          top: -3px;
        }
      }
    }

    .accordion__label {
      display: flex;
      align-items: center;
      padding: 15px 24px 12px 21px;

      .form__checkbox {
        color: #4d4e67;
        font-weight: bold;

        &::after {
          content: '';
          border: 6px solid transparent;
          border-top: 6px solid #4d4e67;
          position: relative;
          top: 2px;
          left: 7px;
        }

        .form__checkbox-label-text {
          font-size: 1.8rem;
        }
      }

      .form__checkbox-label:before {
        border: 0.2rem solid #4d4e67;
      }

      .accordion__label_right {
        display: none;
      }
    }
  }

  &__dates {
    &.container-fixed {
      padding: 1.3rem 1.6rem 1.3rem 2rem;
    }

    &_btn {
      text-align: center;
      display: block;
      width: 100%;
      text-transform: uppercase;
      font: 1.4rem $base-font;
      color: #46a6a4;
      font-weight: 500;
    }
  }

  &__range {
    &_box {
      &.dropdown-options-container {
        min-width: 25rem;
      }

      .seazone-range-slider__inputs {
        display: flex;
        justify-content: space-between;
      }
    }

    &_btn {
      text-align: center;
      display: block;
      width: 100%;
      text-transform: uppercase;
      font: 1.4rem $base-font;
      color: #46a6a4;
      font-weight: 500;
      padding-bottom: 2rem;
    }
  }

  &__badge {
    background-color: #fafafb;
    border: 1px solid #e7e7eb;
    padding: 0.8rem;
    color: #46a6a4;
    font-size: 1.4rem;
    border-radius: 4px;
    text-transform: uppercase;
    font-weight: 500;
    // display: flex;
    // justify-content: space-between;
    margin: 1.2rem 1.2rem 0 0;
    display: inline-block;

    .icon-close:before {
      font-size: 1.3rem;
      color: #a9a9a9;
      margin-left: 1.3rem;
      cursor: pointer;
    }

    &_label {
      color: #000;
      text-transform: uppercase;
      font-size: 1.4rem;
      font-weight: 500;
      margin: 0 1.2rem 1.2rem 0;
    }

    &_box {
      display: inline;
    }
  }
}

.seaz-crew {
  &__currency {
    font-weight: 500;
  }

  &__contract {
    &_box {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      height: 100%;
    }

    &_carousel {
      width: 40rem;

      .seaz-cv__slider_btn {
        top: 25px;
      }

      app-image-carousel {
        height: 100%;
      }

      .carousel {
        overflow-y: visible;
      }
    }

    &_header {
      min-height: 50px;
      padding: 0 60px;
      display: flex;
      align-items: center;
      justify-content: center;
    }

    &_head {
      display: flex;
      justify-content: center;
      align-items: center;
      padding-bottom: 3rem;
      border-bottom: 1px solid $seaz-border;
      position: relative;
      @include tablet {
        flex-direction: column;
        padding-bottom: 2.4rem;
      }

      &_title {
        color: $clr-primary-and-accent;
        text-transform: uppercase;
        display: flex;
        align-items: center;
        @include tablet {
          width: auto;
          padding: 0 0 0 5rem;
          display: flex;
          justify-content: center;
          font-size: 2.2rem;
          text-align: center;
        }
      }

      &_icon {
        margin-left: 1.5rem;
      }

      .seaz__return_btn {
        position: absolute;
        left: 0;
        @include tablet-small {
          top: 0;
        }
      }
    }

    &_content {
      display: flex;
      justify-content: space-between;
      width: 90%;
      margin: 0 0 0 auto;
      padding-top: 6rem;
      @include laptop {
        width: 100%;
        margin: 0 auto;
      }
      @include tablet-small {
        padding-top: 2.8rem;
        flex-direction: column;
        margin: 0 auto;
        align-items: center;
      }

      app-contract-details {
        // width: 100%;
        padding-right: 5rem;
        width: 100%;
        @include laptop {
          width: 49%;
          padding-right: 1.5%;
        }
        @include tablet-small {
          padding-right: 0;
          width: 100%;
        }
      }

      .carousel-slide-content {
        width: 100%;
      }

      app-bank-details {
        @include laptop {
          width: 100%;
          .seaz-crew__contract_box {
            width: 100%;
          }
        }
      }
    }

    &_empty {
      &_label,
      &_title {
        text-align: center;
      }

      &_btn {
        display: block;
        margin: 0 auto;
        margin-bottom: 5rem;
      }

      .boat-home__content {
        padding-bottom: 3rem;
      }

      .boat-home__content-text {
        margin-top: 3rem;
        color: $seaz-document-count;
        opacity: 1;
      }
    }

    &_aside {
      width: 40rem;
      background-color: $seaz-bg;
      padding: 3rem;
      position: relative;
      @include laptop {
        width: 100%;
      }
      @include tablet-small {
        padding: 2.4rem;
      }

      &_title {
        color: $clr-primary-and-accent;
        padding-bottom: 1.5rem;
      }

      &_btn {
        position: absolute;
        top: 3rem;
        right: 2.4rem;
      }

      &_info {
        padding-bottom: 1.5rem;
        display: flex;
        flex-direction: column;
      }

      &_label {
        padding-bottom: 1.2rem;
        color: $seaz-document-count;
        font-size: 1.4rem;
        letter-spacing: 0.5px;
      }

      &_text {
        font-size: 1.6rem;
        color: $seaz-text;
        text-transform: capitalize;
      }

      &_file {
        display: flex;
        align-items: center;
        padding-bottom: 1rem;
        transition: all 0.3s;

        &:hover {
          color: $seaz-edit-hover;
          text-decoration: underline;
        }

        app-seazone-icon {
          margin-right: 1rem;
        }
      }
    }

    &_wrapper {
      padding: 3rem 4.8rem 0;
      @include laptop {
        padding: 3rem 2rem 0;
      }
      @include mobile {
        padding: 1.6rem 1.2rem 0;
      }
    }

    &_details {
      position: relative;
      padding: 4rem 0;

      &_title {
        color: $clr-primary-and-accent;
        padding-bottom: 3.3rem;
        @include laptop {
          padding-bottom: 2.7rem;
        }
      }

      &_row {
        display: flex;
        padding-bottom: 3.7rem;
        @include laptop {
          padding-bottom: 3rem;
        }
      }

      &_label {
        width: 18.4rem;
        margin-right: 3.3rem;
        flex-shrink: 0;
        @include laptop {
          margin-right: 1.2rem;
        }
        @media screen and (max-width: 700px) {
          width: 13.4rem;
        }
        @include tablet-small {
          width: 65%;
        }
      }

      &_text {
        display: block;
        width: 100%;
      }

      &_file {
        color: $seaz-document-count;
        display: flex;
        margin-bottom: 0.8rem;

        &:hover {
          .seaz-text-medium {
            color: $seaz-edit-hover;
            text-decoration: underline;
            cursor: pointer;
          }
        }

        app-seazone-icon {
          margin-right: 1rem;
        }

        &_list {
          display: flex;
          flex-direction: column;
        }
      }
    }
  }
}

.boat-crew__contract_head {
  .seaz__btn_outlined-red {
    position: absolute;
    right: 0;
    @include laptop {
      padding: 1rem 2rem;
      font-size: 1.2rem;
    }
    @include tablet {
      position: relative;
      width: auto;
      margin-top: 2.4rem;
    }
  }
}

app-boat-profile-wrapper {
  display: block;
  background-color: #f5f5f5;
}

.boat__card {
  .seaz-crew__contract_head {
    margin-right: 1.8rem;
    @media screen and (max-width: 1100px) {
      margin-right: 0;
    }
  }

  .seaz-crew__contract_content app-contract-details {
    width: 58%;
    padding: 0;
    @include tablet-big {
      width: 100%;
    }
  }

  .seaz-crew__contract_content {
    width: 126.4rem;
    margin: 0 0 0 auto;
    @media screen and (max-width: 1500px) {
      width: 100%;
    }
  }

  .seaz-crew__contract_details_label {
    @media screen and (max-width: 900px) {
      width: 13rem;
    }
    @include tablet-big {
      width: 18.4rem;
    }
  }
}

.boat-welcome {
  width: 57rem;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  @include tablet-big {
    width: 100%;
  }

  &__title {
    padding-bottom: 4.3rem;
    font-weight: bold;
    font-size: 3.4rem;
    line-height: 4rem;
    color: #4d4e67;
    text-align: center;
    @include tablet {
      padding-bottom: 3rem;
      font-size: 3rem;
    }
    @include mobile {
      padding-bottom: 2rem;
      font-size: 2.4rem;
    }
  }

  &__img {
    width: 30rem;
    margin-bottom: 3.2rem;
    @include tablet {
      width: 25rem;
    }
    @include mobile {
      margin-bottom: 2.4rem;
    }
  }

  &__content {
    width: 100%;
  }

  &__text {
    text-align: center;
    font-size: 1.6rem;
    color: #898b8c;
    line-height: 1.9rem;
    @include tablet {
      display: flex;
      flex-direction: column;
    }
    @include mobile {
      font-size: 1.4rem;
    }

    span {
      font-weight: 700;
      color: $clr-primary-and-accent;
    }
  }

  &__btns {
    margin-top: 3.2rem;
    display: flex;
    justify-content: space-between;

    &.center {
      justify-content: center;
    }

    .main-btn {
      padding: 1rem 0.8rem;
      width: 31.5%;
      @media screen and (max-width: 618px) {
        width: 100%;
        margin-bottom: 1rem;
      }
    }

    @media screen and (max-width: 618px) {
      flex-direction: column;
      justify-content: center;
      align-items: center;
    }
  }

  &__skip_link {
    padding-top: 3.2rem;
    text-align: center;
    width: fit-content;
    margin: 0 auto;
    display: block;
    text-transform: uppercase;
    font-size: 1.4rem;
    font-weight: 500;
    border-bottom: 1px dashed #351f73;
    @include mobile {
      font-size: 1.2rem;
      padding-top: 1.4rem;
    }
  }
}

.expense-form__supplier {
  align-items: center;

  app-seazone-checkbox {
    flex-shrink: 0;
    padding-top: 2rem;
    margin-left: 1.5rem;

    .form__checkbox-label-text {
      padding-left: 1.5rem;
      color: #313247;
    }
  }

  @include mobile {
    align-items: flex-start;

    app-seazone-checkbox {
      margin-left: 0;
    }
  }
}

.expense-modal {
  &__header {
    margin-bottom: 2.6rem;
  }

  &__warn {
    display: block;
    margin-top: 1rem;
  }
}

.boat-setting__sub_navigation {
  .boat__tabs {
    padding-top: 4rem;
  }

  .boat__tabs .tabs__item {
    max-width: none;
    width: auto;

    .tabs__link {
      display: flex;
    }
  }
}

.boat-setting {
  &__supplier {
    display: flex;
    flex-direction: column;

    .title-h1 {
      padding-bottom: 2rem;
    }

    &_row {
      display: flex;
      align-items: center;
    }

    &_label {
      margin-right: 2.4rem;
      color: #4d4e67;
      font-size: 1.6rem;
      font-weight: 400;
    }
  }
}

.boat-users-fleet-manager {
  .fleets {
    max-width: 100%;
    margin: 4rem auto 2rem;
  }

  .fleet-card__description {
    min-height: 10rem;
  }

  .fleets-header {
    margin-bottom: 3.2rem;
  }

  .seaz-title-h2 {
    color: $clr-main;
  }

  .fleets-header-btn {
    background-color: #46a6a4;
    border-color: #46a6a4;
  }

  .buttons-wrap {
    min-width: 30rem;
    box-sizing: border-box;
  }

  @include tablet {
    .fleets {
      padding: 0;
    }

    .fleet-card__description {
      min-height: auto;
    }
  }

  @media screen and (max-width: 560px) {
    .fleet-card-footer {
      flex-direction: column;
    }

    .fleet-card-button,
    .fleet-modal-footer__btn,
    .buttons-wrap {
      width: 100%;
    }

    .buttons-wrap {
      min-width: 100%;
      margin-top: 1.2rem;
    }
  }
}

.boat-title-box {
  @include d-align-items-center;
  margin-bottom: 3.2rem;

  .boat__title {
    margin: 0 0 0 3.8rem;
  }

  @include tablet-small {
    margin-bottom: 2rem;

    .boat__title {
      margin: 0 0 0 1.2rem;
    }
  }
}

.boat-actions-section {
  @include d-flex-space-between;
  align-items: flex-end;

  .boat__wrap-select {
    .form-field--content-outline {
      @include d-flex-center-content;
      width: 8.8rem;
      height: 4rem;
      border-radius: 0.4rem;
    }

    .form-field--label {
      font-size: 1.4rem;
      line-height: 1.6rem;
      color: $seaz-text;
    }
  }

  @include tablet-small {
    flex-direction: column;
    align-items: flex-start;

    .switch {
      width: 100%;
    }

    .boat__wrap-select {
      width: 100% !important;
      margin-top: 1.2rem;

      .form-field--content-outline {
        width: 100%;
      }
    }
  }
}

.boat-date-info-list {
  flex-wrap: wrap;
  margin-bottom: 2rem;

  .boat__date-text {
    margin-bottom: 0.8rem;
  }

  @include tablet-small {
    .boat__date-text:not(:last-child) {
      margin-right: 2rem;
    }
  }
}

.ais-error {
  font-size: 1.2rem;
  font-weight: 500;
  line-height: 1.4rem;
  color: $seaz-alarm;

  & > a {
    color: $seaz-edit-hover;
  }
}

.boat-short-info {
  display: inline-flex;
  margin: 1.2rem 0;

  * {
    font-size: 2rem;
    font-weight: bold;
    line-height: 2.3rem;
  }

  &__flag {
    margin-right: 0.8rem;

    img {
      width: 3.2rem;
      height: 1.8rem;
    }
  }

  &__type {
    color: $dark-green;
  }

  &__divider,
  &__length {
    color: $clr-grey;
  }

  &__divider {
    margin: 0 0.5rem;
    display: inline-block;
  }

  &__name {
    color: $seaz-text;
  }
}

.crew-contract-details {
  .seaz-crew__contract_details_title {
    margin-top: 1.6rem;
  }

  .seaz-crew__contract_aside_btn {
    right: auto;
    left: 0;
  }
}

.visibility-icon-box {
  @include d-flex-center-content;
  width: 2.4rem;
  height: 2.4rem;
  background-color: $clr-ashy-grey;
  border: 1px solid $seaz-head-arrow;
  border-radius: 50%;
  margin-right: 0.8rem;
}
