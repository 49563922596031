app-seazone-form-field {
  display: block;
  position: relative;
  width: 100%;
  height: 100%;
}

app-location-autocomplete {
  width: 100%;
}

.form-field {
  &--wrapper {
    position: relative;
    display: -webkit-box;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    flex-direction: column;
    width: 100%;
    height: 100%;
    cursor: pointer;
    -webkit-tap-highlight-color: transparent;

    &__row {
      flex-direction: row;

      .form-field--label {
        width: 100%;
        max-width: 14rem;
        display: flex;
        align-items: center;
        @media screen and (max-width: 650px) {
          max-width: none;
        }
        @include mobile {
          max-width: none;
        }
      }

      @include mobile {
        flex-direction: column;
      }
    }
  }

  &--label {
    display: block;
    font:
      1.2rem/2.4rem 'Roboto',
      sans-serif;
    text-align: left;
    color: #351f6f;

    &.required::after {
      color: red;
      content: '*';
    }

    &--text {
      display: inline-flex;
      line-height: 1;

      &-suffix:not(empty) {
        margin-left: 0.4rem;
        height: 100%;
        overflow: hidden;
      }
    }
  }

  &--content {
    display: flex;
    width: 100%;
    padding: 0.8rem 1.2rem;
    background-color: inherit;
    border-radius: 0.2rem;
    resize: none;
    border: none;
    position: relative;

    &-outline {
      border: 0.1rem solid #a9a9a9;
      height: 4.8rem;
      box-sizing: border-box;
      background-color: white;

      @include mobile {
        height: 4rem;
      }

      &.active:not(.disabled):not(.readonly) {
        border: 0.1rem solid #7d42d2;
      }
    }

    &.disabled,
    &.readonly {
      background-color: #e7e7eb;
      cursor: default;
      color: #a9a9a9;

      .form-field--content-suffix,
      .select-value,
      .icon-date,
      .icon-mdi_calendar-edit {
        cursor: default;
        color: #a9a9a9;
      }
    }

    &-wrapper {
      flex: 1 auto;
      overflow: hidden;
      width: 100%;
      white-space: nowrap;
    }

    &-suffix:not(:empty),
    &-prefix:not(:empty) {
      height: 100%;
      font-size: 15px;
      width: auto;
      overflow: hidden;
      display: flex;
      align-items: center;
      padding: 0 0.6rem 0 0.2rem;
      z-index: 2;
    }

    &.large &-prefix:not(:empty) {
      margin-right: 1.5rem;
      max-width: 2.2rem;
    }

    &-suffix:not(:empty) {
      margin-left: 0.3rem;
      max-width: 2.5rem;
      cursor: pointer;
    }
  }

  &__area {
    min-height: 11.5rem;

    .form-field {
      &--content {
        height: 100%;
      }

      &--label {
        padding-top: 1.5rem;
        align-items: normal;
      }
    }
  }

  &--hint {
    color: $clr-light-grey;
    font-size: 1.2rem;

    &.bottom-hint {
      text-align: right;
      position: absolute;
      bottom: -2rem;
      left: 0;
      width: 100%;
    }
  }
}

.seazone-form-field {
  &--wrapper {
    .form-field--label {
      font-size: 1.4rem;
      line-height: 2rem;
      color: $seaz-text;
      margin-right: 7rem;
      @include tablet-big {
        margin-right: 2rem;
      }
      @include mobile {
        margin-bottom: 0.8rem;
      }
    }
  }
}

.invalid.form-field--wrapper,
.invalid.form-field__area {
  & .form-field {
    &--content:not(.disabled):not(.readonly) {
      border-color: red;
    }
  }
}

.wrapper-rounded {
  .form-field {
    &--content {
      border-radius: 0.4rem;
      border: 0.1rem solid $seaz-border;
      height: 4rem;
      background-color: $light-color;
      box-sizing: border-box;

      .form-field--content-wrapper {
        .seazone__form-input::placeholder,
        .seazone__form-input,
        .select-placeholder,
        .select-value {
          font-family: $base-font;
          font-size: 1.4rem;
          line-height: 1.6rem;
        }

        .select-value {
          width: 18rem;
          text-align: left;

          @include mobile {
            width: 100%;
          }
        }

        .seazone__form-input::placeholder {
          color: $seaz-border;
        }

        .seazone__form-input {
          background-color: inherit;
        }
      }

      &-wrapper {
        display: flex;
        align-items: center;
      }

      &.disabled {
        background-color: $clr-ashy-grey;
      }
    }

    &--label {
      width: 100%;
      font-size: 1.4rem;
      line-height: 2rem;
      margin-bottom: 0.2rem;
      display: flex;
      flex-direction: column;
      color: $seaz-text;
    }
  }

  &.form-field--wrapper__row {
    .form-field--label {
      justify-content: center;
      align-items: flex-start;
      margin-right: 2rem;

      @include mobile {
        margin: 0 0 0.8rem;
      }
    }

    &.form-field__area {
      .form-field--label {
        justify-content: flex-start;
      }
    }
  }
}

.location-autocomplete {
  display: flex;
  align-items: flex-end;
  width: 100%;

  .wrapper-rounded {
    .form-field {
      &--content {
        border-radius: 0.4rem 0 0 0.4rem;
      }
    }
  }
}

//Add to rounded location-autocomplete

.v3.location-autocomplete .seazone__form-search-icon {
  width: 5.2rem;
  height: 4rem;
  background-color: $clr-primary-and-accent;
  border-radius: 0 0.4rem 0.4rem 0;
}

//SELECT OPTION
.select-option {
  width: 100%;
  font:
    1.6rem/3.9rem 'Roboto',
    sans-serif;
  // padding: 0 2.4rem;
  text-align: left;
  text-decoration: none;
  max-width: 100%;
  position: relative;
  cursor: pointer;
  outline: none;
  display: flex;
  flex-direction: row;
  box-sizing: border-box;
  align-items: center;
  -webkit-tap-highlight-color: transparent;
  color: #4d4e67;
  white-space: normal;
  min-height: 4rem;

  //&.selected,
  //&:hover:not(.not-selectable) {
  //  background: #e7e7eb;
  //}

  &.hidden-option {
    display: none;
  }
}

.expense-status-option {
  &__container {
    display: flex;
    width: 100%;
  }

  &__img {
    height: 100%;
    max-width: 1.5rem;
    width: 100%;
  }

  &__value {
    padding-left: 0.5rem;
  }
}

.search .select-option,
.country-search .select-option {
  &:hover:not(.not-selectable) {
    background: inherit;
  }
}

.seazone__form-input {
  height: 100%;
  width: 100%;
  position: relative;
  font:
    1.6rem/2.8rem 'Roboto',
    sans-serif;
  box-sizing: border-box;
  border: none;

  &.ng-invalid.ng-dirty {
    border: none;
  }

  &::placeholder {
    font:
      1.6rem/2.8rem 'Roboto',
      sans-serif;
    color: #a9a9a9;
    @include mobile {
      font-size: 1.4rem;
      line-height: 2.2rem;
    }
  }

  &:-webkit-autofill,
  &:-webkit-autofill:hover,
  &:-webkit-autofill:focus,
  &:-webkit-autofill {
    //position: absolute !important;
    z-index: 1;
    top: 0;
    left: 0;
    //padding: 0.8rem 1.2rem;
  }

  &:disabled,
  &:read-only {
    background-color: #e7e7eb;
    cursor: default;
  }
}

.seazone__form-icon {
  opacity: 0.8;

  svg {
    fill: #351f73;
  }

  &:hover,
  &:active {
    opacity: 1;
  }

  &.hidden {
    visibility: hidden;
  }
}

.seazone__form-search-icon {
  width: 4.8rem;
  height: 4.8rem;
  background-color: $clr-primary-and-accent;
  cursor: pointer;
  flex-shrink: 0;
}

.select-option {
  display: flex;
  align-items: center;
  flex-grow: 1;
}

.scroll-viewport {
  width: 100%;
}

.container-fixed {
  overflow: auto;
}

// COUNTRY SELECT
app-country-select {
  display: block;
  width: 100%;
  min-width: 9.2rem;
}

.country-flag {
  width: 2.2rem;
  height: 1.2rem;

  img {
    width: 2.2rem;
    height: 1.2rem;
  }
}

.select-container {
  display: flex;

  .country-flag {
    margin-right: 1rem;
  }
}

// PHONE NUMBER SELECT

app-phone-number {
  width: 100%;

  &.ng-invalid.ng-dirty {
    .form__input {
      border-color: red;
    }

    .form__block-text {
      color: red;
    }
  }

  p {
    margin: 0;
  }

  .phone-control-wrapper {
    position: relative;
    width: 100%;
  }

  .phone-control-container {
    display: flex;
    flex-direction: row;
    width: 100%;
    align-items: center;
    border: 0.1rem solid #a9a9a9;

    &.active {
      border: 0.1rem solid #7d42d2;
    }

    .country-flag {
      width: 3.1rem;
      height: 2rem;
      cursor: pointer;

      img {
        width: 3.1rem;
        height: 2rem;
      }
    }

    .phone-input {
      flex: 1 auto;

      input {
        width: 100%;
        height: 100%;
        border: none;
        font-size: 1.6rem;
        line-height: 2rem;
        color: #4d4e67;
        background-color: initial;
        padding: 0;
        @media screen and (max-width: 480px) {
          font-size: 1.4rem;
        }
      }
    }

    &.disabled,
    &.readonly {
      cursor: default;
      background-color: #e7e7eb;

      input:read-only,
      input:disabled {
        background-color: inherit;
        cursor: default;
        color: #a9a9a9;
      }

      .phone-code-container {
        color: #a9a9a9;
      }

      .country-select-container,
      .country-select-container i {
        cursor: default;
      }
    }
  }

  .country-select-container {
    margin-right: 0.4rem;
    cursor: pointer;
  }

  .phone-code-container {
    font-size: 1.6rem;
    margin-right: 1rem;
    padding-left: 1.2rem;
    border-left: 0.1rem solid #a9a9a9;
    display: flex;
    line-height: 2rem;
    align-items: center;
    @media screen and (max-width: 480px) {
      font-size: 1.4rem;
    }
  }

  .required::after {
    color: red;
    content: '*';
  }

  .select-container {
    display: flex;
    height: 4rem;

    .country-flag {
      width: 3.1rem;
      height: 100%;
      display: flex;
      margin-right: 1rem;
      align-items: center;

      img {
        width: 3.1rem;
        height: 2rem;
      }
    }
  }

  .filter-input {
    position: relative;
    width: 100%;
    background: url('/assets/img/img_search.svg') 0.6rem 0.8rem no-repeat;
    padding-left: 4.3rem;
    box-sizing: border-box;
    border: none;
    border-bottom: 0.1rem solid #a9a9a9;
  }
}

app-select {
  min-width: auto;
}

// TODO: copied from seazone-form-field component styles
app-phone-number .wrapper-rounded {
  .form__block-text {
    color: #010202;
    width: 100%;
    font-size: 1.2rem;
    line-height: 1.4rem;
    padding-bottom: 0.2rem;
  }

  .form__input {
    height: 4rem;
    border-radius: 0.4rem;
    border: 0.1rem solid $seaz-border;
    background-color: $light-color;

    &.disabled {
      background-color: $seaz-bg;
    }
  }

  .country-flag {
    width: 2.2rem;
    height: 1.2rem;
    display: flex;
    box-sizing: border-box;

    img {
      width: 100%;
      height: 100%;
      border-radius: 0.4rem;
    }
  }

  .phone-code-container {
    font-size: 1.4rem;
    padding-left: 0;
    border: none;
    color: $seaz-border;
  }

  .phone-input input {
    font-size: 1.4rem;
  }
}

// TEXTAREA

app-seazone-text-area {
  display: flex;
  width: 100%;
  flex-direction: column;

  .offset {
    margin-top: 3rem;
  }

  .wrapper-rounded .form-field--content {
    height: 15rem;
  }

  .seaz-text-area--dynamic .wrapper-rounded .form-field--content {
    height: 100%;
  }

  textarea {
    background-color: inherit;

    &:focus {
      border: none;
    }
  }
}

.seazone__text-area {
  height: 100%;
  width: 100%;
  position: relative;
  font:
    1.6rem/2.8rem 'Roboto',
    sans-serif;
  box-sizing: border-box;
  border: none;

  &--dynamic {
    resize: none;
    overflow: hidden;
  }

  &::placeholder {
    font: 1.6rem/2.8rem $base-font;
    color: #a9a9a9;
    @include mobile {
      font-size: 1.4rem;
      line-height: 2.2rem;
    }
  }

  &:disabled,
  &:read-only {
    cursor: default;
  }

  &_counter {
    position: absolute;
    right: 0;
    top: -2rem;
  }
}

// CHECKBOX LIMITED
.checkbox-limited {
  .form-field--wrapper {
    display: block;
  }

  .form-field--content {
    flex-direction: column;
    padding: 0 0.8rem;
    margin-top: 1.9rem;
  }

  app-seazone-checkbox-item-alternate {
    &:not(:last-of-type) {
      margin-bottom: 2.2rem;
    }
  }

  .form-field--label {
    font-size: 1.4rem;
    line-height: 2rem;
    margin-bottom: 2.2rem;
  }

  .form__checkbox-label-text,
  .form-field--label {
    color: $seaz-text;
  }

  .count {
    margin-left: 1rem;
  }

  .form__checkbox-label:before {
    border: 0.2rem solid $button-main;
  }

  .form__checkbox-input:checked + label:before {
    background-color: $button-main;
  }

  @include mobile {
    .form__checkbox-label-text {
      font-size: 1.6rem;
    }
  }
}

// DATE-CONTROL
.mat-datepicker-content {
  .mat-calendar-body-label {
    vertical-align: bottom;
  }

  .mat-calendar-body-cell.mat-calendar-body-disabled {
    cursor: default;
  }
}

.mat-datepicker-content .wrapper-rounded {
  .mat-calendar-controls {
    justify-content: space-between;
  }

  .mat-calendar-previous-button {
    order: 1;
  }

  .mat-calendar-period-button {
    order: 2;
  }

  .mat-calendar-next-button {
    order: 3;
  }

  .mat-calendar-spacer {
    display: none;
  }

  .mat-calendar-table-header th {
    font-size: 1.2rem;
  }

  .mat-calendar-body-cell:not(.mat-calendar-body-disabled):hover
    > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical),
  .mat-calendar-body-cell:not(.mat-calendar-body-disabled) > .mat-calendar-body-selected {
    background-color: $seaz-primary-and-accent;
    color: white;
  }
}

.seazone-select-option,
.autocomplete-option {
  font:
    1.6rem/3.9rem 'Roboto',
    sans-serif;
  padding: 0 2.4rem;
  text-align: left;
  text-decoration: none;
  max-width: 100%;
  position: relative;
  outline: none;
  display: flex;
  flex-direction: row;
  box-sizing: border-box;
  align-items: center;
  -webkit-tap-highlight-color: transparent;
  color: #4d4e67;
  white-space: normal;
  min-height: 4rem;

  &.selected,
  &.active,
  &:hover {
    &:not([notselectable]):not(.disabled) {
      background: #e7e7eb;
    }
  }

  &:not(.disabled) {
    cursor: pointer;
  }

  &.hidden-option {
    display: none;
  }
}

.wrapper-small {
  .form-field--label {
    padding-top: 0;
  }

  .form-field--content {
    height: 11.6rem !important; // TODO: remove important
    .form-field--content-wrapper {
      .seazone__text-area {
        font:
          1.4rem / normal 'Roboto',
          sans-serif;
        @include custom-scroll-bar;
      }
    }
  }
}
