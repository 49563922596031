.calendar-selection-modal {
  &.seaz-modal.modal-small {
    width: 100%;
    max-width: 42.1rem;
  }

  .modal__wrap {
    padding: 1rem;
  }
}
