.user-info {
  width: 100%;
  background-image: url('/assets/img/bg_user_info.svg'),
    linear-gradient(0deg, rgba(53, 31, 111, 0.7) 0%, rgba(79, 25, 74, 0.7) 100%);
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
  background-color: #422c68;
  padding: 1.1rem 0;

  @include desktop {
    background-image: inherit;
    background-color: #351f73;

    &.show {
      & .user-info__menu {
        max-height: max-content;
      }
    }
  }
  &__header {
    width: 100%;
    display: block;
    // padding-bottom: 6.2rem;

    @include desktop {
      // padding-bottom: 5.4rem;
    }
  }
  .container {
    @media screen and (max-width: 1250px) {
      padding: 0 1rem;
    }
    @include laptop {
      padding: 0 1.6rem;
    }
  }
  &.show {
    .user-info__burger_line {
      transform: translateX(-50px);
      background: transparent;
    }
    .user-info__burger_line::before {
      transform: rotate(45deg) translate(35px, -35px);
    }
    .user-info__burger_line::after {
      transform: rotate(-45deg) translate(35px, 35px);
    }
  }
  &__wrap-content {
    display: flex;
    justify-content: space-between;
    align-items: center;
    @include laptop {
      justify-content: flex-end;
    }
  }
  &__avatar {
    margin-right: 1.6rem;
    border: none;
    @include tablet {
      margin-right: 0.8rem;
    }
    &_link {
      width: 4rem;
      height: 4rem;
      border-radius: 50%;
      overflow: hidden;
      display: flex;
      align-items: center;
      justify-content: center;
      border: 2px solid white;
      @include laptop {
        width: 3.2rem;
        height: 3.2rem;
      }
    }
    &_img {
      width: 3rem;
      height: 3rem;
      border-radius: 50%;
      @include laptop {
        width: 2.4rem;
        height: 2.4rem;
      }
    }
  }
  &__logo {
    height: 1.4rem;
  }
  &__wrap {
    display: flex;
    align-items: center;
    color: white;
  }
  &__menu {
    width: 83rem;
    margin: 0 auto;
    justify-content: center;
    @include desktop {
      padding-left: 0;
    }

    display: flex;
    @include desktop {
      max-height: 0;
      overflow: hidden;
      position: absolute;
      top: 4.3rem;
      left: 0;
      z-index: 10;
      padding-left: 0;
      background-color: $clr-purple;
      width: 100%;
      flex-direction: column;
    }
    &_item {
      display: flex;
      position: relative;
      padding: 0.9rem 0.7rem;
      margin: 0 1rem 0 0;

      @include desktop {
        margin: 0 1rem 1rem;
        flex-direction: column;
      }

      &.blocked {
        opacity: 0.3;
        pointer-events: none;
        .user-info__menu_link .menu__link-icon {
          opacity: 1;
        }
      }
      &.active-item {
        background: rgba(255, 255, 255, 0.15);
        border-radius: 8px;
      }
    }
    &_link {
      display: flex;
      align-items: center;
      color: $clr-white;
      .menu__link-icon {
        opacity: 0.5;
        margin-right: 1.1rem;
      }
    }
  }
  &__burger {
    display: none;
    position: relative;
    justify-content: center;
    align-items: center;
    width: 16px;
    height: 12px;
    cursor: pointer;
    transition: all 0.5s ease-in-out;
    margin-right: 2rem;
    @include desktop {
      display: flex;
    }

    &_line {
      width: 1.6rem;
      height: 0.2rem;
      background-color: $clr-white;
      transition: all 0.5s ease-in-out;
      &::before,
      &::after {
        content: '';
        position: absolute;
        width: 1.6rem;
        height: 0.2rem;
        background-color: $clr-white;
        transition: all 0.5s ease-in-out;
      }
      &::before {
        transform: translateY(-6px);
        left: 0;
      }
      &::after {
        transform: translateY(6px);
        left: 0;
      }
    }
  }
  &__route {
    display: none;
    color: $clr-white;

    @include desktop {
      display: block;
      margin: 0 0 0 auto;
    }
    @include mobile {
      text-align: end;
    }
  }
  .opened .top-navigation__sub-menu {
    opacity: 1;
    visibility: visible;
    transform: translateY(3%);
    transition: all 0.3s ease-in;
    @media screen and (max-width: 1200px) {
      max-height: 500px;
      overflow: auto;
    }
  }

  .menu__link.active-item {
    opacity: 0.5;
  }

  .top-navigation__sub-menu {
    bottom: -4rem;

    &::before {
      content: '';
      border: 7px solid transparent;
      border-bottom: 6px solid $clr-white;
      position: absolute;
      top: -1.2rem;
      left: 33px;

      @media screen and (max-width: 1770px) {
        left: auto;
        right: 33px;
      }

      @include desktop {
        display: none;
      }
    }

    .menu__link-text {
      margin-left: 1rem;
    }

    @include desktop {
      bottom: 0;
      padding: 0.9rem 0 0;
    }

    @media screen and (max-width: 1770px) {
      right: 0;
    }
  }
}

#supplier-header {
  @include desktop {
    .user-info__header .user-info__menu {
      top: 2.7rem;
    }
  }
}

.supplier-nuv-menu {
  .top-navigation__sub-menu {
    bottom: inherit;
  }
}
