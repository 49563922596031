@import '../../../app/shared/modules/seazone-calendar/modules/month/calendar-month-view';
@import '../../../app/shared/modules/seazone-calendar/modules/week/calendar-week-view';
@import '../../../app/shared/modules/seazone-calendar/modules/day/calendar-day-view';
@import '../../../app/shared/modules/seazone-calendar/modules/common/calendar-tooltip';

@mixin cal-theme($overrides) {
  @include cal-month-view-theme($overrides);
  @include cal-week-view-theme($overrides);
  @include cal-tooltip-theme($overrides);
}

.calendar {
  &-month-view {
    display: flex;
    flex-direction: column;
  }

  &-header {
    display: flex;
    flex-direction: row;
    align-items: stretch;

    .calendar-cell {
      display: flex;
      flex: 1;
      overflow: hidden;
      border: 1px solid;
      font-size: 1.6rem;
      color: $clr-light-grey;
      padding: 0.4rem;

      &:first-child {
        padding-left: 0;
      }

      &:last-child {
        padding-right: 0;
      }
    }
  }

  &-days {
    .calendar-cell-row {
      display: flex;
      flex-direction: row;
      align-items: stretch;

      .calendar-day-cell {
        position: relative;
        display: flex;
        flex: 1;
        flex-direction: column;
        //overflow: hidden;
        font-size: 1.6rem;
        color: $clr-light-grey;
        padding: 0.4rem;

        &:first-child {
          padding-left: 0;
        }

        &:last-child {
          padding-right: 0;
        }

        &.calendar-open {
          .calendar-cell {
            background-color: $clr-white;
            border-color: $seaz-primary-and-accent;
          }
        }
      }
    }
  }

  &-cell {
    min-height: 11.5rem;
    border-radius: 0.8rem;
    background-color: $clr-ashy-grey;
    position: relative;
    padding: 0.7rem 0.9rem;
    border: 5px solid transparent;
    font-size: 2rem;
    color: $seaz-text;
    transition:
      background-color 0.2s ease-in,
      border-color 0.2s ease-in;
  }

  &-cell-top {
    flex: 1;
    font-weight: 500;
    font-size: 1.6rem;
    color: $profile-month;
    padding: 0.8rem 0.4rem;
  }

  &-out-month {
    opacity: 0.3;
  }

  &-in-month {
    .calendar-cell {
      cursor: pointer;
    }
  }

  &-today {
    .calendar-cell {
      outline: 1px solid #c2bed4;
    }
  }

  &-events {
    position: absolute;
    display: flex;
    width: 100%;
    left: 0;
    align-items: center;
    justify-content: space-between;

    &.calendar-date {
      top: 1rem;
      height: 1.4rem;
      font-size: 12px;
      align-items: center;
      line-height: 1.4rem;
      display: flex;
      justify-content: center;
      font-weight: 700;
      cursor: pointer;
    }

    &.calendar-charter-events {
      bottom: 0;
      height: 4.2rem;
    }

    &.calendar-shipyard-events {
      bottom: 4.4rem;
      height: 2rem;
    }

    &.calendar-custom-events {
      bottom: 3rem;
      height: 2rem;
    }
  }

  &-event {
    width: 0.8rem;
    min-width: 0.8rem;
    height: 0.8rem;

    &.full-day-event {
      width: 100%;
      border-radius: initial;
    }
  }

  &-event {
    &.charter-type-owners-charter {
      background-color: $clr-green;
    }

    &.charter-type-all-inclusive {
      background-color: $clr-blue;
    }

    &.charter-type-charter {
      background-color: $seaz-primary-and-accent;
    }

    &.charter-type-trip {
      background-color: $seaz-edit-hover;
    }

    &.delivery {
      background-color: $seaz-delivery;
    }

    &.shipyard {
      background-color: $seaz-warning;
    }

    &.custom {
      background-color: $seaz-custom;
      display: block;
    }
  }

  &-one-time-events {
    display: flex;
    flex-direction: row;
    margin: 0 auto;
    justify-content: center;
    position: relative;
    width: 100%;

    &.full {
      max-width: 85%;
    }

    &.half {
      max-width: 55%;
    }

    &.small {
      max-width: 55%;
    }

    &-container {
      margin: 0 0.4rem 0 0.6rem;
      display: flex;
      align-items: center;
      overflow: hidden;
    }

    .calendar-event {
      border-radius: 0.8rem 0.8rem;
      margin-right: 0.2rem;

      &.hide {
        visibility: hidden;
      }

      &.more-events {
        visibility: visible;
        min-width: 2rem;
        height: 2rem;
        border-radius: 4rem;
        color: #010202;
        font-weight: 500;
        font-size: 1.2rem;
        text-align: center;
        line-height: 2rem;
        letter-spacing: -1px;
        position: relative;
        padding-left: 0.5rem;
        background-color: #e0e0e0;

        &::before {
          content: '+';
          position: absolute;
          left: 0.3rem;
          top: -0.1rem;
        }
      }
    }
  }

  &-first-event,
  &-last-event {
    width: 100%;

    .calendar-event {
      width: 100%;
    }
  }

  &-first-event {
    .calendar-event {
      border-radius: 0 0.8rem 0.8rem 0;
    }
  }

  &-last-event {
    margin-left: auto;
    .calendar-event {
      border-radius: 0.8rem 0 0 0.8rem;
    }
  }

  &-one-day-events {
    max-width: 75%;
    width: 100%;
    margin: 0 auto;
    .calendar-event {
      width: 100%;
      border-radius: 0.8rem;
    }
  }

  &-full-day-event {
    width: 100%;

    .calendar-event {
      width: 100%;
      border-radius: initial;
    }
  }

  @media screen and (max-width: 768px) {
    &-days {
      .calendar-cell-row .calendar-day-cell {
        padding: 0.1rem;
      }
    }
    &-cell {
      min-height: 6rem;
      border-width: 3px;
      padding: 0.5rem;
    }
    &-events {
      &.calendar-charter-events {
        bottom: 0;
        height: 2rem;
      }

      &.calendar-shipyard-events {
        bottom: 2rem;
        height: 1rem;
      }

      &.calendar-custom-events {
        bottom: 1.4rem;
        height: 1rem;
      }
    }
    &-event {
      width: 0.4rem;
      min-width: 0.4rem;
      height: 0.4rem;
    }
    &-one-time-events {
      .calendar-event {
        &.more-events {
          min-width: 100%;
          font-size: 1.2rem;
          background-color: transparent;
          padding-left: 0;

          &::before {
            content: none;
          }
        }
      }
    }

    &-first-event {
      .calendar-event {
        border-radius: 0 0.4rem 0.4rem 0;
      }
    }
    &-last-event {
      .calendar-event {
        border-radius: 0.4rem 0 0 0.4rem;
      }
    }
  }
}

.calendar-charter-events {
  .calendar-first-event,
  .calendar-last-event {
    max-width: 30%;
    @media screen and (max-width: 768px) {
      max-width: 25%;
    }
    &.half {
      max-width: 40%;
    }
  }
  &.single-event {
    .calendar-first-event,
    .calendar-last-event {
      max-width: 87.5%;
    }
  }
}

.calendar-shipyard-events,
.calendar-custom-events {
  .calendar-first-event,
  .calendar-last-event {
    max-width: 87.5%;
  }
}
