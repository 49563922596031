.boat-maintenance-work-list-form-modal {
  &__row {
    align-items: center;
    margin-bottom: 4rem;
  }

  .form-field--wrapper__row .form-field--label {
    max-width: 10rem;
  }

  .plug-label {
    width: 10rem;
    min-width: 10rem;
  }

  @include mobile {
    .plug-label {
      margin-bottom: 0.8rem;
    }
  }
}

.boat-maintenance-work-list-form-modal-header {
  display: flex;
  margin-bottom: 3rem;
}

.boat-maintenance-work-list-status-change {
  margin-left: 1.6rem;
}

.boat-maintenance-work-list-form-modal-date-range {
  @include d-flex-space-between;
  align-items: center;

  &__col {
    @include d-align-items-center;

    @include indent-not-last-of-child(0 1.6rem 0 0);
  }

  &__label {
    font-size: $base-font-size;
    line-height: 1.6rem;
    color: $clr-light-grey;
    margin-right: 1.6rem;

    &:first-letter {
      text-transform: lowercase;
    }
  }
}

.boat-maintenance-work-list-form-modal-text-area {
  .form-field__area {
    min-height: 8rem;
  }

  .wrapper-rounded .form-field--label {
    flex-direction: row;
    justify-content: flex-start;
  }

  .wrapper-rounded .form-field--content {
    height: 8rem;
  }
}

.boat-maintenance-work-list-form-modal-footer {
  @include d-flex-space-between;
  align-items: center;
  @include border-top;
  padding: 3rem 0 1rem 0;
  margin-top: 4.6rem;

  &__action {
    @include d-align-items-center;
    justify-content: flex-end;
    width: 100%;
  }

  &__btn {
    padding: 1.22rem 2rem;

    @include indent-not-last-of-child(0 1.2rem 0 0);
  }
}
