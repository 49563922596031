.referrals-list {
  &__title {
    color: $seaz-mulberry;
  }
  &__counter {
    color: $seaz-border;
  }
}

.referrals-list-table {
  margin-top: 2.4rem;

  .table {
    width: 100%;
  }

  th,
  td {
    text-align: left;
  }

  th {
    min-width: 22rem;
    font-size: 1.2rem;
    line-height: 1.4;
    color: $seaz-document-count;
    padding: 0 0.8rem 1.2rem;
  }

  td {
    line-height: 1.6rem;
    color: $seaz-text;
  }

  .table-content:nth-of-type(2n) {
    td {
      background-color: $seaz-bg;
      padding: 0.8rem;

      &:first-of-type {
        border-radius: 1.2rem 0 0 1.2rem;
      }

      &:last-of-type {
        border-radius: 0 1.2rem 1.2rem 0;
      }
    }
  }

  .table-content:nth-of-type(2n + 1) {
    td {
      padding: 2rem 0.8rem;
    }
  }
}
