.business-card-list {
  width: 100%;
  max-width: 127rem;
  margin: 0 auto;
  padding: 4rem 1rem 11.5rem;

  &__header {
    @include d-flex-space-between;
    align-items: center;
  }

  &__title {
    font-size: 3rem;
    font-weight: 500;
    line-height: 145%;
    text-transform: uppercase;
    color: $seaz-text;
    margin-right: 1rem;
  }

  &__button {
    padding: 0.9rem 1.3rem;
  }

  &__scroll-wrap {
    position: relative;
  }

  &__wrap {
    overflow-x: auto;
  }

  &__container {
    width: 125rem;
    margin-top: 1.4rem;
    padding: 2.4rem;
  }

  //@include laptop {
  //  &__container {
  //    width: 125rem;
  //  }
  //}

  @include tablet {
    &__title {
      font-size: 2.2rem;
    }
  }

  @media screen and (max-width: 1270px) {
    &__container {
      margin-top: 5rem;
    }
  }
}

.business-card-item-box {
  display: block;

  &:not(:first-of-type) {
    margin-top: 4rem;
  }
}

.business-card-item {
  @include d-align-items-center;
  align-items: center;
  padding: 2.4rem;
  border-radius: 16px;

  &:nth-of-type(2n) {
    background-color: $seaz-bg;
  }

  &__actions {
    flex-shrink: 0;
    @include d-align-items-center;
    justify-content: flex-end;
  }

  &__title {
    font-size: 1rem;
    line-height: 1.4rem;
    letter-spacing: 0.05rem;
    text-transform: uppercase;
    word-break: break-word;
    color: #828282;
    margin-bottom: 0.4rem;
  }

  &__text {
    font-size: 1.6rem;
    font-weight: 500;
    line-height: 1.9rem;
    word-break: break-word;
    color: $clr-primary-and-accent;
  }

  &__container {
    @include d-align-items-center;
    align-items: center;
    width: 100%;
  }

  &__contact-name,
  &__position,
  &__phone {
    width: 19.3rem;
    margin-right: 3.2rem;
  }

  &__email {
    width: 20rem;
    margin-right: 3.3rem;
  }

  &__btn-view {
    margin-right: 3.4rem;
    transition: transform 0.3s;

    &:hover {
      transform: translateY(-0.5rem);
    }
  }
}
