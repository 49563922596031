.footer {
  position: relative;
  padding: 1.6rem 0 0;
  background-image: url('/assets/img/bg_footer.jpg');
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center center;
  &__wrap-logo {
    width: 100%;
    margin-bottom: 4rem;
    display: flex;
    align-items: center;
    justify-content: space-between;
    @include mobile {
      flex-direction: column;
      .footer__social {
        margin-top: 3rem;
      }
    }
  }

  &__logo {
    display: inline-flex;
  }

  &__form-block {
    margin-right: 2rem;
    @include mobile {
      margin: 0 0 1.2rem 0;
    }
  }

  &__nav {
    display: flex;
    @include desktop {
      flex-grow: 1;
    }
    @include tablet-big {
      justify-content: flex-start;
    }
    @include mobile {
      justify-content: space-between;
    }
    &-list {
      margin-right: 7rem;
      @include desktop {
        flex-grow: 1;
        margin-right: 0;
      }
      @include tablet-big {
        flex-grow: 0;
        margin-right: 7rem;
      }
      @include mobile {
        margin-right: 0;
      }
      &:last-child {
        margin-right: 0;
      }
    }

    &-link {
      display: inline-block;
      color: #fafafb;
      line-height: 2.5rem;
      transition: $tr_03;
      &:not(.disabled):hover {
        color: #55b5b3;
      }
      &.disabled {
        cursor: default;
      }
    }
  }

  &__link {
    display: inline-block;
    line-height: 2.8rem;
    color: #fafafb;
    text-align: right;
    transition: $tr_03;
    margin-right: 2.4rem;
    &:hover {
      color: #55b5b3;
    }
    @include mobile {
      line-height: 1.8rem;
      margin-right: 0;
      margin-bottom: 1.2rem;
    }
    &_box {
      display: flex;
      align-items: center;
      @include mobile {
        flex-direction: column;
      }
    }
  }

  &__top {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    padding-bottom: 1.9rem;
    border-bottom: 0.1rem solid #ffffff36;
    @include tablet-big {
      flex-direction: column;
      border-color: #fafafb4d;
    }
    @include mobile {
      padding-bottom: 1.4rem;
    }
    &-left {
      display: flex;
      margin-top: 2rem;
      @include desktop {
        flex-grow: 1;
      }
      @include tablet-big {
        margin-bottom: 2rem;
      }
    }

    &-right {
      width: 100%;
      display: flex;
      max-width: 77.6rem;
      margin-top: 2rem;
      @include tablet-big {
        max-width: 100%;
        flex-direction: column;
        padding-top: 2rem;
        border-top: 1px solid #fafafb4d;
      }
      @include mobile {
        .form__row {
          flex-wrap: wrap;
        }
        .form__btns {
          width: 100%;
        }
      }
    }
  }

  &__bottom {
    display: flex;
    align-items: center;
    padding: 1.3rem 0 1.1rem;
    justify-content: space-between;
    @include mobile {
      flex-direction: column;
    }
  }

  &__h2 {
    color: #e0e0e0;
    margin-bottom: 0.4rem;
  }

  &__text {
    color: #fafafb;
    margin-bottom: 2.1rem;
    line-height: 2.2rem;
    width: 85%;
    text-align: end;
    padding-right: 2.4rem;
    @include tablet-big {
      width: 100%;
      padding: 0;
      text-align: center;
    }
  }

  &__social {
    display: flex;
    align-items: center;
  }

  &__social-link {
    display: inline-flex;
    margin-right: 3.2rem;
    color: #fff;
    font-size: 2.4rem;
    padding: 0.2rem 0;
    &.icon-twitter {
      &:hover {
        color: #1fa1ff;
      }
    }
    &.icon-facebook {
      &:hover {
        color: #525db9;
      }
    }
    &.icon-linkedin {
      &:hover {
        color: #0e76a8;
      }
    }
    &.icon-instagram {
      width: 2.4rem;
      height: 2.4rem;
      background-image: url('/assets/img/instagram.svg');
      cursor: pointer;
      &:hover {
        background-image: url('/assets/img/instagram-hover.svg');
      }
    }
    &.icon-youtube {
      &:hover {
        color: rgb(255, 0, 0);
      }
    }

    &:last-child {
      margin-right: 0;
    }
  }

  &__social-icon {
    font-size: 2.4rem;
    color: white;
  }

  &__copyright {
    font-size: 1.2rem;
    color: #ffffff;
    opacity: 0.3;
    @include mobile {
      order: 1;
      margin-top: 1.2rem;
    }
  }

  &__wrap {
    margin-top: 2.4rem;
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    @include mobile {
      margin-top: 1.4rem;
    }
  }
}
