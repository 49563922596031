//$font-path : './assets/fonts/';
$font-path: '/assets/fonts/';

@font-face {
  font-display: swap;
  font-family: 'fontello';
  src:
    url('#{$font-path}fontello/font/fontello.woff') format('woff'),
    url('#{$font-path}fontello/font/fontello.woff2') format('woff2');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-display: swap;
  font-family: 'Roboto';
  src:
    url('#{$font-path}Roboto/Roboto-Regular.woff') format('woff'),
    url('#{$font-path}Roboto/Roboto-Regular.woff2') format('woff2');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-display: swap;
  font-family: 'Roboto';
  src:
    url('#{$font-path}Roboto/Roboto-Medium.woff') format('woff'),
    url('#{$font-path}Roboto/Roboto-Medium.woff2') format('woff2');
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-display: swap;
  font-family: 'Roboto';
  src:
    url('#{$font-path}Roboto/Roboto-Bold.woff') format('woff'),
    url('#{$font-path}Roboto/Roboto-Bold.woff2') format('woff2');
  font-weight: bold;
  font-style: normal;
}

@font-face {
  font-display: swap;
  font-family: 'Roboto';
  src:
    url('#{$font-path}Roboto/Roboto-Black.woff') format('woff'),
    url('#{$font-path}Roboto/Roboto-Black.woff2') format('woff2');
  font-weight: 900;
  font-style: normal;
}

@font-face {
  font-display: swap;
  font-family: 'Google Sans';
  src:
    url('#{$font-path}GoogleSans/GoogleSans-Bold.woff') format('woff'),
    url('#{$font-path}GoogleSans/GoogleSans-Bold.woff2') format('woff2');
  font-weight: bold;
  font-style: normal;
}

@font-face {
  font-display: swap;
  font-family: 'Helvetica';
  src:
    url('#{$font-path}Helvetica/Helvetica-light.woff2') format('woff2'),
    url('#{$font-path}Helvetica/Helvetica-light.woff') format('woff');
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-display: swap;
  font-family: 'Helvetica';
  src:
    url('#{$font-path}Helvetica/Helvetica-light-italic.woff2') format('woff2'),
    url('#{$font-path}Helvetica/Helvetica-light-italic.woff') format('woff');
  font-weight: 300;
  font-style: italic;
}

@font-face {
  font-display: swap;
  font-family: 'Helvetica';
  src:
    url('#{$font-path}Helvetica/Helvetica-roman.woff2') format('woff2'),
    url('#{$font-path}Helvetica/Helvetica-roman.woff') format('woff');
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-display: swap;
  font-family: 'Helvetica';
  src:
    url('#{$font-path}Helvetica/Helvetica-roman-italic.woff2') format('woff2'),
    url('#{$font-path}Helvetica/Helvetica-roman-italic.woff') format('woff');
  font-weight: 400;
  font-style: italic;
}

@font-face {
  font-display: swap;
  font-family: 'Helvetica';
  src:
    url('#{$font-path}Helvetica/Helvetica-medium.woff2') format('woff2'),
    url('#{$font-path}Helvetica/Helvetica-medium.woff') format('woff');
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-display: swap;
  font-family: 'Helvetica';
  src:
    url('#{$font-path}Helvetica/Helvetica-bold.woff2') format('woff2'),
    url('#{$font-path}Helvetica/Helvetica-bold.woff') format('woff');
  font-weight: 600;
  font-style: normal;
}

@font-face {
  font-display: swap;
  font-family: 'Helvetica';
  src:
    url('#{$font-path}Helvetica/Helvetica-bold-italic.woff2') format('woff2'),
    url('#{$font-path}Helvetica/Helvetica-bold-italic.woff') format('woff');
  font-weight: 600;
  font-style: italic;
}
