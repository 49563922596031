.document-access {
  .info-card-alert {
    cursor: default;
  }

  .fleet-job-offers-logo__text {
    margin-bottom: 0;
  }

  app-toggle-checkbox {
    margin-left: 1.2rem;
  }
}

.document-access-img {
  display: block;
  width: 5.6rem;
  height: 5.6rem;
  object-fit: cover;
  border-radius: 0.8rem;
  flex-shrink: 0;
}

.document-access-footer {
  @include footer-with-border-left-btn(0 0 1rem, 3rem 0 0);
  border: none;
}
