.seaz-company__auth {
  &_title {
    color: $clr-white;
    font-size: 5.6rem;
    font-weight: 300;
    text-align: center;
    display: block;
    width: 52rem;
    margin: 6.4rem auto 0;
    line-height: 6.7rem;
    @include laptop {
      width: 80%;
    }
    @media screen and (max-width: 900px) {
      margin: 2.4rem auto 0;
      font-size: 3.6rem;
      line-height: 4.7rem;
    }
  }
  .login__wrap_right {
    position: relative;
    padding: 6.4rem 3rem;
    @include mobile {
      padding: 2.2rem 1.5rem 3rem;
    }
  }
  .seaz__return_btn {
    position: absolute;
    top: 7rem;
    @include mobile {
      top: 3rem;
      width: 3rem;
      height: 3rem;
    }
  }
  .form__desc {
    padding-top: 0.6rem;
  }
  .login__copyright {
    p {
      text-align: center;
    }
    a {
      color: $clr-primary-and-accent;
    }
  }
  .company__col_left {
    max-height: 107.8rem;
    position: fixed;
    top: 0;
    bottom: 0;
    height: 100%;
    width: inherit;
    object-fit: cover;
    @media screen and (max-width: 800px) {
      display: none;
    }
  }
  &.login .company__col_left {
    background-repeat: no-repeat;
    background-size: cover;
  }
  .login__col {
    @media screen and (max-width: 800px) {
      width: 100%;
    }
  }
  .login__logo-img {
    img {
      width: 12.9rem;
      height: 12.9rem;
      @include mobile {
        width: 4.6rem;
        height: 4.6rem;
      }
    }
  }
  .seaz-company__logo {
    padding-top: 9.8rem;
    @include mobile {
      padding-top: 0;
    }
    img {
      width: 12.9rem;
      height: 12.9rem;
      @include mobile {
        width: 4.6rem;
        height: 4.6rem;
      }
    }
  }
  .seaz-company__logo_text {
    padding-top: 3.7rem;
    img {
      width: 33.4rem;
    }
  }
  .login__title {
    font-weight: 500;
  }
  app-seazone-media-uploader {
    width: 100%;
  }
  .seaz-uploader {
    align-items: flex-start;
  }
  .seaz-uploader__choose_drop {
    width: 100%;
  }
  .seaz-uploader__header,
  .seaz-uploader__choose_text {
    text-align: left;
  }
  &_left {
    width: 50%;
    height: 100vh;
    @media screen and (max-width: 800px) {
      display: none;
    }
  }
  &_error {
    &_img {
      width: 9.4rem;
      height: 7rem;
      margin-bottom: 4rem;
    }
    &_text {
      font-weight: 500;
      color: $seaz-about-text;
      font-size: 1.6rem;
      line-height: 2rem;
      margin-bottom: 7rem;
    }
  }
  &_log {
    color: $seaz-about-text;
    margin-right: 0.9rem;
  }
  &_note {
    margin: 2.4rem 0;
    padding: 1rem 1.2rem;
    width: 100%;
    background-color: #fdefc8;
    border: 1px solid #f7c948;
    border-radius: 0.2rem;
    color: #94792b;
    font-size: 1.2rem;
    line-height: 1.4rem;
    text-align: left;
  }
  .select-container {
    text-align: left;
  }
}
.seaz-company {
  &__welcome {
    &_title {
      margin-bottom: 4.8rem;
      font-size: 2.4rem;
    }
    &_text {
      color: $seaz-about-text;
      font-size: 1.6rem;
      padding-bottom: 11.4rem;
      text-align: left;
      line-height: 2.6rem;
      &--green {
        color: $seaz-about-text;
        font-size: 1.6rem;
        text-align: left;
        line-height: 2.6rem;
        padding-bottom: 3.2rem;
        span {
          font-weight: 600;
        }
      }
    }
    &_btn {
      width: 100%;
      margin-top: 3.3rem;
    }
  }
  &__registration {
    &_title {
      font-size: 2.4rem;
      line-height: 2.8rem;
      margin-bottom: 2.4rem;
    }
    &_text {
      padding-bottom: 3rem;
      color: $clr-primary-and-accent;
      font-size: 1.2rem;
    }
    &_btn {
      width: 100%;
    }
    &_success {
      &_img {
        margin-top: 5rem;
        width: 12.8rem;
      }
      &_support {
        padding-top: 2.7rem;
        color: $disabled-link;
        display: flex;
        font-size: 1.2rem;
        text-align: center;
        a {
          margin-left: 0.4rem;
          color: $seaz-edit-hover;
        }
      }
    }
  }
  &__inbox {
    &_text {
      opacity: 1;
      font-size: 1.2rem;
      text-align: center;
      margin-bottom: 2.4rem;
    }
  }
  &__header {
    &_logo {
      height: 4.6rem;
      @include desktop {
        height: 3.2rem;
      }
      img {
        width: auto;
        height: 4.6rem;
        object-fit: cover;
        max-width: 15rem;
        @include desktop {
          height: 3.2rem;
        }
      }
      &--purple {
        .seaz-company__header_logo_text {
          color: #c2bed4;
          border: 1px solid #c2bed4;
        }
      }
      &_text {
        padding: 1.3rem 0;
        text-align: center;
        font-size: 2.4rem;
        font-weight: 500;
        text-transform: capitalize;
        cursor: pointer;
        width: 9.6rem;
        border-radius: 0.2rem;
        display: block;
        position: absolute;

        @include desktop {
          padding: 0.6rem 0;
          width: 7.6rem;
        }
        &--green {
          color: $clr-primary-and-accent;
          border: 1px solid $clr-primary-and-accent;
          position: inherit;
        }
      }
    }
  }
  &__logo {
    &_text {
      font-family: $base-font;
      background-color: $clr-white;
      border-radius: 0.4rem;
      box-shadow: (0px 0px 12px rgba(53, 31, 115, 0.22));
      padding: 1.2rem;
      z-index: 100;
      &--purple {
        top: 5.4rem;
        left: -4rem;
      }
      &--green {
        top: 5rem;
        left: 4rem;
      }
      &_title {
        font-weight: 500;
        color: $seaz-text;
        padding-bottom: 0.4rem;
        letter-spacing: 0.01em;
        font-size: 1.2rem;
      }
      &_info {
        font-weight: 500;
        color: $seaz-document-count;
        padding-bottom: 0.4rem;
        letter-spacing: 0.01em;
        font-size: 1.2rem;
        margin-right: 0.4rem;
      }
      &_row {
        display: block;
      }
    }
    &_boat {
      width: 4.8rem;
      height: 4.8rem;
      border-radius: 8px;
      box-shadow: 0 4px 8px rgba(53, 40, 113, 0.26);
      background-color: $clr-white;
      position: absolute;
      display: flex;
      align-items: center;
      justify-content: center;
      font-weight: 500;
      font-size: 2rem;
      color: $clr-primary-and-accent;
      text-transform: capitalize;
      top: 5rem;
      @include mobile {
        font-size: 1.4rem;
        width: 3rem;
        height: 3rem;
        top: 3rem;
      }
      img {
        width: 4.8rem;
        height: 4.8rem;
        object-fit: cover;
        border-radius: 8px;
        @include mobile {
          width: 3rem;
          height: 3rem;
        }
      }
      &_info {
        bottom: 3.4rem;
        z-index: 100;
        .seaz-company__logo_text::before {
          transform: rotate(180deg);
          bottom: -2.2rem;
          top: auto;
          border: 10px solid transparent;
          border-bottom: 13px solid #ffffff;
          left: 46%;
        }
      }
    }
  }

  &__offer_logo {
    width: 10.4rem;
    background-color: $clr-white;
    box-shadow: 0 4px 8px rgba(53, 40, 113, 0.26);
    border-radius: 8px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 2.4rem;
    font-weight: 500;
    text-transform: capitalize;
    color: $clr-primary-and-accent;
    position: absolute;
    bottom: 0;
    right: 0;
    left: 0;
    margin: auto;
    flex-direction: column;
    height: 5.5rem;
    max-height: 7.7rem;

    @media screen and (max-width: 1440px) {
      width: 9.4rem;
      height: 4.5rem;
    }
    @media screen and (max-width: 1300px) {
      width: 10.4rem;
      height: 5.5rem;
    }

    @media screen and (max-width: 600px) {
      //top: 7rem;
      width: 9.4rem;
      height: 4.5rem;
    }
    &_agency {
      color: $clr-white;
      text-transform: uppercase;
      font-size: 1.2rem;
      padding: 0.5rem;
      background-color: $clr-green;
      border-radius: 0 0 8px 8px;
      text-align: center;
      width: 100%;
      position: absolute;
      bottom: -22px;
      @media screen and (max-width: 1440px) {
        padding: 0.5rem 0;
      }
    }
    img {
      height: 5.5rem;
      object-fit: cover;
      width: 10.4rem;
      border-radius: 8px;
      @media screen and (max-width: 1440px) {
        width: 9.4rem;
        height: 4.5rem;
      }
      @media screen and (max-width: 1300px) {
        width: 10.4rem;
        height: 5.5rem;
      }
      @media screen and (max-width: 600px) {
        width: 9.4rem;
        height: 4.5rem;
      }
    }
    &_box {
      top: 7rem;
      right: 0.8rem;
      z-index: 100;
      @media screen and (max-width: 1720px) {
        top: 3rem;
        right: -0.2rem;
      }
      @media screen and (max-width: 1440px) {
        top: -1rem;
        right: -3.2rem;
      }
      @media screen and (max-width: 1300px) {
        top: 7rem;
        right: 0.8rem;
      }
      @media screen and (max-width: 600px) {
        top: -1rem;
        right: -4.2rem;
      }
      .seaz-company__logo_text::before {
        border: 10px solid transparent;
        border-bottom: 15px solid #ffffff;
        transform: rotate(180deg);
        bottom: -2.5rem;
        top: auto;
        left: 44%;
      }
    }
  }
  &__settings {
    position: relative;
    width: 126.4rem;
    margin: 0 auto;
    @include large {
      width: 100rem;
    }
    @include laptop {
      width: 100%;
      padding: 0 2.4rem;
    }
    .user-note {
      @include mobile {
        padding-bottom: 2rem;
      }
    }
    .info-label.light-purple {
      margin-bottom: 2rem;
    }
    .seaz__return_btn {
      position: absolute;
      top: 2rem;
      @include mobile {
        width: 3rem;
        height: 3rem;
      }
    }
    &_title {
      text-align: center;
      color: $clr-primary-and-accent;
      text-transform: uppercase;
      padding: 5.8rem 0;
    }
    &_save {
      margin-bottom: 5.4rem;
      background: rgba(53, 31, 115, 0.08);
      padding: 1.3rem 1.2rem;
      font-weight: 500;
      font-size: 1.4rem;
      color: $clr-primary-and-accent;
    }
    &_form {
      width: 59rem;
      @include tablet {
        width: 100%;
      }
      .form__row {
        app-seazone-select .form-field--wrapper,
        .form__block,
        .form-field--wrapper {
          flex-direction: row;
          align-items: center;
          @media screen and (max-width: 500px) {
            flex-direction: column;
            align-items: flex-start;
          }
          .form-field--label,
          .form__block-text {
            width: 17rem;
            margin-right: 2rem;
            color: $seaz-text;
            flex-shrink: 0;
            @media screen and (max-width: 500px) {
              width: 100%;
              margin-right: 0;
              margin-bottom: 1rem;
            }
          }
          .form__block-text.form__block-text__select {
            width: auto;
          }
          .phone-control-container {
            background-color: $clr-white;
          }
          .phone-control-wrapper {
            width: 100%;
          }
        }
      }
      .yaht-edit__upload_img_box {
        margin-top: 0.9rem;
      }
      .seaz-uploader__video_row .yaht-edit__upload_img_box .seaz-uploader__video_btn {
        right: auto;
        top: -24px;
        left: -8px;
      }
      app-seazone-media-uploader {
        width: 100%;
        .seaz-uploader__choose_drop {
          width: 100%;
        }
      }
    }
    .seaz-crew__btn_box {
      margin-top: 10rem;
      @include tablet {
        width: 100%;
      }
      @include mobile {
        flex-wrap: wrap;
        &.form__row button {
          width: 41%;
          margin-bottom: 1rem;
        }
        .seaz-crew__btn {
          &:nth-of-type(2n) {
            margin-right: 0;
          }
        }
      }
    }
    .user-note {
      margin-right: auto;
    }
  }
}
.user-info__wrap-content {
  position: relative;
}
.company-modal {
  .modal-content__controls {
    padding-top: 3rem;
    border-top: 1px solid $seaz-head-arrow;
  }
  .main-btn.main-btn_white-green,
  .main-btn.main-btn_green {
    border-radius: 0;
  }
  .main-btn.main-btn_green {
    background-color: transparent;
    border: 1px solid $seaz-alarm;
    color: $seaz-alarm;
    &:hover {
      background-color: $seaz-alarm;
      color: #fff;
    }
  }
  .modal-content__text {
    margin-bottom: 3rem;
    color: $seaz-about-text;
    font-size: 1.6rem;
  }
}
.company-modal {
  .rotate-controls {
    .main-btn.main-btn_green {
      border: none;
    }
  }
}
.seaz-search__card_info {
  .seaz-company__offer_logo_box {
    @media screen and (max-width: 600px) {
      right: -7.2rem;
      top: -2rem;
    }
    .seaz-company__logo_text::before {
      @media screen and (max-width: 600px) {
        left: 25%;
      }
    }
  }
}
.top-navigation__header {
  .seaz-company__header_logo img {
    @media screen and (max-width: 1360px) {
      max-width: 10rem;
      margin-right: 1rem;
    }
    @include laptop {
      margin-right: 2rem;
    }
    @include mobile {
      max-width: 6rem;
      margin-right: 0.8rem;
    }
  }
  .seaz-company__header_logo_text--green {
    @media screen and (max-width: 1360px) {
      max-width: 10rem;
      margin-right: 1rem;
    }
    @include laptop {
      margin-right: 2rem;
    }
    @include mobile {
      max-width: 6.6rem;
    }
  }
}
.user-info__header {
  position: sticky;
  top: 4.9rem;
  left: 0;
  width: 100%;
  z-index: 999;

  .seaz-company__header_logo--purple .seaz-company__header_logo_text {
    @include laptop {
      padding: 0.6rem 0;
      width: 7.6rem;
      color: $clr-white;
      border-color: $clr-white;
    }
  }
  .container {
    padding: 0;
  }
  .user-info__wrap-content {
    width: 100%;
    padding: 0 8rem;
    @media screen and (max-width: 1350px) {
      padding: 0 3rem;
    }
    @include laptop {
      padding: 0 1.6rem;
    }
    @include tablet {
      padding: 0 2rem;
    }
    @include mobile {
      padding: 0 1.5rem;
    }
  }

  .seaz-company__header_logo {
    position: absolute;
    top: -4px;

    @include desktop {
      position: inherit;
      top: inherit;
      height: 3.2rem;
    }
    img {
      @include laptop {
        height: 3.2rem;
        max-width: 6.6rem;
      }
    }
  }
}
.seaz-company__tooltip.tooltip-pane {
  &.top {
    .seaz-company__logo_text::before {
      content: '';
      border: 7px solid transparent;
      border-bottom: 6px solid $clr-white;
      position: absolute;
      left: 50%;
      bottom: -12px;
      transform: rotate(180deg) translateX(50%);
    }
  }
  &.bottom {
    .seaz-company__logo_text::before {
      content: '';
      border: 7px solid transparent;
      border-bottom: 6px solid $clr-white;
      position: absolute;
      top: -12px;
      left: 50%;
      transform: translateX(-50%);
    }
  }
  &.right {
    .seaz-company__logo_text::before {
      content: '';
      border: 7px solid transparent;
      border-bottom: 6px solid $clr-white;
      position: absolute;
      top: 35%;
      left: -1.2rem;
      transform: rotate(-90deg);
    }
  }
}
