app-fleets-dashboard-weather,
app-fleet-documents-alerts {
  display: block;
}

app-fleets-dashboard-weather {
  width: 100%;
  max-width: 125rem;
  margin-right: 2.5rem;
}

app-fleet-documents-alerts {
  width: 100%;
  max-width: 59.1rem;

  @include laptop {
    width: 100%;
    max-width: 100%;
    margin-top: 2.4rem;
  }
}

.fleet-info {
  @include d-flex-space-between;
  align-items: stretch;

  @include laptop {
    flex-direction: column;
  }
}

.fleet-weather {
  width: 100%;
  height: 100%;

  &__title {
    text-transform: uppercase;
  }

  &__search {
    margin-bottom: 1.8rem;

    .location__row {
      width: auto;
    }
  }

  @include tablet {
    width: 100%;
  }
}

.fleet-weather-map-box {
  margin-top: 1.6rem;
}

.windy-frame {
  position: relative;
  width: 100%;
  height: 45.6rem;

  app-windy-iframe {
    display: block;
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
  }

  @include tablet {
    height: 25rem;
  }
}

.fleet-documents-alerts {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: stretch;
  width: 100%;
  height: 100%;

  &__row {
    flex-grow: 1;
    margin-top: 2.5rem;
  }
}

.fleet-documents-alerts-link {
  @include d-flex-space-between;
  align-items: center;
  padding: 1.6rem;
  border-radius: 1.6rem;
  background-color: $clr-ashy-grey;
  margin-top: 1.6rem;
  font-size: 2rem;
  font-weight: 400;
  line-height: 120%;
  color: $seaz-text;

  @include tablet {
    font-size: 1.6rem;
  }
}

.fleet-documents-alerts-link-count {
  @include d-align-items-center;
  margin-left: 1rem;

  .text {
    margin: 0 1.7rem 0 1.1rem;
  }
}
