.top-banner {
  display: flex;
  justify-content: center;
  align-items: flex-end;
  position: relative;
  padding: 0 1.6rem;
  background: $banner-bg;
  overflow-x: hidden;

  &__content {
    width: 100%;
    max-width: 75.3rem;
  }

  &__title {
    font-size: 4.8rem;
    font-weight: bold;
    color: $light-color;
    margin-bottom: 2.4rem;
    line-height: 100%;
    padding-top: 7rem;
  }

  &__text {
    font-size: 2.4rem;
    line-height: 140%;
    color: $banner-text-color;
    margin-bottom: 2.4rem;
  }

  &__label {
    position: absolute;
    top: 0;
    right: 0;
    width: 35.8rem;
    padding: 1.8rem 0;
    background-color: #3f9795;
    font-size: 4rem;
    font-weight: bold;
    line-height: 4.7rem;
    text-transform: uppercase;
    text-align: center;
    color: $light-color;
    transform: rotate(45deg) translate(10rem, -7rem);
  }

  @include laptop {
    flex-direction: column;
    align-items: center;
    padding: 1.6rem 1.6rem 0;

    &__content {
      margin-bottom: 2.8rem;
    }

    &__title {
      font-size: 3rem;
      padding-top: 0;
      margin-bottom: 1.4rem;
    }

    &__text {
      font-size: 1.6rem;
      margin-bottom: 1.6rem;
    }

    &__title,
    &__text {
      width: 100%;
      max-width: 47rem;
    }

    &__label {
      font-size: 2.4rem;
      line-height: 2.8rem;
      padding: 1.6rem 0;
    }
  }

  @include tablet {
    &__title {
      font-size: 2.4rem;
      padding-top: 0;
      margin-bottom: 1rem;
    }

    &__text {
      font-size: 1.4rem;
      margin-bottom: 1.6rem;
    }

    &__title,
    &__text {
      width: 100%;
      max-width: 28.4rem;
    }

    &__label {
      transform: rotate(45deg) translate(10.8rem, -9rem);
    }
  }
}

.top-banner-poster {
  width: 100%;
  max-width: 85.7rem;

  &__img {
    display: block;
    width: 100%;
    height: 100%;
    object-fit: contain;
  }

  @include laptop {
    width: 100%;
    max-width: 80rem;
  }

  @include tablet {
    width: 100%;
    max-width: 37.5rem;
  }
}

.users-list {
  @include d-flex-space-between;
  align-self: stretch;
  margin-bottom: 2.4rem;

  &__item {
    width: 33.3%;
    padding: 2.4rem;
    background-color: rgba(0, 0, 0, 0.22);
    border-radius: 1.2rem;

    &:not(:last-of-type) {
      margin-right: 1.6rem;
    }
  }

  &__count {
    font-size: 4rem;
    font-weight: bold;
    line-height: 100%;
    color: $light-color;
    margin-bottom: 1.2rem;
  }

  &__description {
    font-size: 2rem;
    font-weight: 500;
    line-height: 120%;
    color: $clr-grey;
  }

  @include laptop {
    &__item {
      padding: 1.2rem;
    }

    &__count {
      font-size: 3rem;
    }

    &__description {
      font-size: 1.6rem;
    }
  }

  @include tablet {
    &__count {
      font-size: 2rem;
      margin-bottom: 0.4rem;
    }

    &__description {
      font-size: 1.2rem;
    }
  }
}

.services-list {
  @include d-align-items-center;
  padding-bottom: 7rem;

  &__item {
    position: relative;
    font-size: 2.4rem;
    line-height: 140%;
    color: $banner-text-color;

    &:not(:last-of-type) {
      padding-right: 1rem;

      &:after {
        content: '';
        position: absolute;
        top: 50%;
        right: 0;
        transform: translateY(-50%);
        width: 0.2rem;
        height: 70%;
        background-color: $banner-text-color;
      }
    }

    &:not(:first-of-type) {
      padding-left: 1rem;
    }
  }

  @include laptop {
    padding-bottom: 0;
  }

  @include tablet {
    font-size: 1.4rem;
  }
}

.banner-box {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  min-height: 88.7rem;
  border-radius: 1.2rem;

  &__content {
    padding: 3.5rem 3.7rem 0;
  }

  &__title {
    font-size: 3rem;
    font-weight: 500;
    line-height: 145%;
    color: $light-color;
    margin-bottom: 1.2rem;
  }

  &__text {
    font-size: 2rem;
    line-height: 140%;
    color: $banner-text-color;

    &:not(:last-of-type) {
      margin-bottom: 1.6rem;
    }
  }

  &__img-box {
    width: 100%;
    border-radius: 0 0 1.2rem 1.2rem;
  }

  &__img {
    display: block;
    width: 100%;
    height: auto;
    object-fit: contain;
    border-radius: 0 0 1.2rem 1.2rem;
  }

  @media screen and (max-width: 1700px) {
    min-height: 70rem;
    margin-bottom: 3.5rem;

    &__content {
      padding: 1.6rem;
    }

    &__title {
      font-size: 2rem;
    }

    &__text {
      font-size: 1.6rem;

      &:not(:last-of-type) {
        margin-bottom: 1rem;
      }
    }
  }

  @media screen and (max-width: 1300px) {
    min-height: auto;

    &__img-box {
      display: flex;
      justify-content: center;
      align-items: flex-end;
    }

    &__img {
      width: auto;
      height: 30rem;
    }
  }

  @include tablet {
    margin-bottom: 1.6rem;

    &__img-box {
      display: flex;
      justify-content: flex-end;
      align-items: flex-end;
    }

    &__img {
      width: 100%;
      height: auto;
    }

    &__title {
      font-size: 1.6rem;
    }

    &__text {
      font-size: 1.2rem;
    }
  }
}

.banner-left {
  background-color: $dark-green;
}

.banner-right {
  background-color: $seaz-edit-hover;
}
