.boat-inventory-view {
  padding: 3rem;

  .boat-maintenance-task-view-modal-tab {
    width: 50%;
  }

  @include tablet {
    padding: 1.6rem;
  }
}

.boat-inventory-view-header {
  @include d-align-items-center;

  &__status-change {
    margin-left: 1.6rem;
  }

  &__update-box {
    color: $profile-month;
    margin-left: 1.6rem;
  }

  @include tablet {
    flex-wrap: wrap;

    &__title {
      width: 100%;
      margin-bottom: 1.2rem;
    }

    &__status-change {
      margin-left: 0;
    }
  }
}

.boat-inventory-view-form {
  margin-top: 3rem;

  &__title {
    margin-bottom: 1.2rem;
    color: $seaz-text;
  }

  &__row {
    margin-bottom: 2.8rem;

    .form-field--wrapper {
      flex-direction: row;
      align-items: center;
    }

    .form-field--label {
      max-width: 16rem;
      margin-right: 1.6rem;
    }

    &--uploader {
      display: flex;
      align-items: flex-start;
    }

    &--text-area {
      .form-field--wrapper {
        align-items: flex-start;
      }
    }

    &--item-id,
    &--quantity {
      .form-field--content {
        max-width: 15.8rem;
      }
    }
  }

  .file-upload-text {
    margin-bottom: 1.2rem;
  }

  .plug-label {
    width: 16rem;
    min-width: 16rem;
    margin: 0 1.6rem 0 0;
  }

  .boat__form-list {
    margin: 0;
  }

  @include tablet {
    &__row {
      .form-field--wrapper {
        flex-direction: column;
        align-items: flex-start;
      }

      .form-field--label {
        max-width: initial;
        margin-right: 0;
      }

      &--uploader {
        flex-direction: column;
      }

      &--item-id {
        .form-field--content {
          max-width: initial;
        }
      }
    }
  }
}

.boat-inventory-form-footer {
  @include footer-with-border-left-btn(0 0 1rem 0, 4.6rem 0 0 0);
  justify-content: space-between;
  border: 0;

  &__actions {
    @include d-align-items-center;

    @include mobile {
      flex-direction: column;
      align-items: flex-start;
      width: 100%;
    }
  }

  &__actions-group {
    @include mobile {
      width: 100%;
      @include d-align-items-center;
      justify-content: flex-end;
    }
  }

  @include mobile {
    align-items: flex-end;
  }
}

.boat-inventory-view-item {
  @include d-flex-space-between;
  align-items: center;

  &__title {
    word-break: break-word;
    margin-right: 1rem;
  }
}

.boat-inventory-form-checkbox {
  margin-right: 1.2rem;

  .form__checkbox-label-text {
    font-size: $base-font-size;
    color: $seaz-text;
    padding-left: 1rem;
  }

  @include mobile {
    margin: 0 0 2.9rem 0;

    .form__checkbox-label {
      padding: 0;
    }
  }
}
