.boat-inventory {
  @include tablet {
    padding: 0;

    .paginator-list-top {
      display: none;
    }
  }
}

.boat-inventory-container {
  margin-top: 1.6rem;
  box-shadow: 0 2px 2px rgba(43, 28, 54, 0.16);
  background-color: $light-color;
  border-radius: 1.2rem;
}

.boat-action-panel-content {
  .boat-inventory-list-header__action:not(:last-child) {
    margin-bottom: 1rem;
  }
  .boat-inventory-list-header__selected {
    font-size: $base-font-size;
    font-weight: 400;
    color: $light-color;
    opacity: 0.7;
  }

  .seaz__btn_rounded--delete {
    background-color: $seaz-alarm;
    color: $light-color;
    border-color: $seaz-alarm;
  }

  .boat-inventory-cancel-btn {
    display: none;
  }

  .boat-inventory-list-header__btn--select,
  .boat-inventory-status-change .boat-inventory-list-header__btn {
    background-color: $light-color;
    color: $clr-primary-and-accent;

    &:disabled {
      background-color: $clr-grey;
    }
  }
}

.inventory-scroll-btn {
  bottom: 1.8rem;
  width: 5.6rem;
  height: 5.6rem;
  border: 2px solid rgba(255, 255, 255, 0.16);
  box-shadow: 0 16px 32px rgba(43, 28, 54, 0.12);
  border-radius: 12px;
  background-color: $light-color;

  app-seazone-icon svg {
    fill: $clr-primary-and-accent;
  }

  @include laptop {
    display: block;
  }
}

.boat-bottom-panel-content {
  .boat-inventory-filter-btn {
    width: 20rem;
    height: 5.6rem;
    font-family: $base-font;
    font-size: 1.6rem;
  }
}
