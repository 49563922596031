.supplier-search-all {
  .chips-item-large .supplier-search-all-chips-icon-box .seaz-search-chips-filter__chips--icon {
    width: 2rem;
    height: 2rem;
    background-color: initial;
    margin-right: 0;
    filter: invert(100%) sepia(0%) saturate(7500%) hue-rotate(166deg) brightness(112%) contrast(105%);
  }

  .supplier-search-all-chips-icon-box {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 3.2rem;
    height: 3.2rem;
    background-color: rgba(53, 31, 115, 0.2);
    border-radius: 50%;
    margin-right: 1rem;
  }

  .chips-item.selected .supplier-search-all-chips-icon-box {
    background-color: #4f194a;
  }

  .seaz-filters__map_icon {
    height: auto;
  }

  &__choose-btn {
    margin: 0.8rem 0 1.6rem;
  }

  .seaz-search-chips-filter__row:not(:last-of-type) {
    margin-bottom: 0;
  }
}
