.check-auth-modal {
  &__text {
    font-size: 1.6rem;
    line-height: 1.9rem;
    color: $clr-light-grey;
    margin-top: 3rem;
  }

  &__footer {
    @include footer-with-border-left-btn(3rem 0 0, 3rem 0 0);

    .main-btn_green {
      margin-right: 0;
    }
  }

  @include tablet {
    &__footer {
      flex-direction: column;
      @include footer-with-border-left-btn(2rem 0 0, 2rem 0 0);

      .main-btn {
        display: block;
        box-sizing: border-box;
        width: 100%;
        margin-right: 0;
        margin-top: 1rem;
      }
    }
  }
}
