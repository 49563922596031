app-boat-systems-menu {
  display: block;

  @include laptop {
    width: 100%;
  }
}

.systems-menu {
  @include d-align-items-center;
  flex-shrink: 0;

  &__search {
    min-width: 27.8rem;
    margin-right: 1.6rem;
  }

  &__container {
    @include d-align-items-center;
  }

  &__link {
    display: block;
    min-width: 22.5rem;
    margin-right: 1.6rem;
    text-align: center;
  }

  &__menu {
    padding: 0.7rem 2rem;
  }

  @include laptop {
    width: 100%;

    &__search {
      width: 100%;
      min-width: auto;
    }
  }

  @include tablet {
    flex-direction: column;

    &__search {
      margin: 0 0 1.6rem 0;
    }

    &__container {
      width: 100%;
      justify-content: flex-end;
    }

    &__link {
      width: 100%;
      min-width: auto;
    }
  }
}

.mat-mdc-menu-item .mat-mdc-menu-item-text {
  @include d-align-items-center;
  font-size: $base-font-size;
  font-weight: 400;
  line-height: 1.6rem;
  color: $seaz-about-text;
}

.mat-menu-button {
  &__icon {
    margin-right: 1.6rem;
  }
}
