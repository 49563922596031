.general-table {
  box-sizing: border-box;
  position: relative;

  @include mobile {
    padding: 0;
  }

  &__title {
    line-height: 28px;
    font-weight: bold;
    font-size: 2.4rem;
    @include tablet-big {
      padding-top: 2.7rem;
    }
    @include mobile {
      font-size: 1.8rem;
    }

    &-year {
      color: $clr-purple;
    }

    &-name {
      color: $clr-green;
    }

    @include tablet-big {
      width: 85%;
    }
    &_row {
      padding-top: 2.7rem;
      display: flex;
      justify-content: space-between;
      .seaz-budget__btn-container {
        margin-top: 0;
      }
      @include tablet-big {
        flex-direction: column-reverse;
        padding-top: 0;
      }
    }
  }

  &__body {
    padding-top: 40px;
    position: relative;

    .form__toggle-label:before {
      width: 4rem;
      height: 2.4rem;
      background-color: #b6b6b6;
      border-radius: 10rem;
    }

    .form__toggle-input:checked + label:before {
      background-color: $button-main;
    }

    .form__toggle-label:after {
      top: 0.2rem;
      left: 0.2rem;
      width: 2rem;
      height: 2rem;
      background-color: #fff;
    }

    .form__toggle-input:checked + label:after {
      right: 0.2rem;
      background-color: #fff;
    }
  }

  &__attention {
    display: flex;
    align-items: center;
    color: #f7aa38;
    line-height: 19px;
    font-size: 1.6rem;
    padding-right: 0;
    &.mode {
      position: absolute;
      bottom: -4rem;
    }
    &_badge {
      background-color: #f7aa38;
      border-radius: 50%;
      display: flex;
      //align-content: center;
      //justify-content: center;
      width: 30px;
      height: 30px;
      margin-right: 8px;

      &__icon {
        display: block;
        width: 60%;
        height: 60%;
        margin: auto;
      }
    }
  }

  &__head {
    &_row {
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding-top: 16px;
    }

    &_arrows {
      display: flex;
    }

    &_img {
      cursor: pointer;

      &-left {
        margin-right: 20px;
      }

      &-right {
        transform: rotate(180deg);
      }
    }
  }

  &__item {
    &_comment {
      margin: 3px 3px 0 9px;
    }
    &_percentage {
      margin-left: auto;
      padding-left: 0.9rem;
    }
  }

  &__head {
    color: rgba(53, 31, 111, 0.5);
    &_item {
      width: 100%;
      overflow: hidden;
      display: flex;

      &.general-table__content_item td {
        color: rgba(53, 31, 111, 0.5);
        height: 5.5rem;
        padding: 20px 12px;
        display: inline-flex;
        align-items: center;
        min-width: 7vw;

        @include laptop {
          min-width: 77px;
        }
      }
    }
  }

  &__total {
    color: $clr-green;
    font-weight: bold;
    border-top: 1px solid #a9a9a9;
    border-bottom: 1px solid #a9a9a9;
    border-radius: 0;
    &_item {
      width: 100%;
      overflow: auto;
      display: flex;
      border-top: 1px solid #a9a9a9;
      border-bottom: 1px solid #a9a9a9;

      &.general-table__content_item td {
        color: $clr-green;
        font-weight: bold;
        min-width: 7vw;
        height: 5.3rem;
        padding: 20px 12px;
        display: inline-flex;
        align-items: center;

        @include laptop {
          min-width: 77px;
        }
      }
    }
  }

  &__content {
    width: 1%;
    flex-grow: 1;
    overflow: auto;

    &_item {
      width: 100%;
      overflow: hidden;
      display: flex;

      &:nth-of-type(2n) {
        background-color: #fafafb;
      }

      td {
        min-width: 7vw;
        height: 5.5rem;
        padding: 20px 12px;
        color: #4f194a;
        display: inline-flex;
        align-items: center;

        @include laptop {
          min-width: 77px;
        }
      }
    }
  }
}

.budget {
  &__wrapper {
    &.disabled {
      opacity: 0.5;
      pointer-events: none;
    }
  }
  &__select {
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
    @include tablet-small {
      flex-direction: column;
      align-items: baseline;
    }
    .main-btn_green {
      min-width: 16rem;
    }
    .main-btn_white-green {
      min-width: 12rem;
      @include mobile {
        min-width: 10rem;
      }
    }

    @include laptop {
      flex-direction: column;
      align-items: flex-start;
    }

    &_input {
      min-width: 22rem;
      @include mobile {
        min-width: 10rem;
      }
    }

    &_apply {
      @include mobile {
        font-size: 1.4rem;
        line-height: 2.2rem;
      }
    }

    &_section {
      display: flex;
      align-items: flex-end;
      justify-content: flex-end;

      @include mobile-big {
        margin-bottom: 15px;
        justify-content: flex-end;
        width: 100%;
      }
      &--buttons {
        display: flex;
        @include tablet-small {
          width: 100%;
          padding-top: 1rem;
        }
        @include mobile-big {
          padding-top: 0;
        }
      }
      .main-btn_green {
        min-width: 10rem;
      }

      @include mobile {
        .main-btn_green {
          width: 100%;
        }
      }

      .form__col {
        width: 100%;
        @include mobile {
          width: 50%;
          margin-bottom: 0;
        }
      }

      &--right {
        align-items: center;
        position: relative;
        @include laptop {
          justify-content: flex-start;
          padding-top: 3rem;
          &.form__col {
            width: 100%;
          }
        }
        @include mobile {
          &.form__col {
            padding-top: 1rem;
            margin-bottom: 2rem;
          }
        }
        @include tablet-small {
          justify-content: flex-start;
          padding-top: 3rem;
          &.form__col {
            width: 100%;
          }
        }
        @include mobile {
          &.form__col {
            padding-top: 1rem;
            margin-bottom: 2rem;
          }
        }
      }
      &--buttons {
        @include mobile {
          width: 100%;
        }
      }
    }

    &_btn {
      &:not(:last-child) {
        margin-right: 16px;
      }
      @include mobile {
        margin-right: 0;
        &:nth-child(2) {
          margin-right: 1.6rem;
        }
        &:not(:first-child) {
          // margin-top: 2rem;
          width: calc((100% - 1.6rem) / 2);
        }
      }
    }
  }

  &-new {
    &__duration {
      width: 44.3%;
      margin-top: 4rem;

      @include tablet {
        width: 100%;
      }

      &_title {
        margin-bottom: 16px;
        @include mobile {
          margin-bottom: 32px;
          font-size: 1.8rem;
          margin-top: 15px;
        }
      }

      &_row {
        app-date-control {
          @include mobile {
            max-width: 100%;
          }
        }
      }
    }

    &__buttons {
      width: 44.3%;
      @include tablet-big {
        width: 71.3%;
      }
      @include mobile {
        width: 100%;
      }

      &_row {
        display: flex;
        @include mobile {
          justify-content: space-between;
        }
      }
    }

    &__button-group {
      display: flex;
    }

    &__btn-continue-icon {
      display: flex;
      align-items: center;
      margin-left: 1.2rem;
      transition: all 0.3s;

      svg {
        width: 1.9rem;
        height: 2rem;

        @include tablet {
          width: 1.5rem;
          height: 1.3rem;
        }
      }
    }

    &__btn {
      &-back,
      &-cancel {
        width: 31%;
        margin-right: 4%;
        margin-top: 26px;

        @include mobile {
          width: 48%;
          margin-right: 0;
        }
      }
      &-continue {
        width: 65%;
        margin-top: 26px;

        @include mobile {
          width: 48%;
        }

        &:hover {
          .budget-new__btn-continue-icon {
            transform: translate(5px, 0);
          }
        }
      }
    }

    &__salaries {
      width: 44.3%;

      @include tablet-big {
        width: 71.3%;
      }

      @include mobile {
        width: 100%;
      }

      &_select {
        margin-bottom: 72px;
      }

      &_group .select-group__title {
        font: 2rem/3.9rem $base-font;
        font-weight: bold;
        color: #4d4e67;
        padding: 0 1.4rem;
      }

      &_title {
        margin-bottom: 24px;
        margin-top: 15px;

        &:last-of-type {
          margin-bottom: 0;
        }

        @include mobile {
          font-size: 1.8rem;
        }
      }

      &_row {
        display: flex;
        align-items: flex-end;
        margin-bottom: 24px;
      }

      &_col {
        min-width: 60%;
      }

      &_col.form__col {
        @include mobile {
          margin-right: 0;
        }
      }

      &_buttons {
        display: flex;
        width: 40%;
        justify-content: space-between;

        @include mobile {
          width: 100%;
        }
      }

      &_btn {
        width: 100%;
        &:not(:nth-of-type(1)) {
          margin-left: 3%;
        }
      }

      &_add {
        margin-top: 24px;
        width: 100%;
        color: $clr-purple;
        border: 1px solid $clr-purple;
        border-radius: 3px;
      }
    }

    &__food {
      width: 44.3%;

      @include tablet-big {
        width: 71.3%;
      }

      @include mobile {
        width: 100%;
      }

      .form__row {
        align-items: flex-end;
      }

      &_title {
        margin-bottom: 24px;
      }

      &_col {
        width: 100%;
      }

      &_period {
        width: 30%;

        @include mobile {
          width: 100%;
        }
      }

      &_amount {
        app-amount-form {
          .form__row {
            @include mobile {
              display: flex;
              flex-direction: row;
              flex-wrap: wrap;
              justify-content: space-between;
              padding: 0;
              width: 100%;
            }
          }
        }

        .form__col {
          @include mobile {
            width: 45%;
            margin-right: 0;
            margin-bottom: 2.4rem;
          }
        }
      }
    }
  }

  &-comparison {
    &__chart {
      display: flex;
      justify-content: space-between;
      align-items: center;
      position: relative;
      margin: 4rem 0 3.5rem;
      @include tablet-big {
        flex-direction: column;
        align-items: baseline;

        .general-table__title {
          margin-bottom: 15px;
        }
      }

      .btn-expand {
        display: none;

        @include tablet-big {
          display: flex;
          position: absolute;
          right: 0;
          top: 72px;
        }
        @include mobile-big {
          top: 100px;
        }
      }

      .switch__btn.active {
        background-color: #351f6f;
        color: #fff;
      }

      .general-table__title {
        width: fit-content;
      }
    }

    &__buttons {
      display: flex;
      align-items: center;

      @include mobile-big {
        flex-direction: column;
        align-items: baseline;
      }

      &_title {
        color: #4d4e67;
        margin-right: 15px;
        @include mobile-big {
          margin-bottom: 10px;
        }
      }
    }
  }

  &-table__btn {
    display: none;

    @include tablet-big {
      display: flex;
      position: absolute;
      bottom: 0;
      right: 0;
    }
  }

  &-comparing {
    .budget-table__head_item {
      &:nth-of-type(2n - 1) {
        color: #418dff;
      }
    }

    .budget-table__total_item {
      color: #4f194a;
      &:nth-of-type(2n - 1) {
        color: #418dff;
      }
    }

    .budget-table__row td {
      &:nth-of-type(2n) {
        .budget-table__item_center {
          color: #4f194a;
        }
      }

      &:nth-of-type(2n-1) {
        .budget-table__item_center {
          color: #418dff;
        }
      }
    }
  }
  &__info {
    &_icon {
      display: inline-block;
      margin: 0.5rem 0 0 0.5rem;
      &-wrapper {
        display: flex;
        align-items: center;
        justify-content: flex-end;
      }
    }
    &-tooltip.tooltip-pane {
      filter: drop-shadow(0px 4px 8px rgba(53, 40, 113, 0.26));

      .tooltip {
        padding: 0.8rem;
        border-radius: 4px;
        position: relative;
        display: inline-block;
        box-sizing: content-box;

        &__text {
          font-weight: 500;
          font-size: 1.2rem;
          color: $seaz-document-count;
        }
      }

      &.top {
        .tooltip {
          &::before {
            transform: translateX(calc(-50% + 10px));
          }
        }
      }

      &.bottom {
        .tooltip {
          &::before {
            transform: translateX(calc(-50% + 10px)) rotate(180deg);
          }
        }
      }
    }
  }
}

.monthly-budget {
  padding-top: 60px;

  &_header {
    display: flex;
    align-items: flex-end;
    justify-content: flex-end;
    &.boat-budget__head {
      margin-bottom: 3rem;
    }
  }
  &_select {
    width: auto;
    margin-right: 1rem;
  }
  &_table {
    position: relative;
  }
  td {
    &:nth-child(5) {
      border-left: 1px solid #a9a9a9;
    }
  }

  .budget-table__head-right {
    border-radius: 0;
  }

  .budget-table__item_center {
    color: #4f194a;
  }

  .budget-table__item-right {
    color: $clr-green;
    & .negative {
      color: #a159b3;
    }
    .budget-table__item_center {
      padding: 0;
    }
  }

  .budget-table-container {
    app-scroll-controls {
      .controls-container {
        top: -30px;
      }
    }
    .budget-table__collapse-control {
      top: -25px;
    }
  }

  .budget-table .budget-table__row td:last-child,
  .budget-table .budget-table__row th:last-child {
    position: static;
    border-left: 1px solid #e7e7eb;
  }
  &.sticky-header__container {
    padding-top: 0;
  }
}

.table-container {
  display: block;
  max-width: 100%;
  overflow-y: hidden;
}

.sticky-header {
  display: flex;
  justify-content: center;
  -webkit-overflow-scrolling: touch;
  box-sizing: border-box;
  background-color: #fff;
  font: 1.4rem/1.8rem $base-font;
  &__container {
    display: block;
    max-width: 100%;
    width: 100%;
    position: relative;
    background-color: #fff;
  }
  .table-container {
    overflow-x: hidden;
    display: flex;
    flex-wrap: wrap;
    app-scroll-controls {
      display: block;
      width: 100%;
      order: 1;
      height: 3rem;
      .controls-container {
        top: 10px;
      }
    }
  }
  .budget-table {
    align-self: flex-start;
    order: 2;
    border-bottom: 1px solid #a9a9a9;
    td,
    th {
      font-size: 14px;
      box-sizing: border-box;
      @include tablet-small {
        font-size: 0.9rem;
      }
    }
    .budget-table__head_item {
      min-width: 80px;
    }
  }
}

@media screen and (orientation: portrait) {
  .cdk-overlay-container .modal-background.rotated ~ .cdk-global-overlay-wrapper .sticky-header {
    display: none;
  }
}

.modal__wrap .budget-table .budget-table__row td:last-child,
.modal__wrap .budget-table .budget-table__row th:last-child {
  @include tablet-small {
    position: sticky;
  }
}

.modal__wrap .table-container {
  @include tablet-small {
    height: 100%;
  }
}

.modal__wrap .budget-table .budget-table__row td:first-child,
.modal__wrap .budget-table .budget-table__row th:first-child {
  @include tablet-small {
    position: sticky;
  }
}

.budget-table {
  width: 100%;
  margin: 0 auto;
  border-collapse: separate;

  &-container {
    position: static;
    app-scroll-controls .controls-container {
      top: -10px;
    }
  }
  &__item {
    padding: 0.8rem 1.2rem;
    min-height: 4.4rem;
    background-color: inherit;
    color: $seaz-text;
    border-left: 1px solid #e7e7eb;
    &:nth-of-type(2) {
      border-left: 0;
    }

    @include tablet-small {
      font-size: 0.9rem;
      min-height: 4rem;
      max-height: fit-content;
      height: auto;
    }

    &_center {
      padding: 0.8rem 1.2rem;
      color: $seaz-text;
      display: flex;
      align-items: center;
      justify-content: flex-end;
      font-weight: 400;
      @include tablet-small {
        font-size: 0.9rem;
      }
    }

    &-right {
      background-color: inherit;
      text-align: end;
      padding: 0.8rem 1.2rem;
      color: $clr-green;
      font-weight: bold;
      box-shadow: -6px 0 3px 1px rgba(206, 206, 206, 0.15);
      display: flex;
      align-items: center;
      min-height: 4.4rem;
      justify-content: flex-end;
      @include tablet-small {
        font-size: 0.9rem;
        height: 4rem;
        box-shadow: unset;
      }
      &--comparing {
        span {
          width: 8rem;
          display: inline-flex;
          justify-content: flex-end;
          color: #418dff;

          &:nth-of-type(2n) {
            color: #4f194a;
          }

          @include mobile-big {
            width: 5rem;
            font-size: 1rem;
          }
        }

        @include mobile-big {
          padding: 0 5px;
        }
      }
    }
    &.budget-table__row_item {
      font-weight: 400;
      color: $seaz-text;
    }
  }

  &__head {
    min-width: 35rem;
    background-color: #fff;
    height: 4.4rem;
    color: $clr-light-grey;
    box-shadow: 6px 0 3px 1px rgba(206, 206, 206, 0.15);
    @include tablet-small {
      min-width: 14.2rem;
      font-size: 0.9rem;
      height: 4rem;
    }

    &-left {
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 0 8px;
    }

    &_item {
      color: $clr-light-grey;
      text-align: end;
      padding: 0 12px;
      height: auto;
    }

    &-right {
      min-width: 92px;
      padding: 0 12px;
      background-color: inherit;
      color: $clr-light-grey;
      text-align: end;
      box-shadow: -6px 0 3px 1px rgba(206, 206, 206, 0.15);
      @include tablet-small {
        font-size: 0.9rem;
        box-shadow: unset;
        border-left: 1px solid #e7e7eb;
      }

      &--comparing {
        display: flex;
        height: 5.6rem;

        span {
          width: 8rem;
          display: flex;
          align-items: center;
          color: #418dff;
          justify-content: flex-end;

          &:nth-of-type(2n) {
            color: #4f194a;
          }

          @include mobile-big {
            width: 5rem;
            font-size: 1rem;
            box-sizing: border-box;
            line-height: 1.4rem;
          }
        }
      }
    }
  }

  &__total {
    color: $seaz-text;
    text-align: inherit;
    padding: 0 8px;
    min-height: 4.4rem;
    display: flex;
    align-items: center;
    font-weight: bold;
    border-top: 1px solid #a9a9a9;
    background-color: inherit;
    box-shadow: 6px 0 3px 1px rgba(206, 206, 206, 0.15);
    @include tablet-small {
      font-size: 0.9rem;
      height: 4rem;
    }

    &_item {
      color: $clr-green;
      border-top: 1px solid #a9a9a9;
      box-sizing: border-box;
      font-weight: bold;
      text-align: end;
      padding: 0 12px;
      border-left: 1px solid #e7e7eb;
      &:nth-of-type(2) {
        border-left: 0;
      }

      @include tablet-small {
        font-size: 0.9rem;
      }
    }

    &-right {
      font-weight: bold;
      color: $clr-green;
      border-top: 1px solid #a9a9a9;
      background-color: inherit;
      padding: 0 12px;
      text-align: end;
      box-shadow: -6px 0 3px 1px rgba(206, 206, 206, 0.15);
      min-height: 4.4rem;
      display: flex;
      align-items: center;
      justify-content: flex-end;
      @include tablet-small {
        font-size: 0.9rem;
        height: 4rem;
        box-shadow: unset;
      }
      &--comparing {
        span {
          width: 8rem;
          display: inline-flex;
          justify-content: flex-end;
          color: #418dff;

          &:nth-of-type(2n) {
            color: #4f194a;
          }

          @include mobile-big {
            width: 5rem;
            font-size: 1rem;
          }
        }
      }
    }
  }

  &__title {
    border-top: 1px solid #a9a9a9;
    .budget-table__item_center span {
      color: #46a6a4;
      font-weight: bold;
    }
    .budget-table__title-right span {
      color: $seaz-text;
    }
    &--comparison,
    &--spending {
      .budget-table__item_center span {
        color: #a9a9a9;
      }
      .budget-table__item-right span {
        color: #a9a9a9;
      }
    }
    &_item {
      color: $seaz-text;
      font-weight: bold;
      min-height: 4.4rem;
      padding: 0.8rem 1.2rem;
      display: flex;
      align-items: center;
      box-shadow: 6px 0 3px 1px rgba(206, 206, 206, 0.15);
      background-color: #fff;
      &:first-of-type {
        border-left: 0;
      }

      @include tablet-small {
        font-size: 0.9rem;
        height: 4rem;
      }

      &.collapsable {
        cursor: pointer;
      }

      &.collapsed {
        .budget-table__title_icon {
          transform: rotateZ(0);
        }
      }

      &.expanded {
        .budget-table__title_icon {
          transform: rotateZ(180deg);
        }
      }
    }

    &-right {
      font-weight: bold;
      color: $clr-green;
      padding: 0.8rem 1.2rem;
      text-align: end;
      box-shadow: -6px 0 3px 1px rgba(206, 206, 206, 0.15);
      min-height: 4.4rem;
      display: flex;
      align-items: center;
      justify-content: flex-end;
      // background-color: white;
      @include tablet-small {
        font-size: 0.9rem;
        height: 4rem;
        box-shadow: unset;
      }
    }
    td.budget-table__title-right {
      border-left: 0;
    }

    td {
      border-left: 1px solid #e7e7eb;

      &:nth-of-type(2) {
        border-left: 0;
      }
      &:first-of-type {
        border-left: 0;
      }
      &:last-of-type {
        border-left: 0;
      }
    }
  }

  &__icon {
    transition: transform 0.4s cubic-bezier(0.23, 1, 0.32, 1);
    display: inline-block;
    &_wrapper {
      margin: 6px 9px 2px 0;
      width: 14px;
      display: flex;
      justify-content: center;
    }
  }

  &__row {
    background-color: #fff;

    &:nth-child(2n) {
      background-color: $clr-ashy-grey;
    }
    td {
      border-left: 1px solid $seaz-head-arrow;
      &:nth-of-type(2) {
        border-left: 0;
      }
      &:first-of-type,
      &:last-of-type {
        border-left: 0;
      }
    }

    &_item {
      display: flex;
      align-items: center;
      box-shadow: 6px 0 3px 1px rgba(206, 206, 206, 0.15);
      &.budget-table__item {
        border-left: 0;
      }
    }

    &--text {
      margin-right: 0.5rem;
    }

    &--val {
      margin-left: auto;
    }
  }
  th,
  td {
    vertical-align: middle;
  }
  td {
    min-width: 9.8rem;
    // cursor: pointer;
  }
  .budget-table__total_item,
  .budget-table__total-right,
  .budget-table__title-right,
  .budget-table__item_center {
    white-space: nowrap;
  }

  .budget-table__row {
    td:first-child,
    th:first-child {
      position: sticky;
      left: 0;
      z-index: 100;
      background-color: inherit;

      width: 35rem;
      //   @include tablet-small {
      //     position: relative;
      //   }
    }
    td:last-child,
    th:last-child {
      position: sticky;
      right: 0;
      background-color: inherit;
      @include tablet-small {
        position: relative;
        border-left: 1px solid #e7e7eb;
      }
    }
  }
  &.copied {
    td {
      cursor: move;
    }
  }

  &__actually {
    color: #4f194a;
    font-weight: bold;
  }

  .bold {
    font-weight: bold;
  }
  &__collapse {
    &-control {
      position: absolute;
      top: -30px;
      display: flex;
      align-items: center;
      height: 3rem;
      &--toggle {
        display: inline-block;
        margin: 0 12px 0 1px;
      }
    }
    &-label {
      font-size: 16px;
      color: #4d4e67;
      @include tablet-small {
        font-size: 14px;
      }
    }
  }
}

.sticky-header__container.comparison-table {
  .budget-table__head_item {
    &:nth-of-type(2n - 1) {
      color: #418dff;
    }
  }
  .budget-table__total_item {
    color: #4f194a;
    &:nth-of-type(2n - 1) {
      color: #418dff;
    }
  }
}

.budget-table .budget-table__row td:last-child {
  &.budget-table__title-right {
    background-color: #fff;
  }
}

.budget-table__row.compare-budget {
  &__row {
    .budget-table__item-right {
      border-left: 1px solid #e7e7eb;
      box-shadow: none;
    }
  }
}
.compare-budget {
  &__row {
    &-category {
      font-weight: bold;
      .budget-table__item_center,
      .budget-table__item-right {
        color: $seaz-text;
      }
    }
    .budget-table__item-right {
      box-shadow: none;
    }
    .budget-table__head-right {
      box-shadow: none;
    }
    .budget-table__total-right {
      box-shadow: none;
    }

    .budget-table__total_item:nth-of-type(5) {
      border-left-color: #a9a9a9;
    }
    .budget-table__head_item:nth-of-type(5) {
      border-left-color: #a9a9a9;
    }
  }
}

.sticky-controls app-scroll-controls {
  width: 100%;
  display: flex;
  justify-content: flex-end;
  background-color: #fff;
  font-size: 14px;
  .controls-container {
    position: relative;
  }
}

td .budget-table__editable {
  &_cell {
    display: flex;
    justify-content: flex-end;
    .editable-input {
      width: 8rem;
      text-align: end;
    }
  }
  &_name {
    display: flex;
    align-items: center;
    .editable-input {
      width: 100%;
    }
  }
}
.editable-input {
  border: none;
  border-bottom: 1px solid $clr-green;
  border-radius: 0;
  background-color: inherit;
  padding: 0 5px;

  &:focus {
    border: none;
  }

  &.ng-invalid.ng-dirty {
    border: none;
    border-bottom: 1px solid #d12e2e;

    + .editable-input__span {
      height: 0;
    }
  }

  &__label {
    position: relative;
  }
}

.editable-input__span {
  height: 1px;
  background: $clr-green;
  position: absolute;
  display: block;
  margin: 0 auto;
  left: 0;
  right: 0;
  transition: all 0.2s ease-in;
  width: 0px;
}

.editable-input:focus + .editable-input__span {
  width: 100%;
}

.item__message {
  width: 10px;
  height: 10px;
  border-radius: 50%;
  background-color: #f7aa38;
  margin-left: 9px;
  display: inline-block;
  flex-shrink: 0;
  margin-right: 5px;
}

.comparison-table,
.spending-table {
  app-scroll-controls .controls-container,
  .budget-table__collapse-control {
    top: 10px;
  }
}

.comparison-table {
  .btn-expand {
    display: none;

    @include tablet-big {
      display: flex;
      position: absolute;
      top: 27px;
      right: 0;
    }
  }

  .budget-table__head {
    @include tablet-big {
      height: 5.6rem;
    }
  }
}

.tabs__header.switch {
  // width: 38.5rem;

  .tabs__item.active {
    background-color: #351f6f;
    color: #fff;
  }

  .tabs__item {
    @include mobile {
      text-align: center;
    }
  }

  @include mobile {
    // width: 30rem;
  }
}

.spending-table,
.budget-comparing {
  .budget-table__head-right {
    display: flex;
    height: 5.6rem;
    align-items: center;

    span {
      width: 8rem;
    }
  }

  .budget-table__total-right {
    span {
      width: 8rem;
      display: inline-flex;
      justify-content: flex-end;
    }
  }

  .budget-table__item-right {
    span {
      min-width: 8rem;
      display: inline-flex;
      justify-content: flex-end;
    }
  }
  .budget-table__row td {
    font-weight: bold;
  }
}
.spending-table {
  position: relative;
  .btn-expand {
    display: none;
    @include tablet-big {
      display: flex;
      position: absolute;
      top: 25px;
      right: 0;
    }
  }

  .spending-table__title {
    width: 80%;
  }

  .budget-table__head {
    @include tablet-small {
      height: 5.6rem;
    }
  }
}

.comparison-chart {
  &__info {
    display: flex;
    justify-content: center;
    margin-top: 2rem;

    &_item {
      display: inline-flex;
      margin-right: 2rem;
      font-size: 1.6rem;
      color: #a9a9a9;
      align-items: center;
      &::before {
        content: '';
        width: 0.8rem;
        height: 0.8rem;
        background-color: $clr-purple;
        border-radius: 50%;
        margin-right: 0.8rem;
      }

      &::last-child {
        margin-right: 0;
      }

      &-year {
        &::before {
          background-color: $clr-green;
        }
      }
    }
  }
}

.boat-budget {
  &__head {
    justify-content: space-between;
    align-items: center;
    margin-bottom: 2rem;
    position: relative;
    @include tablet-small {
      flex-direction: column;
      justify-content: flex-end;
      align-items: baseline;
    }

    &-right {
      display: flex;
      @include tablet-big {
        margin-right: 4rem;
      }
    }

    &_title {
      font-size: 2.4rem;
      color: $clr-purple;
      font-weight: bold;

      @include tablet-small {
        margin-bottom: 2rem;
        font-size: 2rem;
      }
    }

    .btn-expand {
      display: none;

      @include tablet-big {
        display: flex;
        position: absolute;
        top: 0;
        right: 0;
        min-width: 3rem;
        height: 3rem;
        margin-left: 1rem;
      }
    }
  }
}

.budget-modal {
  &__title {
    font-size: 2.4rem;
    font-weight: 500;
    line-height: 1.4;
    color: $clr-purple;

    &-month {
      font-weight: 500;
      color: $clr-green;
      text-transform: capitalize;
    }

    &_box {
      padding-bottom: 3rem;
      line-height: 4rem;
      font-size: 3.4rem;
    }
  }
  .accordion {
    &__label {
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 20px 0;
      border-bottom: 1px solid #e7e7eb;

      &_text {
        font-weight: bold;
        font-size: 1.8rem;
        color: #a9a9a9;
      }
    }

    &__btn {
      width: 0.9rem;
      height: 0.9rem;
      transition: all 0.2s linear;
      &_box {
        width: 2.1rem;
        height: 2.1rem;
        border-radius: 50%;
        display: flex;
        align-items: center;
        justify-content: center;
        background-color: #e7e7eb;
        cursor: pointer;
      }
    }

    &__content {
      padding-top: 16px;
      display: none;

      .main-btn_white-green {
        width: 100%;
        margin-bottom: 15px;
      }
    }

    &.active {
      .accordion__content {
        display: block;
      }
      .accordion__btn {
        transform: rotate(180deg);
      }
      .accordion__label_text {
        color: #313247;
      }
    }
  }
}

.update-table {
  &__btn {
    &_box {
      width: 35%;
      margin: 0 0 0 auto;
    }
  }
}

.comment {
  &__container {
    display: flex;
    padding-bottom: 1.6rem;
  }

  &__avatar {
    border-radius: 50%;
    width: 4rem;
    height: 4rem;
    margin-right: 0.8rem;
  }

  &__content {
    width: 100%;

    &_name {
      color: $clr-purple;
      padding-bottom: 0.5rem;
      font-weight: 500;
      font-size: 1.2rem;
      line-height: 1.4rem;
    }

    &_row {
      display: flex;
    }

    &_amount {
      color: #313247;
      font-size: 1.6rem;
      line-height: 1.9rem;
      padding: 8px 12px;
      background-color: #e7e7eb;
      border-radius: 2px;
      margin-right: 0.8rem;
    }

    &_comment {
      width: 100%;
      padding: 8px 12px;
      box-sizing: border-box;
      color: #4d4e67;
      background-color: #fafafb;
      border-radius: 2px;
      margin-right: 15px;
    }

    &_text_box {
      display: flex;
      width: 100%;
      align-items: center;
    }

    &_edit,
    &_delete {
      display: block;
      background-position: center;
      background-repeat: no-repeat;
      background-size: contain;
    }

    &_edit {
      width: 1.8rem;
      height: 1.8rem;
      background-image: url('/assets/img/comment-edit.svg');
    }

    &_delete {
      background-image: url('/assets/img/comment-delete.svg');
      width: 1.8rem;
      height: 2rem;
      margin-left: 18px;
    }

    &_data {
      color: #a9a9a9;
      font-size: 1.2rem;
      line-height: 1.4rem;
      padding-top: 0.6rem;
      display: block;
    }

    &_files {
      width: 18px;
      height: 18px;
      margin-right: 18px;
    }

    &_controls {
      display: flex;
    }

    &_delete {
      &_box {
        display: flex;
        padding-top: 0.8rem;
      }

      &_btn {
        margin-left: 0.8rem;

        @media screen and (min-width: 480px) {
          min-width: 7.1rem;
        }
      }
    }
  }

  &-edit {
    &__textarea {
      &--field {
        height: 3.5rem;
      }

      .form__textarea {
        min-height: auto;
        padding: 3px 12px 8px 12px;
        box-sizing: border-box;
        height: 100%;
        // overflow: hidden;
      }
      app-control-error {
        p {
          position: relative;
          top: auto;
        }
      }
    }

    &__row {
      margin-bottom: 0.8rem;
      @include mobile {
        flex-direction: row;
      }
      &_btn {
        width: 40%;
        @include mobile {
          flex-direction: row;
        }

        .main-btn {
          padding: 8px 9px;
        }
      }
    }
  }

  &-add {
    &__radio {
      &_box {
        display: flex;
        flex-direction: column;
      }

      &_form {
        display: flex;
        align-items: center;
        margin-bottom: 1rem;
        .form__radio-label {
          height: 1.8rem;
        }
      }
    }

    &__textarea {
      .form__textarea {
        min-height: 19.7rem;
      }
    }

    &__btns {
      padding-bottom: 1.5rem;
      width: 35%;
      margin: 0 0 0 auto;

      @include mobile {
        flex-direction: row;
        width: 100%;
      }
    }
  }
}

.modal-small {
  width: 97%;
}
