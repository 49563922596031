.boat-task-card {
  box-shadow: 0 2px 2px rgba(43, 28, 54, 0.16);
  background-color: $light-color;
  border-radius: 1.6rem;
  padding: 1.2rem 1.6rem;
  margin-bottom: 1.2rem;
  cursor: pointer;

  @include desktop {
    width: 37rem;
  }

  @include tablet-m {
    width: 100%;
  }

  .boat-task-selection {
    background-color: $yellow-color;
  }
}

.boat-task-card-tooltip {
  filter: drop-shadow(0px 4px 8px rgba(53, 40, 113, 0.26));
  .tooltip {
    padding: 0.8rem;
    position: relative;
    display: inline-block;
    box-sizing: content-box;
  }
  .tooltip__text {
    color: $clr-light-grey;
    font-size: 1.2rem;
    letter-spacing: 0.01em;
    font-weight: 500;

    .boat-task-selection {
      background-color: $yellow-color;
    }
  }
  &.tooltip-pane.top .tooltip::before {
    transform: none;
  }

  .task-tooltip {
    &__text {
      display: block;
    }
  }
}

.selected .boat-task-card {
  box-shadow: 0 0 0 2px $clr-primary-and-accent !important;
}

.expired .boat-task-card {
  box-shadow: 0 0 0 2px $seaz-alarm-light;
}

.new .boat-task-card {
  box-shadow: 0 0 0 2px $dark-green;
}

.boat-task-card-text {
  font-size: 1.2rem;
  font-weight: 400;
  line-height: 120%;
}

.boat-task-card-image {
  position: relative;
  width: 100%;
  height: 16.9rem;
  border-radius: 1.2rem;
  margin-bottom: 0.8rem;

  &__img {
    display: block;
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 1.2rem;
  }
}

.boat-task-card-system-actions {
  @include d-flex-space-between;
  align-items: center;
}

.boat-task-card-chips {
  margin-right: 0.8rem;

  .task-type {
    @include d-flex-center-content;
    padding: 0.6rem 0.9rem;
    border-radius: 1.8rem;
    font-size: 1.2rem;
    font-weight: 400;
    line-height: 120%;
  }

  .task-type--analyse {
    background-color: #d8efd4;
    color: $greeny-border-bg;

    app-seazone-icon svg {
      fill: $greeny-border-bg;
    }
  }

  .task-type--wrench {
    background-color: $m-orange;
    color: $seaz-warning;

    app-seazone-icon svg {
      fill: $seaz-warning;
    }
  }

  .task-type--cogs {
    background-color: $installation;
    color: $seaz-custom;

    app-seazone-icon svg {
      fill: $seaz-custom;
    }
  }

  .task-type--other {
    background-color: $clr-grey;
    color: $seaz-text;
  }

  app-seazone-icon {
    margin-right: 0.3rem;

    svg {
      width: 14px;
      height: 14px;
    }
  }
}

.boat-task-card-system-breadcrumbs {
  font-size: 12px;
  line-height: 1.4rem;
  color: $profile-month;
}

.boat-task-card-description {
  margin-top: 0.8rem;
  overflow: hidden;

  &__text {
    font-size: $base-font-size;
    line-height: 1.6rem;
    color: $seaz-text;
    @include multiline-truncation(2);
  }
}

.boat-task-card-performer-info {
  @include d-flex-space-between;
  align-items: center;
  margin-top: 1.3rem;
}

.boat-task-card-performer-info-icons {
  @include d-align-items-center;
}

.boat-task-card-performer-info-icon {
  @include d-align-items-center;
  color: $profile-month;
  @include indent-not-last-of-type(0 1.3rem 0 0);

  app-seazone-icon {
    margin-right: 0.3rem;
  }
}

.boat-task-card-performer {
  @include d-flex-space-between;
  align-items: center;
  margin-top: 1.3rem;

  &__text {
    width: 50%;
    color: $seaz-edit-hover;
    margin-right: 1.4rem;
  }

  &__photo {
    display: block;
    border-radius: 50%;
  }
}

.boat-task-card-performer-user-wrap {
  @include d-align-items-center;
  justify-content: flex-end;
  width: 100%;
}

.boat-task-card-points {
  white-space: pre-wrap;
  @include d-align-items-center;
  margin-top: 1.2rem;
  &--success {
    color: $seaz-success;
  }
  &--warning {
    color: $seaz-warning;
  }
  &--alarm {
    color: $seaz-alarm;
  }
}

.boat-task-card-tag {
  background-color: $seaz-bg;
}

.date-info-tag {
  @include multiline-truncation(1);

  &__in-line {
    white-space: nowrap;
  }
}

.boat-task-card-points-directory-item {
  @include d-align-items-center;

  app-boat-task-directory-item {
    @include indent-not-last-of-type(0 1.4rem 0 0);
  }

  .task-priority-type--owners {
    app-seazone-icon {
      svg {
        fill: $seaz-mulberry;
      }
    }
  }

  .task-priority-type--urgent {
    app-seazone-icon {
      svg {
        fill: $seaz-warning;
      }
    }
  }

  .task-priority-type--warranty {
    app-seazone-icon {
      svg {
        fill: $greeny-border-bg;
      }
    }
  }
}

.boat-task-card-info-task {
  @include d-flex-space-between;
  align-items: center;
}

.boat-task-card-task-tags {
  @include d-align-items-center;
  flex-wrap: wrap;
}

.boat-task-card-task-tag {
  display: block;
  font-size: 1.2rem;
  line-height: 120%;
  color: $seaz-document-count;
  padding: 0.5rem 0.6rem;
  border-radius: 2.4rem;
  background-color: $seaz-bg;
  margin: 0.8rem 0.8rem 0 0;

  &__val {
    font-size: 1.2rem;
    line-height: 120%;
    color: $seaz-edit-hover;
    margin-top: 0.8rem;
  }
}

.boat-task-card-info-task-status {
  margin: 0.8rem 0 0 1rem;

  .task-status-type {
    font-size: 1.2rem;
    line-height: 120%;
    color: $light-color;
    padding: 0.5rem 0.8rem;
    border-radius: 1.8rem;
  }

  .task-status-type--open {
    background-color: rgb(76, 206, 255);
  }

  .task-status-type--done {
    background-color: rgb(58, 174, 39);
  }

  .task-status-type--canceled {
    background-color: rgb(189, 189, 189);
  }
}

.fleet-boat-task-list {
  max-height: 60rem;
  min-height: 60rem;
  overflow: scroll;
  margin: 2.4rem 0 0;
  padding: 0 0.8rem 0.8rem 0;

  @include tablet {
    margin: 1.2rem 0;
    max-height: 130rem;
    min-height: 130rem;
  }
  @include custom-scroll-bar;
  &__item {
    &:not(:last-child) {
      margin-bottom: 1.2rem;
    }
  }
  &.fleet-boat-task-list-not-scrollable {
    min-height: fit-content;
    &.cdk-virtual-scrollable {
      contain: content;
    }
  }
  .cdk-virtual-scroll-content-wrapper {
    padding-right: 0.8rem;
    padding-bottom: 0.8rem;
  }
}

.fleet-boat-task-item {
  background-color: $clr-white;
  border: 1px solid #f5f5f5;
  box-shadow: 0 2px 2px rgba(43, 28, 54, 0.16);
  border-radius: 1.6rem;
  padding: 1.2rem;
  cursor: pointer;
  display: grid;
  grid-template-areas: 'description body action';
  grid-template-columns: minmax(35.7rem, 35.6rem) 1fr 3.2rem;
  @include large {
    grid-template-columns: minmax(26.5rem, 26.5rem) 1fr 3.2rem;
  }

  @include laptop {
    grid-template-areas:
      'description action'
      'body body';
    grid-template-columns: 1fr 3.2rem;
    grid-row-gap: 1.4rem;
  }

  &-description {
    display: flex;
    align-items: flex-start;
    grid-area: description;
    &__img-box {
      display: flex;
      min-width: 7.6rem;
      width: 7.6rem;
      height: 7.6rem;
    }
    &__type {
      width: fit-content;
      margin-bottom: 0.3rem;
    }
    &__img {
      width: 100%;
      aspect-ratio: 1;
      border-radius: 1.2rem;
      object-fit: cover;
    }
    &__content {
      display: flex;
      flex-direction: column;
      padding: 0 1.2rem;
    }
    &__name {
      font-weight: 500;
      font-size: 2rem;
      line-height: 2.3rem;
      word-break: break-word;
      margin-bottom: 0.3rem;
      @include multiline-truncation(2);
    }
    &__by-crew {
      font-weight: 400;
      font-size: 14px;
      line-height: 16px;
      display: flex;
      align-items: center;
      color: #828282;
    }
  }
  &-body {
    grid-area: body;
    display: grid;
    grid-template-areas: 'system status assignee estimated';
    grid-template-columns: 2fr 1fr 1fr 1fr;

    @include laptop {
      grid-template-areas:
        'assignee status'
        'system system'
        'estimated estimated';
      grid-template-columns: 1fr auto;
      grid-row-gap: 1.2rem;
      grid-column-gap: 2.4rem;
    }

    &__row {
      display: flex;
      flex-direction: column;
      position: relative;
      padding: 0 2.4rem;
      @include left-border;
      @include laptop {
        padding: 0;
        &::before {
          display: none;
        }
      }
    }
    &__row-system-name {
      grid-area: system;
      .breadcrumbs {
        font-size: 1.6rem;
        line-height: 1.9rem;
        word-break: break-word;
      }
    }
    &__row-status {
      grid-area: status;
      .boat-maintenance-task-view-modal-header__status-change {
        display: flex;
      }
    }
    &__row-assignee-name {
      grid-area: assignee;
      @include laptop {
        @include right-border;
      }
    }
    &__row-estimated {
      grid-area: estimated;
    }
    &__title {
      font-size: 1.4rem;
      line-height: 1.6rem;
      color: $clr-light-grey;
      margin-bottom: 0.4rem;
    }

    &__date {
      font-size: 1.2rem;
      line-height: 120%;
      margin-top: 0.3rem;
    }

    &__text {
      font-size: 1.6rem;
      line-height: 1.9rem;
      word-break: break-word;
      color: $seaz-text;
      margin-bottom: 0.4rem;
      @include multiline-truncation(2);

      &-position {
        font-size: 1.4rem;
        color: $seaz-about-text;
      }
    }
    &__no-data {
      app-seazone-icon {
        justify-content: flex-start;
      }
    }
  }
  &-action {
    grid-area: action;
    @include d-flex-center-content;
  }
}

//.fleet-boat-task-item-body {
//  @include d-flex-space-between;
//  align-items: center;
//  gap: 0 2.4rem;
//  grid-area: body;
//  &__row {
//    position: relative;
//    flex-grow: 1;
//    width: 33.3%;
//    max-width: 20rem;
//    padding: 1rem 0 1rem 2.4rem;
//
//    &:before {
//      content: '';
//      position: absolute;
//      top: 0;
//      left: 0;
//      width: 0.1rem;
//      height: 100%;
//      background-color: $clr-ashy-grey;
//    }
//  }
//
//  &__title {
//    font-size: $base-font-size;
//    line-height: 1.6rem;
//    color: $seaz-document-count;
//    margin-bottom: 0.4rem;
//  }
//
//  &__text {
//    font-size: 1.6rem;
//    font-weight: 500;
//    line-height: 1.9rem;
//    color: $seaz-text;
//    overflow-wrap: break-word;
//  }
//
//  @include desktop {
//    align-items: flex-start;
//    gap: 0 1rem;
//
//    &__row {
//      padding: 1rem 0 1rem 1.4rem;
//    }
//
//    &__text {
//      font-size: $base-font-size;
//    }
//  }
//
//  @include laptop {
//    grid-area: body;
//  }
//
//  @include tablet {
//    &__row {
//      max-width: initial;
//      padding: 0;
//
//      &:before {
//        display: none;
//      }
//
//      &--location-item {
//        display: none;
//      }
//    }
//  }
//}
//.fleet-boat-task-item-body__row-status-mobile{
//  display: none;
//}
//.fleet-boat-task-item-description__type-mobile{
//  display: none;
//}
//@media screen and (max-width: 768px){
//  //.fleet-boat-task-item-description__type-mobile{
//  //  display: block;
//  //  grid-area: type-mobile;
//  //}
//  //.fleet-boat-task-item-description__by-crew{
//  //  display: none;
//  //}
//  //.fleet-boat-task-item-description__repeat{
//  //  display: none;
//  //}
//  //.boat-maintenance-task-view-modal-header__status-date{
//  //  margin-top: -1.5rem;
//  //  font-size: 1.2rem;
//  //  color: #828282
//  //;
//  //}
//  //.fleet-boat-task-item{
//  //  grid-gap:0;
//  //  grid-template-columns: 1fr;
//  //  grid-template-areas: "description"
//  //                        "body";
//    //&-description{
//    //  &__type{
//    //    &-desktop{
//    //      display: none;
//    //    }
//    //  }
//    //}
//  }
//  .fleet-boat-task-item-body{
//    display: grid;
//    gap: 1.4rem;
//    grid-template-areas: "assignee-name status"
//                          "system-name system-name"
//                          "estimated estimated";
//    padding-top: 1.45rem;
//    &__row{
//      width: auto;
//      &-system-name{
//        grid-area: system-name;
//      }
//      &-status{
//        display: block;
//        grid-area: status;
//        &-mobile{
//          display: block;
//        }
//      }
//      &-assignee-name{
//        grid-area: assignee-name;
//      }
//      &-estimated{
//        grid-area: estimated;
//      }
//      &-notes{
//        display: none;
//        grid-area: notes;
//      }
//    }
//  }
//}
