.cover-letter-modal {
  &__text {
    line-height: 1.6rem;
    color: $seaz-text;
    margin-top: 3rem;
  }

  &__download {
    display: flex;
    align-items: center;
    font-size: 1.6rem;
    line-height: 1.9rem;
    color: $profile-month;
    margin-top: 1.2rem;
    cursor: pointer;
  }

  &__download-icon {
    display: block;
    margin-right: 0.7rem;
  }

  &__footer {
    display: flex;
    justify-content: flex-end;
    margin-top: 3.2rem;
    padding-top: 3rem;
    border-top: 1px solid $seaz-head-arrow;
  }

  .modal-close {
    top: 3.5rem;
  }
}

.seaz-modal.modal-small.cover-letter-modal {
  border-radius: 1.2rem;
}
