.boat-inventory-status-change-modal {
  &__info {
    font-size: 1.6rem;
    line-height: 1.9rem;
    color: $seaz-text;
    margin-top: 3rem;
  }
}

.boat-inventory-status-change-modal-form {
  margin-top: 3rem;

  &__text-area {
    .wrapper-rounded .form-field--content {
      height: 8rem;
    }
  }
}

.boat-inventory-status-change-modal-form-footer {
  @include footer-with-border-left-btn(0, 4.6rem 0 0 0);
  border: none;

  &__btn {
    @include indent-not-last-of-child(0 2.4rem 0 0);
  }
}
