.boat-inventory-quick-filter-top {
  @include d-flex-space-between;
  align-items: center;

  &__actions {
    @include d-align-items-center;
    flex-shrink: 0;
    margin-left: 1.6rem;

    &--results {
      display: none;
    }

    &--group {
      @include d-align-items-center;
    }
  }

  .boat-inventory-filter-btn {
    @include indent-not-last-of-child(0 1.2rem 0 0);
  }

  @include tablet {
    flex-direction: column;
    align-items: flex-start;

    &__actions {
      width: 100%;
      margin: 0.8rem 0 0;
      flex-shrink: 1;
      justify-content: space-between;

      &--results {
        display: block;
      }

      &--group {
        display: none;
      }
    }

    .boat-inventory-filter-btn {
      @include indent-not-last-of-child(0);
    }
  }

  @media screen and (max-width: 374px) {
    &__actions {
      flex-direction: column;
      align-items: flex-start;
      gap: 1.6rem 0;

      .upload-action {
        width: 100%;

        .boat-inventory-filter-btn {
          width: 100%;
        }
      }
    }
  }
}

.boat-inventory-quick-filter-bottom {
  @include d-flex-space-between;
  align-items: center;
  margin-top: 1.6rem;

  .fleet-chips {
    @include d-align-items-center;
    margin-top: 0;

    app-chips-checkbox-item {
      margin-top: 0;
    }

    .chips-item.selected {
      box-shadow: 0 0 0 1px #432791;
    }
  }

  .boat-inventory-download,
  .boat-inventory-manage {
    display: none;
  }

  @include laptop {
    justify-content: flex-start;

    .boat-tasks-filters-btn {
      background-color: inherit;
      margin-right: 1.6rem;
    }
  }

  @include tablet {
    .fleet-chips {
      display: none;
    }

    .maintenance-list-filters-actions {
      flex-direction: row;
    }

    .boat-inventory-download,
    .boat-inventory-manage {
      @include d-flex-center-content;
      padding: 1rem;
    }

    .boat-inventory-download {
      margin-right: 1.2rem;
    }

    .boat-tasks-filters-btn {
      margin-right: 0;
    }
  }

  @include mobile {
    .boat-tasks-menu-action-filters,
    .maintenance-list-filters-actions {
      width: 100%;
    }
  }
}

.boat-inventory-download {
  &__icon-row {
    transform: rotateZ(-90deg);
  }
}

.inventory-quick-filter-chips-title {
  font-size: $base-font-size;
  font-weight: 500;
  color: $seaz-text;
  margin-right: 1.6rem;
}

.boat-inventory-quick-filter-badges {
  margin-top: 1.6rem;

  .financial-data__badge_label {
    display: none;
  }

  .financial-data__badge {
    border: none;
    background-color: $seaz-bg;
    border-radius: 0.4rem;
    margin: 1rem 1rem 0 0;
    font-size: 1.2rem;
    font-weight: 400;
    line-height: 120%;
    color: $seaz-text;
  }

  .badge-close-btn {
    margin-left: 0.8rem;
  }

  .remove-all-badge {
    margin-right: 1rem;
  }
}

.boat-inventory-filter-form {
  margin-top: 3rem;

  .form-field--wrapper__row .form-field--label {
    max-width: 16rem;
  }

  .plug-label {
    width: 16rem;
    max-width: 16rem;
    min-width: 16rem;
  }

  .status-label {
    margin-top: 1.8rem;
  }

  &__group-container {
    align-items: center;

    app-filter-group-container {
      width: 100%;
    }

    .select-content {
      width: 18rem;
      overflow: visible;
    }
  }

  @include tablet {
    .form-field--wrapper__row .form-field--label {
      max-width: 14rem;
    }

    .plug-label {
      width: 14rem;
      max-width: 14rem;
      min-width: 14rem;
    }
  }

  @include mobile {
    &__group-container {
      align-items: flex-start;
    }

    .form-field--wrapper__row .form-field--label {
      max-width: initial;
    }

    .plug-label {
      width: 100%;
      max-width: initial;
      min-width: auto;
      margin: 0 0 0.8rem 0;
    }

    .fleet-chips {
      margin-top: 0;
    }
  }
}

.date-control-row {
  align-items: center;

  @include mobile {
    align-items: flex-start;
  }
}

.boat-maintenance-task-log-form-date-control {
  @include d-align-items-center;

  .form-field--wrapper .form-field--label {
    display: none;
  }

  app-date-control {
    @include indent-not-last-of-child(0 1.6rem 0 0);
  }

  @include tablet {
    flex-direction: column;
    align-items: flex-start;
    width: 100%;

    app-date-control {
      @include indent-not-last-of-child(0 0 1.2rem 0);
    }
  }
}

.boat-maintenance-task-log-form-footer {
  @include footer-with-border-left-btn(0 0 1rem 0, 6.2rem 0 0 0);
  border: none;

  &__btn {
    @include indent-not-last-of-child(0 2.4rem 0 0);
  }
}

.boat-inventory-download-dropdown {
  width: 100%;
  max-width: 30rem;
  background-color: $clr-white;
  border-radius: 0.3rem;
  box-shadow: 0 0.1rem 0.4rem 0 rgb(0 0 0 / 24%);

  &__item {
    display: block;
    width: 100%;
    font:
      1.6rem/2.2rem 'Roboto',
      sans-serif;
    text-align: left;
    color: $clr-main;
    padding: 1rem 1.6rem;
    transition: background-color 0.3s;

    &:hover {
      background-color: $seaz-alert-info-bg;
    }
  }
}
