.group-action-buttons-container {
  display: flex;
  align-items: center;

  .crew-id-box {
    margin-left: 1.2rem;
  }

  @include mobile {
    flex-direction: column;
    align-items: flex-start;

    .crew-id-box {
      margin: 1.2rem 0 0;
    }
  }
}

.group-action-buttons {
  display: flex;
  align-items: center;

  &__button {
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    width: 4rem;
    height: 4rem;
    border-radius: 50%;
    transition: opacity 0.3s;

    &:hover {
      @include hover-opacity(0.7);
    }

    &:not(:last-child) {
      margin-right: 0.8rem;
    }

    .seaz-search__card_controls--new {
      top: 1px;
      right: 1px;
    }

    &--sharing {
      background-color: $seaz-text;
    }

    &--note {
      background-color: $seaz-edit-hover;
    }

    &--web {
      background-color: $dark-green;
    }

    &--contacts {
      background-color: $seaz-success;

      &.disabled {
        background-color: $clr-grey;
      }
    }

    &--play {
      background-color: $clr-primary-and-accent;

      &:before {
        content: '';
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-20%, -50%);
        border: 0.8rem solid transparent;
        border-left: 1.3rem solid #fff;
      }

      &:disabled {
        background-color: $clr-grey;
      }
    }

    &--like {
      background-color: $clr-grey;

      &.liked {
        background-color: $clr-primary-and-accent;
      }
    }
  }
}
