.completion-modal {
  &__text {
    font-size: 1.6rem;
    font-weight: 400;
    line-height: 1.9rem;
    color: $seaz-about-text;
    margin-top: 3rem;
  }

  &__footer {
    @include footer-with-border-left-btn(0, 3rem 0 0);
    border: none;
  }

  &__btn {
    display: block;

    &:not(:last-child) {
      margin-right: 1.2rem;
    }
  }
}
