.general-experience-management {
  &__row {
    align-items: center;

    app-seazone-select:first-of-type {
      margin-right: 1.6rem;
    }
  }

  &__label {
    width: 100%;
    max-width: 14rem;
    font-size: 1.4rem;
    line-height: 2rem;
    color: #010202;
    margin-right: 7rem;
  }

  &__select {
    .select-value {
      display: block;
      width: 30rem;
    }
  }

  .seaz-crew__btn_box {
    border: none;
    padding: 3rem 0 !important;
  }

  @media screen and (max-width: 800px) {
    &__label {
      margin-right: 2rem;
    }
  }

  @media screen and (max-width: 650px) {
    &__row {
      flex-direction: column;
      align-items: flex-start;
    }
  }
}
