.boat-position-history-title {
  text-transform: uppercase;
  margin-top: 3.1rem;
}

.boat-position-history-box {
  border: 0.1rem solid $seaz-alert-info-bg;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.04);
  border-radius: 1.6rem;
  background-color: $light-color;
  padding: 2.4rem;

  @include tablet {
    padding: 1.2rem;
  }
}

.boat-position-history-box-wrap {
  @include indent-not-last-of-child(0 0 2.4rem 0);
}

.boat-position-history-box-title {
  text-transform: uppercase;
}

.boat-position-history-map {
  .map-container {
    margin-top: 1.6rem;
  }

  .leaflet-container {
    border-radius: 1.2rem;
  }

  .leaflet-pane,
  .leaflet-top,
  .leaflet-bottom {
    z-index: 5;
  }
}

.boat-position-history-statistics {
  @include box-grid(repeat(auto-fit, minmax(25rem, 1fr)), initial, initial, 1.2rem 2.4rem);
  margin-top: 1.6rem;

  @include tablet {
    grid-gap: 1.2rem;
  }
}

.boat-position-history-statistic {
  border: 1px solid $seaz-head-arrow;
  border-radius: 1.2rem;
  padding: 1.6rem;

  &__title {
    font-size: $base-font-size;
    line-height: 1.6rem;
    color: $seaz-text;
    margin-bottom: 0.8rem;
  }

  &__val {
    font-size: 3rem;
    font-weight: 500;
    line-height: 145%;
    color: $seaz-text;
  }

  @include tablet {
    padding: 1.2rem;
  }
}

.cruising-log-table-wrap {
  position: relative;
  width: 100%;
}

.cruising-log-table-wrapper {
  position: relative;
}

.cruising-log-table-container {
  max-width: 100%;
  overflow-x: auto;
}

.cruising-log-table {
  margin-top: 2.2rem;
  border-collapse: separate;
}

.cruising-log-table-cell {
  font-size: $base-font-size;
  font-weight: 400;
  line-height: 16px;
  text-align: left;
  color: $seaz-text;
  vertical-align: middle;
}

.cruising-log-table-th {
  padding: 0.8rem;
  min-width: 13rem;

  &__lg {
    min-width: 36rem;
    max-width: 40rem;
  }
}

.cruising-log-table-row {
  &:nth-child(2n + 1) {
    .cruising-log-table-td {
      background-color: $seaz-bg;
    }
  }
}

.cruising-log-table-td {
  padding: 1.4rem 0.8rem;
}

.boat-position-history-no-data {
  @include d-flex-center-content;
  flex-direction: column;
  height: 49.8rem;

  &__text {
    font-weight: 400;
    font-size: 16px;
    line-height: 19px;
    color: $profile-month;
    margin-top: 1.6rem;
  }
}

.boat-position-history-no-data-icon {
  @include d-flex-center-content;
  background-color: $clr-ashy-grey;
  border-radius: 1.6rem;
  padding: 2.8rem;
}
