.boat-maintenance-checklists-view-list {
  max-height: 65rem;
  overflow-y: auto;

  &.scrollable {
    @include custom-scroll-bar;
    padding-right: 0.5rem;
  }

  app-boat-maintenance-checklist-item {
    @include indent-not-last-of-child(0 0 1.2rem 0);
  }
}

.boat-maintenance-checklists-view-item {
  @include box-grid(3.2rem 1fr 7.4rem, initial, 'grid-count-item grid-description-item grid-actions-item', 0 1.6rem);
  align-items: center;
  background-color: $light-color;
  border: 1px solid $seaz-head-arrow;
  border-radius: 1.2rem;
  padding: 1.6rem;
  font-size: $base-font-size;
  color: $seaz-text;
  line-height: 1.6rem;
  @include indent-not-last-of-child(0 0 1.2rem 0);

  .grid-count-item {
    grid-area: grid-count-item;
  }

  .grid-description-item {
    grid-area: grid-description-item;
  }

  .grid-actions-item {
    grid-area: grid-actions-item;
  }

  &__count {
    display: block;
  }

  .view-item-with-left-line {
    height: 100%;
    position: relative;
    padding-left: 1.6rem;

    &:before {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      width: 0.1rem;
      height: 100%;
      background-color: $seaz-head-arrow;
    }
  }

  @include tablet {
    @include box-grid(
      1fr 5.7rem,
      initial,
      'grid-description-item grid-description-item' 'grid-count-item grid-actions-item',
      1.2rem 1.6rem
    );

    .view-item-with-left-line {
      padding-left: 0;

      &:before {
        display: none;
      }
    }
  }
}

.boat-maintenance-checklists-view-item-description {
  &__decline {
    @include d-align-items-center;
  }

  &__text-decline {
    font-size: 1.2rem;
    line-height: 120%;
    color: $seaz-alarm;
    margin-top: 0.4rem;
  }

  &__btn {
    margin-left: 0.8rem;
  }

  @include tablet {
    border-bottom: 1px solid $seaz-bg;
    padding-bottom: 0.8rem;
  }
}

.boat-maintenance-checklists-view-item-actions {
  @include d-flex-space-between;
  align-items: center;
  gap: 0 1rem;
}
