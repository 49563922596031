.checklist-status-bar {
  min-width: 15.6rem;
  background-color: $light-color;
  box-shadow: 0 2px 2px rgba(43, 28, 54, 0.16);
  border-radius: 1.2rem;
  padding: 1.2rem;
}

.checklist-status-bar-info {
  @include d-flex-space-between;
  align-items: center;
  font-size: $base-font-size;
  line-height: 1.6rem;
  color: #828282;
  gap: 0 1rem;

  &__val {
    font-weight: 500;
    line-height: 120%;
    color: $seaz-text;
  }
}

.checklist-status-bar-slide {
  margin-top: 0.6rem;

  &__container {
    width: 100%;
    height: 0.4rem;
    position: relative;
    border-radius: 10rem;
    background-color: $seaz-head-arrow;
  }

  &__range {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    background-color: $clr-primary-and-accent;
    border-radius: 10rem;
  }
}
