.additional-video {
  &__container {
    display: flex;
    align-items: center;
    width: 100%;
    padding: 1.2rem;

    &:nth-of-type(even) {
      background-color: #fff;
      border-radius: 0.8rem;
    }

    .seaz-uploader__video {
      width: max-content;
    }
  }

  &__title {
    width: 100%;
    font-size: 2rem;
    font-weight: 500;
    line-height: 1.4;
    color: $seaz-text;
  }

  &__action-btn {
    &:not(:last-of-type) {
      margin-right: 2.3rem;
    }
  }

  &__add-btn {
    width: max-content;
    margin-top: 3rem;
  }

  @include mobile {
    &__container {
      padding: 1.2rem 0;

      .seaz-uploader__video_play {
        top: 50%;
        transform: translate(0, -50%);
      }
    }

    &__title {
      font-size: 1.4rem;
      margin: 0 0.8rem 0 0.1rem;
      word-break: break-all;
    }
  }
}
