app-claim-supplier {
  display: block;
}

.claim {
  width: 100%;
  max-width: 46.4rem;
  margin: 0 auto;

  &__title-main {
    text-transform: uppercase;
    margin-bottom: 3.2rem;
  }

  &__title {
    font-size: 1.6rem;
    line-height: 1.9rem;
    color: $seaz-text;
  }

  &__search {
    @include d-align-items-center;
    margin-top: 3.2rem;

    .wrapper-rounded .form-field--content {
      border-radius: 0.4rem 0 0 0.4rem;
    }

    .seaz-filters__row_search-icon {
      border-radius: 0 0.4rem 0.4rem 0;
    }
  }

  &__counter {
    margin-top: 1.2rem;
    text-align: left;
    color: $profile-month;
    line-height: 1.6rem;
  }
}
