.manager-modal {
  padding: 2rem;

  @include tablet {
    padding: 0.6rem;
  }
}

.manager-modal-form {
  margin-top: 3rem;

  &__row {
    display: block;
  }

  .form-field--wrapper__row .form-field--label,
  .form__block-text {
    max-width: 16rem;
  }

  .wrapper-rounded.form-field--wrapper__row .form-field--label,
  .form__block-text {
    margin-right: 1rem;
  }

  @include tablet {
    .form-field--wrapper__row .form-field--label,
    .form__block-text {
      max-width: 14rem;
    }
  }

  app-seazone-image-file {
    @include indent-not-last-of-type(0 1.2rem 0 0);
  }
}

.manager-modal-form-uploader {
  width: 100%;

  .seaz-uploader {
    flex-direction: row;
  }

  .seaz-uploader__header {
    max-width: 16rem;
    min-width: 16rem;
    margin-right: 1rem;
  }

  .seaz-uploader__label {
    font-size: $base-font-size;
    font-weight: 400;
  }

  .seaz-uploader__choose_drop {
    width: 100%;
  }

  @include tablet {
    .seaz-uploader__header {
      max-width: 14rem;
      min-width: 14rem;
    }

    .seaz-uploader__label {
      text-align: left;
    }

    .seaz-uploader__box {
      align-items: flex-start;
    }

    .seaz-uploader__choose {
      justify-content: flex-start;
    }
  }

  @include mobile {
    .seaz-uploader {
      flex-direction: column;
    }

    .seaz-uploader__header {
      max-width: 100%;
      min-width: 100%;
    }
  }
}

.manager-modal-form-phone-container {
  @include indent-not-last-of-type(0 0 2.3rem);

  @include tablet {
    .supplier-edit-form-field-container__plug {
      max-width: 14rem;
    }
  }

  @include mobile {
    .form__checkbox-label {
      padding: 0;
    }
  }
}

.manager-modal-form-phone {
  @include d-flex-space-between;

  &__container {
    @include d-align-items-center;
    width: 100%;
  }

  &__btn,
  &__btn-wrap {
    min-width: 9.5rem;
    margin-left: 1.6rem;
  }

  .seaz-red-close {
    margin-left: 0;
    color: $seaz-alarm;
    border: 1px solid $seaz-bg;
  }

  .manager-text-plug {
    min-width: 16rem;
    margin-right: 1rem;
  }

  @include tablet {
    .manager-text-plug {
      max-width: 14rem;
      min-width: 14rem;
    }
  }

  @include mobile {
    flex-direction: column;

    &__container {
      &--create {
        flex-direction: column;
        .manager-modal-form-phone__btn {
          justify-content: center;
          width: 100%;
          margin: 1.8rem 0 0;
        }
      }
    }

    .manager-text-plug {
      min-width: 100%;
      margin: 0 0 0.8rem;
    }

    .manager-text-plug {
      min-width: 100%;
      margin: 0 0 0.8rem;
    }

    &__btn,
    &__btn-wrap {
      min-width: auto;
      margin-left: 1rem;
    }
  }
}

.manager-modal-form-text-area {
  .wrapper-rounded.form-field--wrapper__row .form-field--label {
    justify-content: flex-start;
  }
}

.manager-modal-form-footer {
  @include d-flex-space-between;
  align-items: center;
  margin-top: 4.6rem;

  app-seazone-checkbox {
    flex-shrink: 0;
  }

  &__action {
    flex-grow: 1;
    @include d-align-items-center;
    justify-content: flex-end;
  }

  &__btn {
    @include indent-not-last-of-type(0 1.2rem 0 0);
  }

  @include mobile {
    flex-direction: column;
    align-items: flex-start;

    &__action {
      @include d-align-items-center;
      width: 100%;
      margin-top: 1.2rem;
    }

    .form__checkbox-label-text {
      padding-left: 1.6rem;
    }

    .form__checkbox-label {
      padding: 0;
    }

    &__btn {
      width: 50%;
    }
  }
}

.manager-modal-container {
  margin-top: 3rem;

  &__file {
    margin-top: 1rem;
  }
}
