.login {
  background: white;
  height: 100%;

  &__content {
    margin-top: 2rem;
  }

  &__desc {
    font-size: 1.4rem;
    margin-top: 1.2rem;

    &_diff {
      opacity: 0.8;
      line-height: 2.2rem;

      @include mobile {
        line-height: 2rem;
      }
    }

    &_indent {
      margin-bottom: 3.8rem;
    }

    a {
      color: $clr-purple;
      font-weight: bold;
    }
  }

  &__logo-img {
    display: inline-flex;
    @include mobile {
      position: relative;
      border: 0.2rem solid white;
      border-radius: 1.1rem;
      img {
        width: 4.6rem;
        height: 4.6rem;
      }
    }
  }

  &__logo-text {
    margin-top: 1.3rem;
    @include mobile {
      position: absolute;
      top: -3.5rem;
      left: 50%;
      transform: translateX(-50%);
      margin-top: 1rem;
    }
  }

  &__logo-text_width img {
    width: 10.6rem;
  }

  &__text {
    color: white;
  }

  &__title {
    font-size: 2.4rem;
    font-weight: 500;
    color: $clr-purple;
    text-transform: uppercase;

    &_indent {
      margin-bottom: 0.8rem;
    }

    @include mobile {
      margin-bottom: 1.2rem;
    }
  }

  &__row {
    display: flex;
    flex-grow: 1;
    height: 100%;
    @include tablet {
      flex-direction: column;
      height: auto;
      overflow: auto;
    }
  }

  &__container {
    @include tablet {
      left: 1.6rem;
      right: 1.6rem;
    }
  }

  &__col {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 50%;
    &.full-width {
      width: 100%;
    }

    &.hide {
      display: none;
    }

    @include tablet {
      width: 100%;
    }

    @include mobile {
      .form__row {
        flex-wrap: wrap;
      }

      .form__col {
        width: 100%;
        &:not(:last-child) {
          margin: 0 0 2.4rem 0;
        }
      }
    }

    &_left {
      display: block;
      min-height: 100vh;
      color: #fff;
      background-image: url(/assets/img/login-image.png);
      background-size: cover;
      background-position: 50% 40%;
      background-repeat: no-repeat;
      padding: 2.4rem;
      background-color: #393d83;

      @include tablet {
        min-height: 45vh;
        background-position: center top;
        padding: 6rem 1.6rem 1.6rem 1.6rem;
        &.hidden__col {
          min-height: 12vh;
          .login__col-left-content {
            visibility: hidden;
            height: 0;
            margin: 0;
          }
        }
      }
    }

    &_right {
      justify-content: space-between;
      background-color: white;
      margin-top: 0;
      padding: 3rem;
      @include tablet {
        position: relative;
        border-radius: 1.6rem 1.6rem 0 0;
        z-index: 10;
        overflow: hidden;
        margin-top: -2rem;
      }
      @include large {
        padding: 1.6rem;
      }

      @media screen and (min-height: 700px) {
        padding: 7.2rem 1.6rem 1.6rem;
      }
    }
  }

  &__wrap {
    text-align: center;
    width: 100%;
    @include tablet {
      padding: 0.8rem 0 2.4rem 0;
    }

    &_left {
      max-width: 52.2rem;
    }

    &_right {
      max-width: 49rem;
      flex: 1 auto;
      display: flex;
      flex-direction: column;
      &.full-width {
        max-width: 100%;
      }
      @include tablet {
        border-bottom: 1px solid $seaz-head-arrow;
      }
    }

    @include mobile {
      margin-top: 0;
    }
  }
  &__logo_left {
    margin-bottom: 5.2rem;
  }

  &__logo_right {
    margin-bottom: 2rem;
    @include mobile {
      margin-bottom: 1.2rem;
    }
  }

  &__copyright {
    font-size: 1.2rem;
    color: #a9a9a9;
    margin-top: 2.3rem;
  }

  &__wrap-img {
    margin: 3rem 0;
    @include mobile {
      margin: 2.5rem 0;
    }
  }
  &__video {
    position: fixed;
    top: 0;
    bottom: 0;
    height: 100%;
    width: auto;
    object-fit: cover;
  }

  .company__col_left {
    background: linear-gradient(180deg, rgba(70, 166, 164, 0.8) 0%, rgba(63, 151, 149, 0.8) 100%),
      url('/assets/img/company-auth-bg.jpg');
  }

  &__header {
    @include tablet {
      position: fixed;
      top: 0;
      left: 0;
      padding: 3.3rem 1.6rem 2.5rem 1.6rem;
      width: 100%;
      background-image: url('/assets/img/login-image.png');
      background-size: cover;
      background-position: 50% 0;
      background-repeat: no-repeat;
      z-index: 1000;
    }

    display: flex;
    justify-content: flex-end;

    &--logo {
      display: none;
      flex: 1 auto;
      svg {
        width: 17.5rem;
        height: 2.1rem;
        fill: #fff;
      }
    }

    @include tablet {
      justify-content: center;
      align-items: center;

      text-align: center;
      &--logo {
        display: block;
      }
    }
  }

  &__back-btn {
    width: 21.2rem;
    font-weight: 500;
    letter-spacing: 0.1rem;
    text-transform: uppercase;
    color: #fff;
    border: 1px solid #fff;
    box-sizing: border-box;
    border-radius: 4px;
    padding: 1rem 1.6rem;

    &--desktop {
      display: flex;
      justify-content: space-between;
      align-items: center;
    }

    &--mobile {
      display: none;
      transform: rotate(180deg);
    }

    @include tablet {
      display: contents;
      transform: translate(0, -50%);
      width: auto;
      border: none;
      padding: 0;

      &--desktop {
        display: none;
      }

      &--mobile {
        display: block;
      }
    }
  }

  &__col-left-content {
    width: 100%;
    max-width: 46.6rem;
    margin: 4.5rem auto;
  }

  &__main-logo {
    text-align: center;
    margin-bottom: 4rem;

    @include tablet {
      display: none;
    }
  }

  &__welcome {
    margin-bottom: 1.6rem;

    &--title {
      font-size: 2.4rem;
      font-weight: 500;
      text-align: center;
      line-height: 2.8rem;
    }

    @include tablet {
      display: none;
    }
  }

  &__description {
    display: flex;
    align-items: flex-start;
    background: rgba(255, 255, 255, 0.18);
    border-radius: 1.2rem;
    padding: 1.6rem;
    backdrop-filter: blur(3.6rem);

    &.hide {
      display: none;
    }

    &--title {
      font-size: 2rem;
      font-weight: 500;
      margin-bottom: 0.4rem;
      line-height: 2.4rem;
    }

    &--text {
      font-size: 1.6rem;
      font-weight: 400;
      color: rgba(255, 255, 255, 0.72);
      line-height: 2.3rem;
    }

    &--text-note {
      font-style: italic;
      font-weight: 500;
      margin-top: 1.2rem;
      font-size: 1.4rem;
      line-height: 2rem;
    }

    b {
      font-weight: 500;
      color: #fff;
    }

    svg {
      width: 4.8rem;
      height: 4.8rem;
      fill: #fff;
      @include tablet {
        width: 3.2rem;
        height: 3.2rem;
      }
    }

    &:not(:last-of-type) {
      margin-bottom: 1.6rem;
    }

    @include tablet {
      padding: 1rem;
    }
  }

  &__description--content {
    margin-left: 1.4rem;
    &--mobile {
      display: none;
    }
    &--desktop {
      display: contents;
    }
    @include tablet {
      &--mobile {
        display: contents;
        .login__description--text {
          max-width: 34rem;
        }
      }
      &--desktop {
        display: none;
      }
    }
  }

  &__form-forgot {
    text-align: right;
    &--url {
      color: $clr-primary-and-accent;
      text-decoration: underline;
    }
  }

  &__form-buttons {
    @include d-flex-center-content;
    margin: 4rem auto 1rem;

    .main-btn {
      width: 50%;
      font-size: 1.6rem;
      letter-spacing: 0.1em;
      text-transform: uppercase;
    }

    @include tablet {
      width: 100%;
      margin: 4rem 0 1.6rem 0;
    }

    @include mobile {
      flex-direction: column;

      .main-btn {
        padding: 1rem 0.8rem;

        &:first-of-type {
          margin: 0 0 1.6rem;
        }
      }
    }
  }

  &__conditions {
    width: 100%;
    max-width: 30rem;
    margin: 0 auto;
    color: $seaz-text;
    line-height: 1.4;
  }

  &__agreement {
    margin-top: 4rem;

    .form__checkbox-label-text {
      color: $seaz-text;
      line-height: 1.4;
      padding-left: 1rem;
    }

    .form__checkbox {
      align-items: flex-start;
    }
  }

  &__to-registration {
    padding-top: 2.4rem;
    padding-bottom: 2.4rem;
    border-top: 1px solid $seaz-head-arrow;
    border-bottom: 1px solid $seaz-head-arrow;
    @include tablet {
      border-bottom: none;
      padding-bottom: 0;
    }

    &--text {
      font-size: 1.4rem;
      color: $seaz-text;
    }

    &--link {
      color: $clr-primary-and-accent;
      transition: opacity 0.3s;

      &:hover {
        opacity: 0.6;
      }
    }
  }

  &__contacts {
    max-width: 46.6rem;
    width: 100%;
    padding: 3.2rem 0;
    border-bottom: 1px solid $seaz-head-arrow;

    &--title {
      text-align: center;
      color: #828282;
    }
  }

  &__contacts-container {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 1.2rem;
    flex-wrap: wrap;

    @include tablet {
      flex-direction: column;
    }
  }

  &__contacts-content {
    display: flex;
    color: $seaz-text;
    align-items: center;

    &--icon {
      margin-bottom: -0.2rem;
      &:not(:last-of-type) {
        margin-right: 1rem;
      }
    }

    &--link {
      color: $seaz-text;
      margin-left: 0.9rem;
      transition: opacity 0.3s;

      &:hover {
        opacity: 0.6;
      }
    }

    &--separator {
      color: #828282;
      margin: 0 3.2rem;
    }
  }

  &__text-description {
    font-size: 1.6rem;
    line-height: 1.4;
    color: $seaz-text;
    margin: 0 0 1.2rem;
  }
}

.login__copyright-logo {
  text-align: center;
  margin-bottom: 2.4rem;
}

.seaz-registration-form {
  margin-top: 3.2rem;

  .form-field--hint {
    font-size: 1rem;
  }

  .form__row {
    margin-bottom: 3rem;
  }
}

// TODO: remove or rename temp class
.scroll-col {
  position: fixed;
  height: 100%;
  top: 8rem;
  overflow-y: auto;
}

app-supplier-auth {
  height: 100%;
}

.supplier-login {
  &__col-left {
    background-image: url('/assets/img/login-supplier.jpg');
    background-size: cover;
    background-position: 50% 23%;
    background-repeat: no-repeat;
    background-color: $bg-registration-page;
  }

  &__profile-form {
    margin-top: 3.2rem;
  }

  @include tablet {
    &__header {
      background: rgb(3, 160, 158, 0.63);
    }

    &__col-left {
      background-position: 50% 0;
    }
  }
}

.sing-in-back {
  display: block;
  width: 8.7rem;
  height: 3.2rem;
  padding: 0 0.8rem;
  margin: 3.2rem 0 0;
  font-size: 1.2rem;
  line-height: 3.2rem;

  @include tablet {
    margin: 2.4rem 0 0;
  }
}

.sing-in-back-icon {
  margin-right: 1rem;
}

app-login,
app-registration {
  overflow-y: auto;
}

.success-registration-title {
  font-weight: 500;
  text-transform: uppercase;
  margin-bottom: 3.2rem;
}

.success-registration-button {
  margin-top: 3.2rem;
  line-height: 2rem;
}
