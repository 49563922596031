.email-form {
  margin-top: 3.4rem;

  &__btn {
    padding: 0.9rem 2rem;
    margin-left: 1.6rem;
  }

  .form__row {
    margin-bottom: 3.2rem;
  }

  &__content {
    display: flex;
  }

  &__plug-label {
    width: 100%;
    max-width: 14rem;
    margin-right: 2rem;
  }

  &__controls {
    display: flex;
    justify-content: flex-end;
    border-top: 1px solid $seaz-head-arrow;
    padding: 3rem 0;
    margin-top: 3rem;
  }

  &__button {
    &:not(:last-of-type) {
      margin-right: 1.6rem;
    }
  }

  @include tablet {
    .form-field--wrapper.form-field--wrapper__row.wrapper-rounded .form-field--label,
    &__plug-label {
      max-width: 9rem;
    }
  }

  @include mobile {
    &__btn {
      margin: 1.6rem 0 0;
    }

    &__plug-label {
      display: none;
    }

    &__controls {
      flex-direction: column;
      justify-content: center;
    }

    &__button {
      width: 100%;

      &:not(:last-of-type) {
        margin: 0 0 1.6rem;
      }
    }
  }
}

.email-form-info {
  display: flex;
  flex-direction: column;
  width: 100%;

  &__content {
    display: flex;

    &:not(:last-of-type) {
      margin-bottom: 1.6rem;
    }
  }

  &__text {
    width: 100%;
    line-height: 1.6rem;
    word-break: break-all;
    color: $seaz-text;
    background-color: $seaz-bg;
    border-radius: 0.8rem;
    padding: 1.6rem;
    margin-right: 1.2rem;
  }

  &__btn {
    flex-shrink: 0;
    display: block;
    width: 2.4rem;
    height: 2.4rem;
    background-color: $seaz-bg;
    border-radius: 50%;
    text-align: center;
  }
}
