app-working-time,
app-seazone-checkbox-triggered-label {
  display: block;
  width: 100%;
}

.working-time {
  display: flex;

  &__label {
    flex-shrink: 0;
  }

  &__label-text {
    display: block;
    width: 100%;
    max-width: 10rem;
    padding-top: 10px;
  }

  &__control {
    width: 100%;
  }

  @include mobile {
    flex-direction: column;

    &__label-text {
      max-width: 100%;
    }
  }
}

.working-time-days {
  margin-bottom: 1.2rem;

  .checkbox-limited {
    position: relative;
    &.invalid .single-checkbox label {
      border-color: $seaz-error;
    }
  }
  .checkbox-limited .form-field--wrapper {
    display: flex;
    margin-bottom: 2.8rem;
  }

  .single-checkbox {
    input {
      display: none;
    }

    label {
      display: flex;
      justify-content: center;
      align-items: center;
      font-size: 1.4rem;
      font-weight: 500;
      letter-spacing: 0.1em;
      text-transform: uppercase;
      color: $profile-month;
      padding: 1.5rem 2rem;
      cursor: pointer;
      border: 1px solid #bdbdbd;

      @include tablet {
        padding: 1.5rem 0.5rem;
      }
    }

    input:checked + label {
      border-color: $seaz-primary-and-accent;
      background: $seaz-primary-and-accent;
      color: $clr-white;
    }

    &:first-child label,
    &:nth-child(6) label {
      border-radius: 0.8rem 0 0 0.8rem;
    }

    &:last-child label,
    &:nth-child(5) label {
      border-radius: 0 0.8rem 0.8rem 0;
    }

    &:nth-child(6) label {
      margin-left: 12px;

      @include mobile {
        margin-left: 0.5rem;
      }
    }

    &:nth-child(6) label,
    &:nth-child(7) label {
      padding: 1.5rem 1.8rem;

      @include tablet {
        padding: 1.5rem 0.5rem;
      }
    }
  }

  @include mobile {
    &__day {
      .form-field--wrapper__row {
        flex-direction: row;
      }
    }
  }
}

.working-time-range-field {
  margin-bottom: 2.1rem;

  &__group {
    display: flex;
    align-items: center;
    width: 100%;
  }

  &__label {
    flex-shrink: 0;
    font-size: 1.4rem;
    color: $clr-grey;
    margin-right: 0.8rem;

    &--first {
      min-width: 6.4rem;
    }
  }

  &__field {
    &:first-of-type {
      margin-right: 0.8rem;
    }
  }

  &__checkbox {
    margin-top: 2.7rem;

    .form__checkbox-input:checked + label:before {
      background-color: $seaz-mulberry;
    }

    .form__checkbox-label:before {
      border-color: $seaz-mulberry;
    }

    .form__checkbox-label-text {
      font-size: 1.4rem;
      color: $seaz-text;
    }
  }
}
