.fleet-guest-details-container {
  margin: 4.8rem auto 15rem;
  padding: 0 1.6rem;

  @include tablet {
    margin: 1.6rem auto 7rem;
  }
}

.fleet-guest-details-header {
  @include d-align-items-center;
  margin-bottom: 4.2rem;

  &__title {
    text-transform: uppercase;
  }

  @include tablet {
    margin-bottom: 1.2rem;
  }
}

.fleet-guest-navigation {
  display: block;
  margin-top: 3.8rem;

  @include tablet {
    margin-top: 2.4rem;
  }
}

.guests-nav-list {
  @include d-align-items-center;
}

.guests-nav-item {
  width: 50%;

  &:first-of-type {
    .guests-nav-link {
      border-radius: 0.8rem 0 0 0.8rem;
    }
  }

  &:last-of-type {
    .guests-nav-link {
      border-radius: 0 0.8rem 0.8rem 0;
    }
  }
}

.guests-nav-link {
  display: block;
  font-size: 1.4rem;
  font-weight: 500;
  line-height: 2rem;
  letter-spacing: 0.1em;
  text-transform: uppercase;
  text-align: center;
  color: $seaz-document-count;
  border: 1px solid $seaz-document-count;
  padding: 1.8rem 1rem;

  &.active {
    color: $clr-primary-and-accent;
    border-color: $clr-primary-and-accent;
  }
}

.fleet-guest-details-action-wrapper {
  display: flex;
  flex-direction: column;
  margin: 3.8rem 0;

  @include mobile {
    margin: 1.2rem 0 2.4rem 0;

    .fleet-guest-details-search {
      order: -1;
    }
  }
}

.fleet-guest-details-btn-box {
  display: flex;
  justify-content: flex-end;
  width: 100%;

  @include mobile {
    &__btn {
      width: 100%;
      margin-top: 2.4rem;
    }
  }
}

.fleet-guest-details-search {
  width: 100%;
  margin-top: 3.8rem;

  @include tablet {
    margin-top: 1.2rem;
  }
}

.fleet-guest-details-wrapper {
  margin-top: 3.2rem;

  @include tablet {
    margin-top: 2.4rem;
  }
}
