.supplier-info-menu {
  &__menu-item {
    flex-direction: column;
  }

  .user-info__menu_link-my-company {
    position: relative;
    padding: 0 1.8rem 0 0;

    &:before {
      content: '';
      border: 5px solid transparent;
      border-top: 6px solid white;
      position: absolute;
      right: 0;
      top: 34%;
    }
  }
}

.nav-sub-menu {
  top: 50px;
  transform: translate(-3%, 0) !important;
  padding: 1rem;

  &:before {
    content: '';
    position: absolute;
    top: -0.5rem;
    left: 4rem;
    width: 1rem;
    height: 1rem;
    background-color: #fff;
    transform: rotateZ(135deg);
    z-index: 100;
  }

  &__wrapper {
    position: relative;
    max-height: 33.2rem;
    @include custom-scroll-bar;
  }

  .menu {
    display: block;
    padding: 1.2rem 1.6rem;
  }

  .menu__item {
    &:not(:last-of-type) {
      margin-bottom: 1.3rem;
    }
  }

  .menu__link {
    min-height: auto;
    font-size: 1.4rem;
    font-weight: 500;
    margin: 0;
    padding: 1.4rem 0.8rem;
    color: $clr-primary-and-accent;
    border-radius: 0.8rem;
    transition: background-color 0.3s;

    &:hover {
      background-color: $clr-ashy-grey;
      @include desktop {
        background-color: transparent;
        outline: 1px solid $clr-white;
      }
    }

    &.active-item {
      border: 2px solid #c2bed4;
      opacity: 1 !important;
    }

    .menu__link-icon {
      opacity: 1;
      margin-right: 1rem;

      svg {
        width: 20px;
        height: 20px;
      }
    }
  }

  &__title {
    line-height: 1.6rem;
    color: $seaz-document-count;
    margin: 1.6rem 0 0.6rem;
  }

  @include desktop {
    background-color: initial;
    top: 0;
    transform: translate(0) !important;
    padding: 0;

    &:before {
      display: none;
    }

    &__wrapper {
      height: auto;
    }

    .menu {
      margin: 1.2rem 0 0;
    }

    .menu__link {
      width: 100%;
      color: $light-color;
    }

    .menu__item {
      &:not(:last-of-type) {
        margin-bottom: 1rem;
      }
    }

    &__title {
      margin: 1.6rem;
      text-transform: uppercase;
    }
  }
}

.supplier-nav-sub-menu__wrapper {
  overflow-y: auto;
}
