.overview-card {
  position: relative;
  width: 100%;
  min-width: 28rem;
  border: 0.4rem solid #fff;
  box-sizing: border-box;
  border-radius: 1.6rem;
  background-color: #fff;

  &:not(:last-of-type) {
    margin-right: 2rem;
  }

  &__title-box {
    position: relative;
    display: flex;
    align-items: flex-end;
    justify-content: center;
    padding: 0.6rem 0 1rem;
    border-radius: 1.6rem 1.6rem 0 0;
    z-index: 4;

    @media screen and (max-width: 1024px) {
      &:before {
        width: 100.6%;
      }
    }
  }

  &__title {
    position: relative;
    font-size: 1.8rem;
    font-weight: bold;
    color: #fff;
    z-index: 4;
  }

  &__title-icon {
    position: relative;
    width: 1.8rem;
    height: 1.8rem;
    color: $clr-primary-and-accent;
    background-color: #fff;
    padding-left: 0;
    margin: 0 0 0 1rem;
    font-weight: bold;
    z-index: 4;
    border-radius: 50%;
    font-size: 1.4rem;
    text-align: center;
    line-height: 1.8rem;

    &.all-inclusive {
      font-size: 1.4rem;
      margin: 0 0 0 0.4rem;
      background-color: #e0e0e0;
      color: white;
    }
  }

  &__content {
    //margin-top: 2.9rem;
    padding: 2.9rem 2.4rem 1.2rem;
    background-color: #fff;
    border-radius: 0 0 1.6rem 1.6rem;
  }

  &__content-col {
    display: flex;
    justify-content: space-between;
    width: 100%;
    height: 5.8rem;

    &:not(:last-of-type) {
      margin-bottom: 1.6rem;
    }
  }

  &__content-item {
    width: 50%;
    text-align: center;
  }

  &__content-item--profit {
    width: 100%;
  }

  &__val {
    font-size: 2.4rem;
    font-weight: bold;
    color: $clr-primary-and-accent;
    margin-bottom: 0.8rem;
    &.overview-card__daily {
      color: $seaz-aqua;
    }
  }

  &__val-income {
    font-size: 1.6rem;
    font-weight: 500;
    display: flex;
    justify-content: center;
    align-items: center;
    color: $clr-primary-and-accent;

    &--disable {
      color: $seaz-document-count;
    }
    &.overview-card__val-income__profit {
      color: $seaz-success;
    }
    &.error {
      color: $seaz-alarm;
    }
  }

  &__val-description {
    color: $seaz-document-count;
    text-transform: lowercase;
  }

  &__averages {
    margin-top: 4.1rem;
    color: $clr-light-grey;
    text-align: center;

    &--val {
      font-size: 1.6rem;
      font-weight: 500;
      margin-right: 0.8rem;
      color: $clr-primary-and-accent;
      &.overview-card__averages--daily {
        color: $seaz-aqua;
      }
    }

    &--item {
      &:not(:last-of-type) {
        margin-bottom: 1.6rem;
      }
    }
  }

  &__averages-title {
    position: relative;
    margin-bottom: 2rem;

    &:after,
    &:before {
      content: '';
      position: absolute;
      top: 50%;
      transform: translate(0, 50%);
      width: 30%;
      height: 0.1rem;
      background-color: $seaz-head-arrow;
    }

    &:before {
      left: 0;
    }

    &:after {
      right: 0;
    }
  }

  &__btn {
    display: block;
    width: 100%;
    font-size: 1.2rem;
    font-weight: 500;
    letter-spacing: 0.1em;
    text-transform: uppercase;
    color: #4f194a;
    padding: 0.5rem 0;
    margin-top: 2.4rem;
    border: 1px solid #4f194a;
    box-sizing: border-box;
    border-radius: 0.4rem;
    transition: opacity 0.3s;

    &:hover {
      opacity: 0.6;
    }
  }
}

.card-total {
  &__header {
    text-align: center;
    margin: 0 2rem 3.9rem;
  }
}

.card-charter {
  border-color: rgba(53, 31, 115, 1);
  background-color: rgba(53, 31, 115, 1);
}

.card-owner {
  border-color: #46a6a4;
  background-color: #46a6a4;
}

.card-all-inclusive {
  border-color: $clr-blue;
  background-color: $clr-blue;
}

.card-trip {
  border-color: #00b9ff;
  background-color: #00b9ff;
}

.card-total {
  border-color: transparent;

  .overview-card__title {
    color: #351f73;
  }
}
