.shortcuts {
  background-color: #f5f5f5;
  box-sizing: border-box;

  &__wrap {
    width: 100%;
    max-width: 126.4rem;
    margin: 0 auto;
    padding: 0 1rem 4.6rem;
  }

  &__container {
    padding: 2.4rem;
    background: #fff;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.04);
    border-radius: 1.6rem;
  }

  &__group {
    margin-top: 3.2rem;
  }

  &__favorite-container {
    height: 2rem;
    transition: height 0.5s;
  }

  &__favorite-container-open {
    height: 30.2rem;
    overflow: hidden;
    transition: height 0.5s;
  }

  &__favorite-header {
    @include d-flex-space-between;
    align-items: center;
    margin-bottom: 2.6rem;

    &--count {
      display: flex;
      align-items: center;
    }

    &--count-text {
      font-size: 2rem;
      font-weight: 500;
      color: $clr-primary-and-accent;
      margin-left: 1.2rem;
    }

    &--count-val {
      margin-left: 1rem;
    }

    &--button {
      border-radius: 0.4rem;
      padding: 0.8rem 1.6rem;
      font-size: 1.2rem;
      font-weight: 500;
    }
    &--add-button {
      display: flex;
      justify-content: center;
      border-radius: 0.4rem;
      padding: 0.8rem 1.6rem;
      font-size: 1.2rem;
      font-weight: 500;
      @media screen and (max-width: 768px) {
        padding: 0.8rem 1.2rem;
        width: 100%;
      }
    }
  }

  &__favorite-prompt {
    font-size: 1.6rem;
    color: $seaz-text;
    text-align: center;
  }

  &__shortcut-card-container {
    display: grid;
    grid-template-columns: repeat(6, 18rem);
    grid-gap: 0 1.6rem;
    justify-content: space-around;
  }

  &__shortcut-card-container-charter {
    display: flex;
    justify-content: space-between;
    padding: 1.6rem 0;
    border-radius: 0 0 1.6rem 1.6rem;

    .shortcut-box {
      margin: 0 0 0 1.6rem;

      &:last-of-type {
        margin: 0 1.6rem;
      }
    }
  }

  &__shortcut-card-container-list {
    grid-gap: 2rem 1.6rem;
    margin-top: 4.8rem;
  }

  @media screen and (max-width: 1260px) {
    &__wrap {
      width: 100%;
      max-width: 102.4rem;
    }

    &__shortcut-card-container {
      grid-template-columns: repeat(6, 14rem);
    }
  }

  &__apply-btn-mobile {
    display: none;
    position: fixed;
    bottom: 0.8rem;
    left: 50%;
    transform: translate(-50%, 0);
    width: 31.2rem;
    background: $clr-primary-and-accent;
    border-radius: 0.4rem;
    font-size: 1.6rem;
    font-weight: 500;
    letter-spacing: 0.1em;
    text-transform: uppercase;
    text-align: center;
    color: $light-color;
    padding: 1rem 0;
    z-index: 10;

    @media screen and (max-width: 480px) {
      display: block;
    }
  }

  @media screen and (max-width: 1024px) {
    &__wrap {
      width: 100%;
      max-width: 67.2rem;
    }

    &__container {
      padding: 1rem;
    }

    &__shortcut-card-container-charter {
      .shortcut-box {
        &:first-of-type {
          margin: 0 0 1.6rem 1rem;
        }

        &:last-of-type {
          margin: 0 1rem 1.6rem 1.6rem;
        }
      }
    }

    &__shortcut-card-container {
      grid-template-columns: repeat(auto-fill, minmax(8rem, 1fr));
    }
  }

  @media screen and (max-width: 768px) {
    &__wrap {
      width: 100%;
      max-width: 41.6rem;
    }

    &__favorite-container {
      height: 4rem;
    }

    &__favorite-container-open {
      height: 20.2rem;
    }

    &__shortcut-card-container {
      grid-gap: 1.6rem;
    }
    &__shortcut-card-container-list,
    &__shortcut-card-container {
      grid-template-columns: repeat(3, 8.8rem);
      justify-content: space-around;
    }

    &__shortcut-card-container-charter {
      flex-wrap: wrap;
      padding: 1rem 0 0;

      .shortcut-box {
        margin: 0 0 1.6rem 1rem;

        &:nth-child(3),
        &:last-of-type {
          margin: 0 1rem 1.6rem 1rem;
        }
      }
    }

    .profile__col-left {
      padding-right: 1.8rem;
    }
  }

  @media screen and (max-width: 360px) {
    &__shortcut-card-container-charter,
    &__shortcut-card-container-list {
      grid-template-columns: repeat(2, 8.8rem);
      justify-content: space-around;
    }
  }
}

.shortcut-charter {
  display: flex;
  flex-direction: column;

  &__header {
    display: flex;
    align-items: center;
    background-color: #fafafb;
    border: 1px solid #e7e7eb;
    border-radius: 1.6rem 1.6rem 0 0;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.04);
    padding: 1.6rem;

    &--badge {
      display: flex;
      align-items: center;
    }

    &--text {
      font-size: 2rem;
      font-weight: 500;
      color: $clr-primary-and-accent;
    }
  }

  .shortcut-description-on-small-device {
    color: #fff;
  }

  @media screen and (max-width: 1024px) {
    &__header {
      flex-direction: column;
      align-items: flex-start;

      &--text {
        margin-top: 1rem;
        line-height: 1.4;
      }
    }
  }
}

.shortcuts-running-charter {
  display: flex;
}

.quick-nav {
  &__button {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 5.6rem;
    height: 5.6rem;
    background: $dark-green;
    border: 2px solid rgba(255, 255, 255, 0.16);
    box-sizing: border-box;
    box-shadow: 0 16px 32px rgba(43, 28, 54, 0.12);
    border-radius: 12px;
  }
}

.quick-add-task__button {
  background-color: $clr-primary-and-accent;
  border-color: rgba(255, 255, 255, 0.16);
}
