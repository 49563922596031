.boat-fleet-nav {
  .seaz-profile__navigation_list {
    width: 100%;
    margin: 0;
  }

  .seaz-profile__navigation_item {
    width: 100%;
    border-color: $clr-light-grey;

    &:first-of-type {
      border-radius: 0.8rem 0 0 0.8rem;
    }

    &:last-of-type {
      border-radius: 0 0.8rem 0.8rem 0;
    }
  }

  .seaz-profile__navigation_link {
    width: 100%;
    color: $clr-light-grey;
    justify-content: center;
  }

  .active-item {
    &.seaz-profile__navigation_item {
      border-color: $seaz-primary-and-accent;
    }
    .seaz-profile__navigation_link {
      color: $seaz-primary-and-accent;
    }
  }

  .disabled.seaz-profile__navigation_item {
    opacity: 0.3;
    .seaz-profile__navigation_link {
      cursor: default;
    }
  }

  @include laptop {
    .seaz-profile__navigation_list {
      width: inherit;
      margin: 0 2.4rem;
    }

    .seaz-profile__navigation_item,
    .seaz-profile__navigation_link {
      width: inherit;
    }
  }

  @include mobile {
    .seaz-profile__navigation_list {
      width: 100%;
      margin: 0;
    }

    .seaz-profile__navigation_item {
      width: 100%;

      &:first-of-type,
      &:last-of-type {
        border-radius: 0;
      }
    }
  }
}
