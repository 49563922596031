.fleet-card {
  border: 2px solid rgba(53, 31, 115, 0.2);
  box-sizing: border-box;
  box-shadow: 0 16px 32px rgba(43, 28, 54, 0.12);
  border-radius: 1.6rem;

  &__logo {
    grid-area: logo;
    width: 15.8rem;
    height: 15.9rem;
    border-radius: 1.6rem;
  }

  &__logo-img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 1.6rem;
  }

  &__title {
    @include d-flex-space-between;
    align-items: flex-start;
    grid-area: title;
    font-size: 2rem;
    font-weight: 500;
    line-height: 120%;
    word-break: break-word;
    color: $clr-primary-and-accent;
  }

  &__description {
    font-size: 1.6rem;
    line-height: 1.9rem;
    color: $clr-light-grey;
    margin-bottom: 1.6rem;
    word-break: break-all;
  }

  @include mobile {
    &__logo {
      width: 6.6rem;
      height: 6.6rem;
    }
  }
}

.fleet-card-body {
  display: grid;
  grid-template-areas:
    'logo title'
    'logo content';
  grid-template-columns: 15.8rem 1fr;
  grid-gap: 0.8rem 2.4rem;
  padding: 2.4rem;

  @include tablet {
    grid-template-areas:
      'logo title'
      'content content';
    grid-gap: 1.2rem;
    padding: 1.6rem;
  }

  @include mobile {
    grid-template-columns: 6.6rem 1fr;
  }
}

.fleet-card-content {
  grid-area: content;
}

.fleet-card-info {
  @include d-flex-space-between;
  align-items: flex-start;
  border-top: 1px solid $seaz-bg;
  padding-top: 1.6rem;

  &__col {
    width: 50%;

    @include indent-not-last-of-type(0 1rem 0 0);

    &:not(:first-of-type) {
      border-left: 1px solid $seaz-bg;
      padding-left: 2.4rem;
    }
  }

  &__title {
    line-height: 1.6rem;
    color: $clr-light-grey;
    margin-bottom: 0.4rem;
  }

  @include tablet {
    flex-direction: column;
    justify-content: flex-start;
    padding-top: 1.2rem;

    &__col {
      width: 100%;

      @include indent-not-last-of-type(0 0 1.2rem 0);

      &:not(:first-of-type) {
        border-left: none;
        padding: 0;
      }
    }
  }
}

.fleet-card-default {
  margin-left: 1rem;
}

.fleet-item-box {
  display: flex;
  justify-content: flex-start;
}

.fleet-item {
  @include d-align-items-center;
  cursor: pointer;

  &__content {
    width: 3.6rem;
    height: 3.6rem;
    border-radius: 50%;
    box-sizing: border-box;
    border: 2px solid $light-color;

    &:not(:first-of-type) {
      margin-left: -1.2rem;
    }
  }

  &__img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 50%;
  }

  &__count {
    font-size: 1.6rem;
    font-weight: 500;
    line-height: 1.9rem;
    text-decoration: underline;
    color: $seaz-edit-hover;
    margin-left: 1.2rem;
  }
}

.fleet-card-footer {
  @include d-flex-space-between;
  align-items: center;
  background-color: $seaz-bg;
  padding: 1.6rem 2.4rem;
  border-radius: 0 0 1.6rem 1.6rem;

  @include tablet {
    padding: 1.6rem;
  }
}

.fleet-card-buttons {
  @include d-flex-space-between;
  align-items: center;
  width: 100%;

  @media screen and (max-width: 540px) {
    flex-direction: column;

    &__wrap {
      width: 100%;
    }
  }
}

.fleet-card-button {
  @include indent-not-last-of-type(0 1.2rem 0 0);

  @media screen and (max-width: 540px) {
    width: 100%;

    @include indent-not-last-of-type(0 0 1.2rem 0);
  }
}

.buttons-wrap {
  @include d-align-items-center;

  @media screen and (max-width: 540px) {
    flex-direction: column;
    width: 100%;
    margin-top: 1.2rem;
  }
}

.fleet-card-buttons-alternate {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  width: 100%;

  @media screen and (max-width: 540px) {
    flex-direction: column;
  }
}

.fleet-card-button-reject {
  color: $light-color;
  border-color: $seaz-alarm;
  background-color: $seaz-alarm;

  &:hover {
    opacity: 1;
    background-color: $light-color;
    color: $seaz-alarm;
  }
}
