.charter-calendar {
  width: 126.4rem;
  margin: 0 auto;
  padding-bottom: 4rem;
  @media screen and (max-width: 1300px) {
    width: 100rem;
  }
  @include laptop {
    width: 98%;
    padding: 0 0.8rem 4rem;
  }

  &__header {
    display: flex;
    align-items: center;
    border-top: 1px solid $seaz-border;
    padding-top: 4rem;
    margin-bottom: 1.6rem;

    &--btn {
      background-color: #fff;
      margin-left: 1.6rem;
    }
  }

  &-title {
    color: $clr-primary-and-accent;
    text-transform: uppercase;

    @media screen and (max-width: 1024px) {
      border-top: none;
      padding-top: 0;
    }
  }
  &-wrapper {
    display: flex;
    width: 100%;
  }

  &-monthly {
    width: 100%;
    background-color: $clr-white;
    padding: 3rem;
    border-radius: 1.6rem;
    margin-right: 3.2rem;
  }

  &-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 0.4rem;
    &-left {
      display: flex;
    }
  }

  &-buttons {
    margin-left: 2rem;
  }

  &-arrow {
    svg {
      stroke: $clr-primary-and-accent;
    }
    &:disabled svg {
      stroke: $clr-light-grey;
    }
  }
  &-next,
  &-prev {
    path {
      stroke-width: 4px;
    }
  }

  &-next {
    margin-left: 2.5rem;
    transform: rotateZ(180deg);
  }

  &-date {
    font-weight: 500;
    font-size: 2rem;
    color: $seaz-text;
  }

  &-period {
    display: flex;

    &-select {
      min-width: 12.5rem;
      &:not(:last-child) {
        margin-right: 1.2rem;
      }
    }
  }

  &-mode {
    display: block;
    padding: 3rem;
    border-bottom: 1px solid $seaz-head-arrow;
    .switch {
      border: 1px solid $seaz-head-arrow;
      padding: 0;
      &__btn {
        padding: 0.5rem;
        font-size: 1.2rem;
        line-height: 3rem;
        text-align: center;
        text-transform: uppercase;
        width: 50%;
      }
    }
  }

  .seaz-dashboard-charters-info__content {
    max-height: 77.2rem;
  }

  @media screen and (max-width: 1354px) {
    &-wrapper {
      flex-direction: column;
    }
    &-monthly {
      margin-right: 0;
    }
    .seaz-dashboard-charters-info__content {
      max-height: none;
    }
  }

  @media screen and (max-width: 768px) {
    &-monthly {
      padding: 1.2rem;
    }
    &-header {
      flex-direction: column;
      &-left {
        width: 100%;
        justify-content: space-between;
        margin-bottom: 1.2rem;
      }
      &-right {
        width: 100%;
      }
    }
    &-period {
      &-select {
        width: 100%;
      }
    }
    &-mode {
      padding: 1.2rem;
    }
  }
}
