.dynamic-table-form-title {
  margin-bottom: 3rem;
}

.dynamic-table-form-item {
  @include indent-not-last-of-child(0 0 3rem 0);

  .form__checkbox {
    justify-content: space-between;
  }

  .form__checkbox-label-text {
    order: -1;
    padding: 0 1.8rem 0 0;
  }

  .form__checkbox-input:disabled ~ label:before {
    background-color: $seaz-head-arrow;
    border: 0.2rem solid $seaz-head-arrow;
  }

  .form__checkbox-label-text {
    font-size: $base-font-size;
    color: $seaz-text;
  }

  .form__checkbox-input:disabled ~ span {
    color: $seaz-text;
  }
}

.dynamic-table-form-footer {
  @include footer-with-border-left-btn(1rem 0 2rem, 0);
  border: 0;
}
