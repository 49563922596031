.boat-maintenance-task-log-header {
  @include box-header(center, space-between, 0, 0);

  &__title {
    text-transform: uppercase;
    color: $seaz-text;
    &-container {
      display: flex;
      .seaz__return_btn {
        margin-right: 1rem;
      }
    }
  }

  &__subtitle {
    color: $clr-light-grey;
    font-size: 1.6rem;
    text-transform: none;
  }

  &__container {
    margin-left: 1rem;
  }

  @include desktop-m {
    @include box-header(flex-start, space-between, 0, 0);
  }

  @include laptop {
    flex-direction: column;
    @include box-header(flex-start, space-between, 0, 0);

    &__title {
      margin-bottom: 1.6rem;
    }

    &__container {
      width: 100%;
      margin-left: 0;
    }
  }

  @include tablet {
    &__title {
      font-size: 2.4rem;
      text-transform: initial;
    }
  }
}

.boat-maintenance-task-log-header-info {
  .boat-maintenance-task-log-header__container {
    @include d-align-items-center;
  }

  .task-download-button {
    margin-left: 1.2rem;
  }

  @include laptop {
    flex-direction: row;

    .boat-maintenance-task-log-header__title {
      margin-bottom: 0;
    }

    .boat-maintenance-task-log-header__container {
      width: auto;
    }
  }

  @include tablet-small {
    flex-direction: column;

    .boat-maintenance-task-log-header__container {
      width: 100%;
      margin-top: 1.6rem;

      .task-download-button,
      .add-task-btn {
        width: 100%;
      }
    }

    .boat-maintenance-task-log-header__container {
      flex-direction: column;
    }

    .task-download-button {
      margin: 1.2rem 0 0 0;
    }
  }
}

.boat-position-history-title {
  text-transform: uppercase;
  margin: 3.1rem 0;
}

.boat-maintenance-task-log-results {
  margin-top: 2.5rem;

  &__period {
    font-weight: 500;
  }

  &__container {
    display: flex;
    justify-content: flex-end;
    font-size: 1.6rem;
    line-height: 1.6rem;
    color: $clr-light-grey;

    &--info {
      margin-top: 1.6rem;
    }

    @include tablet-small {
      justify-content: flex-start;
    }
  }
}

.boat-maintenance-task-log-form {
  @include box-grid(28.3rem auto auto, initial, 'search chips actions', 0 2rem);
  width: 100%;

  &__search {
    grid-area: search;
    .form-field--content-outline {
      height: 4rem;
      border-radius: 0.4rem;
      border-color: $clr-grey;
    }
  }

  &__chips {
    grid-area: chips;
  }

  &__actions {
    grid-area: actions;
    display: flex;
    justify-content: flex-end;
  }

  .add-task-btn {
    height: 4rem;
    padding: 0.9rem 2rem;
  }

  .task-download-button {
    align-self: baseline;
    justify-self: end;
    max-width: 16rem;
    margin-left: 1.2rem;
  }

  .task-view-select {
    margin-left: 1.2rem;
  }

  .task-options-button {
    padding: 1rem 1.5rem;
    margin-left: 1.2rem;
  }

  .fleet-chips {
    @include d-align-items-center;
    margin-top: 0;

    app-chips-checkbox-item {
      margin: 0 0.8rem 0 0;
    }
  }

  @include large-md-screen {
    @include box-grid(1fr 1fr, initial, 'actions actions' 'search chips', 1.6rem 2rem);
  }

  @include laptop {
    &__actions {
      justify-content: flex-start;
    }
  }

  @include tablet {
    @include box-grid(1fr, initial, 'search' 'chips' 'actions', 1.2rem);

    .chips-group {
      flex-shrink: 0;
    }

    .task-download-button,
    .add-task-btn {
      width: 100%;
      max-width: initial;
    }
  }

  @include tablet-small {
    &__actions {
      flex-direction: column;
    }

    .task-download-button,
    .task-options-button {
      margin: 1.2rem 0 0 0;
      padding: 0.9rem 2rem;
    }

    .task-view-select {
      margin: 1.2rem 0 0 0;

      .seaz__btn_rounded {
        width: 100%;
      }
    }
  }
}

.boat-maintenance-task-log-container {
  background-color: $light-color;
  box-shadow: 0 2px 2px rgba(43, 28, 54, 0.16);
  border-radius: 1.2rem;
  padding: 2.4rem;
  margin-top: 2.6rem;

  .max-repeats-control {
    margin-top: 1.2rem;
  }

  .wrap-table {
    margin-top: 3rem;
  }

  .financial-data__badge {
    font-size: 1.2rem;
    font-weight: 400;
    line-height: 120%;
    color: $seaz-text;
    border: none;
    margin-top: 2.4rem;

    app-seazone-icon {
      margin-left: 0.7rem;
    }
  }

  @include desktop {
    .wrap-table {
      margin-top: 4.5rem;
    }
  }

  @include tablet {
    padding: 1.6rem;
  }
}

.max-repeats-control {
  width: 100%;
  max-width: 12.4rem;
}

.boat-maintenance-task-log-table {
  width: 100%;
  min-height: 22rem;

  app-block-loader {
    position: absolute;
    width: 100%;
  }

  app-boat-maintenance-tasks-log-filter th {
    padding-bottom: 1.6rem;
  }

  .date-row {
    @include d-align-items-center;

    .date-col {
      // padding-right: 0.8rem;
      min-width: 8rem;
      max-width: 8rem;
    }
  }

  .th-row {
    @include d-align-items-center;
  }

  .th-pr {
    app-seazone-select,
    app-seazone-input,
    .form__row {
      padding-right: 1.2rem;
    }

    app-date-range {
      display: block;
      padding-right: 1.2rem;
    }
  }

  .th-label {
    display: block;
    width: 100%;
    font-size: 1.4rem;
    line-height: 2rem;
    text-align: left;
    margin-bottom: 0.2rem;
    color: #010202;
  }

  .task-name-th {
    app-seazone-input {
      min-width: 14rem;
    }
  }

  .th-priorities {
    app-seazone-select {
      min-width: 15rem;
      max-width: 15rem;
    }
  }

  .task-name-label {
    min-width: 14rem;
  }

  .select-placeholder {
    color: $seaz-border;
  }

  .quote-th {
    app-seazone-select {
      min-width: 36rem;
    }
  }

  .seazone__form-date-input {
    font: 1.4rem/2.8rem $base-font !important;
    color: $seaz-border !important;

    &::placeholder {
      font: 1.4rem/2.8rem $base-font !important;
      color: $seaz-border !important;
    }
  }

  .task-work-list-description-th {
    .task-work-list-description-label {
      max-width: 15rem;
    }
    app-seazone-input {
      min-width: 15rem;
    }
  }

  .types-th {
    max-width: 19.2rem;
    width: 19.2rem;
    .th-row {
      padding-right: 1.2rem;
      app-seazone-select {
        width: 8.8rem;
        padding-right: 0;
        &:not(:last-child) {
          padding-right: 0;
          margin-right: 0.4rem;
        }
      }
    }
  }

  .date-th {
    max-width: 16.2rem;
    width: 16.2rem;
  }

  .task-log-table-fixed-col {
    position: sticky;
    right: -0.1rem;
    background-color: $light-color;
    z-index: 20;
    padding-left: 0.8rem;
    width: 19rem;
    &::before {
      content: '';
      position: absolute;
      top: 0;
      left: -1rem;
      width: 1rem;
      height: 100%;
      background: linear-gradient(270deg, rgba(0, 0, 0, 0.09) 0%, rgba(0, 0, 0, 0) 99.99%, rgba(217, 217, 217, 0) 100%);
    }
    &__inner {
      display: flex;
      justify-content: space-between;
      align-items: flex-start;
      width: 19rem;
      @include tablet {
        width: 12rem;
      }
      &-header {
        align-items: flex-end;
      }

      &-status {
        margin-right: 0.8rem;
        max-width: 11rem;
        width: 11rem;
        @include tablet {
          max-width: 5rem;
        }
        .boat-maintenance-task-view-modal-header__status-change {
          display: flex;
        }
      }
      &-actions {
        display: flex;
        .main-btn {
          width: 4.8rem;
          height: 4.8rem;
        }
        .tasks-log-table-actions__menu {
          display: none;
          @include tablet {
            display: flex;
            position: relative;
            z-index: 2;
            justify-content: center;
            width: 100%;
          }
        }
      }
    }
  }
}

.boat-maintenance-task-log-table-link {
  text-decoration: underline;
  color: $seaz-edit-hover;
}

.boat-maintenance-task-log-table-info {
  .types-th {
    min-width: 10rem;
    .th-row {
      app-seazone-select {
        max-width: 8.8rem;
        &:not(:last-child) {
          padding-right: 0.4rem;
        }
      }
    }
  }

  .date-th,
  .systems-th {
    min-width: 16rem;
  }

  .th-p {
    padding: 1.5rem 0.9rem 0.9rem;
  }

  .th-estimate {
    .th-label {
      min-width: 10rem;
    }
  }

  .description {
    .table__col-inner {
      max-width: 15rem !important;
      min-width: 15rem !important;
    }
  }
}

.charters-related-dropdown {
  .select-option {
    min-height: initial;
  }

  .form__checkbox-label-text {
    padding-top: 0.8rem;
    padding-bottom: 0.8rem;
  }
}

.select-option-table.seazone-select-option {
  padding: 0 1rem;
}

.boat-maintenance-tasks-log-table-list {
  tr {
    &:first-of-type {
      td {
        border-top: 1px solid rgba(53, 31, 115, 0.2);
      }
    }

    td {
      padding: 1.5rem 0.9rem 0.9rem;
      border-bottom: 1px solid $seaz-bg;
    }

    &:nth-of-type(2n + 1) {
      td {
        background-color: $seaz-bg;
      }
    }
  }

  td {
    vertical-align: top;
  }

  .boat-task-card-chips {
    max-width: 10rem;
  }

  .td-text-bold {
    font-weight: 500;
    color: $seaz-text;
  }

  .work-list-select {
    min-width: 20rem;
  }

  .directory-sub-item {
    font-size: 12px;
    line-height: 120%;
    color: $seaz-document-count;
    margin-top: 0.2rem;
  }

  .additional-cell {
    color: $seaz-text;

    .table-col--additional {
      font-size: 1.2rem;
      color: $seaz-about-text;
      margin-top: 0.2rem;
      &.alarm {
        color: $seaz-alarm;
      }
    }
  }

  .additional-cell-wrap {
    @include d-align-items-center;
  }

  .description {
    color: $seaz-text;
    line-height: 1.6rem;

    .table__col-inner {
      overflow: visible;
      white-space: normal;
      text-overflow: initial;
      max-width: 15rem;
      min-width: 15rem;
      word-break: break-word;
    }
  }

  .col-with-btns {
    @include d-align-items-center;
    @include tablet {
      display: none;
    }
  }

  .edit-btn {
    margin-right: 1rem;
  }

  .note-btn {
    background-color: $seaz-bg;
    padding: 0.5rem 0.9rem;
    border-radius: 3.8rem;
    color: $clr-primary-and-accent;

    app-seazone-icon {
      margin-right: 0.5rem;
    }
  }

  .boat-task-card-points-directory-item {
    .task-priority-type {
      app-seazone-icon {
        svg {
          fill: $seaz-border;
        }
      }
    }

    .selected.task-priority-type--owners {
      app-seazone-icon {
        svg {
          fill: $seaz-mulberry;
        }
      }
    }

    .selected.task-priority-type--urgent {
      app-seazone-icon {
        svg {
          fill: $seaz-warning;
        }
      }
    }

    .selected.task-priority-type--warranty {
      app-seazone-icon {
        svg {
          fill: $greeny-border-bg;
        }
      }
    }
  }

  @include tablet {
    .tasks-log-table-cell-right {
      vertical-align: middle;
    }
  }
}

.task-log-quote-action {
  @include d-align-items-center;
  width: 100%;
  min-width: 12rem;
  max-width: 14rem;
  height: 4rem;
  border: 1px solid $seaz-primary-and-accent;
  border-radius: 0.4rem;
  padding: 0.8rem;
  white-space: nowrap;

  app-seazone-icon {
    margin-right: 0.3rem;
  }

  &.approved {
    border: 1px solid $seaz-delivery;
    background-color: $greeny-bg;
    color: $seaz-delivery;
  }
}

.dynamic-table-view-select {
  &__dropdown {
    width: 19rem;
    font-family: $base-font;
    line-height: 1.4;
    color: $seaz-text;
    background-color: $light-color;
    box-shadow: 0 16px 32px rgba(43, 28, 54, 0.12);
    border-radius: 1.6rem;
    padding: 0.8rem;
    overflow-y: auto;
    display: flex;
    flex-direction: column;
    &--item {
      display: inline-flex;
      padding: 1.4rem 0.8rem;
      font-size: 1.4rem;
      cursor: pointer;
      &-name {
        flex: 1 auto;
      }
    }
  }
}

.maintenance-service {
  &--last {
    color: #010202;
    font-weight: 700;
  }
  &--next {
    color: #3aae27;
    font-weight: 700;
  }
  &--planned {
    color: #3aae27;
  }
  &--expired {
    color: #bd0202;
  }
}

.tasks-log-table-actions__button {
  display: flex;
  width: 100%;
  height: 2.4rem;
  @include tablet {
    &:not(:last-child) {
      margin: 0 0 1.7rem;
    }
  }
}

.tasks-log-table-actions__text {
  font-family: $base-font;
  font-size: 1.6rem;
  font-weight: 500;
  line-height: 1.9rem;
  color: $clr-primary-and-accent;
  margin-left: 1.3rem;
  display: none;
  &-notes {
    display: inline-block;
    @include tablet {
      display: none;
    }
  }
  @include tablet {
    display: inline-block;
  }
}

.maintenance-dashboard-download-btn {
  position: relative;
  &::after {
    content: '';
    top: 0;
    position: absolute;
    width: 100%;
    height: 100%;
    pointer-events: all !important;
  }
}

.breadcrumbs-container {
  display: flex;
  flex-direction: row-reverse;
  justify-content: flex-end;
}

.breadcrumbs {
  font-size: 1.2rem;
  font-weight: 500;
  line-height: 120%;
  color: $profile-month;

  .active {
    color: $clr-primary-and-accent;
  }
}
