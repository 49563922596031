.outstanding-status {
  &__title {
    margin-bottom: 3rem;
  }
}

.status-manage-item-container {
  @include d-align-items-center;
  flex-wrap: wrap;
}

.status-manage-item {
  margin: 0 1.2rem 1.2rem 0;
}

.manage-item {
  @include d-align-items-center;
  padding: 1.2rem 1.6rem;
  border-radius: 5.6rem;
  font-size: 1.4rem;
  font-weight: 700;
  line-height: 2rem;
  text-transform: uppercase;
  color: $light-color;

  &__btn {
    margin-left: 0.3rem;
  }
}

.outstanding-status-color-select {
  display: block;
  width: 10.7rem;
  min-width: 10.7rem;

  .form-field--content-outline {
    border: 0;

    &.active:not(.disabled):not(.readonly) {
      border: 0;
    }
  }

  .form-field--content {
    padding: 1.6rem;
  }

  .form-field--content {
    position: relative;
  }

  .select-html-container div {
    display: flex;
    align-items: center;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    padding-left: 1.6rem;
    font-size: 1.4rem;
    font-weight: 700;
    line-height: 2rem;
    text-transform: uppercase;
    color: $light-color;

    box-sizing: border-box;
  }

  .icon-seazone-select-arrow {
    color: $light-color;

    &:before {
      font-size: 1.2rem;
      font-weight: bold;
    }
  }

  @include mobile {
    width: 100%;
  }
}

.outstanding-status-color-dropdown {
  background-color: $light-color;
  box-shadow: 0 8px 16px rgba(43, 28, 54, 0.2);
  border-radius: 0.4rem;
  padding: 1.2rem;

  .dropdown-options-container {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(3.5rem, 1fr));
    grid-gap: 0.4rem;
    border-radius: 0;
    box-shadow: initial;
    background-color: initial;
  }

  app-seazone-select-option {
    padding: 0;
    box-sizing: border-box;

    &.selected,
    &:hover {
      &:not([notselectable]):not(.disabled) {
        background: initial;

        .select-option-content {
          border: 3px solid #000000;
        }
      }
    }
  }

  .select-option-content {
    width: 3.5rem;
    height: 3.5rem;
    border-radius: 12px;
    font-size: 0;
    border: 3px solid $light-color;
  }
}

.outstanding-status-dropdown {
  font: 400 1.4rem/1.6rem $base-font;
  background-color: $light-color;
  box-shadow: 0 8px 16px rgba(43, 28, 54, 0.2);
  border-radius: 0.4rem;
  padding: 1.2rem;

  &__form {
    width: 100%;
  }

  &__label {
    color: $seaz-text;
    margin-bottom: 0.2rem;
  }

  &__actions {
    @include d-align-items-center;
    margin-top: 2rem;
  }

  &__btn {
    &:first-of-type {
      width: 8.1rem;
      padding: 1rem;
    }

    &:last-of-type {
      width: 100%;
      margin-left: 0.4rem;
    }
  }
}
