.supplier-general-edit {
  &__group {
    padding-bottom: 4.8rem;
  }

  &__my-location {
    margin-top: 4.8rem;
  }

  &__my-location-btn {
    font-size: 1.2rem;
    padding: 0.8rem 1.6rem;
    margin: 2.4rem 0;
  }

  app-seazone-text-area .offset {
    margin-top: 0;
  }

  &__textarea {
    .wrapper-rounded.form-field--wrapper__row .form-field--label {
      justify-content: flex-start;
    }

    .wrapper-rounded .form-field--content {
      height: auto;
      min-height: 8rem;
    }
  }

  &__sub-title {
    font-size: 2rem;
    font-weight: 500;
    line-height: 1.4;
    color: $seaz-text;

    &.other {
      padding-top: 16px;
      margin-bottom: 24px;
    }

    &.for-tags {
      margin-bottom: 1.6rem;
    }
  }

  &__search-tags {
    display: flex;
    margin-top: 1.6rem;
  }

  &__search-button {
    margin-left: 1.2rem;
  }

  &__tags-list {
    margin-top: 1.6rem;
  }

  .seaz-uploader__choose_btn {
    border-radius: 0.4rem;
  }

  .seaz-uploader__choose_drop {
    width: 100%;
    border-radius: 0.8rem;
  }

  .seaz-uploader__video_item {
    border-radius: 1rem;
  }

  .seaz-uploader__video_play {
    background-color: $clr-primary-and-accent;
  }

  app-seazone-image-file {
    &:not(:last-of-type) {
      margin-right: 2rem;
    }
  }

  &__language {
    .wrapper-rounded .form-field--content {
      overflow-x: hidden;
    }
  }

  @include tablet {
    &__additional-video {
      .seaz-uploader,
      .seaz-uploader__box {
        align-items: flex-start;
      }

      .seaz-uploader__label {
        text-align: left;
      }
    }
  }
}

.supplier-edit-tags {
  display: flex;
  flex-wrap: wrap;

  &__tag {
    display: flex;
    align-items: center;
    box-sizing: border-box;
    padding: 1rem 1.6rem;
    word-break: break-all;
    border: 1px solid $seaz-head-arrow;
    background-color: $light-color;
    border-radius: 5.6rem;
    margin-bottom: 1.6rem;

    &:not(:last-of-type) {
      margin-right: 0.8rem;
    }
  }

  &__btn {
    width: 2.4rem;
    height: 2.4rem;
    background-color: $clr-ashy-grey;
    border-radius: 50%;
    margin-left: 0.8rem;
  }
}

.supplier-edit-tags-counter {
  color: $seaz-document-count;
}
