.outstanding-email-form-modal {
  box-sizing: border-box;

  &.seaz-modal.modal-small {
    width: 100%;
    max-width: 101.5rem;
    padding: 0;
  }

  .modal-close {
    right: 2rem;
  }
}

.outstanding-payment-email {
  &__title {
    font-size: 2.4rem;
    font-weight: 500;
    color: $clr-primary-and-accent;
  }
}

.outstanding-payment-email-form {
  margin-top: 3rem;

  &__field {
    position: relative;
    margin-bottom: 4.6rem;

    .form-field--hint {
      position: absolute;
      bottom: -2.2rem;
      right: 0;
      width: 100%;
    }
  }

  &__note {
    margin-bottom: 4.6rem;

    .wrapper-rounded {
      &.form-field--wrapper__row .form-field--label {
        justify-content: flex-start;
        padding-top: 0;
      }

      .form-field--content {
        height: 15rem;
      }
    }

    app-seazone-form-field.offset {
      margin-top: 0;
    }
  }

  &__footer {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    margin-top: 3.6rem;
    padding: 3rem 0;
    border-top: 1px solid $seaz-head-arrow;
  }

  &__btn {
    display: block;
    width: 14.5rem;
    box-sizing: border-box;
    font-weight: 500;
    line-height: 1.4rem;
    letter-spacing: 0.1rem;
    text-transform: uppercase;
    padding: 1.8rem 0;
    border: 1px solid $dark-green;
    cursor: pointer;
    transition: opacity 0.3s;

    &:hover {
      opacity: 0.7;
    }

    &:not(:last-of-type) {
      margin-right: 1.6rem;
    }

    &:disabled {
      color: $clr-grey;
      border-color: $clr-grey;
      background-color: initial;
      cursor: unset;
    }

    &--cancel {
      color: $dark-green;
      border-color: $dark-green;
    }

    &--preview {
      color: $seaz-edit-hover;
      border-color: $seaz-edit-hover;
    }

    &--submit {
      color: $light-color;
      background-color: $dark-green;
    }
  }

  @media screen and (max-width: 650px) {
    &__field,
    &__note {
      margin-bottom: 3rem;

      .form-field--wrapper__row .form-field--label {
        width: 26%;
      }
    }
  }

  @include mobile {
    &__footer {
      flex-direction: column;
    }

    &__btn {
      width: 100%;

      &:not(:last-of-type) {
        margin: 0 0 1.6rem 0;
      }
    }
  }
}

.outstanding-payment-email-form-show-data {
  display: flex;

  &__label {
    width: 100%;
    max-width: 14rem;
    margin-right: 2rem;
  }

  &__button {
    display: flex;
    align-items: flex-end;
    font-size: 1.6rem;
    word-break: break-all;
    text-align: left;
    line-height: 1.4;
    color: $clr-light-grey;
  }

  &__icon {
    display: block;
    margin-right: 1rem;
  }

  @media screen and (max-width: 650px) {
    &__label {
      width: 20%;
      max-width: none;
      margin: 0 0 1rem 0;
    }
  }

  @include mobile {
    flex-direction: column;

    &__label {
      margin: 0 0 1rem 0;
    }
  }
}

.outstanding-email-preview-modal {
  .modal-close {
    top: 3.5rem;
    right: 3.5rem;
  }

  @include tablet {
    .modal__wrap {
      padding: 0;
    }

    &.seaz-modal {
      &.modal-small {
        width: 100%;
      }
    }

    .modal-close {
      top: 2rem;
    }
  }
}
