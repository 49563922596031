.boat-system-settings {
  width: 100%;
  max-width: 83rem;
  margin: 0 auto;
}

.boat-system-settings-header {
  @include d-flex-space-between;
  align-items: center;
  margin-bottom: 1.6rem;

  &__action {
    @include d-align-items-center;
  }

  .maintenance-equipment-tree-toggle {
    padding: 0;
  }

  &__btn {
    flex-shrink: 0;
  }

  @include tablet {
    flex-direction: column;
    align-items: flex-start;

    &__action {
      flex-direction: column;
      align-items: flex-start;
      width: 100%;
      margin-top: 1.2rem;
    }

    &__btn {
      width: 100%;
      flex-shrink: initial;
    }
  }
}

.boat-system-settings-title {
  font-size: $base-font-size;
  line-height: 1.4rem;
  color: $seaz-text;

  &.disabled {
    color: $clr-light-grey;
  }
}

.boat-system-settings-search {
  margin: 0 1.2rem 0 0;

  @include tablet {
    margin: 0 0 1.2rem 0;
  }
}

.boat-system-settings-maintenance-tree {
  padding-right: 1rem;

  .mat-tree-node {
    padding: 1.2rem 1rem;

    &:nth-last-of-type(2n + 1) {
      background-color: $clr-ashy-grey;
      border-radius: 0.8rem;
    }
  }
}

.systems-tree-actions {
  @include d-align-items-center;
  position: relative;
  margin-left: 1rem;

  &__input-box {
    display: block;
    position: relative;
  }

  &__input {
    position: absolute;
    width: 0;
    height: 0;
    z-index: -1;
  }

  &__label {
    @include d-align-items-center;
    width: 2rem;
    height: 2rem;
    border: 0.2rem solid $seaz-head-arrow;
    border-radius: 50%;
    background-color: $light-color;
    box-sizing: border-box;
    cursor: pointer;

    &:before {
      content: '\e820';
      font-family: 'Fontello';
      color: transparent;
      position: absolute;
      left: 0;
      top: 10%;
      width: 100%;
      height: 100%;
      font-size: 0.9rem;
      text-align: center;
    }
  }

  &__btn {
    margin-left: 1rem;
  }
}

.systems-tree-actions__input:checked + .systems-tree-actions__label {
  background-color: $clr-primary-and-accent;
  border-color: $clr-primary-and-accent;

  &:before {
    color: $light-color;
  }
}
