.shortcuts-list {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(15rem, 1fr));
  grid-gap: 1.2rem 1.6rem;
  align-self: stretch;

  @include desktop {
    grid-template-columns: repeat(auto-fit, minmax(20rem, 1fr));
  }

  @include tablet {
    grid-gap: 1.2rem;
  }

  @include mobile {
    grid-template-columns: repeat(auto-fit, minmax(15rem, 1fr));
  }
}

.shortcuts-list-item {
  @include d-flex-space-between;
  align-items: center;
  height: 100%;
  padding: 2.4rem 2.4rem 2.6rem;
  border-radius: 1.2rem;

  &__content {
    display: block;
    width: 100%;
    color: $light-color;
    text-align: left;
  }

  &__title {
    display: block;
    font-size: 1.6rem;
    font-weight: 500;
    line-height: 1.9rem;
    margin-bottom: 0.8rem;
  }

  @include tablet {
    padding: 1.2rem;

    &__title {
      font-size: 1.2rem;
    }
  }
}

.shortcuts-list-item-boat {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  width: 100%;

  &__content {
    display: flex;
    align-items: flex-end;
    flex-wrap: wrap;
    font-size: 1.6rem;
    font-weight: 400;
    line-height: 120%;
    margin-right: 1rem;
  }

  &__count {
    display: block;
    font-size: 5.6rem;
    font-weight: 300;
    line-height: 5rem;
    margin-right: 1.2rem;
  }

  &__icon {
    transform: rotate(180deg);
  }

  @include tablet {
    &__count {
      font-size: 3rem;
    }

    &__content {
      font-size: 1.2rem;
    }
  }
}

.shortcuts-list-item-boat-fleet {
  .shortcuts-list-item-boat {
    align-items: center;
  }

  .shortcuts-list-item-boat__count {
    @include d-align-items-center;
    gap: 0 2.3rem;
    width: 100%;
    border-bottom: 1px solid $light-color;
    padding-bottom: 1.9rem;
  }

  .task-val {
    display: block;
    font-size: 1.6rem;
    font-weight: 400;
    line-height: 120%;
    margin-top: 0.8rem;
  }
}
