.boat-tasks-status {
  width: 100%;
  max-width: 126.4rem;
  margin: 0 auto;

  .boat-tasks-menu {
    margin-top: 3.6rem;
  }

  .boat-tasks-list {
    justify-content: flex-start;
  }

  @include laptop {
    .boat-tasks-menu {
      margin-top: 0;
    }

    .boat-tasks-menu-action {
      align-items: center;
    }
  }

  @include mobile {
    .boat-tasks-menu-action-group {
      order: initial;
      width: auto;
    }

    .boat-tasks-menu-action-group-chips {
      .fleet-chips app-chips-checkbox-item {
        margin: 0 0 0 0.8rem;
      }
    }
  }
}

.boat-tasks-status-val {
  font-size: 3rem;
  font-weight: 500;
  line-height: 145%;
  text-transform: uppercase;
  color: $seaz-primary-and-accent;
  margin-left: 3.8rem;

  @include tablet-m {
    font-size: 2.4rem;
    line-height: 116%;
    text-transform: initial;
    margin-left: 1.6rem;
  }
}
