.fleet-charters {
  &__title {
    text-transform: uppercase;
  }

  .seaz-charter-overview__services--wrap {
    margin-top: 2rem;
  }

  .duration-tabs__title {
    color: $clr-purple;
  }

  app-month-range,
  app-seazone-select {
    .form-field--wrapper__row {
      flex-direction: column;
    }
  }

  &__button {
    margin-left: 2.4rem;
  }

  @include desktop {
    .seaz-charter-overview-container {
      margin: 0;
    }
  }

  @include laptop {
    .duration-mode-form {
      width: 100%;
    }

    .duration-tabs {
      width: 50%;
    }

    .duration-tabs__switch-btn {
      width: 100%;
      text-align: center;
    }

    .duration-mode-selecting {
      width: 50%;
    }

    .duration-mode-selecting__item {
      width: 100%;
      min-width: auto;
    }

    .select-value {
      width: auto !important;
    }
  }

  @include tablet {
    .duration-tabs,
    .duration-mode-selecting {
      width: 100%;
    }

    &__button {
      width: 100%;
      margin: 2.2rem 0 0;
    }
  }
}

.fleet-charters-action {
  @include d-align-items-center;

  @include laptop {
    width: 100%;
    margin-top: 3.6rem;

    .seaz-charter-overview__toggle-box {
      @include d-align-items-center;
      margin: 0;
    }

    .seaz-charter-overview__toggle:first-of-type {
      margin: 0 3.6rem 0 0;
    }
  }

  @include tablet {
    flex-direction: column;
    align-items: flex-start;
    margin-top: 2rem;

    .seaz-charter-overview__toggle-box {
      flex-direction: column;
      align-items: flex-start;
    }

    .seaz-charter-overview__toggle:first-of-type {
      margin: 0 0 2rem 0;
    }
  }
}

.fleet-charter-filters {
  display: block;
  margin-top: 1.6rem;

  .seaz-search-crew-filters__title-box {
    margin-top: 0;
  }

  .seaz-charter__filtering--list {
    border-top: none;
  }

  @include mobile {
    .seaz-search-crew-filters__title-box {
      flex-direction: column;
      align-items: flex-start;
    }

    .seaz-search-crew-filters__button-more-filters {
      margin: 0 0 0.8rem;
    }
  }
}

.fleet-filters-box {
  @include d-align-items-center;

  @include laptop {
    .seaz-search-crew-filters__button-more-filters {
      display: block;
    }
  }

  @include tablet {
    .seaz-search-crew-filters__title--main-filters {
      display: flex;
    }
  }
}

.fleet-charter-filters-form {
  margin-top: 1.6rem;

  &__container {
    border: 1px solid $seaz-head-arrow;
    background-color: $light-color;
    border-radius: 1.2rem;
    padding: 2.4rem;
  }

  .seaz-search-crew-filters__reset {
    margin-top: 2rem;
  }

  @include tablet {
    &__container {
      padding: 1.2rem;
    }
  }
}

.fleet-charter-filter-results {
  font-size: 1.4rem;
  color: $clr-light-grey;
}

.fleet-charter-filter-list {
  margin-top: 3.2rem;

  &__row {
    display: flex;
    flex-wrap: wrap;
    align-items: flex-start;
  }

  .filter-list-title {
    font-size: 1.6rem;
    font-weight: 500;
    line-height: 1.9rem;
    color: $clr-light-grey;
    text-transform: uppercase;
  }
}

.fleet-charter-filter-item {
  width: 19%;
  margin-top: 1.6rem;

  &:not(:first-of-type) {
    border-left: 1px solid $clr-ashy-grey;
    padding-left: 1.6rem;
  }

  &:not(:first-of-type) {
    margin-right: 1rem;
  }

  &__text {
    font-size: 1.4rem;
    line-height: 1.6rem;
    color: $seaz-document-count;
    margin-bottom: 0.4rem;
  }

  .seaz-charter__filtering--num {
    margin-right: 0;
  }

  @include laptop {
    width: 21%;
  }

  @include tablet {
    width: 46%;
    max-width: 15.1rem;

    &:not(:first-of-type) {
      border-left: none;
      padding-left: 0;
    }
  }
}

.filter-item-hint {
  display: block;
  width: 1.6rem;
  height: 1.6rem;
  font-size: 1rem;
  font-weight: 500;
  text-align: center;
  line-height: 1.6rem;
  background-color: $clr-grey;
  color: $light-color;
  margin-left: 0.7rem;
  border-radius: 50%;
}

.fleet-charter-filters-form-sorted {
  @include d-align-items-center;
  margin-top: 2.4rem;

  .sorted-item {
    margin-right: 1.6rem;
  }

  @include tablet {
    flex-direction: column;

    .sorted-item {
      margin: 0 0 0.8rem 0;
    }

    .download-template__button {
      width: 100%;
    }
  }
}

.fleet-charters-list {
  margin-top: 2.4rem;
}

.fleet-charters-card {
  display: block;
  @include indent-not-last-of-type(0 0 1.6rem);

  .fleet-charter-card {
    padding: 0;
  }

  .fleet-charter-card-grid {
    padding: 2.4rem 2.4rem 2.8rem;
  }

  @include tablet {
    .fleet-charter-card-grid {
      padding: 1.6rem;
    }
  }
}

.fleet-charters-card-footer {
  display: block;
  background-color: $clr-ashy-grey;
  border-radius: 0 0 1.6rem 1.6rem;

  .seaz-charter__list_card_footer {
    background-color: initial;
    border-radius: 0;
  }
}
