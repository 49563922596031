.job-search-auth-modal {
  &__text {
    font-size: 1.6rem;
    line-height: 1.9rem;
    margin-top: 3rem;
    color: $profile-month;
  }

  &__footer {
    @include footer-with-border-left-btn(3rem 0 0, 3rem 0 0);
  }

  &__btn {
    &:not(:last-of-type) {
      margin-right: 1.2rem;
    }
  }
}
