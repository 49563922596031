.seaz-tabs {
  &__button {
    display: block;
    width: 100%;
    font-weight: 500;
    text-align: center;
    letter-spacing: 0.1em;
    text-transform: uppercase;
    color: $profile-month;
    border: 1px solid $profile-month;
    padding: 1.8rem 1rem;

    &.active {
      border: 1px solid $clr-primary-and-accent;
      color: $clr-primary-and-accent;
    }

    &:disabled {
      opacity: 0.4;
    }
  }

  .tabs__header-wrap {
    margin-bottom: 4.2rem;
  }
}
