.boat-fleets-contacts {
  margin: 2rem auto 0;

  &__title {
    text-transform: uppercase;
    margin-bottom: 4.8rem;
  }

  &__container {
    margin-top: 2.4rem;
  }

  .seaz-search-quick-filter {
    @include d-align-items-center;
    margin-top: 2.4rem;
  }

  &__button {
    @include indent-not-last-of-type(0 1.2rem 0 0);
  }

  @include tablet {
    .seaz-search-quick-filter {
      flex-direction: column;
      align-items: flex-start;
    }

    .seaz-search-quick-filter-label {
      margin: 0 0 0.8rem 0;
    }
  }

  @include mobile {
    &__button {
      width: 100%;
      @include indent-not-last-of-type(0 0 1.2rem 0);
    }
  }
}

.boat-fleets-contacts-action-container {
  @include d-align-items-center;
  justify-content: flex-end;

  @include mobile {
    flex-direction: column;

    margin-top: 1.3rem;
  }
}

app-fleet-contacts-guest-card {
  display: block;

  @include indent-not-last-of-type(0 0 2.4rem 0);
}

.boat-fleets-contacts-card {
  padding: 2.4rem;
  border: 2px solid $seaz-head-arrow;
  box-shadow: 0 16px 32px rgba(43, 28, 54, 0.12);
  border-radius: 1.6rem;

  @include tablet {
    padding: 1.6rem;
  }
}

.fleets-contacts-card-header {
  @include d-flex-space-between;
  align-items: center;
  border-bottom: 1px solid $clr-ashy-grey;
  padding-bottom: 1.6rem;

  &__fleets {
    line-height: 1.6rem;
    color: $profile-month;
    text-align: right;
    margin-bottom: 0.4rem;
  }

  @include tablet {
    flex-direction: column;
    align-items: flex-start;
    border-bottom: none;
    padding-bottom: 0;

    &__container {
      @include d-align-items-center;
      width: 100%;
      border-top: 1px solid $clr-ashy-grey;
      padding-top: 1.2rem;
      margin-top: 1.2rem;
    }

    &__fleets {
      min-width: 11.4rem;
      text-align: left;
      margin: 0 0.3rem 0 0;
    }
  }
}

.fleets-contacts-card-header-person {
  @include d-align-items-center;
  margin-right: 1.2rem;

  &__avatar {
    display: block;
    border-radius: 50%;
    margin-right: 2.4rem;
  }

  &__title {
    text-transform: uppercase;
    margin-bottom: 0.4rem;
    word-break: break-word;
  }

  &__text {
    font-size: 1.6rem;
    font-weight: 500;
    line-height: 1.9rem;
    color: $seaz-text;
    word-break: break-word;
  }
}

.fleets-contacts-card-body {
  @include d-flex-space-between;
  align-items: center;
  margin-top: 1.6rem;

  &__contact {
    min-width: 4px;
    margin-right: 2.4rem;
    cursor: pointer;
  }

  &__info {
    flex-grow: 1;
    @include d-flex-space-between;
    align-items: center;
    margin-right: 2.4rem;
  }

  &__actions {
    @include d-align-items-center;
  }

  &__col {
    width: 33.3%;
    max-width: 19.2rem;
    padding-left: 2.4rem;
    border-left: 1px solid $clr-ashy-grey;
    box-sizing: border-box;
    @include indent-not-last-of-type(0 1rem 0 0);

    &--last-boat {
      max-width: 21.9rem;
    }
  }

  &__title {
    font-size: 1.4rem;
    line-height: 1.6rem;
    color: $seaz-document-count;
    margin-bottom: 0.3rem;
  }

  &__text {
    @include d-align-items-center;
    font-weight: 500;
    font-size: 1.6rem;
    line-height: 1.9rem;
    color: $seaz-text;
    word-break: break-word;

    .card-icon-left {
      margin-right: 0.3rem;
    }

    .card-icon-right {
      margin-left: 0.3rem;
    }

    &--info {
      display: inline-block;
      @include cut-text-add-dots;
    }

    &--length {
      white-space: nowrap;
      color: $seaz-document-count;
    }

    &--sign {
      padding: 0 0.5rem;
    }
  }

  &__btn {
    @include indent-not-last-of-type(0 1.2rem 0 0);
  }

  @include laptop {
    flex-wrap: wrap;

    &__info {
      order: -1;
      width: 100%;
      margin: 0 0 1.6rem;
      padding-bottom: 1.9rem;
      border-bottom: 1px solid $clr-ashy-grey;
    }
  }

  @include tablet {
    &__info {
      flex-direction: column;
    }

    &__col {
      @include d-align-items-center;
      width: 100%;
      max-width: 100%;
      padding-left: 0;
      border-left: none;
      @include indent-not-last-of-type(0 0 1.6rem 0);

      &--last-boat {
        max-width: 100%;
      }
    }

    &__contact {
      margin-right: 1rem;
    }

    &__title {
      min-width: 11.4rem;
      margin-right: 0.3rem;
    }

    &__text {
      width: 50%;
    }
  }

  @include mobile {
    &__info {
      padding-bottom: 0;
    }

    &__btn {
      @include indent-not-last-of-type(0 1rem 0 0);
      padding: 1rem 0.8rem;
    }
  }
}
