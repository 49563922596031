.social-auth-control {
  display: flex;
  align-items: center;
  margin: 3.2rem 0;

  &__button {
    @include d-flex-center-content;
    width: 100%;
    font-size: 1.6rem;
    font-weight: 500;
    padding: 1.2rem;
    border-radius: 0.4rem;
    background-color: $light-color;
    filter: drop-shadow(0px 1px 4px rgba(0, 0, 0, 0.25));
    transition: opacity 0.3s;

    &:hover {
      opacity: 0.8;
    }

    &--fb {
      color: $fb-color;
      &:disabled {
        background-color: $clr-ashy-grey;
        cursor: not-allowed;
      }
    }

    &--google {
      color: $seaz-text;
      margin-right: 1.2rem;
    }

    &:disabled {
      color: #bdbdbd;

      &:hover {
        opacity: initial;
      }
    }
  }

  &__button-icon {
    @include d-flex-center-content;
    margin-right: 1rem;
  }

  @include tablet {
    flex-direction: column;

    &__button {
      width: 100%;

      &--google {
        margin: 0 0 2.4rem;
      }
    }
  }
}
