.download-info-modal {
  &.download-info-modal {
    width: 69.2rem;
    max-width: 69.2rem;
    border-radius: 1.2rem;
  }

  &__text {
    color: $profile-month;
    margin-top: 3rem;
  }

  &__footer {
    @include footer-with-border-left-btn(0, 3rem 0 0);
    border: none;
    padding-bottom: 3rem;
  }

  &__btn {
    &:not(:last-of-type) {
      margin-right: 1.2rem;
    }
  }

  @include tablet {
    &.download-info-modal {
      width: 100%;
    }
  }
}

.download-info-modal-list {
  @include d-flex-space-between;
  align-items: center;
  margin-top: 3rem;

  @include tablet {
    flex-wrap: wrap;
  }

  @include mobile {
    flex-direction: column;
    justify-content: center;
    margin-top: 2rem;
  }
}

.download-info-modal-text {
  font-size: 1.6rem;
  line-height: 1.9rem;
}

.download-info-modal-item {
  width: 33.3%;
  text-align: center;
  padding: 1.2rem;
  border: 2px solid $light-color;
  border-radius: 1.2rem;
  cursor: pointer;

  &.selected {
    border: 2px solid $clr-primary-and-accent;
    border-radius: 1.2rem;
  }

  &__img-box {
    width: 100%;
    max-width: 12.7rem;
    margin: 0 auto 1.7rem;
  }

  &__img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  &__description {
    color: $seaz-about-text;
  }

  @include tablet {
    width: 40%;

    &__img-box {
      width: 100%;
      max-width: 16rem;
    }
  }

  @include mobile {
    width: 80%;

    &__img-box {
      width: 100%;
      max-width: 70%;
    }
  }
}
