.boat-maintenance-wrap {
  display: block;
  width: 100%;
  max-width: 127rem;
  margin: 0 auto;
}

.boat-maintenance-work-list-header {
  @include d-flex-space-between;
  align-items: center;

  &__btn {
    margin-left: 1rem;
  }

  @include tablet {
    &__title {
      font-size: 3rem;
    }
  }

  @include mobile {
    flex-direction: column;
    align-items: flex-start;

    &__btn {
      width: 100%;
      margin: 1.6rem 0 0 0;
    }
  }
}

.boat-maintenance-task-view-repeat-number {
  @include d-align-items-center;
  color: $seaz-document-count;

  app-seazone-icon {
    margin-right: 0.5rem;
  }
}

.boat-maintenance-work-list-filters {
  margin-top: 5.2rem;

  @include mobile {
    margin-top: 1.6rem;
  }
}

.boat-maintenance-work-list-filters-form {
  &__search {
    margin-bottom: 1.6rem;
  }
}

.boat-maintenance-work-list-filters-form-action {
  @include box-grid(24.4rem 24.4rem auto, initial, 'crew-select range-picker form-action-chips', 0.8rem);

  .crew-select {
    grid-area: crew-select;
  }

  .range-picker {
    grid-area: range-picker;
  }

  .form-action-chips {
    grid-area: form-action-chips;
  }

  @include laptop {
    @include box-grid(
      1fr 1fr,
      initial,
      'crew-select range-picker' 'form-action-chips form-action-chips',
      1.6rem 0.8rem
    );
  }

  @include tablet {
    @include box-grid(1fr, initial, 'crew-select' 'range-picker' 'form-action-chips', 1.6rem 0);
  }
}

.boat-maintenance-work-list-filters-form-action-chips {
  align-self: end;

  app-chips-checkbox-item {
    @include indent-not-last-of-child(0 0.8rem 0 0);
  }

  .chips-item {
    padding: 1rem 1.6rem;
  }
}

.boat-maintenance-work-list-filters-form-reset-filters {
  display: flex;
  align-items: baseline;
  margin-top: 1.6rem;

  &__btn {
    flex-shrink: 0;
    display: block;
    font-size: $base-font-size;
    line-height: 1.6rem;
    color: $seaz-edit-hover;
    border-bottom: 1px dashed $seaz-edit-hover;
    padding-bottom: 0.1rem;
    margin-right: 1rem;
  }

  .financial-data__badge {
    flex-direction: row-reverse;
    text-transform: none;
    display: inline-flex;
    font-size: 1.2rem;
    font-weight: 400;
    line-height: 120%;
    color: $seaz-text;
    border: none;
    margin: 0;

    app-seazone-icon {
      margin: 0 0.7rem 0 0;
    }
  }

  .financial-data__badge_label {
    display: none;
  }
}

.boat-maintenance-work-list-search-results {
  font-size: $base-font-size;
  line-height: 1.6rem;
  color: $seaz-document-count;
  margin-top: 1rem;
}

.boat-maintenance-work-list {
  margin-top: 4.8rem;

  @include tablet {
    margin-top: 1.6rem;
  }
}

.boat-maintenance-work,
.boat-preference {
  &-list-card-container {
    display: block;
    @include indent-not-last-of-child(0 0 2.4rem 0);
  }
}

.boat-maintenance-work-list-card {
  display: block;
  border: 2px solid rgba(53, 31, 115, 0.2);
  box-shadow: 0 16px 32px rgba(43, 28, 54, 0.12);
  border-radius: 1.6rem;

  &__title {
    font-size: 2rem;
    font-weight: 700;
    line-height: 2.3rem;
    color: $clr-primary-and-accent;
  }

  &__info-title {
    display: inline-block;
    font-size: $base-font-size;
    line-height: 1.6rem;
    color: $profile-month;
    margin-bottom: 0.4rem;
  }

  &__hide-task {
    display: inline-block;
    font-size: 1.2rem;
    line-height: 1.4rem;
    color: $profile-month;
    margin-top: 0.4rem;
  }

  @include tablet {
    &__info-title {
      margin: 0 1rem 0 0;
    }
  }
}

.boat-maintenance-work,
.boat-preference {
  &-list-card-wrapper {
    padding: 2.4rem;
    border-radius: 1.6rem 1.6rem 0 0;
    background-color: $clr-white;
    @include tablet {
      padding: 1.6rem;
    }
  }
}

.boat-maintenance-work-list-card-header {
  @include d-flex-space-between;
  align-items: center;
  border-bottom: 1px solid $seaz-bg;
  padding-bottom: 1.6rem;
  margin-bottom: 1.6rem;

  @include tablet {
    flex-direction: column;
    align-items: flex-start;
  }
}

.boat-maintenance-work,
.boat-preference {
  &-list-card-info-task {
    @include d-align-items-center;
    margin-left: 1.2rem;

    &__row {
      @include d-align-items-center;
      @include indent-not-last-of-child(0 1.6rem 0 0);
    }

    &__text {
      font-size: 1.2rem;
      font-weight: 400;
      line-height: 1.4rem;
      color: $seaz-document-count;
      margin-right: 0.8rem;
    }

    &__description {
      font-size: 1.2rem;
      font-weight: 500;
      line-height: 1.4rem;
      color: $seaz-text;
    }

    @include tablet {
      flex-direction: column;
      align-items: flex-start;
      margin: 0.4rem 0 0 0;

      &__row {
        @include indent-not-last-of-child(0 0 0.4rem 0);
      }
    }
  }
}

.boat-maintenance-work-list-card-content {
  @include box-grid(repeat(4, 1fr), initial, initial, 0 2rem);

  &__col {
    &:not(:first-of-type) {
      border-left: 1px solid $seaz-bg;
      padding-left: 2.4rem;
    }
  }

  &__text {
    font-size: 1.6rem;
    font-weight: 500;
    line-height: 1.9rem;
    color: $seaz-text;

    &.seaz-alarm {
      color: $seaz-alarm;
    }
  }

  .boat-maintenance-task-view-modal-header__status-change {
    display: block;
    max-width: 12rem;

    .status-select-container {
      max-width: 12rem;
    }
  }

  @include tablet {
    @include box-grid(1fr, initial, initial, 1.2rem 0);

    &__col {
      @include d-align-items-center;
      width: 100%;

      &--status {
        align-items: baseline;
      }

      &:not(:first-of-type) {
        border-left: 0;
        padding-left: 0;
      }
    }

    &__text {
      width: 100%;
    }

    &__title {
      min-width: 11.4rem;
      margin-right: 0.4rem;
    }
  }
}

.boat-maintenance-work {
  &-list-card-footer {
    @include d-flex-space-between;
    align-items: flex-end;
    background-color: $clr-ashy-grey;
    border-radius: 0 0 1.6rem 1.6rem;
    padding: 1.6rem 2.4rem;

    &__currency {
      margin-right: 1rem;
    }

    &__currency-cost {
      font-size: 2.4rem;
      font-weight: 500;
      line-height: 2.8rem;
      color: $clr-primary-and-accent;
    }

    &__actions {
      flex: 1 auto;
      @include d-items-end;
    }

    &__btn {
      @include indent-not-last-of-child(0 1.2rem 0 0);
    }

    @include tablet {
      flex-direction: column;
      align-items: flex-start;
      padding: 1.6rem;

      &__currency {
        @include d-align-items-center;
      }

      &__actions {
        margin-top: 1.2rem;
      }
    }

    @include tablet-md {
      flex-direction: column;
      align-items: flex-start;

      &__currency {
        margin: 0 0 1.2rem 0;
      }

      &__actions,
      &__btn {
        width: 100%;

        @include indent-not-last-of-child(0 0 1.2rem 0);
      }

      &__actions {
        flex-direction: column;
      }
    }
  }
}

.colored-select-status-change-dropdown {
  max-width: 13rem;
}

.boat-maintenance-work,
.boat-preference {
  &-list-card-progress-status-wrap {
    width: 100%;

    .progress-status-bar {
      position: relative;
      width: 100%;
      border-radius: 0.4rem;
      background-color: $bar-color;
      padding: 0.5rem 0.8rem;

      &__progress {
        position: absolute;
        top: 0;
        left: 0;
        height: 100%;
        background-color: $clr-primary-and-accent;
        border-radius: 0.4rem;
      }

      &.completed {
        .progress-status-bar__progress {
          background-color: $green-grey;
        }
      }

      &.preference-completed {
        .progress-status-bar__progress {
          background-color: #3aae27;
        }
      }

      &__val {
        position: relative;
        font-size: 1.2rem;
        font-weight: 500;
        line-height: 1.4rem;
        color: $light-color;
        z-index: 2;
      }
    }
  }
}
