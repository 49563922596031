.shared-apa-report-view {
  margin-bottom: 25rem;

  &__title {
    text-transform: uppercase;
    margin-bottom: 3rem;
  }

  .seaz-charter__apa_table {
    background-color: $light-color;
    box-shadow: 0 0.2rem 0.5rem rgb(0 0 0 / 4%);
    border-radius: 1.6rem;
    border: 0.05rem solid $seaz-alert-info-bg;
  }

  .table__col-inner-description {
    max-width: 164px;
  }

  .table_financial .table__head .table__col {
    color: $clr-purple;
  }

  .financial-data__name {
    color: $seaz-mulberry;
  }

  .pagination {
    margin-top: 3rem;
  }

  .download-apa-receipts {
    display: flex;
  }

  @include tablet {
    margin-bottom: 10rem;

    .card-dashboard,
    .seaz-charter__apa_table {
      padding: 1.7rem 1rem;
    }

    .dashboard__aside-inner {
      padding: 0;
    }
  }

  @media screen and (max-width: 1270px) {
    padding: 0 1rem;
  }
}
