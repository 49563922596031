app-boat-maintenance-equipment-tree {
  display: block;
  width: 100%;
  max-width: 42.1rem;
  margin-right: 3.2rem;

  @include desktop {
    max-width: 33rem;
  }
}

.maintenance-equipment-tree {
  width: 100%;
  max-width: 42.1rem;
  box-sizing: border-box;
  @include card-layout(1px solid $seaz-head-arrow, 0px 2px 2px rgba(43, 28, 54, 0.16), 1.6rem, $light-color, 2.4rem);

  &__link {
    position: relative;
    font-size: $base-font-size;
    font-weight: 400;
    line-height: 1.6rem;
    color: $seaz-text;
    word-break: break-word;

    &:after {
      content: '';
      position: absolute;
      bottom: -0.5rem;
      left: 0;
      width: 0;
      height: 0.2rem;
      background-color: $clr-light-grey;
      border-radius: 1rem;
      transition: width 0.3s ease;
    }

    &:hover {
      &:after {
        width: 100%;
      }
    }
  }

  .expand-btn {
    margin-right: 1rem;
  }

  @include tablet {
    padding: 1.2rem;
  }
}

.system-groups-equipment-tree {
  .maintenance-equipment-tree {
    padding: 2.4rem 0.8rem;
  }

  @include desktop {
    max-width: 33rem;
  }

  @include laptop {
    display: none;
  }
}

.maintenance-equipment-tree-toggle {
  @include d-align-items-center;
  padding: 0 0 3rem 1.2rem;

  .form-field__label {
    font-size: $base-font-size;
    line-height: 1.6rem;
    color: $seaz-text;
  }

  .form__toggle {
    order: -1;
    margin-right: 0.8rem;
  }
}

.maintenance-tree {
  max-height: 54.4rem;
  overflow-y: auto;
  padding-right: 0.6rem;
  @include custom-scroll-bar;

  mat-tree-node {
    &[aria-level='2'] {
      margin-left: 2.4rem;
    }

    &[aria-level='3'] {
      margin-left: 4.8rem;
    }

    &[aria-level='4'] {
      margin-left: 7.2rem;
    }
  }

  .mat-tree-node {
    min-height: 4rem;
  }

  .expand-btn {
    display: flex;
    justify-content: center;
    align-items: baseline;
    width: 2.4rem;
    height: 2.4rem;

    app-seazone-icon {
      transform: rotate(270deg);

      &.expanded {
        transform: rotate(90deg);
      }
    }
  }

  .count {
    @include d-flex-center-content;
    font-size: $base-font-size;
    line-height: 1.6rem;
    border-radius: 1.2rem;
    background-color: $clr-ashy-grey;
    padding: 0.4rem 1rem;
    position: relative;

    &__expired {
      color: $seaz-alarm;
      margin-right: 1.6rem;
      &::after {
        content: '';
        position: absolute;
        border: 0.1rem solid $seaz-head-arrow;
        top: 0;
        bottom: 0;
        right: 50%;
      }
    }
  }
}

.maintenance-tree-container {
  @include d-flex-space-between;
  width: 100%;
}
