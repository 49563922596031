.duration-mode-form {
  display: flex;
  align-items: flex-end;

  @include tablet {
    flex-direction: column;
    align-items: start;
  }
}

.duration-tabs {
  margin-right: 2rem;

  &__title {
    color: $seaz-text;
    margin-bottom: 0.2rem;
    line-height: 2rem;
  }

  &__switch {
    display: flex;
    padding: 0.2rem;
    background-color: $seaz-alert-info-bg;
    border-radius: 0.9rem;
  }

  &__switch-btn {
    display: block;
    border-radius: 0.7rem;
    padding: 1rem 1rem;
    cursor: pointer;

    .switch__btn_active {
      background-color: $clr-purple;
    }
  }

  .switch__btn_active .duration-tabs__switch-text {
    color: $light-color;
  }

  &__switch-text {
    font-size: 1.3rem;
    font-weight: bold;
    line-height: 1.5rem;
    color: $clr-purple;
  }
}

.duration-mode-selecting {
  &__list {
    display: flex;
  }

  &__item {
    min-width: 20rem;

    &--duration {
      min-width: 36.8rem;
    }

    &:not(:last-of-type) {
      margin-right: 2rem;
    }
  }

  @media screen and (max-width: 1300px) {
    &__item {
      width: 17rem;
      min-width: 17rem;
    }
  }

  @include tablet {
    margin-top: 1.4rem;
    width: 100%;
  }

  @include tablet-small {
    &__list {
      width: 100%;
      flex-direction: column;
    }

    &__item {
      min-width: 100%;

      &--duration,
      &--year {
        min-width: 100%;
      }

      &:not(:last-of-type) {
        margin-right: 1rem;
      }
    }
  }
}
