[class^='icon-']:before,
[class*='icon-']:before {
  font-family: 'fontello';
  font-style: normal;
  font-weight: normal;
  display: inline-block;
  text-decoration: inherit;
  text-align: center;
  font-variant: normal;
  text-transform: none;
  line-height: 1em;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-log-out:before {
  content: '\e800';
}

.icon-sharp-settings:before {
  content: '\e801';
}

.icon-account:before {
  content: '\e802';
}

.icon-arrow-left:before {
  content: '\e803';
}

.icon-arrow-up:before {
  content: '\e804';
}

.icon-close:before {
  content: '\e805';
}

.icon-anchor:before {
  content: '\e806';
}

.icon-boat-length:before {
  content: '\e807';
}

.icon-boat:before {
  content: '\e808';
}

.icon-employed:before {
  content: '\e809';
}

.icon-facebook:before {
  content: '\e80a';
}

.icon-no-glass:before {
  content: '\e80c';
}

.icon-twitter:before {
  content: '\e80d';
}

.icon-circle-right:before {
  content: '\e80f';
}

.icon-dollar:before {
  content: '\e810';
}

.icon-reports:before {
  content: '\e811';
}

.icon-email:before {
  content: '\e812';
}

.icon-forgot:before {
  content: '\e813';
}

.icon-stub:before {
  content: '\e814';
}

.icon-bell:before {
  content: '\e816';
}

.icon-upload:before {
  content: '\e815';
}

.icon-edit:before {
  content: '\e817';
}

.icon-file-delete:before {
  content: '\e818';
}

.icon-file-download:before {
  content: '\e819';
}

.icon-file-eye:before {
  content: '\e81a';
}

.icon-notification:before {
  content: '\e81b';
}

.icon-upload-doc:before {
  content: '\e81c';
}

.icon-glass:before {
  content: '\e81d';
}

.icon-budget:before {
  content: '\e804';
}

.icon-dashboard:before {
  content: '\e809';
}

.icon-fin-data:before {
  content: '\e80e';
}

.icon-fin-report:before {
  content: '\e812';
}

.icon-boat-profile:before {
  content: '\e81e';
}

.icon-date:before {
  content: '\e813';
}

.icon-web:before {
  content: '\e814';
}

.icon-plus:before {
  content: '\e81f';
}

.icon-check:before {
  content: '\e820';
}

.icon-table-error:before {
  content: '\e821';
}

.icon-list:before {
  content: '\e822';
}

.icon-arrow-right:before {
  content: '\e823';
}

.icon-update:before {
  content: '\e824';
}

.icon-table-edit:before {
  content: '\e825';
}

.icon-down-arrow-sm:before {
  content: '\e826';
}

.icon-close:before {
  content: '\e827';
}

.icon-arrow-rights:before {
  content: '\e829';
}

.icon-sort-down:before {
  content: '\e82a';
}

.icon-up-arrow-sm:before {
  content: '\e828';
}

.icon-expand:before {
  content: '\e82b';
}

.icon-add-crew:before {
  content: '\e82c';
}

.icon-polygon-right-sm:before {
  content: '\e82d';
}

.icon-card-calendar:before {
  content: '\e82e';
}

.icon-card-cash:before {
  content: '\e82f';
}

.icon-card-contact:before {
  content: '\e830';
}

.icon-card-pos:before {
  content: '\e831';
}

.icon-vacation:before {
  content: '\e832';
}

.icon-search:before {
  content: '\e833';
}

.icon-edit-two:before {
  content: '\e834';
}

.icon-list-1:before {
  content: '\e835';
}

.icon-add-image:before {
  content: '\e822';
}

.icon-dots:before {
  content: '\e836';
}

.icon-finish-contracts:before {
  content: '\e837';
}

.icon-error:before {
  content: '\e858';
}

.icon-thin-check:before {
  content: '\e859';
}

.icon-circle:before {
  content: '\e83A';
}

.icon-up-arrow-thin:before {
  content: '\E83B';
}

.icon-mdi_calendar-edit:before {
  content: '\e83c';
}

.icon-youtube:before {
  content: '\e83d';
}

.icon-change-password:before {
  content: '\e83e';
}

.icon-file:before {
  content: '\e87e';
}

.icon-linkedin:before {
  content: '\e882';
}

.icon-portfolio:before {
  content: '\e883';
}

.icon-phone:before {
  content: '\e842';
}

.icon-message:before {
  content: '\e843';
}

.icon-gas:before {
  content: '\e844';
}

.icon-generator:before {
  content: '\e845';
}

.icon-mileage:before {
  content: '\e846';
}

.icon-play:before {
  content: '\e847';
}
