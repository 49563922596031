.available-days-quantity {
  display: flex;

  &__btn {
    &:disabled {
      background-color: $seaz-border;
      cursor: unset;
      border-color: transparent;
    }
  }

  &__input {
    display: block;
    width: 11.7rem !important;
    margin: 0 0.8rem;

    input {
      text-align: center;
    }
  }
}
