.folders-list-container {
  margin-top: 2.4rem;
}

.documents-folder-text {
  font-size: 1.6rem;
  font-weight: 500;
  line-height: 1.9rem;
  color: $seaz-text;
  margin-top: 1rem;
}

.folders-container {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(29.8rem, 1fr));
  grid-gap: 2.4rem;

  @include tablet {
    grid-gap: 1.2rem;
  }
}

.documents-folder-info {
  @include d-align-items-center;

  &__row {
    @include d-align-items-center;
    font-size: 1.4rem;
    font-weight: 400;
    line-height: 1.6rem;
    margin-top: 0.8rem;
  }

  &__warning {
    &:before {
      content: '';
      width: 0.1rem;
      height: 1.6rem;
      background-color: $seaz-head-arrow;
      margin: 0 1.2rem;
    }
  }

  &__icon {
    margin-right: 0.7rem;
  }
}
