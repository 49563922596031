.rating-modal {
  &__footer {
    @include footer-with-border-left-btn(3rem 0 1rem, 4.6rem 0 0);
  }

  @include tablet {
    .modal__wrap {
      padding: 1rem;
    }
  }
}

.ratings-company-content {
  @include d-flex-space-between;
  align-items: center;
  margin-top: 3rem;
}

.text__company {
  font-size: 1.6rem;
  font-weight: 500;
  line-height: 1.9rem;
  color: $seaz-about-text;
}

.text__reviews {
  margin-top: 0.4rem;
  color: $profile-month;
}

.rate-alert {
  color: $profile-month;
  margin-top: 3rem;
  padding-bottom: 3rem;
  border-bottom: 1px solid $seaz-head-arrow;
}

.supplier-rating-form {
  display: block;
  margin-top: 3rem;
}

.rating-report-modal {
  .rating-history {
    border-top: 0;
    padding-top: 0;
    padding-bottom: 2.4rem;
    border-bottom: 1px solid #e0e0e0;
  }

  &__text-area {
    margin-top: 3rem;
  }
}
