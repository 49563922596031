.service-form {
  box-sizing: border-box;

  &__top-indent {
    margin-top: 6.4rem;
  }

  &__title {
    color: $seaz-text;

    &--small {
      font-size: 1.6rem;
    }
  }

  &__row {
    margin-top: 3.2rem;
  }

  &__chips-select {
    display: block;
    &.ng-touched.ng-invalid .chips-item {
      border-color: $seaz-alarm;
    }

    &--indent-top {
      margin-top: 2.4rem;
    }
  }

  &__chips-icon {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 3.2rem;
    height: 3.2rem;
    background-color: #c2bed4;
    border-radius: 50%;
    margin-right: 0.8rem;
    img {
      max-width: 100%;
      filter: invert(100%) sepia(0%) saturate(0%) hue-rotate(148deg) brightness(108%) contrast(101%);
    }
  }

  //.stash-items {
  //  flex-wrap: initial;
  //}

  .stash-items-overflow-count {
    cursor: initial;
  }

  &__search-content {
    display: flex;
    align-items: center;
    width: 100%;
  }

  &__search-container {
    width: 90%;
    box-sizing: border-box;
    margin-right: 2.4rem;
  }

  &__search-select-form-list {
    flex-shrink: 0;
  }

  &__suggested-services {
    margin-top: 2.4rem;
  }

  &__role {
    color: $clr-light-grey;
  }

  &__name {
    color: $clr-primary-and-accent;
  }

  &__separator {
    margin: 0 1.2rem;
    color: $clr-light-grey;
  }

  &__suggested-services-btn {
    app-seazone-icon {
      transition: transform 0.3s;
    }

    &:hover {
      app-seazone-icon {
        transform: rotateZ(180deg);
      }
    }
  }

  &__title-box {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  &__details {
    margin-top: 3rem;
  }

  .supplier-edit__footer {
    padding: 0;
  }

  @include tablet {
    &__top-indent {
      margin-top: 3rem;
    }

    &__chips-select {
      &--indent-top {
        margin-top: 1.6rem;
      }
    }

    &__search-content {
      flex-direction: column;
    }

    &__search-container {
      width: 100%;
      margin-right: 0;
    }

    &__separator {
      margin: 0 1rem;
    }

    &__search-select-form-list {
      flex-shrink: 1;
      width: 100%;
      margin-top: 1.6rem;
    }
  }

  @include mobile {
    &__title-box {
      flex-direction: column;
      align-items: flex-start;
    }

    .form__checkbox-label-text {
      padding-left: 1rem;
    }

    &__added-btn {
      width: 100%;
      margin-top: 1.6rem;
    }
  }
}
