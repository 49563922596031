.supplier-short-card {
  margin-top: 3.2rem;
  text-align: left;

  &.supplier-card {
    padding: 0;

    .search-card__about-person-info {
      display: block;
    }
  }

  &__body {
    display: grid;
    grid-template-columns: 8.9rem 1fr 8.3rem;
    grid-gap: 0 2rem;
    grid-template-areas: 'info person toggle-box';
    padding: 2.4rem;
  }

  &__info {
    grid-area: info;

    .rating-box-person {
      flex-direction: column;
      justify-content: center;
    }

    .rating-box-person__text {
      margin: 0.8rem 0 0;
    }
  }

  .search-card__about-person {
    grid-area: person;
    border: none;
    padding-bottom: 0;
  }

  &__photo {
    width: 8.8rem;
    height: 8.8rem;
    border: 2px solid $clr-primary-and-accent;
    border-radius: 50%;
    box-sizing: border-box;

    .seaz-search__card_avatar {
      border: 3px solid #fff;
      box-sizing: border-box;
    }
  }

  &__link {
    @include d-align-items-center;
    font-size: 1.6rem;
    color: $seaz-text;
    line-height: 1.9rem;
    margin-top: 1.2rem;
  }

  &__link-text {
    display: block;
    width: 17rem;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
  }

  .supplier-card__work-time {
    text-align: left;
  }

  .supplier-card__visit-info {
    justify-content: flex-start;
  }

  .search-card__location-point {
    width: 9.2rem;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
  }

  &__toggle-box {
    grid-area: toggle-box;

    app-toggle-checkbox {
      @include d-align-items-center;
    }

    .form-field__label {
      margin-right: 0.8rem;
    }
  }

  @include laptop {
    &__body {
      grid-template-columns: 8.9rem 1fr;
      grid-template-areas:
        'info person'
        'toggle-box toggle-box';
      grid-gap: 2rem 1rem;
      padding: 1.6rem;
    }

    &__toggle-box {
      justify-self: end;
    }
  }

  @include tablet {
    grid-template-columns: 8.9rem 1fr 8.3rem;
    grid-template-areas: 'info person toggle-box';
    grid-gap: 2rem;

    .supplier-card__rating-box {
      margin-top: 0.8rem;
    }
  }
}
