.seaz-user {
  &__contract {
    .seaz-crew__contract_head {
      padding-top: 6rem;
      width: 126.4rem;
      margin: 0 auto;
      @media screen and (max-width: 1300px) {
        width: 100rem;
      }
      @include laptop {
        width: 97%;
        padding-top: 2.9rem;
      }
    }

    &_wrap {
      width: 126.4rem;
      margin: 0 auto;
      margin-top: 3rem;
      padding-bottom: 10rem;
      @media screen and (max-width: 1300px) {
        width: 100rem;
      }
      @include laptop {
        width: 100%;
        padding: 0 1.6rem;
        padding-bottom: 10rem;
      }
      app-contracts-item-card {
        &:last-of-type {
          .seaz-user__contract_card {
            margin-bottom: 0;
          }
        }
      }
      .no-contracts img {
        max-width: 9.3rem;

        @include tablet {
          max-width: 3.5rem;
        }
      }
    }
    &_badge {
      position: absolute;
      padding: 0.9rem 1.2rem;
      font-size: 1.4rem;
      text-transform: uppercase;
      color: $clr-white;
      letter-spacing: 0.1em;
      font-weight: 500;
      top: -16px;
      left: -12px;
      @include laptop {
        position: relative;
        top: inherit;
        left: inherit;
        width: fit-content;
        padding: 0.3rem 0.8rem 0.1rem;
      }
      &--tablet {
        display: none;
        @include laptop {
          display: flex;
        }
      }
      &--desktop {
        @include laptop {
          display: none;
        }
      }
      &.seaz-badge-new {
        background: $seaz-edit-hover;
      }
      &.seaz-badge-running {
        background: $seaz-success;
      }
      &.seaz-badge-rejected {
        background: $seaz-alarm;
      }
      &.seaz-badge-finished {
        background: $seaz-warning;
      }
    }
    &_card {
      display: flex;
      justify-content: space-between;
      background-color: $clr-white;
      padding: 2.4rem;
      margin-bottom: 1.6rem;
      @include laptop {
        flex-direction: column;
        padding: 1.6rem;
      }
      &--left {
        display: flex;
        padding: 1.5rem 0;
        width: 80%;
        @include laptop {
          width: 100%;
          padding: 0;
        }
        @media screen and (max-width: 600px) {
          .boat-info {
            .seaz-user__contract_card-col {
              padding-bottom: 0;
            }
          }
        }
      }
      &_img {
        position: relative;
        margin-right: 3rem;
        display: flex;
        flex-direction: column;
        @media screen and (max-width: 600px) {
          margin-right: 1.6rem;
        }
        img {
          width: 20rem;
          height: 20rem;
          border-radius: 16px;
          object-fit: cover;
          @include laptop {
            width: 11rem;
            height: 11rem;
          }
        }
      }
      &_head_row {
        display: flex;
        margin-bottom: 1.6rem;
        @include laptop {
          margin-bottom: 1rem;
        }

        @include mobile {
          display: inline-block;
        }
      }
      &_flag {
        width: 3.2rem;
        height: 2rem;
        margin-right: 1.2rem;
        border: 1px solid $seaz-head-arrow;

        @media screen and (max-width: 480px) {
          margin-right: 0.8rem;
        }
      }
      &_title {
        color: $clr-primary-and-accent;
        @include laptop {
          font-size: 1.5rem;
        }

        @include tablet {
          line-height: 2rem;
        }
      }
      &-col {
        display: flex;
        flex-direction: column;
        @media screen and (max-width: 600px) {
          padding-bottom: 1.2rem;
        }
      }
      &_label {
        font-size: 1rem;
        text-transform: uppercase;
        color: $clr-light-grey;
        letter-spacing: 0.5px;
        @include laptop {
          margin-bottom: 0.4rem;
        }
      }
      &_name {
        color: $clr-primary-and-accent;
        margin-bottom: 1.6rem;
        @include laptop {
          font-size: 2rem;
          margin-bottom: 1rem;
          line-height: 2rem;
        }
      }
      &_type {
        color: $seaz-text;
        margin-top: 0.5rem;
        margin-bottom: 1.6rem;
        @include laptop {
          margin-bottom: 0;
          margin-right: 1.6rem;
          margin-top: 0;
        }
      }
      &-row {
        display: flex;
        align-items: flex-end;
        @media screen and (max-width: 600px) {
          align-items: center;
        }
        app-seazone-icon {
          padding-bottom: 6px;
          margin: 0 10px;
          @media screen and (max-width: 600px) {
            padding-bottom: 0;
            padding-top: 0.5rem;
          }
        }
      }
      &_date {
        color: $seaz-text;
      }
    }
    &_btns {
      display: flex;
      flex-direction: column;
      @include laptop {
        flex-direction: row;
        flex-wrap: wrap;
      }
      @media screen and (max-width: 600px) {
        flex-direction: column;
        flex-wrap: nowrap;
      }
      button {
        padding: 1rem 1.4rem;
        @include laptop {
          width: 49%;
          &:nth-of-type(2n - 1) {
            margin-right: 1%;
          }
        }
        @media screen and (max-width: 600px) {
          margin-bottom: 0.8rem;
          width: 100%;
          &:nth-of-type(2n - 1) {
            margin-right: 0;
          }
        }
      }
    }

    &_page {
      background-color: $seaz-bg;

      .seaz-crew__contract_head {
        width: 126.4rem;
        margin: 0 auto;
        @media screen and (max-width: 1350px) {
          width: 100rem;
        }
        @include laptop {
          width: 100%;
          padding: 2rem 1.6rem 0;
          flex-direction: column;
        }
        .seaz__btn_outlined-green,
        .seaz__btn_outlined-red {
          @include tablet {
            padding: 0.9rem 2rem;
            font-size: 1.2rem;
            line-height: 1.2rem;
          }
        }
        .seaz__btn_outlined {
          @include tablet {
            border-color: $seaz-alarm;
            color: $seaz-alarm;
            &:hover {
              background-color: transparent;
            }
          }
        }
        .seaz__return_btn {
          @include laptop {
            top: 3rem;
            left: 1.6rem;
          }
        }
        .seaz-crew__contract_head_title {
          @media screen and (max-width: 700px) {
            width: 78%;
          }
          @include mobile {
            width: 100%;
            padding-left: 7rem;
            justify-content: flex-start;
          }
        }
      }
      .seaz-crew__contract_head_icon {
        margin-bottom: 7px;
      }

      .seaz-user__contract_head_btns {
        display: flex;
        position: absolute;
        right: 0;
        @include laptop {
          position: relative;
          flex-direction: column;
          padding-top: 2.4rem;
          width: 100%;
          .card__btn {
            width: 100%;
            margin-bottom: 1.2rem;
          }
        }
        button:not(:last-of-type) {
          margin-right: 0.8rem;
          @include laptop {
            margin-right: 0;
          }
        }
      }
      .seaz-crew__contract_content {
        width: 126.4rem;
        margin: 0 auto;
        @media screen and (max-width: 1350px) {
          width: 100rem;
        }
        @include laptop {
          width: 100%;
          padding: 2rem 1.6rem 0;
        }
        @media screen and (max-width: 700px) {
          flex-direction: column;
        }
        app-bank-details {
          @include laptop {
            width: 100%;
          }
        }
        .carousel-slide-content {
          @include laptop {
            width: 100%;
          }
        }
        .seaz-crew__contract_header {
          @include laptop {
            min-height: 60px;
          }
        }
        .seaz-crew__contract_details_file {
          @include laptop {
            word-break: break-all;
            align-items: flex-start;
          }
        }
        .seaz-crew__contract_carousel {
          @include tablet {
            width: 36rem;
          }
          @media screen and (max-width: 700px) {
            width: 100%;
          }
        }
        .seaz-crew__contract_details_label {
          @media screen and (max-width: 700px) {
            width: 18.9rem;
          }
        }
        app-contract-details {
          @media screen and (max-width: 700px) {
            width: 100%;
          }
        }
      }
      .seaz-crew__contract_details_label,
      .seaz-crew__details_text {
        color: $seaz-text;
      }
      .seaz-crew__contract_details_file {
        display: flex;
        cursor: pointer;
        margin-bottom: 1.5rem;
      }
    }
    &_info {
      @include laptop {
        display: none;
      }
      &--tablet {
        display: none;
        @include laptop {
          display: flex;
          margin: 1.2rem 0;
          align-items: center;
        }
        @media screen and (max-width: 600px) {
          flex-direction: column;
          align-items: flex-start;
        }
      }
    }
  }
}
app-user-contracts {
  display: block;
  background-color: $seaz-bg;
}

.boat__card {
  .seaz-crew__contract_aside {
    background-color: $seaz-bg;
  }
  .seaz-crew__contract_aside_btn {
    top: 0;

    @media screen and (max-width: 768px) {
      top: -2rem;
    }
  }
}
.seaz-crew__contract_header {
  flex-direction: column;
  min-height: 60px;
}
.carousel-indicators {
  bottom: 1.2rem;
  position: absolute;
  width: 100%;
  @media screen and (max-width: 700px) {
    bottom: 5.2rem;
  }
}
.carousel-slide .carousel-slide {
  position: relative;
}
.seaz-crew__contract_aside {
  background-color: $clr-white;
  margin-bottom: 4rem;
  height: 100%;
  margin-top: 1.6rem;
  @media screen and (max-width: 700px) {
    margin-bottom: 8rem;
  }
}
.seaz-crew__contract_carousel .seaz-cv__slider_btn {
  background-color: $seaz-head-arrow;
}
.seaz-crew__add_date {
  width: 100%;
}
.seaz-crew__contract_add_row {
  width: 100%;
  .form__col_currency {
    width: 24%;
    @include mobile {
      width: 100%;
    }
  }
  .form__col {
    @include mobile {
      width: 100%;
    }
  }
  app-seazone-documents-uploader {
    width: 100%;
    .seaz-uploader__document {
      flex-direction: column;
    }
    .seaz-uploader__document_label {
      padding-bottom: 1.6rem;
      color: $clr-primary-and-accent;
    }
    .seaz__btn_small-outlined {
      font-weight: 500;
      padding: 0.6rem 1.5rem;
      border-color: $seaz-mulberry;
      color: $seaz-mulberry;
      &.disabled {
        color: $clr-white;
        border-color: transparent;
      }
    }
    .icon-close {
      background: $seaz-bg;
      border: none;
      color: $seaz-text;
      width: 1.6rem;
      display: flex;
      align-items: center;
      justify-content: center;
      height: 1.6rem;
      &::before {
        font-size: 8px;
      }
    }
  }
  .form-field--label {
    margin-right: 2rem;
  }
  app-toggle-checkbox {
    padding-top: 3px;
  }
  &.seaz-modal__offer_publish {
    flex-direction: column;
    .form-field--label {
      color: $clr-primary-and-accent;
      padding-bottom: 1.5rem;
    }
    .form__toggle-label:before {
      width: 4rem;
      height: 2.4rem;
      border-radius: 18px;
    }
    .form__toggle-input:checked + label:before {
      background-color: $seaz-mulberry;
    }
    .form__toggle-label:after {
      top: 0;
      width: 2.4rem;
      height: 2.4rem;
      border: 2px solid $seaz-mulberry;
      background-color: transparent;
      box-shadow: none;
    }
    .form__toggle-input:checked + label:after {
      background-color: $clr-white;
      border: 2px solid $seaz-mulberry;
      width: 2.4rem;
      height: 2.4rem;
    }
    .form__toggle-label:before {
      background-color: transparent;
      border: 2px solid $seaz-mulberry;
      position: relative;
      left: -1px;
    }
  }

  .amount-salary-crew {
    .form__input {
      height: 4rem;
      border-radius: 0.4rem;
    }

    .form__block-text {
      font-size: 1.4rem;
      line-height: 2rem;
      margin-bottom: 0.2rem;
      color: #010202;
    }
  }
}
.seaz-crew__contract_add_checkbox {
  display: flex;
  align-items: flex-end;
  padding-bottom: 1.4rem;
  .form__checkbox-label {
    height: 2.4rem;
    width: 2.4rem;
  }
  .form__checkbox-label:before {
    height: 2.4rem;
    width: 2.4rem;
    border-color: $seaz-mulberry;
    border-radius: 0;
  }
  .form__checkbox-input:checked + label:before {
    background-color: $seaz-mulberry;
  }
}
.seaz-modal__contract_add {
  padding: 1rem;
  display: block;
  @include mobile {
    padding: 2rem;
  }
  &_title {
    padding-bottom: 3rem;
  }
}
.seaz-crew__boat_contract {
  .seaz-crew__contract_carousel {
    @include laptop {
      width: 36rem;
    }
  }
  .seaz-crew__contract_content {
    @include tablet-big {
      flex-direction: column;
    }
  }
  app-contract-details {
    @include tablet-big {
      width: 100%;
    }
  }
  .seaz-crew__contract_carousel {
    @include tablet-big {
      width: 100%;
    }
  }
  .boat__card .seaz-crew__contract_aside_btn {
    @include tablet-big {
      top: -18px;
    }
  }
}
app-overlay .modal__wrap .modal-close {
  position: fixed;
}
.seaz-modal__contract_salary {
  justify-content: space-between;
  margin-bottom: 4.8rem;
  app-seazone-input {
    width: 49.4%;
    margin-right: 0.8rem;
  }
  .form__col {
    width: 49.4%;
  }

  @media screen and (max-width: 480px) {
    app-seazone-input,
    .form__col {
      width: 100%;
    }

    app-seazone-input {
      margin-right: 0;
      margin-bottom: 1.6rem;
    }
  }
}
.seaz-modal__contract_btns {
  justify-content: flex-end;
  .form__col {
    width: auto;
  }
}
