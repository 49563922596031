.charter-guest-list-modal {
  width: 100%;
  max-width: 126.3rem;
}

.charter-manage-guests-modal {
  .switch {
    margin-top: 3rem;
  }

  .search-field-alternate {
    margin-top: 2.4rem;
  }

  .fleet-contacts-guest-card {
    margin-top: 2.4rem;
  }
}

.charter-manage-guests-modal-footer {
  @include footer-with-border-left-btn(0 0 1rem 0, 3rem 0 0);
  border: none;

  &__btn {
    @include indent-not-last-of-type(0 1.2rem 0 0);
  }
}
