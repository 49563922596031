.card-statements {
  flex-shrink: 0;
  flex-basis: 33%;
  @include tablet {
    flex-grow: 1;
    width: 100%;
    flex-basis: 100%;
  }

  &__header-card {
    min-width: 7.5rem;
    margin-right: 0.8rem;
    margin-bottom: 0.8rem;
    display: flex;
    background-color: #f0f0f3;
    border-radius: 8px;
    padding: 0.8rem;
    justify-content: space-between;
    flex-direction: row;

    .text-purple-dark,
    .text-green {
      font-size: 1.6rem;
    }

    &:not(.enable) {
      cursor: default;
    }

    &.positive {
      background-color: #46a6a4;
      color: white;
    }

    &.yellow {
      background-color: #fdefc8;
      color: #94792b;
    }

    &.red {
      background-color: #f5dadb;
      color: #d70000;
    }

    &_container {
      display: flex;
      flex-wrap: wrap;
      margin-top: 0.4rem;

      &--month {
        font-size: 1.2rem;
        color: #828282;
        width: 100%;
        font-weight: 400;
        margin-bottom: 0.8rem;
        line-height: 1.44rem;
      }
    }

    &--title {
      flex: 1 1 auto;
      font-weight: 500;
      font-size: 14px;
      line-height: 14px;
      display: inline-flex;
      align-items: center;
      gap: 0.8rem;
      color: $seaz-text;
    }

    &--count {
      display: none;
      @include tablet {
        width: 2.2rem;
        height: 2.2rem;
        border-radius: 2.8rem;
        background-color: #f5f5f5;
        color: #351f73;
        display: flex;
        align-items: center;
        text-align: center;
        justify-content: center;
      }
    }

    &--toggle {
      display: none;
      @include tablet {
        display: flex;
        transform: rotate(90deg);
        &.active {
          transform: rotate(-90deg);
        }
      }
    }

    &--tooltip {
      display: flex;
      justify-content: flex-start;
      width: 2rem;
    }
  }

  &__header-card--bank-account {
    color: white;

    &.normal {
      background-color: #05a1e8;
      .balance-card__currency {
        color: rgba(255, 255, 255, 0.72);
      }
    }
    &.error {
      background-color: #f5dadb;
      color: #d70000;
    }
    &.warning {
      background-color: #fdefc8;
      color: #94792b;
    }
  }

  &__nav {
    display: flex;
    justify-content: space-between;
    align-items: center;

    @include laptop {
      flex-direction: column;
      margin-bottom: 3.2rem;
    }

    &--slider {
      width: 80%;
      position: relative;

      &.full-width {
        width: 100%;
      }

      @include laptop {
        width: 100%;
      }
    }

    &--add-btn {
      display: flex;
      justify-content: center;
      align-items: center;
      align-self: center;
      min-width: 22.9rem;
      border-radius: 1.6rem;
      background-color: $clr-ashy-grey;
      font-size: 1.4rem;
      font-weight: bold;
      color: $clr-green;
      transition: opacity 0.3s;
      padding: 3.4rem;

      &:hover {
        border: 0.1rem solid $seaz-head-arrow;
      }

      &:active {
        border: 0.3rem solid $seaz-head-arrow;
      }

      @include laptop {
        width: 100%;
        padding: 1.2rem;
        border: 0.3rem solid transparent;
      }
    }

    &--add-btn-icon {
      display: block;
      margin-right: 1.7rem;
    }

    app-scroll-controls .controls-container {
      button {
        display: flex;
        justify-content: center;
        align-items: center;
      }

      .controls-container__btn {
        background-color: unset;
        border-radius: 100rem;
        width: 3.2rem;
        height: 3.2rem;

        &:hover {
          background-color: $seaz-bg;

          .icon-arrow-left::before {
            color: unset;
          }

          .icon-arrow-rights::before {
            color: unset;
          }
        }

        &:active {
          background-color: $seaz-head-arrow;

          .icon-arrow-left::before {
            color: unset;
          }

          .icon-arrow-rights::before {
            color: unset;
          }
        }
      }
    }

    .large-card {
      background-color: white;
      width: 23rem;
      height: 8.4rem;
      box-shadow: 0 0.2rem 0.8rem rgba(0, 0, 0, 0.1);
      border-radius: 1.6rem;
      padding: 1.6rem;

      &.card-statements__header-card {
        background-color: white;
        transition: all 0.4s ease;

        svg {
          fill: #bdbdbd;
        }

        .large-currency {
          opacity: 0.6;
        }

        &.positive {
          color: #3f9795;

          .large-currency {
            color: rgba(79, 79, 79);
          }
        }

        &.yellow {
          color: #f49342;
        }

        &.red {
          color: #d70000;
        }

        &.active {
          &.positive {
            color: white;
            background-color: #46a6a4;

            .large-currency {
              color: white;
            }

            svg {
              fill: white;
            }
          }

          &.yellow {
            color: #94792b;
            background-color: #fdefc8;

            svg {
              fill: #94792b;
            }
          }

          &.red {
            background-color: #f5dadb;

            svg {
              fill: #d70000;
            }
          }
        }
      }

      .large-currency {
        font-size: 1.4rem;
        line-height: 1.6rem;
        font-weight: bold;
        margin-bottom: 0.8rem;

        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        width: 17rem;
      }

      .large-value {
        font-size: 2.4rem;
        line-height: 2.8rem;
        font-weight: bold;

        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        width: 18rem;
      }
    }
  }

  .table-spacing th {
    border: none;
    padding-top: 1.2rem;
  }

  &__cards {
    &-info {
      width: 100%;
      display: inline-flex;
      flex-direction: row;
      position: relative;
      align-items: center;
      gap: 0.8rem;
    }

    &-toggle {
      @include tablet {
        display: none;
      }
    }
  }

  .flexible-form {
    display: flex;

    @include tablet {
      flex-direction: column;
    }
  }

  .flexible {
    display: flex;
    margin-bottom: 3.5rem;

    @include laptop {
      flex-direction: column;
    }
  }

  .date-period {
    font-style: normal;
    font-weight: normal;
    font-size: 1.4rem;
    line-height: 1.6rem;
    color: $clr-main;
    margin-bottom: 2.5rem;

    display: flex;
    align-items: center;
  }

  .card-statements-form {
    .form-wrapper {
      display: flex;

      @include tablet {
        flex-direction: column;
      }
    }

    app-date-control {
      margin-right: 1.2rem;

      @include tablet {
        margin-right: unset;
      }
    }

    .btn {
      font-style: normal;
      font-weight: 500;
      font-size: 1.2rem;
      line-height: 100%;
      color: $clr-green;
      border: 0.1rem solid #3f9795;
      padding: 1.4rem 2.3rem;

      display: flex;
      justify-content: center;
      align-items: center;
      text-align: center;
      letter-spacing: 0.099626rem;
      text-transform: uppercase;

      &:hover {
        background: $clr-green;
        color: white;
      }

      &:active {
        color: white;
        background: #3f9795;
      }
    }
  }

  .block {
    display: flex;
    position: relative;
    width: 100%;
    height: 100%;
    flex: 1 50%;

    app-date-control {
      width: 16.1rem;
    }

    @include tablet {
      margin-bottom: 1.2rem;
      justify-content: space-between;
      flex: unset;

      app-date-control {
        flex-basis: 100%;
      }
    }

    @include tablet {
      justify-content: space-between;
    }
  }

  .field-label {
    font-style: normal;
    font-weight: normal;
    font-size: 1.4rem;
    line-height: 2rem;
    color: $seaz-text;
    margin-right: 1.2rem;
    align-items: center;
    display: flex;
    white-space: nowrap;

    @include tablet {
      width: 16rem;
      flex-shrink: 0;
    }
  }

  .title-h3 {
    font: bold 2.4rem/2.8rem $base-font;
    color: $clr-purple;
    margin-right: 4rem;

    @include tablet {
      margin-right: unset;
      margin-bottom: 3.2rem;
    }
  }

  &-title {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-bottom: 2.4rem;

    @include laptop {
      flex-direction: column;
      margin-bottom: 3.2rem;
    }

    @include huge {
      margin-bottom: 4rem;
    }
  }

  // table
  .title-table {
    display: flex;
    flex-direction: row;
    justify-content: space-between;

    @include tablet {
      flex-direction: column;

      .btn {
        width: 100%;
        margin-top: 1.2rem;
        justify-content: center;
      }
    }
  }

  .boat-home__row .title-h1 {
    font-style: normal;
    font-weight: 500;
    font-size: 3rem;
    line-height: 3rem;
    color: $clr-primary-and-accent;

    @include tablet {
      font-size: 2.4rem;
    }
  }

  .buttons-row .btn {
    display: flex;
    align-items: center;
    border: 0.1rem solid $clr-primary-and-accent;
    padding: 1.1rem 3.2rem;
    border-radius: 0.4rem;
    text-transform: uppercase;
    &:not(:last-child) {
      margin-right: 1.2rem;
    }

    .main-btn__text {
      font-style: normal;
      font-weight: 500;
      font-size: 1.4rem;
      letter-spacing: 0.16rem;
      color: $clr-primary-and-accent;
    }
  }

  .wrap-table__scroll {
    background: #ffffff;
    box-shadow: 0 0.2rem 0.5rem rgba(0, 0, 0, 0.04);
    border-radius: 1.6rem;
    padding: 0 1.6rem 0 1.6rem;
    margin-top: 3rem;

    .cover {
      overflow: auto;
      overflow-y: hidden;
    }

    .table_financial {
      margin-top: 0;
      border-collapse: separate;

      &-wrapper {
        padding: 1.6rem;
      }

      .table_financial-wrapper {
        padding-top: 0;
      }
    }

    app-scroll-controls .controls-container {
      top: -3rem;
    }
  }

  .table_financial .table__head {
    .financial-data__table_row .table__col:nth-child(n) {
      padding-bottom: 1.2rem;
    }

    .financial-data__table_row .table__col:first-child {
      padding-left: 0.8rem;
    }

    .form-field--content-suffix .icon-seazone-select-arrow:before {
      font-size: 1rem;
    }

    app-seazone-form-field .seazone-form-field--wrapper {
      .select-placeholder {
        //color: $seaz-text;
      }

      label {
        font-style: normal;
        font-weight: normal;
        font-size: 1.2rem;
        line-height: 1.2rem;
        display: flex;
        align-items: center;
        color: $seaz-document-count;
        margin: 0 0 0.4rem;
      }
    }

    .financial-data__table_joined {
      max-width: 15.2rem;
      padding-left: 0.8rem;

      label {
        font-style: normal;
        font-weight: normal;
        font-size: 1.2rem;
        line-height: 1.2rem;
        display: flex;
        align-items: center;
        color: $seaz-document-count;
        margin-bottom: 0.4rem;
      }
    }

    .financial-data__table--receipt {
      app-date-range app-seazone-form-field {
        max-width: 11.5rem;
      }
    }

    //.financial-data__table--method {
    //  app-seazone-form-field {
    //    max-width: 9.3rem;
    //  }
    //}

    //.financial-data__table--category {
    //  max-width: 14.8rem;
    //  width: 14.8rem;
    //
    //  app-filter-group-container {
    //    max-width: 14.8rem;
    //    width: 14.8rem;
    //  }
    //}

    .financial-data__table_joined .form__col:not(:last-child) {
      margin-right: 0.6rem !important;
    }

    .financial-data__table--supplier {
      max-width: 16.4rem;
      width: 16.4rem;

      app-seazone-form-field {
        max-width: 15rem;
        width: 15rem;
      }
    }

    .financial-data__table--description {
      max-width: 10.6rem;
      width: 10.6rem;

      app-seazone-input app-seazone-form-field {
        max-width: 9.8rem;
        width: 9.8rem;
      }
    }
  }

  .table_financial .table__body .table__row:nth-child(4n-2) + .table__row .table__col {
    background: $seaz-bg;
  }

  .table_financial .table__body .table__row:nth-child(6n-2) .table__col {
    background: white;
  }

  .table_financial .table__body {
    .table__row {
      .financial-data__description {
        max-width: 9.8rem;
        width: 9.8rem;
      }

      .financial-data__currency {
        max-width: 4.9rem;
        width: 4.9rem;
        max-height: 4rem;
        line-height: 2rem;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;

        .currency {
          max-height: 4rem;
          line-height: 2rem;
          overflow: hidden;
          white-space: nowrap;
          text-overflow: ellipsis;
        }
      }

      .financial-data__currency-amount {
        max-width: 9.3rem;
        width: 9.3rem;
        max-height: 4rem;
        line-height: 2rem;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;

        .amount {
          max-height: 4rem;
          line-height: 2rem;
          overflow: hidden;
          white-space: nowrap;
          text-overflow: ellipsis;
        }
      }

      .financial-data__usd {
        max-width: 8.4rem;
        width: 8.4rem;
        max-height: 4rem;
        line-height: 2rem;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
      }
    }

    .table__row:first-child {
      .table__col:first-child {
        border-radius: 0.8rem 0 0 0.8rem;
      }

      .table__col:last-child {
        border-radius: 0 0.8rem 0.8rem 0;
      }
    }

    .table__row + .table__row {
      .table__col:first-child {
        border-radius: 0.8rem 0 0 0.8rem;
      }

      .table__col:last-child {
        border-radius: 0 0.8rem 0.8rem 0;
      }
    }

    .table__col {
      padding: 0.8rem;
      background: $seaz-bg;

      .table__col-inner {
        color: $seaz-text;

        div {
          max-height: 4rem;
          line-height: 2rem;
          overflow: hidden;
          white-space: nowrap;
          text-overflow: ellipsis;
        }

        div:last-child {
          font-size: 1.2rem;
          color: $seaz-about-text;
        }
      }
    }

    .selected-table-row:nth-child(n) {
      .table__col {
        border-top: 0.1rem solid $seaz-head-arrow;
        &:first-child {
          border-bottom-left-radius: 0;
          border-left: 0.1rem solid $seaz-head-arrow;
        }
        &:last-child {
          border-right: 0.1rem solid $seaz-head-arrow;
          border-bottom-right-radius: 0;
        }
      }
    }

    .active-row {
      .table__col_details {
        border-radius: 0 0 0.8rem 0.8rem;
      }

      app-card-statements-table-details {
        .details {
          background: $clr-bg;
          padding: 0.8rem;
          display: flex;
          flex-direction: column;
          border-radius: 0 0 0.8rem 0.8rem;
          border: 0.1rem solid $seaz-head-arrow;
          border-top: unset;

          .details__col {
            flex-basis: 25%;
            padding: 0;
            margin-bottom: 2.4rem;
          }

          .details__col-description {
            flex-basis: 100%;
          }

          .details__title {
            font-style: normal;
            font-weight: 500;
            font-size: 2rem;
            line-height: 120%;
            color: $clr-primary-and-accent;
          }

          .details__head {
            font-style: normal;
            font-weight: normal;
            font-size: 1.2rem;
            line-height: 120%;
            display: flex;
            align-items: center;
            color: $seaz-document-count;
            margin-bottom: 1.2rem;
          }

          .details__text {
            font-style: normal;
            font-weight: normal;
            font-size: 14px;
            line-height: 20px;
            display: flex;
            align-items: center;
            color: $seaz-text;
          }

          .details__sub-text {
            font-size: 1.2rem;
            color: $seaz-about-text;
            margin-top: 0.2rem;
          }
        }
      }
    }

    .table__col_details {
      padding: 0;
    }

    .financial-data__status .financial-data__col_btns {
      border-left: unset;
    }

    .financial-data__btns .table__col-btns-file {
      border-left: unset;
    }
  }

  &__description-tooltip,
  &__warning-tooltip {
    box-shadow: 0 0 12px rgb(53 31 115 / 22%);

    .tooltip {
      padding: 1.2rem;
      border-radius: 4px;
      position: relative;
      display: inline-block;
      box-sizing: content-box;
    }
  }

  &__description-tooltip {
    .tooltip__text {
      color: $seaz-text;
      letter-spacing: 0.01em;
    }
  }
  &__warning-tooltip {
    .tooltip__text {
      color: $clr-light-grey;
    }
  }

  .title-h1 {
    text-transform: uppercase;
  }

  .buttons-row {
    display: flex;
    flex-shrink: 0;
    @include tablet {
      flex-direction: column;
    }
  }

  .table_financial .financial-data__table_row .financial-data__table--method app-seazone-form-field,
  .table_financial .financial-data__table_row .financial-data__table--status app-seazone-form-field,
  .table_financial .financial-data__table_row .financial-data__table--receipts app-seazone-form-field {
    max-width: 100%;
  }

  @media screen and (max-width: 1605px) {
    .financial-data__table_small app-seazone-select {
      width: 100%;
    }
  }

  .credit-card-tooltip {
    position: absolute;
    right: 3.8rem;

    @media screen and (min-width: 769px) {
      display: none;
    }
  }
}

.credit-card-tooltip-warning.top .tooltip:before {
  transform: translateX(183%);

  @media screen and (max-width: 480px) {
    transform: translateX(272%);
  }
}

.balance-card {
  min-width: 9.13rem;

  &__content {
    display: flex;
    flex-direction: column;
    gap: 0.4rem;
  }

  &__currency,
  &__value {
    display: block;
    text-align: left;
  }

  &__currency {
    font-size: 1.2rem;
    line-height: 1.4rem;
    font-weight: normal;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    width: 7.53rem;
  }

  &__value {
    font-size: 1.4rem;
    line-height: 1.4rem;
    font-weight: 500;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    width: 7.53rem;
  }

  @include tablet {
    min-width: 100%;
  }
}

.card-statistics {
  .text-bold {
    font-weight: bold;
  }

  .text-left {
    color: $seaz-about-text;
  }

  .amount {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    white-space: break-spaces;
  }

  .dashboard__aside {
    width: 37.8rem;

    @include laptop {
      width: 100%;
    }

    .total {
      font-size: 2.4rem;
      line-height: 2.8rem;
      color: $seaz-text;
      padding: 0 1.5rem 0 0.5rem;
    }
  }

  .positive {
    color: #3f9795;
  }

  .negative {
    color: $seaz-mulberry;

    .seaz-charter__list_payment_main_list_icon {
      transform: rotateZ(90deg);
    }
  }

  .total.negative {
    color: #d70000;
  }
}

.card-chart {
  width: 100%;

  .card-dashboard {
    height: 100%;
  }

  .seaz-apa-report-dashboard-title {
    background-color: unset;
    margin-bottom: 0.3rem;
    padding: unset;
  }
}

.add-edit-card-modal.modal-small {
  @include tablet {
    padding: 0;
    justify-content: space-between;
    margin-bottom: 1.6rem;
  }

  .modal-close .icon-close::before {
    color: $clr-green;
  }

  .title {
    font-style: normal;
    font-weight: 500;
    font-size: 2.4rem;
    line-height: 116%;
    color: $clr-green;
  }

  .modal-form {
    .card-name {
      margin-top: 3rem;

      @include tablet {
        margin-top: 1.5rem;
      }

      app-seazone-input app-seazone-form-field .form-field--wrapper {
        @include tablet {
          flex-direction: column;

          .form-field--content {
            margin-top: 0.5rem;
          }
        }

        .form-field--label {
          margin-right: 3.5rem;

          @include tablet {
            margin-right: unset;
          }

          span {
            font-style: normal;
            font-weight: normal;
            font-size: 1.4rem;
            line-height: 2rem;
            color: $seaz-text;
          }
        }
      }
    }

    .card-label {
      .gold {
        font-style: normal;
        font-weight: 550;
        font-size: 1.4rem;
        line-height: 2rem;
        color: $waiting-dark;
        padding: 0.8rem;
        margin-top: 3rem;

        @include tablet {
          margin-top: 1.5rem;
        }
      }
    }

    .card-currency {
      margin-top: 3rem;

      @include tablet {
        margin-top: 1.5rem;
      }

      app-seazone-select app-seazone-form-field .form-field--wrapper {
        @include tablet {
          flex-direction: column;

          .form-field--content {
            margin-top: 0.5rem;
          }
        }

        .form-field--label {
          margin-right: 3.5rem;

          @include tablet {
            margin-right: unset;
          }

          span {
            font-style: normal;
            font-weight: normal;
            font-size: 1.4rem;
            line-height: 2rem;
            color: $seaz-text;
          }
        }
      }
    }

    .card-type {
      margin-top: 3rem;

      @include tablet {
        margin-top: 1.5rem;
      }

      @include tablet {
        flex-direction: column;
      }

      .tittle {
        width: 100%;
        max-width: 14rem;
        display: flex;
        margin-right: 3.5rem;

        @include tablet {
          margin-right: unset;
          margin-bottom: 1rem;
        }
      }

      .type {
        .expense-split__radio_label:first-child {
          margin-bottom: 2rem;
        }

        .expense-split__radio_label {
          margin-right: 0;
          text-transform: unset;

          .expense-split__radio_inner {
            border-color: $button-main;

            &:checked {
              border-color: $button-main;
            }

            &::before {
              background-color: $button-main;
            }
          }
        }
      }
    }

    .card-limit {
      margin-top: 3rem;

      @include tablet {
        margin-top: 1.5rem;
      }

      app-seazone-input app-seazone-form-field .form-field--wrapper {
        @include tablet {
          flex-direction: column;

          .form-field--content {
            margin-top: 0.5rem;
          }
        }

        .form-field--label {
          margin-right: 3.5rem;

          @include tablet {
            margin-right: unset;
          }

          span {
            font-style: normal;
            font-weight: normal;
            font-size: 1.4rem;
            line-height: 2rem;
            color: $seaz-text;
          }
        }
      }
    }

    .card-balance-day {
      margin-top: 3rem;

      @include tablet {
        margin-top: 1.5rem;
      }

      app-seazone-select app-seazone-form-field .form-field--wrapper {
        @include tablet {
          flex-direction: column;
        }

        .form-field--content {
          width: 10.4rem;

          @include tablet {
            margin-top: 0.5rem;
            width: unset;
          }
        }

        .form-field--label {
          margin-right: 3.5rem;

          @include tablet {
            max-width: 100%;
            margin-right: unset;
          }

          span {
            font-style: normal;
            font-weight: normal;
            font-size: 1.4rem;
            line-height: 2rem;
            color: $seaz-text;
          }
        }
      }
    }

    .card-lower {
      margin-top: 3rem;

      @include tablet {
        margin-top: 1.5rem;
      }

      app-seazone-input app-seazone-form-field .form-field--wrapper {
        @include tablet {
          flex-direction: column;

          .form-field--content {
            margin-top: 0.5rem;
          }
        }

        .form-field--label {
          margin-right: 3.5rem;

          @include tablet {
            margin-right: unset;
          }

          span {
            font-style: normal;
            font-weight: normal;
            font-size: 1.4rem;
            line-height: 2rem;
            color: $seaz-text;
          }
        }
      }
    }

    .card-balance {
      margin-top: 3rem;

      @include tablet {
        margin-top: 1.5rem;
      }

      app-seazone-input app-seazone-form-field .form-field--wrapper {
        @include tablet {
          flex-direction: column;

          .form-field--content {
            margin-top: 0.5rem;
          }
        }

        .form-field--label {
          margin-right: 3.5rem;

          @include tablet {
            margin-right: unset;
          }

          span {
            font-style: normal;
            font-weight: normal;
            font-size: 1.4rem;
            line-height: 2rem;
            color: $seaz-text;
          }
        }
      }

      .hint {
        font-style: normal;
        font-weight: normal;
        font-size: 1.2rem;
        line-height: 120%;
        color: $seaz-document-count;
        margin: 0.8rem 0 0 17.6rem;

        @include tablet {
          margin-left: unset;
        }
      }
    }

    .row-line {
      margin-top: 3rem;
      border: 0.1rem solid $seaz-head-arrow;

      @include tablet {
        margin-top: 1.5rem;
      }
    }

    .card-warning {
      margin-top: 3rem;
      font-size: 1.4rem;
      color: $seaz-text;
    }
    .card-btns {
      margin-top: 3rem;
      display: flex;
      justify-content: flex-end;

      @include tablet {
        margin-top: 1.5rem;
        justify-content: center;
        flex-direction: column;
        padding-bottom: 2rem;
      }

      &.card-btns-checkbox {
        justify-content: space-between;
      }

      .checkbox {
        display: flex;
        justify-content: center;
        align-items: center;

        @include tablet {
          justify-content: unset;
          margin-bottom: 1.2rem;
        }

        span {
          font-style: normal;
          font-weight: normal;
          font-size: 1.4rem;
          line-height: 2rem;
          color: $seaz-text;
          margin-left: 0.8rem;
        }
      }

      .btns {
        display: flex;

        button {
          font-style: normal;
          font-weight: 500;
          font-size: 1.4rem;
          line-height: 100%;
          display: flex;
          align-items: center;
          text-align: center;
          letter-spacing: 0.1em;
          text-transform: uppercase;
          color: $clr-green;
          padding: 1.8rem 3.2rem;
          border: 0.1rem solid $clr-green;
          margin-left: 1.2rem;

          @include tablet {
            margin-left: unset;
          }
        }

        .cancel {
          @include tablet {
            width: 100%;
            display: flex;
            justify-content: center;
            padding: 1.8rem 0;
          }

          &:hover {
            background-color: #f6fbfa;
            color: #55b5b3;
            border-color: #55b5b3;
          }

          &:active {
            background-color: #deefef;
            color: #3f9795;
            border-color: #3f9795;
          }
        }

        .add {
          color: white;
          background-color: $clr-green;

          &:hover {
            background-color: #55b5b3;
            border-color: #55b5b3;
          }

          &:active {
            background-color: #3f9795;
            border-color: #3f9795;
          }

          &.disabled {
            border-color: gray;
            background-color: gray;
            opacity: 0.3;
          }

          @include tablet {
            width: 100%;
            display: flex;
            justify-content: center;
            padding: 1.8rem 0;
            margin-left: 1.2rem;
          }
        }
      }
    }
  }
}

.details__col.seaz-card-statements-details__description {
  flex-basis: 100%;
}

.add-edit-card-modal.modal-small .modal-form.seaz-card-edit-form {
  .hint {
    margin: 1.5rem 0 0 17.6rem;

    @include tablet {
      margin: 1.5rem 0 0 0;
    }
  }
}

.seaz-card-statements-table-filter .select-placeholder {
  font-size: 1.4rem;
}

.table .seaz-card-statements-table-filter .seazone__form-input {
  font-size: 1.4rem;

  &::placeholder {
    font-size: 1.4rem;
  }
}

.bank-account-statements__navigation_list {
  display: flex;
}

.bank-account-statements-card {
  @include card-block();
  display: flex;
  justify-content: space-between;
  padding: 1.6rem;
  width: 22.5rem;
  color: var(--text-color);
  transition:
    background-color 0.3s ease,
    color 0.3s ease;

  &.active {
    background-color: var(--background-color);
  }
  &.normal {
    --background-color: #05a1e8;
    --text-color: #05a1e8;
    &.active {
      --text-color: #fff;
    }
  }
  &.error {
    --background-color: #f5dadb;
    --text-color: #d70000;
  }
  &.warning {
    --background-color: #fdefc8;
    --text-color: #94792b;
  }
  &__content {
    display: flex;
    flex-direction: column;
    overflow: hidden;
    &-name,
    &-value {
      font-size: 1.4rem;
      line-height: normal;
      font-weight: 700;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      width: 100%;
    }
    &-name {
      margin-bottom: 0.8rem;
      opacity: 0.6;
    }
    &-value {
      font-size: 2.4rem;
    }
  }
}

.bank-account-statements-section {
  margin-bottom: 4rem;
  display: flex;
  @include tablet {
    margin-bottom: 3.2rem;
  }
  &__header {
    justify-content: space-between;
    @include laptop {
      flex-direction: column;
    }
  }
  &__actions {
    justify-content: flex-end;
    @include tablet {
      &-item {
        width: 100%;
      }
    }
  }
  &__content {
    flex-direction: column;
  }

  &__empty {
    justify-content: center;
    text-align: center;
    padding: 3rem 0;
    margin-bottom: 4rem;
    font-size: 2rem;
    line-height: 2.4rem;
    font-weight: 500;
    color: $clr-light-grey;
  }
}
