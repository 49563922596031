.boat-tasks-list-wrapper {
  @include d-flex-space-between;
  align-items: flex-start;
}

.boat-tasks-list-container {
  width: 100%;
}

.boat-tasks-list {
  display: flex;
  align-content: stretch;
  width: 100%;

  @include desktop {
    overflow-x: auto;
    padding-bottom: 1rem;
  }

  @include tablet-m {
    flex-direction: column;
    overflow-x: visible;
    padding-bottom: 0;
    margin-top: 1.7rem;
  }
}

.boat-tasks-list-item {
  width: 33.3%;
  max-width: 46rem;
  padding: 2.6rem 2.4rem;
  background-color: $seaz-bg;
  border-radius: 1.2rem;
  order: 1;
  @include indent-not-last-of-child(0 3.2rem 0 0);

  .no-data-text {
    font-size: 2rem;
    font-weight: 500;
    line-height: 120%;
    text-align: center;
    color: $clr-grey;
    margin-bottom: 1.2rem;
  }

  @include extra-large {
    @include indent-not-last-of-child(0 2rem 0 0);
  }

  @include desktop {
    width: 42rem;
    min-width: 42rem;
  }

  @include tablet-m {
    width: 100%;
    min-width: initial;
    max-width: initial;
    background-color: initial;
    padding: 1.4rem 1.2rem;
  }

  &.inbox {
    order: 0;
    background-color: $m-orange;
  }
}

.inbox {
  .no-data-text {
    color: #ffae6a;
  }
}

.boat-task-list-add-btn,
.boat-task-list-show-btn {
  width: 100%;
  padding: 0.8rem 2rem;
}

.boat-task-list-show-btn {
  margin-top: 1.2rem;
}

.boat-tasks-list-item-date {
  @include d-flex-space-between;
  align-items: center;
  margin-bottom: 1.4rem;
  color: $seaz-text;

  &__title {
    font-size: 2rem;
    font-weight: 500;
    line-height: 120%;
    margin-right: 1rem;
    text-transform: capitalize;
  }

  &__count {
    font-size: 1.6rem;
    font-weight: 700;
    line-height: 120%;
    color: $clr-primary-and-accent;

    &--total {
      color: $seaz-document-count;
    }
  }
}

.boat-tasks-list-item-no-tasks {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 1.2rem;
  &__icon {
    margin-right: 1.2rem;
  }
}

.boat-tasks-list-selected {
  @include d-align-items-center;
  margin: 2.6rem 0;

  &__date {
    font-size: 2.4rem;
    font-weight: 500;
    line-height: 115%;
    text-transform: uppercase;
    color: $seaz-text;
    margin-right: 2.6rem;
  }

  &__btn {
    padding: 1rem;
    border-radius: 50%;
    transition: background-color 0.3s;

    &--next {
      margin-right: 1.8rem;
      transform: rotateZ(180deg);
    }

    &:hover {
      background-color: $seaz-bg;
    }
  }

  @include tablet-m {
    display: none;
  }
}
