.invite-user {
  @include tablet {
    .modal__wrap {
      padding: 0.6rem;
    }
  }
}

.fleet-manager-modal {
  &__form {
    margin-top: 3rem;
  }

  @media screen and (max-width: 650px) {
    .form-field--wrapper__row .form-field--label {
      max-width: 12rem;
    }
  }
}

.boat-fleet-users-permission-container {
  position: relative;
  margin-top: 1.6rem;
}

.boat-fleet-users-permission {
  width: 48%;
  max-height: 28rem;
  overflow-y: auto;
  @include custom-scroll-bar;

  @include tablet {
    width: 50%;
  }

  @include mobile {
    max-height: 23rem;
  }
}

app-boat-fleet-users-permission-item {
  display: block;
}

.fleet-users-permission {
  width: 100%;

  .form__checkbox {
    width: 1.8rem;
  }
}

.fleet-users-permission-title {
  @include d-flex-space-between;
  align-items: center;
  padding: 1.6rem;
  margin-right: 3.2rem;
  cursor: pointer;
  border-radius: 0.4rem;
  transition: background-color 0.3s;

  &:hover {
    background-color: $seaz-bg;
  }

  &.active {
    outline: 2px solid $clr-primary-and-accent;
    outline-offset: -2px;
  }

  &__checkbox {
    margin-right: 1rem;
  }

  @include tablet {
    padding: 1rem;
    margin-right: 1rem;
  }
}

.fleet-users-permission-group {
  position: absolute;
  top: 0;
  right: 0;
  width: 45%;
  max-height: 28rem;
  border-left: 1px solid $seaz-head-arrow;
  padding: 0 1rem 0 1rem;
  overflow-y: auto;
  overflow-x: hidden;
  @include custom-scroll-bar;

  &__item {
    justify-content: space-between;
    align-items: center;
    padding: 1.6rem;
    border-radius: 0.4rem;
    cursor: pointer;
    transition: background-color 0.3s;

    &:hover {
      background-color: $seaz-bg;
    }
  }

  &__checkbox {
    margin-left: 1rem;
  }

  @include tablet {
    padding: 0 1rem 0 0;

    &__item {
      padding: 1rem;
    }
  }

  @include mobile {
    max-height: 23rem;
  }
}

.fleet-info-modal {
  margin-top: 3rem;

  @include mobile {
    .info-card {
      padding: 1rem;
    }

    .info-card-alert__content {
      margin-left: 1rem;
    }

    .info-card-alert .shevron-icon {
      margin-right: 0;
    }

    .fleet-job-offers-logo__text {
      font-size: 1.4rem;
      margin-bottom: 0;
    }

    .info-card__count {
      font-size: 1.4rem;
      margin-left: 1rem;
      flex-shrink: 0;
    }
  }
}

.plug-label-fleet-modal {
  @include mobile {
    margin: 0 0 1.8rem 0;
  }
}

.fleet-modal-footer {
  @include d-flex-space-between;
  align-items: center;
  padding-bottom: 2rem;
  margin-top: 3rem;

  &__group-btn {
    @include d-align-items-center;
    justify-content: flex-end;
    width: 100%;
  }

  &__btn {
    @include indent-not-last-of-type(0 1.2rem 0 0);
  }

  @include mobile {
    &__group-btn {
      margin-left: 1rem;
    }

    &__btn {
      @include indent-not-last-of-type(0 1rem 0 0);
    }

    .seaz__btn_rounded {
      padding: 1rem 1.8rem;
    }
  }
}

.fleet-manager-modal-footer {
  @include footer-with-border-left-btn(0, 3rem 0 0);
  border: none;

  &__btn {
    @include indent-not-last-of-type(0 1.2rem 0 0);
  }
}

.fleet-manager-modal-footer-users-permissions {
  @include d-flex-space-between;
  align-items: center;
  padding-bottom: 1rem;
  margin-top: 2rem;
  border: none;

  &__group {
    @include d-align-items-center;
    justify-content: flex-end;
    width: 100%;
  }

  &__btn {
    @include indent-not-last-of-type(0 1.2rem 0 0);
  }

  &__btn-remove {
    min-width: 23.5rem;
  }

  @include mobile {
    &__btn-remove {
      width: 14rem;
      min-width: auto;
      height: 4rem;
      overflow: hidden;
      margin-right: 1.2rem;
    }

    &__btn-text {
      display: block;
      white-space: nowrap;
      overflow: hidden;
    }
  }
}

.fleet-info-modal-footer {
  @include footer-with-border-left-btn(0 0 2rem 0, 3rem 0 0);
  border: none;

  &__btn {
    @include indent-not-last-of-type(0 1.2rem 0 0);
  }
}

.fleet-contacts-header-modal {
  @include d-align-items-center;

  &__btn {
    margin-left: 1.6rem;
  }
}

.fleet-contacts-content-modal {
  margin-top: 3rem;
}

.fleet-contacts-phone-modal {
  display: flex;
  align-items: flex-start;

  &__content {
    @include d-align-items-center;
    font-size: 16px;
    line-height: 19px;
    color: $seaz-text;
    margin-bottom: 0.8rem;
  }

  &__text {
    margin-left: 1.6rem;
  }

  &__icon {
    margin-left: 1rem;
  }
}

.contract-fleets-container {
  @include d-align-items-center;
  flex-wrap: wrap;
  margin-top: 3rem;
}

.contract-fleets-chips {
  @include d-align-items-center;
  max-width: 20.7rem;
  background-color: #f5f5f5;
  border-radius: 4.1rem;
  padding: 0.4rem;
  margin: 0 0.8rem 1.2rem 0;

  &__img {
    display: block;
    margin-right: 0.8rem;
    border-radius: 50%;
  }

  &__text {
    @include cut-text-add-dots;
    font-size: 1.4rem;
    line-height: 1.6rem;
    color: $seaz-text;
  }
}

.add-to-charter-modal {
  padding: 2rem;
}

.add-to-charter-form {
  margin-top: 3rem;

  &__col {
    @include indent-not-last-of-type(0 0 2.8rem);
  }

  @media screen and (max-width: 650px) {
    .form-field--wrapper__row .form-field--label {
      max-width: 14rem;
    }
  }

  @include mobile {
    .form-field--wrapper__row .form-field--label {
      max-width: 100%;
    }
  }
}

.add-to-charter-form-footer {
  @include footer-with-border-left-btn(0, 4.6rem 0 0);
  border: none;
}

.fleet-charter-filter-modal {
  .app-charter-filter-form__select,
  .app-charter-filter-form__location {
    width: 100%;
    margin-right: 0;
  }

  &__checkbox-wrap {
    @include d-align-items-center;

    .app-charter-filter-form__checkbox .form__checkbox-label {
      width: 1.8rem;
      height: 1.8rem;
    }

    .form__checkbox-label:before {
      width: 1.8rem !important;
      height: 1.8rem !important;
      border-radius: 0.2rem !important;
    }

    .app-charter-filter-form__checkbox .form__checkbox-label:before {
      font-size: 1rem;
    }
  }

  .seaz-crew-search-all-filter__actions {
    @include border-top;
    padding-top: 1.6rem;
  }
}
