.notes-list-container {
  max-height: 63.4rem;
  overflow-y: auto;
  @include custom-scroll-bar;
  padding-right: 1rem;

  @include large-md-screen {
    max-height: 40rem;
  }

  @include tablet-md {
    max-height: 63.4rem;
  }
}

.fleet-notes-list {
  &__header {
    @include d-flex-space-between;
    align-items: center;
    margin-bottom: 2rem;
  }

  .fleet-info-modal-search {
    margin-bottom: 1.5rem;
  }
}

.notes-list {
  @include box-grid(9.4rem 1fr 1fr 6rem, initial, 'date info note actions', 0 1.6rem);
  padding: 0.8rem;

  &__date {
    grid-area: date;
  }

  &__info {
    grid-area: info;
  }

  &__note {
    grid-area: note;
    word-break: break-word;
  }

  &__actions {
    grid-area: actions;
  }

  @include large-md-screen {
    @include box-grid(9.4rem 12rem 1fr 6rem, initial, 'date info note actions', 0 1.6rem);
  }

  @include tablet-md {
    @include box-grid(1fr, initial, 'date' 'info' 'note' 'actions', 1.6rem 0);
  }
}

.notes-list-header {
  margin-bottom: 0.7rem;

  &__title {
    font-size: $base-font-size;
    line-height: 1.6rem;
    color: $profile-month;
    vertical-align: middle;
  }

  @include tablet-md {
    display: none;
  }
}

.notes-list-body {
  border-radius: 1.2rem;

  &:nth-of-type(2n) {
    background-color: $clr-ashy-grey;
  }

  &:not(:last-of-type) {
    margin-bottom: 2rem;
  }

  &__text {
    font-size: $base-font-size;
    line-height: 1.6rem;
    color: $seaz-text;

    &--min {
      font-size: 1.2rem;
      line-height: 120%;
      color: $seaz-about-text;
      margin-top: 0.2rem;
    }
  }

  &__actions {
    @include d-flex-space-between;
    align-items: flex-start;
  }
}
