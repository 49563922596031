.calendar-event-select {
  font-family: $base-font;
  position: relative;

  &__btn {
    display: flex;
    align-items: center;

    &--icon {
      margin-right: 1.1rem;
    }

    &--events {
      min-width: 18rem;
    }
  }

  &__dropdown {
    width: 29.2rem;
    font-family: $base-font;
    line-height: 1.4;
    color: $seaz-text;
    background-color: $light-color;
    box-shadow: 0 16px 32px rgba(43, 28, 54, 0.12);
    border-radius: 1.2rem;
    padding: 2.4rem;
    overflow-y: auto;

    &--title {
      display: flex;
      align-items: center;
      font-size: 1.6rem;
      font-weight: 500;
    }

    &--item {
      display: flex;
      align-items: center;
      margin-top: 2.2rem;
      font-size: 1.4rem;
    }

    &--checkbox {
      margin-right: 1.1rem;

      .form__checkbox {
        width: 1.8rem;
      }
    }

    .owner-charter {
      .form__checkbox-label:before {
        border-color: $dark-green;
      }

      .form__checkbox-input:checked + label:before {
        background-color: $dark-green;
      }
    }

    .trip {
      .form__checkbox-label:before {
        border-color: $seaz-edit-hover;
      }

      .form__checkbox-input:checked + label:before {
        background-color: $seaz-edit-hover;
      }
    }

    .all-inclusive {
      .form__checkbox-label:before {
        border-color: $clr-blue;
      }

      .form__checkbox-input:checked + label:before {
        background-color: $clr-blue;
      }
    }

    .delivery {
      .form__checkbox-label:before {
        border-color: $seaz-delivery;
      }

      .form__checkbox-input:checked + label:before {
        background-color: $seaz-delivery;
      }
    }

    .shipyard {
      .form__checkbox-label:before {
        border-color: $seaz-warning;
      }

      .form__checkbox-input:checked + label:before {
        background-color: $seaz-warning;
      }
    }

    .custom {
      .form__checkbox-label:before {
        border-color: $seaz-custom;
      }

      .form__checkbox-input:checked + label:before {
        background-color: $seaz-custom;
      }
    }
  }

  &__group_checkbox {
    margin-right: 1.1rem;
  }

  &__item-create-event {
    margin-top: 0.8rem;

    &--btn {
      display: block;
      width: 100%;
    }

    .trip {
      background-color: $seaz-edit-hover;
      border-color: $seaz-edit-hover;
    }

    .delivery {
      background-color: $seaz-delivery;
      border-color: $seaz-delivery;
    }

    .shipyard {
      background-color: $seaz-warning;
      border-color: $seaz-warning;
    }

    .custom {
      background-color: $seaz-custom;
      border-color: $seaz-custom;
    }
  }

  @include tablet {
    width: 100%;

    &__btn {
      width: 100%;
      justify-content: center;
    }

    &__dropdown {
      width: 100%;
    }
  }
}
