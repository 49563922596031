.seaz-dashboard-wrap {
  width: 100%;
  max-width: 120.7rem;
  padding: 4.8rem 1.6rem;
  margin: 0 auto;

  @include tablet {
    padding: 1.6rem 1.6rem 4rem;
  }
}

.seaz-dashboard-info {
  @include d-flex-space-between;

  &__item {
    display: block;
    width: 33.3%;
    border: 0.5px solid #e7e7eb;
    box-sizing: border-box;
    background-color: $light-color;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.04);
    border-radius: 1.6rem;
    padding: 2.4rem;

    &:not(:last-child) {
      margin-right: 2.5rem;
    }
  }

  @include desktop {
    &__item {
      width: 100%;

      &:not(:last-child) {
        margin-right: 1rem;
      }
    }
  }

  @include laptop {
    flex-direction: column;

    &__item {
      &:not(:last-child) {
        margin: 0 0 1rem;
      }
    }
  }

  @include mobile {
    padding: 0;
  }
}

.seaz-dashboard-status {
  &__header {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;

    .seaz-cv__badges_status_job {
      border-radius: 2.4rem;
      padding: 0.4rem 1.2rem;
      font-size: 1.2rem;
      min-width: initial;
      min-height: initial;
    }

    .seaz-cv__badges_status_update {
      display: block;
      text-align: center;
      padding-top: 0.4rem;
    }
  }

  &__btn {
    cursor: pointer;
  }

  &__title {
    text-transform: uppercase;
    color: $seaz-primary-and-accent;
    line-height: 3rem;
  }

  &__person {
    display: flex;
    align-items: center;
    margin-top: 1.6rem;
  }

  &__avatar {
    flex-shrink: 0;
    position: relative;
    width: 10.2rem;
    height: 10.2rem;
    border-radius: 50%;

    .play-icon {
      display: none;
    }

    &.outlined {
      border: 0.4rem solid $clr-primary-and-accent;
      cursor: pointer;
      .play-icon {
        display: block;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        opacity: 0;
        transition: opacity 0.3s;
      }

      &:before {
        content: '';
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        width: 75%;
        height: 75%;
        background: linear-gradient(0deg, rgba(53, 31, 115, 0.56), rgba(53, 31, 115, 0.56));
        border-radius: 50%;
        padding: 0.6rem;
        opacity: 0;
        transition: opacity 0.3s;
      }

      &:hover {
        &:before,
        .play-icon {
          opacity: 1;
        }
      }

      .seaz-dashboard-status__avatar-img {
        border: 1.2rem solid $light-color;
      }
    }
  }

  &__avatar-img {
    display: block;
    width: 100%;
    height: 100%;
    border-radius: 50%;
  }

  @include mobile {
    &__header {
      .seaz-cv__badges_status_job.looking-job {
        width: 16rem;
      }
    }
  }
  &__card-link {
    margin-top: 1.6rem;
  }
}

.seaz-dashboard-person-info {
  margin-left: 1.6rem;

  &__name {
    word-break: break-word;
  }

  &__role {
    font-size: 1.6rem;
    font-weight: 500;
    line-height: 1.9rem;
    color: $seaz-text;
    margin-bottom: 0.8rem;
  }

  .seaz-profile__info_line,
  .seaz-profile__info_line_progress {
    border-radius: 1rem;
  }

  .crew-id-box {
    align-items: center;
    margin-top: 0.8rem;
    margin-bottom: 0.8rem;
    @include tablet {
      flex-direction: row;
    }
  }

  .seaz-profile__info_completion {
    justify-content: flex-start;
    margin-top: 1.2rem;
  }
}

.hint-status {
  @include d-flex-space-between;
  align-items: center;
  padding: 1.2rem;
  background-color: rgba(53, 31, 115, 0.08);
  font-size: 1.6rem;
  line-height: 1.9rem;
  color: $clr-primary-and-accent;
  border-radius: 0.4rem;
  margin-top: 1.6rem;

  &:hover {
    app-seazone-icon {
      transform: rotateZ(180deg) translateX(-0.5rem);
    }
  }
}

.icon-arrow {
  margin-right: 1.2rem;
}

.shevron-icon {
  transform: rotateZ(180deg);
  transition: transform 0.3s;
}

.value-icon {
  margin-left: 1.7rem;
}

.dashboard-card {
  &__title {
    text-transform: uppercase;
    color: $clr-primary-and-accent;
  }

  &__link {
    display: block;
    padding: 1.6rem;
    background-color: $seaz-bg;
    border-radius: 1.6rem;
    margin-top: 1.6rem;

    &:hover {
      .shevron-icon {
        transform: rotateZ(180deg) translateX(-0.5rem);
      }
    }
  }

  &__box {
    display: flex;
    align-items: center;
  }

  .warning {
    @include d-align-items-center;
    margin-top: 1.2rem;
    font-size: 1.4rem;
    line-height: 1.6rem;
    color: $seaz-warning;

    app-seazone-icon {
      margin-right: 0.5rem;
    }
  }
}

.dashboard-card-content {
  @include d-flex-space-between;
  align-items: center;
  width: 100%;

  &__title {
    font-size: 2rem;
    line-height: 120%;
    color: $seaz-text;
  }

  &__values {
    @include d-align-items-center;

    .seaz-dashboard__offers_head_new--green {
      font-size: 2rem;
    }
  }

  &__text {
    font-size: 2rem;
    color: $seaz-text;
    margin-right: 0.8rem;
  }

  .document-expiring-soon {
    color: $seaz-warning;
  }

  .document-expired {
    color: $seaz-alarm;
  }

  .document-all {
    color: $seaz-text;
  }
}
