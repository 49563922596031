.boat-tasks-filters-form {
  padding: 3rem;

  .seaz-filters__head_filters {
    padding: 2.4rem 0 3rem 0;
    border-bottom: none;
  }

  @include tablet {
    padding: 1.6rem;

    .seaz-filters__head_filters {
      padding: 2.4rem 0 6rem 0;
    }
  }
}

.boat-tasks-filter-form {
  margin-top: 3rem;

  &__row {
    align-items: center;
  }

  .form-field--wrapper__row .form-field--label {
    max-width: 16rem;
  }

  .plug-label {
    width: 16rem;
    min-width: 16rem;
  }

  @include tablet {
    .form-field--wrapper__row .form-field--label {
      max-width: 10rem;
    }

    .plug-label {
      width: 10rem;
      min-width: 10rem;
    }
  }

  @include mobile {
    &__row {
      align-items: flex-start;
    }
  }
}

.boat-maintenance-statistic-filter-action__assignee {
  .boat-tasks-filter-form-select-option,
  .select-option--all {
    app-seazone-checkbox {
      width: 100%;
    }

    .form__checkbox-label-text {
      display: block;
      width: 100%;
      order: -1;
      padding: 0 1.3rem 0 0;
    }

    @include mobile {
      .form__checkbox-label-text {
        padding: 0 1rem 0 0;
      }
    }
  }

  .boat-tasks-filter-form-selected {
    @include d-flex-space-between;
    align-items: center;

    &__content {
      @include d-align-items-center;
      margin-right: 1rem;
    }

    &__text {
      font-size: 1.3rem;
      line-height: 1.6rem;
      margin-left: 1rem;
    }

    app-role-position-name {
      font-size: $base-font-size;
      line-height: 1.6rem;
      color: $profile-month;
      text-align: right;
    }
  }

  .boat-tasks-filter-form-selected-img {
    display: block;
    border-radius: 50%;
  }

  .boat-tasks-filter-form-select-group {
    .select-group__title {
      display: block;
      font-family: $base-font;
      font-size: 1.6rem;
      line-height: 1.6rem;
      padding: 1.6rem 0 1.2rem 2.4rem;
    }
  }
}
