.view-rate-modal {
  .ratings-person {
    margin-top: 2.4rem;
    padding-bottom: 2.4rem;
    border-bottom: 1px solid $seaz-head-arrow;
  }

  &__container {
    @include d-align-items-center;
    padding-top: 1.4rem;
  }

  &__content {
    width: 100%;
    max-width: 33.5rem;
  }

  .rate-me-link__box {
    width: 89%;
  }

  &__text {
    margin-top: 1rem;
    word-break: break-word;
  }

  &__footer {
    @include footer-with-border-left-btn(2.4rem 0 0, 2.4rem 0 0);
  }

  @include tablet {
    &__container {
      flex-direction: column;
    }

    &__content {
      width: 100%;
      max-width: 100%;
    }

    .rate-me-link__box {
      width: 60%;
    }
  }
}
