.boat-documents-filter {
  @include d-align-items-center;
  align-items: stretch;

  &__item {
    width: 33.3%;
    border: 0.2rem solid $seaz-head-arrow;
    box-sizing: border-box;
    border-radius: 1.6rem;
    padding: 2.4rem;
    text-align: center;
    cursor: pointer;

    &.active {
      border-color: $clr-primary-and-accent;
    }

    @include indent-not-last-of-type(0 2.4rem 0 0);
  }

  &__title {
    font-size: 2rem;
    font-weight: 500;
    line-height: 120%;
    color: $seaz-document-count;
    margin-bottom: 1.2rem;
  }

  &__content {
    @include d-flex-center-content;
    font-size: 4rem;
    font-weight: 700;
    color: $seaz-text;
  }

  &__icon {
    margin-right: 1.1rem;
  }

  @include laptop {
    &__item {
      padding: 2rem;
    }

    &__title {
      font-size: 1.8rem;
    }

    &__content {
      font-size: 2.4rem;
    }

    &__icon {
      width: 20px;
      height: 20px;
    }
  }

  @include tablet {
    &__item {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      padding: 1.2rem;
      @include indent-not-last-of-type(0 0.8rem 0 0);
    }

    &__title {
      font-size: 1.4rem;
      margin-bottom: 0.4rem;
    }

    &__icon {
      margin-right: 1rem;
    }
  }
}
