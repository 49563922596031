.boat-tasks-menu {
  display: flex;
  flex-direction: column;

  .seaz-filters__head_filters {
    display: flex;
    align-items: flex-start;
    padding: 0;
    border-bottom: none;
    margin-top: 1.6rem;
    min-height: 3.6rem;
  }

  .financial-data__badge_reset {
    flex-shrink: 0;
    margin-right: 1rem;
  }

  .seaz-filters__form_row {
    padding-bottom: 0;
  }

  .fleet-chips {
    margin-top: 0;
  }

  &__toggle-checkbox {
    @include d-align-items-center;
    margin-right: 1.6rem;

    &--text {
      font-size: $base-font-size;
      line-height: 1.6rem;
      color: $seaz-text;
      margin-left: 1rem;
      white-space: nowrap;
    }
  }

  &__search {
    margin-top: 2rem;
  }

  @include laptop {
    &__toggle-checkbox {
      margin-right: 0;

      &:first-child {
        margin-right: 1.2rem;
      }
    }
  }

  @include tablet-m {
    &__search {
      order: -1;
      margin: 1.6rem 0;
    }
  }

  @include mobile {
    &__toggle-checkbox {
      &:first-child {
        margin-right: 1rem;
      }

      &--text {
        margin-left: 0.5rem;
      }
    }
  }
}

.boat-tasks-menu-action {
  display: grid;
  grid-template-columns: auto 1fr auto;
  grid-template-areas: 'filters-group menu-chips action-group';

  .filters-group {
    grid-area: filters-group;
  }

  .menu-chips {
    justify-self: end;
    grid-area: menu-chips;
  }

  .action-group {
    grid-area: action-group;
    justify-self: end;
  }

  .menu-manager-btn {
    &:disabled {
      border-color: initial;
      background-color: rgba(52, 142, 206, 0.4);
    }
  }

  @media screen and (max-width: 1870px) {
    grid-template-columns: auto 1fr;
    grid-template-areas:
      'filters-group action-group'
      'menu-chips menu-chips';
    grid-gap: 1.6rem 1rem;

    .menu-chips {
      justify-self: start;
    }
  }

  @include laptop {
    .menu-manager-btn,
    .menu-add-btn {
      display: none;
    }
  }

  @include mobile {
    grid-gap: 0.6rem 0.8rem;
  }
}

.boat-tasks-menu-action-filters-group {
  @include d-align-items-center;

  .menu-calendar-btn {
    margin-right: 1.6rem;
    display: none;

    @include laptop {
      margin-right: 1.2rem;
    }

    @include mobile {
      margin-right: 1rem;
    }
  }

  @include large {
    .menu-calendar-btn {
      display: block;
    }
  }
}

.boat-tasks-menu-action-filters {
  @include d-align-items-center;

  @include laptop {
    .maintenance-list-filters-actions {
      margin-right: 0;
    }

    .seaz-search-crew-filters__title--icon,
    .fleet-charter-filter-results {
      display: none;
    }
  }
}

.boat-tasks-filters-btn-mob-box {
  display: none;
}

@include laptop {
  .boat-tasks-filters-btn {
    background-color: $clr-ashy-grey;
    padding: 0.7rem 0.6rem;

    &.maintenance-list-filters-actions__btn {
      border: none;
      border-radius: 1.8rem;
      padding: 0.7rem 0.6rem;
    }
  }

  .boat-tasks-filters-btn-mob-box {
    @include d-align-items-center;
    font-size: 1.6rem;
    color: $seaz-about-text;

    &__icon {
      margin-right: 0.8rem;
    }
  }

  .boat-tasks-filters-btn-text {
    display: none;
  }
}

.boat-tasks-menu-action-group {
  @include d-align-items-center;
  margin-left: 1rem;
}

.boat-tasks-menu-action-group-chips {
  @include d-align-items-center;

  .fleet-chips app-chips-checkbox-item {
    margin: 0 0.8rem 0 0;
  }

  @include tablet-m {
    margin-right: 0;
  }

  @include mobile {
    overflow-x: auto;
    padding: 1rem 0;

    .form__group {
      flex-shrink: 0;
    }
  }
}

.boat-tasks-menu-action-group-buttons {
  @include d-align-items-center;

  &__btn {
    text-align: center;
    &:not(:last-child) {
      margin: 0 1.6rem 0 0;
    }
    &--manager {
      background-color: $clr-blue;
      border-color: $clr-blue;

      &:disabled {
        border-color: initial;
        background-color: rgba(52, 142, 206, 0.4);
      }
    }
  }

  @include tablet-m {
    &__btn {
      @include indent-not-last-of-type(0 1.2rem 0 0);
    }
  }
}

.manager-task-button {
  position: relative;
  height: 5.6rem;
  font-family: $base-font;
  font-size: 1.6rem;
}
