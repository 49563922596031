.services-catalog {
  display: grid;
  grid-gap: 2rem;
  grid-template-columns: repeat(2, 1fr);
  grid-auto-rows: 1rem;

  @include tablet {
    grid-template-columns: 1fr;
    grid-gap: 1.6rem;
    grid-auto-rows: auto;
  }
}

.brands-services {
  display: inline-block;
  width: 100%;

  &__info {
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: $light-color;
    border-radius: 1.6rem 1.6rem 0 0;
    box-sizing: border-box;
    padding: 2.4rem 2.6rem;
    border: 2px solid $seaz-head-arrow;
    border-bottom: none;

    &.rounded {
      border-radius: 1.6rem;
      border-bottom: 2px solid $seaz-head-arrow;
    }
  }

  &__info-container {
    display: flex;
    align-items: center;
  }

  &__img-box {
    flex-shrink: 0;
    width: 3.6rem;
    height: 3.6rem;
    margin-right: 2rem;
  }

  &__img {
    display: block;
    width: 100%;
    height: 100%;
    filter: invert(17%) sepia(24%) saturate(4663%) hue-rotate(238deg) brightness(86%) contrast(105%);
  }

  &__description {
    font-size: 2rem;
    font-weight: 500;
    line-height: 1;
    word-break: break-word;
    margin: 0 1rem 0 0;
  }

  &__role,
  &__separator {
    color: $profile-month;
  }

  &__separator {
    margin: 0 1.6rem;
    display: inline-block;
  }

  &__name {
    color: $clr-primary-and-accent;
  }

  &__container {
    min-height: 8.4rem;
    border: 2px solid $seaz-head-arrow;
    border-top: 1px solid $seaz-head-arrow;
    background-color: #fafafb;
    border-radius: 0 0 1.6rem 1.6rem;
    box-sizing: border-box;
    padding: 1.6rem;
    width: 100%;
  }

  &__content {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
  }

  &__text {
    font-size: 2rem;
    font-weight: 500;
    line-height: 1;
    color: $profile-month;
    margin-right: 0.5rem;
  }

  &__btn-action {
    display: flex;
    align-items: center;
    flex-shrink: 0;
    background-color: initial;
    padding: 0.8rem 1.9rem;
  }

  &__btn-action-icon {
    margin-right: 1.1rem;

    svg {
      width: 1.8rem;
      height: 1.8rem;
    }

    &--plus {
      svg {
        width: 1.2rem;
        height: 1.2rem;
      }
    }
  }

  @include laptop {
    &__description,
    &__text {
      font-size: 1.6rem;
    }

    &__container {
      min-height: initial;
    }
  }

  @include tablet {
    &__content {
      flex-direction: column;
      align-items: flex-start;
    }

    &__info {
      padding: 1.6rem;
    }

    &__img-box {
      margin-right: 1rem;
    }

    &__description {
      margin: 0 1rem;
    }

    &__separator {
      margin: 0 1rem;
    }

    &__btn-action {
      width: 100%;
      flex-shrink: 1;
      justify-content: center;
      margin-top: 1.6rem;
    }
  }
}

// TODO: Stas refactor this please

.brand-item {
  display: flex;
  align-items: center;
  min-height: 5.2rem;
  box-sizing: border-box;
  border: 1px solid #e0e0e0;
  border-radius: 5.6rem;
  background-color: #fff;
  padding: 0.8rem 1.6rem 0.8rem 1rem;
  margin-right: 1.2rem;

  &.hide {
    position: absolute;
    width: 0;
    height: 0;
    visibility: hidden;
    z-index: -1;
  }
}

.brands-img-box {
  width: 3.2rem;
  height: 3.2rem;
  margin-right: 0.8rem;
}

.brands-img {
  display: block;
  width: 100%;
  height: auto;
  object-fit: contain;
}

.brand-name {
  font-size: 1.4rem;
  line-height: 1.6rem;
  color: #010202;
}

.brand-certified {
  font-size: 1rem;
  line-height: 1.4rem;
  letter-spacing: 0.05rem;
  text-transform: uppercase;
  color: #3aae27;
}
