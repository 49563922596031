.fleet-calendar-wrapper {
  .seaz-search-crew-filters__search-result-label {
    display: none;
    color: $profile-month;
    margin-top: 1.4rem;
  }

  @include tablet-big {
    .seaz-search-crew-filters__search-result-label {
      display: block;
    }
  }
}

.fleet-calendar-filters-wrapper {
  @include d-flex-space-between;
  align-items: flex-start;
  margin-top: 2rem;

  .seaz-search-crew-filters__title-box .seaz-search-crew-filters__search-result-label {
    display: block;
    margin-top: 0;
  }

  .seaz-search-crew-filters__button-filters {
    color: $seaz-mulberry;
  }

  .button-filters-desktop {
    margin-right: 1.6rem;
  }

  .button-filters-mobile {
    display: none;
  }

  @include tablet-big {
    .seaz-search-crew-filters__title-box .seaz-search-crew-filters__search-result-label {
      display: none;
    }
  }

  @include tablet {
    flex-direction: column;
    margin-top: 0;

    .fleet-calendar-filters {
      margin: 0 0 1rem 0;
    }

    .button-filters-desktop {
      display: none;
    }

    .button-filters-mobile {
      display: block;
      margin-top: 1.4rem;
    }

    .seaz-search-crew-filters__title-box {
      margin-top: 0;
    }

    .seaz-search-crew-filters__toggle-btn {
      margin-top: 2rem;
    }
  }
}

.fleet-calendar-filters-search {
  margin-left: 0;
}

.fleet-calendar-filters {
  width: 50%;
  margin-right: 2.4rem;

  .seaz-search-chips-filter {
    margin-top: 1.6rem;
  }

  @include tablet {
    width: 100%;
    margin: 0 0 3.4rem 0;
  }
}

.fleet-calendar-activity-date-range {
  width: 100%;
  max-width: 30.3rem;

  app-date-range-picker {
    display: block;
    padding-bottom: 2rem;
  }

  input {
    &:focus {
      border: none;
    }
  }
}

.fleet-calendar-activity-label {
  display: block;
  font-size: 1.6rem;
  font-weight: 500;
  line-height: 1.9rem;
  color: $profile-month;
  padding-bottom: 1.6rem;
}

.fleet-search-location {
  width: 50%;

  .fleet-map-form {
    margin-top: 0;
  }

  @include tablet {
    width: 100%;
  }
}

.fleet-calendar {
  margin-top: 5rem;
}

.fleet-calendar-header {
  @include d-align-items-center;
  justify-content: flex-end;

  &__content {
    @include d-align-items-center;
    flex-shrink: 0;
    font-size: 2.4rem;
    font-weight: 500;
    line-height: 116%;
    color: $seaz-text;
  }

  &__btn {
    height: 4rem;
    line-height: 4rem;
    margin-left: 1.6rem;
  }

  @include laptop {
    flex-direction: column;
    justify-content: center;
    align-items: flex-end;

    &__content {
      justify-content: space-between;
      width: 100%;
    }
  }
}

.fleet-small-calendar {
  @include d-align-items-center;
  position: relative;
  width: 100%;
  max-width: 80.2rem;
  margin-left: 4.5rem;

  .charter-calendar-next,
  .charter-calendar-prev {
    margin: 3rem 0 0;
  }

  .charter-calendar-arrow svg {
    stroke: #828282;
  }

  @include laptop {
    flex-direction: column;
    align-items: flex-end;
    width: 100%;
    max-width: 100%;
    margin: 2rem 0 0;
    padding: 1.2rem 0 0;

    .charter-calendar-next,
    .charter-calendar-prev {
      position: absolute;
      top: 0;
      margin: 0;
    }

    .charter-calendar-next {
      right: 0;
    }

    .charter-calendar-prev {
      right: 3rem;
    }
  }
}
