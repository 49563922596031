.boat-maintenance-dashboard-assignee {
  margin-top: 2.4rem;

  &__container {
    margin-top: 1.6rem;
  }
}

.boat-maintenance-dashboard-assignee-chart {
  @include d-align-items-center;
  width: 100%;
  @include indent-not-last-of-type(0 0 1.2rem 0);

  @include tablet {
    flex-direction: column;
    align-items: flex-start;
    @include indent-not-last-of-type(0 0 1.2rem 0);
  }

  &__date {
    flex-shrink: 0;
    color: $profile-month;
    line-height: 1.6rem;
    margin-right: 0.8rem;
  }
}

.boat-maintenance-dashboard-assignee-chart-item {
  @include d-flex-space-between;
  align-items: center;
  width: 50%;
  min-width: 50%;
  padding: 0.75rem 0.8rem;
  border-radius: 0.8rem;
  background-color: $clr-ashy-grey;

  &__unassigned {
    background: linear-gradient(0deg, #fbe7e8, #fbe7e8), #f5dadb;
  }

  .tablet-template,
  .count-template {
    display: none;
  }

  &__row-wrap {
    @include d-align-items-center;
    width: 100%;

    @include laptop {
      width: 66%;
    }

    @include tablet {
      width: 100%;
    }
  }

  &__row-content {
    @include d-flex-space-between;
    align-items: center;
    gap: 0 1rem;
    width: 100%;

    @include laptop {
      flex-direction: column;
      align-items: flex-start;

      .web-template {
        display: none;
      }

      .tablet-template {
        display: block;
      }
    }
  }

  &__row-flex {
    @include d-align-items-center;
  }

  &__img {
    border-radius: 50%;
    margin-right: 0.8rem;
  }

  &__text {
    font-size: $base-font-size;
    line-height: 16px;
    color: $seaz-text;

    &--name,
    &--dot {
      color: $profile-month;
    }

    &--name {
      @include multiline-truncation(1);
      text-align: left;
    }

    &--dot {
      padding: 0 0.8rem;
    }

    &--tasks {
      flex-shrink: 0;
      color: #351f6f;

      @include tablet {
        display: none;
      }
    }

    &--unassigned {
      color: $seaz-alarm;
    }
  }

  &__bar {
    @include d-align-items-center;
    justify-content: flex-end;
    height: 4.7rem;
    border-radius: 0.4rem;
    margin-left: 1.6rem;
    padding: 0.6rem 0.8rem;
  }

  &__bar-text {
    display: none;
    @include tablet {
      font-size: $base-font-size;
      line-height: 1.6rem;
      color: $light-color;
      @include multiline-truncation(1);
    }
  }

  &__icon {
    transform: rotateZ(180deg);
    margin-left: 1.2rem;
  }

  @include laptop {
    .web-template {
      display: none;
    }

    .tablet-template,
    .count-template {
      display: block;
    }
  }

  @include tablet {
    width: 100%;
    background-color: initial;

    .count-template {
      display: none;
    }

    &__unassigned {
      background: initial;
    }

    &__bar {
      margin: 0.4rem 0 0;
      height: 2.8rem;
    }
  }
}
