.social-registration-wrap {
  @include d-flex-space-between;
  align-items: center;

  @include tablet {
    flex-direction: column;
  }
}

.social-registration {
  @include d-flex-space-between;
  align-items: center;
  width: 100%;
  padding: 2.4rem 1.6rem;
  background-color: $clr-ashy-grey;
  border-radius: 1.2rem;

  &:first-of-type {
    margin-right: 2.4rem;
  }

  &__description {
    display: flex;
    align-items: center;
    line-height: 1.6rem;
    color: $seaz-text;
  }

  &__person-info {
    font-size: 2rem;
    font-weight: 500;
    margin-top: 0.4rem;
  }

  &__icon {
    display: block;
    margin-right: 1.4rem;
  }

  &__button {
    display: inline-block;
    height: 3.6rem;
    font-size: 1.2rem;
    padding: 0 1.6rem;
    line-height: 3.6rem;
  }

  @include tablet {
    &:first-of-type {
      margin: 0 0 1.2rem;
    }
  }
}
