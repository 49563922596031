.suggested-services {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-gap: 2.4rem;

  &__item {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 1.6rem;
    background-color: $bg-grey-light;
    border-radius: 1rem;
  }

  &__description {
    font-size: 2rem;
    font-weight: 500;
    line-height: 1.4;
    margin-right: 1rem;
  }

  &__btn {
    box-sizing: border-box;
    border-radius: 0.4rem;
    background-color: $clr-primary-and-accent;
    padding: 1.1rem 1.4rem;
  }

  @include laptop {
    grid-template-columns: repeat(2, 1fr);

    &__description {
      font-size: 1.6rem;
    }
  }

  @include tablet {
    grid-template-columns: repeat(1, 1fr);
  }
}
