// NOT add custom style in this file!!!!!!!!!

.large-container {
  width: 100%;
  max-width: 190.2rem;
  margin: 0 auto;
  box-sizing: border-box;
}

.main-container {
  width: 100%;
  max-width: 126.4rem;
  margin: 0 auto;
  box-sizing: border-box;
}

.wrapper-indent {
  padding-top: 6.2rem;

  @include desktop {
    padding-top: 5.4rem;
  }
}

.table,
.budget-table,
.outstanding-table {
  &.container-ref {
    margin-top: 3.8rem;
  }
}

.search-field-alternate {
  display: flex;
  align-items: center;
  width: 100%;
  box-sizing: border-box;

  .wrapper-rounded .form-field--content {
    border-radius: 0.4rem 0 0 0.4rem;
  }

  .form-field--content {
    border-radius: 0.4rem 0 0 0.4rem;
  }

  .form-field--content-outline {
    height: 4rem;
  }

  &__btn {
    display: block;
    width: 5.2rem;
    height: 4rem;
    border-radius: 0 0.4rem 0.4rem 0;
    background-color: $clr-primary-and-accent;
    cursor: pointer;

    &:disabled {
      background-color: $seaz-border;
    }
  }
  &.realtime-search-control {
    .wrapper-rounded .form-field--content {
      border-radius: 0.4rem;
    }
  }
}

.location-autocomplete-selected {
  &__seazone-select {
    width: 11.4rem;

    .wrapper-rounded .form-field--content {
      border-radius: 0;
    }
  }
}

.info-rounded-box {
  position: relative;
  background-color: $light-color;
  border-radius: 1.6rem;
  padding: 3.2rem;

  &--with-shadow {
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.04);
  }

  &--with-border {
    border: 0.5px solid $seaz-alert-info-bg;
  }

  &__no-data {
    margin: 0 auto;
    display: block;
  }
}

.chips-checkbox-select {
  display: flex;
  flex-wrap: wrap;
  align-items: center;

  &__item {
    display: inline-block;

    &:not(:last-of-type) {
      margin: 0.8rem 0.8rem 0.8rem 0;
    }

    &.disabled {
      .chips-item {
        background-color: $seaz-bg;
        color: $disabled-link;
        cursor: default;
        box-shadow: 0 0 0 1px #e0e0e0;
        -moz-box-shadow: 0 0 0 1px #e0e0e0;
      }
    }
  }

  .all-types {
    margin: 0.8rem 0.8rem 0.8rem 0;
  }

  .chips-item {
    position: relative;
    display: flex;
    align-items: center;
    box-sizing: border-box;
    border-radius: 5.6rem;
    background-color: $light-color;
    box-shadow: 0 0 0 1px #e0e0e0;
    -moz-box-shadow: 0 0 0 1px #e0e0e0;
    padding: 0.8rem 1.4rem 0.8rem 0.8rem;
    line-height: 1.6rem;
    color: $seaz-text;
    cursor: pointer;

    input {
      position: absolute;
      top: 0;
      left: 0;
      z-index: -1;
    }

    &.selected {
      box-shadow: 0 0 0 2px #432791;
      -moz-box-shadow: 0 0 0 2px #432791;

      .chips-checkbox-select__icon {
        background-color: $clr-primary-and-accent;
      }
    }
  }
}

.chips {
  position: relative;
  display: flex;
  align-items: center;
  box-sizing: border-box;
  border: 1px solid $seaz-head-arrow;
  background-color: $light-color;
  border-radius: 5.6rem;
  padding: 0.8rem 1.4rem 0.8rem 0.8rem;
  line-height: 1.6rem;
  color: $seaz-text;
  word-break: break-word;

  &__icon {
    flex-shrink: 0;
    display: block;
    width: 3.2rem;
    height: 3.2rem;
    border: 1px solid $seaz-head-arrow;
    border-radius: 50%;
    margin-right: 0.8rem;
  }
}

.chips-group {
  display: flex;
  flex-wrap: wrap;
  align-items: baseline;
  gap: 0.8rem;
}

.chips-container {
  display: flex;
  align-items: center;
  background-color: $clr-ashy-grey;
  border-radius: 4.1rem;
  padding: 0.4rem 1.2rem 0.4rem 0.8rem;

  &__icon-box {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 2.4rem;
    height: 2.4rem;
    margin-right: 0.8rem;
    background-color: $clr-primary-and-accent;
    border-radius: 50%;
    padding: 0.7rem;
  }

  &__icon {
    display: block;
    color: $profile-month;
    border-radius: 50%;
    filter: invert(100%) sepia(0%) saturate(0%) hue-rotate(148deg) brightness(108%) contrast(101%);
  }

  &__content {
    line-height: 1.6rem;
    color: $seaz-text;
    word-break: break-word;
  }

  &__title {
    display: block;
    font-size: 1.2rem;
    color: $profile-month;
  }

  &__btn {
    margin-left: 1.3rem;
  }
}

.chips-color-label {
  display: inline-block;
  width: 1.2rem;
  height: 1.2rem;
  border-radius: 50%;
  margin-right: 0.8rem;
}

.toggle-select-buttons {
  display: flex;
  background-color: $seaz-bg;
  border: 1px solid $seaz-head-arrow;
  border-radius: 0.8rem;
  padding: 0.2rem;

  &__button {
    width: 50%;
    font-size: 1.2rem;
    font-weight: 500;
    line-height: 1.6rem;
    letter-spacing: 0.1rem;
    text-transform: uppercase;
    text-align: center;
    color: $clr-primary-and-accent;
    padding: 1rem;
    border-radius: 0.6rem;

    &.active {
      background-color: $clr-primary-and-accent;
      color: $light-color;
    }
  }
}

.toggle-checkbox-control {
  @include d-align-items-center;

  &__label {
    font-size: $base-font-size;
    line-height: 1.6rem;
    color: $seaz-text;
    margin-left: 1rem;
  }
}

app-common-supplier-profile {
  display: block;
  min-height: 80vh;
}

.rate-me-link {
  width: 100%;

  &__title {
    font-size: 1.2rem;
    color: $profile-month;
    margin-bottom: 0.6rem;
  }

  &__box {
    @include d-flex-space-between;
    cursor: pointer;
    transition: opacity 0.3s;

    &:hover {
      @include hover-opacity(0.5);
    }
  }

  &__link {
    width: 28rem;
    font-size: 1.6rem;
    font-weight: 500;
    color: $seaz-edit-hover;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }
}

.region-autocomplete {
  flex-direction: column;

  app-info-label {
    display: block;
    margin-top: 1rem;
  }
}

.info-rounded-label {
  display: block;
  padding: 0.5rem 0.8rem;
  border-radius: 3.7rem;
  color: $light-color;
  background-color: $green-grey;

  &.main {
    background-color: $seaz-primary-and-accent;
  }
}

.label-box {
  display: inline-block;
  font-size: 1.2rem;
  font-weight: 500;
  line-height: 1.6rem;
  letter-spacing: 0.01rem;
  text-transform: uppercase;
  white-space: nowrap;
  padding: 0.4rem 1.2rem;
  border-radius: 2.4rem;
}

.lacked {
  color: $seaz-alarm;
  background: linear-gradient(0deg, #fbe7e8, #fbe7e8), #f5dadb;
}

.probable,
.perfect,
.user-match {
  color: $light-color;
}

.probable {
  background-color: $yellow-color;
}

.perfect {
  background-color: $seaz-success;
}

.user-match {
  background-color: $green-grey;
}

.footer-content-between {
  @include d-flex-space-between;

  &__box {
    @include d-align-items-center;
  }

  @include mobile {
    flex-direction: column;

    &__box {
      flex-direction: column;
      @include indent-not-last-of-type(0 0 1.2rem 0);
    }
  }
}

.plug-label {
  @include plug-label(14rem, 0 2rem 0 0);
}

.no-data {
  @include d-flex-center-content;
  flex-direction: column;

  &__text {
    font-size: 16px;
    line-height: 19px;
    color: $clr-light-grey;
    margin-top: 3rem;
  }
}

.no-data-info-icon {
  padding: 4rem 4.6rem;
  background-color: $clr-ashy-grey;
  border-radius: 2.4rem;
}

.chevron-button {
  @include d-flex-center-content;
  width: 4rem;
  min-width: 4rem;
  height: 4rem;
  border-radius: 0.4rem;
  outline-offset: -2px;

  &:hover {
    outline: 2px solid $clr-primary-and-accent;
  }
}

.dropdown-options-container.dropdown-body {
  box-shadow: 0 16px 24px rgba(53, 40, 113, 0.16);
  border-radius: 1.6rem;
  background-color: $light-color;
  padding: 0.8rem;

  .select-option {
    box-sizing: border-box;
    padding: 0.8rem;
    border-radius: 0.8rem;
    color: $seaz-about-text;

    &:hover {
      background-color: $clr-ashy-grey;
      font-weight: 500;
    }
  }
}

.clickable {
  cursor: pointer;
}

.uploader-action {
  @include d-flex-center-content;
  border: 1px solid $clr-primary-and-accent;
  border-radius: 0.4rem;
  padding: 0.8rem;
  font-size: 1.2rem;
  font-weight: 500;
  line-height: 1.6rem;
  text-transform: uppercase;
  color: $clr-primary-and-accent;
  cursor: pointer;
}

.uploader-action-icon {
  margin-right: 0.7rem;
}

.uploader-box {
  position: relative;
  width: 100%;
  border: 2px solid $seaz-head-arrow;
  border-radius: 1.2rem;
  padding: 1.6rem;
  @include indent-not-last-of-type(0 0 1.6rem 0);

  .form-field--wrapper__row {
    flex-direction: column;
  }

  .form-field--content {
    width: 90%;
  }

  .yaht-edit__upload_img_box {
    position: static;
    margin-top: 1.2rem;

    .seaz-uploader__video_btn {
      top: 4.8rem;
      right: 1.8rem;
    }
  }

  @include tablet {
    .yaht-edit__upload_img_box {
      .seaz-uploader__video_btn {
        right: 1rem;
      }
    }
  }

  @include mobile {
    .yaht-edit__upload_img_box {
      .seaz-uploader__video_btn {
        top: 5.3rem;
        right: 1rem;
      }
    }
  }
}

.list-view-btn {
  @include d-align-items-center;
  position: fixed;
  color: #351f73;
  font-size: 1.4rem;
  font-weight: 500;
  white-space: nowrap;
  background-color: $light-color;
  padding: 0.9rem 1.2rem;
  border-radius: 0 0 1.2rem 1.2rem;
  transform: rotate(270deg);
  z-index: 100;
}

.seaz-alarm {
  color: $seaz-alarm;
}

.seaz-warn {
  color: $seaz-warning;
}

.seaz-success {
  color: $seaz-success;
}

.seaz-text {
  color: $seaz-text;
}

.extended-location-autocomplete {
  @include mobile {
    margin-top: 2rem;
  }
}

.location-autocomplete__option {
  justify-content: space-between;

  &.select-option {
    font-size: 1.4rem;
    line-height: 2rem;
    color: $seaz-text;
  }

  .location-autocomplete__option-val {
    overflow: hidden;
  }

  .location-autocomplete__option-text {
    @include multiline-truncation(1);
  }

  .location-autocomplete__icon {
    margin-left: 1.1rem;
  }
}

.actual-cost-input {
  &.success {
    .form-field--content,
    .seazone__form-input {
      color: $seaz-success;
    }
  }

  &.alarm {
    .form-field--content,
    .seazone__form-input {
      color: $seaz-alarm;
    }
  }
}

.actual-cost-button {
  background-color: $greeny-border-bg;
  border-radius: 50%;
  padding: 0.3rem;
  margin-right: 0.35rem;
}

.paginator-list {
  .boat-home__nav {
    margin-top: 2rem;
  }

  app-seazone-select {
    width: 15.3rem;
    margin-left: 1.5rem;
  }
}

.reset-all {
  font-size: $base-font-size;
  font-weight: 500;
  line-height: 100%;
  color: $seaz-edit-hover;
  border-bottom: 1px dashed $seaz-edit-hover;
}

.boat-action-panel {
  position: relative;

  .action-panel-close {
    top: auto;
    bottom: 2rem;
  }
}

.no-data-wrapper {
  @include d-flex-center-content;
  flex-direction: column;
  height: 50vh;
  background-color: $light-color;
  margin-top: 1.6rem;
  border-radius: 1.2rem;

  &__content {
    font-size: 2rem;
    font-weight: 500;
    line-height: 120%;
    color: $clr-light-grey;
    margin-bottom: 1.6rem;
  }
}
