@mixin card-block() {
  position: relative;
  background-color: white;
  box-shadow: 0 0.2rem 0.5rem rgba(0, 0, 0, 0.04);
  border-radius: 1.6rem;
  border: 0.05rem solid #e7e7eb;
}

@mixin typography($font-size, $font-weight, $line-height) {
  font-size: $font-size;
  font-weight: $font-weight;
  line-height: $line-height;
}

//Display flex

@mixin d-flex-space-between {
  display: flex;
  justify-content: space-between;
}
@mixin d-align-items-center {
  display: flex;
  align-items: center;
}

@mixin d-items-end {
  display: flex;
  justify-content: flex-end;
}

@mixin d-flex-center-content {
  display: flex;
  justify-content: center;
  align-items: center;
}
@mixin d-flex-column-all-height {
  display: flex;
  flex-direction: column;
  height: 100%;
}
@mixin d-flex-column-space-between-all-height {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
}
@mixin d-flex-end {
  display: flex;
  justify-content: flex-end;
}
@mixin row-reverse-flex-end {
  display: flex;
  flex-direction: row-reverse;
  justify-content: flex-end;
}
@mixin preference-list-component {
  display: flex;
  flex-direction: column;
  flex: 1 1 auto;
  overflow: hidden;
}
//-------------------------------//

@mixin light-bg-border-corners {
  border: 0.05rem solid $seaz-alert-info-bg;
  box-sizing: border-box;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.04);
  border-radius: 1.6rem;
}

@mixin hover-opacity($opacity-val) {
  opacity: $opacity-val;
}

//Card layout

@mixin card-layout($border, $boxShadow, $borderRadius, $background, $padding) {
  border: $border;
  box-shadow: $boxShadow;
  border-radius: $borderRadius;
  background-color: $background;
  padding: $padding;
}

//Custom scroll bar

@mixin custom-scroll-bar {
  //Works on Firefox
  scrollbar-width: thin;
  scrollbar-color: $clr-primary-and-accent $light-color;

  // Works on Chrome, Edge, and Safari
  &::-webkit-scrollbar {
    width: 0.5rem;
    border-radius: 1.6rem;
  }

  &::-webkit-scrollbar-track {
    box-shadow: inset 0 0 5px transparent;
    border-radius: 1.6rem;
  }

  &::-webkit-scrollbar-thumb {
    width: 0.5rem;
    background: $clr-primary-and-accent;
    border-radius: 1.6rem;
  }
}

//------------------------------//

@mixin border-top {
  border-top: 1px solid $seaz-head-arrow;
}

@mixin box-bg($bg-color, $shadow, $radius) {
  background-color: $bg-color;
  box-shadow: $shadow;
  border-radius: $radius;
}

@mixin indent-not-last-of-type($indent) {
  &:not(:last-of-type) {
    margin: $indent;
  }
}

@mixin indent-not-last-of-child($indent) {
  &:not(:last-child) {
    margin: $indent;
  }
}

@mixin plug-label($width, $outer-indent) {
  width: $width;
  min-width: $width;
  margin: $outer-indent;
}

@mixin cut-text-add-dots {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

//Footer with right btn position and border top.
@mixin footer-with-border-left-btn($padding, $margin) {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  @include border-top;
  padding: $padding;
  margin: $margin;
}

//Multiline truncation text
@mixin multiline-truncation($num) {
  display: -webkit-box;
  display: -moz-box;
  -webkit-line-clamp: $num;
  -moz-line-clamp: $num;
  line-clamp: $num;
  -webkit-box-orient: vertical;
  -moz-box-orient: vertical;
  overflow: hidden;
}

@mixin box-header($align, $justify, $padding, $margin) {
  display: flex;
  align-items: $align;
  justify-content: $justify;
  padding: $padding;
  margin: $margin;
}

//GRID TEMPLATE
@mixin box-grid($template-columns, $template-rows, $template-areas, $gap) {
  display: grid;
  grid-template-columns: $template-columns;
  grid-template-rows: $template-rows;
  grid-template-areas: $template-areas;
  grid-gap: $gap;
}

@mixin left-border {
  &::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 0.1rem;
    height: 100%;
    background-color: #f5f5f5;
  }
}

@mixin right-border {
  &::after {
    content: '';
    position: absolute;
    top: 0;
    right: 0;
    width: 0.1rem;
    height: 100%;
    background-color: #f5f5f5;
  }
}

@mixin disable-layer {
  position: relative;
  &::after {
    content: '';
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    background: $seaz-bg;
    opacity: 0.7;
    z-index: 2;
  }
}
