// :host {
//     display: block;
//     width: 100%;
//     height: 400px;
//   }

.carousel {
  width: 100%;
  position: relative;
  overflow: hidden;
  outline: 0;
}

.carousel-prev,
.carousel-next {
  position: absolute;
  z-index: 1;
  top: 50%;
}

.carousel-prev {
  transform: translateY(-50%);
  left: 0;
}

.carousel-next {
  right: 0;
  transform: translateY(-50%) rotateZ(180deg);
}

.carousel-list {
  width: 100%;
  margin: 0;
  padding: 0;
  list-style: none;
  display: flex;
  position: relative;
}

.carousel-slide {
  width: 100%;
  height: 0;
  display: flex;
  flex-shrink: 0;
  position: relative;
}

.carousel-slide .carousel-slide {
  width: 100%;
  height: 100%;
  position: absolute;
  z-index: auto;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
}
.carousel-indicators {
  display: flex;
  justify-content: center;
  align-items: center;
}
.carousel-indicator {
  margin-right: 5px;
  width: 8px;
  height: 8px;
  border-radius: 50%;
  background-color: #351f6f;
  opacity: 0.3;
  &.active {
    opacity: 1;
  }
}
