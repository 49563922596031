.seaz-boat-event-modal {
  width: 100%;
  max-width: 72.2rem;
  box-sizing: border-box;

  .modal__wrap {
    padding: 3rem;

    @include tablet {
      padding: 2rem;
    }
  }
}
.boat-event__tooltip {
  position: absolute;
  left: 6.5rem;
  top: 1rem;
  z-index: 4;
  @include tablet {
    top: 0;

    svg {
      height: 1.4rem;
      width: 1.4rem;
    }
  }
  svg {
    height: 1.7rem;
    width: 1.7rem;
  }
}

.boat-event-modal {
  &__header {
    display: flex;
    align-items: center;
    margin-bottom: 3.4rem;

    &--icon {
      display: block;
      width: 2.4rem;
      height: 2.4rem;
      border-radius: 50%;
      margin-right: 1.2rem;
    }

    &--title {
      font-size: 2.4rem;
      font-weight: 500;
      line-height: 1.4;
      color: $clr-primary-and-accent;
    }
  }

  .delivery {
    background-color: $seaz-delivery;
  }

  .shipyard {
    background-color: $seaz-warning;
  }

  .custom {
    background-color: $seaz-custom;
  }

  @media screen and (max-width: 650px) {
    .form-field--wrapper__row .form-field--label,
    .seaz-charter__edit_form_label {
      max-width: 14rem;
    }
  }

  @include mobile {
    .boat-event-modal-form-shipyard .seaz-charter__edit_form_row app-date-control {
      margin: 0 0 2rem 0;
    }
  }
}

.boat-event-modal-form {
  &__row {
    margin: 1.6rem 0 0 0;

    &--name {
      margin-bottom: 3.2rem;
    }

    &--notes {
      margin: 3rem 0 0 0;
    }
  }

  &__info-label-wrap {
    display: flex;
    align-items: center;
    margin-top: 2.4rem;
  }

  &__period {
    margin-top: 4.8rem;
    text-align: right;

    .seaz-event__period {
      line-height: 2rem;
      color: $seaz-text;
    }

    .seaz-event__period-label {
      margin-right: 0.8rem;
    }
  }

  &__btn-box {
    margin-top: 3rem;
  }

  &__btn {
    &:not(:last-of-type) {
      margin-right: 1.6rem;
    }
  }

  input,
  textarea {
    background-color: inherit !important;
  }

  .seazone-form-field {
    &--wrapper {
      .form-field--label {
        margin-right: 2rem !important;
      }
    }
  }

  app-location-timezone-autocomplete {
    width: 100%;
  }

  @include tablet {
    &__label-cap {
      width: auto;
      margin: 0;
    }

    .form-field--wrapper.form-field__area.form-field--wrapper__row.seazone-form-field--wrapper.wrapper-rounded {
      flex-direction: column;
    }

    .form-field--wrapper.form-field--wrapper__row.seazone-form-field--wrapper.wrapper-rounded {
      flex-direction: column;
    }
  }
}

.seaz-crew__btn_box.boat-event-modal-form__btn-box {
  padding-bottom: 3rem;

  @include tablet {
    width: 100%;
  }
}

.boat-event-modal-form-shipyard {
  .seaz-charter__edit_form_row app-date-control {
    width: 50%;
    margin-right: 1.1rem;
    flex-shrink: 1;
  }

  .seaz-charter__edit_form_row app-seazone-time-input {
    width: 50%;
    margin-right: 0;
    flex-shrink: 1;
  }

  @include mobile {
    .seaz-charter__edit_form_row app-date-control {
      width: 100%;
      margin: 0 0 1.1rem 0;
    }

    .seaz-charter__edit_form_row app-seazone-time-input {
      width: 100%;
    }
  }
}
