.schedule {
  overflow: auto;
  &_height-sm {
    height: 37.5rem;
    @include mobile {
      height: 33rem;
    }
  }
  &_height-md {
    height: 43rem;
  }
  &_height-md--comprasion {
    @include mobile {
      height: 20rem;
    }
  }
}
.dashboard {
  &__top {
    padding: 2.7rem 2.8rem 2.3rem;
    border-radius: 0 0 1.6rem 1.6rem;
    margin-bottom: 1.9rem;
  }
  &__row {
    display: flex;
    &:not(:last-child) {
      margin-bottom: 2.2rem;
    }
    @include laptop {
      flex-direction: column;
    }
  }
  &__aside {
    width: 30rem;
    flex-shrink: 0;
    padding: 0;
    max-height: 50rem;
    overflow-y: auto;
    margin-right: 3rem;
    @include laptop {
      margin: 0 0 2.2rem 0;
      width: 100%;
    }
    @include mobile {
      padding: 0;
      width: auto;
    }
    &-inner {
      padding: 1.7rem 1.8rem;
      @include mobile {
        padding: 1.5rem;
      }
    }
    &-title {
      color: $clr-purple;
      margin-bottom: 2.6rem;
    }
  }
  &__board {
    flex-grow: 1;
    @include mobile {
      //margin: 0 -1.6rem;
      //padding: 1.5rem;
      //border-radius: 0;

      box-shadow: 0 2px 5px rgba(0, 0, 0, 0.04);
      border-radius: 16px;
    }
    &-title {
      margin-bottom: 0.7rem;
      @include mobile {
        width: calc(100% - 9rem);
      }
    }
    &-total {
      font: 1.4rem/1.8rem $base-font;
      color: $clr-purple;
      margin-bottom: 1.6rem;
      @include mobile {
        font-size: 1.2rem;
        line-height: 1.4rem;
      }
    }
    &-btn {
      display: none;
      cursor: pointer;
      @include mobile {
        display: inline-flex;
      }
    }
    &-schedule {
      position: relative;
    }
    &-switch {
      margin-left: auto;
    }
    &-wrap-title {
      flex-wrap: wrap;
      align-items: flex-start;
    }
    &-table {
      margin-top: 3.4rem;
      @include mobile {
        font-size: 1rem;
        margin-top: 1.5rem;
      }
    }
  }
}

.chartjs-render-monitor {
  width: 100% !important;
}

.rotated ~ [dir='ltr'] .chart-container--comparing {
  @include mobile {
    z-index: -1;
  }
}

.seaz-dashboard {
  &__wrapper {
    width: 126.4rem;
    margin: 0 auto;
    display: flex;
    flex-direction: column;
    @media screen and (max-width: 1350px) {
      width: 100rem;
    }
    @include laptop {
      width: 100%;
      padding: 0 1.6rem;
    }

    .toggle-button {
      transform: rotateX(180deg);
      padding-bottom: 0;
      transition: all 0.1s ease-in-out;
      &.active {
        transform: rotateX(0deg);
      }
    }
    .hidden {
      display: none;
    }
  }
  &__header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 3rem 0;
    border-bottom: 1px solid $seaz-border;
    margin-bottom: 3.3rem;
    @include laptop {
      flex-direction: column;
      align-items: flex-start;
    }
    .profile__wrap-img {
      width: 10rem;
      height: 10rem;
      &.avatar {
        margin: 0 2.4rem 1rem 0;
        border: none;
      }
      .avatar__img {
        border-radius: 16px;
      }
    }
    .profile__col_info {
      align-items: center;
    }
  }
  &__main {
    display: flex;
    flex-direction: column;
    padding-bottom: 3rem;
  }
  &__finance {
    &_section {
      display: none;
      .pretty-cash__title {
        text-transform: uppercase;
        font-weight: 500;
      }
      &.active {
        display: block;
      }
    }
    &_head {
      display: flex;
      justify-content: space-between;
      padding: 3.3rem 0 1.7rem;
      .toggle-button {
        @include laptop {
          display: none;
        }
        &:before {
          color: #351f73;
        }
      }
    }
    &_title {
      text-transform: uppercase;
      color: $clr-primary-and-accent;
      margin-left: 2rem;
      @media screen and (max-width: 600px) {
        font-size: 2rem;
      }
      @include mobile {
        margin-left: 0;
      }
      &_head {
        display: flex;
        align-items: center;
      }
    }
    &_btn {
      color: $seaz-mulberry;
      border-color: $seaz-mulberry;
      padding: 0.5rem 1.5rem;
    }
    &_expense {
      &_label {
        font-size: 1.2rem;
        color: $seaz-document-count;
        padding-bottom: 0.8rem;
        @include laptop {
          padding-bottom: 2.4rem;
        }
      }
      &_btn {
        button {
          @extend .seaz__btn_outlined_plus;
          flex-direction: row-reverse;
          font-weight: 500;
          @include laptop {
            width: 100%;
            justify-content: center;
          }
          &::after {
            margin-left: 0;
            margin-right: 0.8rem;
          }
        }
      }
    }
    &_wrapper {
      display: flex;
      justify-content: space-between;
      margin-bottom: 1.6rem;
      @include laptop {
        flex-direction: column;
      }
    }
    &_info {
      display: flex;
      width: 100%;
      justify-content: space-between;
      background-color: $clr-white;
      padding: 2.7rem 2.4rem;
      @include laptop {
        flex-direction: column;
        margin-bottom: 2.4rem;
      }
      @include mobile {
        padding: 1.6rem;
      }
      &--left {
        display: flex;
        @include laptop {
          padding-bottom: 2.4rem;
        }
      }
      &--right {
        display: flex;
        flex-direction: column;
      }
      &_col {
        display: flex;
        flex-direction: column;
        width: 17.5rem;
        @include laptop {
          width: 50%;
        }
        &:not(:last-of-type) {
          border-right: 1px solid $seaz-head-arrow;
        }
        &:not(:first-of-type) {
          padding-left: 1.2rem;
        }
        &--pretty {
          .seaz-dashboard__finance_info_title,
          .seaz-dashboard__finance_info_cash {
            color: $clr-green;

            &.negative-amount {
              color: $seaz-alarm;
            }
          }
        }
      }
      &_title {
        padding-bottom: 0.8rem;
        font-weight: 500;
        font-size: 1.4rem;
        line-height: 1.4rem;
        color: $clr-primary-and-accent;
      }
      &_date {
        padding-bottom: 0.8rem;
        letter-spacing: 0.01em;
        font-weight: 500;
        font-size: 1.2rem;
        color: $seaz-document-count;
      }
      &_cash {
        font-size: 2rem;
        font-weight: 500;
        color: $clr-primary-and-accent;
      }
    }
  }
  &__pretty {
    background-color: $clr-white;
    padding: 3rem;
    margin-bottom: 4.9rem;
    .pretty-cash__title {
      padding-bottom: 1.2rem;
      font-size: 2.4rem;
      color: $clr-primary-and-accent;
    }
    &_update {
      padding-bottom: 2.4rem;
      font-size: 1.2rem;
      color: $seaz-document-count;
      @include laptop {
        padding-bottom: 0;
      }
    }
  }
  &__expenses {
    &_table {
      overflow: auto;
      overflow-y: hidden;
      width: 100%;
    }
    &_title {
      text-transform: uppercase;
      color: $clr-primary-and-accent;
      padding-bottom: 1.2rem;
      @media screen and (max-width: 600px) {
        font-size: 2rem;
      }
    }
    &_wrapper {
      background-color: $clr-white;
      padding: 3rem;
      display: none;
      margin-bottom: 4.9rem;
      &.active {
        display: block;
      }
      @include laptop {
        padding-right: 0;
      }

      app-financial-data-filtered-table {
        display: table-row-group;
        .table__row {
          &:nth-of-type(4n - 1) {
            background-color: #fafafb;
          }
          &.collapsible {
            .table__col {
              padding: 0;
            }
          }
          .financial-data__currency {
            .table__col-inner {
              display: inline;
            }
          }
        }
        .table__col-inner-multiple {
          justify-content: flex-end;
          app-date-control {
            width: auto;
            .date-picker-icon {
              color: $clr-primary-and-accent;
            }
          }
        }
        .financial-data__name,
        .financial-data__category_name {
          color: $seaz-mulberry;
          font-size: 1.4rem;
        }
        .financial-data__department,
        .financial-data__category_subname {
          color: #4d4e67;
          font-size: 1.1rem;
        }
        .table__col-btn {
          .icon-file-eye,
          .icon-table-edit,
          .icon-down-arrow-sm {
            color: $clr-primary-and-accent;
          }
        }
      }
    }
    &_update {
      padding-bottom: 2.4rem;
      font-size: 1.2rem;
      color: $seaz-document-count;
      @include laptop {
        padding-bottom: 0;
      }
    }
  }
  &__documents {
    &_title {
      color: $clr-primary-and-accent;
      margin-right: 0.8rem;
      &_box {
        display: flex;
        align-items: center;
        margin-bottom: 2rem;
      }
      &_count {
        font-size: 1.2rem;
        letter-spacing: 0.01em;
        color: $clr-primary-and-accent;
        min-width: 2.4rem;
        height: 2.4rem;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 50%;
        background-color: $seaz-head-arrow;
        padding: 0 0.5rem;
      }
    }
    &_row {
      position: relative;
    }
    &_select {
      position: absolute;
      width: 12.6rem;
      top: -0.8rem;
      right: 0;
      @include laptop {
        right: 2rem;
      }
      @media screen and (max-width: 600px) {
        position: relative;
        right: 0;
        width: 100%;
        margin-bottom: 1.2rem;
      }
      app-seazone-select {
        .form-field--content-outline {
          height: 4rem;
          padding: 0.5rem 1.2rem;
          border-color: $seaz-head-arrow;
          border-radius: 0;
        }
      }
    }
    &_section {
      display: flex;
      flex-direction: column;
      margin-bottom: 3.8rem;
    }
    &_content {
      display: flex;
      justify-content: space-between;
      margin-top: 2.7rem;
      margin-bottom: 1.6rem;
      @include laptop {
        flex-direction: column;
      }
    }
    &_fav {
      width: 50%;
      margin-right: 1.5%;

      .boat-document-content {
        grid-template-columns: 1fr 15rem 19% 2rem;
      }

      .document-info {
        grid-template-columns: 1fr;
      }

      @include tablet {
        .boat-document-content {
          grid-template-columns: 1fr 1fr;
        }

        .document-info {
          margin: 1.6rem 0 0;
        }
      }

      @include laptop {
        margin-right: 0;
        width: 100%;
        margin-bottom: 2.7rem;
      }
    }
    &_notification {
      width: 48%;
      @include laptop {
        width: 100%;
      }

      .yaht-documents__box_head {
        width: 100%;
        padding-left: 4rem;
        @media screen and (max-width: 600px) {
          display: none;
        }
        .yaht-documents__box_head_name {
          width: 44%;
        }
        .yaht-documents__box_head_date {
          width: 27%;
          justify-content: flex-end;
        }
        .yaht-documents__box_head_date {
          width: 29%;
          justify-content: flex-end;
        }
      }
      &_wrapp {
        display: flex;
        flex-direction: column;
        max-height: 45rem;
        overflow-y: auto;
        padding-right: 2rem;
        @include custom-scroll-bar;

        @include mobile {
          padding-right: 0.8rem;
        }
      }
      &_row {
        display: flex;
      }
    }
    &_list {
      .yaht-documents__box_head {
        width: 100%;
        padding-left: 4rem;
        @media screen and (max-width: 600px) {
          display: none;
        }
        .yaht-documents__box_head_name {
          width: 35%;
          @include laptop {
            width: 26%;
          }
        }
      }
      .yaht-documents__row--long .yaht-documents__row:nth-of-type(2n - 1) {
        background-color: #ffffff;
      }
      .yaht-documents__row--long .yaht-documents__row:nth-of-type(2n) {
        background-color: $seaz-logo-border;
      }
      .yaht-documents__row--content {
        width: 100%;
        padding: 1rem;
        @media screen and (max-width: 600px) {
          flex-direction: column;
        }
      }
      .yaht-documents__row--left {
        @media screen and (max-width: 600px) {
          position: relative;
        }
      }
      .yaht-documents__row_info--left {
        width: 35%;
        padding-top: 4px;
        @include laptop {
          width: 40%;
        }
        @media screen and (max-width: 600px) {
          position: absolute;
          width: 45%;
          top: 0;
          right: 0;
          .yaht-documents__info_col_label {
            display: none;
          }
        }
        .yaht-documents__date {
          width: 100%;
          @media screen and (max-width: 600px) {
            justify-content: flex-end;
          }
        }
      }
      .yaht-documents__name {
        width: 53.7%;
        padding-right: 2rem;
        @media screen and (max-width: 1350px) {
          padding-right: 1rem;
          width: 60%;
          .yacht-document__name_text {
            span {
              font-size: 1.4rem;
            }
          }
        }
        @include laptop {
          padding-right: 2rem;
          width: 44%;
        }
        @media screen and (max-width: 600px) {
          width: 50%;
        }
      }
      .yaht-documents__action {
        width: 38%;
        @media screen and (max-width: 1350px) {
          width: 52.3%;
        }
        @media screen and (max-width: 600px) {
          position: relative;
          top: inherit;
          width: 100%;
          justify-content: flex-start;
          padding: 1rem 0;
        }
      }
      .yaht-documents__row--long {
        max-height: 45rem;
        overflow-y: auto;
        padding-right: 2rem;

        @include custom-scroll-bar;

        @include mobile {
          padding-right: 0.8rem;
        }
      }
    }
    &_btns {
      width: 100%;
      display: flex;
      justify-content: space-between;
      padding: 0 0 1.6rem;
      margin-bottom: 3.3rem;
      @media screen and (max-width: 600px) {
        flex-direction: column;
      }
      button {
        padding: 1rem 3.2rem;
        width: 33%;
        @include laptop {
          font-size: 1.2rem;
          padding: 1rem 1.2rem;
          width: 32%;
        }
        @media screen and (max-width: 600px) {
          width: 100%;
          margin-bottom: 1.2rem;
        }
      }
    }
  }
  &__notification {
    &_row {
      display: flex;
      padding: 1.3rem 2rem;
      &:nth-of-type(2n - 1) {
        background-color: $clr-white;
      }

      &_circle {
        width: 0.8rem;
        min-width: 0.8rem;
        height: 0.8rem;
        background-color: $seaz-success;
        border-radius: 50%;
      }
      &_name,
      &_date,
      &_now {
        padding-bottom: 0.4rem;
        color: $clr-primary-and-accent;
      }
      &_expire {
        color: $seaz-document-count;
      }

      @include mobile {
        padding: 1.3rem 1rem;
      }
    }
    &_name_head {
      display: flex;
      align-items: center;
      width: 50%;
      flex-shrink: 0;
      @media screen and (max-width: 600px) {
        width: 70%;
      }
    }
    &_col {
      &_name,
      &_date,
      &_now {
        margin-left: 1.6rem;
        display: flex;
        flex-direction: column;
        // flex-shrink: 0;
      }
    }
    &_col_date {
      width: 25%;
      text-align: end;
      @media screen and (max-width: 600px) {
        display: none;
        width: 30%;
        .seaz-dashboard__notification_row_expire {
          display: none;
        }
      }
    }
    &_col_now {
      width: 21%;
      text-align: end;
    }
  }
  &__offers {
    &_head {
      &.seaz-dashboard__head {
        @media screen and (max-width: 600px) {
          padding-bottom: 4rem;
        }
        @include mobile {
          flex-direction: column;
          align-items: flex-start;

          .seaz-dashboard__head_btn {
            width: 100%;
            margin-top: 1rem;
          }
        }
      }
      &_right {
        display: flex;
        align-items: center;
        @include tablet {
          flex-direction: column-reverse;
          align-items: flex-end;
          position: relative;
        }
        @include mobile {
          width: 100%;
          align-items: flex-start;
          margin-top: 1rem;
        }
      }
      &_text {
        display: flex;
        align-items: center;
        @include tablet {
          margin-top: 1rem;
        }
        @media screen and (max-width: 600px) {
          white-space: nowrap;
          top: 3rem;
          position: absolute;
        }
        @include mobile {
          position: inherit;
          top: inherit;
        }
      }
      &_new {
        font-size: 1.2rem;
        color: $seaz-document-count;
        margin-right: 1rem;
        &--green {
          color: $seaz-success;
        }
      }
    }
    &_section {
      display: none;
      margin-bottom: 4.9rem;
      &.active {
        display: block;
      }
    }
    &_item {
      display: flex;
      padding: 0.8rem 1.6rem;
      cursor: pointer;
      &:nth-of-type(2n - 1) {
        background-color: $clr-white;
      }
      @include laptop {
        flex-direction: column;
      }
    }
    &_count_box {
      display: flex;
      width: 27%;
      @include laptop {
        width: 100%;
        margin-bottom: 1.2rem;
      }
    }
    &_row {
      display: flex;
      align-items: center;
      .seaz__btn_primary {
        font-size: 1.2rem;
        padding: 0.5rem 2.8rem;
        margin-right: 1.2rem;
      }
      .seaz__btn_outlined {
        padding: 0.5rem 1.5rem;
        font-size: 1.2rem;
      }
      .seaz-dashboard__offers_jump {
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 0.5rem 1.8rem;
        background-color: $seaz-edit-hover;
        app-seazone-icon {
          margin-right: 1rem;
          width: 17px;
          height: 17px;
        }
      }
      &--position {
        align-items: center;
        display: flex;
        width: 19%;
        @media screen and (max-width: 1350px) {
          width: 25%;
        }
        @include laptop {
          width: 100%;
          margin-bottom: 1.6rem;
          flex-direction: row-reverse;
          justify-content: flex-end;
        }
        span {
          color: $clr-primary-and-accent;
          @include laptop {
            font-size: 2rem;
          }
        }
      }
      &--name {
        display: flex;
        flex-direction: column;
        width: 15%;
        align-items: flex-start;
        @include laptop {
          width: 100%;
          margin-bottom: 1rem;
        }
        .seaz-dashboard__offers_col {
          width: 100%;
        }
        .seaz-dashboard__offers_name {
          font-weight: 500;
          color: $clr-primary-and-accent;
          font-size: 1.4rem;
        }
      }
      &--controls {
        margin: 0 0 0 auto;
        @media screen and (max-width: 1350px) {
          display: flex;
          align-items: center;
        }
        @include laptop {
          margin: 0;
          display: flex;
          flex-wrap: wrap;
          button {
            width: 49%;
            &:first-of-type {
              margin-right: 1%;
            }
          }
        }
        @media screen and (max-width: 600px) {
          .icon-search {
            display: none;
          }
        }
      }
    }
    &_img {
      width: 3.2rem;
      height: 3.2rem;
      margin-right: 1.6rem;
      @include laptop {
        margin-right: 0;
        margin-left: 0.8rem;
      }
    }
    &_col {
      display: flex;
      flex-direction: column;
      width: 33%;
    }
    &_label {
      padding-bottom: 0.4rem;
      font-size: 1rem;
      color: $seaz-document-count;
      text-transform: uppercase;
    }
    &_count {
      font-weight: 500;
      font-size: 1.6rem;
      color: $clr-primary-and-accent;
      margin-right: 8px;
      &--new {
        color: $seaz-success;
      }
    }
    &_update {
      font-size: 1.2rem;
      color: $seaz-document-count;
      margin-right: 0.8rem;
      @include laptop {
        order: 3;
        margin-right: 0;
        text-align: center;
        margin-top: 1rem;
        width: 100%;
        text-transform: uppercase;
      }
    }
    &_container {
      display: flex;
      align-items: center;
    }

    @include mobile {
      margin-top: 1rem;
    }
  }
  &__head {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 1.7rem 0;
    width: 100%;
    &_title {
      color: $clr-primary-and-accent;
      text-transform: uppercase;
      margin-left: 2rem;
      @media screen and (max-width: 600px) {
        font-size: 2rem;
      }
      &_box {
        display: flex;
        align-items: center;

        .toggle-button {
          &::before {
            color: $clr-primary-and-accent;
          }
        }
      }
    }
    &_btn {
      color: $seaz-mulberry;
      border-color: $seaz-mulberry;
      padding: 0.5rem 1.5rem;
    }

    @include mobile {
      flex-direction: column;
      align-items: flex-start;

      &_btn {
        width: 100%;
        margin-top: 1rem;
      }
    }
  }
  &__settings {
    display: none;
    position: absolute;
    z-index: 100;
    width: 29.6rem;
    height: auto;
    background-color: $clr-white;
    box-shadow: 0 0 10px #82828280;
    right: 0;
    top: 5.7rem;
    padding: 2.4rem;
    border-radius: 1.2rem;
    @include laptop {
      right: inherit;
      left: 0;
    }
    &_title {
      color: $seaz-text;
      text-transform: capitalize;
      padding-bottom: 1.6rem;
      font-weight: 500;
    }
    &_row {
      display: flex;
      align-items: center;
      padding-bottom: 1.6rem;
    }
    &_btn {
      width: 1.6rem;
      margin-right: 1.2rem;

      span {
        display: block;
        width: 100%;
        height: 0.2rem;
        background-color: $seaz-document-count;
        &:not(:last-of-type) {
          margin-bottom: 0.3rem;
        }
      }
    }
    &_checkbox {
      .form__checkbox {
        margin-bottom: 0;
        width: 2.4rem;
        margin-right: 0.8rem;
        .form__checkbox-label {
          width: 2.4rem;
          height: 2.4rem;
          &:before {
            border-color: $seaz-border;
            width: 2.4rem;
            height: 2.4rem;
            border-radius: 0;
          }
        }
      }
      .boat__form-checkbox .form__checkbox-input:checked + label::before {
        background-color: $seaz-mulberry;
        border-color: $seaz-mulberry;
      }
    }
    &_item_text {
      text-transform: capitalize;
      color: $seaz-text;
    }
    &_update {
      padding: 0.4rem 1.5rem;
      border-color: $seaz-mulberry;
      color: $seaz-mulberry;
    }
    &.active {
      display: block;
    }
  }
  &__settings-wrap {
    position: relative;
  }

  &__charters-wrapper {
    @include d-flex-space-between;
    align-self: baseline;
  }

  &__charters-statistics {
    &--overview-statistic-total {
      width: 80%;
      border: none;
      box-shadow: none;
      border-radius: 0;
    }
  }

  &__charters-statistics-total-box {
    @include d-flex-space-between;
  }
}

.cdk-overlay-container .seaz-select__overlay .container-fixed.dropdown-options-container {
  height: 20rem;
  overflow: hidden;
  &:hover {
    overflow: auto;
  }
  @include custom-scroll-bar;
}

.seaz-apa-report-dashboard-title {
  padding: 0.5rem 1.2rem;
  margin-bottom: 2.4rem;
  background-color: rgba(231, 231, 235, 0.5);
  border-radius: 0.4rem;

  .dashboard__board-title {
    margin-bottom: 0;
  }
}

.seaz-dashboard-charters {
  .seaz-dashboard__head_title-sub-title {
    font-size: 1.8rem;
  }

  &__wrapper {
    display: none;
    align-self: baseline;
    margin-bottom: 4.9rem;
    &.active {
      @include d-flex-space-between;
    }
  }

  &__statistics {
    flex-grow: 1;
    margin-right: 3.2rem;

    &--overview-statistic-total {
      width: 100%;
      border: none;
      box-shadow: none;
      border-radius: 0;
    }
    .card-total {
      display: flex;
      flex-direction: column;
      .overview-card__title-box {
        order: 1;
        margin-bottom: 2.5rem;
      }
      .overview-statistic__header {
        order: 2;
        margin: 0 2rem 0;
      }
      .overview-card__content {
        order: 3;
      }
    }
  }

  &__statistics-total-box {
    @include d-flex-space-between;
    padding: 2.4rem;
    margin-bottom: 1.6rem;
    background-color: #fff;
  }

  &__statistics-chart {
    width: 28rem;
    flex-shrink: 0;
    border-left: 1px solid $seaz-head-arrow;
    padding-left: 2.4rem;
    box-sizing: border-box;

    .seaz-charter-overview-chart__chart-title.seaz-dashboard-charters__statistics-chart--title {
      font-size: 1.8rem;
    }
  }

  &__card-scrollable {
    overflow: auto;
    max-width: 100%;
  }

  &__card-wrapper {
    position: relative;
    background-color: #fff;
    padding: 4.9rem 2.4rem 2.4rem;
    @media screen and (max-width: 768px) {
      position: unset;
    }
  }

  &__cards-box {
    &.overview-statistic__card-box {
      max-width: 78rem;
    }
    .seaz-charter__list_card_head_error {
      display: block;
      width: 1.6rem;
      height: 1.6rem;
      font-size: 1.2rem;
      font-weight: 500;
      text-align: center;
      line-height: 1.6rem;
      padding: 0;
      margin-right: 0;
      margin-left: 0.6rem;
      background-color: #fff;
      color: $clr-primary-and-accent;
    }
  }

  @media screen and (max-width: 1354px) {
    &__wrapper.active {
      flex-direction: column;
      align-self: initial;
    }

    &__statistics {
      margin-right: 0;
    }
  }

  @media screen and (max-width: 768px) {
    &__statistics-total-box {
      flex-direction: column;
      padding: 1.2rem;
    }

    &__statistics-chart {
      width: 100%;
      border-left: 0;
      border-top: 1px solid $seaz-head-arrow;
      padding: 2.4rem 0 0;
      margin-top: 1.2rem;

      .seaz-charter-overview-chart__chart-title-box {
        text-align: center;
      }
    }

    &__cards-box {
      flex-direction: column;
      align-items: center;
      padding: 1.2rem;

      .overview-statistic__card-box-item:not(:last-of-type) {
        margin: 0 0 2rem;
      }
    }
  }

  @media screen and (max-width: 480px) {
    .seaz-dashboard__head {
      flex-direction: column;
    }

    .seaz-dashboard__head_btn {
      width: 100%;
      margin-top: 1rem;
    }
  }
}

.seaz-dashboard-charters-info {
  display: flex;
  flex-direction: column;
  align-content: space-between;
  width: 40rem;
  box-sizing: border-box;
  background-color: #fff;

  &__title {
    padding: 3rem;
    border-bottom: 1px solid $seaz-head-arrow;
    color: $seaz-document-count;
    font-size: 1rem;
    letter-spacing: 0.5px;
    text-transform: uppercase;
  }

  &__content {
    padding: 2.4rem 3rem 1.4rem;
    max-height: 84.2rem;
    height: 100%;
    overflow-y: auto;
    &.full {
      max-height: 99rem;
    }
    @include custom-scroll-bar;
  }

  &__warning {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0.5rem 1.6rem;
    background: linear-gradient(0deg, #fbe7e8, #fbe7e8), #f5dadb;
    border-radius: 0 0 1.6rem 1.6rem;
    margin-top: auto;
    &--button {
      font-weight: 500;
    }
  }

  &__warning-text {
    font-size: 1.2rem;
    color: $seaz-alarm;
  }

  .seaz-charter__list_card_head_error {
    display: inline-block;
    text-align: center;
    line-height: 2rem;
    margin-right: 0.6rem;
  }

  .seaz-charter-card-badge {
    margin: 0 0.8rem 0.8rem 0;
  }

  .seaz-charter__list_card_head_error_box {
    margin-bottom: 0.8rem;
  }

  @media screen and (max-width: 1354px) {
    width: 100%;
    margin-top: 1.2rem;

    &__content {
      height: auto;
      max-height: none;
      overflow-y: visible;
      &.full {
        max-height: none;
      }
    }
  }

  @media screen and (max-width: 768px) {
    &__title {
      padding: 1.6rem;
    }

    &__content {
      padding: 1.3rem;
    }

    &__warning {
      padding: 0.5rem 1.2rem;
    }
  }

  @media screen and (max-width: 480px) {
    &__warning {
      .seaz-dashboard__head_btn {
        width: auto;
        margin-top: 0;
      }
    }

    &__warning-text {
      display: flex;
      align-items: center;
    }

    .seaz-charter__list_card_head_error {
      flex-shrink: 0;
    }
  }
}
