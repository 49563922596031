app-suppliers-search-card,
app-suppliers-search-list {
  display: block;
  width: 100%;
}

app-suppliers-search-card {
  &:not(:last-of-type) {
    margin-bottom: 2.4rem;
  }
}

.supplier-card {
  padding: 2.4rem 0 0;
  position: relative;
  overflow: hidden;

  &__top-label {
    position: absolute;
    background-color: #f7c948;
    font-weight: 500;
    font-size: 2rem;
    color: $seaz-text;
    text-transform: uppercase;
    padding: 2.8rem 0 0.4rem;
    width: 10rem;
    text-align: center;
    left: 0;
    transform: rotate(-45deg);
    transform-origin: -15% 100%;
    z-index: 1;
    pointer-events: none;
  }

  .card-label-box {
    display: flex;
    justify-content: flex-end;
    padding-right: 4.1rem;
    margin-bottom: 1.6rem;

    @include tablet {
      margin: 0 0 1.6rem 1rem;
      padding-right: 3rem;
    }
  }

  &__title {
    font-size: 2.4rem;
    font-weight: 500;
    text-transform: uppercase;
    line-height: 1.2;
    word-break: break-word;
    color: $clr-primary-and-accent;
  }

  &__general-info {
    min-height: 19.5rem;
  }

  &__chips-group {
    margin-top: 1.6rem;
  }

  .seaz-search__card_photo {
    margin-right: 0;
  }

  .chips-container__icon-box {
    width: 2.4rem;
    height: 2.4rem;
    padding: 0;
  }

  &__show-all {
    margin-bottom: 1.6rem;

    button {
      line-height: 1.6rem;
      color: $seaz-edit-hover;
    }
  }

  &__services {
    display: flex;

    .chips-group {
      align-items: center;
    }
  }

  &__col {
    width: 50%;

    &:last-of-type {
      border-left: 1px solid $seaz-bg;
      padding-left: 2.4rem;
    }
  }

  &__services-title {
    color: $profile-month;
    margin-bottom: 0.4rem;
  }

  &__info-person {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  &__rating-box {
    margin-top: 1.2rem;
  }

  &__work-days {
    margin-top: 1.2rem;

    &--mobile {
      display: none;
    }
  }

  &__work-time {
    line-height: 1.6rem;
    text-align: center;
  }

  &__special-time,
  &__timezone {
    display: block;
    color: $clr-grey;
  }

  &__timezone {
    text-transform: uppercase;
  }

  &__visit-info {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 1.2rem;

    app-seazone-icon {
      display: block;
      margin-left: 1.3rem;
    }
  }

  &__time-marker {
    min-height: 2.4rem;
    font-size: 1.2rem;
    font-weight: 500;
    letter-spacing: 0.1em;
    text-transform: uppercase;
    text-align: center;
    line-height: 2.4rem;
    padding: 0.4rem 0.8rem;
    border-radius: 2.4rem;

    &.open {
      color: $seaz-delivery;
      background-color: $greeny-bg;
    }

    &.closed {
      color: $seaz-about-text;
      background-color: $clr-ashy-grey;
    }
  }

  &__contact-name {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    margin: 1.2rem 1.6rem 0 0;
    color: $seaz-text;
  }

  &__about-person-services {
    display: flex;
    flex-wrap: wrap;
    align-items: center;

    .search-card__location {
      margin: 1.2rem 2.8rem 0 0;
    }
  }

  &__branches {
    font-size: 1.2rem;
    padding: 0.8rem 1.6rem;
    margin-top: 1.2rem;
  }

  &__status {
    display: flex;
    align-items: center;

    .seaz-btn-primary-small-relevant {
      margin-right: 1.2rem;
    }
  }

  &__icon-certified {
    margin-left: 1rem;
  }

  .brand-item,
  .count-item {
    min-height: 3.2rem;
    font-size: 1.4rem;
    font-weight: normal;
    line-height: 1.6;
    color: $profile-month;
    border: none;
    background-color: $clr-ashy-grey;
    padding: 0.8rem 1.2rem;
  }

  .count-item {
    border-radius: 5.6rem;
    padding: 0.8rem 1.6rem 0.8rem 1rem;
  }

  .stash-items-wrapper {
    align-items: baseline;
  }

  .stash-items {
    height: 4rem;
  }

  .seaz-btn-primary-small-favorite.active {
    background-color: $seaz-edit-hover;
  }
  .seaz-btn-primary-small-relevant.active {
    background-color: $seaz-alarm;
  }

  &__chips-icon {
    width: 1.6rem;
    height: 1.6rem;
  }

  .search-card__about-person-info {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
  }

  &__footer {
    position: relative;
  }

  @include tablet {
    .seaz-search__card_photo {
      width: 8.8rem;
      height: 8.8rem;
      margin-bottom: 0.8rem;
    }

    &__rating-box {
      margin-top: 0;
    }

    &__work-days {
      margin-top: 0;

      &--desktop {
        display: none;
      }

      &--mobile {
        display: block;
      }
    }

    &__work-time {
      text-align: left;
    }

    &__visit-info {
      justify-content: flex-start;
    }

    &__general-info {
      min-height: initial;
      border-top: 1px solid $seaz-bg;
      padding: 1.2rem 0 0 0;
      margin-top: 1.6rem;
    }

    &__chips-group {
      margin-top: 0;
    }

    &__services {
      display: flex;
      flex-direction: column;
    }

    &__col {
      width: 100%;

      &:last-of-type {
        border-top: 1px solid $seaz-bg;
        border-left: none;
        padding: 1.2rem 0 0 0;
        margin-top: 1.2rem;
      }
    }

    &__footer {
      .crew-id-box {
        flex-direction: row;
      }
    }
  }

  @include mobile {
    &__footer {
      align-items: flex-start;
    }
  }

  .crew-id-box {
    margin-top: 0;
    align-items: center;
  }
}

.rating-box-person {
  display: flex;
  align-items: center;

  &__text {
    line-height: 1.6rem;
    color: $profile-month;
    margin-left: 0.8rem;
  }

  @include tablet {
    flex-direction: column;
    justify-content: center;

    &__text {
      margin: 0.8rem 0 0 0;
    }
  }
}

.rating-box {
  display: flex;
  align-items: center;
  font-size: 1.2rem;
  font-weight: 500;
  color: $light-color;
  background-color: $green-grey;
  border-radius: 2.4rem;
  padding: 0.5rem 0.7rem;

  &__icon {
    margin-right: 0.4rem;
  }
}

.chips-container__brands-box {
  margin-right: 0.8rem;
}

.chips-container__brands-icon {
  display: block;
  border-radius: 50%;
}
