.share-modal {
  &__description {
    font-size: 1.6rem;
    line-height: 1.9rem;
    color: $profile-month;
    margin-top: 3rem;
  }

  .form {
    margin-top: 3rem;
  }

  &__action {
    margin-left: 1.2rem;
  }

  @media screen and (max-width: 650px) {
    .form-field--wrapper__row .form-field--label {
      max-width: 8rem;
    }
  }

  @include mobile {
    &__action {
      margin: 3.2rem 0 0;
    }
  }
}

.share-modal-footer {
  @include footer-with-border-left-btn(0 0 1rem 0, 4.6rem 0 0);
  border: none;

  &__btn {
    @include indent-not-last-of-type(0 1.2rem 0 0);
  }
}
