.edit-folder-modal {
  &__btn {
    height: 4rem;
    padding: 0 2rem;
    @include indent-not-last-of-type(0 1.2rem 0 0);
  }

  &__checkbox {
    display: flex;

    .form-field__label {
      width: 100%;
      max-width: 14rem;
      margin-right: 2rem;
      flex-shrink: 0;
    }
  }

  .seaz-modal__btn_box {
    @include d-flex-center-content;
    align-items: center;
  }

  &__action {
    @include d-align-items-center;
    justify-content: flex-end;
    flex-grow: 1;
  }

  @media screen and (max-width: 650px) {
    .form-field--wrapper__row .form-field--label {
      max-width: 14rem;
    }
  }

  @include mobile {
    &__checkbox {
      .form-field__label {
        max-width: 10rem;
      }
    }
  }
}

.document-preview {
  .document-preview-row__text {
    word-break: break-word;
  }

  &__footer {
    margin-top: 3rem;
    padding-bottom: 1rem;
  }

  &__btn {
    @include indent-not-last-of-type(0 1.2rem 0 0);
  }

  @include mobile {
    &__btn {
      width: 100%;
      @include indent-not-last-of-type(0 0 1.2rem 0);
    }
  }
}

.crew-document-preview {
  padding: 5rem 0;
  position: relative;
  @include desktop {
    .seaz-modal__document_row {
      width: 100%;
    }
  }
}

.document-preview-title {
  padding-right: 2rem;
  word-break: break-word;
}

.document-top-indent {
  margin-top: 3rem;
}

.document-preview-row {
  display: flex;
  align-items: flex-start;

  @include indent-not-last-of-type(0 0 1.6rem 0);

  &__title {
    flex-shrink: 0;
    width: 16rem;
    margin-right: 1.6rem;
    line-height: 1.6rem;
    color: $seaz-text;
  }

  &__text {
    font-weight: 700;
    line-height: 2rem;
    color: $seaz-text;
    word-break: break-word;

    .seaz-text-medium {
      font-size: 1.4rem;
      font-weight: 700;
      line-height: 2rem;
    }

    /* TODO: check */
    .seaz-alarm {
      color: $seaz-text;
    }

    &--date {
      &.seaz-alarm {
        color: $seaz-alarm;
      }
    }
  }

  @include tablet {
    flex-direction: column;

    &__title {
      width: 100%;
      margin: 0 0 1.6rem;
    }
  }
}

.document-preview-form {
  .form__checkbox-label-text {
    color: $seaz-text;
  }

  .seaz-uploader__document_label {
    margin-right: 2rem;
  }

  @include tablet {
    .form-field--wrapper__row {
      flex-direction: column;
    }

    .seaz-uploader__document {
      justify-content: space-between;
    }

    .seaz-uploader__document_file_box {
      width: auto;
    }

    .seaz-uploader__document_label {
      margin-right: 0;
    }
  }

  @include mobile {
    .form__checkbox-label-text {
      padding-left: 0;
    }
  }
}

.documents-version {
  &__list {
    margin-top: 3rem;
  }

  &__footer {
    @include footer-with-border-left-btn(0 0 2rem, 3rem 0 0);
    border: none;
  }
}

.documents-version-item-box {
  &:nth-of-type(2n + 1) {
    .documents-version-item {
      background-color: $seaz-bg;
    }
  }
}

.documents-version-item {
  display: grid;
  grid-template-columns: 1fr 1fr 5.3rem;
  grid-template-areas: 'name versions actions';
  align-items: center;
  grid-gap: 0 0.8rem;
  padding: 2.4rem 0.8rem;
  border-radius: 0.8rem;

  &__text {
    line-height: 1.6rem;
    color: $seaz-text;
  }

  &__btn {
    @include indent-not-last-of-type(0 1rem 0 0);
  }

  @include mobile {
    grid-template-columns: 1fr 5.3rem;
    grid-template-areas:
      'name actions'
      'versions actions';
    grid-gap: 1rem;
  }
}

.documents-version-name {
  grid-area: name;
}

.documents-version-versions {
  grid-area: versions;
}

.documents-version-action {
  grid-area: actions;
}

.documents-version-action {
  @include d-flex-space-between;
  align-items: center;
}
