.date-range-control {
  padding: 1.6rem;

  &__switch-tabs {
    margin-bottom: 1.4rem;
  }

  &__button {
    display: block;
    width: 100%;
    font-family: $base-font;
    font-size: 1.2rem;
    font-weight: 500;
    letter-spacing: 0.1em;
    text-transform: uppercase;
    color: $clr-green;
    padding: 0.8rem;
    border-radius: 0.4rem;
    transition: opacity 0.3s;

    &:not(:last-of-type) {
      margin-bottom: 1rem;
    }

    &:hover {
      opacity: 0.7;
    }
    &:not(:disabled) {
      border: 1px solid $clr-green;
    }
    &--apply:not(:disabled) {
      background-color: $clr-green;
      color: $light-color;
    }
    &:disabled {
      background-color: $clr-grey;
      color: $light-color;
    }
  }

  @include mobile {
    padding: 1.3rem;
  }
}

.dropdown-options-container.date-range-control {
  min-width: 32rem;
  max-height: 100%;
  border-radius: 1.2rem;

  @include mobile {
    min-width: 26rem;
  }
}
