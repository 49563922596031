.seaz-charter-info-card-alternate {
  color: #351f73;
  margin-bottom: 1.6rem;

  &__date {
    font-size: 1rem;
    letter-spacing: 0.5px;
    text-transform: uppercase;
  }

  &__container {
    padding: 0.8rem 1.6rem;
    margin-top: 0.8rem;
    background-color: #fafafb;
    border-radius: 0.4rem;
  }

  &__services {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  &__services-box {
    display: flex;
    flex-wrap: wrap;

    .seaz-charter__list_card_head_error_box {
      position: relative;
      display: flex;
      justify-content: center;
      align-items: center;
    }

    .seaz-charter__list_card_head_error {
      padding: 0;
    }
  }

  &__action-btn {
    min-width: 6rem;
    text-align: right;

    .boat-calendar-card-event__button-edit {
      margin-right: 2.3rem;
    }

    @include tablet {
      .boat-calendar-card-event__button-edit {
        margin-right: 1rem;
      }
    }
  }

  &__services-badge {
    font-size: 1.2rem;
    font-weight: 500;
    text-transform: uppercase;
    padding: 0.7rem 1.2rem 0.5rem;
    border-radius: 2.4rem;
    color: #fff;
    background-color: $clr-primary-and-accent;

    &:not(:last-child) {
      margin-right: 0.4rem;
    }
  }

  &__services-btn {
    &--icon {
      transform: rotateZ(180deg);
    }
  }

  &__title-box {
    margin-top: 0.4rem;

    &--title {
      font-size: 1.6rem;
      font-weight: 500;
    }

    .seaz-charter__list_card_head_name {
      padding-bottom: 0;
    }
  }

  &__charter-info {
    display: flex;
    margin-top: 1.2rem;

    &--title {
      font-size: 1rem;
      letter-spacing: 0.5px;
      text-transform: uppercase;
      white-space: nowrap;
      color: #828282;
      margin-bottom: 0.4rem;
    }

    &--val {
      font-size: 1.6rem;
      font-weight: 500;
    }
  }

  &__charter-info-item {
    &:not(:last-of-type) {
      margin-right: 3rem;
    }
  }

  &__button-box {
    margin-top: 1.2rem;

    .seaz-charter-info-card-alternate__button,
    .seaz-charter__btn_primary--plus {
      justify-content: center;
    }
  }

  &__button {
    width: 100%;
    transition: opacity 0.3s;

    &:not(:last-of-type) {
      margin-bottom: 1rem;
    }

    &:hover {
      @include hover-opacity(0.8);
    }
  }

  @media screen and (max-width: 480px) {
    &__services-badge {
      font-size: 1rem;
      margin-bottom: 0.5rem;
    }

    .seaz-charter__list_card_head_error {
      margin-bottom: 0.5rem;
    }
  }
}
