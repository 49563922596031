.job-search-list {
  position: relative;
  margin: 4.8rem 4.8rem 7rem;
  display: grid;
  grid-template-columns: 32.2rem 1fr 32.2rem;
  grid-template-rows: auto 1fr auto;
  grid-template-areas:
    'banner-wrap-left card-1 banner-wrap-right'
    'banner-wrap-left card-2 banner-wrap-right'
    'banner-wrap-left card-3 banner-wrap-right'
    'banner-wrap-left card-4 banner-wrap-right'
    'banner-wrap-left card-5 banner-wrap-right'
    'pagination-box pagination-box pagination-box';
  grid-gap: 0 3rem;

  @media screen and (max-width: 1700px) {
    grid-template-columns: 23rem 1fr 23rem;
    grid-gap: 0 1rem;
    margin: 4.8rem 1rem 7rem;
  }

  @media screen and (max-width: 1300px) {
    display: block;
  }

  @include tablet {
    margin: 1.6rem 1rem 5rem;
  }
}

.common-job-search-card {
  width: 100%;

  &:not(:last-of-type) {
    margin-bottom: 3.5rem;
  }

  .search-card__info-person {
    width: 16rem;
    height: 16rem;
  }

  .seaz-search__card_avatar {
    border-radius: 1.6rem;
  }

  .job-offer-id-box {
    @include d-flex-center-content;
    font-size: 1.2rem;
    color: $clr-light-grey;
    margin-top: 1.7rem;

    .job-offer-id-box__number {
      @include d-align-items-center;
      font-weight: 500;
      letter-spacing: 0.1em;
      text-transform: uppercase;
      margin-left: 0.8rem;
      padding: 0.5rem;
      background-color: $clr-ashy-grey;
      border-radius: 2.4rem;
    }
  }

  .search-card__person {
    padding: 2.4rem;
  }

  &__greeny {
    @include d-align-items-center;
    flex-wrap: wrap;
  }

  //.seaz-greeny__available {
  //  margin-left: 1rem;
  //}

  &__types {
    margin: 0 1rem 0 0;
  }

  .seaz-search__card_location_row {
    padding-top: 0;
  }

  .small-relevant {
    margin-right: 1.2rem;
  }

  .seaz-btn-primary-small-relevant.active {
    background-color: $seaz-alarm;
  }

  .seaz-btn-primary-small-favorite.active {
    background-color: $seaz-edit-hover;
  }

  .search-card__footer {
    margin-top: 0;
  }

  @include tablet {
    &:not(:last-of-type) {
      margin-bottom: 1.6rem;
    }

    .search-card__person {
      grid-gap: 3rem 1.6rem;
    }

    .search-card__info-person {
      width: 8.8rem;
      height: 8.8rem;
    }

    .job-offer-id-box {
      flex-direction: column;
    }

    .info-crew__row {
      align-items: flex-start;
    }

    .info-crew__title {
      flex-shrink: 0;
      width: 17.8rem;
      margin-right: 1rem;
    }

    .seaz-search__card_location_col {
      width: 100%;
      flex-direction: row;
      padding-top: 0;

      app-seazone-icon {
        display: none;
      }
    }

    .crew-search-card__footer-btn-group:last-of-type {
      width: auto;
      margin-top: 0;
    }
  }

  @include mobile {
    .info-crew__title {
      flex-shrink: 0;
      width: 11.4rem;
      margin-right: 0.4rem;
    }

    .search-card__footer {
      flex-wrap: wrap;
    }

    .small-relevant:last-of-type {
      margin-right: 0;
    }

    .crew-search-card__footer-btn-group:last-of-type {
      width: 100%;
      margin-top: 1.2rem;

      .seaz__btn_rounded {
        width: 100%;
      }
    }
  }
}

.banner-wrap-left {
  grid-area: banner-wrap-left;
}

.banner-wrap-right {
  grid-area: banner-wrap-right;
}

.pagination-box {
  grid-area: pagination-box;
}
