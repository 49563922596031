.fleet-calendar-list {
  margin-top: 1.6rem;
}

.fleet-calendar-item {
  @include d-flex-space-between;
  align-items: center;
  box-shadow: 0 2px 2px rgba(43, 28, 54, 0.16);
  border-radius: 2.4rem;
  background-color: $light-color;
  padding: 2.4rem;

  @include indent-not-last-of-type(0 0 1.6rem 0);

  .info-card-wrap {
    align-items: center;
    width: 100%;
    max-width: 40.1rem;
    min-width: 40.1rem;
  }

  .calendar-days {
    width: 100%;
    max-width: 74rem;
  }

  .calendar-cell {
    min-height: 8rem;
    cursor: pointer;
    @media screen and (max-width: 768px) {
      min-height: 6rem;
    }
  }

  @include laptop {
    flex-direction: column;
    align-items: flex-start;

    .info-card-wrap {
      align-items: center;
      width: 100%;
      max-width: 100%;
      min-width: 100%;
      margin-bottom: 1.2rem;
    }

    .calendar-days {
      width: 100%;
      max-width: 100%;
    }
  }
}
