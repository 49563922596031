.download-crew-guests {
  height: calc(100% - 3rem);
  padding: 3rem 3rem 0;

  @include tablet {
    padding: 1.6rem 1.6rem 0;
  }
}

.download-crew-guests-form {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: calc(100% - 6rem);
  box-sizing: border-box;
  margin-top: 3rem;
}

.download-crew-guests-form-select-group {
  .select-group__title {
    display: flex;
    align-items: center;
    min-height: 4rem;
    font:
      1.6rem/1.9rem 'Roboto',
      sans-serif;
    padding: 0 2.4rem;
    color: $clr-light-grey;
  }

  .app-seazone-select-option {
    color: $seaz-dark-color-text;
  }
}

.download-crew-guests-lists {
  margin-top: 4.6rem;

  &__col {
    display: block;

    &:first-child {
      margin-bottom: 3.5rem;
    }
  }

  .counter {
    line-height: 1.6rem;
    color: $clr-light-grey;
    margin-top: 0.4rem;
  }

  .checkbox-group {
    margin-top: 2.3rem;

    .checkbox-group-text,
    .form__checkbox-label-text {
      font-weight: 400;
      font-size: 1.4rem;
      line-height: 1.6rem;
      color: $seaz-text;
    }

    .form__checkbox-label-text {
      padding-left: 1.1rem;
    }

    .checkbox-group-text {
      @include cut-text-add-dots;
      margin-right: 1.2rem;

      &--position {
        flex-shrink: 0;
        color: $profile-month;
      }
    }

    .checkbox-group-item {
      @include indent-not-last-of-type(0 0 2.2rem 0);

      .form__checkbox-label-text {
        display: flex;
        justify-content: space-between;
        align-items: center;
        width: 100%;
      }
    }

    @include mobile {
      .checkbox-group-item {
        .form__checkbox-label-text {
          width: 92%;
        }
      }
    }
  }

  .checkbox-all {
    padding-bottom: 2.2rem;
  }

  @include tablet {
    .checkbox-group-text {
      width: 100%;
      max-width: 32.4rem;
    }
  }

  @include mobile {
    .form__checkbox-label {
      padding: 0;
    }
  }
}

.charter-download-guests-list-header {
  @include d-flex-space-between;
  align-items: center;
  margin-bottom: 1.4rem;
}

.download-crew-guests-footer {
  @include footer-with-border-left-btn(0 0 3rem 0, 3.3rem 0 0 0);
  border: none;

  &__btn {
    height: 4.8rem;
    @include indent-not-last-of-type(0 1.2rem 0 0);
  }

  @include tablet {
    flex-direction: column;
    @include footer-with-border-left-btn(0 0 1.6rem 0, 2rem 0 0 0);

    &__btn {
      width: 100%;
      @include indent-not-last-of-type(0 0 1.2rem 0);
    }
  }
}

.charter-checkbox-group-alternate {
  max-height: 38rem;
  overflow-y: auto;
  @include custom-scroll-bar;
  padding: 0 1.2rem 0 0;
}
