.invite {
  margin: 4.8rem auto 11.6rem;
  padding: 0 1.2rem;

  &__title {
    font-size: 3rem;
  }

  &__box {
    display: block;
    padding: 3.2rem;
    background-color: $light-color;
    border-radius: 1.2rem;
    margin-bottom: 2.8rem;
  }

  @include tablet {
    margin: 1.2rem auto 4.8rem;

    &__box {
      padding: 1.6rem;
      margin-bottom: 0.8rem;
    }

    &__referral {
      margin-top: 1.2rem;
    }
  }
}
