$track-height: 0.2rem;
$track-color: $clr-purple;

$thumb-size: 1.6rem;
$thumb-color: $clr-purple;
$thumb-border-color: #fafafb;

.seazone-range-slider {
  &__wrapper {
    &[type='range'] {
    }
  }
  [type='range'] {
    -webkit-appearance: none;
    margin: 18px 0;
    width: 100%;
    border: none;
    height: $track-height;
    &:focus {
      outline: none;
      border: none;
    }
    &::-webkit-slider-runnable-track {
      width: 100%;
      height: $track-height;
      cursor: pointer;
      //background: $track-color;
      //background-color: yellow;
    }
    &::-webkit-slider-thumb {
      height: $thumb-size;
      width: $thumb-size;
      border-radius: 50%;
      background: $thumb-color;
      cursor: pointer;
      -webkit-appearance: none;
      margin-top: -7px;
      border: 0.2rem solid $thumb-border-color;
    }
    &:focus::-webkit-slider-runnable-track {
      //background: $track-color;
      //background: yellow;
    }
    &::-moz-range-track {
      width: 100%;
      height: $track-height;
      cursor: pointer;
      //background: $track-color;
    }
    &::-moz-range-thumb {
      height: $thumb-size;
      width: $thumb-size;
      border-radius: 50%;
      background: $thumb-color;
      border: 0.2rem solid $thumb-border-color;
      cursor: pointer;
    }
    &::-ms-track {
      width: 100%;
      height: $track-height;
      cursor: pointer;
      background: transparent;
      border-color: transparent;
      border-width: 3rem 0;
      color: transparent;
    }
    &::-ms-fill-lower {
      background: $track-color;
    }
    &::-ms-fill-upper {
      background: yellow;
    }
    &::-ms-thumb {
      height: $thumb-size;
      width: $thumb-size;
      border-radius: 50%;
      background: $thumb-color;
      border: 0.2rem solid $thumb-border-color;
      cursor: pointer;
    }
    &::-moz-range-progress {
      background-color: $track-color;
      height: $track-height;
    }
  }
}
