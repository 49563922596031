.seaz-user-downloads {
  width: 100%;
  max-width: 126.4rem;
  margin: 3.2rem auto 5.3rem;
  padding: 0 1rem;

  &__title {
    font-size: 3rem;
    font-weight: 500;
    text-transform: uppercase;
    line-height: 1.4rem;
    color: $clr-primary-and-accent;
  }

  &__list {
    background: $light-color;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.04);
    border-radius: 1.6rem;
    box-sizing: border-box;
    margin-top: 2.6rem;
    padding: 3.7rem 5.6rem;
    color: $seaz-text;
  }

  &__title-list,
  &__body-list {
    display: flex;
    align-items: center;
    min-height: 4.8rem;
  }

  &__title-item,
  &__body-item {
    width: 25%;
  }

  &__title-list {
    font-weight: 500;
    padding: 0 0 1.3rem;
    border-bottom: 1px solid rgba(53, 31, 115, 0.2);
  }

  &__body-list {
    border-radius: 0.8rem;

    &:nth-of-type(2n + 1) {
      background-color: $seaz-bg;
    }
  }

  &__body-item {
    &--date {
      color: $profile-month;
    }

    &--btn-box {
      text-align: right;
    }
  }

  &__body-item,
  &__title-item {
    padding: 0.7rem 0.8rem;
    word-break: break-all;
  }

  &__btn {
    font-size: 1.2rem;
    font-weight: 500;
    letter-spacing: 0.1rem;
    text-transform: uppercase;
    color: $light-color;
    padding: 0.8rem 1.6rem;
    background-color: $clr-primary-and-accent;
    border-radius: 0.4rem;
    transition: opacity 0.3s;

    &:hover {
      opacity: 0.6;
    }
  }

  .ready {
    color: $seaz-success;
  }

  .in-progress {
    color: $seaz-warning;
  }

  .failed {
    color: red;
  }

  @include laptop {
    &__list {
      padding: 2rem;
    }
  }

  @include tablet {
    &__list {
      padding: 1rem;
    }

    &__body-list {
      flex-wrap: wrap;
    }

    &__title-item,
    &__body-item {
      width: 33.3%;
      text-align: center;
    }

    &__body-item {
      font-size: 1.2rem;
    }

    &__body-item {
      &--btn-box {
        width: 100%;
      }
    }

    &__btn {
      width: 100%;
    }
  }
}
