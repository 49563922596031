.photo-box {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(31rem, 1fr));
  grid-gap: 2.4rem;

  &__container {
    //border-radius: 1.2rem;
    max-height: 22.7rem;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  &__img {
    width: 100%;
    height: 100%;
    object-fit: contain;
    border-radius: 1.2rem;
  }
}

.video-box {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(31rem, 1fr));
  grid-gap: 2.4rem;

  &__content {
    position: relative;
    border-radius: 1.2rem;
    height: 22.7rem;
  }

  &__video {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 1.2rem;
  }

  &__play {
    top: 50%;
    transform: translate(0, -50%);
    background: $clr-primary-and-accent;
  }
}
