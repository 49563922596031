.seaz-filters {
  width: 29.6rem;
  margin-right: 3.2rem;
  display: block;
  padding-top: 2rem;
  @media screen and (max-width: 1200px) {
    display: none;
  }
  &__open {
    display: none;
    @media screen and (max-width: 1200px) {
      position: relative;
      width: 49%;
      justify-content: center;
      display: flex;
    }
  }
  &__map_icon {
    display: none;
    @media screen and (max-width: 1200px) {
      background-color: $clr-white;
      height: 4rem;
      display: flex;
      align-items: center;
      justify-content: center;
      width: 49%;
      flex-direction: row-reverse;
      color: $seaz-edit-hover;
      text-transform: uppercase;
      font-size: 1.2rem;
      font-weight: 500;
      letter-spacing: 0.1em;
      app-seazone-icon {
        margin-right: 0.6rem;
      }
    }
  }
  &__close {
    display: flex;
    margin-left: 1.6rem;
  }
  &__badges {
    &_row {
      display: none;
      @media screen and (max-width: 1200px) {
        display: flex;
        flex-shrink: 0;
      }
      &_box {
        @media screen and (max-width: 1300px) {
          display: flex;
          overflow: auto;
        }
      }
    }
    &_item {
      @media screen and (max-width: 1300px) {
        &:not(:last-of-type) {
          margin-right: 0.8rem;
        }
        background-color: $clr-white;
        padding: 0.5rem;
        color: $seaz-text;
        font-size: 1.2rem;
        text-align: center;
      }
      @media screen and (max-width: 700px) {
        margin-right: 0.8rem;
      }
      @include mobile {
        background-color: $seaz-logo-border;
      }
    }
  }
  &__head {
    &_top {
      display: flex;
      justify-content: center;
      align-items: flex-start;
      border-bottom: 1px solid $seaz-head-arrow;
      padding: 2.4rem;
      flex-direction: column;
      &_box {
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: space-between;
      }
    }
    &_title {
      color: $clr-primary-and-accent;
      margin-right: 1.2rem;
    }
    &--right {
      display: flex;
    }
    &_choose_job {
      width: 100%;
      padding-top: 1.2rem;
      .form-field--content-outline.form-field--content {
        border-color: $seaz-head-arrow;
      }
    }
    &_filters {
      padding: 2.4rem;
      border-bottom: 1px solid $seaz-head-arrow;

      .financial-data__badge_reset {
        display: block;
        color: $seaz-edit-hover;
        font-weight: 500;
        font-size: 1.4rem;
        border-bottom: 1px dashed $seaz-edit-hover;
      }
      .financial-data__badge {
        margin: 0 0.8rem 0.8rem 0;
        padding: 5px;
        border: none;
        border-radius: 0;
        flex-direction: row-reverse;
        text-transform: none;
        display: inline-flex;
        color: $seaz-text;
        font-size: 1.2rem;
        font-weight: 400;
        background-color: $seaz-bg;
        .icon-close {
          &:before {
            margin-left: 0;
            margin-right: 0.7rem;
            color: $seaz-text;
          }
        }
      }
    }
  }

  &__select-box {
    padding: 2.4rem;
  }

  &__main-content {
    height: 80vh;
    overflow-y: auto;

    @media screen and (max-height: 550px) {
      height: 65vh;
    }

    @include custom-scroll-bar;
  }

  &__form {
    .form__group {
      padding: 2.4rem;
      &:not(:last-of-type) {
        border-bottom: 1px solid $seaz-head-arrow;
      }
      .form-field--content.form-field--content-outline {
        border-color: $seaz-head-arrow;
      }
    }
    app-seazone-checkbox {
      .form__checkbox {
        margin-bottom: 1.6rem;
      }
      .form__checkbox-label-text {
        padding-left: 0.8rem;
        font-size: 1.4rem;
        color: $seaz-text;
        line-height: 2rem;
      }
      .form__checkbox-label {
        width: 2.4rem;
        height: 2.4rem;
      }
      .form__checkbox-label:before {
        width: 2.4rem;
        height: 2.4rem;
        border-radius: 0;
        border: 1.5px solid $seaz-border;
      }
      .form__checkbox-input:checked + label:before {
        background-color: $seaz-mulberry;
        border-color: $seaz-mulberry;
      }
    }
    &_label {
      padding-bottom: 1.8rem;
      color: $seaz-text;
      font-weight: 500;
      display: block;
      text-transform: capitalize;
    }
    &_row {
      padding-bottom: 2.6rem;
    }
    &_availability {
      &.form__group {
        padding: 0;
      }
      app-seazone-form-field {
        .form-field--wrapper__row.seazone-form-field--wrapper .form-field--label {
          display: none;
        }
        app-seazone-icon {
          width: 16px;
          height: 13px;
        }
      }
    }
    .form__radio {
      padding-bottom: 0.6rem;
    }
    app-seazone-radio-item {
      .form__radio-label {
        height: 2.8rem;
      }
      .form__radio-label:before {
        width: 2.4rem;
        height: 2.4rem;
        border: 1.5px solid $seaz-border;
      }
      .form__radio-input:checked + label:after {
        width: 12px;
        height: 12px;
        left: 6px;
        top: 6px;
        background-color: $seaz-mulberry;
      }
      .form__radio-input:checked + label:before {
        border-color: $seaz-mulberry;
      }
      .form__radio-label-text {
        margin-left: 0.8rem;
        color: $seaz-text;
        font-size: 1.4rem;
      }
    }
    app-seazone-range-slider {
      padding: 0;
      .seazone-range-slider .seazone-range-slider__wrapper {
        .seazone-range-slider__ticks-container {
          height: 5px;
        }
      }
      .seazone-range-slider__track-background {
        height: 5px;
        border-radius: 4px;
        background-color: $seaz-head-arrow;
      }

      app-seazone-form-field {
        .form-field--wrapper.form-field--wrapper__row {
          .form-field--label {
            margin-right: 1.6rem;
            width: auto;
            text-transform: lowercase;
          }
        }
      }
    }
    &_expected {
      &.form__group {
        padding: 0;
        app-seazone-range-slider {
          @media screen and (max-width: 1200px) {
            width: 100%;
            .seazone-range-slider__inputs {
              width: 100%;
              .seazone-range-slider__row {
                width: 100%;
              }
            }
          }
          @include mobile {
            .form__col {
              margin-bottom: 0;
            }
            .form-field--wrapper__row {
              flex-direction: row;
              .form-field--label {
                margin-bottom: 0;
              }
            }
            .seazone-range-slider__row {
              flex-direction: row;
            }
          }
        }
      }
      .form__row {
        margin-bottom: 0.8rem;
      }
      .seazone-range-slider__inputs {
        .form__col:not(:last-child) {
          margin-right: 1.6rem;
        }
        .form-field--content {
          // width: 7.9rem;
          &.disabled {
            background-color: $clr-white;
            opacity: 0.3;
            .seazone__form-input:disabled {
              background-color: $clr-white;
            }
          }
        }
      }
    }
    &_skills {
      .financial-data__form {
        height: 50.4rem;
        overflow-y: scroll;
        margin-top: 2.4rem;
        box-sizing: border-box;
        @include custom-scroll-bar;
      }
      .single-checkbox {
        .form__checkbox-label {
          display: none;
        }
        .form__checkbox-label-text {
          font-size: 1.4rem;
          color: $seaz-document-count;
          padding: 0;
        }
      }
    }
    &_range {
      .seazone-range-slider__inputs {
        margin-bottom: 3.3rem;
        .form-field--content-outline {
          width: 5.4rem;
        }
        .seazone-range-slider__row {
          margin-bottom: 0;
        }
      }
      .form__row {
        margin-bottom: 0;
        &:last-of-type {
          display: flex;
          align-items: center;
          margin-left: 1.6rem;
          @include mobile {
            justify-content: center;
          }
        }
      }
    }
  }
  &__cards {
    width: 100%;
    .seaz-filters__row_search {
      margin-right: 2.4rem;
      width: 50%;
      @media screen and (max-width: 1200px) {
        width: 100%;
        margin-right: 0;
        // padding-right: 20rem;
        margin-top: 1rem;
      }
    }
    .seaz-filters__row_select {
      width: 50%;
      margin-right: 0;
      @media screen and (max-width: 1200px) {
        display: flex;
        width: 100%;
        margin-top: 1.2rem;
      }
    }
    .seaz-filters__row {
      align-items: center;
      @media screen and (max-width: 1200px) {
        width: 100%;
        margin-bottom: 1rem;
      }
      @media screen and (max-width: 600px) {
        padding-right: 0;
      }
      &.seaz-filters__row--bottom {
        @media screen and (max-width: 1600px) {
          flex-direction: column;
        }
      }
      .seaz-filter__row--left {
        display: flex;
        align-self: center;
        width: 72%;
        @media screen and (max-width: 1600px) {
          width: 100%;
        }
        @media screen and (max-width: 1200px) {
          flex-direction: column;
        }
        .distance {
          app-seazone-range-slider {
            @media screen and (max-width: 1200px) {
              width: 100%;
            }
            .form-field--label {
              @media screen and (max-width: 600px) {
                display: none;
              }
            }
          }
        }
      }
      .seaz-filter__row--right {
        display: flex;
        align-self: center;
        .form__checkbox-label-text {
          white-space: nowrap;
        }
        @media screen and (max-width: 1600px) {
          width: 100%;
          padding-top: 2rem;
          .form__checkbox {
            margin-left: 0;
          }
          app-seazone-checkbox:not(:first-of-type) {
            .form__checkbox {
              margin-left: 2.4rem;
            }
          }
        }
      }
      .location {
        width: 45%;
        @media screen and (max-width: 1600px) {
          width: 100%;
        }
      }
      .distance {
        app-seazone-range-slider {
          flex-direction: row;
          width: auto;
          align-items: center;
        }
        .seazone-range-slider__inputs {
          .form-field--label {
            width: auto;
            margin-right: 0;
            margin-left: 1.6rem;
          }
          .form-field--wrapper__row {
            flex-direction: row-reverse;
            .form-field--content-outline {
              height: 4rem;
              width: 7.3rem;
              border-radius: 0;
              border-color: $seaz-head-arrow;
              position: relative;
              .seazone__form-input {
                text-align: center;
              }
              .form__block-error {
                bottom: -2rem;
                top: inherit;
                white-space: nowrap;
              }
            }
          }
          .seazone-range-slider__row {
            margin-bottom: 0;
            .form__col {
              width: 100%;
            }
          }
        }
      }
      .seazone-range-slider {
        margin-right: 1.6rem;
        @media screen and (max-width: 1300px) {
          min-width: 80px;
        }
      }
      .form__checkbox {
        margin-left: 2.4rem;
        .form__checkbox-label-text {
          padding-left: 0.8rem;
          color: $seaz-text;
          font-size: 1.4rem;
        }
        .form__checkbox-label {
          height: 2.4rem;
          width: 2.4rem;
          &:before {
            height: 2.4rem;
            width: 2.4rem;
            border-radius: 0;
            border-color: $seaz-mulberry;
          }
        }
        .form__checkbox-input:checked + label:before {
          background-color: $seaz-mulberry;
        }
      }
    }
    &_job {
      .seaz-filters__row_select {
        @media screen and (max-width: 1300px) {
          margin-top: 1.2rem;
          width: 100%;
        }
      }
      .seaz-filters__row_search {
        @media screen and (max-width: 1300px) {
          width: 100%;
          margin-right: 0;
          padding-right: 0;
        }
      }
      .seaz-job__main_filters_mobile {
        display: flex;
        padding: 0 1.2rem;
      }
      .seaz-filters__row {
        @media screen and (max-width: 1300px) {
          flex-direction: column;
          padding-right: 1rem;
        }
        .location,
        .distance,
        .form__checkbox {
          @media screen and (max-width: 1300px) {
            display: none;
          }
        }
      }
    }
  }
  &__row {
    margin-bottom: 3.2rem;
    display: flex;
    padding-right: 3.2rem;
    @media screen and (max-width: 1200px) {
      flex-direction: column;
      padding-right: 0;
      margin-bottom: 1rem;
    }
    &_search {
      position: relative;
      display: flex;
      width: 17%;
      margin-right: 3.1rem;
      @media screen and (max-width: 1500px) {
        width: 19%;
      }
      @media screen and (max-width: 1200px) {
        width: calc(100% - 20.5rem);
      }
      @media screen and (max-width: 600px) {
        width: 100%;
      }

      app-seazone-input .form-field--content-outline {
        height: 4rem;
      }

      app-seazone-autocomplete-input {
        .form-field--content-outline {
          border: 0.1rem solid $seaz-head-arrow;
          height: 4rem;
        }
        .form-field--content {
          border-radius: 0;
        }
        .seazone__form-input {
          font-size: 1.4rem;
          width: 92%;
          padding-right: 3rem;
        }
      }
      &-icon {
        width: 4rem;
        height: 4rem;
        background-color: $clr-primary-and-accent;
        //position: absolute;
        //top: 0;
        //right: 0;
        margin-left: -0.1rem;
        cursor: pointer;
        z-index: 2;
      }
      &-close {
        position: absolute;
        right: 1rem;
        cursor: pointer;
      }

      &--sorted {
        width: 38.4rem;

        @media screen and (max-width: 1500px) {
          width: 38.4rem;
        }
        @media screen and (max-width: 1200px) {
          width: 38.4rem;
        }

        @media screen and (max-width: 480px) {
          width: 100%;
          margin-right: 0;
          margin-bottom: 1.2rem;
        }
      }
    }
    &_select {
      width: 12%;
      margin-right: 2.4rem;
      @media screen and (max-width: 1500px) {
        width: 16%;
      }
      @media screen and (max-width: 1200px) {
        display: none;
      }
      @media screen and (max-width: 600px) {
        display: flex;
        width: 100%;
        margin-top: 1.2rem;
      }
      app-seazone-select {
        .form-field--content-outline {
          height: 4rem;
          border: 0.1rem solid $seaz-head-arrow;
        }
        .form-field--content {
          border-radius: 0;
          padding: 0.5rem 1.2rem 0.8rem 1.2rem;
        }
        .select-placeholder {
          color: #000000;
        }
      }
    }
    .location {
      width: 35.5%;
      margin-right: 2.4rem;
      @media screen and (max-width: 1500px) {
        width: 21.5%;
      }
      app-location-autocomplete {
        .form-field--content-outline {
          height: 4rem;
          border: 0.1rem solid $seaz-head-arrow;
        }
        .form-field--content {
          border-radius: 0;
        }
      }
    }
    .distance {
      display: flex;
      align-items: center;
      border-left: 1px solid $seaz-head-arrow;
      height: 4rem;
      padding-left: 2.4rem;
      @media screen and (max-width: 1200px) {
        padding-left: 0;
        border-left: none;
        padding-top: 2rem;
      }
      app-seazone-range-slider {
        padding: 0;
        width: 16.8rem;
        .seazone-range-slider__track-background {
          border-radius: 2rem;
          height: 0.3rem;
        }
        .seazone-range-slider__ticks-container {
          border-radius: 2rem;
        }
      }
      &_text {
        margin-right: 1.6rem;
        font-size: 1.4rem;
        color: $seaz-text;
      }
      &_km {
        background-color: $clr-white;
        border: 1px solid $seaz-head-arrow;
        padding: 1rem 1.6rem;
        margin-left: 2rem;
        span {
          color: $seaz-text;
          font-size: 1.4rem;
        }
      }
    }
  }
}

.seaz-filters__form {
  .form__block-error {
    white-space: normal;
    width: 90px;
    padding-right: 5px;
  }
  .seazone-range-slider__row {
    margin-bottom: 2.8rem;
    @include mobile {
      margin-bottom: 3rem;
    }
  }
}

.seaz-search__boat {
  .boat__card-wrap {
    width: 126.4rem;
    margin: 0 auto;
    padding-top: 3.2rem;

    @media screen and (max-width: 1300px) {
      width: 100rem;
    }
    @media screen and (max-width: 1024px) {
      width: 100%;
      padding: 3.2rem 2rem 0;
    }
    @include mobile {
      padding: 1.2rem 1.6rem;
    }
  }
  &.crew-search-all .boat__card-wrap,
  &.supplier-search .boat__card-wrap {
    width: 100%;
    padding-left: 4.8rem;
    padding-right: 4.8rem;
    @include laptop {
      padding-left: 2rem;
      padding-right: 2rem;
    }

    @include mobile {
      padding-left: 1.2rem;
      padding-right: 1.2rem;
    }
  }
}

.seaz-search {
  &__wrapper {
    position: relative;
    padding: 0 0 1rem;
    background-color: $clr-bg;

    .seaz-search-crew-filters {
      border-top: 1px solid $seaz-border;
      @media screen and (max-width: 480px) {
        border-top: none;
        padding-top: 0;
      }
    }
    .job-offer__header {
      @media screen and (max-width: 480px) {
        margin-bottom: 1rem;
      }
    }

    .profile {
      justify-content: space-between;

      @media screen and (max-width: 1024px) {
        flex-direction: row;
      }

      @media screen and (max-width: 768px) {
        flex-direction: column;
      }
    }
    //
    //.job-offer__main_navigation,
    //.seaz-search__boat .boat__card-wrap {
    //  width: 100%;
    //}
    @media screen and (max-width: 480px) {
      .job-offer__main_navigation {
        border-bottom: none;
      }
    }
    .seaz-profile__navigation_list {
      margin: 0;
    }
  }
  .job-offer__main_navigation {
    @media screen and (max-width: 1200px) {
      // width: 100%;
      padding: 2.4rem 0;
      .job-offer__main_navigation_item {
        font-size: 2.2rem;
      }
    }

    @media screen and (max-width: 480px) {
      width: 100%;
    }
  }
  .seaz-search__boat .boat__card-wrap {
    .profile__wrap-img.avatar {
      border-radius: 16px;
      background-color: $seaz-head-arrow;
      width: 10rem;
      height: 10rem;
      border: none;
      @include mobile {
        width: 5.6rem;
        height: 5.6rem;
        border-radius: 8px;
      }
    }
  }
  &__content {
    display: flex;
    justify-content: space-between;
    height: 100%;
    position: relative;
    &_box {
      display: flex;
      flex-direction: column;
      width: 100%;
    }
  }
  &__main_content {
    display: flex;
    margin-bottom: 7rem;
    min-height: calc(100vh - 4.9rem);
    @media screen and (max-width: 1200px) {
      padding: 0 1.6rem;
      position: relative;
      flex-direction: column;
      height: auto;
    }
    .seaz-search__card_info_col {
      display: flex;
      flex-direction: column;
      align-items: center;
      margin-right: 2rem;
    }
    .seaz-search__card_photo {
      margin-right: 0;
    }
    .seaz-search__card_info {
      @media screen and (max-width: 600px) {
        padding-bottom: 15.8rem;
      }
    }
    .seaz-search__card_head .header__controls {
      bottom: 6.4rem;
    }
  }
  &__main_filter_col--mobile {
    @media screen and (max-width: 1200px) {
      display: flex;
      flex-direction: column;
    }
  }
  &__main_filter_btns {
    @media screen and (max-width: 1200px) {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-bottom: 1.2rem;
    }
  }
  &__card {
    background-color: $clr-white;
    margin-bottom: 2.4rem;
    margin-right: 3.2rem;
    border: 1px solid $seaz-border;
    @media screen and (max-width: 1200px) {
      margin-right: 0;
      position: relative;
    }

    @include mobile {
      border: none;
    }

    &_head {
      padding: 2.4rem;
      display: flex;
      justify-content: space-between;
      border-bottom: 1px solid $seaz-head-arrow;
      @media screen and (max-width: 1200px) {
        .header__controls {
          position: absolute;
          bottom: 2.4rem;
        }
      }
      @media screen and (max-width: 600px) {
        padding: 2.4rem 1.2rem;
        justify-content: flex-start;

        .header__controls {
          left: 50%;
          transform: translate(-50%, 0);
        }
      }

      @media screen and (max-width: 480px) {
        padding: 2.4rem 0;
      }

      &_info {
        display: flex;
        width: 100%;
      }
      &_badges {
        display: flex;
        margin-right: 1.5rem;

        @media screen and (max-width: 600px) {
          justify-content: center;
          width: 100%;
          margin-right: 0;
        }

        app-crew-cv-status {
          text-align: center;
          display: block;
          margin-left: 1rem;
          .seaz-cv__badges_status {
            font-size: 1.2rem;
            background-color: white;
            color: $seaz-edit-hover;
            border: 1px solid $seaz-edit-hover;
            @media screen and (max-width: 1200px) {
              font-size: 1.6rem;
              width: auto;
            }
          }
          @media screen and (max-width: 670px) {
            .seaz-cv__badges_status {
              padding: 0 1.2rem;
            }
          }
          @include mobile {
            .seaz-cv__badges_status {
              font-size: 1rem;
            }
          }
        }
      }
      &_status {
        display: flex;
        flex-direction: column;
        //width: 100%;
        margin-right: 0.4rem;

        .seaz-cv__badges_status_job {
          padding: 1rem 1.2rem 0.7rem;
          font-weight: 500;
          font-size: 1.6rem;
          letter-spacing: 0.1em;
          margin-right: 0.7rem;

          @media screen and (max-width: 600px) {
            width: 100%;
          }

          @include mobile {
            font-size: 1rem;
            padding: 0.7rem;
            //line-height: 2.5rem;
          }
        }
      }
      &_updates {
        padding: 0.8rem 0;
        color: $seaz-about-text;
        margin-right: 0.7rem;
        @media screen and (max-width: 1500px) {
          font-size: 1.2rem;
        }
      }
    }
    &_controls {
      button {
        width: 5rem;
        height: 5rem;
        position: relative;
        &:not(:last-child) {
          margin-right: 0.8rem;
        }
      }
      .seaz-cv__info_btns_play::before {
        right: 3px;
        border: 11px solid transparent;
        border-left: 17px solid $clr-white;
      }
      &--new {
        background-color: $seaz-success;
        border: 1px solid $seaz-bg;
        border-radius: 50%;
        width: 1rem;
        height: 1rem;
        display: block;
        position: absolute;
        top: 6px;
        right: 1px;
      }
    }
    &_matching {
      font-weight: bold;
      text-transform: uppercase;
      letter-spacing: 0.1em;
      color: $seaz-success;
      padding-top: 1rem;
    }
    &_list {
      width: 60%;
      flex-shrink: 0;
      display: flex;
      align-items: flex-start;
      flex-direction: column;
      padding-right: 3.2rem;
      @media screen and (max-width: 1365px) {
        width: 65%;
      }
      @media screen and (max-width: 1200px) {
        width: 100%;
        padding-right: 0;
      }
      app-crew-search-list {
        width: 100%;
      }
    }
    &_info {
      display: flex;
      justify-content: space-between;
      padding: 2.4rem;
      @media screen and (max-width: 1200px) {
        flex-direction: column;
        padding-bottom: 12.8rem;
      }

      @media screen and (max-width: 480px) {
        padding: 1.6rem 1rem;
      }

      &--left {
        display: flex;
        margin-right: 1rem;
        @media screen and (max-width: 600px) {
          flex-direction: column;
          align-items: center;
          margin-right: 0;
        }
      }
      &_head {
        &_row {
          display: flex;
          flex-wrap: wrap;
          align-items: baseline;
          padding: 0 2rem 1.2rem 0;

          @media screen and (max-width: 961px) {
            align-items: flex-start;
            justify-content: flex-start;
            padding: 0;
          }

          @media screen and (max-width: 600px) {
            align-items: center;
            justify-content: center;
            padding-bottom: 0;
            margin-bottom: 0.8rem;
          }

          &.seaz-search__card_info_head_row--years {
            align-items: center;
            @media screen and (max-width: 600px) {
              justify-content: flex-start;
            }
          }
        }

        .job-search-head-row {
          @media screen and (max-width: 600px) {
            justify-content: flex-start;
          }
        }
      }
      &_wrapper {
        display: flex;
        flex-direction: row;
      }
    }
    &_greeny {
      display: flex;
      align-items: center;
      @media screen and (max-width: 1300px) {
        margin-bottom: 1rem;
      }
      &_badge {
        display: flex;
        align-items: center;
        background-color: #d8efd4;
        padding: 0.6rem;
        border-radius: 0.4rem;
        app-seazone-icon {
          margin-right: 0.7rem;
        }
      }
      &_text {
        font-size: 1.2rem;
        color: $seaz-success;
      }
    }

    &_photo {
      position: relative;
      width: 16rem;
      height: 16rem;
      border-radius: 50%;
      padding: 0.6rem;
      margin-right: 3.2rem;

      &--icon {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        color: #fff;
        font-size: 4rem;
        opacity: 0;
        transition: opacity 0.3s;
        svg {
          height: 4rem;
          width: 3.5rem;
        }
      }

      @media screen and (max-width: 1500px) {
        width: 13rem;
        height: 13rem;
        margin-right: 2rem;

        &--icon {
          svg {
            height: 3rem;
            width: 2.6rem;
          }
        }
      }
      @media screen and (max-width: 1200px) {
        width: 16rem;
        height: 16rem;
      }

      @media screen and (max-width: 600px) {
        margin-right: 0;
        margin-bottom: 2.2rem;
      }

      @media screen and (max-width: 480px) {
        padding: 0;
      }
    }

    &_avatar {
      display: block;
      width: 100%;
      height: 100%;
      object-fit: cover;
      border-radius: 50%;
    }

    &_position {
      width: 5.6rem;
      height: 5.6rem;
      border-radius: 50%;
      position: absolute;
      bottom: 1rem;
      right: 0;
      @media screen and (max-width: 1500px) {
        width: 4.6rem;
        height: 4.6rem;
        bottom: 2.6rem;
      }
      @media screen and (max-width: 1200px) {
        bottom: 0;
        width: 5.6rem;
        height: 5.6rem;
      }
    }
    &_contract {
      &_icon {
        width: 3.2rem;
        height: 3.2rem;
        position: absolute;
        background-color: $seaz-success;
        border: 2px solid $clr-white;
        top: 0;
        right: 0;
        display: flex;
        justify-content: center;
        align-items: center;
      }
    }
    &_name {
      color: $clr-primary-and-accent;
      text-transform: uppercase;
      line-height: 2.8rem;
      margin-right: 0.8rem;
      @media screen and (max-width: 1500px) {
        font-size: 2.2rem;
      }
      @media screen and (max-width: 1200px) {
        font-size: 3rem;
      }
      @include mobile {
        word-break: break-word;
        font-size: 2rem;
      }
      &_info {
        color: $seaz-text;
        margin-right: 0.8rem;
        line-height: 2rem;
        text-transform: lowercase;
        font-weight: 400;
        .seaz-cv__experience_head_verified {
          top: 2px;
          position: relative;
        }
      }
    }
    &_personal {
      color: $seaz-text;
      @media screen and (max-width: 600px) {
        text-align: center;
        font-size: 1.6rem;
      }
    }
    &_location {
      color: $seaz-text;
      &_row {
        display: flex;
        align-items: center;
        padding-top: 0.8rem;
        cursor: pointer;
        @media screen and (max-width: 600px) {
          position: relative;
        }
        app-seazone-icon {
          margin-right: 0.6rem;
          @media screen and (max-width: 600px) {
            position: absolute;
            left: -2.4rem;
            top: 1px;
          }
        }
      }
      &_col {
        display: flex;
        flex-direction: column;
        padding-top: 1.6rem;
        @media screen and (max-width: 600px) {
          align-items: center;
        }
      }
    }
    &_info_label {
      font-size: 1rem;
      text-transform: uppercase;
      letter-spacing: 0.5px;
      color: $seaz-document-count;
    }
    &_salary {
      &_col {
        padding-top: 1.6rem;
        @media screen and (max-width: 600px) {
          align-items: center;
          display: flex;
          flex-direction: column;
        }
      }
      &_row {
        color: $seaz-text;
      }
    }
  }
  &__result {
    &_text {
      padding: 1.2rem 0 0 0;
      font-size: 1.2rem;
      color: $seaz-document-count;
      &.mobile {
        display: none;
      }
      @media screen and (max-width: 1200px) {
        &.mobile {
          display: block;
          padding-bottom: 1rem;
        }
      }
    }
  }

  &__relevant {
    display: flex;
    position: relative;
    width: 126.4rem;
    margin: 0 auto 4rem;

    app-job-search-list {
      width: 100%;
    }

    @media screen and (max-width: 1300px) {
      width: 100rem;
    }

    @media screen and (max-width: 1200px) {
      flex-direction: column;
      width: 100%;
      padding: 0 1.6rem;
    }

    @media screen and (max-width: 650px) {
      flex-direction: column;
    }

    .seaz-search__card_info {
      @media screen and (max-width: 1200px) {
        padding-bottom: 9rem;
      }
      @media screen and (max-width: 600px) {
        padding-bottom: 12rem;
      }
    }
    .seaz-search__card {
      margin-right: 0;
    }
    .seaz-search__card_btns {
      @media screen and (max-width: 1200px) {
        width: 100%;
        flex-direction: row;
        margin-top: 3.2rem;

        button {
        }
        .seaz-search_relevant_btn {
          margin-top: 0;
        }
      }
      @media screen and (max-width: 600px) {
        flex-direction: column;
        button {
          width: 100%;
          &:not(:last-of-type) {
            margin-right: 0;
            margin-bottom: 1.6rem;
          }
        }
        .seaz-search_relevant_btn {
          width: auto;
        }
      }
    }
    &_clear {
      display: flex;
      position: absolute;
      top: -7rem;
      right: 0;
      align-items: center;
      justify-content: flex-end;
      @media screen and (max-width: 1200px) {
        right: 1.6rem;
      }
      @media screen and (max-width: 1100px) {
        position: relative;
        top: inherit;
        right: inherit;
        margin-bottom: 2rem;
      }
      &_text {
        line-height: 2rem;
        text-align: end;
        color: $seaz-document-count;
        margin-right: 1.8rem;
        font-weight: 400;
        @media screen and (max-width: 900px) {
          font-size: 1.2rem;
          line-height: 1.5rem;
        }
        @media screen and (max-width: 970px) {
          width: 100%;
        }
      }
      .seaz__btn_outlined-red {
        @include tablet {
          font-weight: 500;
          padding: 1.5rem 1.7rem;
        }
      }
      .sharing__offers-btn {
        &.seaz__btn_aqua {
          height: 5rem;
          @include tablet {
            margin-right: 1.2rem;
          }
          @include mobile {
            margin-right: 0;
            width: 100%;
            margin-bottom: 1.2rem;
          }
        }
      }
    }
    &_content {
      width: 100%;
      .seaz-search__card {
        margin-right: 0;
      }
    }

    .seaz-search__relevant_clear--right {
      display: flex;
      width: 72%;
      @media screen and (max-width: 1100px) {
        width: 39%;
      }
      @include tablet {
        width: auto;
        justify-content: flex-end;
        align-items: center;
      }
      .seaz-search__relevant_clear_text {
        width: auto;
      }
    }
  }

  &__suppliers-favorites {
    position: relative;
  }

  &__favorites {
    display: flex;
    position: relative;
    width: 100%;
    max-width: 126.4rem;
    margin: 0 auto 4rem;

    @media screen and (max-width: 1300px) {
      padding: 0 1.6rem;
    }
    @media screen and (max-width: 1100px) {
      flex-direction: column;
    }
    .seaz-search__card_info {
      @media screen and (max-width: 1200px) {
        padding-bottom: 2.8rem;
      }
      @media screen and (max-width: 768px) {
        padding-bottom: 12.8rem;
      }
      @include mobile {
        padding-bottom: 14.8rem;
      }
    }
    .seaz-search__relevant_clear {
      @include mobile {
        flex-direction: column;
        align-items: flex-start;
      }
    }
    .seaz-search__relevant_clear--right {
      display: flex;
      width: 72%;
      @media screen and (max-width: 1100px) {
        width: 39%;
      }
      @include tablet {
        width: auto;
        justify-content: flex-end;
        align-items: center;
      }
      .seaz-search__relevant_clear_text {
        width: auto;
      }
    }

    app-job-search-list {
      width: 100%;
    }
  }
  .seaz-search__navigation_box {
    display: flex;
    align-items: center;
    width: 126.4rem;
    margin: 0 auto;

    @media screen and (max-width: 1300px) {
      width: 100rem;
    }

    @media screen and (max-width: 1200px) {
      width: 100%;
      justify-content: space-between;
      padding: 0 2rem;
      .seaz-profile__navigation_list {
        margin-left: 1.6rem;
      }
    }
    @media screen and (max-width: 700px) {
      flex-direction: column-reverse;
      // align-items: flex-start;
      margin-top: 1.2rem;
      .seaz__btn_primary {
        width: 100%;
      }
    }
    @media screen and (max-width: 480px) {
      padding: 0;
    }
    app-search-navigation-links {
      width: 100rem;
      @media screen and (max-width: 1350px) {
        width: 80%;
      }
      @media screen and (max-width: 1200px) {
        width: auto;
      }
      @include mobile {
        width: 100%;
      }
    }
  }
  .seaz-profile__navigation {
    .seaz-profile__navigation_list {
      margin-left: 0;
    }
    .seaz-profile__navigation_list {
      @include mobile {
        margin-left: 0;
        padding-right: 0;
        width: 100%;
      }
    }
    .seaz-profile__navigation_item {
      @include mobile {
        width: 100%;
        a {
          width: 100%;
          text-align: center;
          justify-content: center;
        }
      }
      a {
        @include tablet {
          padding: 1.8rem 1.4rem 1.4rem;
        }
      }
    }
  }
}

.offer-greeny {
  display: flex;
  justify-content: center;
  height: 24px;
  width: 24px;
  background-color: #3aae27;
  border-radius: 50%;
}

.seaz-search__card_list,
.seaz-map {
  min-height: calc(100vh - 5rem);
}

.seaz-map {
  width: 40%;
  height: calc(100vh - 4.9rem);
  display: block;
  overflow: hidden;
  &.job-search__map {
    height: calc(100vh - 11.1rem);
  }
  &.fixed {
    position: fixed;
    top: 0;
  }
  &.absolute {
    position: absolute;
    bottom: 0;
    right: 0;
  }
  @media screen and (max-width: 1365px) {
    width: 35%;
  }
  @media screen and (max-width: 1200px) {
    // display: none;
    width: 100%;
    height: 100%;
  }
  &__modal {
    height: 100vh;
    width: 100vw;
    background-color: white;
    .modal__wrap {
      padding: 0;
    }
    &_wrapper {
      display: flex;
      flex-direction: column;
      height: 100%;
      position: relative;
      .modal-close {
        right: 3rem;
        top: 2.4rem;
      }
      .seaz-map {
        width: 100%;
      }
      .seaz-filters__row {
        flex-direction: column;
        padding-right: 0;
        margin-bottom: 1rem;
        .distance {
          padding-left: 0;
          border-left: none;
          padding-top: 2rem;
        }
      }
    }
    &-header {
      padding: 2.4rem 3rem;
      .seaz-filter__row--left .location {
        width: 100%;
      }
    }
  }
}
.info-window {
  &:not(:last-child) {
    border-bottom: 1px solid #bdbdbd;
    padding-bottom: 1rem;
    margin-bottom: 1rem;
  }
  &__list {
    .info-window:not(:last-child) {
      border-bottom: 1px solid #bdbdbd;
      padding-bottom: 1rem;
      margin-bottom: 1rem;
    }
  }
  &__header {
    display: flex;
    justify-content: space-between;
    margin-bottom: 1rem;
    @media screen and (max-width: 1300px) {
      flex-direction: column;
    }
    @media screen and (max-width: 1200px) {
      flex-direction: row;
    }
  }
  &__status {
    text-transform: uppercase;
    padding: 0.6rem 0.9rem;
    color: $clr-white;
    font-weight: 500;
    font-size: 1.2rem;
    letter-spacing: 0.1em;
    margin-right: 3.5rem;
    text-align: center;
    &.looking-job {
      background-color: $seaz-edit-hover;
    }
    &.vacation {
      background-color: $clr-green;
    }
    &.on-board {
      background-color: $seaz-mulberry;
    }
    &.not-active {
      background-color: $clr-grey;
    }
    @media screen and (max-width: 1300px) {
      margin-right: 1.5rem;
      font-size: 1rem;
    }
    @include tablet-big {
      font-size: 1.1rem;
      min-width: 17rem;
    }
    @media screen and (max-width: 400px) {
      min-width: 14rem;
    }
  }
  &__distance {
    color: $seaz-text;
    display: flex;
    align-items: center;
    white-space: nowrap;
    @media screen and (max-width: 1300px) {
      margin-top: 1rem;
    }
    @media screen and (max-width: 1200px) {
      margin-top: 0;
    }
    &::before {
      content: '';
      background-image: url('/assets/img/icon-location-map.svg');
      width: 1.1rem;
      height: 1.6rem;
      display: block;
      background-position: center;
      background-repeat: no-repeat;
      background-size: contain;
      margin-right: 0.5rem;
    }
  }
  &__user {
    display: flex;
    img {
      width: 4rem;
      height: 4rem;
      border-radius: 50%;
      object-fit: contain;
      margin-right: 0.8rem;
    }
  }
  &__user_work {
    font-weight: 500;
    font-size: 1.4rem;
    margin-bottom: 0.5rem;
    color: $seaz-text;
  }
  &__user_person {
    color: $seaz-document-count;
    font-size: 1.4rem;
    margin-bottom: 0.8rem;
  }
  &__profile {
    color: $clr-primary-and-accent;
    text-decoration: underline;
    font-weight: 500;
    font-size: 1.4rem;
  }
  &__position {
    margin-bottom: 0.8rem;
    color: $seaz-text;
    font-size: 1.4rem;
    font-weight: 500;
    text-transform: capitalize;
  }
  &__salary {
    margin-bottom: 0.8rem;
    color: $seaz-text;
    font-size: 1.4rem;
    font-weight: 500;
  }
  &__boat {
    display: flex;
    align-items: center;
    margin-right: 3.5rem;
    @media screen and (max-width: 1300px) {
      margin-right: 1.5rem;
      font-size: 1rem;
    }
    &_flag {
      img {
        width: 2.2rem;
        height: 1.3rem;
        object-fit: cover;
        margin-right: 0.7rem;
      }
    }
    &_info {
      color: $seaz-document-count;
      font-size: 1.4rem;
    }
  }
}
.seaz-cv__info_btns_like {
  background-color: $seaz-border;
  border-radius: 50%;
  &.liked {
    background-color: $clr-primary-and-accent;
  }
}
.seaz-cv__info_btns_document {
  background-color: $seaz-edit-hover;
  border-radius: 50%;
  &:disabled {
    background-color: $seaz-border;
  }
}
.seaz-profile__navigation_item a {
  app-seazone-icon.seaz-search__navigation_icon {
    background: $seaz-document-count;
    padding: 0.7rem;
    border-radius: 50%;
    position: relative;
    top: 1px;
    margin-right: 0.8rem;
    @include mobile {
      display: none;
    }
    svg {
      fill: $clr-white;
      width: 1rem;
      height: 1rem;
    }
  }
}
.seaz-profile__navigation_item.active-item {
  a {
    app-seazone-icon.seaz-search__navigation_icon {
      background: $clr-primary-and-accent;
    }
  }
}

.search__modal-filter {
  app-overlay {
    max-width: 76.8rem;
  }
}

.maintenance-equipment-modal-filter {
  app-overlay {
    max-width: 60rem;
  }

  .seaz-crew-search-all-filter__actions {
    width: auto;
  }
}

.seaz-filters__form_range .seazone-range-slider__inputs {
  @media screen and (max-width: 1200px) {
    .seazone-range-slider__row {
      width: 100%;
    }
    .form-field--content.form-field--content-outline {
      width: 100%;
    }
  }

  .seazone-range-slider__row .form-field--wrapper__row {
    @include mobile {
      flex-direction: row;
      .form-field--label {
        margin-bottom: 0;
      }
    }
  }
  .seazone-range-slider__row {
    @include mobile {
      flex-direction: row;
      .form__col {
        margin-bottom: 0;
      }
    }
  }
}

.slide-from-top {
  animation-duration: 400ms;
  animation-timing-function: cubic-bezier(0.25, 0.8, 0.25, 1);
  animation-name: slideFromTop;
}

.slide-from-bottom {
  animation-duration: 400ms;
  animation-timing-function: cubic-bezier(0.25, 0.8, 0.25, 1);
  animation-name: slideFromBottom;
}

@keyframes slideFromTop {
  from {
    transform: translate3d(0, -30px, 0);
  }

  to {
    transform: translate3d(0, 0, 0);
  }
}
@keyframes slideFromBottom {
  from {
    transform: translate3d(0, 30px, 0);
  }

  to {
    transform: translate3d(0, 0, 0);
  }
}

.seaz-job {
  &__search {
    .seaz-job__main_content {
      .seaz-filters__open {
        @media screen and (max-width: 1300px) {
          display: flex;
          position: relative;
          top: inherit;
          right: inherit;
          padding: 1rem 3.2rem;
          width: 49%;
          justify-content: center;
        }
        &--map {
          display: none;
          @media screen and (max-width: 1300px) {
            background-color: $clr-white;
            height: 4rem;
            display: flex;
            align-items: center;
            justify-content: center;
            flex-direction: row-reverse;
            color: $seaz-edit-hover;
            text-transform: uppercase;
            font-size: 1.2rem;
            font-weight: 500;
            letter-spacing: 0.1em;
            padding: 1rem 0.2rem;
            app-seazone-icon {
              margin-right: 0.6rem;
            }
          }
        }
      }
      .seaz-filters__open_btn--filter {
        @media screen and (max-width: 1300px) {
          justify-content: center;
        }
        @media screen and (max-width: 600px) {
          margin-bottom: 0;
        }
      }
      .seaz-filters__open--map {
        @media screen and (max-width: 600px) {
          margin-bottom: 0;
        }
      }
    }
    .seaz-search__favorites {
      .seaz-search__card {
        margin-right: 0;
        .seaz-search__card_btns {
          width: 22.4rem;
          @media screen and (max-width: 1300px) {
            width: 100%;
            flex-direction: row;
            flex-wrap: wrap;
          }
          @media screen and (max-width: 600px) {
            margin-bottom: 3rem;
          }
          @include mobile {
            margin-bottom: 2rem;
          }
          button {
            padding: 1.4rem 1.2rem;
            @media screen and (max-width: 1300px) {
              width: 49%;
              &:first-of-type {
                margin-right: 2%;
              }
            }
            @media screen and (max-width: 600px) {
              width: 100%;
              &:first-of-type {
                margin-right: 0;
              }
            }
          }
          .seaz-search_relevant_btn {
            @media screen and (max-width: 1300px) {
              width: auto;
              margin: 0 auto;
              margin-bottom: 1.4rem;
              padding-top: 0.6rem;
            }
            @include mobile {
              margin: 0 auto;
            }
          }
          .seaz__btn_outlined {
            @include mobile {
              width: 100%;
            }
          }
        }
        .seaz-search__card_info_img {
          @include mobile {
            margin-bottom: 2rem;
          }
        }
      }

      .seaz-search__relevant_clear {
        @media screen and (max-width: 1290px) {
          right: 1rem;
        }

        @media screen and (max-width: 1100px) {
          right: 0;

          .seaz-search__relevant_clear_text {
            width: auto;
            margin-right: 0;
          }
        }

        @media screen and (max-width: 480px) {
          .seaz-search__relevant_clear_text {
            width: 100%;
            text-align: center;
          }

          .seaz__btn_outlined-red {
            width: 100%;
            margin-top: 1.5rem;
          }
        }
      }

      @media screen and (max-width: 1350px) {
        width: 100%;
        max-width: 126.4rem;
      }
    }
    .seaz-search__relevant,
    .seaz-job__search_applied {
      .seaz-search__card .seaz-search__card_btns {
        @include large {
          width: 22rem;
        }
        @media screen and (max-width: 1300px) {
          width: 100%;
          flex-direction: row;
          flex-wrap: wrap;
          align-items: flex-start;
        }
        @media screen and (max-width: 480px) {
          margin-bottom: 3rem;
          justify-content: center;
        }
        button {
          padding: 1.4rem 1.2rem;
          @media screen and (max-width: 1300px) {
            width: 49%;
            &:nth-of-type(2n - 1) {
              margin-right: 2%;
            }
          }
          @media screen and (max-width: 480px) {
            width: 100%;
            &:nth-of-type(2n - 1) {
              margin-right: 0;
            }
          }
        }
        .seaz-search_relevant_btn {
          @media screen and (max-width: 600px) {
            width: auto;
          }
        }
      }
      .seaz-search__card {
        margin-right: 0;
      }

      .seaz-search__relevant_clear {
        @media screen and (max-width: 1290px) {
          right: 1rem;
        }

        @media screen and (max-width: 1100px) {
          right: 0;

          .seaz-search__relevant_clear_text {
            width: auto;
            margin-right: 0;
          }
        }

        @media screen and (max-width: 480px) {
          flex-direction: column;

          .seaz-search__relevant_clear_text {
            width: 100%;
            text-align: center;
          }

          .seaz__btn_outlined-red {
            width: 100%;
            margin-top: 1.5rem;
          }
        }
      }

      @media screen and (max-width: 1350px) {
        width: 100%;
        max-width: 126.4rem;
      }
    }
    .seaz-job__search_applied {
      .seaz-search__card .seaz-search__card_info_head_row--contract {
        margin-top: 2rem;
      }
    }
    .seaz-job__main_content .seaz-search__content {
      .seaz-search__card {
        .seaz-search__card_btns {
          @media screen and (max-width: 1300px) {
            flex-direction: row;
            flex-wrap: wrap;
          }
          @media screen and (max-width: 600px) {
            margin-bottom: 3rem;
          }
          @include mobile {
            justify-content: center;
          }
          button {
            padding: 1.4rem 1.2rem;
            @media screen and (max-width: 1300px) {
              width: 49%;
              &:not(:last-of-type) {
                margin-right: unset;
              }
              &:nth-of-type(2n - 1) {
                margin-right: 2%;
              }
            }
            @include mobile {
              width: 100%;
              &:not(:last-of-type) {
                margin-right: 0;
              }
            }
            &.seaz__btn_outlined-red {
              @include mobile {
                width: 100%;
                margin-right: 0;
              }
            }
          }
          .seaz-search_relevant_btn {
            @media screen and (max-width: 1300px) {
              margin-top: 0;
              margin-bottom: 1.4rem;
            }
          }
          .seaz__btn_outlined {
            @include mobile {
              width: 100%;
            }
          }
        }
      }
    }

    .seaz-search__favorites,
    .seaz-search__relevant {
      width: 126.4rem;

      @media screen and (max-width: 1350px) {
        width: 100%;
        max-width: 126.4rem;
      }
    }

    @media screen and (max-width: 480px) {
      background-color: #fafafb;
    }
  }

  &__main {
    &_filters {
      &_mobile {
        display: flex;
        padding: 0 1.2rem;
        @media screen and (max-width: 1300px) {
          margin-bottom: 1rem;
          flex-direction: column;
        }
        .seaz-filters__open {
          @media screen and (max-width: 1300px) {
            display: flex;
          }
        }
        .seaz-job__main_filter_btns {
          @media screen and (max-width: 1300px) {
            display: flex;
            justify-content: space-between;
            align-items: center;
            margin-bottom: 1.2rem;
          }
        }
      }
    }
  }
  &__main_content {
    display: flex;
    margin-bottom: 7rem;
    @media screen and (max-width: 1300px) {
      flex-direction: column;
      .seaz-search__result_text {
        //display: none;
      }
    }
    .seaz-search__card_info {
      @media screen and (max-width: 1200px) {
        padding-bottom: 3.8rem;
      }
      @media screen and (max-width: 768px) {
        padding-bottom: 12.8rem;
      }
    }
    .seaz-search__card_info_col {
      display: flex;
      flex-direction: column;
      align-items: center;
    }
    .seaz-filters__badges_row {
      @media screen and (max-width: 1300px) {
        display: flex;
      }
    }
    .seaz-filters__row_select {
      @media screen and (max-width: 1200px) {
        display: flex;
        width: 100%;
        margin-right: 0;
      }
    }
    .seaz-filters__row {
      @media screen and (max-width: 1200px) {
        padding: 0 1rem;
      }
    }
  }
  .seaz-search__content {
    margin-bottom: 4rem;
  }
  &__search {
    &_title {
      width: 126.4rem;
      margin: 0 auto;
      padding: 6.6rem 0 3rem;
      border-bottom: 1px solid $seaz-border;
      display: flex;
      justify-content: center;
      @media screen and (max-width: 1300px) {
        width: 98%;
        padding: 2.4rem 0;
      }
      &_text {
        text-align: center;
        color: $clr-primary-and-accent;
        @media screen and (max-width: 1300px) {
          font-size: 2.2rem;
        }
      }
    }
    &_navigation {
      .seaz-profile__navigation {
        padding-top: 4rem;
        width: 126.4rem;
        margin: 0 auto;
        @media screen and (max-width: 1300px) {
          width: 100%;
          overflow: auto;
          .seaz-profile__navigation_list {
            padding-right: 0;
          }
          .seaz-profile__navigation_item a {
            font-size: 1.4rem;
            padding: 1.4rem 3.8rem 1.2rem;
          }
          app-scroll-controls .controls-container button {
            top: 59%;
          }
        }
      }
      .seaz-profile__navigation_list {
        margin: 0;
      }
    }
    .seaz-filters__open {
      @media screen and (max-width: 600px) {
        display: none;
      }
    }
    app-job-search-list {
      width: 100%;
    }
    .seaz-search__result_text {
      padding-bottom: 3.2rem;
      @media screen and (max-width: 1300px) {
        &.mobile {
          display: block;
          padding: 1.2rem 0 1.2rem 0;
        }
      }
    }
    .seaz-filters__row {
      @media screen and (max-width: 1300px) {
        padding-left: 1rem;
        margin-bottom: 1.2rem;
      }
    }
    .location {
      width: 32%;
    }
    .seaz-filters__range_lenght {
      padding-bottom: 1.6rem;
      color: $seaz-text;
      font-size: 1.4rem;
      display: block;
    }
    .seaz-filters__form_range .seazone-range-slider__inputs .form-field--content-outline {
      width: 8.1rem;
    }
    .seaz-search__card_list {
      @media screen and (max-width: 1300px) {
        width: 100%;
      }
    }
    .seaz-search__card {
      margin-right: 3%;
      @media screen and (max-width: 1300px) {
        margin-right: 0;
      }
      .seaz-search__card_photo {
        //height: auto;
        margin-right: 2rem;
        position: relative;

        @media screen and (max-width: 600px) {
          margin-bottom: 3rem;
          margin-right: 1rem;
        }

        @media screen and (max-width: 480px) {
          width: 11rem;
          height: 11rem;
          flex-shrink: 0;
        }

        .seaz-search__card_photo_status div {
          position: absolute;
          padding: 0.7rem 1.2rem;
          text-transform: uppercase;
          top: -1.2rem;
          left: -1.2rem;
          font-size: 1.4rem;
          font-weight: 500;
          white-space: nowrap;
        }
        .seaz-search__card_photo_status {
          .offerred-status-declined {
            font-size: 1.2rem;
            padding: 0.6rem 0.4rem;
          }
        }
        .offer__inProcess-status {
          background-color: $seaz-success;
          color: $clr-white;
        }
        .offer__pending-status {
          background-color: $seaz-warning;
          color: $clr-white;
        }
        .offer__accepted-status {
          background-color: $seaz-edit-hover;
          color: $clr-white;
        }
        .offer__rejected-status {
          background-color: $seaz-alarm;
          color: $clr-white;
        }
        .offer__declined-status {
          background-color: $seaz-border;
          color: $seaz-text;
        }
        .offer__closed-status {
          color: $seaz-alarm-bg;
          background-color: $seaz-document-count;
        }
      }
      .seaz-search__card_info_box_head {
        @media screen and (max-width: 600px) {
          display: none;
        }
        &--mobile {
          display: none;
          @media screen and (max-width: 600px) {
            display: flex;
            flex-direction: column;
            width: 100%;

            .seaz-search__card_location_row {
              padding-top: 0;
            }
          }
        }
        .seaz-user__contract_card_head_row {
          @media screen and (max-width: 600px) {
            margin-bottom: 0.8rem;
          }
        }
        .seaz-search__card_salary_col {
          @media screen and (max-width: 600px) {
            align-items: flex-start;
            padding-top: 0.8rem;
          }
        }
        .seaz-search__card_salary_row {
          color: $clr-primary-and-accent;
          padding-bottom: 1rem;
          @media screen and (max-width: 600px) {
            padding-top: 0;
          }
        }
      }
      .seaz-search__controls_box {
        display: flex;
        flex-direction: column;
        align-items: center;
        width: 100%;
        padding-top: 3rem;

        @include tablet {
          position: absolute;
          bottom: 1.2rem;
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;
          left: 0;
        }
        .seaz-search__card_head_updates {
          margin-right: 0;
          text-align: center;
          margin-top: 2rem;
        }
      }

      .seaz-search__card_avatar {
        width: 100%;
        height: 100%;
        //object-fit: contain;

        //width: 20rem;
        //height: 20rem;
        border-radius: 1.6rem;
        //margin-bottom: 2.4rem;

        //@media screen and (max-width: 1720px) {
        //    width: 16rem;
        //    height: 16rem;
        //}
        //@include large {
        //    width: 11rem;
        //    height: 11rem;
        //}
        //@media screen and (max-width: 1300px)  {
        //    width: 14rem;
        //    height: 14rem;
        //}
        //@media screen and (max-width: 600px) {
        //    width: 11rem;
        //    height: 11rem;
        //    margin-bottom: 1.4rem;
        //}
      }
      .seaz-search__card_from_contract {
        display: inline-flex;
        padding: 8px 10px;
        background: #351f6f;
        position: absolute;
        top: -1.2rem;
        right: -1rem;
      }
      .seaz-search__card_controls {
        display: flex;
      }
      .seaz-search__card_controls button {
        @include large {
          width: 3rem;
          height: 3rem;
          display: flex;
          align-items: center;
          justify-content: center;
          .seaz-search__card_controls--new {
            top: 0;
            right: 0;
          }
          app-seazone-icon {
            width: 52%;
          }
        }
        @media screen and (max-width: 1300px) {
          width: 5rem;
          height: 5rem;
          .seaz-search__card_controls--new {
            top: 6px;
            right: 1px;
          }
        }
      }
      .seaz-search__card_info {
        @media screen and (max-width: 1300px) {
          flex-direction: column;
          position: relative;
        }
      }
      .seaz-job__search .seaz-search__card--right {
        @media screen and (max-width: 1300px) {
          position: relative;
        }
      }
      .seaz-search__card_info_label {
        padding-bottom: 0.5rem;
        display: block;
      }
      .seaz-user__contract_card_title {
        @media screen and (max-width: 600px) {
          font-size: 1.5rem;
        }
      }
      .seaz-search__card_name {
        margin-bottom: 0;
        @media screen and (max-width: 600px) {
          font-size: 2rem;
        }
      }
      .seaz-search__card_subtitle {
        font-size: 1.6rem;
        line-height: 1.6rem;
        padding-bottom: 1.6rem;
        display: inline-block;
        color: $seaz-text;
        &.seaz-search__card_subtitle--years {
          padding-bottom: 0;
          margin-right: 1.2rem;
        }
      }
      .seaz-search__card_contract_col {
        display: flex;
        flex-direction: column;
        .seaz-search__card_subtitle {
          // padding-top: 1rem;
        }
      }
      .seaz-search__card_flexible {
        padding-top: 0.2rem;
        display: inline-block;
        color: $seaz-about-text;
        font-size: 1.2rem;
        font-weight: 400;
      }
      .seaz-search__card_info_head_row--contract {
        display: flex;
        align-items: flex-start;
        flex-wrap: wrap;
        .seaz-search__card_info_contract {
          margin-right: 2.4rem;
        }
        app-seazone-icon {
          // padding-top: 0.5rem;
          margin: 0 0.6rem;
        }
        @media screen and (max-width: 1300px) {
          .seaz-search__card_info_contract {
            width: 100%;
          }
        }
      }
      .seaz-search__card_btns {
        width: 22.4rem;
        @media screen and (max-width: 1720px) {
          width: 19.4rem;
        }
        @media screen and (max-width: 1300px) {
          width: 100%;
        }
        .seaz__btn_outlined {
          @media screen and (max-width: 600px) {
            width: 100%;
          }
        }
      }
      .seaz-search__card_info_img {
        margin-right: 3.2rem;
        display: flex;
        flex-direction: column;
        align-items: center;
        @media screen and (max-width: 600px) {
          width: 100%;
          align-items: flex-start;
          margin-right: 0;
          flex-direction: row;
        }
      }
      .seaz-search__card_location_col,
      .seaz-search__card_salary_col {
        @media screen and (max-width: 600px) {
          align-items: flex-start;
        }
      }
      .seaz-search__card_location_row app-seazone-icon {
        @media screen and (max-width: 600px) {
          position: relative;
          left: 0;
        }
      }
    }
    .seaz-search__card--right {
      display: flex;
      flex-direction: column;
      align-items: center;
    }
    .seaz-search__card_info--left {
      width: 100%;
      @media screen and (max-width: 1300px) {
        padding-bottom: 3.2rem;
      }
    }
    .seaz-search__card_info_box {
      width: 100%;
    }
    .seaz-map {
      width: 38%;
      @include large {
        width: 35%;
      }
      @media screen and (max-width: 1300px) {
        display: none;
      }
    }
    .seaz-search__card_dates {
      height: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      @media screen and (max-width: 1300px) {
        width: 100%;
        padding-top: 3.2rem;
      }
      @media screen and (max-width: 600px) {
        display: none;
      }
      div {
        font-size: 1.2rem;
        color: $seaz-about-text;
        @media screen and (max-width: 1300px) {
          width: 100%;
        }
      }
      &--mobile {
        display: flex;
        div {
          font-size: 1.2rem;
          color: $seaz-about-text;
          width: 100%;
          padding-top: 1rem;
        }
      }
    }
    .seaz-search__content .seaz-search__card_btns {
      div {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: space-between;
        @media screen and (max-width: 1300px) {
          flex-direction: row;
          flex-wrap: wrap;
          width: 100%;

          button {
            width: 49.5%;
            margin-top: 0;
            margin-right: 0;
            margin-bottom: 1.6rem;
          }
          .seaz-search_relevant_btn {
            margin: auto;
            width: auto;
          }
        }
        @media screen and (max-width: 600px) {
          .seaz-search_relevant_btn {
            margin-bottom: 2rem;
          }
        }
      }
    }
    .seaz-filters {
      @media screen and (max-width: 1300px) {
        display: none;
      }
    }
  }
  &__search_applied {
    position: relative;
    width: 126.4rem;
    margin: 0 auto;
    margin-bottom: 4rem;
    @media screen and (max-width: 1300px) {
      width: 100%;
    }
    .seaz-search__card_info {
      @media screen and (max-width: 1200px) {
        padding-bottom: 2.8rem;
      }
      @media screen and (max-width: 768px) {
        padding-bottom: 12.8rem;
      }
    }
    &_nav {
      padding: 3.2rem 0;
      overflow: auto;
      display: flex;
      &_link {
        padding: 0 2.4rem;
        opacity: 0.6;
        border-right: 1px solid $clr-primary-and-accent;
        color: $clr-primary-and-accent;
        font-size: 1.2rem;
        font-weight: 500;
        text-transform: capitalize;
        flex-shrink: 0;
        &:last-of-type {
          border-right: none;
        }
        &.active {
          opacity: 1;
          text-decoration: underline;
        }
      }
      &_action {
        color: #3aae27;
      }
    }

    .seaz-search__relevant_clear_text {
      width: 100%;

      @media screen and (max-width: 1100px) {
        text-align: center;
      }

      @media screen and (max-width: 1100px) {
        width: 100% !important;
        text-align: center;
      }
    }
  }
}
.seaz-search__card_btns {
  width: 22.4rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  button {
    width: 100%;
    padding: 1.3rem 1.2rem 1.6rem 1.1rem;
    &:not(:last-of-type) {
      margin-bottom: 1.2rem;
    }
  }

  .seaz-search__card_history-btn {
    padding: 1.4rem 2rem;
  }
}

.seaz-job__search
  .seaz-job__main_content
  .seaz-search__content
  .seaz-search__card
  .seaz-search__card_btns
  .seaz-search_relevant_btn {
  padding: 0 0 0.5rem;
}

.seaz-search_relevant_btn {
  margin: 3rem 0 0;
  width: auto;
  color: $clr-primary-and-accent;
  position: relative;
  font-weight: 500;
  font-size: 1.6rem;

  &:after {
    content: '';
    position: absolute;
    bottom: 0;
    width: 10rem;
    left: 50%;
    transform: translate(-50%, 0);
    border-bottom: 1px dashed $clr-primary-and-accent;
  }
}

.seaz-search__card_btns {
  @media screen and (max-width: 1200px) {
    flex-direction: row;
    width: 100%;
    margin-top: 3.2rem;
    flex-wrap: wrap;
    button {
      width: 49%;
      margin-bottom: 1.6rem;
      &:nth-child(odd) {
        margin-right: 2%;
      }
    }
    .seaz-search_relevant_btn {
      margin-top: 0;
    }
  }
  @media screen and (max-width: 600px) {
    flex-direction: column;
    button {
      width: 100%;
      &:not(:last-of-type) {
        margin-right: 0;
        margin-bottom: 1.6rem;
      }

      &:nth-child(odd) {
        margin-right: 0;
      }
      &.seaz-search_relevant_btn {
        width: auto;
      }
    }
  }
}
.seaz-filters__cards .seaz-filters__row.seaz-job__search_filter_row {
  .seaz-filter__row--right {
    padding-top: 0;
    padding-left: 2.4rem;
    width: auto;
    @media screen and (max-width: 1300px) {
      width: 100%;
      padding-top: 1.2rem;
      padding-left: 0;
    }
    .form__checkbox {
      @media screen and (max-width: 1300px) {
        display: flex;
      }
    }
  }
  .seaz-filter__row--left {
    @media screen and (max-width: 1300px) {
      flex-direction: column;
    }
    .location,
    .distance {
      @media screen and (max-width: 1300px) {
        display: flex;
      }
      app-location-autocomplete {
        @media screen and (max-width: 1300px) {
          width: 100%;
        }
      }
    }
    .distance {
      @media screen and (max-width: 1300px) {
        border-left: none;
        padding-left: 0;
        padding-top: 2rem;
        app-seazone-range-slider {
          width: 100%;
          margin-right: 1rem;
        }
      }
    }
  }
}
.job-offer__head_top {
  @media screen and (max-width: 1300px) {
    display: flex;
    justify-content: space-between;
    width: 100%;
    align-items: center;
  }
}

app-search-crew-all {
  .seaz-search__card_head_badges {
    flex-direction: row;
    flex-wrap: wrap;
    // flex-direction: column;
    app-crew-cv-status {
      margin: 1rem 0.7rem 0 0;
    }

    //@media screen and (max-width: 480px) {
    //  flex-wrap: nowrap;
    //}
  }

  .seaz-search__card_head_badges app-crew-cv-status {
    margin: 0;
  }
}
.seaz-search__all {
  .crew-id__box {
    @include large {
      margin-top: 0;
    }
    @media screen and (max-width: 1200px) {
      margin-top: 2rem;
    }
    @media screen and (max-width: 600px) {
      margin-top: 0;
      position: absolute;
      bottom: 1rem;
    }
  }
  .seaz-search__card_info {
    @media screen and (max-width: 1200px) {
      padding-bottom: 9.8rem;
    }
    @media screen and (max-width: 600px) {
      padding-bottom: 12.8rem;
    }
  }
  .seaz-search__card_head .header__controls {
    @media screen and (max-width: 1200px) {
      bottom: 3.4rem;
    }
    @media screen and (max-width: 600px) {
      left: 50%;
      bottom: 6.4rem;
      transform: translate(-50%, 0);
    }
  }
}
.seaz-job__search_favorites {
  &.seaz-search__favorites {
    .seaz-search__card_info {
      @media screen and (max-width: 1200px) {
        padding-bottom: 8.8rem;
      }
      @media screen and (max-width: 600px) {
        padding-bottom: 12.8rem;
      }
    }
  }
  .seaz-search__card_head .header__controls {
    @media screen and (max-width: 600px) {
      left: 50%;
      bottom: 6.4rem;
      transform: translate(-50%, 0);
    }
  }
  .crew-id__box {
    @include large {
      margin-top: 0;
    }
    @media screen and (max-width: 1200px) {
      margin-top: 2rem;
    }
    @media screen and (max-width: 600px) {
      position: absolute;
      bottom: 1rem;
      width: 100%;
      left: 0;
      justify-content: center;
    }
  }
}
.seaz-search {
  &__all {
    position: relative;
    &_offer_btn {
      position: absolute;
      top: -10.3rem;
      right: 8.5%;
      padding: 1.1rem 3.2rem 1.4rem;
      @include large {
        right: 12%;
      }
      @media screen and (max-width: 1200px) {
        right: 1.6rem;
      }

      @media screen and (max-width: 768px) {
        top: -8rem;
      }

      @media screen and (max-width: 700px) {
        top: -15.5rem;
        width: 94%;
        right: 0;
        margin: auto;
        left: 0;
      }
      @include mobile {
        top: -13rem;
      }
    }
  }
  &__navigation_box--crew {
    &.seaz-search__navigation_box {
      @media screen and (max-width: 700px) {
        // margin-top: 6.2rem;
      }
      @include mobile {
        // margin-top: 4.2rem;
      }
    }
  }
}
.job-search__crew_id_container {
  display: flex;
  align-items: flex-end;
  justify-content: center;
  width: 100%;
  margin-top: 2rem;
  @include large {
    flex-direction: column;
    align-items: center;
  }
  @media screen and (max-width: 1300px) {
    flex-direction: row;
    align-items: center;
    position: absolute;
    bottom: 2rem;
    left: 0;
    padding-left: 2.4rem;
    justify-content: flex-start;
  }
  @media screen and (max-width: 768px) {
    position: initial;
    justify-content: center;
  }
}

.job-search__crew_id_image.seaz-modal__history_card_img {
  margin-right: 0;
  margin-left: 1rem;
  @include large {
    margin-left: 0;
    margin-top: 1rem;
  }
  @media screen and (max-width: 1300px) {
    margin-top: 0;
    margin-left: 2rem;
  }
}
.seaz-greeny__available {
  display: flex;
  align-items: center;
  padding: 0.5rem 0.6rem;
  background-color: #d8efd4;
  border-radius: 0.4rem;
  app-seazone-icon {
    margin-right: 0.7rem;
  }
}
app-search-map-info-modal-list .info-window__list {
  padding-right: 15px;
}

.seaz-search__card_photo.outlined {
  position: relative;
  border: 0.4rem solid #351f73;
  padding: 0.6rem;
  cursor: pointer;

  &:before {
    content: '';
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 85%;
    height: 85%;
    background: linear-gradient(0deg, rgba(53, 31, 115, 0.56), rgba(53, 31, 115, 0.56));
    border-radius: 50%;
    padding: 0.6rem;
    opacity: 0;
    transition: opacity 0.3s;
  }

  &:hover {
    .seaz-search__card_photo--icon {
      opacity: 1;
    }

    &:before {
      opacity: 1;
    }
  }
}

.seaz-search-all {
  position: relative;
  margin-top: 3.2rem;
  padding-left: 4.8rem;
  padding-right: 4.8rem;

  @include laptop {
    padding-left: 2rem;
    padding-right: 2rem;
  }

  @include mobile {
    padding-left: 1.2rem;
    padding-right: 1.2rem;
  }

  .seaz-search__all_offer_btn {
    right: 0;
  }

  @media screen and (max-width: 900px) {
    margin-top: 0;
  }

  &__services {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 60%;
    margin: 2rem 0 2.4rem;
    padding-right: 3.2rem;
    &--nav {
      .seaz-search__navigation_box {
        width: auto;
      }

      .seaz-profile__navigation {
        padding: 0;
      }

      .seaz-profile__navigation_item a {
        padding: 1.4rem 2rem 1.6rem;
      }
    }

    .seaz-search-crew-filters__search-result-label {
      display: none;
    }

    .seaz-search-crew-filters__search-result-box {
      width: auto;
    }

    .seaz-search-crew-filters__search-result {
      margin: 0;
    }

    .seaz-search__result_text {
      padding: 0;
    }

    //@media screen and (max-width: 1908px) {
    //  margin: -2rem 0 2.4rem;
    //}

    @media screen and (max-width: 1350px) {
      .seaz-search__navigation_box {
        width: auto;
      }
    }
    @media screen and (max-width: 1366px) {
      width: 65%;
    }
    @media screen and (max-width: 1200px) {
      width: 100%;
      margin: 1.8rem 0 2.4rem;
      padding-right: 0;
      .seaz-search__navigation_box {
        padding: 0;
      }

      .seaz-search-crew-filters__search-result-box {
        display: flex;
        justify-content: flex-end;
        width: 50%;
      }

      .seaz-search-crew-filters__search-result-select-box {
        display: flex;
        justify-content: flex-end;
        width: 100%;
      }
    }

    @media screen and (max-width: 900px) {
      &--nav {
        .seaz-profile__navigation_item a {
          padding: 1.4rem 1rem 1.6rem;
        }
      }
    }

    @media screen and (max-width: 768px) {
      flex-direction: column;

      .seaz-search-crew-filters__search-result-label {
        display: block;
      }

      &--nav {
        .seaz-search__navigation_box {
          margin-bottom: 1.2rem;
        }

        .seaz-search__navigation_box--crew.seaz-search__navigation_box {
          margin-top: 0;
        }

        .seaz-profile__navigation .seaz-profile__navigation_item a {
          padding: 1.8rem 2.1rem 1.4rem;
        }
      }

      .seaz-filters__map_icon {
        height: 4rem;
      }

      .seaz-search-crew-filters__search-result-box {
        justify-content: center;
        width: 100%;
      }

      .seaz-search-crew-filters__search-result {
        width: 100%;
      }
    }

    @media screen and (max-width: 480px) {
      margin: 0 0 2.4rem;
    }
  }

  @media screen and (max-width: 1200px) {
    width: 100%;

    .seaz-search__main_content {
      padding: 0;
    }
  }

  @media screen and (max-width: 768px) {
    margin-top: 1rem;
  }
}

.seaz-search-crew-filters {
  display: flex;
  justify-content: space-between;
  box-sizing: border-box;
  padding-top: 3.2rem;

  > .seaz-search-crew-filters__search-result-label {
    display: none;
    @include mobile {
      display: block;
    }
  }

  &__header-btn-wrap {
    .seaz__btn_outlined {
      background-color: $light-color;

      &:hover {
        color: $clr-primary-and-accent;
      }
    }

    @include tablet {
      width: 100%;
      margin-top: 2.4rem;
    }
  }

  &__header-btn {
    display: block;
    width: 18.8rem;

    &:not(:last-of-type) {
      margin-bottom: 1.6rem;
    }

    @include tablet {
      width: 100%;
    }
  }

  &__main-filters {
    display: block;
    width: 60%;
    padding-right: 3.2rem;
  }

  &__location-filters {
    display: block;
    width: 40%;
  }

  &__search {
    position: relative;
    display: flex;
    align-items: center;

    .form-field--content.form-field--content-outline {
      height: 4rem;
      padding: 0.8rem 1.2rem;
      border-radius: 0.4rem 0 0 0.4rem;
    }

    .seazone__form-search-icon,
    .seaz-filters__row_search-icon {
      width: 5.2rem !important;
      height: 4rem !important;
      border-radius: 0 0.4rem 0.4rem 0;
    }
  }

  &__title-box {
    display: flex;
    align-items: center;
    margin-top: 2.4rem;
  }

  &__title {
    display: flex;
    align-items: center;
    font-size: 2rem;
    font-weight: 500;

    &--main-filters {
      color: $seaz-mulberry;
      margin-right: 1.6rem;

      @include tablet {
        display: none;
      }
    }

    &--location-filters {
      color: $seaz-edit-hover;
      margin-top: 2.2rem;
    }

    &--icon {
      margin-right: 0.8rem;
    }
  }

  &__button-filters {
    display: flex;
    align-items: center;
    border: 1px solid #4f194a;
    border-radius: 4px;
    background-color: #fff;
    box-sizing: border-box;
    padding: 0.8rem 1.2rem;
    font-weight: 500;
    text-transform: uppercase;
    transition: opacity 0.3s;

    &--icon {
      margin-right: 0.4rem;
    }

    &:hover {
      opacity: 0.7;
    }
  }

  &__button-more-filters {
    color: $button-main;
    margin-right: 1.6rem;
  }

  &__reset {
    display: flex;
    align-items: baseline;
    margin-top: 0.5rem;
    padding: 0;
    border-bottom: none;

    .financial-data__badge_reset {
      white-space: nowrap;
    }

    .financial-data__badge {
      background-color: initial;
      margin: 0.8rem 0.8rem 0.8rem 0;
    }
  }

  &__reset-list {
    margin-left: 1.7rem;
  }

  &__mobile-buttons-filter {
    display: none;
    // justify-content: space-between;
    justify-content: flex-end;
    position: fixed;
    bottom: 0;
    left: 0;
    width: 100%;
    z-index: 10;
    padding: 2rem 1.6rem;
    background-color: #fff;
    font-size: 1.2rem;
    font-weight: 500;
  }

  &__mobile-button-filter {
    display: flex;
    align-items: center;
    // width: 50%;
    font-weight: 500;
    text-align: center;
    text-transform: uppercase;
    // border-radius: 0.4rem;
    box-sizing: border-box;
    // padding: 1.2rem 0.8rem;
    letter-spacing: 0.1em;
    transition: opacity 0.3s;

    &:hover {
      opacity: 0.7;
    }

    &--more {
      // border: 1px solid #4F194A;
      color: $button-main;
      margin-right: 2rem;
    }

    &--location {
      // border: 1px solid #00B9FF;
      color: $seaz-edit-hover;
    }

    .seaz-search-crew-filters__title--icon {
      margin-right: 0.8rem;
    }
  }

  &__toggle {
    &-btn {
      display: none;
      padding: 0.5rem 0.2rem;
      margin-top: 3.2rem;
      font-weight: 550;
      font-size: 1.6rem;
      color: $seaz-mulberry;

      @include tablet {
        display: flex;
      }
    }

    @include tablet {
      &-content {
        max-height: 0;
        overflow: hidden;
        &.expand {
          max-height: none;
        }
      }
    }

    &-icon {
      font-size: 0.8rem;
      transition: all 0.4s;
      margin-left: 1.4rem;
      line-height: 3em;
      transform: rotateZ(180deg);

      &.expand {
        transform: rotateZ(0);
      }
    }
  }

  &__search-result-box {
    width: 100%;
  }

  &__search-result {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 0 2.8rem 3.2rem 0;
  }

  &__search-result-select-box {
    width: 19.2rem;
  }

  @media screen and (max-width: 1700px) {
    //&__search {
    //  .form-field--content.form-field--content-outline {
    //    padding: 0.8rem 4rem 0.8rem 1.2em;
    //  }
    //}
  }

  @media screen and (max-width: 1200px) {
    &__search-result {
      margin: 0 0 3.2rem 0;
    }

    &__search-result-select-box {
      display: flex;
      width: 50%;

      .seaz-filters__map_icon {
        border: 1px solid #00b9ff;
        border-radius: 4px;
        box-sizing: border-box;
        margin-right: 1.6rem;
        padding: 1rem 1.4rem;
        white-space: nowrap;
      }
    }
  }

  @media screen and (max-width: 1365px) {
    &__main-filters {
      width: 65%;
    }
    &__location-filters {
      width: 35%;
    }
  }
  @media screen and (max-width: 1024px) {
    flex-direction: column;

    &__main-filters {
      width: 100%;
      margin: 0 0 2.8rem 0;
      padding-right: 0;
    }

    &__button-more-filters {
      display: none;
    }

    &__location-filters {
      width: 100%;
    }

    &__mobile-buttons-filter {
      display: flex;
    }

    &__search-result-select-box {
      width: 70%;
    }
  }

  @media screen and (max-width: 768px) {
    &__search-result {
      flex-direction: column;
      align-items: flex-start;
    }

    &__search-result-label {
      margin-bottom: 1.2rem;
    }

    &__search-result-select-box {
      flex-direction: column;
      width: 100%;

      .seaz-filters__map_icon {
        width: 100%;
        margin: 0 0 1.2rem 0;
      }
    }
  }
}

.seaz-search-chips-filter {
  margin-top: 2.2rem;
  box-sizing: border-box;

  &__row {
    display: flex;
    flex-wrap: wrap;
    align-items: center;

    &:not(:last-of-type) {
      margin-bottom: 0.8rem;
    }
  }

  &__inline-group {
    display: inline-block;

    .seaz-search-chips-filter__chips {
      display: inline;
    }

    .seaz-search-chips-filter__chips--item {
      display: inline-block;
      vertical-align: middle;
    }
  }

  &__container {
    display: flex;
    align-items: center;
  }

  &__chips {
    display: flex;
    flex-wrap: wrap;
    position: relative;

    &--item {
      margin: 0 0.8rem 0.8rem 0;
      &.disabled {
        .chips-item {
          background-color: #e7e7eb;
          cursor: default;
        }
      }
    }

    .chips-item {
      display: flex;
      justify-content: space-between;
      align-items: center;
      color: $seaz-text;
      background-color: $light-color;
      border: 1px solid $seaz-head-arrow;
      border-radius: 4rem;
      box-sizing: border-box;
      padding: 0.8rem 1.3rem;
      cursor: pointer;
      height: 4rem;
    }

    .chips-item-large {
      padding: 1rem 1.6rem 1rem 1rem;
      height: 5.2rem;
      .seaz-search-chips-filter__chips--icon {
        width: 3.2rem;
        height: 3.2rem;
        background-color: $seaz-border;
        border-radius: 50%;
        svg {
          fill: white;
        }
      }
    }

    &--icon {
      margin-right: 1rem;
      svg {
        fill: $clr-grey;
      }
    }

    &--icon-greeny {
      svg {
        width: 1.4rem;
        height: 1.4rem;
      }
    }

    input {
      position: absolute;
      z-index: -1;
    }
  }

  &__label {
    font-weight: 500;
    white-space: nowrap;
    color: $seaz-text;
    margin: 0 1.6rem 0.8rem 0;
  }

  &__distance {
    display: flex;
    align-items: center;
    border-left: 1px solid #e0e0e0;
    padding-left: 0.6rem;
    margin-left: 0.2rem;

    .form-field--wrapper.form-field--wrapper__row.seazone-form-field--wrapper .form-field--label {
      width: auto;
      max-width: initial;
      margin-right: 1.6rem;
    }

    &--unit {
      margin-left: 1rem;
    }

    .form-field--label {
      font-weight: 500 !important;
    }
  }

  @media screen and (max-width: 1908px) {
    &__distance {
      width: 100%;
      border-left: none;
      padding-left: 0;
      margin-left: 0;
      margin-top: 1.6rem;

      app-seazone-input {
        width: 45%;
      }

      .form-field--wrapper__row .form-field--label {
        max-width: 5rem;
      }
    }
  }

  @media screen and (max-width: 1440px) {
    &__main-filters {
      width: 54%;
    }
  }

  @media screen and (max-width: 480px) {
    &__label {
      display: none;
    }

    &__chips {
      &--icon {
        svg {
          width: 2.3rem;
          height: 2rem;
        }
      }
    }

    &__distance {
      .form-field--wrapper {
        flex-direction: column !important;
      }

      &--unit {
        align-items: baseline;
        margin-top: 2.5rem;
      }
    }
  }
}

.seaz-search-quick-filter {
  .selected {
    border: 1px solid $seaz-mulberry;
    app-seazone-icon {
      svg {
        fill: $seaz-mulberry;
      }
    }
    &.greeny-chips {
      border: 1px solid $greeny-border-bg;
      background-color: $greeny-bg;
      color: $greeny-border-bg;

      app-seazone-icon {
        svg {
          fill: $greeny-border-bg;
        }
      }
    }
    &.chips-item-large {
      .seaz-search-chips-filter__chips--icon {
        background-color: $seaz-mulberry;
      }
    }
  }
}

.seaz-search-crew-filters-location {
  margin-bottom: 3.2rem;

  .selected {
    border: 1px solid $seaz-edit-hover;
    app-seazone-icon {
      svg {
        fill: $seaz-edit-hover;
      }
    }
  }

  .chips-distance {
    .selected {
      background-color: #00b9ff;
      color: #fff;
    }
  }

  &__label-boat-label {
    display: none;
  }

  @media screen and (max-width: 1600px) {
    .seaz-search-chips-filter__row {
      flex-direction: column;
      align-items: flex-start;
    }
  }

  @media screen and (max-width: 1024px) {
    &__desktop-row {
      display: none;
    }

    &__label-boat-label {
      display: block;
    }
  }

  @media screen and (max-width: 768px) {
    margin-bottom: 0;

    &__label-boat-label {
      display: none;
    }
  }
}

.seaz-search-map-modal {
  &__header {
    padding: 2.4rem;
  }

  &__content {
    padding: 0 2.4rem 2.4rem;
  }
}

.seaz-search-crew-filter-item {
  text-transform: capitalize;
  &--type {
    color: $clr-light-grey;
  }
  &--name {
    color: $seaz-text;
  }
}

.seaz-search__navigation_box--supplier {
  display: flex;
  align-items: center;
  width: 126.4rem;
  margin: 0 auto;

  .seaz-profile__navigation_list {
    margin: 0;
  }

  @media screen and (max-width: 1300px) {
    width: 100rem;
  }

  @media screen and (max-width: 1200px) {
    width: 100%;
    justify-content: space-between;
    padding: 0 2rem;
  }

  @media screen and (max-width: 700px) {
    flex-direction: column-reverse;
    margin-top: 1.2rem;
    .seaz__btn_primary {
      width: 100%;
    }
  }
  @media screen and (max-width: 480px) {
    padding: 0;
  }
  app-search-navigation-links {
    width: 100rem;
    @media screen and (max-width: 1350px) {
      width: 80%;
    }
    @media screen and (max-width: 1200px) {
      width: auto;
    }
    @include mobile {
      width: 100%;
    }
  }
}

.crew-search-filters-group {
  @include d-flex-space-between;
  align-items: center;

  &__row {
    @include d-align-items-center;
    flex-grow: 1;

    &:last-of-type {
      justify-content: flex-end;
      margin-left: 3.2rem;
    }
  }

  @include tablet {
    flex-direction: column;

    &__row {
      width: 100%;

      &:last-of-type {
        margin: 2.4rem 0 0;
      }
    }
  }
}
