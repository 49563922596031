.boat-systems-notes {
  margin-top: 3.2rem;

  @include tablet {
    margin-top: 1.2rem;

    &__btn {
      width: 100%;
    }
  }
}

.boat-systems-note {
  border-bottom: 1px solid $seaz-head-arrow;
  padding-bottom: 1.6rem;
  @include indent-not-last-of-type(0 0 1.6rem);

  @include tablet {
    padding-bottom: 1.2rem;
    @include indent-not-last-of-type(0 0 1.2rem);
  }
}

.boat-systems-note-info {
  @include d-align-items-center;

  &__title {
    font-size: $base-font-size;
    font-weight: 500;
    line-height: 20px;
    text-transform: uppercase;
    color: $clr-primary-and-accent;
    margin-left: 1.2rem;
  }
}

.boat-systems-note-info-media {
  width: 2.4rem;
  height: 2.4rem;
  border-radius: 50%;

  &__img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 50%;
  }
}

.boat-systems-note-description {
  display: flex;
  align-items: flex-start;
  margin-top: 1rem;

  &__content {
    width: 100%;
    margin-left: 1.4rem;
  }

  &__date {
    font-size: 1.2rem;
    line-height: 120%;
    color: $seaz-document-count;
    margin-top: 1.2rem;
  }
}

.boat-systems-note-description-info {
  font-size: 1.6rem;
  line-height: 1.9rem;
  word-break: break-word;
  color: $seaz-text;
}

.equipment-note-entry__item {
  &:not(:last-of-type) {
    margin-bottom: 1.6rem;
    padding-bottom: 1.6rem;
    border-bottom: 1px solid $seaz-head-arrow;
  }

  @include tablet {
    &:not(:last-of-type) {
      margin-bottom: 1.2rem;
      padding-bottom: 1.2rem;
    }
  }
}

.equipment-note-entry__label,
.equipment-note-entry__info {
  font-size: 1.6rem;
  line-height: 1.9rem;
  color: $seaz-text;
}

.equipment-note-entry__label,
.boat-task-note-entry__label {
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.4rem;
  letter-spacing: 0.05rem;
  text-transform: uppercase;
  color: $seaz-document-count;
  word-break: break-word;
}

.equipment-note-entry__info,
.boat-task-note-entry__info {
  display: flex;
  align-items: flex-start;
  margin-top: 0.2rem;
}

.equipment-note-entry__old,
.equipment-note-arrow-right,
.equipment-note-entry__new,
.boat-task-note-entry__old,
.boat-task-note-entry__new {
  display: block;
  text-align: justify;
}

.equipment-note-arrow-right,
.boat-task-note-arrow-right {
  margin: 0 0.7rem;

  &:before {
    content: '\279C';
    font-size: 1.6rem;
    color: #bdbdbd;
  }
}
