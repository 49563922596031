.card-slider-list {
  position: relative;

  @media screen and (min-width: 480px) {
    app-scroll-controls .controls-container {
      button {
        &:hover,
        &:active {
          background-color: initial;
          box-shadow: 0 0 10px rgb(0 0 0 / 10%);
          border-radius: 1rem;

          .icon-arrow-left,
          .icon-arrow-rights {
            &::before {
              color: initial;
            }
          }
        }
      }
    }
  }

  @include mobile {
    .cards__navigation {
      margin: 0 2rem;
    }

    app-scroll-controls .controls-container {
      button {
        width: 2rem;

        &:nth-child(1) {
          left: -0.6rem;
        }

        &:nth-child(2) {
          right: -0.6rem;
        }
      }
    }
  }
}

.card-slider-container {
  @include d-flex-space-between;
  height: 100%;
  align-items: stretch;
  box-sizing: border-box;
}

.card-slider {
  display: block;
  width: 31.4rem;
  min-width: 31.4rem;
  border: 1px solid $clr-grey;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
  border-radius: 1.6rem;
  background-color: $clr-ashy-grey;
  padding: 1.6rem;
  box-sizing: border-box;
  @include indent-not-last-of-type(0 1.6rem 0 0);

  &.active {
    background-color: #e4daff;
    outline: 3px solid $clr-primary-and-accent;
    outline-offset: -3px;
  }
  &.card-slider--aggregate {
    background-color: $seaz-success;
    > *,
    .card-slider-total {
      color: white;
    }
  }

  @include mobile {
    width: 26rem;
    min-width: 26rem;
    @include indent-not-last-of-type(0 1rem 0 0);
  }
}

.card-slider-title {
  font-size: $base-font-size;
  font-weight: 700;
  line-height: 1.6rem;
  color: $seaz-text;
  opacity: 0.6;
  margin-bottom: 0.8rem;
}

.card-slider-total {
  font-size: 2.4rem;
  font-weight: 700;
  line-height: 2.8rem;
  color: $seaz-document-count;
  margin-bottom: 0.8rem;

  &__badge {
    margin-right: 0.5rem;
  }
  &.positive {
    color: $seaz-success;
  }

  &.negative {
    color: $seaz-alarm;
  }
}

.card-slider-boat-currency {
  font-size: 1.2rem;
  color: $seaz-text;

  &__text {
    line-height: 120%;
    margin-bottom: 0.2rem;
    opacity: 0.6;
  }
}
