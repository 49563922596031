@import 'base/reset.scss';
@import 'base/variable.scss';
@import 'base/fonts.scss';
@import 'base/fontello.scss';
@import 'base/media_variables';
@import 'base/mixin.scss';
@import 'base/buttons.scss';
@import 'base/mat-theme.scss';
/*----------------------*/
@import 'layout/general/general';
@import 'layout/header/header';
@import 'layout/sidemenu';
@import 'layout/user-info';
@import 'layout/footer.scss';
@import 'layout/login';
@import 'layout/recovery-password';
@import 'layout/modal/modal';
@import 'layout/top-navigation';
@import 'layout/card/card';
@import 'layout/profile';
@import 'layout/boat/boat-home';
@import 'layout/boat/boat';
@import 'layout/form/form';
@import 'layout/form/rage-selection-date-form';
@import 'layout/form/boat-task-form';
@import 'layout/form/boat-tasks-filters-form';
@import 'layout/table/table';
@import 'layout/table/charters-table';
@import 'layout/table/dynamic-table-form';
@import 'layout/dashboard/dashboard';
@import 'layout/dashboard/crew-dashboard';
@import 'layout/dashboard/quick-access';
@import 'layout/my-profile';
@import 'layout/content-navigation';
@import 'layout/holders';
@import 'layout/general/general-table';
@import 'layout/job';
@import 'layout/search';
@import 'layout/slider';
@import 'layout/contracts';
@import 'layout/company';
@import 'layout/charter/charters';
@import 'layout/charter/charter-calendar';
@import 'layout/charter/charter-tasks';
@import 'layout/charter/charter-preference-list';
@import 'layout/charter/charter-fee';
/*-----------------------*/
@import 'layout/tooltip';

/*-----------------------*/
@import 'layout/default/default-global-style';

/*-----------------------*/
@import 'layout/default/default-title';

/*-----------------------*/
@import 'layout/overview/overview-statistic';
@import 'layout/overview/overview-card';

/*-----------------------*/
@import 'layout/shortcuts/shortcuts';
@import 'layout/shortcuts/shortcut-card';
@import 'layout/shortcuts/shortcuts-list';

/*-----------------------*/
@import 'layout/card/info-card-alternate';
@import 'layout/card/cards';
@import 'layout/card/card-slider';
@import 'layout/card/card-statements';

/*-----------------------*/
@import 'layout/release-info';
@import 'layout/alert-info';

/*-----------------------*/
@import 'layout/budget';
@import 'layout/seaz/seaz-budget';
/*-----------------------*/
@import 'layout/calendar/calendar';

/*-----------------------*/
@import 'layout/seaz/seaz-user-downloads';

/*-----------------------*/
@import 'layout/seaz/seaz-charter-expenses';

/*-----------------------*/
@import 'layout/search/search-card';

/*-----------------------*/
@import 'layout/search/crew-search-card';

/*-----------------------*/
@import 'layout/search/job-search-list';

/*-----------------------*/
@import 'layout/duration-mode-control';

/*-----------------------*/
@import 'layout/charter/charter-available-days';

/*-----------------------*/
@import 'layout/available-days-quantity';

/*-----------------------*/
@import 'layout/user/user-download-notification';

/*-----------------------*/
@import 'layout/user/user-notification-modal';

/*-----------------------*/
@import 'layout/couple';

/*-----------------------*/
@import 'layout/boat/boat-calendar';

/*-----------------------*/
@import 'layout/calendar/calendar-event-select';

/*-----------------------*/
@import 'layout/boat/boat-event-modal';

/*-----------------------*/
@import 'layout/boat/boat-type';

/*-----------------------*/
@import 'layout/boat/boat-navigation';

/*-----------------------*/
@import 'layout/boat/boat-documents';

/*-----------------------*/
@import 'layout/boat/boat-documents-filter';

/*-----------------------*/
@import 'layout/boat/boat-document';

/*-----------------------*/
@import 'layout/boat/boat-documents-folders';

/*-----------------------*/
@import 'layout/boat/folders-list';

/*-----------------------*/
@import 'layout/boat-maintenance/boat-maintenance-equipment-tree';

/*-----------------------*/
@import 'layout/boat/boat-task-card';

/*-----------------------*/
@import 'layout/boat/boat-tasks-list';

/*-----------------------*/
@import 'layout/boat/boat-tasks-calendar';

/*-----------------------*/
@import 'layout/boat/boat-tasks-menu';

/*-----------------------*/
@import 'layout/boat-maintenance/boat-maintenance-tasks-action-panel';

/*-----------------------*/
@import 'layout/boat/boat-assignee-settings';

/*-----------------------*/
@import 'layout/boat/boat-tasks-status';

/*-----------------------*/
@import 'layout/boat-maintenance/boat-maintenance-task-log';

/*-----------------------*/
@import 'layout/boat-maintenance/boat-maintenance-dashboard';

/*-----------------------*/
@import 'layout/boat-maintenance/boat-maintenance-alerts';

/*-----------------------*/
@import 'layout/boat-maintenance/boat-maintenance-dashboard-statistic';

/*-----------------------*/
@import 'layout/boat-maintenance/boat-maintenance-dashboard-assignee';

/*-----------------------*/
@import 'layout/boat-maintenance/boat-maintenance-dashboard-map';

/*-----------------------*/
@import 'layout/boat-maintenance/boat-maintenance-dashboard-calendar';

/*-----------------------*/
@import 'layout/boat-maintenance/boat-maintenance-dashboard-systems';

/*-----------------------*/
@import 'layout/boat-maintenance/boat-maintenance-work-list';

/*-----------------------*/
@import 'layout/boat-maintenance/boat-maintenance-task-quote';

/*-----------------------*/
@import 'layout/boat-maintenance/boat-maintenance-checklist';

/*-----------------------*/
@import 'layout/boat-maintenance/boat-maintenance-checklists-form';

/*-----------------------*/
@import 'layout/boat-maintenance/maintenance-checklist-tables';

/*-----------------------*/
@import 'layout/boat-maintenance/checklist-status-bar';

/*-----------------------*/
@import 'layout/boat-maintenance/boat-maintenance-checklists-view-form';

/*-----------------------*/
@import 'layout/boat-maintenance/boat-maintenance-checklists-view';

/*-----------------------*/
@import 'layout/boat/boat-systems-tasks';

/*-----------------------*/
@import 'layout/boat/boat-inventory-list';

/*-----------------------*/
@import 'layout/boat/boat-inventory';

/*-----------------------*/
@import 'layout/boat/boat-inventory-location-settings';

/*-----------------------*/
@import 'layout/boat/boat-inventory-quick-filter';

/*-----------------------*/
@import 'layout/boat/boat-position-history';

/*-----------------------*/
@import 'layout/outstanding/outstanding';

/*-----------------------*/
@import 'layout/outstanding/outstanding-table';

/*-----------------------*/
@import 'layout/outstanding/outstanding-payment-form';

/*-----------------------*/
@import 'layout/seaz/seaz-quick-filters';

/*-----------------------*/
@import 'layout/group/group-checkbox';

/*-----------------------*/
@import 'layout/not-found';

/*-----------------------*/
@import 'layout/outstanding/outstanding-view';

/*-----------------------*/
@import 'layout/seaz/seaz-tabs';

/*-----------------------*/
@import 'layout/seazone-colored-select';

/*-----------------------*/
@import 'layout/outstanding/outstanding-payment-email';

/*-----------------------*/
@import 'layout/label-badges';

/*-----------------------*/
@import 'layout/date-range-control';

/*-----------------------*/
@import 'layout/switch-tabs';

/*-----------------------*/
@import 'layout/calendar/calendar-emails-modal';

/*-----------------------*/
@import 'layout/form-fild-control';

/*-----------------------*/
@import 'layout/expense-view';

/*-----------------------*/
@import 'layout/edit-supplier-profile';

/*-----------------------*/
@import 'layout/service/service-form';

/*-----------------------*/
@import 'layout/suggested-services';

/*-----------------------*/
@import 'layout/contacts-form';

/*-----------------------*/
@import 'layout/range-data-field';

/*-----------------------*/
@import 'layout/service/service-brands-modal';

/*-----------------------*/
@import 'layout/service/brands-services';

/*-----------------------*/
@import 'layout/supplier/supplier-general-edit';

/*-----------------------*/
@import 'layout/supplier/branch-supplier';

/*-----------------------*/
@import 'layout/supplier/supplier-short-card';

/*-----------------------*/
@import 'layout/working-time';

/*-----------------------*/
@import 'layout/additional-video';

/*-----------------------*/
@import 'layout/view.profile';

/*-----------------------*/
@import 'layout/media/media-content';

/*-----------------------*/
@import 'layout/modal/cover-letter-modal';

/*-----------------------*/
@import 'layout/modal/ratings-modal';

/*-----------------------*/
@import 'layout/modal/serch-all-services-modal';

/*-----------------------*/
@import 'layout/modal/view-all-brands-modal';

/*-----------------------*/
@import 'layout/modal/check-auth-modal';

/*-----------------------*/
@import 'layout/modal/supplier-branches-modal';

/*-----------------------*/
@import 'layout/modal/apply-form-modal';

/*-----------------------*/
@import 'layout/modal/invite-friends-modal';

/*-----------------------*/
@import 'layout/modal/job-search-auth-modal';

/*-----------------------*/
@import 'layout/modal/matching-modal';

/*-----------------------*/
@import 'layout/modal/view-rate-modal';

/*-----------------------*/
@import 'layout/modal/completion-modal';

/*-----------------------*/
@import 'layout/modal/general-experience-management';

/*-----------------------*/
@import 'layout/modal/contact-persons-modal';

/*-----------------------*/
@import 'layout/modal/download-info-modal';

/*-----------------------*/
@import 'layout/modal/contact-person-edit-modal';

/*-----------------------*/
@import 'layout/modal/boat-documents-modal';

/*-----------------------*/
@import 'layout/modal/fleet-modal';

/*-----------------------*/
@import 'layout/modal/fleet-expenses-filter-modal';

/*-----------------------*/
@import 'layout/modal/fleet-calendar-details-modal';

/*-----------------------*/
@import 'layout/modal/share-modal';

/*-----------------------*/
@import 'layout/modal/download-crew-guests';

/*-----------------------*/
@import 'layout/modal/manager-modal';

/*-----------------------*/
@import 'layout/modal/charter-manage-guests-modal';

/*-----------------------*/
@import 'layout/modal/outstanding-status';

/*-----------------------*/
@import 'layout/modal/document-access';

/*-----------------------*/
@import 'layout/modal/system-manager-form';

/*-----------------------*/
@import 'layout/modal/maintenance-filters-modal';

/*-----------------------*/
@import 'layout/modal/maintenance-tree-modal';

/*-----------------------*/
@import 'layout/modal/systems-note-modal';

/*-----------------------*/
@import 'layout/modal/boat-maintenance-task-view-modal';

/*-----------------------*/
@import 'layout/modal/boat-assignee-selection-modal';

/*-----------------------*/
@import 'layout/modal/calendar-selection-modal';

/*-----------------------*/
@import 'layout/modal/ais-position-modal';

/*-----------------------*/
@import 'layout/modal/boat-maintenance-work-list-form-modal';

/*-----------------------*/
@import 'layout/modal/boat-inventory-view';

/*-----------------------*/
@import 'layout/modal/boat-inventory-status-change-modal';

/*-----------------------*/
@import 'layout/ratings/rating-stars';

/*-----------------------*/
@import 'layout/ratings/ratings-person';

/*-----------------------*/
@import 'layout/ratings/rating-form';

/*-----------------------*/
@import 'layout/ratings/rating-list';

/*-----------------------*/
@import 'layout/supplier/supplier-card';

/*-----------------------*/
@import 'layout/group/group-action-buttons';

/*-----------------------*/
@import 'layout/stash-items';

/*-----------------------*/
@import 'layout/supplier/supplier-search-all';

/*-----------------------*/
@import 'layout/map-info';

/*-----------------------*/
@import 'layout/header/unauth-header';

/*-----------------------*/
@import 'layout/controls/range';

/*-----------------------*/
@import 'layout/invite';

/*-----------------------*/
@import 'layout/referrals/referral';

/*-----------------------*/
@import 'layout/referrals/referrals-list';

/*-----------------------*/
@import 'layout/social/social-auth-control';

/*-----------------------*/
@import 'layout/registration';

/*-----------------------*/
@import 'layout/business-cards/business-cards';

/*-----------------------*/
@import 'layout/business-cards/business-card-list';

/*-----------------------*/
@import 'layout/social/social-registration';

/*-----------------------*/
@import 'layout/nav-sub-menu';

/*-----------------------*/
@import 'layout/claim';

/*-----------------------*/
@import 'layout/banners';

/*-----------------------*/
@import 'layout/filter-by-offer';

/*-----------------------*/
@import 'layout/fleets/fleets';

/*-----------------------*/
@import 'layout/fleets/fleet-card';

/*-----------------------*/
@import 'layout/fleets/fleet-form';

/*-----------------------*/
@import 'layout/fleets/info-card';

/*-----------------------*/
@import 'layout/fleets/boat-fleets';

/*-----------------------*/
@import 'layout/fleets/boat-fleets-header';

/*-----------------------*/
@import 'layout/fleets/boat-fleet-nav';

/*-----------------------*/
@import 'layout/fleets/fleet-activity';

/*-----------------------*/
@import 'layout/fleets/fleet-info';

/*-----------------------*/
@import 'layout/fleets/fleet-expenses';

/*-----------------------*/
@import 'layout/fleets/fleet-job-offers';

/*-----------------------*/
@import 'layout/fleets/fleet-news';

/*-----------------------*/
@import 'layout/fleets/fleets-map';

/*-----------------------*/
@import 'layout/fleets/fleet-charters';

/*-----------------------*/
@import 'layout/fleets/fleet-calendar';

/*-----------------------*/
@import 'layout/fleets/boat-fleet-calendar-header';

/*-----------------------*/
@import 'layout/fleets/fleet-calendar-list';

/*-----------------------*/
@import 'layout/fleets/boat-fleet-documents';

/*-----------------------*/
@import 'layout/fleets/boat-fleets-contacts';

/*-----------------------*/
@import 'layout/fleets/fleet-guest-details';

/*-----------------------*/
@import 'layout/fleets/fleet-charter-card';

/*-----------------------*/
@import 'layout/fleets/fleet-notes-list';

/*-----------------------*/
@import 'layout/shared-auth';

/*-----------------------*/
@import 'layout/shared-apa-report-view';

/*-----------------------*/
@import 'layout/boat-systems/systems';

/*-----------------------*/
@import 'layout/boat-systems/systems-tabs';

/*-----------------------*/
@import 'layout/boat-systems/systems-menu';

/*-----------------------*/
@import 'layout/boat-systems/maintenance-card';

/*-----------------------*/
@import 'layout/boat-systems/maintenance-equipment-tree';

/*-----------------------*/
@import 'layout/boat-systems/system-info';

/*-----------------------*/
@import 'layout/boat-systems/system-details';

/*-----------------------*/
@import 'layout/boat-systems/boat-systems-notes';

/*-----------------------*/
@import 'layout/boat-systems/boat-equipment-mileage';

/*-----------------------*/
@import 'layout/boat-systems/systems-search';

/*-----------------------*/
@import 'layout/boat-systems/boat-system-settings';

/*-----------------------*/
@import 'layout/petty-cash/petty-cash';
