app-fleet-whats-new {
  width: 100%;
}

.fleet-news {
  width: 100%;
  height: 100%;

  &__title {
    text-transform: uppercase;
  }
}

.fleet-news-content {
  height: 45.6rem;
  overflow-y: auto;
  padding-right: 1rem;
  margin-top: 2rem;
  @include custom-scroll-bar;
  word-break: break-word;
}
