.content-navigation {
  &_crew {
    margin-top: 5rem;
  }
  &__item {
    border-bottom: 0.1rem solid rgba($color: $clr-purple, $alpha: 0.2);
  }
  &__link {
    display: flex;
    align-items: center;
    padding: 1.45rem 0;
    color: #4d4e67;
    &:hover .content-navigation__icon {
      background-color: rgba($color: $clr-purple, $alpha: 0.2);
    }
  }
  &__text {
    flex-grow: 1;
    font: 1.6rem/1.9rem $base-font;
  }
  &__icon {
    width: 2.6rem;
    height: 2.6rem;
    border-radius: 50%;
    font-size: 1rem;
    letter-spacing: -0.2rem;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #4d4e67;
    background-color: rgba($color: $clr-purple, $alpha: 0.08);
    transition: $tr_03;
  }
}
