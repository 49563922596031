.my-profile {
  display: flex;
  @include mobile {
    flex-flow: row wrap;
  }
  &__avatar {
    height: 13.5rem;
    width: 13.5rem;
    min-width: 13.5rem;
    position: relative;
    @include tablet {
      height: 10rem;
      width: 10rem;
      min-width: 10rem;
      & .avatar {
        height: 100%;
      }
    }
    @include mobile {
      height: 8rem;
      width: 8rem;
      min-width: 8rem;
    }
    & > .avatar {
      width: 100%;
      height: 100%;
    }
    &-remove {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 2.4rem;
      height: 2.4rem;
      border-radius: 50%;
      background-color: #fff;
      border: 2px solid #fafafb;
      cursor: pointer;
      position: absolute;
      top: -0.5rem;
      right: -1rem;
      &:before {
        color: #d70000;
      }
    }
  }
  &__add-image {
    position: absolute;
    right: 0;
    bottom: 0;
    @include tablet {
      height: 3rem;
      width: 3rem;
      font-size: 1.6rem;
    }
    @include mobile {
      height: 2.2rem;
      width: 2.2rem;
      font-size: 1.2rem;
    }
  }
  &__content {
    flex-grow: 1;
    padding-right: 3rem;
    padding-left: 3rem;
    @include tablet {
      padding-left: 2.5rem;
      width: calc(100% - 8rem);
    }
    @include mobile {
      padding-left: 1.2rem;
      padding-right: 0;
    }
  }
  &__title {
    margin-top: -0.9rem;
    line-height: 3.5rem;
    @include tablet {
      line-height: 2.1rem;
      margin-top: 0;
    }
  }
  &__description {
    margin-top: 0.3rem;
    @include tablet {
      font-size: 1.2rem;
      line-height: 1.6rem;
    }
    @include mobile {
      font-size: 1.1rem;
      line-height: 1.3rem;
      margin-top: 0.7rem;
    }
  }
  &__status {
    margin-top: 1.5rem;
    &-title {
      color: $clr-purple;
      font-size: 1.1rem;
      line-height: normal;
      margin-bottom: 0.7rem;
    }
    @include mobile {
      margin-top: 0.8rem;
    }
  }
  &__button {
    @include mobile {
      flex-grow: 1;
      padding-top: 2.7rem;
      & > .main-btn {
        width: 100%;
      }
    }
  }
  &__main-btn {
    padding: 0.8rem;
  }
}
