.boat-fleets {
  padding: 4.8rem 4.8rem 9rem;
  background-color: #fbfbfb;

  @include large {
    padding: 1.6rem 1.6rem 5rem;
  }
}

.boat-fleet-container {
  width: 100%;
  max-width: 120.7rem;
  margin: 0 auto;
}

.fleet-container {
  display: grid;
  grid-template-columns: 73% minmax(25%, 1fr);
  grid-gap: 0 2.4rem;

  @include desktop {
    grid-template-columns: 1fr;
  }
}

.boat-fleets-top-indent {
  margin-top: 2.4rem;
}

.fleets-select {
  width: 100%;
  max-width: 37.2rem;
  min-width: 29.5rem;
  border-radius: 100rem;

  //text-overflow: ellipsis;
  //white-space: nowrap;
  //overflow: hidden;

  .wrapper-rounded .form-field--content {
    height: 5.6rem;
    box-shadow: 0 4px 8px rgba(43, 28, 54, 0.24);
    border-radius: 100rem;
    border: none;
  }

  .select-html-container {
    @include d-align-items-center;
    font-size: 2rem;
    font-weight: 500;
    line-height: 120%;
    color: $clr-primary-and-accent;

    img {
      display: block;
      width: 3.2rem;
      height: 3.2rem;
      border-radius: 50%;
      margin-right: 0.8rem;
    }
  }

  @include tablet {
    max-width: 29rem;
    min-width: 25rem;
  }

  @include mobile {
    max-width: 20rem;
    min-width: 19rem;
  }
}

.boat-fleets-header-select-dropdown {
  .dropdown-container {
    margin-top: 0.4rem;
  }

  .dropdown-options-container {
    min-width: 26rem !important;
    box-sizing: border-box;
    border-radius: 1.6rem;
  }

  app-seazone-select-option {
    padding: 1.6rem;
  }
}

.fleets-select-option {
  @include d-align-items-center;

  font-size: 1.4rem;
  font-weight: 400;
  line-height: 1.6rem;
  color: $seaz-about-text;

  &__img {
    display: block;
    width: 2.4rem;
    height: 2.4rem;
    border-radius: 50%;
    margin-right: 1.2rem;
  }

  &__text {
    width: 19rem;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
}

.boat-fleets-container {
  @include light-bg-border-corners;
  background-color: $light-color;
  padding: 2.4rem;

  @include laptop {
    padding: 1.6rem;
  }

  @include mobile {
    padding: 1.2rem;
  }
}
