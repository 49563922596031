.apply-form-modal {
  &__title {
    margin-bottom: 1.6rem;
  }

  &__questions-box {
    margin-top: 3rem;
    padding: 2.4rem;
    border: 1px solid $seaz-head-arrow;
    border-radius: 1.2rem;
  }

  app-info-label {
    .info-label.purple {
      background-color: rgba(53, 31, 115, 0.08);
    }
  }

  &__container {
    margin-top: 3rem;
  }

  &__text-area {
    margin-top: 1.2rem;

    .form-field__area {
      min-height: 8rem;
    }
  }

  &__wish {
    margin-bottom: 3rem;
  }

  &__footer {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    border-top: 1px solid $seaz-head-arrow;
    padding: 3rem 0;
  }

  &__btn {
    display: block;

    &:not(:last-of-type) {
      margin-right: 1.6rem;
    }
  }

  .wrapper-rounded.form-field--wrapper__row .form-field--label {
    margin-right: 7rem;
  }

  @include tablet {
    &__footer {
      flex-direction: column;
    }

    &__btn {
      width: 100%;

      &:not(:last-of-type) {
        margin: 0 0 1.6rem;
      }
    }
  }

  @include mobile {
    .seaz-uploader__document {
      .seaz-uploader__document_label {
        width: 50%;
        flex-shrink: 1;
      }

      .seaz-uploader__document_file_box {
        display: flex;
        flex-direction: column;
        align-items: flex-end;
      }
    }
  }
}

.questions {
  margin-top: 3rem;

  &__label {
    display: block;
    color: $seaz-text;
    margin-bottom: 2.2rem;
    word-break: break-word;
  }

  &__actions {
    .form__radio-container {
      @include d-align-items-center;
    }

    .form__radio {
      &:not(:last-of-type) {
        margin-right: 3.4rem;
      }
    }
  }
}
