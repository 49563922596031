.not-found {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 80vh;
  padding: 5rem 1rem;
  text-align: center;

  &__img-box {
    width: 20.7rem;
  }

  &__img {
    width: 100%;
    height: 100%;
    object-fit: contain;
  }

  &__title {
    font-size: 5rem;
    font-weight: 500;
    line-height: 5rem;
    letter-spacing: -0.2rem;
    color: $clr-primary-and-accent;
    margin-top: 5rem;
  }

  &__text {
    font-size: 2.4rem;
    line-height: 2.4rem;
    color: $seaz-about-text;
    margin-top: 2rem;
  }

  &__btn {
    margin-top: 2rem;
  }

  @include tablet {
    padding: 7rem 1rem;

    &__img-box {
      width: 25.7rem;
    }

    &__title {
      font-size: 3rem;
      line-height: 4rem;
      margin-top: 2.4rem;
    }

    &__text {
      font-size: 1.8rem;
      margin-top: 2rem;
    }

    &__btn {
      margin-top: 2.4rem;
    }
  }
}
