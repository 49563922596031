.boat-maintenance-checklists-form-list {
  max-height: 65rem;
  overflow-y: auto;

  &.scrollable {
    @include custom-scroll-bar;
    padding-right: 0.5rem;
  }
}

.boat-maintenance-checklists-form-header-action {
  @include box-grid(1fr auto, initial, 'selects actions' 'last-edit last-edit', 1.8rem 1rem);
  align-items: end;

  .content-grid-item {
    grid-area: selects;
  }

  .actions-grid-item {
    grid-area: actions;
    @include d-align-items-center;
    gap: 0 1.6rem;

    @include laptop {
      app-checklist-status-bar {
        width: 100%;
      }
    }

    @include tablet {
      flex-direction: column;
      align-items: flex-start;
      gap: 2.4rem 0;
    }
  }

  .last-edit-grid-item {
    grid-area: last-edit;
  }

  .checklists-form-header-action-group {
    @include d-align-items-center;
    gap: 0 1.6rem;
    flex-shrink: 0;

    &__edit {
      display: none;
    }

    @include tablet {
      order: -1;

      &__text {
        display: none;
      }

      &__edit {
        @include d-align-items-center;
        flex-wrap: wrap;
        gap: 1.2rem;
      }

      .seaz__btn_rounded--icon {
        margin-right: 0;
      }
    }
  }

  .checklists-form-header-action-desktop {
    @include tablet {
      display: none;
    }
  }

  @include laptop {
    @include box-grid(1fr, initial, 'selects' 'last-edit' 'actions', 2rem 0);
  }
}

.boat-maintenance-checklists-form-header-selects {
  display: flex;
  align-items: flex-end;
  max-width: 52.2rem;

  .seaz__return_btn {
    margin-right: 1.6rem;
  }

  &__field {
    min-width: 22.7rem;
    @include indent-not-last-of-child(0 1.2rem 0 0);
  }

  @include laptop {
    width: 100%;
    max-width: unset;

    .seaz__return_btn {
      display: none;
    }

    &__field {
      width: 50% !important;
      min-width: unset;
      @include indent-not-last-of-child(0 1.2rem 0 0);
    }
  }

  @include mobile {
    flex-direction: column;

    &__field {
      width: 100% !important;
      @include indent-not-last-of-child(0 0 1.8rem 0);
    }
  }
}

.boat-maintenance-checklists-form-header-list-action {
  @include d-flex-space-between;
  align-items: center;
  background-color: $seaz-bg;
  border-radius: 24px 24px 0 0;
  padding: 1.2rem 2.4rem;
  margin-top: 2.4rem;

  &__group {
    @include d-align-items-center;
    gap: 1rem;
  }

  @include tablet {
    display: none;
  }
}

.boat-maintenance-checklists-form-header-list-action-view {
  &__group {
    @include d-align-items-center;
    gap: 1.2rem;
  }
}

.boat-maintenance-checklists-action-view {
  @include d-align-items-center;
  gap: 0 1.2rem;
}

.boat-maintenance-checklists-form-header-list-action-group {
  .form__checkbox-label-text {
    padding-left: 1.1rem;
  }
}

.boat-maintenance-checklists-form-container {
  box-shadow: 0 2px 2px rgba(43, 28, 54, 0.16);
  border-radius: 0 0 24px 24px;
  background-color: #fff;
  padding: 2.4rem;

  @include tablet {
    border-radius: 24px;
    padding: 1.2rem;
    margin-top: 2.4rem;
  }
}

.boat-maintenance-checklists-form-info {
  font-size: 1.4rem;
  line-height: 1.6rem;
  color: $seaz-text;
  margin-bottom: 2.4rem;

  &__title {
    font-weight: 700;
    margin-bottom: 0.4rem;
  }
}

.boat-maintenance-checklists-form-info-edit {
  margin-bottom: 4rem;

  app-seazone-text-area .wrapper-rounded .form-field--content {
    height: 8rem;
  }
}

.boat-maintenance-checklists-form-item {
  @include box-grid(3.2rem 1fr 6rem 4rem, initial, initial, 0 1.6rem);
  align-items: center;
  background-color: $light-color;
  border: 1px solid $seaz-head-arrow;
  border-radius: 12px;
  padding: 1.6rem;
  @include indent-not-last-of-child(0 0 1.2rem 0);

  @include tablet {
    @include box-grid(2.4rem 1fr 6rem, initial, 'arrow description description' 'arrow count actions', 1rem 1.6rem);

    .count-item {
      grid-area: count;
    }

    .field-item {
      grid-area: description;
    }

    .action-items {
      grid-area: actions;
    }

    .arrow-items {
      grid-area: arrow;
    }
  }
}

.boat-maintenance-checklists-form-item-description-field {
  padding-bottom: 1.65rem;

  .form-field__area {
    min-height: unset;
  }

  @include tablet {
    padding-bottom: 2.4rem;
    border-bottom: 1px solid $seaz-bg;
  }
}

.boat-maintenance-checklists-form-item-action {
  @include d-align-items-center;
  justify-content: space-between;
  gap: 1.6rem;
}

.boat-maintenance-checklists-form-item-arrow {
  border-left: 1px solid $seaz-head-arrow;
  padding-left: 1.6rem;

  &__btn {
    @include indent-not-last-of-child(0 0 1.6rem 0);
  }

  @include tablet {
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    height: 100%;
    border: none;
    padding: 0;
  }
}

.boat-maintenance-checklists-last-edit-info {
  font-size: 1.4rem;
  line-height: 1.6rem;
  color: $profile-month;

  &__val {
    margin-left: 1.2rem;
    color: $seaz-text;
    word-break: break-word;
  }
}

.boat-maintenance-checklist-panel {
  .boat-maintenance-checklists-form-header-list-action__group {
    flex-wrap: wrap;
    margin-top: 1rem;
  }

  .maintenance-checklists-form-list-action-add,
  .maintenance-checklists-form-list-action-checkbox {
    border: 1px solid $clr-primary-and-accent;
    background-color: $light-color;
    border-radius: 0.4rem;
    color: $clr-primary-and-accent;
  }

  .maintenance-checklists-form-list-action-checkbox {
    padding: 0.8rem 1rem;

    .form__checkbox-label {
      width: auto;
    }

    .form__checkbox-label-text {
      padding-left: 1.1rem;
    }
  }

  .maintenance-checklists-form-list-action-save {
    background-color: $seaz-success;
  }

  .seaz__btn_rounded--delete.maintenance-checklists-form-list-action-delete {
    background-color: $light-color;
    padding: 0.8rem 1rem;
  }
}
