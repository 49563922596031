.boat-tasks-calendar-wrap {
  width: 100%;
  max-width: 42.1rem;
  height: auto;
  background-color: $light-color;
  border: 1px solid $seaz-head-arrow;
  border-radius: 1.2rem;
  padding: 3rem;
  box-sizing: border-box;

  &__desktop {
    margin-right: 3.2rem;
  }

  @include extra-large {
    max-width: 38rem;
    padding: 2rem;

    &__desktop {
      margin-right: 2rem;
    }
  }

  @include large {
    &__desktop {
      display: none;
    }
  }
}

.boat-tasks-calendar-modal.boat-tasks-calendar-wrap {
  max-width: 100%;
  border: 0;
  padding: 0;
}

.boat-tasks-calendar-title {
  margin-bottom: 3.8rem;

  app-boat-timezone {
    display: block;
  }
}

.boat-tasks-calendar {
  width: 100%;
  max-width: 36.1rem;
}

.boat-tasks-calendar-modal-footer {
  @include d-align-items-center;
  margin: 3rem 0 0;
  padding-bottom: 1rem;

  &__btn {
    width: 50%;

    @include indent-not-last-of-type(0 1.2rem 0 0);
  }
}

.calendar-tasks-actions {
  @include d-flex-space-between;
  align-items: center;
  margin-bottom: 3.3rem;

  &__title {
    color: $seaz-text;
  }
}

.calendar-tasks-actions-arrow {
  display: block;

  svg {
    stroke: $profile-month;

    path {
      stroke-width: 4px;
    }
  }
}

.calendar-tasks-actions-next {
  transform: rotateZ(180deg);
}

.calendar-tasks-view-grid {
  display: grid;
  grid-template-columns: repeat(7, 1fr);
  grid-auto-columns: 5.1rem;
  align-self: center;
}

.calendar-tasks-view-header {
  &__item {
    font-size: 1.2rem;
    line-height: 120%;
    color: $profile-month;
    text-align: center;
  }
}

.calendar-tasks-view-days {
  margin-top: 0.9rem;
}

.calendar-tasks-view-days-item {
  &:first-of-type {
    .calendar-tasks-view-day {
      &:first-of-type {
        border-radius: 1.2rem 0 0 0;
      }

      &:last-of-type {
        border-radius: 0 1.2rem 0 0;
      }
    }
  }

  &:last-of-type {
    .calendar-tasks-view-day {
      &:first-of-type {
        border-radius: 0 0 0 1.2rem;
      }

      &:last-of-type {
        border-radius: 0 0 1.2rem 0;
      }
    }
  }
}

.calendar-tasks-view-day {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  min-height: 5.44rem;
  border: 0.05rem solid $seaz-head-arrow;
  padding: 0.3rem;
  box-sizing: border-box;

  &:not(.disabled):not(.hide) {
    cursor: pointer;
  }

  &__date {
    font-size: 2rem;
    font-weight: 500;
    line-height: 120%;
    color: $seaz-text;
  }

  &__event {
    font-size: 1.2rem;
    line-height: 120%;
    color: $light-color;
    background-color: $green-grey;
    border-radius: 50%;
    padding: 0.2rem 0.6rem;
    margin-top: 0.4rem;
  }

  &.today:not(.hide) {
    background-color: $clr-primary-and-accent;

    .calendar-tasks-view-day__date {
      color: $light-color;
    }

    .calendar-tasks-view-day__event {
      color: $clr-primary-and-accent;
      background-color: $light-color;
    }
  }

  &.active:not(.hide) {
    border: 0.1rem solid #3f9795;
  }

  &.disabled {
    .calendar-tasks-view-day__date {
      color: $seaz-border;
    }
  }

  &.hide * {
    visibility: hidden;
  }

  @include mobile {
    &__date {
      font-size: 1.6rem;
    }
  }
}
