.system-manager-form {
  margin-top: 4rem;

  &__row-container {
    display: flex;
    flex-direction: column;
    width: 100%;
  }

  &__row {
    @include d-align-items-center;
    &--hours-column {
      .system-manager-form__label--hours-label,
      .system-manager-form__toggle-checkbox {
        margin-top: 2rem;
      }
      .system-manager-form__hours-field .form-field--label {
        font-size: 1.2rem;
      }
    }
  }

  &__row-date {
    align-items: baseline;
  }

  &__label {
    display: block;
    width: 100%;
    min-width: 100%;
    margin-bottom: 0.8rem;

    &--radio-label {
      margin-bottom: 1.2rem;
    }

    &--file-label,
    &--hours-label {
      display: none;
    }
  }

  &__toggle-checkbox {
    margin-left: 1.8rem;
  }

  app-seazone-radio-item {
    @include indent-not-last-of-type(0 0 2rem);
  }

  .seaz-uploader__header,
  .seaz-uploader {
    margin: 0;
  }

  .warranty-checkbox {
    margin-top: 2.7rem;

    .form__checkbox-label-text {
      font-size: $base-font-size;
      color: $seaz-text;
    }
  }

  &__hours-field {
    @include indent-not-last-of-type(0 1.2rem 0 0);

    .form-field--wrapper__row {
      flex-direction: column;
    }
    &--service {
      .form-field--label {
        max-width: fit-content;
      }
    }
  }

  @media screen and (max-width: 650px) {
    .form-field--wrapper__row .form-field--label {
      max-width: 14rem;
    }
  }

  @media screen and (min-width: 480px) {
    &__label {
      display: block;
      width: 14rem;
      min-width: 14rem;
      margin: 0 2rem 0 0;

      &--radio-label {
        margin-bottom: 0;
      }

      &--file-label,
      &--hours-label {
        display: block;
      }
    }
  }

  @include mobile {
    .form__row {
      margin-bottom: 1.6rem;
    }

    &__row {
      &--hours-row {
        flex-direction: row;
      }
    }

    .seaz-uploader__label {
      padding: 0;
    }

    .warranty-radio {
      .form__radio-container {
        display: flex;
      }

      app-seazone-radio-item {
        @include indent-not-last-of-type(0 1.4rem 0 0);
      }
    }

    .warranty-checkbox {
      margin-top: 1.1rem;

      .form__checkbox-label {
        padding: 0;
      }
    }
  }

  .info-label.error {
    font-size: 1.1rem;
  }

  .plug-label {
    flex-shrink: 0;
  }
}

.system-manager-files {
  app-seazone-media-uploader-with-custom-name {
    width: 100%;
  }

  .seaz-uploader__choose {
    order: -1;
  }

  app-seazone-media-uploader,
  .seaz-uploader__video_row {
    width: 100%;
  }

  .seaz-uploader__video_btn {
    top: 4.8rem;
    right: 1.8rem;
  }

  .uploader-action {
    width: 18.7rem;
    margin-bottom: 1rem;
  }

  .seaz-uploader__video {
    width: 12.3rem;
    height: 12.3rem;
    border-radius: 0.8rem;
    margin-top: 2rem;
  }

  .seaz-uploader__video_item {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 0.8rem;
  }

  .seaz-uploader__video_play {
    width: 3rem;
    height: 3rem;
    top: 50%;
    transform: translateY(-50%);
    background: $light-color;
  }

  .seaz-uploader__video_play::before {
    border: 5px solid transparent;
    border-left: 8px solid #000;
    top: 50%;
    left: 50%;
    transform: translate(-18%, -50%);
  }

  .seaz-modal__document_wrapper {
    position: static;
    margin-top: 1.2rem;
  }

  @include tablet {
    .seaz-uploader__video_btn {
      right: 1.2rem;
    }

    .seaz-uploader__choose {
      justify-content: flex-start;
    }
  }

  @media screen and (max-width: 560px) {
    .seaz-uploader__video_row {
      justify-content: flex-start;
    }
  }

  @include mobile {
    .seaz-uploader__video_btn {
      top: 5.2rem;
    }
  }
}

.system-manager-toggle {
  @include border-top;
  padding-top: 2.4rem;
  flex-wrap: wrap;
  app-toggle-checkbox {
    @include d-align-items-center;
    flex-direction: row-reverse;
  }

  .form-field__label {
    margin-left: 1rem;
  }

  .form__block-error {
    position: relative;
    margin: 1rem 0 0;
    @include multiline-truncation(2);
  }
}

.system-manager-form-footer {
  @include footer-with-border-left-btn(3rem 0 1.5rem 0, 0);

  &__btn {
    @include indent-not-last-of-type(0 1.2rem 0 0);
  }
}
