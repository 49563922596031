.supplier-rating-history {
  margin-top: 2.4rem;
}

.rating-history {
  padding-top: 2.4rem;
  @include border-top;

  &__title {
    color: $seaz-primary-and-accent;
    line-height: 1.4;
    margin-bottom: 1.2rem;
  }

  &__description {
    line-height: 1.6rem;
    color: $clr-light-grey;
    margin-bottom: 1.2rem;
  }

  &__date {
    font-size: 1.2rem;
    font-weight: 500;
    letter-spacing: 0.1em;
    text-transform: uppercase;
    color: $seaz-border;
  }

  &__reported {
    font-size: 1.2rem;
    font-weight: 500;
    letter-spacing: 0.1em;
    text-transform: uppercase;
    color: $seaz-error;
    margin-top: 1.2rem;
  }

  &__report-btn {
    font-size: 1.2rem;
    padding: 0.8rem 1.6rem;
    margin-top: 1.2rem;
  }
}

.rating-list {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-gap: 0 0.5rem;
  margin-bottom: 1.4rem;

  &__title {
    color: $clr-light-grey;
    line-height: 1.6rem;
    margin-bottom: 0.4rem;
  }

  .star-rating {
    justify-content: flex-start;
    padding: 0;
  }
}
