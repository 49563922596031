.header {
  display: flex;
  align-items: center;
  // position: fixed;
  width: 100%;
  min-height: 4.9rem;
  //left: 0;
  //top: 0;
  background: white;
  //z-index: 1000;
  padding: 0.7rem 0;
  box-shadow: 0 0.2rem 0.8rem rgba(0, 0, 0, 0.1);

  .container {
    width: 100%;
  }

  &__top {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
  }

  @include mobile {
    .main-btn_purple {
      display: none;
    }
  }

  &__logo {
    display: inline-flex;
  }

  &__btn {
    padding: 0.6rem 2rem;
    margin-right: 1.2rem;
  }

  &__wrap {
    display: flex;
    align-items: center;
  }

  &__language {
    position: relative;
    margin-left: 0.5rem;
    @include tablet {
      display: none;
    }

    &-btn {
      cursor: pointer;
      color: $clr-purple;
    }

    &-dropdown {
      display: none;
      flex-direction: column;
      position: absolute;
      top: 0;
      left: 100%;

      &-link {
        color: #4d4e67;
        opacity: 0.8;
      }
    }
  }

  &__avatar {
    position: relative;
    display: inherit;
    margin-right: 1.5rem;
    @include tablet {
      margin-right: 0;
    }

    &-logo {
      cursor: pointer;
      width: 2.4rem;
      height: 2.4rem;
    }

    &-info {
      position: absolute;
      min-width: 31.2rem;
      top: 4rem;
      background: #ffffff;
      box-shadow: 0 1rem 5rem rgba(0, 0, 0, 0.25);
      border-radius: 1.6rem;
      box-sizing: border-box;
      z-index: 1000;
      right: -5rem;
      opacity: 0;
      visibility: hidden;
      @include tablet {
        display: none;
      }

      &-top {
        padding: 2.4rem 1.6rem 1rem;
        border-bottom: 0.1rem solid #e0e0e0;
      }

      &:before {
        content: '';
        position: absolute;
        width: 2rem;
        height: 2rem;
        background-color: white;
        top: -0.6rem;
        transform: rotate(-45deg);
        right: 5rem;
      }
    }

    &-list {
      overflow: hidden;
      border-bottom-left-radius: 1.6rem;
      border-bottom-right-radius: 1.6rem;
    }

    &-title {
      font-weight: 500;
      font-size: 2.4rem;
      line-height: 2.8rem;
      color: $clr-purple;
      margin-bottom: 1.2rem;
    }

    &-wrap {
      display: flex;
      align-items: flex-start;
    }

    &-user {
      margin-right: 0.9rem;
      width: 5.6rem;
      height: 5.6rem;
    }

    &-name {
      font-weight: 500;
      margin-bottom: 0.2rem;
    }

    &-email {
      display: block;
      color: $clr-main;
      opacity: 0.5;
      margin-bottom: 0.3rem;
    }

    &.active .header__avatar-info {
      opacity: 1;
      visibility: visible;
      transition: 0.4s;
    }

    &.active::before {
      content: '';
      position: absolute;
      width: 13rem;
      height: 3rem;
      left: 50%;
      transform: translateX(-50%);
      top: calc(100% - 1rem);
    }

    &-link {
      display: flex;
      align-items: center;
      padding: 1.6rem;
      color: $clr-main;
      transition: $tr_03;

      &-icon {
        font-size: 2.2rem;
        margin-right: 1.2rem;
      }

      &:hover {
        background-color: rgba(231, 231, 235, 0.5);
      }
    }
  }

  &_home {
    padding: 1.1rem 0;
  }
}

.header-menu-box {
  display: flex;
  align-items: center;

  &__burger {
    display: none;
    margin-right: 1.5rem;
  }

  &__menu {
    display: none;
    position: fixed;
    top: 4.8rem;
    left: 0;
    width: 100%;
    padding: 1.2rem 2.4rem 2.4rem;
    background-color: $light-color;
  }

  &__back {
    &-btn {
      display: flex;
      align-items: center;
      justify-content: space-between;
      font-size: 1.2rem;
      font-weight: 500;
      letter-spacing: 0.1rem;
      text-transform: uppercase;
      color: #351f73;
      border: 1px solid #351f73;
      border-radius: 4px;
      padding: 0.5rem 1.5rem;
      margin-right: 3.2rem;

      &:hover {
        background-color: rgba(53, 31, 111, 0.05);
        color: #432989;
        border-color: #432989;
      }
    }

    &-icon {
      transform: rotate(180deg);

      svg {
        width: 10px;
        height: 10px;
      }
    }

    &-text {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-left: 1rem;
    }
  }

  @include tablet {
    &__burger {
      display: block;
    }

    &__menu {
      display: block;

      .main-btn {
        display: block;

        &:not(:last-of-type) {
          margin-bottom: 1.2rem;
        }
      }
    }
  }

  @include laptop {
    &__back {
      &-btn {
        display: contents;
        transform: translate(0, -50%);
        width: auto;
        border: none;
        padding: 0;
      }
      &-icon {
        margin-right: 1.5rem;

        svg {
          width: 16px;
          height: 16px;
        }
      }
      &-text {
        display: none;
      }
    }
  }
}

.header-authentication {
  display: flex;
  align-items: center;

  @include tablet {
    display: none;
  }
}
