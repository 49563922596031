.boat-home {
  // padding: 5.3rem 0;
  background: #fafafb;
  @include mobile {
    padding: 2.4rem 0 4.4rem;
  }

  &__wrap-title {
    padding-bottom: 1.6rem;
    border-bottom: 0.1rem solid rgba(53, 31, 111, 0.2);
    margin-bottom: 4.8rem;
    @include mobile {
      margin-bottom: 1.6rem;
      padding-bottom: 1.2rem;
    }
  }

  &__card {
    margin-bottom: 1.7rem;
    &_renew {
      width: 100%;
      color: #ff1f3a;
      padding-top: 0.7rem;
    }

    .card__wrap-img {
      margin-right: 1.3rem;
      border-width: 0.05rem;
      width: 8rem;
      height: 8rem;
      @include mobile {
        width: 4.3rem;
        height: 4.3rem;
      }
      .avatar__img {
        border-radius: 1.6rem;
        @include mobile {
          border-radius: 0.8rem;
        }
      }
    }

    @include tablet {
      .card__row {
        flex-direction: column;
      }
    }
    @include mobile {
      margin-bottom: 0.8rem;
      .card__btns {
        width: 100%;

        .card__btn {
          width: 100%;
        }
      }
      .card__head span {
        display: none;
      }
    }

    .card__col_yacht {
      display: flex;
      justify-content: space-between;
      flex-grow: 1;
      padding: 0 5rem;
      @include tablet {
        margin-top: 1rem;
        padding: 0;
        flex-wrap: wrap;
      }
      @include mobile {
        flex-direction: column;
      }

      .card__text {
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
      }

      .card__block {
        display: flex;
        flex-direction: column;
        width: 17rem;
        padding: 0 0.2rem;
        @include large {
          width: 13rem;
        }
        @include tablet-big {
          width: 11rem;
        }
        @include tablet {
          flex-grow: 1;
          margin-bottom: 15px;
        }
        @include mobile {
          flex-direction: row;
          width: 100%;
          padding: 0;
          max-width: 20rem;
          margin-bottom: 0;
        }
      }
    }

    .card__col_info {
      width: 23%;
      min-width: 24.4rem;
      @include tablet {
        min-width: auto;
        width: 100%;
      }
    }

    .card__col_team {
      display: flex;
      flex-direction: column;
      width: 23%;
      min-width: 26rem;
      @include large {
        width: 95%;
        flex-direction: row;
        justify-content: space-between;
        flex-wrap: wrap;
      }
      @include tablet {
        min-width: auto;
        width: 98%;
      }
      @include mobile {
        flex-direction: column;
        align-items: flex-start;
      }
    }
  }

  &__content {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 6rem 0;
    @include tablet {
      padding: 3rem 0;
    }
    @include mobile {
      padding: 2rem 0;
    }

    &-text {
      margin-top: 2.5rem;
      font-size: 1.6rem;
      opacity: 0.3;
      text-align: center;
      &.purple {
        color: $clr-purple;
        opacity: 1;
      }
      @include tablet {
        margin-top: 1.7rem;
        font-size: 1.2rem;
      }
    }
  }

  &__wrap-icon {
    width: 13.9rem;
    height: 13.9rem;
    background-color: #e7e7eb;
    border-radius: 2.4rem;
    display: flex;
    align-items: center;
    justify-content: center;
    @include tablet {
      width: 8rem;
      height: 8rem;
      border-radius: 1.4rem;
    }
  }

  &__icon {
    max-width: 5.8rem;
    height: auto;
    @include tablet {
      max-width: 3.5rem;
    }
  }

  &__nav {
    margin-top: 3.4rem;
    @include mobile {
      margin-top: 2rem;
    }
  }

  &__search {
    margin-top: 1.6rem;
    display: flex;
    .info-btn {
      margin-left: 1.5rem;
      font-size: 2rem;
      color: $clr-green;
      cursor: pointer;
      @include mobile {
        margin-left: 1rem;
        font-size: 1.6rem;
      }
    }
    @include mobile {
      margin-top: 0.8rem;
    }
  }
}

.boat-home-page {
  &__title {
    text-transform: uppercase;
  }

  app-boat-preview {
    display: block;
    @include indent-not-last-of-type(0 0 2.4rem 0);
  }
}

.boat-home-page-container {
  width: 100%;
  max-width: 127rem;
  margin: 0 auto 10rem;
  padding: 6.2rem 1.6rem 0 1.6rem;

  @include desktop {
    padding: 5.4rem 1.6rem 0 1.6rem;
  }
}

.boat-home-page-header {
  @include d-flex-space-between;
  align-items: center;

  @include tablet {
    .seaz-title-h2 {
      font-size: 3rem;
    }
  }

  @include mobile {
    flex-direction: column;
    align-items: flex-start;

    .seaz__btn_rounded {
      width: 100%;
      margin-top: 1.6rem;
    }
  }
}

.boat-home-page-search {
  margin-top: 5.2rem;

  @include tablet {
    margin-top: 1.6rem;
  }
}

.boat-home-list {
  margin: 4.8rem 0;

  @include tablet {
    margin: 1.6rem 0;
  }
}
