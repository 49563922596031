.boat-inventory-categories-location-settings {
  width: 100%;
  max-width: 57rem;
  margin: 0 auto;
}

.boat-inventory-location-settings-list {
  margin-top: 1.2rem;
}

.boat-inventory-location-settings-item {
  @include d-flex-space-between;
  align-items: center;
  background-color: $light-color;
  border-radius: 0.8rem;
  padding: 1rem 0.8rem;

  &__info {
    @include d-align-items-center;
  }

  &__info-arrow {
    margin-right: 1rem;
    transform-origin: 50% 50%;
    transform: rotateZ(180deg);
  }

  &__title {
    font-size: 1.6rem;
    font-weight: 500;
    line-height: 1.9rem;
    color: $seaz-text;
    padding-right: 1.2rem;
  }

  &__actions {
    @include d-align-items-center;
    justify-content: flex-start;
    min-width: 6.1rem;
  }

  &__edit-btn {
    margin-left: 0.8rem;
  }
}

app-boat-inventory-location-item {
  &:nth-of-type(2n + 1) {
    .boat-inventory-location-settings-item {
      background-color: $seaz-bg;
    }
  }
}

.inventory-categories-item-main {
  &:nth-of-type(2n + 1) {
    .clickable {
      background-color: $seaz-bg;
    }
  }
}

.boat-inventory-location-settings-details {
  display: none;

  .boat-inventory-location-settings-item {
    padding: 1.2rem 0.8rem 1.2rem 5.6rem;
  }

  app-boat-inventory-categories-item {
    &:nth-of-type(2n) {
      .boat-inventory-location-settings-item {
        background-color: $seaz-bg;
      }
    }
  }
}

.location-settings-item-wrap.expand {
  .boat-inventory-location-settings-details {
    display: block;
  }

  .boat-inventory-location-settings-item__info-arrow {
    transform: rotateZ(0deg);
  }
}

.boat-inventory-location-form {
  margin-top: 3rem;

  .form__radio {
    @include indent-not-last-of-child(0 0 2rem 0);
  }

  @media screen and (max-width: 650px) {
    .form-field--wrapper__row .form-field--label {
      max-width: 14rem;
    }
  }

  @include mobile {
    .plug-label {
      width: 100%;
      min-width: auto;
      max-width: initial;
      margin: 0 0 0.8rem 0;
    }
  }
}

.boat-inventory-location-form-footer {
  @include footer-with-border-left-btn(0, 4.6rem 0 0 0);
  border: none;

  &__btn {
    @include indent-not-last-of-child(0 1.2rem 0 0);
  }
}
