.boat-maintenance-task-quote-form {
  margin-top: 3rem;

  &__field {
    width: 100%;
    max-width: 22.1rem;
  }

  .boat__form-list {
    margin: 1.3rem 0 0;
  }

  .outstanding-form__checkbox-container {
    .boat__form-checkbox {
      &:before {
        content: '';
        width: 14rem;
        min-width: 14rem;
        height: 1rem;
        margin-right: 2rem;
      }
    }
  }

  @media screen and (max-width: 650px) {
    .form-field--wrapper__row .form-field--label {
      max-width: 14rem;
    }

    .form-details-plug-label {
      display: none;
    }
  }

  @include mobile {
    &__field {
      width: 100%;
      max-width: initial;
    }
  }
}

.quote-form-conversion-amount {
  margin-top: 1.6rem;

  .wrapper-rounded .form-field--content {
    height: 3rem;
    padding: 0.7rem 1.2rem;
  }
}

.boat-maintenance-task-quote-approve-btn {
  margin-bottom: 3rem;
}

.boat-maintenance-task-quote-approve-details {
  margin-bottom: 1.6rem;

  &__text {
    color: $profile-month;
    margin-top: 0.8rem;
  }
}

.boat-maintenance-task-quote-approve-action {
  @include d-align-items-center;

  &__btn {
    font-size: $base-font-size;
    line-height: 1.6rem;
    color: $seaz-alarm;
    border-bottom: 1px dashed $seaz-alarm;
    margin-left: 0.8rem;
    transition: opacity 0.3s;

    &:hover {
      opacity: 0.7;
    }
  }
}

.boat-maintenance-task-quote-approve-label {
  @include d-align-items-center;
  background-color: $greeny-bg;
  border: 1px solid $greeny-border-bg;
  color: $seaz-delivery;
  font-size: $base-font-size;
  line-height: 1.6rem;
  border-radius: 4rem;
  padding: 0.8rem 1.5rem;

  app-seazone-icon {
    margin-right: 1rem;
  }
}

.boat-maintenance-task-quote-form-details-button {
  @include d-align-items-center;
  width: 100%;
  max-width: 20.7rem;
  font-size: 1.6rem;
  line-height: 1.9rem;
  color: $seaz-edit-hover;
  margin-bottom: 1.5rem;

  &__icon {
    display: block;
    margin-right: 0.8rem;

    app-seazone-icon {
      transform: rotateZ(0);
      transition: transform 0.3s;
    }

    &.active-icon {
      app-seazone-icon {
        transform: rotateZ(-180deg);
      }
    }
  }
}

.boat-maintenance-task-quote-form-details-form {
  height: 0;
  overflow-y: hidden;
  visibility: hidden;
  opacity: 0;
  transition-property: height, overflow-y, visibility, opacity;
  transition-duration: 0.3s;

  &.active {
    height: 100%;
    overflow-y: visible;
    visibility: visible;
    opacity: 1;
  }

  .outstanding-form-container {
    width: 100%;
  }

  .outstanding-form .form__row {
    margin-bottom: 2.8rem;
  }
}

.boat-task-quote-container {
  width: 100%;
  max-width: 40rem;
  margin: 3rem auto 0;
}

.boat-maintenance-task-actual-cost {
  @include d-flex-space-between;
  align-items: center;
  margin-bottom: 2.8rem;

  .plug-label {
    @include plug-label(16rem, 0 1.6rem 0 0);
  }
}

.boat-task-quote {
  border: 1px solid $clr-ashy-grey;
  box-shadow: 0 2px 2px rgba(43, 28, 54, 0.16);
  border-radius: 1.2rem;
  padding: 1.2rem;
  cursor: pointer;
  @include indent-not-last-of-child(0 0 1.2rem 0);

  &.approved {
    border: 1px solid $seaz-success;
  }
}

.boat-task-quote-content {
  @include d-flex-space-between;
  align-items: center;
}

.boat-task-quote-info {
  @include d-align-items-center;

  &__quote {
    flex-shrink: 0;
  }

  &__text {
    color: $seaz-text;
    margin: 0 0.7rem;
    word-break: break-word;
  }

  &__amounts {
    flex-shrink: 0;
  }

  &__converted-amount {
    font-weight: 700;
    line-height: 1.6rem;
    color: $seaz-text;
  }

  &__origin-amount {
    font-size: 1.2rem;
    line-height: 120%;
    color: $profile-month;
    margin-top: 0.2rem;
  }
}

.boat-task-quote-status {
  font-size: 1.2rem;
  line-height: 1.4rem;
  color: $profile-month;
  margin-top: 1.2rem;
}

.boat-task-quote-button {
  margin: 3rem 0 0 auto;

  @include mobile {
    width: 100%;
    margin: 1.6rem 0 0;
  }
}

.quote-counters {
  @include d-align-items-center;
  gap: 2.4rem;
  width: max-content;
  background-color: $light-color;
  box-shadow: 0 2px 2px rgba(43, 28, 54, 0.16);
  border-radius: 1.2rem;
  padding: 1.2rem;

  @include mobile {
    justify-content: space-between;
    width: 100%;
    gap: 1rem;
    padding: 1rem;
  }
}

.quote-counter-separate {
  width: 0.1rem;
  height: 2.4rem;
  background-color: $seaz-head-arrow;

  @include mobile {
    display: none;
  }
}

.quote-counter {
  @include d-align-items-center;

  &__title {
    font-size: $base-font-size;
    line-height: 1.6rem;
    color: $profile-month;
    margin-right: 1.2rem;
  }

  &__val {
    font-size: 2rem;
    font-weight: 500;
    line-height: 120%;
    color: $seaz-text;

    &.seaz-alarm {
      color: $seaz-alarm;
    }

    &.seaz-success {
      color: $seaz-delivery;
    }
  }

  @include tablet {
    flex-direction: column;
    justify-content: flex-start;

    &__title {
      text-align: center;
      margin: 0 0 0.4rem 0;
    }

    &__val {
      text-align: center;
    }
  }
}

.boat-maintenance-task-log-counters {
  &__web {
    min-height: 4.8rem;
  }

  &__mob {
    display: none;
    min-height: 19.2rem;
  }

  @include desktop {
    &__web {
      display: none;
    }

    &__mob {
      display: block;
      margin-top: 1.6rem;
    }

    .quote-counters {
      flex-direction: column;
      align-items: flex-start;
      width: 100%;
      gap: 1.2rem 0;
    }

    .quote-counter {
      width: 100%;
      justify-content: space-between;
    }

    .quote-counter-separate {
      display: none;
    }
  }

  @include tablet {
    .quote-counter {
      flex-direction: row;
      gap: 0 1rem;
    }
  }
}
