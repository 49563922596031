.boat-maintenance-dashboard-systems {
  margin-top: 1.6rem;
  cursor: pointer;

  .maintenance-card-name__text {
    font-size: 2rem;
    line-height: 120%;
    color: $seaz-primary-and-accent;
    font-weight: 500;
    margin-bottom: 0.4rem;
  }

  .maintenance-card-photo-box {
    width: 7.2rem;
    min-width: 7.2rem;
    height: 7.2rem;
    margin-right: 1.2rem;
  }
}

.boat-maintenance-dashboard-systems-cards {
  @include box-grid(repeat(auto-fill, minmax(33rem, 1fr)), initial, initial, 1.6rem 2.5rem);

  .maintenance-card-schedule__title {
    margin-right: 0;
    margin-bottom: 0.4rem;
  }

  @include mobile {
    @include box-grid(repeat(auto-fill, minmax(30rem, 1fr)), initial, initial, 1.6rem 2.5rem);
  }
}

.boat-maintenance-dashboard-systems-body {
  width: 100%;

  .boat-maintenance-system-info-service {
    margin-top: 1.2rem;
  }
}

.boat-maintenance-dashboard-systems-container {
  display: flex;
  align-items: flex-start;
  margin-bottom: 1.2rem;
}

.boat-maintenance-dashboard-systems-schedules {
  @include box-grid(1fr 1fr, initial, initial, 0.8rem 7rem);

  .maintenance-card-schedule {
    flex-direction: column;
    align-items: flex-start;
    text-align: right;

    &:nth-of-type(2n) {
      align-items: flex-end;
    }
  }
}

.boat-maintenance-dashboard-systems-tasks {
  @include d-flex-space-between;
  align-items: center;
  margin-top: 1.2rem;

  &__text {
    font-size: $base-font-size;
    color: $clr-light-grey;
  }

  &__info {
    @include d-align-items-center;
    font-size: 1.6rem;
    font-weight: 700;
    line-height: 120%;
    color: $seaz-text;
    border: 1px solid $clr-grey;
    border-radius: 1.8rem;
    padding: 0.7rem 1.6rem;
    margin-left: 1rem;

    &.alarm {
      border: 1px solid $seaz-alarm;
    }

    &--text,
    &--separator {
      color: $seaz-alarm;
    }

    &--separator {
      padding: 0 0.8rem;
    }
  }

  &__info-icon {
    transform: rotateZ(180deg);
    margin-left: 1.1rem;
  }
}

.boat-maintenance-system-info-service {
  width: 100%;

  &__row {
    @include d-flex-space-between;
    align-items: flex-start;
    margin-bottom: 1.6rem;

    &--left {
      width: 50%;

      .boat-maintenance-system-info-service-date-box__val {
        font-size: 1.4rem;
        text-align: left;
        color: $clr-grey;
      }
    }
  }

  &__btn {
    font-size: $base-font-size;
    line-height: 1.6rem;
    text-decoration: underline;
    word-break: break-word;
    text-align: left;
    color: #00b9ff;

    &--add {
      color: $clr-primary-and-accent;
    }
  }

  &__name {
    font-size: 1.2rem;
    font-weight: 400;
    line-height: 120%;
    color: $seaz-document-count;
    margin-top: 0.2rem;
  }

  &__expired {
    text-decoration: underline;
    word-break: break-word;
    text-align: left;
    color: $seaz-alarm;
  }
}

.boat-maintenance-system-info-service-date-box {
  width: 50%;
  margin-left: 1rem;

  &__text {
    font-size: $base-font-size;
    line-height: 1.6rem;
    text-align: right;
    word-break: break-word;
    color: $seaz-text;
    margin-bottom: 0.2rem;
  }

  &__val {
    font-size: 1.2rem;
    font-weight: 400;
    line-height: 120%;
    text-align: right;
    word-break: break-word;
    color: $profile-month;
  }
}
