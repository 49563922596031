.matching-modal {
  &__header {
    @include d-align-items-center;
  }

  &__title {
    margin-right: 1.2rem;
  }

  &__footer {
    @include footer-with-border-left-btn(0, 3rem 0 0);
    border-top: none;
  }

  @include mobile {
    &__header {
      flex-direction: column;
      align-items: flex-start;
    }

    &__title {
      margin: 0 0 1.2rem 0;
    }
  }
}

.matching-info {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-gap: 3rem 1rem;
  margin-top: 3rem;

  &__label {
    display: block;
    color: $profile-month;
    line-height: 1.6rem;
    margin-bottom: 0.65rem;
  }

  &__description {
    @include d-align-items-center;
    font-size: 1.6rem;
    font-weight: 500;
    line-height: 1.9rem;
    color: $seaz-text;
  }

  &__greeny,
  &__inline {
    display: inline-block;
  }

  .match-icon {
    margin-left: 1.3rem;
  }

  @include mobile {
    grid-template-columns: 1fr;
    grid-gap: 2rem 0;
  }

  &__percent {
    &.match {
      color: $seaz-success;
    }
    &.probable {
      color: $seaz-warning;
    }
    &.not-match {
      color: $seaz-alarm;
    }
  }
}

.skills {
  margin-top: 3.5rem;

  &__list {
    @include d-align-items-center;
    flex-wrap: wrap;
  }

  &__item {
    margin-bottom: 0.8rem;

    &:not(:last-of-type) {
      margin-right: 1.2rem;
    }
  }

  .not-match {
    color: $seaz-alarm;
    background: $label-bg;
  }

  .match {
    color: $seaz-success;
    background-color: $greeny-bg;
  }
}
