.boat-fleet-documents {
  margin: 1.5rem auto 0;

  .seaz-search-quick-filter {
    @include d-align-items-center;
    margin-top: 2.4rem;
  }
}

.seaz-search-quick-filter-label {
  font-weight: 500;
  line-height: 1.4rem;
  color: $seaz-text;
  margin-right: 1.6rem;
}

.boat-fleet-documents-container {
  margin-top: 4.9rem;
}

app-fleet-documents-boat {
  display: block;
  @include indent-not-last-of-type(0 0 2.4rem 0);
}

.boat-fleet-documents-accordion {
  padding: 2.4rem;
  box-shadow: 0 2px 2px rgba(43, 28, 54, 0.16);
  border-radius: 2.4rem;
  background-color: $light-color;

  @include tablet {
    padding: 1.6rem;
  }
}

.boat-fleet-documents-accordion-header {
  @include d-flex-space-between;
  align-items: center;
  position: relative;

  &__info {
    @include d-align-items-center;
  }

  &__text {
    font-size: 3rem;
    font-weight: 500;
    line-height: 145%;
    margin-left: 1.6rem;
    word-break: break-word;
  }

  &__text-info {
    color: $profile-month;
  }

  &__btn-arrow {
    position: absolute;
    top: 50%;
    right: 0;
    transform: rotateZ(180deg) translate(0, 50%);
    &.collapsed {
      transform: translate(0, -50%);
    }
  }

  @include laptop {
    flex-direction: column;
    align-items: flex-start;
  }

  @include tablet {
    &__text {
      font-size: 2rem;
    }
  }
}

.boat-fleet-documents-accordion-header-action {
  @include d-align-items-center;
  flex-shrink: 0;

  &__wrap {
    @include d-align-items-center;
  }

  &__counter {
    @include d-align-items-center;
    font-size: 2rem;
    line-height: 120%;
    margin-left: 0.9rem;

    app-seazone-icon {
      margin-right: 0.9rem;
    }
  }

  &__btn {
    margin: 0 3rem 0 2.4rem;
  }

  @include laptop {
    margin-top: 1.2rem;

    &__counter {
      &:first-of-type {
        margin-left: 0;
      }
    }
  }

  @include mobile {
    flex-direction: column;
    align-items: flex-start;

    &__btn {
      margin: 1.6rem 0 0;
    }
  }
}

.documents-list-container {
  display: none;
  margin-top: 0;
}

.content--hidden {
  display: block;
  max-height: 58.3rem;
  height: 100%;
  margin-top: 2.4rem;
  padding: 0 1rem 1rem 0;
  overflow-y: auto;
  @include custom-scroll-bar;
}
