.boat-maintenance-task-view-wrapper app-overlay {
  max-width: 63rem;
}

.boat-maintenance-task-view-modal {
  padding: 3rem;

  .boat-task-form-footer {
    padding: 0;
  }

  @include tablet {
    padding: 1.6rem;
  }

  &-clone {
    padding: 0.7rem 1.2rem;
    font-size: 1.2rem;
    line-height: 1.6rem;
    margin-right: 1.8rem;
  }
}

.boat-maintenance-task-view-modal-header {
  display: flex;
  flex-direction: column;

  &-wrapper {
    @include d-align-items-center;
  }
  &-info {
    margin-top: 0.95rem;

    &__row {
      display: flex;
    }

    &__col {
      display: flex;
      flex-direction: column;
      &:not(:last-child) {
        margin-right: 3rem;
      }
    }
  }

  &__title,
  &__status-change {
    margin-right: 1.8rem;
  }

  &__text {
    color: $clr-light-grey;
    font-size: 1.2rem;
    line-height: 1.4rem;
    &:not(:last-child) {
      margin-bottom: 0.4rem;
    }
    &--alert {
      color: $seaz-warning;
    }
  }

  &__status-change {
    .status-select-container {
      @include d-flex-space-between;
      align-items: center;
      border-radius: 1.8rem;
      padding: 0.7rem 1.2rem;
      color: $light-color;
      width: fit-content;
    }
  }

  @include mobile {
    flex-direction: column;
    align-items: flex-start;
    gap: 1rem 0;
  }

  .boat-maintenance-task-view-repeat-number {
    margin-bottom: 0.55rem;
  }
}

.boat-maintenance-task-view-modal-header-actions {
  @include d-align-items-center;
}

.boat-maintenance-task-view-modal-content {
  margin-top: 3rem;

  @media screen and (max-width: 560px) {
    .tabs__header-wrap {
      flex-direction: row;
      overflow-x: auto;
    }
  }
}

.boat-maintenance-task-view-modal-tab {
  display: block;
  width: 33.3%;
  padding: 1.8rem;
  border: 1px solid $seaz-document-count;
  font-size: 1.4rem;
  font-weight: 500;
  line-height: 2rem;
  color: $seaz-document-count;

  &.active {
    border-color: $clr-primary-and-accent;
    color: $clr-primary-and-accent;
  }

  &:first-of-type {
    border-radius: 8px 0 0 8px;
  }

  &:last-of-type {
    border-radius: 0 8px 8px 0;
  }
  &:disabled {
    opacity: 0.3;
  }
  @include tablet {
    border: none;
    border-bottom: 2px solid $seaz-document-count;

    &:first-of-type {
      border-radius: 0;
    }

    &:last-of-type {
      border-radius: 0;
    }

    &.active {
      border-color: $clr-primary-and-accent;
    }
  }

  @include mobile {
    width: 16.6rem;
    padding: 1rem;
    white-space: nowrap;
  }
}

.boat-maintenance-notes-list-header {
  @include d-flex-space-between;
  margin-top: 4rem;

  &__btn {
    height: 4rem;
    margin: 0.8rem 0 0 1rem;
    flex-shrink: 0;
  }

  @include mobile {
    flex-direction: column;
    align-items: flex-start;

    &__btn {
      margin: 1.2rem 0 0 1rem;
    }
  }
}

.boat-maintenance-notes-list-chips {
  .chips-item {
    @include d-align-items-center;
    max-height: 4rem;
    box-sizing: border-box;
    border: 1px solid $seaz-head-arrow;
    border-radius: 4rem;
    background-color: $light-color;
    padding: 1rem 1.4rem;
    margin-top: 0.8rem;
    margin-right: 0.8rem;
    cursor: pointer;

    app-seazone-icon {
      margin-right: 1rem;

      svg {
        fill: $seaz-head-arrow;
      }
    }

    &.selected {
      box-shadow: 0 0 0 1px $seaz-mulberry;
      border-color: initial;

      app-seazone-icon {
        svg {
          fill: $seaz-mulberry;
        }
      }
    }
  }
}

.maintenance-notes-table {
  .history-view-table__cell {
    width: initial;
    min-width: 17rem;
    max-width: 20rem;
  }

  .notes-date-cell {
    width: 10.5rem;
    min-width: 10.5rem;
  }

  .notes-info-cell {
    width: 18.5rem;
    min-width: 18.5rem;
    max-width: 19rem;
  }

  .history-view-table__cell--with-arrow {
    width: 2rem;
    min-width: 2rem;
    max-width: 2rem;
  }
}

.boat-maintenance-notes-img-table-list {
  @include d-align-items-center;
  width: 73rem;
  gap: 0 0.8rem;
  margin-top: 2rem;
  padding-bottom: 1rem;
  overflow-x: auto;
  @include custom-scroll-bar;

  &__img {
    display: block;
    width: 6.8rem;
    height: 4.4rem;
    border-radius: 0.8rem;
  }
}

.boat-maintenance-notes-form,
.boat-maintenance-template-form {
  app-seazone-media-uploader,
  .seaz-uploader,
  .seaz-uploader__choose_drop {
    width: 100%;
  }

  .form-field__area {
    min-height: unset;
  }
}

.boat-maintenance-info {
  font-size: 1.4rem;
  line-height: 1.4rem;
  font-weight: 500;
  color: $seaz-mulberry;
  text-transform: uppercase;
  padding-bottom: 3rem;
  @include tablet {
    padding-bottom: 0.8rem;
  }
}
