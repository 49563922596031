.boat-fleet-calendar-header {
  display: block;
  width: 100%;
  max-width: 76.1rem;
  margin: 0 2.4rem;

  &__date {
    padding: 1.6rem;
    border-radius: 0.8rem;
    background-color: $seaz-bg;
    color: $seaz-text;
    margin-top: 0.8rem;
    font-size: 1.8rem;
    font-weight: 700;
    line-height: 2.1rem;
  }

  .active-period &__date {
    background-color: $greeny-bg;
  }

  @include laptop {
    width: 100%;
    max-width: 100%;
    margin: 0;
  }
}
