.boat-maintenance-dashboard {
  @include box-grid(
    66.5% 32%,
    initial,
    'maintenance-dashboard-tasks maintenance-dashboard-alerts'
      'maintenance-dashboard-position maintenance-dashboard-calendar'
      'maintenance-dashboard-work-list maintenance-dashboard-crew-list'
      'maintenance-dashboard-systems maintenance-dashboard-crew-list'
      'maintenance-dashboard-added-tasks maintenance-dashboard-crew-list',
    2.4rem
  );

  &__title {
    text-transform: uppercase;
    margin-bottom: 2rem;
  }

  &__header {
    @include d-flex-space-between;
    align-items: flex-start;
  }

  &__title {
    text-transform: uppercase;
  }

  &__header-action {
    @include d-align-items-center;
  }

  &__header-action-btn {
    @include indent-not-last-of-type(0 1.2rem 0 0);
  }

  &__time-zone {
    font-size: 1.2rem;
    line-height: 1.4rem;
    text-align: right;
    color: $clr-light-grey;
    margin-left: 1rem;
  }

  &__separator {
    border-top: 1px solid $seaz-head-arrow;
    margin: 2.4rem 0;
  }

  &__hidden-tasks-link {
    text-decoration: underline;
    color: $seaz-edit-hover;
    &.hidden-link {
      visibility: hidden;
    }
  }

  @include large-md-screen {
    @include box-grid(
      1fr 1fr,
      initial,
      'maintenance-dashboard-tasks maintenance-dashboard-tasks'
        'maintenance-dashboard-alerts maintenance-dashboard-alerts'
        'maintenance-dashboard-position maintenance-dashboard-position'
        //"maintenance-dashboard-calendar maintenance-dashboard-crew-list"
      //Change this when dashboard-crew-list is adding
      'maintenance-dashboard-work-list maintenance-dashboard-work-list'
        'maintenance-dashboard-calendar maintenance-dashboard-calendar'
        'maintenance-dashboard-systems maintenance-dashboard-systems'
        'maintenance-dashboard-added-tasks maintenance-dashboard-added-tasks',
      2.4rem
    );
  }

  @include laptop {
    @include box-grid(
      1fr,
      initial,
      'maintenance-dashboard-tasks' 'maintenance-dashboard-alerts' 'maintenance-dashboard-position'
        'maintenance-dashboard-work-list' 'maintenance-dashboard-calendar' 'maintenance-dashboard-crew-list'
        'maintenance-dashboard-systems' 'maintenance-dashboard-added-tasks',
      1.6rem
    );
  }

  @include tablet {
    margin-top: 1.6rem;

    &__header {
      flex-direction: column;
      align-items: flex-start;
    }

    &__title {
      margin-bottom: 0;
    }

    &__header-action {
      flex-direction: column;
      align-items: flex-start;
      width: 100%;
      margin-top: 1.6rem;
    }

    &__header-action-btn {
      width: 100%;
      @include indent-not-last-of-type(0 0 1.6rem 0);

      &--add {
        width: fit-content;
      }
    }

    &__time-zone {
      margin: 0.8rem 0 0;
      text-align: left;
    }
  }
}

.fleet-maintenance-dashboard {
  @include box-grid(
    66.5% 32%,
    initial,
    'maintenance-dashboard-tasks maintenance-dashboard-alerts'
      'maintenance-dashboard-position maintenance-dashboard-notes'
      'maintenance-dashboard-position maintenance-dashboard-notes',
    2.4rem
  );

  .maintenance-dashboard-tasks {
    grid-area: maintenance-dashboard-tasks;
  }

  .maintenance-dashboard-alerts {
    grid-area: maintenance-dashboard-alerts;
  }

  .maintenance-dashboard-position {
    grid-area: maintenance-dashboard-position;
  }

  .maintenance-dashboard-notes {
    grid-area: maintenance-dashboard-notes;
  }

  @include large-md-screen {
    @include box-grid(
      1fr,
      initial,
      'maintenance-dashboard-tasks' 'maintenance-dashboard-alerts' 'maintenance-dashboard-position'
        'maintenance-dashboard-notes',
      1.6rem
    );
  }
}

.maintenance-tasks-header {
  justify-content: space-between;
  align-items: flex-end;

  @include laptop {
    &.boat__card-wrap.profile {
      flex-direction: row;
    }
  }

  @include tablet {
    align-items: flex-start;
    margin-bottom: 1.6rem;

    &.boat__card-wrap.profile {
      flex-direction: column;
    }

    .tasks-header-download-btn {
      margin-top: 1.2rem;
    }
  }
}

.boat-maintenance-dashboard-section {
  background-color: $light-color;
  border: 0.5px solid $seaz-alert-info-bg;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.04);
  border-radius: 1.6rem;
  padding: 2.4rem;

  @include tablet {
    padding: 1.6rem;
  }
}

.maintenance-dashboard-tasks {
  grid-area: maintenance-dashboard-tasks;
}

.maintenance-dashboard-alerts {
  grid-area: maintenance-dashboard-alerts;
}

.maintenance-dashboard-position {
  grid-area: maintenance-dashboard-position;

  .boat-fleets-top-indent {
    margin-top: 0;
  }
}

.maintenance-dashboard-work-list {
  grid-area: maintenance-dashboard-work-list;

  @include tablet-big {
    .boat-maintenance-work-list-card-content {
      grid-gap: 0 1rem;
    }
  }

  @include tablet {
    .boat-maintenance-work-list-card-content {
      grid-gap: 1.2rem 0;
    }
  }

  @include mobile {
    .boat-maintenance-work-list-card-footer__actions {
      flex-direction: column;
    }

    .boat-maintenance-work-list-card-footer__btn {
      @include indent-not-last-of-child(0 0 1.2rem 0);

      &:first-of-type {
        width: 100%;
        white-space: nowrap;
      }

      &:last-of-type {
        width: 100%;
      }
    }
  }
}

.maintenance-dashboard-calendar {
  grid-area: maintenance-dashboard-calendar;
}

.maintenance-dashboard-systems {
  grid-area: maintenance-dashboard-systems;
}

.maintenance-dashboard-crew-list {
  grid-area: maintenance-dashboard-crew-list;
  align-self: baseline;
}

.maintenance-dashboard-added-tasks {
  grid-area: maintenance-dashboard-added-tasks;
}

.maintenance-info-item {
  @include d-flex-space-between;
  align-items: center;
  width: 100%;
  font-size: $base-font-size;
  line-height: 120%;
  color: $seaz-text;
  border-radius: 0.8rem;
  background-color: $seaz-bg;

  &__val-row {
    @include d-align-items-center;
    margin-left: 1rem;

    app-seazone-icon {
      transform: rotateZ(180deg);
      transform-origin: 50%;
      margin-left: 1.7rem;
      transition: transform 0.3s;
    }
  }

  &__val {
    color: $clr-light-grey;
    &.warning {
      color: $seaz-warning;
      &.expired {
        color: $seaz-alarm;
      }
    }
  }

  @include hover-effect-only-web-devices {
    &:hover {
      app-seazone-icon {
        transform: rotateZ(180deg) translateX(-0.3rem);
      }
    }
  }
}

.maintenance-alerts-chart-item {
  min-height: 2.8rem;
  padding: 0.8rem 0.6rem;
  border-radius: 0.4rem;
  text-align: right;

  &__text {
    font-size: $base-font-size;
    line-height: 16px;
    color: $light-color;
  }
}

.boat-maintenance-dashboard-shortcuts {
  margin-top: 2.4rem;

  .task-type--analyse {
    background-color: $seaz-success;
  }

  .task-type--cogs {
    background-color: $seaz-custom;
  }

  .task-type--wrench {
    background-color: $seaz-alarm-light;
  }

  .task-type--other {
    background-color: $profile-month;
  }
}

.boat-maintenance-calendar-modal.modal-right {
  app-overlay {
    width: 100%;
    max-width: 50.2rem;
  }

  .seaz-dashboard-charters-info {
    margin: 0;
  }
}

.fleet-tasks-boats-list {
  &__wrapper {
    display: flex;
    flex-direction: column;
  }
  &__header {
    display: flex;
    margin: 1.2rem 0;
    .seaz__btn_rounded:not(:last-child) {
      margin-right: 1.2rem;
    }
  }
  &__items {
    display: flex;
    flex-direction: column;
    margin: 1.2rem 0;
  }
  &__item {
    background: $clr-white;
    border: 1px solid #f5f5f5;
    box-shadow: 0 2px 2px rgba(43, 28, 54, 0.16);
    border-radius: 2.4rem;
    padding: 2.4rem 1.6rem 2.4rem 2.4rem;
    @include laptop {
      padding: 1.6rem;
    }
    &:not(:last-child) {
      margin-bottom: 2.4rem;
    }
    &--hidden {
      display: none !important;
    }
  }
}

.fleet-tasks-boat {
  &__wrapper {
    display: flex;
    flex-direction: column;
  }
  &__header {
    display: grid;
    grid-template-areas: 'info count action';
    grid-template-columns: 1fr auto 3.2rem;
    grid-column-gap: 1.6rem;
    @include tablet-md {
      grid-template-areas:
        'info action'
        'count count';
      grid-template-columns: 1fr 3.2rem;
      grid-gap: 1.2rem;
    }
    &-boat-info {
      display: flex;
      flex: 1 auto;
      align-items: center;
      grid-area: info;
      &__name {
        font-size: 3rem;
        line-height: 4.4rem;
        font-weight: 500;
        color: $seaz-text;
        text-transform: uppercase;
        @include laptop {
          font-size: 2.4rem;
          line-height: 2.8rem;
        }
        @include tablet {
          font-size: 2rem;
          line-height: 2.4rem;
        }
      }
      &__length {
        color: $clr-grey;
      }
      &__flag {
        display: flex;
        width: 3.8rem;
        height: 2.4rem;
        overflow: hidden;
        margin-right: 1.6rem;
        @include laptop {
          width: 3.8rem;
          line-height: 2.4rem;
        }
        @include tablet {
          width: 2.6rem;
          height: 1.6rem;
          margin-right: 1.2rem;
        }
        &--img {
          width: 100%;
          height: 100%;
        }
      }
    }
    &-tasks-count {
      grid-area: count;
      display: flex;
      font-size: 2rem;
      line-height: 2.4rem;
      font-weight: 400;
      &--wrapper {
        @include d-align-items-center;
        margin-right: 1.6rem;
        text-align: left;
        @include tablet-md {
          min-width: 8rem;
        }
      }
      .fleet-tasks-boat__header-tasks-action {
        flex: 1 auto;
      }
    }
    &-button-arrow {
      grid-area: action;
      .btn-collapse {
        width: 100%;
        height: 100%;
        transition: transform 300ms;
        &.collapsed {
          transform: rotate(180deg);
        }
      }
    }
  }
}
