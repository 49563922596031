.quick-access {
  border: 0.05rem solid $seaz-alert-info-bg;
  box-sizing: border-box;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.04);
  background-color: $light-color;
  border-radius: 1.6rem;
  margin-top: 2.4rem;
  padding: 2.4rem;

  &__title {
    font-size: 2rem;
    font-weight: 500;
    line-height: 120%;
    text-transform: uppercase;
    color: $seaz-primary-and-accent;
  }

  &__row {
    @include d-align-items-center;
    flex-wrap: wrap;
  }

  .shortcut-box {
    margin: 1.6rem 2rem 0 0;
  }

  .shortcut-light-green {
    background-color: $seaz-delivery;
  }

  @include tablet {
    margin-top: 1rem;
  }

  @include mobile {
    padding: 2rem;

    .shortcut-box {
      margin: 1.6rem 1rem 0 0;
    }
  }
}
