.view-profile {
  display: flex;
  justify-content: space-between;
  width: 100%;
  box-sizing: border-box;
  max-width: 127rem;
  margin: 4.3rem auto 0;
  padding: 0 1rem;

  &__person-info {
    width: 31%;
    box-sizing: border-box;
    margin-right: 3.8rem;
  }

  &__common-info {
    width: 66%;
    box-sizing: border-box;
  }

  &__block {
    margin-bottom: 6.4rem;
  }
  &__header {
    display: inline-flex;
    width: 100%;
    margin-bottom: 2.4rem;
    &--title {
      font: 3rem/4rem $base-font;
      font-weight: 500;
      text-transform: uppercase;
      width: 100%;
    }
  }
  &__photo {
    &--img {
      border-radius: 1.2rem;
    }
  }
  &__video {
    flex-direction: column;
    position: relative;
    .seaz-uploader__video_play {
      top: 32%;
    }
    &--file {
      border-radius: 1.2rem;
      max-height: 27.7rem;
    }
  }

  .video-box__title {
    padding: 0 1.2rem;
    font: 2.4rem/1.2 $base-font;
    font-weight: 500;
    text-align: center;
    margin-top: 1.6rem;
  }

  .seaz-cv__documents_show {
    width: 100%;
  }

  @include desktop {
    &__person-info {
      width: 31%;
      margin-right: 1.6rem;
    }

    &__common-info {
      width: 67%;
    }
  }

  @include laptop {
    flex-direction: column;
    margin: 1rem auto 0;

    &__person-info {
      width: 100%;
      margin: 0 0 1.6rem 0;
    }

    &__common-info {
      width: 100%;
    }

    .info-rounded-box {
      padding: 1.6rem;
    }
  }

  .info-rounded-label {
    position: absolute;
    top: 2rem;
    left: 2rem;
  }
}

.person-info {
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
  height: 100%;
  padding-bottom: 6.4rem;

  &__box {
    padding: 3.2rem;
  }

  .person-info-views {
    margin: 2.2rem 0 0;
  }

  &__rate-me-link {
    margin-top: 1.6rem;
  }

  &__ratings-person {
    margin-top: 1.6rem;
  }

  &__rate-button {
    margin-top: 1.6rem;
  }

  &__footer {
    margin: 1.6rem 0 0;
  }

  &__footer-action {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .crew-id-box {
    align-items: center;
  }

  &__contact-name {
    display: inline-flex;
    align-items: center;
    margin-top: 1.6rem;
    &--icon {
      margin-right: 0.8rem;
    }
  }

  &-business {
    &__name {
      margin-top: 1.6rem;
    }

    &__contact-name {
      margin-bottom: 0.4rem;
    }

    &__position {
      color: $clr-grey;
    }
  }

  &__jump-top {
    @include d-flex-center-content;
    width: 100%;
    margin-top: 1.6rem;
    padding-top: 1.6rem;
    border-top: 1px solid $seaz-head-arrow;
    &-container {
      text-align: center;
    }
    &-date {
      display: inline-block;
      margin-top: 0.8rem;
    }
    .seaz-cv__head_btn_jump {
      padding: 1.3rem 3.1rem;
      border-radius: 0.4rem;
    }
  }

  @include laptop {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 1.6rem 1rem;
    grid-template-areas:
      'person-info-common person-info-contacts'
      'person-info-views person-info-views';

    &__box {
      padding: 1.6rem;
    }
  }

  @include tablet {
    grid-template-columns: 1fr;
    grid-gap: 1.6rem;
    grid-template-areas:
      'person-info-common'
      'person-info-contacts'
      'person-info-views';
    padding-bottom: 2rem;

    .crew-id-box {
      flex-direction: row;
    }
  }

  .supplier-status {
    &__wrapper {
      display: flex;
      width: 100%;
      justify-content: center;
      padding: 2.4rem 0;
      border-bottom: 1px solid $seaz-head-arrow;
      color: $clr-light-grey;
    }
  }

  .change-status {
    display: flex;
    flex-direction: row-reverse;
    justify-content: center;
    align-items: center;
    .form-field__label {
      margin-left: 0.8rem;
      &.checked {
        color: $seaz-alarm;
      }
    }
    .form__toggle-input:checked + label:before {
      border-color: $seaz-alarm;
      background-color: $seaz-alarm;
    }

    .form__toggle-input:checked + label:after {
      border-color: $seaz-alarm;
    }
  }

  .supplier-branches-button {
    @include d-flex-center-content;
    width: 100%;
  }

  .supplier-contact-person-button {
    display: block;
    margin-top: 1.6rem;
  }
}

.person-info-common {
  grid-area: person-info-common;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 1.6rem;

  .seaz-search__card_photo {
    margin-right: 0;
  }

  .crew-id-box {
    align-items: center;
  }

  &__name {
    margin-top: 1.6rem;
    word-break: break-all;
  }

  @include laptop {
    margin-bottom: 0;
  }
}

.person-info-views {
  grid-area: person-info-views;
  display: flex;
  align-items: center;
  margin-bottom: 1.6rem;

  &__icon {
    margin-right: 1.6rem;
  }

  &__text {
    margin-left: 1rem;
    word-break: break-all;
  }

  @include laptop {
    margin-bottom: 0;
  }
}

.person-info-contacts {
  grid-area: person-info-contacts;

  &__wrapper {
    position: relative;
    height: 100%;
  }

  &__sticky {
    &.fixed {
      position: fixed;
    }
    &.absolute {
      position: absolute;
      bottom: 0;
    }
  }

  &__container {
    border-bottom: 1px solid $seaz-head-arrow;
    padding-bottom: 1.6rem;
    margin-bottom: 1.8rem;
  }

  &__row {
    display: flex;
    align-items: center;
    margin-bottom: 1.6rem;

    &--list {
      align-items: flex-start;
    }
  }

  &__location {
    &-icon {
      margin-right: 1.4rem;
    }
    &-text {
      font-size: 1.6rem;
      font-weight: 500;
      word-break: break-word;

      &--map {
        color: #55acee;
      }
    }
    &-distance {
      font-weight: 500;
      font-size: 1.4rem;
      line-height: 2rem;
      letter-spacing: 0.1em;
      text-transform: uppercase;
      color: $seaz-primary-and-accent;
      padding-top: 0.4em;
    }
    &-container {
      display: flex;
      justify-content: space-between;
      align-items: center;
      width: 100%;
    }
  }

  &__info-work {
    flex-shrink: 0;
    color: $success-color;
    padding: 0.5rem 0.8rem;
    background-color: $greeny-bg;
    border-radius: 3.7rem;
    margin-left: 1rem;
    display: flex;
    align-items: center;
    &.closed {
      background-color: #f5dadb;
      color: $seaz-alarm;
    }
  }

  &__text {
    font-size: 1.6rem;
    line-height: 1.9rem;
    margin-left: 1.8rem;
    color: $seaz-text;
    word-break: break-all;

    &--list {
      display: block;
      color: $seaz-text;
      word-break: break-all;

      &:not(:last-of-type) {
        margin-bottom: 0.8rem;
      }
    }

    &--list-tel {
      display: flex;
      align-items: center;
    }
  }

  &__links {
    display: flex;
    align-items: center;

    .contact-link {
      display: block;
      color: $seaz-text;
      word-break: break-all;

      &.social {
        margin-left: 1rem;
      }
    }

    &:not(:last-of-type) {
      // margin-bottom: 0.8rem;
      margin-bottom: 1rem;
    }
  }

  &__social-box {
    display: flex;
    align-items: center;
  }

  &__social-link {
    &:not(:last-of-type) {
      margin-right: 1.8rem;
    }
  }

  @include laptop {
    // height: 100%;

    &__sticky {
      height: 100%;
    }
  }

  @include mobile {
    &__social-link {
      &:not(:last-of-type) {
        margin-right: 1rem;
      }
    }
  }
}

.view-profile-brands {
  display: flex;
  justify-content: space-between;
  align-items: center;
  // background-color: $clr-bg;
  background-color: #fff;
  padding: 1.1rem 2.4rem;
  border-radius: 0.4rem 0.4rem 0 0;

  &__brands {
    display: flex;
    flex-wrap: wrap;
  }

  &__brand {
    display: flex;
    align-items: center;
    min-height: 5.2rem;
    box-sizing: border-box;
    border: 1px solid $seaz-head-arrow;
    border-radius: 5.6rem;
    background-color: $light-color;
    padding: 0.8rem 1.6rem 0.8rem 1rem;
    margin: 0.5rem 0.8rem 0.5rem 0;
  }

  &__brand-icon {
    margin-right: 0.8rem;
    filter: invert(17%) sepia(24%) saturate(4663%) hue-rotate(238deg) brightness(86%) contrast(105%);
  }
}

.profile-services-catalog.info-rounded-box {
  border-radius: 0 0 1.6rem 1.6rem;
}

.profile-services-catalog {
  padding: 2.4rem;
  margin-bottom: 6.7rem;

  &__title {
    color: $seaz-text;
  }

  &__container {
    margin-top: 2.4rem;
  }

  .services-catalog {
    margin-top: 0;
  }

  &__item {
    display: block;
    // width: 100%;
    border-radius: 1.6rem;
    box-sizing: border-box;
    // margin-bottom: 2.4rem;

    &.hidden {
      opacity: 0;
      visibility: hidden;
      position: absolute;
      z-index: -1;
    }
  }

  &__btn-box {
    margin-top: 1.8rem;
  }

  &__view-btn {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
  }

  @include laptop {
    padding: 1.6rem;
  }
}

.services-info-label {
  margin-top: 2.4rem;

  &__container {
    display: flex;
    flex-wrap: wrap;
    align-items: baseline;
  }

  &__item {
    margin-bottom: 1rem;
  }

  &__alert {
    font-weight: 500;
    color: $profile-month;
  }

  &__chips {
    width: max-content;
    line-height: 1.6rem;
    color: $seaz-text;
    padding: 1.2rem 1.6rem;
    border: 1px solid $seaz-head-arrow;
    background-color: $light-color;
    border-radius: 5.6rem;
    margin: 0 1.2rem 1rem 0;
  }

  @include tablet {
    .supplier-edit-form-field-container__form-label {
      width: 10rem;
    }
  }
}

.view-documents {
  &__show {
    margin-top: 2.2rem;
  }

  &__container {
    height: 0;
    transition: height 0.4s;
    overflow: hidden;

    &.opened {
      height: auto;
    }
  }

  &__show-text {
    background-color: $clr-white;
  }

  .seaz-cv__documents {
    padding: 0 2.4rem;

    &:nth-of-type(2n) {
      background-color: $seaz-bg;
      border-radius: 1.6rem;
    }
  }

  .seaz-cv__documents_row {
    width: 100%;
  }

  .document-text {
    &__container {
      display: flex;
      flex-direction: column;
      width: 30%;
      margin-right: 1rem;

      &:last-child {
        width: 40%;
      }

      @media screen and (max-width: 600px) {
        width: 100%;

        &:not(:last-of-type) {
          margin: 0 0 1rem 0;
        }

        &:last-child {
          width: 100%;
        }
      }
    }
    &__label {
      color: $clr-light-grey;
      font-size: 1rem;
      text-transform: uppercase;
      line-height: 1.4rem;
    }
    &__value {
      font-weight: 500;
      font-size: 1.6rem;
      line-height: 1.9rem;
      color: $clr-primary-and-accent;
      word-wrap: break-word;
    }

    @media screen and (max-width: 600px) {
      &__value {
        margin-top: 1rem;
      }
    }
  }
  .document-actions {
    &__container {
      display: flex;
      align-items: center;
    }
    &__button {
      &:not(:last-child) {
        margin-right: 1.2rem;
      }
    }

    @media screen and (max-width: 600px) {
      &__container {
        margin-top: 1rem;
      }
    }
  }

  @include laptop {
    .seaz-cv__documents_rows--box {
      width: 80%;
    }
  }

  @media screen and (max-width: 600px) {
    app-supplier-view-bank-details-item.seaz-cv__documents_row {
      flex-direction: column;
      align-items: flex-start;
    }

    .seaz-cv__documents_rows--box {
      width: 100%;
    }
  }

  @media screen and (max-width: 480px) {
    .seaz-cv__documents_rows--box {
      width: 100%;
    }

    .seaz-cv__documents:nth-of-type(2n - 1) {
      background-color: #fff;
    }
  }
}

.view-about {
  &__block {
    &:not(:last-child) {
      margin-bottom: 2.4rem;
    }
  }
  &__text {
    font-size: 1.6rem;
    color: $seaz-about-text;
  }
  &__label {
    color: $clr-light-grey;
    font-size: 1rem;
    margin-bottom: 0.8rem;
    text-transform: uppercase;
  }

  &__tags {
    display: flex;
    flex-wrap: wrap;
  }

  &__tag {
    background-color: $seaz-bg;
    color: $clr-light-grey;
    padding: 0.5rem 0.8rem;
    border-radius: 3.7rem;
    margin: 0.5rem 1.2rem 0 0;
    font-size: 1.4rem;
  }
}
