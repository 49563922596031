.view-all-brands-modal {
  &__title {
    display: flex;
    align-items: center;
  }

  &__title-icon {
    margin-right: 2.8rem;
    filter: invert(16%) sepia(22%) saturate(7028%) hue-rotate(244deg) brightness(103%) contrast(100%);
  }

  &__text {
    display: block;
    color: $clr-primary-and-accent;

    &--role {
      font-size: 1.6rem;
      line-height: 1.9rem;
      color: $seaz-document-count;
    }
  }

  &__footer {
    @include footer-with-border-left-btn(2.4rem 0 1rem 0, 1.2rem 0 0);
  }
}

.view-all-brands-modal-brands {
  padding-top: 2.4rem;
  margin-top: 2.4rem;
  @include border-top;

  &__title {
    font-size: 1.6rem;
    line-height: 1.9rem;
    color: $seaz-document-count;
  }

  &__chips-group {
    align-items: center;
    margin-top: 1.2rem;
  }

  &__brand-item {
    margin-bottom: 1.2rem;
  }
}
