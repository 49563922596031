.boat-inventory-list-header {
  @include d-flex-space-between;
  align-items: center;
  padding: 1.8rem 2.4rem;
  background-color: $seaz-bg;
  border-radius: 1.2rem 1.2rem 0 0;

  &__action {
    @include d-align-items-center;
  }

  &__selected {
    font-size: 1.6rem;
    font-weight: 500;
    line-height: 1.9rem;
    color: $seaz-about-text;
  }

  &__btn {
    font-size: 1.2rem;
    padding: 0.8rem 1.6rem;

    &--delete {
      margin-right: 1rem;
    }
    &--select {
      margin-right: 1rem;
      min-width: 13rem;
    }
  }

  .boat-inventory-cancel-btn {
    margin-left: 1.2rem;
  }

  @include tablet {
    display: none;
  }
}

.boat-inventory-list {
  padding: 2.4rem;

  @include tablet {
    padding: 1.6rem;
  }
}

.boat-inventory-list-item-wrap {
  border: 1px solid $clr-ashy-grey;
  box-shadow: 0 2px 2px rgba(43, 28, 54, 0.16);
  border-radius: 16px;
  padding: 1.2rem;
  cursor: pointer;
  @include indent-not-last-of-child(0 0 1rem 0);
  &--active {
    box-shadow: 0 0 0 2px #351f73;
  }
}

.boat-inventory-list-item {
  @include box-grid(auto 1fr auto, initial, initial, 0 2.4rem);

  @include laptop {
    @include box-grid(1fr auto, initial, 'description action' 'body body', 1.2rem);
  }
}

.boat-inventory-list-item-description {
  display: flex;
  align-items: flex-start;

  &__img-box {
    width: 7.6rem;
    height: 7.6rem;
    border-radius: 1.2rem;
  }

  &__img {
    display: block;
    height: auto;
    border-radius: 1.2rem;
    width: 7rem;
    object-fit: contain;
    aspect-ratio: 1;
  }

  &__content {
    min-width: 20rem;
    max-width: 20rem;
    margin-left: 1.2rem;
  }

  &__name {
    font-size: 2rem;
    font-weight: 500;
    line-height: 2.3rem;
    word-break: break-word;
    color: $seaz-text;
    margin-bottom: 0.2rem;
  }

  &__location {
    display: none;
    font-size: $base-font-size;
    color: $seaz-text;
    margin-bottom: 0.7rem;
  }

  &__status-change {
    margin-bottom: 0.2rem;
  }

  &__updated {
    font-size: 1.2rem;
    font-weight: 400;
    line-height: 120%;
    color: $profile-month;
  }

  @include desktop {
    &__content {
      min-width: 15rem;
      max-width: 15rem;
    }

    &__name {
      font-size: 1.8rem;
    }
  }

  @include laptop {
    grid-area: description;
  }

  @include tablet {
    &__content {
      min-width: auto;
      max-width: initial;
    }

    &__location {
      display: block;
    }
  }
}

.boat-inventory-list-item-body {
  @include d-flex-space-between;
  align-items: center;
  gap: 0 2.4rem;

  &__row {
    position: relative;
    flex-grow: 1;
    width: 33.3%;
    max-width: 19rem;
    padding: 1rem 0 1rem 2.4rem;

    &:before {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      width: 0.1rem;
      height: 100%;
      background-color: $clr-ashy-grey;
    }
  }

  &__title {
    font-size: $base-font-size;
    line-height: 1.6rem;
    color: $seaz-document-count;
    margin-bottom: 0.4rem;
  }

  &__text {
    font-size: 1.6rem;
    font-weight: 500;
    line-height: 1.9rem;
    color: $seaz-text;
  }

  @include desktop {
    align-items: flex-start;
    gap: 0 1rem;

    &__row {
      padding: 1rem 0 1rem 1.4rem;
    }

    &__text {
      font-size: $base-font-size;
    }
  }

  @include laptop {
    grid-area: body;
  }

  @include tablet {
    &__row {
      max-width: initial;
      padding: 0;

      &:before {
        display: none;
      }

      &--location-item {
        display: none;
      }
    }
  }
}

.boat-inventory-list-item-action {
  @include d-flex-center-content;

  &__btn {
    @include indent-not-last-of-child(0 0.9rem 0 0);
  }

  @include laptop {
    justify-content: flex-start;
    grid-area: action;
    align-self: start;
  }
}

.boat-inventory-list-status-change {
  width: 12rem;

  .status-select-container {
    @include d-flex-space-between;
    align-items: center;
    padding: 0.6rem 1.2rem;
    border-radius: 1.8rem;
    box-sizing: border-box;
  }

  .select-status-value {
    font-size: 1.2rem;
    color: $light-color;
  }

  .icon-status-arrow::before {
    font-size: 1rem;
    font-weight: bold;
  }
}
