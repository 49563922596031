.seaz-budget {
  .budget__select_btn,
  .budget__select_apply {
    min-height: 4.8rem;
    font-size: 1.6rem;
    letter-spacing: 0.1em;
    padding: 1rem 2.4rem;
  }

  .switch__btn {
    font-size: 1.2rem;
    font-weight: 500;
    letter-spacing: 0.1em;
    text-transform: uppercase;
    line-height: 1.6rem;
    padding: 1.1rem 3rem 0.9rem;
  }

  &__btn-container {
    display: flex;
    align-items: center;

    .main-btn,
    .main-btn_green {
      font-size: 1.4rem;
      font-weight: 500;
      letter-spacing: 0.1em;
      text-transform: uppercase;
      padding: 0.8rem 2rem;
    }

    .main-btn.main-btn_green {
      margin-right: 1.2rem;
      color: #fff;
    }

    .main-btn {
      color: $clr-green;
      border: 1px solid $clr-green;
      box-sizing: border-box;
      border-radius: 4px;
    }
  }

  &__table-title {
    font-size: 4rem;
    line-height: 100%;
  }

  &__title-name {
    text-transform: uppercase;
  }

  .budget-table__collapse-label {
    font-size: 1.4rem;
    color: $seaz-text;
  }

  .form__toggle-label:before {
    width: 4rem;
    height: 2.4rem;
    background-color: #b6b6b6;
    border-radius: 10rem;
  }

  .form__toggle-input:checked + label:before {
    background-color: $button-main;
  }

  .form__toggle-label:after {
    top: 0.2rem;
    left: 0.2rem;
    width: 2rem;
    height: 2rem;
    background-color: #fff;
  }

  .form__toggle-input:checked + label:after {
    right: 0.2rem;
    background-color: #fff;
  }

  @include laptop {
    .tabs__header-wrap {
      flex-direction: column;
      align-items: flex-start;
      justify-content: flex-start;
    }

    &__btn-container {
      margin-top: 3rem;
    }
  }

  &__comparison-chart-title {
    font-size: 2.4rem;
    font-weight: bold;
    color: $clr-purple;
  }
  @include tablet {
    .profile__col_button {
      margin-top: 2rem;
    }

    &__comparison-chart-title {
      margin-bottom: 3.5rem;
    }
  }

  .budget-table__currency {
    color: $clr-blue;
  }

  @media screen and (max-width: 500px) {
    .budget__select_section--buttons {
      flex-direction: column;
    }

    &__btn-container {
      flex-direction: column;
      width: 100%;

      .main-btn,
      app-import-budget {
        width: 100%;
      }

      .main-btn.main-btn_green {
        margin: 0 0 0.8rem 0;
      }
    }

    .budget__select_section--buttons {
      width: 100%;
    }

    .budget__select_btn,
    .budget__select_apply {
      width: 100%;
    }

    .budget__select_btn {
      margin: 0 0 1.2rem 0;
    }

    .switch {
      width: 100%;
      text-align: center;
    }

    .switch__btn {
      padding: 1.1rem 1rem 0.9rem;
    }

    .tabs__item:not(:last-child) {
      margin-right: 0;
    }

    &__table-title {
      font-size: 2.4rem;
    }
  }

  @include mobile {
    .budget__select_btn,
    .budget__select_apply {
      min-height: 4rem;
      padding: 0.8rem 2.4rem;
    }
  }
}

.seaz-budget-table {
  padding-bottom: 1rem;

  .budget-table__item {
    border-color: $seaz-head-arrow;
  }

  .budget-table__title_icon {
    svg {
      width: 1.3rem;
      height: 1.3rem;
    }
  }

  .budget-table__row:nth-of-type(2n) th:first-of-type {
    border-top: 1px solid $clr-grey;
    background-color: #fff;

    .budget-table__total {
      border-radius: 0.8rem 0 0 0.8rem;
      border-top: 0;
      background-color: $seaz-bg;
    }
  }

  .budget-table__row:nth-of-type(2n) th:last-of-type {
    border-top: 1px solid $clr-grey;
    background-color: #fff;

    .budget-table__total-right {
      border-radius: 0 0.8rem 0.8rem 0;
      border-top: 0;
      background-color: $seaz-bg;
    }
  }

  .seaz-budget__tbody:nth-of-type(2n) .budget-table__title td:first-of-type {
    border-top: 1px solid $clr-grey;
    background-color: #fff;
  }

  .seaz-budget__tbody .budget-table__title td {
    border-top: 1px solid $clr-grey;
  }

  .seaz-budget__tbody:nth-of-type(2n) .budget-table__title td {
    background-color: $seaz-bg;
  }

  .seaz-budget__tbody:nth-of-type(2n) .budget-table__title td:first-of-type,
  .seaz-budget__tbody:nth-of-type(2n) .budget-table__title td:last-of-type {
    background-color: #fff;
  }

  .seaz-budget__tbody:nth-of-type(2n) .budget-table__title td .budget-table__title_item {
    border-radius: 0.8rem 0 0 0.8rem;
    background-color: $seaz-bg;
  }

  .seaz-budget__tbody:nth-of-type(2n) .budget-table__title td .budget-table__title-right {
    border-radius: 0 0.8rem 0.8rem 0;
    background-color: $seaz-bg;
  }

  .seaz-budget-table__row-add,
  .budget-table__row.seaz-budget-table__row-sub {
    background-color: $light-blue;
  }

  .budget-table__row_item .editable-input__label {
    margin: 0;
  }

  .editable-input__label {
    margin: auto;
  }

  .editable-input {
    height: 4rem;
    border: 1px solid $clr-grey;
    border-radius: 0.4rem;
    background-color: $light-color;

    &.ng-invalid.ng-dirty {
      border: 1px solid #d12e2e;
    }
  }

  .general-table__item_comment {
    padding-top: 0.7rem;
    margin: 0 0 0 0.9rem;
  }

  .general-table__item_no-name {
    color: $disabled-link;
  }

  .item__message {
    margin-top: 0.5rem;
  }

  &__btn-add {
    display: flex;
    align-items: center;
    font-size: 1.2rem;
    font-weight: 500;
    text-transform: uppercase;
    letter-spacing: 0.1em;
    color: $clr-green;
    border: 1px solid $clr-green;
    border-radius: 0.4rem;
    padding: 0.8rem 1.6rem;
    margin: 1.6rem 0.8rem 0.8rem;

    app-seazone-icon {
      margin-right: 1rem;
    }
  }
}

.seaz-budget-monthly-budget {
  &__monthly-budget_table {
    padding-top: 4rem;
  }

  .seaz-budget-monthly-table .seaz-budget__tbody .budget-table__row:nth-child(2n) {
    background-color: initial;
  }

  .compare-budget__row-subcategory:nth-of-type(2n) td:first-of-type,
  .compare-budget__row-subcategory:nth-of-type(2n) td:last-of-type {
    background-color: #fff;
  }

  .compare-budget__row-subcategory:nth-of-type(2n) td .budget-table__item {
    border-radius: 0.8rem 0 0 0.8rem;
    background-color: $seaz-bg;
  }

  .compare-budget__row-subcategory:nth-of-type(2n) td.budget-table__item-right {
    border-radius: 0 0.8rem 0.8rem 0;
    background-color: $seaz-bg;
  }

  .compare-budget__row-subcategory:nth-of-type(2n) td {
    background-color: $seaz-bg;
  }

  .form__toggle-label:before {
    width: 4rem;
    height: 2.4rem;
    background-color: #b6b6b6;
    border-radius: 10rem;
  }

  .form__toggle-input:checked + label:before {
    background-color: $button-main;
  }

  .form__toggle-label:after {
    top: 0.2rem;
    left: 0.2rem;
    width: 2rem;
    height: 2rem;
    background-color: #fff;
  }

  .form__toggle-input:checked + label:after {
    right: 0.2rem;
    background-color: #fff;
  }
}

.seaz-budget-monthly-table {
  padding-bottom: 1rem;

  .budget-table__row:nth-child(2n) {
    background-color: $light-color;
  }

  .seaz-budget__tbody .budget-table__title td,
  .budget-table__row:nth-of-type(2n) th {
    border-top: 1px solid $seaz-border;
  }

  .seaz-budget__tbody .budget-table__row:nth-child(2n) {
    background-color: $clr-ashy-grey;
  }

  .budget-table__total {
    border-top: 0;
  }
}

.seaz-budget-table-spending {
  .budget-table__row:nth-of-type(2n) td:last-child.budget-table__title-right {
    background-color: $seaz-bg;
  }

  .budget-table__title-right span {
    width: 8rem;
    display: inline-flex;
    justify-content: flex-end;
  }
}

.seaz-budget-settings-modal {
  padding-bottom: 3rem;

  &__title {
    font-size: 2.4rem;
    font-weight: 500;
    color: $clr-primary-and-accent;
    margin-bottom: 2.4rem;
  }

  .info-label.gold {
    font-size: 1.4rem;
    color: $seaz-warning;
    padding: 0.8rem 1.2rem;
    margin-bottom: 3.6rem;
  }

  &__category-container {
    margin-bottom: 2.4rem;
    border-bottom: 1px solid $seaz-head-arrow;
  }

  &__category,
  &__subcategory {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  &__category {
    margin-bottom: 2.6rem;
  }

  &__category-title {
    font-size: 2rem;
    font-weight: 500;
    color: $seaz-text;
  }

  &__subcategory {
    margin-bottom: 2.8rem;
  }

  &__subcategory-item {
    font-size: 1.4rem;
    font-weight: normal;
    color: $seaz-text;
  }

  &__category-icon {
    cursor: pointer;
  }

  &__button-container {
    text-align: right;
  }

  &__button {
    font-weight: 500;
    letter-spacing: 0.1em;
    color: #fff;
    background-color: $clr-primary-and-accent;
    padding: 1.8rem 3.2rem;
    text-transform: uppercase;
  }
}

.seaz-modal-budget-settings {
  background-color: white;
  position: absolute;
  right: 0;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 100%;
  max-width: 650px;
  max-height: 90vh;
  border-radius: 0;
}

.seaz-budget-comment-modal {
  &__title {
    font-size: 2.4rem;
    font-weight: 500;
    margin-bottom: 3rem;
  }

  .form__radio-label-text,
  .form__block-text {
    font-size: 1.4rem;
    color: $seaz-text;
  }

  .form__radio-month {
    margin-top: 2.1rem;
  }

  .form__textarea {
    font-size: 1.4rem;
  }

  .form__row {
    margin-bottom: 0;
  }

  .form__row-textarea {
    margin-top: 1.5rem;
  }

  .comment-add__radio_box,
  .comment-add__radio_form {
    width: 100%;
  }

  .form__radio-label-text:first-letter {
    text-transform: uppercase;
  }

  .seaz-form-documents-uploader {
    margin-top: 4.4rem;
  }
}

.seaz-form-documents-uploader {
  display: block;

  .seaz-uploader__document {
    display: block;
  }

  .seaz-uploader__document_label {
    display: block;
    margin: 0 0 1.3rem;
  }
}

.seaz-btns-container {
  border-top: 1px solid $seaz-head-arrow;
  padding-top: 3rem;
  margin-top: 3rem;
}

.seaz-modal-budget-history-table {
  background-color: white;
  position: absolute;
  right: 0;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 100%;
  max-width: 650px;
  height: auto;
  border-radius: 0;
}

.seaz-budget-add-comment-btn {
  border-top: 1px solid #e0e0e0;
  margin-top: 1.4rem;
  padding-top: 3rem;
  text-align: right;
}

.accordion__content .seaz-budget-add-comment-btn .main-btn_white-green {
  width: auto;
  min-width: 20.4rem;
}
