.fleet-form {
  margin-top: 3rem;

  &__text-area {
    .wrapper-rounded.form-field--wrapper__row .form-field--label {
      justify-content: flex-start;
    }
  }

  .plug-label {
    flex-shrink: 0;
  }

  app-seazone-media-uploader,
  .seaz-uploader__choose_drop {
    width: 100%;
  }

  .seaz-uploader {
    margin-top: 0;
  }

  @media screen and (max-width: 650px) {
    .form-field--wrapper__row .form-field--label,
    .plug-label {
      flex-shrink: 0;
      width: 12rem;
      max-width: 12rem;
    }
  }

  @include tablet {
    .seaz-uploader__box {
      align-items: flex-start;
    }
    .seaz-uploader__choose {
      justify-content: flex-start;
    }
  }
}
