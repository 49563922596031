.modal-background {
  bottom: 0;
  left: 0;
  right: 0;
  top: 0;
}

.modal-background {
  background-color: rgba(10, 10, 10, 0.86);
  position: fixed;
}

.modal-close {
  color: #351f6f;
  position: absolute;
  top: 2.2rem;
  right: 3rem;
  cursor: pointer;
  z-index: 5;

  i {
    font-size: 1.8rem;
    height: 1.8rem;
    width: 1.8rem;
    display: block;
    transition: transform 0.4s cubic-bezier(0.23, 1, 0.32, 1);
  }

  &:hover > i {
    transform: rotate(90deg);
  }
}

.modal {
  background-color: white;
  width: 100%;
  height: 100%;

  .modal__wrap {
    -webkit-overflow-scrolling: touch;
    @media screen and (orientation: portrait) {
      transform: rotate(-90deg);
      transform-origin: left top;
      width: 100vh;
      overflow-x: hidden;
      position: absolute;
      top: 100%;
      height: auto;
      left: 0;
    }
  }
}

.modal__wrap {
  -webkit-overflow-scrolling: touch;
  padding: 2rem;
  box-sizing: border-box;
  height: 100%;
  font: 1.4rem/1.8rem $base-font;

  &-content {
    display: flex;
    flex-direction: column;
    height: 100%;
  }

  &-scroll {
    overflow: auto;
    padding-right: 1.5rem;
  }
}

.modal-small {
  background-color: white;
  position: absolute;
  right: 0;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 100%;
  max-width: 650px;
  border-radius: 16px;
  max-height: 90vh;
  // TODO: temporary
  .form__col-left {
    margin-right: 0;
    width: 100%;
    margin-bottom: 0.1rem !important;
  }

  &.modal-transparent {
    background-color: transparent;

    .modal-close {
      color: #ffffff;
      top: 1.2rem;
      right: 2rem;
    }
  }
}

.modal-content {
  font-family: 'Roboto', sans-serif;

  &__title {
    margin-bottom: 1.6rem;
    color: $clr-primary-and-accent;

    &.modal-content__title_budget {
      margin-top: 2rem;
    }
  }

  &__text {
    font-size: 1.6rem;
    line-height: 2.2rem;
    margin-bottom: 4.8rem;
    @include mobile {
      font-size: 1.4rem;
      margin-bottom: 2rem;
    }
  }

  &__controls {
    display: flex;
    justify-content: flex-end;
    @include tablet {
      flex-direction: column;
    }

    button {
      min-width: 15rem;

      &:not(:last-child) {
        margin-right: 1.6rem;
      }

      @include tablet {
        width: 100%;
        margin-bottom: 1.5rem;
        &:not(:last-child) {
          margin-right: 0;
        }
      }
    }

    &--invitation {
      flex-wrap: wrap;

      button {
        margin-top: 1.6rem;
      }
    }
  }

  &_center {
    text-align: center;
    padding: 1.4rem 1rem;

    & [class*='title'] {
      color: #351f6f;
    }

    & .modal-content__title {
      margin-bottom: 2.3rem;
      margin-top: 2rem;
    }

    & .modal-content__controls {
      justify-content: center;
    }
  }

  &__title-big {
    margin-bottom: 1rem;
  }

  &__logo {
    margin-bottom: 6.5rem;
    @include tablet {
      margin-bottom: 4rem;
    }

    & > img {
      @include mobile {
        max-height: 1.8rem;
      }
    }
  }

  &__icon {
    margin-bottom: 0.9rem;

    & > img {
      @include mobile {
        max-height: 8rem;
        width: auto;
      }
    }
  }
}

.modal-budget {
  display: flex;
  flex-direction: column;
  align-items: center;

  &_title {
    font-weight: 500;
    font-size: 34px;
    line-height: 40px;
    color: $clr-primary-and-accent;
    margin: 32px 10px;
    text-align: center;
  }

  &_btn {
    padding: 12px 60px;
    font-size: 1.8rem;
  }
}

.budget-create-modal {
  background-color: $clr-white;
  border: 0.5px solid #e7e7eb;
  box-sizing: border-box;
  box-shadow: 0 10px 50px rgba(0, 0, 0, 0.25);
  border-radius: 16px;
  max-width: 466px;
  width: 100%;
  margin: 0;
  padding: 2rem;
  position: fixed;

  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 2;
  @include tablet {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
}

.budget-apply {
  &__title {
    color: $clr-purple;
  }

  &__text {
    color: #a9a9a9;
    margin-bottom: 2.4rem;
  }

  &__checkbox {
    &.form__col {
      width: 61%;
      @include mobile {
        width: 100%;
      }
    }

    .form__radio-container {
      display: flex;
    }

    .form__block {
      span {
        padding-bottom: 1rem;
      }

      app-seazone-radio-item {
        .form__radio-label.disabled {
          cursor: default;

          &::before {
            border-color: gray;
            opacity: 0.3;
          }
        }

        .form__radio-label-text.disabled {
          cursor: default;
          color: gray;
          opacity: 0.3;
        }

        &:not(:last-of-type) {
          margin-right: 2.6rem;
        }
      }
    }
  }

  &__select {
    &.form__col {
      width: 36%;
      @include mobile {
        width: 100%;
      }
    }
  }

  &__row_controls {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  &__btn {
    min-width: 19.5rem;
    @include mobile {
      margin-bottom: 1rem;
    }

    &:disabled {
      color: #fff;
    }

    &:not(:last-of-type) {
      margin-right: 1.6rem;
      @include mobile {
        margin-right: 0;
      }
    }

    &_box {
      display: flex;
      justify-content: flex-end;
      padding-top: 3rem;
    }
  }
}

.seaz-background {
  &.modal-background {
    background-color: rgba(53, 40, 113, 0.2);
  }
}

.seaz-modal {
  .icon-close {
    cursor: pointer;
    color: $clr-light-grey;
  }

  &.modal-small {
    background-color: $clr-white;
    width: 62.9rem;
    padding: 1rem;
    box-shadow: 0 16px 24px rgba(53, 40, 113, 0.16);
    margin-bottom: 8rem;
    border-radius: 0;
    @include tablet {
      width: 42.9rem;
    }
    @include mobile {
      width: 100%;
    }
  }

  &__head {
    display: flex;
    justify-content: space-between;
    padding-bottom: 3rem;
    @include mobile {
      flex-direction: column;
    }

    &_title {
      color: $clr-primary-and-accent;
      @include mobile {
        order: 2;
      }
    }

    &_btn {
      @include mobile {
        order: 1;
        padding-bottom: 2rem;
        margin: 0 0 0 auto;
      }

      &.icon-close {
        color: $clr-primary-and-accent;
        width: 2.4rem;
        @include mobile {
          font-size: 17px;
        }
      }
    }
  }

  &__form {
    display: flex;
    align-items: center;
    margin-bottom: 3rem;
    flex-direction: column;
    @include mobile {
      flex-direction: column;
      align-items: baseline;
    }

    app-seazone-form-field {
      .form-field--content.disabled {
        background-color: $clr-white;
        border: 1px solid $seaz-head-arrow;
        // opacity: 0.3;
        .seazone__form-input,
        .seazone__text-area {
          background-color: $clr-white;
        }
      }
    }

    .form__row {
      width: 100%;
    }

    &_label {
      color: $seaz-text;
      margin-right: 3rem;
      flex-shrink: 0;
      @include mobile {
        padding-bottom: 0.8rem;
      }
    }

    &_control {
      padding: 1.5rem 1.6rem;
      border: 1px solid $seaz-head-arrow;
      color: $seaz-document-count;
      width: 100%;
    }

    &_checkbox {
      display: flex;
      justify-content: flex-end;

      .form__checkbox {
        color: $seaz-text;
      }

      .form__checkbox-label:before {
        border-color: $seaz-mulberry;
      }

      .form__checkbox-input:checked + label:before {
        background-color: $seaz-mulberry;
      }
    }
  }

  &__btn {
    margin-right: 1.6rem;

    &_box {
      border-top: 1px solid $seaz-head-arrow;
      display: flex;
      justify-content: flex-end;
      padding-top: 3rem;
      @include mobile {
        justify-content: space-between;
      }

      button {
        @include mobile {
          width: 49%;
        }
      }
    }
  }

  &__large {
    width: 66%;
    max-width: 66%;
    background-color: $clr-white;
    margin: 0 auto;
    left: 50%;
    top: 50%;
    max-height: 80%;
    transform: translate(-50%, -50%);
    @include mobile {
      width: 100vw;
      max-width: 100%;
    }
  }

  &__xl {
    width: 126.2rem;
    background-color: $clr-white;
    margin: 0 auto;
    left: 50%;
    top: 50%;
    max-height: 70%;
    min-height: 70%;
    transform: translate(-50%, -50%);
    @media screen and (max-width: 1320px) {
      width: 100rem;
    }
    @include laptop {
      width: 70rem;
    }
    @include tablet {
      width: 100%;
    }
  }

  &__medium {
    width: 63rem;
    background-color: $clr-white;
    margin: 0 auto;
    left: 50%;
    top: 50%;
    max-height: 80%;
    transform: translate(-50%, -50%);
    @include tablet {
      width: 83%;
    }
    @include mobile {
      width: 100%;
    }
  }

  &__file,
  &__document {
    .seaz-modal__head {
      justify-content: flex-end;
      @include mobile {
        align-items: flex-end;
      }
    }

    .seaz-modal__file_content {
      width: 487px;
      margin: 0 auto;
      padding-bottom: 6rem;
      @include tablet {
        width: 100%;
      }
    }

    .seaz-modal__btn_box {
      @include tablet {
        flex-direction: column;
        align-items: flex-end;
      }
      @include mobile {
        flex-direction: row;
        align-items: center;
      }

      button {
        @include mobile {
          width: fit-content;
        }
      }
    }

    .seaz-modal__btn {
      @include tablet {
        margin-right: 0;
        margin-bottom: 1rem;
      }
      @include mobile {
        margin-bottom: 0;
      }
    }

    .seaz__btn_outlined-red {
      @include mobile {
        display: none;
      }
    }

    .seaz-modal__btn_close {
      display: none;
      @include mobile {
        background-image: url('/assets/img/busket-cancel.svg');
        width: 2rem;
        height: 2.2rem;
        display: block;
        background-position: center;
        background-repeat: no-repeat;
        background-size: contain;
        margin-right: 2rem;
      }
    }
  }

  &__document {
    .form__row {
      app-seazone-select {
        .select-value {
          max-width: 30rem;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
          display: block;
        }
      }
    }

    .seaz-modal__box {
      @include tablet-small {
        width: 100%;
        top: 0;
        bottom: 0;
        margin: auto;
      }
      @include mobile {
        height: auto;
        position: relative;
      }
    }

    &_content {
      padding-top: 5rem;
      padding-bottom: 6rem;
      @include desktop {
        width: 91%;
      }
      @media screen and (max-width: 900px) {
        width: 100%;
        padding-bottom: 2rem;
      }

      &_controls {
        position: absolute;
        display: flex;
        width: auto;
        justify-content: space-between;
        right: 6rem;
        @include desktop {
          top: -0.5rem;
        }
      }
    }

    &_title {
      @include mobile {
        display: flex;
        flex-direction: column;
      }

      &_label {
        max-width: 14rem;
        width: 14rem;
        margin-right: 7rem;
        font-size: 1.4rem;
        flex-shrink: 0;
        @include tablet {
          margin-right: 2rem;
        }
        @include mobile {
          margin-bottom: 1rem;
        }
      }
    }

    &_row {
      display: flex;
      padding-bottom: 3.7rem;
      align-items: baseline;
      width: 60%;
      margin: 0 auto;
      @include tablet {
        width: 91%;
      }
      @media screen and (max-width: 640px) {
        width: 100%;
      }

      &--title {
        @include mobile {
          flex-direction: column;
          .seaz-modal__document_row_title {
            padding-bottom: 1rem;
          }
        }
      }

      &_title {
        min-width: 19rem;
        max-width: 19rem;
        box-sizing: border-box;
        padding-right: 2rem;
        color: $seaz-text;
        @media screen and (max-width: 640px) {
          min-width: 14rem;
          max-width: 14rem;
        }
        @include mobile {
          min-width: 15rem;
          max-width: 15rem;
        }
      }

      &_text {
        color: $seaz-text;
        line-height: 2.6rem;
        display: flex;
        flex-direction: column;

        &-date {
          app-seazone-icon {
            margin: 0 1.5rem;
          }
        }
      }

      &_date-row {
        flex-direction: row;

        @include mobile {
          flex-direction: column;
        }
      }

      &_files,
      &_notes {
        @include tablet-small {
          flex-direction: column;
          .seaz-modal__document_row_title {
            padding-bottom: 1rem;
          }
        }
      }
    }

    &_wrapper {
      position: relative;
      padding-right: 3rem;
    }

    &_file {
      color: $seaz-document-count;
      display: flex;
      align-items: center;
      cursor: pointer;
      @include tablet {
        word-break: break-all;
      }

      span {
        margin-left: 1rem;
      }

      &:hover {
        color: $seaz-edit-hover;
        text-decoration: underline;
      }
    }

    &_checkbox {
      width: 100%;
      margin-bottom: 1.6rem;
      padding-left: 21rem;
      box-sizing: border-box;
      @include tablet {
        padding-left: 0;
      }

      .form__checkbox {
        flex-direction: row-reverse;
        justify-content: flex-end;
        @include tablet {
          justify-content: flex-start;
        }
      }

      .form__checkbox-label-text {
        padding-left: 0;
        padding-right: 0.8rem;
        color: $seaz-text;
      }

      .form__checkbox-input:disabled ~ span {
        opacity: 0.3;
        color: $seaz-text;
      }

      .form__checkbox-input:disabled ~ label:before {
        border-color: $seaz-mulberry;
        opacity: 0.3;
      }

      .form__checkbox-label:before {
        border-color: $seaz-mulberry;
        border-radius: 0;
      }

      .form__checkbox-input:checked + label:before {
        background-color: $seaz-mulberry;
      }
    }

    &_uploader {
      width: 100%;
      padding-bottom: 2rem;
    }
  }

  &__archivate {
    background-color: $seaz-alarm;
    background-image: url('/assets/img/archivate-icon.svg');
    width: 4rem;
    height: 4rem;
    border-radius: 50%;
    background-position: center;
    background-repeat: no-repeat;
    background-size: 61%;
    display: block;
    position: absolute;
    top: 10rem;
    right: 5rem;
    cursor: pointer;
    display: flex;
    justify-content: center;
    @include mobile {
      position: relative;
      top: auto;
      right: auto;
      background-color: inherit;
      background-image: url('/assets/img/archivate-icon-purple.svg');
      margin-bottom: 2rem;
    }

    &:hover {
      &::before {
        content: 'Archivate file';
        white-space: nowrap;
        position: absolute;
        top: -4rem;
        color: $seaz-document-count;
        padding: 0.5rem 0.6rem;
        border: 1px solid $seaz-head-arrow;
      }

      &::after {
        content: '';
        height: 12px;
        width: 12px;
        position: absolute;
        left: 3px;
        right: 0;
        top: -17px;
        background: $clr-white;
        margin: auto;
        transform: rotate(45deg);
        border: 1px solid $seaz-head-arrow;
        border-top: none;
        border-left: none;
      }
    }
  }

  .modal-content__controls {
    .main-btn_white-green {
      @extend .seaz__btn_outlined;
    }

    .main-btn_green {
      @extend .seaz__btn_primary;

      &.delete {
        background-color: $seaz-alarm;
      }
    }

    @include tablet {
      button {
        min-width: 50%;
      }
    }
  }

  &-small {
    border-radius: 0;

    .modal__wrap {
      padding: 3rem;

      .seaz-crew__btn_box {
        padding: 3rem 0 0;
      }
    }
  }

  &__document {
    &_form {
      width: 65%;
      margin: 0 auto;
      @include laptop {
        width: 91%;
      }
      @include tablet {
        width: 100%;
      }

      app-seaz-image-uploader {
        width: 100%;
        padding-bottom: 2rem;
      }

      .form-field--wrapper__row {
        @include tablet {
          flex-direction: column;
        }
      }

      .seaz-uploader__document_label {
        @include tablet-big {
          margin-right: 2rem;
        }
      }

      app-seazone-form-field .form-field--wrapper__row {
        @include tablet-small {
          flex-direction: column;
          .form-field--label {
            margin-bottom: 1rem;
          }
        }
      }

      .seaz-uploader__document {
        @include tablet {
          flex-direction: column;
          .seaz-uploader__document_label {
            margin-bottom: 1rem;
          }
        }
      }

      &--edit {
        @media screen and (max-width: 650px) {
          .form-field--wrapper__row.form-field--wrapper {
            flex-direction: column;

            .form-field--label {
              padding-bottom: 1rem;
            }
          }
        }
      }

      .form__checkbox {
        justify-content: flex-start;
      }
    }

    &_checkbox {
      justify-content: flex-end;
      padding-left: 0;

      @include tablet {
        .form__checkbox-label {
          padding: 0;
        }
      }
    }
  }

  .seaz-crew__btn_box {
    padding-bottom: 0;
    @include tablet {
      flex-direction: column;
      justify-content: center;
      button {
        width: 100%;
        margin: 0 0 1.6rem;
      }
    }
  }

  &__courses {
    padding: 5rem 0;
    position: relative;
    @include tablet {
      padding-top: 3rem;
    }

    .icon-close {
      flex-shrink: 0;
    }

    .seaz-modal__head_title,
    .seaz-modal__document_row {
      width: 70%;
      margin: 0 auto;
    }

    &_form {
      .form__row {
        width: 70%;
        margin: 0 auto;

        &.seaz-crew__btn_box {
          width: 95%;
          margin: 0 auto;
          margin-top: 6rem;
        }
      }
    }

    &_edit {
      position: absolute;
      right: 10rem;
      top: 9.5rem;
    }

    &_file {
      display: flex;
      align-items: center;
      padding-bottom: 1.5rem;

      span {
        margin-left: 1rem;
      }

      &_box {
        display: flex;
        flex-direction: column;
      }
    }

    &_row {
      &.form__row {
        margin: 2rem auto;
      }
    }
  }

  &__experience {
    &_img {
      width: 12.2rem;
      height: 12.2rem;
      object-fit: cover;
      border-radius: 0.8rem;
    }

    &.seaz-modal__document_row {
      align-items: end;
      border-bottom: 1px solid $seaz-head-arrow;
    }

    &_title {
      padding-top: 2rem;
    }

    .seaz-modal__head_title,
    .form__row {
      @include desktop {
        width: 85%;
      }
      @media screen and (max-width: 650px) {
        width: 100%;
      }
    }

    .seaz-uploader__document {
      @media screen and (max-width: 650px) {
        flex-direction: column;
      }
    }

    .seaz-modal__document_row {
      @media screen and (max-width: 1200px) {
        width: 85%;
      }
      @media screen and (max-width: 650px) {
        width: 100%;
        .seaz-modal__document_row_title {
          min-width: 11rem;
          max-width: 11rem;
          line-height: 1.8rem;
        }
      }
    }

    @media screen and (max-width: 650px) {
      .seaz-modal__courses_edit {
        right: 1rem;
        top: 10rem;
      }
    }

    &_photo {
      width: 12.2rem;
      height: 12.3rem;
      border-radius: 8px;
      object-fit: cover;
    }
  }

  &__experience_form {
    .seaz-uploader__header {
      margin-right: 7rem;
      max-width: 14rem;
      min-width: 14rem;
      @media screen and (max-width: 800px) {
        margin-right: 2rem;
      }
    }

    .seaz-uploader__document_box_text,
    .seaz-uploader__document_box_drop {
      @include mobile {
        display: none;
      }
    }

    .seaz-uploader__document_file_box {
      @include mobile {
        //display: flex;
        //justify-content: space-between;
        //span {
        //  width: 72%;
        //  display: inline-flex;
        //}

        .seaz-uploader__document_box {
          flex-shrink: 0;
        }
      }
    }

    .seaz-uploader__document_label {
      @media screen and (max-width: 800px) {
        margin-right: 2rem;
      }
      @media screen and (max-width: 650px) {
        margin-bottom: 1rem;
      }
    }

    .seaz-crew__btn_box {
      @include tablet {
        button {
          margin-bottom: 1rem;
          width: auto;

          &.seaz-crew__btn_delete {
            width: auto;
          }
        }
      }
      // @include mobile {
      //   .seaz-crew__btn {
      //     margin-right: 2%;
      //   }
      // }
    }

    .form-field--wrapper__row.form-field--wrapper {
      @media screen and (max-width: 650px) {
        flex-direction: column;
        .form-field--label {
          margin-bottom: 1rem;
        }
      }
    }

    .seaz-uploader__label {
      font-weight: 400;
      font-size: 1.4rem;
    }

    .form-field--wrapper {
      margin-bottom: 1.6rem;
    }

    .seaz-modal__experience_date_control {
      width: 70%;
      margin: 0 auto;
      display: flex;
      padding-top: 2rem;
      @include desktop {
        width: 85%;
      }
      @media screen and (max-width: 650px) {
        flex-direction: column;
        width: 100%;
      }

      app-date-control {
        max-width: 42%;

        .form-field--content.disabled {
          background-color: $clr-white;
          opacity: 0.5;

          .seazone__form-date-input:disabled {
            background-color: $clr-white;
          }
        }
      }

      app-date-control:first-of-type {
        .form-field--label {
          display: none;
        }
      }

      .seaz-modal__experience_date {
        .form-field--wrapper.form-field--wrapper__row .form-field--label {
          margin-right: 2rem;
          width: auto;
          margin-left: 2rem;
          position: absolute;
          top: 0;
          left: -31%;
          bottom: 17px;
          @include large {
            left: -40%;
          }
          @include laptop {
            position: relative;
            left: auto;
            margin-bottom: 1rem;
            margin-left: 0;
          }
        }
      }
    }

    .seaz-modal__experience_date_wrapper {
      width: 100%;
    }

    .seaz-modal__experience_date_label {
      max-width: 14rem;
      width: 100%;
      margin-right: 7rem;
      flex-shrink: 0;
      @media screen and (max-width: 800px) {
        margin-right: 2rem;
      }
      @media screen and (max-width: 650px) {
        margin-bottom: 1rem;
      }
    }

    .seaz-modal__experience_date_box {
      width: 100%;
      display: flex;
      justify-content: space-between;
      position: relative;
      @include laptop {
        flex-direction: column;
        app-date-control {
          max-width: 100%;

          .form-field--wrapper__row {
            flex-direction: column;
          }
        }
      }
    }

    .seaz-modal__experience_date_checkbox {
      display: flex;
      // justify-content: flex-end;
      margin-top: 2rem;
      @include laptop {
        margin-top: 1rem;
      }

      .form__checkbox-label-text {
        color: $seaz-mulberry;
      }

      .form__checkbox-label {
        width: 2.4rem;
        height: 2.4rem;

        &::before {
          width: 2.4rem;
          height: 2.4rem;
          border-color: $seaz-mulberry;
        }

        @include mobile {
          width: 0;
        }
      }

      .form__checkbox-input:checked + label:before {
        background-color: $seaz-mulberry;
      }
    }

    .seaz-modal__experience-head {
      padding-bottom: 2rem;
    }

    .seaz-modal__experience-toggle {
      display: flex;
      flex-direction: row-reverse;
      margin-bottom: 4rem;

      .form-field__label {
        margin-left: 1rem;
        display: inline-block;
      }
    }

    app-seazone-media-uploader {
      width: 100%;

      .seaz-uploader {
        flex-direction: row;
        border-bottom: 1px solid $seaz-head-arrow;
        padding-bottom: 3rem;
        margin-bottom: 3rem;
        @include tablet {
          flex-direction: column;
          align-items: flex-start;
        }

        .seaz-uploader__label {
          max-width: 14rem;
          flex-shrink: 0;
          @include tablet {
            text-align: inherit;
          }
        }

        .seaz-uploader__choose_drop {
          width: 100%;
        }

        .seaz-uploader__box {
          @include tablet {
            flex-direction: row;
            justify-content: space-between;
            .seaz-uploader__choose {
              width: auto;
            }
          }
        }
      }
    }

    app-seazone-documents-uploader {
      width: 100%;
    }

    app-phone-number.seaz-crew__phone {
      .form__row {
        margin-bottom: 1.6rem;
      }

      .form__block-text {
        margin-right: 7rem;
        width: 100%;
        max-width: 14rem;
        font-size: 1.4rem;
        @media screen and (max-width: 800px) {
          margin-right: 2rem;
        }
      }

      .seazone-form-field--wrapper {
        @media screen and (max-width: 650px) {
          flex-direction: column;
        }
      }
    }

    @include mobile {
      .experience--row {
        flex-direction: row;
      }
    }
  }

  &__cruising_area {
    .form-field--content-outline {
      white-space: nowrap;
      text-overflow: inherit;
      overflow: hidden;
    }
  }

  &__preview_img_box {
    .seaz-modal__preview_img {
      max-width: 61rem;
      height: 40rem;
      object-fit: contain;
    }
  }

  &__periew_video {
    height: 27rem;
  }

  &__bank {
    &.seaz-modal__courses .seaz-modal__head_title {
      width: 65%;
      @include laptop {
        width: 91%;
      }
    }

    app-seazone-documents-uploader {
      .seaz-uploader__document {
        width: 100%;
        @include tablet {
          padding: 0;
        }

        .seaz-uploader__document_label {
          margin-right: 7rem;
          width: 14rem;
        }
      }
    }

    .form__row.seaz-uploader__document_row,
    .form__row.seaz-crew__btn_box {
      width: 100%;
    }

    .seaz-modal__document_form {
      @include tablet {
        padding: 0 1.6rem;
        box-sizing: border-box;
      }
    }
  }

  &__note {
    padding-top: 3rem;
  }

  &__offer_wrapp.seaz-modal {
    width: 64rem;
    // padding-right: 1rem;
    @include tablet-big {
      width: 100%;
    }

    .modal__wrap {
      padding: 0;
    }

    .modal-close {
      right: 3.2rem;
    }
  }

  &__apply {
    &_title {
      text-align: center;
      color: $clr-primary-and-accent;
      line-height: 4.4rem;
      text-transform: uppercase;
      width: 80%;
      margin: 0 auto;
      @include mobile {
        width: 100%;
        line-height: 3.4rem;
        padding-bottom: 1.8rem;
        padding-top: 2rem;
      }
    }

    &_text {
      font-size: 1.8rem;
      color: $seaz-about-text;
      text-align: center;
      padding: 0.8rem 0;
      line-height: 2.4rem;

      &_box {
        display: flex;
        flex-direction: column;
        margin-bottom: 3.2rem;

        &.no-title {
          margin-top: 2rem;
        }
      }
    }

    &_icon {
      display: block;
      font-size: 3rem;
      height: 3rem;
      width: 3rem;
      margin: 10px auto 0;

      &.mascotOk,
      &.mascotOk svg {
        width: 12.8rem;
        height: 12.8rem;
      }
    }

    &_btn_box {
      padding-top: 3rem;
      border-top: 1px solid $seaz-head-arrow;
      display: flex;
      justify-content: flex-end;

      button:not(:last-child) {
        margin-right: 2rem;
      }

      &.choose-captain {
        justify-content: center;
      }

      @include tablet {
        flex-direction: column;
        button:not(:last-child) {
          margin: 0 0 2rem;
        }
      }
    }
  }
}

.yaht-edit__document_btn_box {
  @include laptop {
    width: 91%;
  }
  @include tablet {
    width: 100%;
  }
}

.seaz-edit {
  &.active {
    background-color: black;
  }

  &__folder {
    display: flex;
    margin-bottom: 2rem;

    div {
      @extend .seaz__btn_outlined;
      @include mobile {
        padding: 1rem 1.2rem;
      }

      &:hover {
        background-color: $clr-primary-and-accent;
        border-color: $clr-primary-and-accent;
      }

      &.active {
        background-color: $clr-primary-and-accent;
        color: $clr-white;
        border-color: $clr-primary-and-accent;
      }
    }
  }
}

.seaz-select {
  &__overlay {
    app-seazone-select-option {
      padding: 0 1.4rem;

      .form__checkbox {
        flex-direction: row-reverse;
        justify-content: space-between;
      }

      app-seazone-checkbox {
        width: 100%;

        .form__checkbox-label-text {
          padding-left: 0;
        }
      }
    }
  }
}

.seaz-select__overlay .dropdown-options-container {
  @include custom-scroll-bar;
}

.seaz-uploader__document {
  display: flex;

  &_label {
    width: 14rem;
    margin-right: 7rem;
    flex-shrink: 0;
  }

  &_file {
    &_box {
      width: 100%;
    }

    display: flex;
    align-items: center;
    padding-bottom: 1.8rem;

    span {
      @extend .seaz-h6;
      color: $seaz-document-count;
      margin-right: 8px;
    }

    .icon-close {
      @extend .seaz-red-close;
      border: 1px solid $seaz-bg;
      color: $seaz-alarm;
    }
  }

  &_box {
    display: flex;
    flex-direction: column;
    align-items: baseline;
    position: relative;
    margin-bottom: 2rem;

    &_text {
      text-transform: uppercase;
      color: $seaz-document-count;
      padding: 1.6rem 0;
      @include tablet {
        display: none;
      }
    }

    &_drop {
      width: 100%;
      height: 12.4rem;
      display: flex;
      align-items: center;
      justify-content: center;
      border: 1.5px dashed #cacaca;
      opacity: 0.7;
      @include tablet {
        display: none;
      }

      &_text {
        color: $seaz-document-count;
      }
    }
  }

  &_row {
    app-seazone-documents-uploader {
      width: 100%;
    }
  }
}

.seaz-select__overlay {
  app-seazone-select-option.selected {
    background-color: $seaz-bg;
  }

  app-seazone-checkbox {
    .form__checkbox-label {
      width: 2.4rem;
      height: 2.4rem;

      &::before {
        width: 2.4rem;
        height: 2.4rem;
        border: 1.5px solid $seaz-border;
      }
    }

    .form__checkbox-input:checked + label:before {
      border-color: $seaz-mulberry;
      background-color: $seaz-mulberry;
    }
  }
}

.yaht-edit__document_btn_box {
  width: 90%;
  @include tablet-big {
    flex-direction: column;
    justify-content: center;
    width: 100%;

    button {
      width: 100%;
      margin: 0 0 1.6rem;
    }
  }
}

.seaz-modal__edit_courses {
  .form__row.seaz-crew__btn_box {
    width: 100%;
  }

  @include laptop {
    .seaz-modal__head_title,
    .form__row,
    .seaz-modal__document_row {
      width: 85%;
    }
  }
  @media screen and (max-width: 800px) {
    .seaz-uploader__document_label {
      margin-right: 2rem;
    }
    .form-field--wrapper__row.form-field--wrapper,
    .seaz-uploader__document {
      flex-direction: column;
    }
    .seaz-uploader__document_label {
      margin-bottom: 1rem;
    }
    .seaz-crew__btn_box button {
      margin-bottom: 1rem;
      // width: 46%;
    }
    .seaz-crew__btn:nth-of-type(2) {
      margin-right: 0;
    }

    .seaz-crew__btn_box {
      flex-wrap: wrap;
    }
  }
  @include tablet {
    .seaz-modal__courses_edit {
      right: 4rem;
      top: 3rem;
    }
    .seaz-modal__document_row_title {
      min-width: 11rem;
      max-width: 11rem;
    }
  }
}

.seaz-modal {
  &__bank {
    // &.seaz-modal__courses .seaz-modal__head_title {
    //   width: 66%;
    //   @include laptop {
    //     width: 90%;
    //   }
    //   @include tablet-big {
    //     width: 100%;
    //   }
    // }
    .seaz-crew__edit_box {
      .seaz-crew__edit_row.form__row {
        app-seazone-input .form-field--wrapper,
        app-seazone-select .form-field--wrapper {
          width: 100%;
          @include tablet-big {
            padding: 0;
          }
        }

        app-seazone-input .form-field--label,
        app-seazone-select .form-field--label {
          max-width: 17.7rem;
          margin-right: 1.2rem;
        }
      }
    }

    .seaz-uploader__document_label {
      width: 17.7rem;
      margin-right: 1.2rem;
    }

    .seaz-crew__edit_box {
      @include laptop {
        width: 90%;
      }
      @include tablet-big {
        width: 100%;
      }
    }

    .form__row.seaz-crew__edit_row {
      @include tablet-big {
        width: 100%;
      }
    }

    .seaz-crew__btn_box {
      @include tablet-big {
        width: 100%;
      }
    }

    .seaz-uploader__document_box_text,
    .seaz-uploader__document_box_drop {
      @include tablet-big {
        display: none;
      }
    }

    .seaz-modal__courses_edit {
      top: 9.5rem;
      @include tablet {
        top: 2.4rem;
      }
    }

    &.seaz-modal__edit_courses {
      .seaz-modal__document_row {
        @include laptop {
          width: 90%;
          padding-bottom: 2.3rem;
        }
        @include tablet-big {
          width: 100%;
        }
      }

      .seaz-modal__document_row_title {
        @include tablet {
          min-width: 17rem;
          max-width: 17rem;
        }
      }
    }
  }

  &__charter_name {
    &_title {
      color: $clr-primary-and-accent;
      margin-bottom: 1rem;
    }

    app-seazone-input {
      margin-bottom: 2rem;

      .form-field--wrapper.form-field--wrapper__row {
        @include tablet {
          flex-direction: column;
        }

        .form-field--label {
          margin-right: 1rem;
          @include tablet {
            margin-bottom: 1rem;
          }
        }
      }
    }
  }
}

.seaz-modal__offer {
  .form__toggle {
    &.disabled {
      .form__toggle-label:after {
        border-color: $seaz-head-arrow;
        background-color: $seaz-head-arrow;
      }

      .form__toggle-label:before {
        border-color: $seaz-head-arrow;
      }
    }
  }

  .seaz-modal__head {
    padding: 3rem 0 0;
  }

  .boat-short-info {
    margin-bottom: 3rem;
  }

  &.seaz-modal__offer_create,
  &.seaz-modal__offer_send {
    .seaz-crew__edit_box {
      .form-field--label {
        @media screen and (max-width: 850px) {
          margin-bottom: 0;
        }
      }

      .seaz-crew__edit_row.form__row {
        app-seazone-input,
        app-seazone-select,
        app-seazone-form-field {
          .form-field--wrapper {
            @media screen and (max-width: 850px) {
              flex-direction: row;
              align-items: center;
            }
            @include mobile {
              flex-direction: column;
              align-items: flex-start;
            }
          }

          .form-field--label {
            @media screen and (max-width: 850px) {
              max-width: 12rem;
              margin-right: 1.5rem;
              margin-bottom: 0;
            }
          }
        }

        .form-field--content-outline.form-field--content {
          @media screen and (max-width: 850px) {
            width: 100%;
          }
        }

        &.seaz-modal__offer_publish {
          @media screen and (max-width: 850px) {
            justify-content: flex-start;
          }
          @include mobile {
            justify-content: space-between;
          }
        }
      }

      .form__row.seaz-crew__edit_row {
        @media screen and (max-width: 850px) {
          flex-direction: row;
        }
        @include mobile {
          flex-direction: column;
        }
      }

      .form-field--label {
        @media screen and (max-width: 850px) {
          width: 12rem;
          max-width: 12rem;
        }
        @include mobile {
          margin-bottom: 1rem;
        }
      }
    }

    .form__checkbox {
      @include mobile {
        padding-left: 0;
      }
    }

    .job-offer__form_label {
      width: 17rem;
      margin-right: 1.5rem;
      @include laptop {
        width: 12rem;
      }
    }

    app-seazone-form-field.offset {
      margin-top: 0;
    }
  }

  .seaz-crew__edit_box {
    width: 100%;

    .seaz-crew__edit_row.form__row {
      @media screen and (max-width: 850px) {
        flex-direction: column;
        align-items: flex-start;
      }
    }

    .seaz-crew__edit_row.form__row {
      align-items: center;
      @include tablet {
        align-items: flex-start;
      }

      &.seaz-crew__edit_row--contract {
        .seaz-crew__edit_row--currency {
          @media screen and (max-width: 850px) {
            flex-direction: column;
            align-self: flex-start;
          }
        }
      }

      &.seaz-crew__edit_row--languages {
        align-items: flex-start;

        .seaz-language__form_section:not(:last-of-type) {
          border-bottom: none;
        }

        .seaz-language__form_section {
          display: flex;
          justify-content: space-between;
        }

        .seaz-language__form_row,
        .seaz-language__form_row:last-of-type {
          margin-bottom: 0;
          width: 48%;
        }

        .form-field--content-outline.form-field--content {
          width: 100%;
        }

        .form-field--content-outline.form-field--content.disabled {
          .select-placeholder {
            color: $seaz-document-count;
          }
        }

        app-language-level-form {
          .select-value {
            width: auto;
          }
        }
      }
    }

    .seaz-crew__edit_row.form__row app-seazone-input,
    .seaz-crew__edit_row.form__row app-seazone-select,
    .seaz-crew__edit_row.form__row app-date-control,
    .seaz-crew__edit_row.form__row app-seazone-text-area,
    .seaz-crew__edit_row.form__row app-location-autocomplete,
    .seaz-crew__edit_row.form__row app-location-region-autocomplete {
      width: 100%;

      .form-field--wrapper {
        width: 100%;
        flex-direction: row;
        align-items: center;

        @media screen and (max-width: 850px) {
          flex-direction: column;
          align-items: flex-start;
          padding: 0;
        }
      }

      .form-field--label {
        max-width: 17rem;
        @include laptop {
          max-width: 12rem;
        }
        @media screen and (max-width: 850px) {
          width: 100%;
          max-width: 100%;
          margin-right: 0;
          margin-bottom: 1rem;
        }
        @include laptop {
          font-size: 12px;
        }
      }
    }

    .form-field--label {
      max-width: 17rem;
      width: 17rem;
      margin-right: 1.5rem;
      flex-shrink: 0;
      @include laptop {
        max-width: 12rem;
        width: 12rem;
      }
      @media screen and (max-width: 850px) {
        width: auto;
        max-width: inherit;
        margin-bottom: 1rem;
      }
    }

    .seaz-crew__edit_row--currency {
      display: flex;
      @include mobile {
        flex-direction: column;
      }

      .seaz-crew__edit_label {
        padding-bottom: 1rem;
      }

      .form__checkbox {
        padding-left: 14.4rem;
        @include laptop {
          padding-left: 9.4rem;
        }
        @media screen and (max-width: 850px) {
          padding-left: 0;
        }
        @include mobile {
          padding-left: 0;
          padding-top: 1rem;
        }
      }
    }
  }

  .seaz-crew__edit_flexible {
    margin-top: 2rem;

    @include mobile {
      margin-top: 0.5rem;
    }
  }

  .form-field--content-outline.form-field--content.disabled {
    background-color: $clr-ashy-grey;
    // opacity: 0.3;

    .seazone__form-date-input,
    .seazone__text-area {
      background-color: $clr-ashy-grey;
    }

    input {
      background-color: $clr-ashy-grey;
    }
  }

  .form-field--content-outline.form-field--content {
    border-color: $seaz-head-arrow;

    input {
      &::placeholder {
        color: $seaz-document-count;
      }
    }

    .select-placeholder {
      color: $seaz-document-count;
    }
  }

  .form__toggle-label:after {
    left: 0;
  }

  .form__toggle {
    .form__toggle-label:before {
      width: 2.9rem;
      height: 1.6rem;
      border-radius: 18px;
    }

    .form__toggle-input:checked + label:before {
      background-color: $seaz-mulberry;
    }

    .form__toggle-label:after {
      top: 0;
      width: 1.6rem;
      height: 1.6rem;
      border: 2px solid $seaz-mulberry;
      background-color: transparent;
      box-shadow: none;
    }

    .form__toggle-input:checked + label:after {
      background-color: $clr-white;
      border: 2px solid $seaz-mulberry;
      width: 1.6rem;
      height: 1.6rem;
    }

    .form__toggle-label:before {
      background-color: transparent;
      border: 2px solid $seaz-mulberry;
    }
  }

  &_section {
    color: $seaz-text;
    padding: 1rem 3rem;
    background-color: $seaz-bg;
    margin-bottom: 2rem;
    @media screen and (max-width: 850px) {
      padding: 0.4rem 0.8rem;
      margin: 0 3rem 2rem 3rem;
    }
    @include mobile {
      margin-top: 2rem;
    }
  }

  app-seazone-checkbox {
    &.covid-checkbox {
      .form__checkbox {
        padding-left: 0;
      }
    }

    .form__checkbox {
      padding-left: 19rem;
      margin-bottom: 1.6rem;
      @include laptop {
        padding-left: 13.5rem;
      }

      .form__checkbox-label-text {
        padding-left: 0.8rem;
        color: #000;
      }

      .form__checkbox-label {
        width: 2.4rem;
        height: 2.4rem;
        @include mobile {
          padding: 0;
        }
      }

      .form__checkbox-label:before {
        width: 2.4rem;
        height: 2.4rem;
        border-radius: 0;
      }

      &:not(.disabled) {
        .form__checkbox-label-text {
          color: #000;
        }

        .form__checkbox-label:before {
          border-color: $seaz-mulberry;
        }

        .form__checkbox-input:checked + label:before {
          background-color: $seaz-mulberry;
        }
      }
    }
  }

  &_date {
    &.seaz-crew__edit_row.form__row {
      margin-bottom: 5px;
    }
  }

  &_range {
    width: 100%;

    &_label {
      color: $seaz-document-count;
      font-size: 1.4rem;
    }

    &_count {
      margin-left: 5px;
    }
  }

  app-seazone-range-slider {
    padding: 0;

    .seazone-range-slider {
      height: 35px;
    }
  }

  &_radio {
    margin-top: 3rem;

    app-seazone-radio {
      padding: 0;
      width: 100%;

      .form__block {
        flex-direction: row;
        @include mobile {
          flex-direction: column;
        }

        .form__block_label {
          width: 17rem;
          max-width: 17rem;
          margin-right: 1.5rem;
          @include laptop {
            max-width: 12rem;
            width: 12rem;
          }
          @media screen and (max-width: 850px) {
            max-width: 15rem;
            width: 15rem;
          }
          @include mobile {
            max-width: 14rem;
            width: 14rem;
            font-size: 12px;
            margin: 0 0 1.5rem;
          }
        }

        .form__radio-container {
          display: flex;
        }

        .form__radio {
          margin-right: 3rem;
        }

        .form__radio-label-text {
          @media screen and (max-width: 850px) {
            word-break: break-all;
          }
        }
      }
    }
  }

  .seaz-crew__btn_box {
    margin-top: 4.8rem;
    width: 100%;
    padding-bottom: 3rem;
    @include mobile {
      margin-top: 2rem;
    }
  }

  .seaz-crew__edit_box .seaz-crew__edit_row.form__row.seaz-modal__offer_publish {
    @media screen and (max-width: 850px) {
      justify-content: space-between;
      align-items: center;
      flex-direction: row;
    }
  }

  .seaz-crew__edit_row {
    .form-field--content-outline.form-field--content {
      .select-container {
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        width: 100%;
      }
    }
  }

  .seazone-form-field {
    &--wrapper {
      .form-field--label {
        margin-right: 1.5rem !important;
      }
    }
  }
}

.job-offer {
  &__invite {
    padding-top: 2rem;

    &_title {
      padding: 0 3rem 3rem 3rem;
      padding-top: 0;
      color: $clr-primary-and-accent;
    }

    &_form {
      app-seazone-form-field .form-field--wrapper {
        flex-direction: row;
        align-items: center;
        @include mobile {
          flex-direction: column;
          align-items: flex-start;
        }

        .form-field--label {
          width: 16rem;
          padding-right: 1rem;
          font-size: 1.4rem;
          color: $seaz-text;
          @include mobile {
            margin-bottom: 1rem;
          }
        }

        .form-field--content.form-field--content-outline {
          border: 1px solid $seaz-head-arrow;
        }

        .form-field--content-wrapper {
          input {
            &::placeholder {
              color: $seaz-document-count;
            }
          }
        }
      }

      .seaz-crew__edit_row.form__row {
        margin-bottom: 3rem;
        padding: 0 3rem;
      }
    }

    &_btns {
      width: 94%;
      margin: 0 auto;
      padding: 3rem 0;
      border-top: 1px solid $seaz-head-arrow;
      justify-content: flex-end;

      .seaz__btn_primary {
        width: auto;
      }
    }
  }
}

.seaz-crew__btn_delete {
  display: flex;
  @include tablet {
    width: auto;
    padding: 0;
    border: none;
    margin: 0 auto 0 0;
    padding-bottom: 0.8rem;
  }

  &_icon {
    display: none;
    @include tablet {
      display: flex;
      background-image: url('/assets/img/busket-cancel.svg');
      width: 2.8rem;
      height: 3.2rem;
      background-position: center;
      background-repeat: no-repeat;
      background-size: cover;
    }
  }
}

.seaz-cv__tooltip.tooltip-pane {
  .tooltip {
    font-family: $base-font;
    box-shadow: (0px 0px 12px rgba(53, 31, 115, 0.22));
  }

  .tooltip__text {
    font-weight: 500;
    text-transform: uppercase;
    letter-spacing: 0.1em;
    font-size: 1.2rem;
    color: #000;
  }

  &.seaz-cv__tooltip--contract.top {
    .tooltip::before {
      transform: translateX(calc(-50% + 2px));
    }
  }

  &.seaz-cv__tooltip--contract.bottom {
    .tooltip::before {
      transform: translateX(calc(-50% + 2px)) rotate(180deg);
    }
  }

  &.seaz-cv__tooltip--no-info.top {
    .tooltip::before {
      transform: translateX(calc(-50% + 8px));
    }
  }

  &.seaz-cv__tooltip--no-info.bottom {
    .tooltip::before {
      transform: translateX(calc(-50% + 8px)) rotate(180deg);
    }
  }

  &.seaz-cv__tooltip--contract,
  &.seaz-cv__tooltip--no-info {
    .tooltip__text {
      color: $clr-light-grey;
      letter-spacing: 0.01em;
      text-transform: unset;
    }
  }
}

.seaz-crew__tooltip {
  .tooltip {
    padding: 0.5rem 0.6rem;
    border: 1px solid $seaz-head-arrow;
    color: $seaz-document-count;
  }
}

.job-offer__form_label {
  width: 17rem;
  margin-right: 1.5rem;
  flex-shrink: 0;
  @include laptop {
    font-size: 12px;
    width: 12rem;
  }
  @include tablet {
    margin-bottom: 1rem;
    width: 100%;
    margin-right: 0;
  }
}

.seaz-map {
  &__modal {
    &_title {
      margin-bottom: 1.5rem;
      color: $clr-primary-and-accent;
    }

    &_wrapper {
      app-search-location-form {
        .form-field--content-outline.form-field--content {
          height: 4rem;
          border-radius: 0;
          border-color: $seaz-head-arrow;
        }

        .distance {
          display: flex;
          align-items: center;
          margin-bottom: 2rem;

          .form-field--wrapper {
            flex-direction: row-reverse;
          }

          app-seazone-form-field {
            .form-field--wrapper__row .form-field--label {
              @include mobile {
                display: none;
              }
            }
          }

          app-seazone-range-slider {
            padding: 1rem 1.6rem;
            width: 100%;
            flex-direction: row;
            align-items: center;

            .seazone-range-slider {
              margin-right: 1.6rem;
              @include laptop {
                min-width: 90px;
              }
            }
          }

          .seazone-range-slider__inputs {
            .form__row {
              margin-bottom: 0;
            }

            .form__col {
              width: 100%;
            }

            .form-field--label {
              margin-right: 0;
              width: auto;
              margin-left: 1.6rem;
              @include laptop {
                margin-bottom: 0;
              }
            }

            .form-field--content {
              width: 7.5rem;
            }
          }

          &_km {
            padding: 1.1rem 1.6rem;
            color: $seaz-text;
            border: 1px solid $seaz-head-arrow;
          }
        }

        .seaz-filter__row--right {
          display: flex;
          align-items: center;

          app-seazone-checkbox:not(:first-of-type) {
            .form__checkbox {
              margin-left: 2.4rem;
            }
          }
        }

        .form__checkbox {
          .form__checkbox-label-text {
            padding-left: 0.8rem;
            color: $seaz-text;
            font-size: 1.4rem;
          }

          .form__checkbox-label {
            height: 2.4rem;
            width: 2.4rem;

            &:before {
              border-radius: 0;
              border-color: $seaz-mulberry;
              height: 2.4rem;
              width: 2.4rem;
            }
          }

          .form__checkbox-input:checked + label:before {
            background-color: $seaz-mulberry;
          }
        }
      }
    }
  }
}

.seaz-search {
  &__no_date {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
}

.seaz-modal {
  &__contact {
    &_title {
      color: $clr-primary-and-accent;
    }

    &_box {
      display: flex;
      flex-direction: column;
      padding-top: 3rem;

      .seaz-cv__contact_item {
        margin-bottom: 3rem;
        padding-right: 0;
        width: 100%;
      }

      .seaz-cv__contact_item:nth-of-type(2n) {
        padding-left: 0;
      }
    }

    &_btn {
      padding: 3rem 0 0;
      border-top: 1px solid $seaz-head-arrow;
      display: flex;
      justify-content: flex-end;

      button {
        &:not(:last-of-type) {
          margin-right: 1.2rem;
        }
      }
    }
  }

  &__status {
    &_title {
      color: $clr-primary-and-accent;
      padding-bottom: 4.6rem;
    }

    &_form {
      display: flex;
      flex-direction: column;

      app-seazone-radio-item {
        display: flex;
        padding: 1.6rem;
        align-items: center;

        &:hover {
          background-color: #f5f5f5;
        }

        .form__radio-label-text {
          padding: 0.8rem 1.2rem;
          text-transform: uppercase;
          font-weight: 500;
          letter-spacing: 0.1em;
          color: $clr-white;
        }

        &.looking-job {
          .form__radio-label-text {
            background-color: $seaz-edit-hover;

            &.disabled {
              background-color: $seaz-border;
            }
          }
        }

        &.vacation {
          .form__radio-label-text {
            background-color: $clr-green;

            &.disabled {
              background-color: $seaz-border;
            }
          }
        }

        &.on-board {
          .form__radio-label-text {
            background-color: $seaz-mulberry;
          }
        }

        &.not-active {
          .form__radio-label-text {
            background-color: $clr-grey;
          }
        }

        .form__radio-label {
          height: 1.8rem;
        }

        .form__radio-label.disabled {
          cursor: default;

          &:before {
            border-color: $seaz-border;
          }
        }
      }
    }

    &_warning {
      font-size: 1.2rem;
      color: $seaz-about-text;
      padding-left: 4.2rem;
    }

    &_updated {
      text-align: center;
      width: 67%;
      margin: 0 auto;
      line-height: 2.4rem;
      color: $clr-primary-and-accent;
      padding: 1.6rem 0;
      font-size: 1.8rem;
    }

    &_finish {
      padding: 1.2rem;
      text-align: center;

      &_title {
        padding-bottom: 0.8rem;
        color: $clr-primary-and-accent;
        text-transform: uppercase;
      }

      &_text {
        padding: 0 0.2rem 0.8rem 0.2rem;
        color: $seaz-about-text;
        font-size: 1.8rem;
        line-height: 2.4rem;
        font-weight: 400;
      }

      &_icon {
        font-size: 28px;
        display: block;
        padding-top: 0.8rem;
      }
    }

    &_jump_text {
      color: #4f4f4f;
      font-size: 1.8rem;
      text-align: center;
      display: block;
      line-height: 1.8rem;
      padding-bottom: 1.2rem;

      &_box {
        padding-bottom: 1.8rem;
      }
    }

    &_jump_wrapper {
      padding: 2.4rem 3.2rem;
      border: 1px dashed $dashed-border;
      width: fit-content;
      margin: 2rem auto 3rem;
      position: relative;

      &::before {
        content: '';
        height: 16px;
        width: 16px;
        position: absolute;
        right: 0;
        top: -9px;
        background: #ffffff;
        margin: auto;
        transform: rotate(-134deg);
        border: 1px dashed $dashed-border;
        border-top: none;
        border-left: none;
        left: 0;
      }

      .seaz-cv__head_btn_jump {
        margin-right: 0;
      }
    }
  }
}

.seaz-contract {
  &__finish {
    &_form {
      .seaz-modal__experience_date_box {
        margin-bottom: 2.4rem;
      }

      .form-field--wrapper.seazone-form-field--wrapper.form-field--wrapper__row {
        .form-field--label {
          max-width: 16rem;
          margin-right: 5rem;
        }
      }

      app-seazone-documents-uploader {
        width: 100%;

        .seaz-uploader__document_label {
          margin-right: 5rem;
          max-width: 16rem;
          width: 16rem;
        }
      }

      app-phone-number {
        .form__block-text {
          margin-right: 5rem;
          max-width: 16rem;
          width: 16rem;
          flex-shrink: 0;
        }

        &.seaz-crew__phone .phone-control-container.disabled {
          background-color: #e7e7eb;
          border: 1px solid #a9a9a9;

          .phone-input input,
          .phone-code-container {
            background-color: #e7e7eb;
          }
        }

        .phone-control-wrapper {
          width: 100%;
        }
      }

      .form__row {
        .reference__control {
          margin-top: 0;
          margin-left: 0;
        }
      }

      .seaz-modal__head {
        margin-right: 5rem;
        max-width: 16rem;
        width: 16rem;
        @include mobile {
          padding-bottom: 1rem;
        }
      }

      .seaz-uploader__document {
        @include mobile {
          flex-direction: column;
        }

        .seaz-uploader__document_label {
          @include mobile {
            margin-bottom: 1rem;
          }
        }
      }

      .seaz-crew__btn_box {
        @include mobile {
          flex-direction: column;
        }

        button {
          @include mobile {
            width: 100%;
            margin-right: 0;
            margin-bottom: 1rem;
          }
        }
      }
    }
  }
}

.info-modal .info-modal__header .info-modal__header-icons {
  img.info-modal__header_logo {
    width: 10.6rem;
    height: 10.6rem;
    margin: 1.3rem auto;
  }
}

.notification-modal-content {
  display: flex;
  flex-direction: column;
  justify-content: center;
  font-family: 'Roboto', sans-serif;
  text-align: center;

  &__img {
    margin-top: 3rem;
    margin-bottom: 5rem;

    app-seazone-icon[name='warningTriangle'] svg {
      fill: $seaz-warning;
      width: 124px;
      height: 124px;
    }
  }

  &__text {
    font-size: 1.6rem;
    line-height: 2.2rem;
  }
}

.seaz-modal__update-location {
  .seaz-modal__offer .seaz-modal__head {
    padding: 0 0 3rem 0;
  }

  .seazone-form-field--wrapper.form-field--wrapper__row.form-field--wrapper {
    .form-field--label {
      margin-right: 1rem;
      max-width: 15rem;
    }
  }

  app-location-autocomplete {
    width: 100%;
  }

  app-seazone-checkbox .form__checkbox {
    padding-left: 16rem;
  }

  .seaz-crew__btn_box {
    padding-bottom: 0;
  }
}

.add-boat-modal {
  .modal-content__text {
    margin: 3rem 0 0;
    color: $seaz-about-text;
  }

  &__controls {
    margin-top: 3rem;
  }

  &__footer {
    display: flex;
    justify-content: flex-end;
    margin-top: 3rem;
  }

  &__cancel-btn {
    font-size: 1.6rem;
    line-height: 2.8rem;
  }
}

.add-boat-modal-button {
  display: flex;
  align-items: center;
  position: relative;
  width: 100%;
  background-color: $clr-ashy-grey;
  border-radius: 1.2rem;
  padding: 1.6rem 1.8rem;

  &:not(:last-of-type) {
    margin-bottom: 1.6rem;
  }

  &__content {
    display: block;
    font-size: 2rem;
    font-weight: 500;
    color: $seaz-text;
    text-align: left;
  }

  &__icon {
    display: block;
    margin-right: 1.8rem;
  }

  &__text {
    display: block;
    font-size: 1.6rem;
    line-height: 1.9rem;
    color: $profile-month;
    margin-top: 0.4rem;
  }

  &__icon-arrow {
    position: absolute;
    top: 50%;
    right: 1.5rem;
    transform: translate(0, -50%) rotateZ(180deg);
    transition: transform 0.3s;
  }
}

.add-boat-modal-button:hover .add-boat-modal-button__icon-arrow {
  transform: translate(0.5rem, -50%) rotateZ(180deg);
}

.trial-warning-modal {
  .modal-content__text {
    color: $seaz-about-text;
    margin: 3rem;
  }
}

.seaz-modal__offer_language {
  width: 100%;
}

.seaz-modal {
  &__rating {
    &_title {
      color: $clr-primary-and-accent;
      padding-bottom: 4rem;
    }

    &_total {
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding-bottom: 3rem;
      border-bottom: 1px solid $seaz-head-arrow;
      margin-bottom: 3rem;

      &_text {
        font-weight: 500;
        font-size: 2.4rem;
        color: $seaz-text;
      }
    }

    &_badge {
      display: flex;
      align-items: center;
      background-color: $clr-green;
      border-radius: 0.8rem;
      padding: 1.2rem 1.1rem;
      font-weight: 500;
      font-size: 2.4rem;
      color: $clr-white;
    }

    &_scale {
      &_text {
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding-bottom: 0.8rem;
      }

      &_name,
      &_score {
        color: $seaz-text;
      }

      &_score {
        letter-spacing: 1px;
      }

      &_line {
        width: 100%;
        height: 0.4rem;
        background-color: $seaz-bg;
        margin-bottom: 3rem;
        border-radius: 12px;
        overflow: hidden;

        &--filled {
          display: block;
          height: 0.4rem;
          background-color: $clr-green;
        }
      }
    }

    &_review {
      &_title {
        padding-bottom: 0.8rem;
        color: $seaz-text;
        display: block;
      }

      &_comment {
        color: $seaz-about-text;
        padding-bottom: 3rem;
      }
    }

    &_btn {
      &.seaz-crew__btn_box {
        border-color: $seaz-head-arrow;
      }
    }
  }

  &__share_offer {
    &_title {
      text-transform: uppercase;
      color: $clr-primary-and-accent;
      padding-bottom: 0.8rem;
    }

    &_subtitle {
      padding-bottom: 2.4rem;
      color: $seaz-text;
      line-height: 2.6rem;
    }

    @media screen and (max-width: 600px) {
      .header__controls {
        transform: translate(-50%, 83%) !important;
      }
    }

    &_selected {
      color: $seaz-about-text;

      &_box {
        padding-bottom: 2.4rem;
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-right: 2.4rem;
        @media screen and (max-width: 600px) {
          margin-right: 1.2rem;
        }

        .form__checkbox.boat__form-checkbox {
          width: 2.4rem;
        }
      }
    }

    &_all {
      display: flex;
      align-items: center;

      &_text {
        color: $clr-primary-and-accent;
        margin-right: 1.2rem;
      }

      .boat__form-checkbox {
        margin-bottom: 0;
      }
    }

    &_checkbox {
      .form__checkbox-label {
        width: 2.4rem;
        height: 2.4rem;
        @include mobile {
          padding: 0;
        }

        &:before {
          border-color: $seaz-border;
          border-radius: 0;
          width: 2.4rem;
          height: 2.4rem;
        }
      }

      .form__checkbox-label-text {
        display: none;
      }

      .boat__form-checkbox .form__checkbox-input:checked + label::before {
        background-color: $seaz-mulberry;
        border-color: $seaz-mulberry;
      }
    }

    &-btn {
      margin-top: 4rem;
    }

    .seaz-search__card {
      margin-right: 0;
    }

    .seaz-search__card_info {
      @media screen and (max-width: 600px) {
        padding-bottom: 5.8rem;
      }
    }

    .seaz-search__card_head {
      align-items: center;
      border: 1px solid $seaz-head-arrow;
      border-bottom: none;

      @media screen and (max-width: 600px) {
        padding: 2.4rem 1.2rem;
      }

      @media screen and (max-width: 480px) {
        border: none;
      }

      .header__controls {
        @include tablet {
          position: initial;
        }
      }
    }

    .seaz-search__card_head_info {
      @media screen and (max-width: 600px) {
        width: 100%;
      }
    }

    .seaz-search__card_btns {
      width: auto;
    }

    .job-offer__card_id {
      margin-top: 0;
    }

    .seaz-search__card_location {
      font-size: 1.4rem;
    }

    .seaz-search__card_head_status .seaz-cv__badges_status_job {
      min-width: auto;
      font-size: 1.4rem;
      padding: 0.7rem 1rem;
      min-height: auto;
      @media screen and (max-width: 600px) {
        margin-right: 0;
      }
    }

    .seaz-search__card_info_head_row {
      @media screen and (max-width: 600px) {
        justify-content: center;
      }
    }

    .seaz-search__card_head_updates_status {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
    }

    .offerred-status-process,
    .offerred-status-pending,
    .offerred-status-new,
    .offerred-status-declined,
    .offerred-status-hired,
    .offerred-status-closed,
    .offerred-status-shared {
      text-transform: uppercase;
      font-size: 1.4rem;
      padding: 0.7rem 2rem;
      font-weight: 500;
      letter-spacing: 0.1em;
      text-align: center;
    }

    .offerred-status-declined.grey {
      background-color: $seaz-document-count;
    }

    .seaz-search__card_head .header__controls {
      @media screen and (max-width: 1150px) {
        position: initial;
      }
    }

    .seaz-search__card_avatar {
      @include tablet {
        width: 12rem;
        height: 12rem;
      }
    }

    .seaz-search__card_position {
      @include tablet {
        width: 3.6rem;
        height: 3.6rem;
      }
    }

    .seaz-search__card_photo {
      @include tablet {
        height: 12rem;
      }
    }

    .seaz-search__card_info_head_row {
      @include tablet {
        padding: 0 0 1.2rem 0;
      }
    }

    .seaz-search__card_head_badges {
      @media screen and (max-width: 600px) {
        flex-direction: column;
      }
    }

    .seaz-search__card--offer .seaz-search__card_info_col .job-offer__card_id {
      @include tablet {
        flex-direction: column;
      }
    }

    .seaz-search__card {
      border-color: $seaz-head-arrow;
    }
  }

  &__looking {
    &_title {
      color: $clr-primary-and-accent;
    }

    &_text {
      padding-top: 3rem;
      font-size: 1.8rem;
      line-height: 2.4rem;
      color: $seaz-text;
      font-weight: 300;

      span {
        font-weight: 500;
      }

      &--jump {
        span {
          font-weight: 500;
          text-transform: uppercase;
          color: $seaz-edit-hover;
        }
      }

      &_luck {
        padding-top: 2.4rem;
        font-size: 1.8rem;
        font-weight: 300;
        color: $seaz-text;
        padding-bottom: 3rem;
      }
    }

    &_btns {
      border-top: 1px solid $seaz-head-arrow;
      padding-top: 3rem;
      display: flex;
      justify-content: flex-end;
      @include tablet {
        flex-direction: column;
      }

      button {
        @include tablet {
          margin-bottom: 1rem;
        }

        &:not(:last-of-type) {
          margin-right: 1.2rem;
          @include tablet {
            margin-right: 0;
          }
        }
      }
    }
  }
}

.offerred-status-declined.grey {
  background-color: $seaz-document-count;
}

.seaz-modal__deactivate {
  &_title {
    padding-bottom: 3rem;
    color: $clr-primary-and-accent;
    line-height: 2.8rem;
  }

  &_text {
    padding-bottom: 3rem;
    font-size: 1.8rem;
    color: $seaz-text;
  }

  .form__row.seaz-crew__btn_box {
    @include tablet {
      flex-wrap: wrap;
    }

    .seaz__btn_outlined,
    .seaz__btn_primary {
      padding: 1.4rem 1.8rem;
    }

    button {
      margin-right: 1.2rem;
      @include tablet {
        margin-bottom: 1rem;
      }
      @include mobile {
        width: 100%;
        margin-right: 0;
      }

      &:last-of-type {
        margin-right: 0;
      }

      &:nth-of-type(2n) {
        @include tablet {
          margin-right: 0;
        }
      }
    }
  }
}

.charter-guest-form-modal,
.charter-broker-form-modal {
  .modal__wrap {
    padding: 0;
  }

  &__wrap {
    box-sizing: border-box;
    padding: 2rem;
  }

  .modal-close {
    z-index: 1;
  }
}

.seaz-charter__guests_add-modal {
  width: 100%;
  max-width: 61.6rem;
  padding: 12rem 1rem;
  margin: 0 auto;

  .seaz-charter__guests_add_box {
    display: flex;
    -webkit-justify-content: space-between;
    align-items: flex-start;
    width: 100%;
    margin: 0;
    padding: 0;
  }

  .seaz-charter__guests_view_edit {
    position: relative;
    top: 0;
    left: 0;
  }

  &__wrap {
    &:not(:first-of-type) {
      margin-top: 4rem;
    }
  }

  &__title {
    font-weight: bold;
    color: $seaz-about-text;
    margin-bottom: 1.6rem;
  }

  &__item {
    display: flex;
    justify-content: space-between;
    align-items: center;
    color: $clr-purple;
    padding: 1.2rem 1.6rem;

    &:nth-child(2n + 1) {
      background-color: $seaz-logo-border;
    }
  }

  &__val {
    font-size: 1.6rem;
    color: #313247;

    &--include {
      color: $clr-grey;
    }
  }

  @media screen and (max-width: 768px) {
    padding: 7rem 1rem;

    &__wrap {
      &:not(:first-of-type) {
        margin-top: 3rem;
      }
    }

    &__item {
      padding: 1rem 1.2rem;
    }
  }
}

.empty-active-job-offers-modal {
  .seaz-h3 {
    margin-bottom: 2rem;
    color: #351f73;
    text-align: center;
  }

  .modal-content__text {
    line-height: 1.5;
    text-align: center;

    p:not(:last-child) {
      margin-bottom: 2rem;
    }
  }
}

.seaz-charter-add-edit-persons-form {
  @media screen and (max-width: 768px) {
    .seaz-charter-new-agent__item {
      flex-direction: column;
    }

    .seaz-charter-new-agent__item .seaz-charter-form-label {
      margin: 0 0 1rem 0;
    }
  }
}

.seaz-search-location-modal-form {
  &__content {
    padding: 0 0 2.4rem;
  }

  &__content-wrap {
    padding: 2.4rem;
    border-bottom: 1px solid $seaz-head-arrow;

    &--not-border {
      border: none;
    }
  }

  .seaz-filters__head_top_box {
    width: auto;
    border-bottom: 1px solid $seaz-head-arrow;
    padding: 2.4rem 2.4rem 1.2rem 2.4rem;
  }

  .chips-item.selected {
    border: 2px solid $seaz-edit-hover;
  }

  &__text {
    margin-bottom: 1.6rem;
  }

  .seaz-search-crew-filters__search {
    .form-field--content.form-field--content-outline {
      width: 100%;
    }
  }

  @media screen and (max-width: 480px) {
    padding-bottom: 3rem;

    .seaz-filters__head_top_box {
      padding: 1.6rem 1.6rem 1.2rem 1.6rem;
    }

    .form-field--wrapper.form-field--wrapper__row.seazone-form-field--wrapper {
      flex-direction: row;
    }
  }
}

.seaz-modal-experience {
  &__edit {
  }

  @media screen and (max-width: 768px) {
    &__edit {
      right: 16rem;
      top: 2.5rem;
    }
  }

  @media screen and (max-width: 650px) {
    .seaz-modal__courses_edit {
      right: 4rem;
      top: 2.5rem;
    }
  }

  .form__row.seaz-crew__btn_box {
    margin-top: 1rem;
  }
}

.seaz-modal-courses-form {
  .seaz-crew__btn_box {
    flex-wrap: nowrap;
  }

  @media screen and (max-width: 768px) {
    .form-field--label {
      margin-right: 2rem;
      margin-bottom: 0.8rem;
    }
  }
}

.seaz-modal-job-offer {
  height: 100%;
  padding: 0 2rem;
  overflow: auto;

  .seaz-h5-caps {
    margin: 0 0 2rem 0;
  }

  .seaz-crew__edit_box {
    padding: 0;
  }

  .areas-row {
    margin-bottom: 3.2rem;

    .form-field--content {
      .select-value {
        width: 3.5rem;
      }
    }
  }

  .info-label-modal-offer {
    display: block;
    margin-bottom: 3rem;
  }

  .for-couples-toggle {
    margin-left: 1.6rem;
  }

  .for-couples-box {
    @include d-flex-space-between;
    align-items: center;

    .form-field--content {
      align-items: center;
    }

    .form-field--content-outline {
      height: 4rem;
    }

    .form-field--wrapper {
      flex-direction: row;
      align-items: center;
    }

    .form-field--label {
      font-size: 1.4rem;
      font-weight: 400;
      color: #010202;
    }

    @include tablet {
      flex-direction: column;
      align-items: flex-start;

      .for-couples-btn-box {
        margin: 1.6rem 0 0 0;
      }
    }

    @include mobile {
      .form-field--wrapper {
        flex-direction: column;
        align-items: flex-start;
      }
    }
  }

  .for-couples-btn-box {
    flex-shrink: 0;
    margin-left: 1.6rem;
  }

  @include mobile {
    .seaz-crew__edit_box .seaz-crew__edit_row.form__row {
      padding: 0;
    }

    .for-couples-toggle-row {
      flex-direction: row;
      justify-content: space-between;
    }

    .for-couples-box {
      flex-direction: column;
    }

    .for-couples-btn-box {
      width: 100%;
      flex-shrink: 1;
      margin: 1.6rem 0 0;
    }

    .new-couple-btn {
      display: block;
      width: 100%;
    }
  }
}

.seaz-modal__offer_create.seaz-modal-job-offer {
  @include mobile {
    .seaz-modal__offer_publish {
      margin-top: 1.5rem;
    }

    .seaz-modal__offer_publish .form-field--label {
      margin-bottom: 0;
    }
  }
}

.release-info__modal {
  max-width: 100rem;
  width: 100%;

  .modal__wrap {
    padding: 0;
  }
}

.seaz-modal-other-experience-form {
  @include laptop {
    .form-field--wrapper__row.form-field--wrapper .form-field--label,
    .seaz-modal__experience_date_label {
      margin-bottom: 1rem;
      margin-right: 0;
    }

    .form__row.seaz-crew__btn_box,
    .form__row {
      width: 100%;
    }

    .form-field--wrapper__row.form-field--wrapper,
    .seaz-modal__experience_date_control {
      flex-direction: column;
      width: 100%;
    }
  }
}

.seaz-update-location-modal {
  app-seazone-checkbox.seaz-update-location-modal__checkbox .form__checkbox-label:before,
  app-seazone-checkbox .form__checkbox .form__checkbox-label {
    width: 1.8rem;
    height: 1.8rem;
    border-radius: 0.2rem;
  }

  .form__checkbox-label-text {
    font-size: 1.4rem;
  }

  @include mobile {
    .form__checkbox {
      padding: 0 !important;
    }
  }
}

.modal-right {
  app-overlay {
    background-color: $clr-white;
    width: 100%;
    height: 100%;
    transform: translateX(100%);
    position: fixed;
    right: 0;
    top: 0;
  }

  .modal__wrap {
    padding: 0;
  }
}

.modal-left {
  app-overlay {
    background-color: $clr-white;
    width: 100%;
    height: 100%;
    transform: translateX(-100%);
    position: fixed;
    left: 0;
    top: 0;
  }

  .modal__wrap {
    padding: 0;
  }
}

.seaz-rounded.seaz-modal {
  width: 100%;
  border-radius: 1.6rem;
  padding: 1rem;

  .modal-close {
    top: 3.8rem;
  }
}

.info-modal {
  &__text {
    font-size: 1.6rem;
    line-height: 1.9rem;
    color: $seaz-document-count;

    &--custom-text {
      color: $seaz-text;
      line-height: 2rem;
    }
  }

  &__content {
    margin-top: 1.6rem;
  }

  &__controls {
    display: flex;
    justify-content: flex-end;
    margin-top: 3rem;
  }

  &__btn {
    &:not(:last-of-type) {
      margin-right: 1.2rem;
    }
  }

  a {
    color: $seaz-edit-hover;
  }
}

.general-experience-date {
  .select-value {
    width: auto;
  }
}

.ais-modal {
  .modal-content__text {
    margin-bottom: 1.2rem;

    &--hint {
      font-size: 1.4rem;
      color: #828282;
      line-height: 1.6rem;
      margin-bottom: 3rem;
    }
  }
}

.document-modal-header {
  @include laptop {
    padding-top: 3.3rem;
  }
}

.general-update-report-modal-form-footer {
  @include footer-with-border-left-btn(0, 4.6rem 0 0 0);
  border: none;
}

.text-area-general-update-report-modal {
  app-seazone-text-area .wrapper-rounded .form-field--content {
    height: 8rem;
  }
}

.crew-cv-contacts-modal {
  .modal-content__title {
    margin-top: 0;
    margin-bottom: 3rem;
  }

  .modal-content {
    a {
      color: $seaz-edit-hover;
    }
  }
}

.modal-container {
  padding: 1rem;
  &--title {
    margin-bottom: 3rem;
  }
  &:has(&--subtitle) {
    .modal-container--title {
      margin-bottom: 1.2rem;
    }
  }
  &--subtitle {
    margin-bottom: 3rem;
  }
  &--form-content {
    margin-bottom: 3rem;
  }
  &--actions {
    display: flex;
    justify-content: flex-end;
    .seaz__btn_rounded {
      line-height: 2.8rem;
      &:not(:last-child) {
        margin-right: 1.2rem;
      }
    }
    &__col {
      display: flex;
      justify-content: flex-end;
      &:last-child {
        flex: 1 1 auto;
      }
    }
  }
}
