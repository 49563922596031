.seaz-quick-filters {
  &__title {
    margin-bottom: 1.2rem;
  }

  &__container {
    position: relative;

    app-scroll-controls .controls-container {
      position: static;

      button {
        height: auto;
        width: auto;
        display: inline-block;
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        background-color: initial;
        border-radius: 0;
        z-index: 2;

        &:hover,
        &:active {
          background-color: initial;

          .icon-arrow-left,
          .icon-arrow-rights {
            &::before {
              color: initial;
            }
          }
        }

        &:nth-child(1) {
          left: -1.6rem;

          .icon-arrow-left::before {
            transform: rotate(180deg);
          }
        }

        &:nth-child(2) {
          right: -1.6rem;
        }

        .icon-arrow-left,
        .icon-arrow-rights {
          &::before {
            content: '\e823';
            font-weight: bold;
            font-size: 1.4rem;
          }
        }

        @include mobile {
          &:nth-child(1) {
            left: 0;
          }

          &:nth-child(2) {
            right: 0;
          }
        }
      }
    }
  }

  &__slider {
    display: flex;
    width: 100%;
    overflow: hidden;
  }

  &__list {
    display: flex;
    align-self: stretch;
  }

  &__item {
    &:not(:last-of-type) {
      margin-right: 2.4rem;
    }
  }

  @include tablet {
    &__slider {
      overflow: auto;
    }
  }

  @include mobile {
    &__container {
      padding: 0 2rem;
    }
  }
}

.seaz-quick-filters-box {
  min-width: 19rem;
  height: 100%;
  box-sizing: border-box;
  border: 1px solid $seaz-head-arrow;
  border-radius: 1.2rem;
  padding: 1.6rem;
  cursor: pointer;

  &.active {
    background-color: $clr-ashy-grey;
    outline: 3px solid $clr-primary-and-accent;
    outline-offset: -0.3rem;
  }

  &__header {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  &__title {
    color: $seaz-text;
  }

  &__count {
    display: block;
    min-width: 2.3rem;
    height: 2.2rem;
    font-size: 1.2rem;
    font-weight: 500;
    letter-spacing: 0.01em;
    text-align: center;
    line-height: 2.3rem;
    color: $light-color;
    padding: 0 0.2rem;
    box-sizing: border-box;
    border-radius: 50%;
  }

  &__price {
    font-size: 2rem;
    font-weight: 500;
    line-height: 1.4;
    margin-top: 0.8rem;
  }
}
