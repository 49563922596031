.fleet-activity-title {
  @include d-flex-space-between;
  align-items: center;
  text-transform: uppercase;
  margin-right: 1rem;

  &__count {
    @include d-align-items-center;
    font-size: 2rem;
    font-weight: 400;
    line-height: 120%;
    color: $seaz-edit-hover;

    &--icon {
      margin-right: 1rem;
    }
  }
}

.fleet-activity-filters {
  margin-top: 1.6rem;

  &__form {
    display: flex;
    align-items: flex-end;
  }

  &__label {
    line-height: 16px;
    color: $seaz-text;
    margin-bottom: 0.2rem;
  }

  .switch__btn-text {
    word-break: break-word;
  }

  @include tablet {
    margin-top: 1.2rem;

    &__form {
      flex-direction: column;
    }

    &__form-btn {
      width: 100%;
    }
  }
}

.fleet-activity-filters-period {
  width: 100%;
  max-width: 25.2rem;
  min-width: 25.2rem;
  margin-right: 2.6rem;

  @include tablet {
    min-width: 100%;
    margin: 0 0 1.2rem;
  }
}

.fleet-activity-filters-date {
  width: 100%;
  max-width: 24.4rem;
  min-width: 24.4rem;
  margin-right: 2.4rem;

  input {
    &:focus {
      border: none;
    }
  }

  @include tablet {
    min-width: 100%;
    margin: 0 0 1.2rem;
  }
}
