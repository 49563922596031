.systems-note-modal-form {
  margin-top: 3rem;

  app-seazone-text-area .wrapper-rounded .form-field--content {
    height: 8rem;
  }
}

.systems-note-modal-form-footer {
  @include footer-with-border-left-btn(0, 1rem 0 0);
  border: none;

  &__btn {
    @include indent-not-last-of-type(0 1.2rem 0 0);
  }
}
