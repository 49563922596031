.cards {
  &__navigation {
    display: flex;
    overflow: auto;
    max-width: 100%;
    padding: 2rem 0;
    margin: 0 4rem;

    &::-webkit-scrollbar {
      display: none;
    }

    &_wrapper {
      width: 980px;
      position: relative;
    }

    &_list {
      display: flex;
    }

    app-scroll-controls .controls-container {
      position: static;

      button {
        height: 5.2rem;
        width: 2.4rem;
        display: inline-block;
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        background-color: white;
        border-radius: 0;
        z-index: 1;
        @include mobile {
          height: 3.6rem;
        }

        @media screen and (min-width: 480px) {
          &:hover,
          &:active {
            background-color: $clr-purple;

            .icon-arrow-left,
            .icon-arrow-rights {
              &::before {
                color: white;
              }
            }
          }
        }

        &:nth-child(1) {
          left: 0;

          .icon-arrow-left::before {
            transform: rotate(180deg);
          }
        }

        &:nth-child(2) {
          right: 0;
        }

        .icon-arrow-left,
        .icon-arrow-rights {
          &::before {
            content: '\e823';
            font-weight: bold;
            font-size: 1.2rem;
          }
        }
      }
    }
  }
}
