@import 'mixin';

.user-preference-list {
  position: fixed;
  height: 100%;
  width: 100%;
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 4.8rem 0;
  background-color: $seaz-bg;

  @include desktop-m {
    padding: 0;
  }

  &__wrapper {
    height: 100%;
    max-width: $mobile-screen;
    width: 100%;
    border-radius: 1.6rem;
    overflow: auto;
    @include desktop-m {
      border-radius: 0;
    }

    & app-user-preference-list {
      @include tablet {
        height: 100%;
      }
      & .seaz__btn_rounded {
        width: 100%;
      }
    }
  }
}

.user-preference-list__auth {
  display: flex;
  padding: 7.2rem 1.6rem 2.4rem;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  flex: 1 0 0;
  align-self: stretch;
  background-image: url('/assets/img/preference-list/auth.webp');
  background-color: #002c3d;
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  @include tablet {
    padding: 2.4rem 1.6rem;
  }

  &__row {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  &__title {
    @include typography(4rem, 500, 5.8rem);
    color: $clr-white;
    text-align: center;
    animation: fadeInTitle ease 1s;
  }

  &__btn {
    width: 100%;
    @include typography(1.4rem, 500, 2rem);
    letter-spacing: 1.4px;
    padding: 1rem 2rem;
    background-color: $clr-white;
    border-radius: 0.4rem;
    text-align: center;
    text-transform: uppercase;
    color: $seaz-text;
  }

  &__form {
    max-width: 34.5rem;
    width: 100%;
    animation: fadeInForm ease 1s;
    @include tablet-md {
      max-width: none;
    }
    .form__row {
      margin-bottom: 1.6rem;
    }
    .form-field--label {
      visibility: hidden;
      height: 0;
    }
    .seazone__form-input {
      -webkit-text-security: disc;
    }
  }

  &__logo {
    @include d-flex-center-content;
    padding: 0.8rem 1.6rem;
    margin-top: 2.4rem;
    &-text {
      @include typography(1.2rem, 500, 1.44rem);
      white-space: nowrap;
      color: $clr-white;
    }
  }
}

.user-preference-list__form {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  flex: 1 1 auto;
  overflow: hidden;
  &__body {
    display: flex;
    flex-direction: column;
    flex: 1 1 auto;
    overflow: hidden;
    .seazone__text-area {
      @include typography(1.4rem, 400, normal);
      @include custom-scroll-bar;
    }

    .form-field--content-suffix:not(:empty) {
      margin-left: 0;
    }
  }
  &-title {
    font-family: Roboto, sans-serif;
    font-size: 1.6rem;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    color: $seaz-text;
  }
  &__subtitle {
    display: flex;
    align-items: center;
    @include typography(1.6rem, 500, normal);
    color: $seaz-text;
    margin-bottom: 1.6rem;
    &-icon {
      object-fit: cover;
      &-wrapper {
        position: relative;
        margin-left: 0.8rem;
        width: 1.6rem;
        height: 1.6rem;
      }
    }
  }
  &-notes {
    display: inline-flex;
    font-family: Roboto, sans-serif;
    font-size: 1.4rem;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    color: $seaz-text;
    align-items: center;
  }
  & .form-field__area .form-field--label {
    padding: 0;
  }

  &__breakfast-drink {
    position: relative;
    display: flex;
    flex-direction: column;
    flex: 1 1 auto;
    background: url('/assets/img/preference-list/drinks.webp') no-repeat;
    background-size: cover;
    &::before {
      content: '';
      position: absolute;
      width: 100%;
      height: 100%;
      background: rgba(255, 255, 255, 0.74);
      backdrop-filter: blur(0.5px);
    }
  }

  &__notes {
    flex: 1 1 auto;
    app-seazone-text-area,
    app-seazone-text-area .wrapper-rounded .form-field--content {
      height: 100%;
    }
    @include tablet {
    }
  }
}

@keyframes pulse {
  0% {
    opacity: 0;
  }
  50% {
    opacity: 0.1;
  }
  100% {
    opacity: 0;
  }
}

.user-preference-list__section {
  display: flex;
  flex-direction: column;
  height: 100%;
  position: relative;
  &:has(.user-preference-list__section__header) {
    .user-preference-list__section__container {
      padding-top: 0;
    }
  }
  &__wrapper {
    display: flex;
    flex-direction: column;
    background-color: $clr-white;
    height: 100%;
    overflow: hidden;
  }
  &__content {
    display: flex;
    flex: 1 1 auto;
    overflow: hidden;
    position: relative;
  }
  &__wrapper__footer {
    display: grid;
    grid-template-columns: 6.3rem 7.1rem;
    justify-content: center;
    align-items: center;
    grid-gap: 0.6rem;
    padding: 0.8rem 1.6rem 2.4rem;
    &-text {
      @include typography(1.2rem, 500, 1.44rem);
      color: $clr-primary-and-accent;
      white-space: nowrap;
    }
  }
  &__container {
    display: flex;
    flex-direction: column;
    flex: 1 1 75%;
    padding: 1.6rem;

    border-radius: 1.6rem 1.6rem 0 0;
    background-color: $clr-white;
    overflow: hidden;
  }
  &__header {
    flex: 1 1 25%;
    position: relative;
    &::after {
      content: '';
      position: absolute;
      width: 100%;
      bottom: -1px;
      border-radius: 1.6rem 1.6rem 0 0;
      right: 0;
      left: 0;
      height: 1.3rem;
      background-color: $clr-white;
    }
    &__img__container {
      position: relative;
      @include d-flex-column-all-height;
      &.loaded {
        &::after {
          animation: none;
          content: none;
        }
      }
      &::after {
        content: '';
        position: absolute;
        inset: 0;
        opacity: 0;
        animation: pulse 2.5s infinite;
        background-color: $seaz-primary-and-accent;
      }
    }
    &__img {
      opacity: 0;
      object-fit: cover;
      transition: opacity 250ms ease-in-out;
      &.loaded {
        opacity: 1;
      }
    }
  }
  &--auto-scroll {
    padding-right: 1rem;
    flex: 1 1 auto;
    overflow: auto;
    @include custom-scroll-bar;
  }
}

.form__buttons {
  display: grid;
  margin-top: 1.6rem;
  grid-gap: 0 1.2rem;
  grid-template-columns: 1fr 1fr;
  justify-content: end;
}

.opening-text {
  &__container {
    @include d-flex-column-all-height;
  }
  &__message-text {
    font-family: Roboto, sans-serif;
    @include typography(1.6rem, 400, normal);
    color: $seaz-text;
    white-space: pre-line;
  }
}

.boat-info {
  &__container {
    @include d-flex-column-space-between-all-height;
  }
  &__date-descriptions {
    display: grid;
    grid-template-columns: 1fr;
    grid-gap: 1.4rem;
  }
  &__description {
    padding: 1.6rem;
    border: 0.1rem solid $seaz-head-arrow;
    border-radius: 0.8rem;
  }
  &__description-box {
    font-family: Roboto, sans-serif;
    font-size: 1.6rem;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    color: $seaz-text;
  }
  &__description-title {
    display: flex;
    gap: 0.6rem;
    margin-bottom: 1.6rem;
  }
  &__description-date {
    margin-bottom: 0.9rem;
  }
  &__description-day {
    font-family: Roboto, sans-serif;
    font-size: 1.2rem;
    font-style: normal;
    font-weight: 400;
    line-height: 120%;
    color: $clr-grey;
  }
}
.boat-name {
  font-family: Roboto, sans-serif;
  font-size: 2rem;
  font-style: normal;
  font-weight: 500;
  line-height: 2.4rem;
  color: $seaz-text;
}
.boat-parameters {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-gap: 1.6rem;
  margin: 1.4rem 0;
  &__item {
    @include d-flex-center-content;
    white-space: nowrap;
    width: 100%;
    padding: 1.6rem;
    border: 0.1rem solid $seaz-head-arrow;
    border-radius: 0.8rem;
    font-family: Roboto, sans-serif;
    font-size: 1.4rem;
    font-style: normal;
    font-weight: 500;
    line-height: 1.4rem;
    color: $seaz-text;
    & svg {
      margin-left: 0.6rem;
    }
  }
}

.movement__container {
  & .form__rows {
    display: grid;
    grid-template-columns: 1fr 1fr;
    align-items: end;
    grid-template-areas:
      'date time '
      'flight-number  flight-number '
      'airport airport';
    grid-gap: 2.4rem 1.2rem;
    margin-bottom: 2.4rem;
    &_date {
      grid-area: date;
    }
    &_time {
      grid-area: time;
    }
    &_flight-number {
      grid-area: flight-number;
    }
    &_airport {
      grid-area: airport;
    }
    @include tablet {
    }
  }
  & .form__row {
    margin-bottom: 0;
  }
  & .seazone-radio-group__wrapper {
    font-family: Roboto, sans-serif;
    font-size: 1.4rem;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    color: $seaz-text;
  }
  & .seazone-radio-group__label {
    width: auto;
  }
  & .seaz-text-area {
    @include tablet {
      margin-top: 0;
    }
  }
}

.crew-interaction__container {
  & .seazone-radio-group__wrapper {
    @include row-reverse-flex-end;
  }
  & .seazone-radio-group__items--column app-seazone-radio-button:not(:last-child) {
    margin-bottom: 0;
  }
}

.preference-notes__container {
  & .wrapper-rounded {
    & .form-field--label {
      padding-top: 0;
    }
  }
}

.like-with-notes__container {
  & .form__row {
    margin-bottom: 0;
  }
  & .seazone-radio-group__wrapper {
    @include row-reverse-flex-end;
  }
  & .seaz-text-area {
    margin-top: 0.4rem;
  }
  & .wrapper-rounded {
    & .form-field--label {
      padding-top: 0;
    }
  }
}

.meal-times__container {
  & .user-preference-list__serving-chips__item__checkbox {
    @include tablet {
      justify-content: flex-start;
    }
  }
}

.cuisines__container {
}

.breakfast__container {
  & .user-preference-list__form-title {
    padding-bottom: 1.6rem;
  }
}

.user-preference-list__breakfast-chips {
  display: grid;
  grid-template-columns: 1fr;
  gap: 1.6rem;
  &--item {
    position: relative;
    .chips-item {
      display: flex;
      padding: 0.2rem;
      border: 0.2rem solid transparent;
      border-radius: 0.8rem;
      cursor: pointer;
      &.selected {
        border-color: $clr-primary-and-accent;
        .user-preference-list__breakfast-chips__text-container {
          background-color: $clr-primary-and-accent;
          color: $clr-white;
        }
      }
    }

    &.disabled {
      .chips-item.selected {
        border-color: $seaz-bg;
      }
      .user-preference-list__breakfast-chips__text-container {
        @include disable-layer;
      }
    }
  }
  &--item-container {
    position: relative;
    height: 8.025rem;
    width: 100%;
    display: flex;
    align-items: flex-end;
    padding: 0.8rem;
  }
  &--item__img {
    border-radius: 0.4rem;
    object-fit: cover;
  }
  &__text-container {
    position: relative;
    z-index: 1;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    padding: 0.6rem 0.8rem;
    background-color: $clr-white;
    border-radius: 0.6rem;
    color: $clr-primary-and-accent;
    width: 100%;
    text-align: center;
  }
  &__text-title {
    @include typography(1.2rem, 500, 1.4rem);
    letter-spacing: 0.5px;
    text-transform: uppercase;
    margin-bottom: 0.2rem;
  }
  &__text-subtitle {
    @include typography(1rem, 400, 1.2rem);
  }
}

.bread__container {
  .form__row {
    margin-bottom: 1.6rem;
  }
}

.bread__container {
  .form__row {
    margin-bottom: 1.6rem;
  }
  & .wrapper-rounded {
    & .form-field--content {
      height: 21.5rem;
    }
    & .form-field--label {
      padding-top: 0;
    }
  }
  & .form-field__area {
    padding-top: 0;
  }
}

.meal {
  &-notes {
    font-family: Roboto, sans-serif;
    font-size: 1.4rem;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    color: $seaz-text;
  }
}

.fruits__container {
  & .meal-notes {
    padding-bottom: 1.6rem;
  }
}

.breakfast-drink__container {
  & .form__row {
    display: grid;
    grid-template-columns: 1fr;
    align-items: center;
    padding: 0 1.2rem;
    margin-bottom: 0;
  }
  & .user-preference-list__form__subtitle {
    margin-top: 1.6rem;
  }
}

.meal-style__container {
  & .form__row {
    margin-bottom: 0;
  }
  & .seazone-radio-group__wrapper {
    padding: 0.8rem 0;
    & label {
      font-family: Roboto, sans-serif;
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
      color: $seaz-text;
      width: auto;
    }
  }
}

.lunch-dinner__container {
  & .form__row {
    flex-direction: column;
    margin-bottom: 0;
    @include tablet {
      grid-template-columns: 1fr;
      grid-gap: 0;
    }
  }
  & .user-preference-list__header {
    margin-bottom: 0;
  }
  & .user-preference-list__form__subtitle {
    margin-top: 1.6rem;
  }
}
.lunch-dinner-second__container {
  .form__row {
    flex-direction: column;
    margin-bottom: 1.2rem;
    @include tablet {
      margin-bottom: 1.6rem;
    }
  }
}
.list-with-notes__container {
  .form__row {
    flex-direction: column;
    & .user-preference-list__form-title {
      padding-bottom: 1.6rem;
    }
  }
  .user-preference-list__header {
    margin-bottom: 1.6rem;
  }
  .seazone-radio-group__wrapper {
    padding-top: 0.5rem;
  }
}

.beverage__container {
  & .form__row {
    flex-direction: column;
  }
  .user-preference-list__form-info {
    display: flex;
    flex-direction: column;
    font-family: Roboto, sans-serif;
    font-size: 1.4rem;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    color: $seaz-text;
  }
}

.alcohol__container,
.beverage__container {
  & .user-preference-list__form {
    & .user-preference-list__form-title {
      margin-bottom: 0.8rem;
    }
    & .user-preference-list__form-notes {
      margin-bottom: 1.6rem;
    }
  }
}

.alcohol-advice__container {
  & .user-preference-list__form {
    & .form__row {
      flex-direction: column;
      margin-bottom: 1.6rem;
    }
    &_price-alcohol {
      & .user-preference-list__form-notes {
        margin-bottom: 0.8rem;
      }
      & .price-per-bottle {
        display: grid;
        grid-template-columns: 1fr 1fr;
        grid-gap: 0 1.2rem;
      }
    }
    &_choice-alcohol {
      & .user-preference-list__form-title {
        margin-bottom: 0.8rem;
      }
      & .user-preference-list__form-notes {
        margin-bottom: 1.6rem;
      }
    }
  }
}

.general-guidelines__container {
  &__text {
    flex: 1 auto;
  }
  .form__seaz-checkbox-control {
    display: flex;
    padding-bottom: 0.9rem;
    width: 100%;
    color: $seaz-text;
    @include typography(1.4rem, 400, normal);
  }
}

@keyframes fadeInTitle {
  0% {
    opacity: 0;
    transform: translate3d(0, -20%, 0);
  }
  100% {
    opacity: 1;
    transform: translate3d(0, 0, 0);
  }
}

@keyframes fadeInForm {
  0% {
    opacity: 0;
    transform: translate3d(0, 20%, 0);
  }
  100% {
    opacity: 1;
    transform: translate3d(0, 0, 0);
  }
}

.user-preference-list__header {
  display: grid;
  gap: 1.6rem;
  margin-bottom: 1.6rem;
  &-title {
    display: inline-flex;
    justify-content: space-between;
    align-items: stretch;
    @include typography(3rem, 500, 4.35rem);
    color: $clr-primary-and-accent;
    width: 100%;
    padding-bottom: 1.6rem;
  }
  &-title__counter {
    display: inline-flex;
    align-items: center;
    @include typography(2rem, 500, 2.4rem);
    font-size: 2rem;
    line-height: 2.4rem;
    border-radius: 0.4rem;
    color: $clr-white;
    background-color: $clr-primary-and-accent;
    margin: 0.075rem 1rem 0.075rem 1.6rem;
    padding: 0 1.6rem;
  }
  &-subtitle {
    @include typography(2rem, 500, 2.4rem);
    color: $seaz-text;
    margin-bottom: 1.6rem;
  }
  & .user-preference-list__header-title {
    padding-bottom: 0;
  }
}

.user-preference-list__header-section-title {
  display: inline-flex;
  align-items: center;
  font-size: 2.4rem;
  line-height: 2.78rem;
  font-weight: 500;
  color: $seaz-text;
  &__icon {
    margin-left: 0.8rem;
    width: 2.4rem;
    height: 2.4rem;
  }
}

.user-preference-list__header-section-description {
  display: inline-flex;
  font-size: 1.4rem;
  line-height: normal;
  font-weight: 400;
  color: $seaz-text;
  padding-top: 0;
  &__icon {
    margin-right: 0.8rem;
  }
  &--charter-info {
    padding-bottom: 1.6rem;
  }
}

.user-preference-list__header-types {
  display: flex;
  background-color: $clr-ashy-grey;
  border-radius: 0.4rem;
  overflow: hidden;

  &__item {
    flex: 1 auto;
    text-align: center;
    padding: 1rem 2rem;
    font-size: 1.4rem;
    line-height: 1.4rem;
    font-weight: 500;
    color: $seaz-text;
    border-radius: 0.4rem;
    &.active {
      background-color: $clr-green;
      color: $clr-white;
    }
  }
}

.user-preference-list__header-boat {
  display: inline-flex;

  &__logo {
    width: 3rem;
    height: 3rem;
    margin-right: 0.8rem;
    position: relative;
    &__img {
      object-fit: contain;
      border-radius: 0.4rem;
    }
  }

  &__info {
    font-size: 1.4rem;
    font-weight: 500;
    line-height: 1.4rem;
    color: $seaz-text;
    text-transform: uppercase;
    align-self: center;
  }
}

.user-preference-list__chips {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 1.2rem;
  &__wrapper {
    display: flex;
    flex-direction: column;
    width: 100%;
  }
  &--item {
    .chips-item {
      display: flex;
      padding: 1.2rem 1rem 1.2rem;
      background-color: $clr-ashy-grey;
      border-radius: 1.2rem;
      cursor: pointer;
      position: relative;
      overflow: hidden;
      .user-preference-list__chips--item {
        &-text {
          font-size: 1.4rem;
          font-weight: 500;
          line-height: 1.4rem;
          color: $clr-primary-and-accent;
        }
        &-icon {
          margin-right: 0.8rem;
          display: flex;
          width: 1.6rem;
          height: 1.6rem;
          overflow: hidden;
          position: relative;
          img {
            object-fit: cover;
          }
          &--selected {
            visibility: hidden;
            width: 100%;
          }
        }
      }
    }

    .chips-item.selected {
      background-color: $clr-primary-and-accent;
      .user-preference-list__chips--item {
        &-text {
          color: $clr-white;
        }
        &-icon {
          &--default {
            visibility: hidden;
            width: 0;
          }
          &--selected {
            visibility: visible;
            width: 100%;
            object-fit: cover;
          }
        }
      }
    }
    &.disabled {
      .chips-item {
        @include disable-layer;
      }
    }
  }
}

.user-preference-list__serving-chips {
  display: grid;
  grid-template-columns: 1fr;
  gap: 1.2rem;
  width: 100%;
  &__wrapper {
    display: grid;
    gap: 1.2rem;
    grid-template-columns: 1fr 1fr;
  }
  &__item {
    position: relative;
    padding: 0.2rem;
    border: 0.2rem solid transparent;
    border-radius: 0.8rem;
    &--checked {
      border-color: $clr-primary-and-accent;
      .user-preference-list__serving-chips__item__title {
        color: $clr-white;
        background-color: $clr-primary-and-accent;
      }
      &.user-preference-list__serving-chips__item--disabled {
        border-color: $seaz-bg;
      }
    }
    &--disabled {
      .user-preference-list__serving-chips__item__title {
        @include disable-layer;
      }
    }
  }
  &__item__container {
    height: 10.9rem;
    width: 100%;
    position: relative;
    padding: 0.8rem;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  &__item__title {
    position: relative;
    z-index: 1;
    padding: 0.6rem 0.8rem;
    background-color: $clr-white;
    color: $clr-primary-and-accent;
    border-radius: 0.6rem;
    @include typography(1.2rem, 500, 1.4rem);
    text-transform: uppercase;
    width: 100%;
    text-align: center;
    overflow: hidden;
  }
  &__image {
    object-fit: cover;
    border-radius: 0.4rem;
  }
  &__item__time {
    display: flex;
    align-items: center;
    width: 100%;
    &--checked {
      .wrapper-rounded .form-field--content {
        border-color: $clr-primary-and-accent;
      }
    }
  }
}

.user-preference-list__events-chips {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 1.7rem 1.2rem;
  width: 100%;
  &__wrapper {
    display: flex;
    flex-direction: column;
  }
  &__item {
    display: grid;
    gap: 0.4rem;
    border: 0.2rem solid transparent;
    border-radius: 0.8rem;
    padding: 0.2rem;
    margin-bottom: 0.4rem;
    position: relative;
    &--checked {
      border-color: $clr-primary-and-accent;
      .user-preference-list__events-chips__item__title {
        color: $clr-white;
        background-color: $clr-primary-and-accent;
      }
      .user-preference-list__events-chips__item__title-icon {
        &--selected {
          visibility: visible;
          width: 100%;
          object-fit: cover;
        }
        &--default {
          visibility: hidden;
          width: 0;
          object-fit: cover;
        }
      }
    }
    &--checked {
      &.user-preference-list__events-chips__item--disabled {
        border-color: $seaz-bg;
      }
    }
    &--disabled {
      .user-preference-list__events-chips__item__title {
        @include disable-layer;
      }
    }
  }

  &__item__container {
    height: 10.9rem;
    width: 100%;
    position: relative;
    padding: 0.8rem;
    display: flex;
    align-items: flex-end;
  }
  &__image {
    object-fit: cover;
    border-radius: 0.4rem;
  }
  &__item__date {
    .wrapper-rounded .form-field--label {
      display: none;
    }
    &--checked {
      .wrapper-rounded .form-field--content {
        border-color: $clr-primary-and-accent;
      }
    }
  }

  &__item__title {
    position: relative;
    padding: 0.6rem 0.8rem;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    border-radius: 0.6rem;
    background-color: $clr-white;
    color: $clr-primary-and-accent;
    overflow: hidden;
    &-text {
      @include typography(1.2rem, 500, 1.4rem);
      display: flex;
      text-align: left;
      align-items: center;
      width: 100%;
      letter-spacing: 0.5px;
      text-transform: uppercase;
    }
    &-icon {
      width: 1.6rem;
      height: 1.6rem;
      margin-right: 0.4rem;
      position: relative;
      &--selected {
        visibility: hidden;
        width: 0;
      }
    }
  }
}

.user-preference-list__dynamic-form {
  display: grid;
  row-gap: 0.8rem;
  width: 100%;
  &__row {
    display: grid;
    column-gap: 1.2rem;
    grid-template-columns: 2fr 2fr 1fr auto;
    &--small {
      grid-template-columns: 2fr 1fr auto;
    }
    &:not(:first-child) {
      .form-field--label {
        visibility: hidden;
        height: 0;
      }
    }
    &:first-child {
      .user-preference-list__dynamic-form__col__remove-btn {
        padding-bottom: 0;
      }
    }
  }
  &__col {
    display: flex;
    padding-bottom: 1.6rem;
    align-items: center;
  }
  &__col__remove {
    &--hidden {
      visibility: hidden;
    }
  }
  &__remove-btn {
    display: flex;
  }
  &__add-line {
    display: flex;
    justify-content: flex-end;
    &-btn {
      display: flex;
      align-items: center;
      color: $clr-primary-and-accent;
      @include typography(1.4rem, 500, 2rem);
      letter-spacing: 1.4px;
      text-transform: uppercase;
      background-color: transparent;
      &__icon {
        margin-right: 0.8rem;
      }
    }
  }
}

.great-job {
  &__container {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    height: 100%;
    text-align: center;
  }
  &__title {
    @include typography(4rem, 500, 5.8rem);
    color: $clr-primary-and-accent;
  }
  &__subtitle {
    @include typography(3rem, 500, 4.35rem);
    color: $clr-primary-and-accent;
  }
}
