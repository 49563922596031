.search-all-services-modal {
  margin-top: 3.2rem;

  &__list {
    &:not(:last-of-type) {
      margin-bottom: 1.6rem;
    }
  }

  &__title {
    display: flex;
    align-items: center;
    font-size: 1.6rem;
    font-weight: 500;
    line-height: 1.9rem;
  }

  &__title-icon {
    margin-right: 1.4rem;
    filter: invert(16%) sepia(22%) saturate(7028%) hue-rotate(244deg) brightness(103%) contrast(100%);
  }

  &__container {
    margin-top: 1.4rem;
  }

  &__chips-group {
    align-items: stretch;
  }

  .chips-container__icon-box {
    background-color: initial;
    padding: 0;
  }

  &__footer {
    display: flex;
    justify-content: flex-end;
    @include border-top;
    padding: 3rem 0 2rem 0;
    margin-top: 3rem;
  }

  &__btn--copy {
    margin-right: 1.2rem;
  }
}

.supplier-contacts-modal {
  .person-info-contacts__container {
    border-bottom: 0;
    padding-bottom: 0;
  }

  .toggle-select-buttons,
  .supplier-contacts-modal__contacts {
    margin-top: 3rem;
  }

  &__icon {
    margin-right: 1.8rem;
  }

  &__top-indent {
    @include border-top;
    padding-top: 1.8rem;
  }
}
