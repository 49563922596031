.boat-calendar {
  padding: 3.2rem;

  &__header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 1.6rem 0 3.2rem 0;

    &--actions {
      display: flex;
    }
  }

  &__title {
    font-size: 4rem;
    font-weight: bold;
    line-height: 1.4;
    color: $seaz-text;
  }

  &__header-events-all {
    display: block;
    margin-right: 1.2rem;
  }

  .switch__btn {
    line-height: 2.6rem;
  }

  @include tablet {
    &__header {
      flex-direction: column;
      align-items: flex-start;
      margin: 1.6rem 0 3.2rem 0;

      app-boat-calendar-header-actions {
        width: 100%;
      }

      &--actions {
        width: 100%;
        margin-top: 2rem;

        app-filter-calendar-event-select,
        app-create-calendar-event-select {
          width: 100%;
        }
      }
    }

    &__title {
      font-size: 3rem;
    }

    &__header-events-all {
      // margin: 0 0 1.6rem;
    }
  }

  .boat-calendar-card-event {
    .seaz-charter-card-badge {
      color: $light-color;
    }

    .custom {
      background-color: $seaz-custom;
    }

    .shipyard {
      background-color: $seaz-warning;
    }

    .delivery {
      background-color: $seaz-delivery;
    }

    .trip {
      background-color: $seaz-edit-hover;
    }

    &__button-edit {
      margin-right: 2.3rem;
    }
  }

  .seaz-charter__list_card_head_error {
    line-height: 2rem;
  }

  @include mobile {
    &__header {
      &--actions {
        flex-direction: column;
      }
    }

    &__header-events-all {
      margin: 0 0 1.6rem;
    }

    .boat-calendar-card-event {
      &__button-edit {
        margin-right: 1rem;
      }
    }
  }
}

.open-emails-modal__btn {
  display: flex;
  align-items: center;
  justify-content: center;
  max-width: 4.4rem;
  margin-right: 1.2rem;

  @include mobile {
    max-width: 100%;
    margin: 0 0 1.6rem;
  }

  .sidemenu__link-icon {
    margin: 0;
  }
}
