.profile {
  display: flex;
  align-items: flex-start;
  .container {
    width: 100%;
  }
  &__header-wrap {
    padding: 4.8rem 4rem 0;

    @include large {
      padding: 4.8rem 2rem 0;
    }

    @include tablet {
      padding: 1.6rem 1.6rem 0;
    }
  }
  &__wrap-img {
    margin-right: 2.4rem;
    width: 7.4rem;
    height: 7.4rem;
    .avatar__img {
      border-radius: 0.8rem;
    }
  }
  &__card {
    margin-bottom: 6.8rem;
  }
  &__col {
    display: flex;
    flex-grow: 1;
    flex-wrap: wrap;
    @include tablet-big {
      &:not(:first-child) {
        margin-top: 3rem;
      }
    }
    @include mobile {
      width: 100%;
      &:not(:first-child) {
        margin-top: 2rem;
      }
    }
    &_info {
      flex-grow: 0;
      flex-wrap: nowrap;
      min-width: 40rem;
      padding-right: 2.6rem;
      // flex-shrink: 0;

      @include laptop {
        padding-bottom: 2rem;
      }
      @include tablet {
        padding-bottom: 0;
      }

      @include mobile {
        min-width: 100%;
      }
    }
    &_button {
      flex-grow: 0;
      align-self: flex-end;
    }

    &-left {
      padding-right: 1.6rem;
      flex-shrink: 0;
      flex-basis: 33%;
      @include tablet {
        // padding-right: 1.8rem;
        flex-grow: 1;
        width: 50%;
        flex-basis: 50%;
      }
    }

    &-right {
      padding-left: 1.6rem;
      border-left: 0.1rem solid #e7e7eb;
      flex-basis: 33%;
      @include tablet {
        flex-grow: 1;
        width: 50%;
        padding-left: 0.8rem;
        flex-basis: 50%;
      }
    }
  }
  &__col--finance {
    display: grid;
    grid-template-columns: repeat(4, minmax(100px, 25%));
    @include tablet {
      grid-template-columns: 1fr 1fr;
    }
    &-item {
      border-left: 1px solid rgba(53, 31, 115, 0.2);
      padding: 0 1.6rem;
      @include tablet {
        padding: 0 0.8rem;
      }
    }
    &-item__statements {
      @include tablet {
        grid-column-start: span 2;
        border-left: none;
        padding: 1.6rem 0;
        border-bottom: 1px solid rgba(53, 31, 115, 0.2);
      }
    }
    &-item__balance {
      @include laptop {
        &:first-child {
          border-left: none;
          padding-left: 0;
        }
        &:last-of-type {
          padding-right: 0;
        }
      }
    }
  }
  &__title {
    font: bold 2.4rem $base-font;
    color: $clr-purple;
    text-transform: uppercase;
    margin-bottom: 1rem;
    word-break: break-all;
  }
  &__flag {
    display: inline-flex;
    margin-right: 0.8rem;
    img {
      width: 3.2rem;
      height: 1.8rem;
    }
  }
  &__point {
    margin: 0 0.5rem;
  }
  &__col-title {
    font-weight: 500;
    margin-bottom: 0.4rem;
    line-height: 1.4rem;
    span {
      white-space: nowrap;
    }
    &_box {
      display: flex;
      align-items: center;
    }
  }
  &__col-month {
    font-size: 1.2rem;
    color: $profile-month;
    width: 100%;
  }
  &__col-price {
    font: 500 2rem $base-font;
    margin-bottom: 0.2rem;
    @include mobile {
      font-size: 1.6rem;
    }
  }
  &__upload {
    font-size: 1.2rem;
    color: #828282;
    width: 100%;
    line-height: 1.44rem;
    @include mobile {
      margin-top: 0.5rem;
    }
  }
  &_expense {
    margin-bottom: 2rem;
  }

  &__form {
    &-title {
      margin-bottom: 2.6rem;
      &-wrap {
        position: relative;
      }
    }
    &-list {
      margin-bottom: 3.2rem;
    }
    &-checkbox-label-text {
      color: $clr-main;
    }
    &-checkbox {
      padding-top: 3.6rem;
      padding-left: 3.6rem;
    }
    &-documents {
      &-btn {
        width: 14.7rem;
      }
      &-row {
        &.form__row {
          &:last-child {
            margin-bottom: 2.4rem;
          }
        }
      }
    }
    &-btns {
      position: absolute;
      top: 0;
      right: 0;
    }
    &-text {
      margin-bottom: 1.6rem;
      font-size: 1.8rem;
      line-height: 2.1rem;
      font-weight: 500;
    }
  }

  &__tabs {
    margin: 5rem 0 3.5rem;
    @include mobile {
      margin: 3rem 0 1.5rem;
    }
    .tabs__item {
      max-width: 16.4rem;
      width: 100%;
    }
    .tabs__link {
      padding: 0 18% 1rem;

      &:hover {
        color: #351f6f;
        &:before {
          border-bottom: 0.3rem solid #351f6f;
        }
      }
      @include laptop {
        padding: 0 10% 1rem;
      }
      @include tablet {
        padding: 0 15% 1rem;
      }
      @include mobile {
        padding: 0 8% 1rem;
        font-size: 1.3rem;
        line-height: 2rem;
      }
    }
    .active-item .tabs__link {
      color: #351f6f;
      &:before {
        border-bottom: 0.3rem solid #351f6f;
      }
    }
  }
  & .form__document {
    border-bottom: 0;
  }

  &__date:not(:last-child) {
    margin-right: 2rem;
  }
  &__status {
    margin-bottom: 1.1rem;
    padding: 0;
    align-items: center;
    font-size: 1.6rem;
    line-height: 1.9rem;
    &-icon {
      width: 3rem;
      height: 3rem;
      margin-right: 0.8rem;
      font-size: 1.7rem;

      .icon-error {
        color: inherit;
      }
    }
  }

  &__phone-number {
    .phone-control-container {
      &.disabled,
      &.readonly {
        input:read-only,
        input:disabled {
          color: inherit;
        }
        .phone-code-container {
          color: inherit;
        }
      }
    }
  }
}

app-seazone-radio-item.no-show {
  .form__radio-label-text {
    cursor: default;
    color: $seaz-alarm;
    border: 1px solid $seaz-alarm;
  }
}
.seaz-crew__edit_box--general {
  padding-top: 4rem;
  @include tablet {
    width: 94%;
    margin: 0 auto;
  }
  @include mobile {
    padding-top: 2.4rem;
  }
}
.seaz-language {
  &__form {
    &_section {
      &:not(:last-of-type) {
        border-bottom: 1px solid $seaz-head-arrow;
        margin-bottom: 1.6rem;
      }
    }
    &_row {
      margin-bottom: 0.8rem;
      &:last-of-type {
        margin-bottom: 1.6rem;
      }
      .form-field--content.form-field--content-outline {
        border-radius: 0;
        .select-placeholder {
          color: $seaz-text;
        }
      }
    }
  }
}
.seaz-filters__form .form__group {
  .seaz-language__form_section {
    .form-field--content.form-field--content-outline {
      &.disabled {
        opacity: 1;
        .select-placeholder {
          color: $seaz-border;
        }
      }
    }
  }
}

.form__group--subgroup {
  margin-top: 1.6rem;
}

.seaz-cv {
  &__reference {
    display: flex;
    margin-bottom: 5rem;
    background-color: $clr-white;
    padding: 3rem;
    @include laptop {
      flex-wrap: wrap;
      padding: 2.4rem;
    }
    @include mobile {
      padding: 0;
      flex-wrap: nowrap;
      flex-direction: column;
    }
    & > div:last-child {
      border-right: none;
    }
    &_header {
      display: flex;
      align-items: center;
      padding-top: 4.8rem;
      padding-bottom: 2rem;
      .seaz-cv__experience_reference_verified {
        width: 2.4rem;
        height: 2.4rem;
        display: flex;
        align-items: center;
        justify-content: center;
        background-color: $seaz-success;
        cursor: pointer;
      }
    }
    &_title {
      color: $seaz-text;
      text-transform: uppercase;
      margin-right: 1.2rem;
    }
    &_letter {
      display: flex;
      flex-direction: column;
      width: 23%;
      border-right: 1px solid $seaz-border;
      @include laptop {
        width: 50%;
        margin-bottom: 2.4rem;
        padding-bottom: 2rem;
      }
      @include mobile {
        width: 100%;
        border-bottom: 1px solid $seaz-border;
        margin-bottom: 1.6rem;
        padding-bottom: 1.6rem;
        border-right: none;
      }
      &_title {
        color: $seaz-document-count;
        padding-bottom: 1.2rem;
        letter-spacing: 0.5px;
        font-size: 1rem;
        text-transform: uppercase;
      }
      &_position {
        color: $clr-primary-and-accent;
        font-size: 1.6rem;
        font-weight: 500;
        line-height: 1.6rem;
      }
    }
    &_info {
      padding: 0 0 0 1.6rem;
      box-sizing: border-box;
      width: 25%;
      border-right: 1px solid $seaz-border;
      @include laptop {
        border-right: none;
        width: 50%;
        margin-bottom: 2.4rem;
        padding-bottom: 2rem;
      }
      @include mobile {
        padding-left: 0;
        width: 100%;
        border-bottom: 1px solid $seaz-border;
        margin-bottom: 1.6rem;
        padding-bottom: 1.6rem;
        border-right: none;
      }
      &_title {
        color: $seaz-document-count;
        padding-bottom: 1.2rem;
        letter-spacing: 0.5px;
        font-size: 1rem;
        text-transform: uppercase;
        display: block;
      }
      &_contacts {
        word-break: break-all;
        padding-right: 0.8rem;
        color: $clr-primary-and-accent;
        font-size: 1.6rem;
        font-weight: 500;
        line-height: 1.6rem;
        &_box {
          display: flex;
          flex-direction: column;
        }
      }
    }
    &_docs {
      width: 26%;
      display: flex;
      padding: 0 1.6rem 0 1.6rem;
      flex-direction: column;
      border-right: 1px solid $seaz-border;

      @include laptop {
        padding: 0 0.8rem 0 0.8rem;
        width: 50%;
        align-items: flex-start;
      }
      @include mobile {
        width: 100%;
        border-bottom: 1px solid $seaz-border;
        margin-bottom: 1.6rem;
        padding-bottom: 1.6rem;
        border-right: none;
      }
      svg {
        width: 3.4rem;
        height: 4rem;
      }
      &_btn {
        &:not(:last-of-type) {
          margin-right: 2.2rem;
        }
        app-seazone-icon {
          justify-content: flex-start;
        }
      }
    }
    &_request {
      background-color: $clr-white;
      border: 1px dashed $dashed-border;
      display: flex;
      align-items: center;
      padding: 2.4rem 3.2rem;
      &_text {
        color: $clr-primary-and-accent;
      }
      @media screen and (max-width: 500px) {
        &_text {
          font-size: 1.4rem;
        }
      }
    }
    &_verified {
      &.green {
        .seaz-cv__reference_verified_rating {
          background-color: $clr-green;
          &.hidden {
            background-color: $seaz-border;
          }
        }
      }
      padding-left: 1.6rem;
      @include laptop {
        width: 50%;
      }
      @include mobile {
        width: 100%;
        padding-left: 0;
        padding-bottom: 1.6rem;
      }
      &_box {
        display: flex;
        align-items: center;
      }
      &_rating {
        padding: 1.2rem;
        border-radius: 8px;
        color: $clr-white;
        font-weight: 500;
        display: flex;
        align-items: center;
        width: 11.5rem;
        margin-right: 1.2rem;
        justify-content: center;
        cursor: pointer;
        @include laptop {
          width: 50%;
        }
        @include mobile {
          width: 10rem;
        }
        span {
          padding-left: 0.8rem;
          font-size: 1.8rem;
        }
      }
    }
  }
}
