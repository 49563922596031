.systems-search {
  width: 100%;
  max-width: 126.4rem;
  margin: 0 auto 16rem;

  .systems-menu__search {
    margin-bottom: 3.8rem;
  }

  .systems-tabs {
    max-width: 100%;
    margin: 0 0 3.8rem 0;
  }

  .systems-tab {
    max-width: initial;
  }

  @include tablet {
    margin: 0 auto 5rem;

    .systems-menu__search,
    .maintenance-list-filters {
      margin-bottom: 1.6rem;
    }

    .systems-tabs {
      margin: 0 0 1.6rem 0;
    }
  }
}

.systems-search-header {
  @include d-align-items-center;
  margin-bottom: 3.2rem;

  &__title {
    text-transform: uppercase;
    margin-left: 3.8rem;
  }

  @include tablet {
    margin-bottom: 1.6rem;

    &__title {
      text-transform: initial;
      margin-left: 1.6rem;
    }
  }
}
