.range-data-field {
  display: flex;
  align-items: center;

  &__item {
    display: flex;
    align-items: center;
    width: 100%;

    &:not(:last-of-type) {
      margin-right: 1.6rem;
    }
  }

  &__text {
    line-height: 1.6rem;
    color: $profile-month;
  }

  &__label {
    margin-right: 1.6rem;
  }

  &__units {
    margin-left: 1.6rem;
  }

  @include mobile {
    &__item {
      &:not(:last-of-type) {
        margin-right: 1rem;
      }
    }

    &__label {
      margin-right: 1rem;
    }

    &__units {
      margin-left: 1rem;
    }
  }
}
