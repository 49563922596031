.rating-form {
  &__item {
    margin-bottom: 3.3rem;
  }

  &__title {
    font-size: 1.6rem;
    font-weight: 500;
    line-height: 1.6rem;
    color: $seaz-about-text;
    margin-bottom: 1rem;
  }

  app-seazone-text-area .wrapper-rounded .form-field--content {
    height: 8rem;
  }

  .form-field__area .form-field--label {
    padding-top: 0;

    span {
      display: block;
      margin-right: 5.5rem;
    }
  }
}
