.system-details {
  margin-top: 1.6rem;

  @include tablet {
    display: none;

    &.active {
      display: block;
    }
  }
}

.system-details-content {
  margin-top: 1.6rem;
}

.systems-notes {
  font-size: 1.4rem;
  line-height: 1.6rem;

  &__title {
    color: $seaz-document-count;
  }

  &__text {
    color: $seaz-text;
    margin-top: 0.4rem;
  }
}

.system-details-brands {
  @include d-flex-space-between;
  align-items: center;
  font-size: $base-font-size;
  line-height: 1.6rem;
  margin-bottom: 1.2rem;

  &__title {
    color: $seaz-document-count;
    margin-right: 0.5rem;
  }

  &__text {
    @include d-align-items-center;
    color: $seaz-text;

    app-seazone-icon {
      margin-left: 0.5rem;
    }
  }
}

.systems-details-documents {
  margin-top: 1.6rem;
}

.systems-details-document {
  padding-bottom: 1.1rem;
  border-bottom: 1px solid $seaz-head-arrow;
  @include indent-not-last-of-type(0 0 1.2rem 0);

  &__title {
    font-size: 1.6rem;
    line-height: 1.9rem;
    color: $seaz-text;
    margin-bottom: 0.3rem;
  }
}

.systems-details-document-media {
  width: 12.8rem;
  height: 12.8rem;
  border-radius: 0.8rem;

  &__img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 0.8rem;
  }
}

.systems-details-document-docs {
  @include d-align-items-center;
  text-align: left;
  word-break: break-word;

  app-seazone-icon {
    margin-right: 0.7rem;
  }
}
