// WHen refactor style change it!!!

.boat-documents {
  .boat-navigation {
    @include d-align-items-center;
    width: 100%;
    border: none;
    padding: 0;
    margin: 0;

    &__link {
      display: block;
      width: 50%;
      font-size: 1.4rem;
      font-weight: 500;
      letter-spacing: 0.1em;
      text-transform: uppercase;
      line-height: 2rem;
      text-decoration: none;
      text-align: center;
      color: $clr-light-grey;
      padding: 1.8rem 1rem;
      border: 1px solid $clr-light-grey;
      box-sizing: border-box;

      &:first-of-type {
        border-top-left-radius: 0.8rem;
        border-bottom-left-radius: 0.8rem;
      }

      &:last-of-type {
        border-top-right-radius: 0.8rem;
        border-bottom-right-radius: 0.8rem;
      }

      &.seaz-yaht__navigation_item__active {
        color: $clr-primary-and-accent;
        border-color: $clr-primary-and-accent;
      }
    }

    @include laptop {
      &__link {
        padding: 1.4rem 0.5rem;
      }
    }

    @include tablet {
      &__link {
        font-size: 1.2rem;
      }
    }
  }
}
