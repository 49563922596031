.system-info {
  .maintenance-card {
    position: relative;
    border: none;
    box-shadow: initial;
    border-radius: 0;
    padding: 0;
  }

  //TODO: Change this!!!
  .systems-level-lines {
    height: 22rem;
  }

  .boat-maintenance-system-info-service {
    margin-top: 1.6rem;
  }

  @include laptop {
    max-width: 100%;
  }
}

.system-info-hide-btn {
  display: none;
  width: 100%;
  margin-top: 1.6rem;

  @include tablet {
    display: block;
  }
}
