.systems {
  padding: 4.8rem 4rem 7.8rem;
  background-color: $bg-pages;

  .list-view-btn {
    left: 0;
    top: 30rem;
    transform-origin: 16%;
    box-shadow: 0 16px 32px rgba(43, 28, 54, 0.12);

    app-seazone-icon {
      transform: rotate(-90deg);
      margin-left: 1rem;
    }

    &.list-view-btn-overview {
      display: none;
    }
  }

  .seaz__return_btn {
    width: 4rem;
    min-width: 4rem;
    height: 4rem;
    border-radius: 0.4rem;
  }

  @include large {
    padding: 4.8rem 2rem 7.8rem;
  }

  @include tablet {
    padding: 1.6rem 1.6rem 5rem;
  }

  @media (min-width: 370px) and (max-width: 1024px) {
    .list-view-btn.list-view-btn-overview {
      display: flex;
    }
  }
}

.systems-header {
  @include d-flex-space-between;

  @include laptop {
    flex-direction: column;
  }
}

.systems-header-nav {
  @include d-align-items-center;

  .document-top-indent {
    margin: 0 0 0 3.2rem;
  }

  .boat-document-bread-crumbs__link {
    &.active {
      color: $seaz-edit-hover;
    }
  }

  @include laptop {
    margin-bottom: 1.5rem;
  }

  @include tablet {
    .document-top-indent {
      margin: 0 0 0 1.2rem;
    }
  }
}

.systems-nav {
  @include d-flex-space-between;
  align-items: flex-end;

  @include laptop {
    flex-direction: column;
  }
}

.systems-nav-tabs {
  @include d-align-items-center;
  margin-bottom: 3.2rem;

  &__link {
    flex-grow: 1;
    font-size: $base-font-size;
    font-weight: 500;
    line-height: 2rem;
    letter-spacing: 0.1em;
    text-transform: uppercase;
    text-align: center;
    text-decoration: none;
    color: $seaz-document-count;
    padding: 1.6rem 1rem;
    border: 1px solid $seaz-document-count;

    &.seaz-yaht__navigation_item__active {
      color: $clr-primary-and-accent;
      border-color: $clr-primary-and-accent;
    }

    &:first-of-type {
      border-radius: 8px 0 0 8px;
    }

    &:last-of-type {
      border-radius: 0 8px 8px 0;
    }
  }

  @include tablet {
    margin-bottom: 1.2rem;

    &__link {
      border: none;
      border-bottom: 2px solid $seaz-document-count;

      &.seaz-yaht__navigation_item__active {
        border-color: $clr-primary-and-accent;
      }

      &:first-of-type {
        border-radius: 0;
      }

      &:last-of-type {
        border-radius: 0;
      }
    }
  }
}

.systems-container {
  display: flex;
  align-items: flex-start;
  margin-top: 4rem;

  @include laptop {
    flex-direction: column;
  }

  @include tablet {
    margin-top: 1.5rem;
  }
}

.maintenance-list-filters {
  margin-bottom: 4.3rem;

  &__container {
    @include d-flex-space-between;
    margin-bottom: 1.6rem;
  }
}

.maintenance-list-filters-actions {
  @include d-align-items-center;
  margin-right: 1rem;

  &__container {
    @include d-align-items-center;
  }

  &__btn {
    font-size: $base-font-size;
    font-weight: 500;
    line-height: 2rem;
    letter-spacing: 0.1em;
    text-transform: uppercase;
    color: $button-main;
    border: 1px solid $button-main;
    border-radius: 0.4rem;
    padding: 0.9rem 2rem;
    box-sizing: border-box;
  }

  .fleet-charter-filter-results {
    margin-left: 1.6rem;
  }

  @include laptop {
    flex-direction: column;

    .fleet-charter-filter-results {
      margin: 1.2rem 0 0;
    }
  }
}

.maintenance-list-filters-select {
  width: 18rem;
}

.maintenance-list-filters-select-option {
  padding: 0 2rem;

  .select-option {
    font-size: 1.3rem;
  }
}

.systems-items {
  flex-grow: 1;
  margin-left: 3.2rem;

  @include laptop {
    width: 100%;
    margin: 2.3rem 0 0;
  }
}
