.app-boat-maintenance-checklist-table-item {
  @include indent-not-last-of-child(0 0 1.2rem 0);
}

.maintenance-checklist-table-item {
  @include d-align-items-center;
  background-color: $light-color;
  border: 1px solid $seaz-head-arrow;
  border-radius: 12px;
  padding: 1.2rem;
  gap: 1.2rem;
  cursor: pointer;

  &__col-group {
    display: flex;
    align-items: flex-start;
    gap: 1rem 1.2rem;

    &--col-1 {
      width: 60%;
    }

    &--col-2 {
      width: 40%;
    }

    &--name {
      flex-wrap: wrap;
    }
  }

  .col-name {
    width: 30%;
  }

  .col-dates,
  .col-edit-by {
    width: 20%;
  }

  .col-status {
    width: 25%;

    app-seazone-icon {
      justify-content: flex-start;
    }
  }

  &__title {
    font-size: 1.2rem;
    font-weight: 400;
    line-height: 120%;
    color: $profile-month;
    text-transform: uppercase;
    margin-bottom: 0.4rem;

    &--name {
      text-transform: unset;
      margin-bottom: 0.8rem;
    }
  }

  &__icon {
    margin-bottom: 0.4rem;

    &--not-checked {
      display: none;
    }
  }

  &__text {
    font-size: 1.4rem;
    font-weight: 400;
    line-height: 1.6rem;
    color: $seaz-text;
    word-break: break-word;

    &--name {
      font-size: 1.6rem;
      font-weight: 500;
      line-height: 1.9rem;
    }

    &--edit-by {
      @include multiline-truncation(1);
    }
  }

  &__arrow {
    app-seazone-icon {
      transform: rotateZ(180deg);
    }
  }

  @include laptop {
    flex-direction: column;
    align-items: flex-start;

    &__col-group {
      justify-content: space-between;
      width: 100%;

      &--col-1,
      &--col-2 {
        width: 100%;
      }

      &--col-2 {
        border-top: 1px solid $seaz-bg;
        padding-top: 1.3rem;
      }
    }

    &__title {
      display: none;
    }

    .col-hidden {
      display: none;
    }

    .col-name,
    .col-dates {
      width: auto;
    }

    .col-status {
      width: 33.3%;
    }

    .col-edit {
      @include d-align-items-center;
      .maintenance-checklist-table-item__title {
        display: block;
        text-transform: unset;
        margin: 0 0.8rem 0 0;

        &:after {
          content: ':';
        }
      }

      .maintenance-checklist-table-item__text {
        font-size: 1.2rem;
        font-weight: 400;
        line-height: 120%;
        color: $profile-month;
      }
    }

    &__icon {
      &--not-checked {
        display: block;
      }
    }

    &__arrow {
      display: none;
    }
  }
}

.plug-no-data-icon {
  app-seazone-icon {
    justify-content: flex-start;
  }
}
