.supplier-branches-modal {
  &__container {
    margin-top: 3rem;
  }

  &__footer {
    display: flex;
    justify-content: flex-end;
    margin-top: 3rem;
    padding-bottom: 2rem;
  }
}

.branches-modal-item {
  border: 0.2rem solid $clr-ashy-grey;
  box-sizing: border-box;
  border-radius: 0.8rem;
  padding: 1.6rem;
  margin-bottom: 1.2rem;

  &__header {
    @include d-flex-space-between;
    align-items: center;
    margin-bottom: 1.2rem;
  }

  &__title {
    display: block;
    font-size: 1.4rem;
    font-weight: 500;
    line-height: 1.6rem;
    color: $clr-primary-and-accent;
    transition: opacity 0.3s;

    &:hover {
      @include hover-opacity(0.6);
    }
  }

  &__contacts {
    display: flex;
    align-items: center;
    margin-bottom: 1.3rem;

    &--location {
      transition: opacity 0.3s;

      &:hover {
        @include hover-opacity(0.6);
      }
    }
  }

  &__icon {
    margin-right: 1rem;
  }

  &__content,
  &__link,
  &__text {
    font-size: 1.4rem;
    line-height: 1.6rem;
    color: $seaz-about-text;
    word-break: break-word;
  }

  &__link {
    transition: opacity 0.3s;

    &:hover {
      @include hover-opacity(0.6);
    }
  }
}

.supplier-branches-create-modal {
  margin-top: 3.8rem;
  color: $seaz-text;

  &__toggle {
    display: flex;
    align-items: center;
    margin-bottom: 2.5rem;
  }

  &__toggle-label {
    display: block;
    color: $seaz-text;
    margin-left: 0.8rem;
  }
}
