.boat-documents-folders {
  margin-top: 3.8rem;
}

.boat-documents-folders-search {
  @include d-align-items-center;

  .search-field-alternate {
    margin-right: 1.2rem;
  }

  &__btn {
    flex-shrink: 0;
    @include indent-not-last-of-type(0 1.2rem 0 0);
  }

  @include tablet {
    flex-direction: column;

    .search-field-alternate {
      margin-right: 0;
    }

    &__btn {
      width: 100%;
      margin-top: 1.2rem;
      @include indent-not-last-of-type(1.2rem 0 0 0);
    }
  }
}
