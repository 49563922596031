.boat-maintenance-tasks-action-panel {
  @include d-flex-space-between;
  align-items: center;
  position: relative;
  background-color: $clr-primary-and-accent;
  box-shadow: 0 4px 8px rgba(43, 28, 54, 0.24);
  padding: 1.6rem;
  font-family: $base-font;

  &__content {
    @include d-align-items-center;
  }

  &__text {
    font-size: $base-font-size;
    line-height: 1.6rem;
    color: $light-color;
    opacity: 0.7;
    margin-right: 1.2rem;
  }

  &__buttons {
    display: flex;
    &.disabled {
      opacity: 0.3;
    }
  }

  &__btn {
    @include indent-not-last-of-type(0 1.2rem 0 0);

    &:disabled {
      background-color: $clr-grey;
      cursor: inherit;
      border-color: $clr-grey;
      color: $light-color;

      &:hover {
        opacity: 1;
      }
    }

    &.seaz__btn_rounded--outline:disabled {
      opacity: 1;
    }
  }

  @include tablet {
    align-items: flex-end;

    &__content {
      flex-direction: column;
      align-items: flex-start;
    }

    &__text {
      margin: 0 0 0.4rem 0;
    }

    &__btn {
      @include indent-not-last-of-type(0 0.4rem 0 0);
    }

    &__btn-close {
      padding-bottom: 0.9rem;
    }
  }

  @include mobile {
    padding: 1.2rem;

    &__btn {
      padding: 1rem 0.8rem;
    }

    .seaz__btn_rounded--icon {
      margin-right: 0.5rem;
    }
  }
}
