.checklist-boat-header {
  @include tablet {
    .avatar {
      display: none;
    }
  }
}

.boat-maintenance-checklist-folders {
  @include mobile {
    margin-top: 2.4rem;
  }
}

.boat-maintenance-checklist-title-wrap {
  @include d-align-items-center;
  gap: 0 1.6rem;
  margin-bottom: 2.4rem;
}

.boat-maintenance-checklist-folders-header {
  @include d-flex-space-between;
  align-items: center;
  margin-bottom: 3.2rem;
  gap: 0 1.6rem;

  .boat-maintenance-checklist-title-wrap {
    margin-bottom: 0;
  }

  &__action {
    @include d-align-items-center;

    &--search {
      width: 30rem;
      margin-right: 1rem;
    }

    &--btn {
      flex-shrink: 0;
    }

    @include mobile {
      margin-bottom: 2.4rem;

      &--search {
        margin-right: 0;
      }

      &--btn {
        width: 100%;
      }
    }
  }

  .seaz__btn_rounded--with-icon {
    padding: 0.9rem 2rem;
  }

  @include laptop {
    &__action {
      &--search {
        width: 20rem;
      }
    }
  }

  @include tablet {
    flex-direction: column;
    align-items: flex-start;

    &__title {
      font-size: 3rem;
      font-weight: 500;
      text-transform: uppercase;
    }

    &__action {
      width: 100%;
      margin-top: 1.6rem;

      &--search {
        width: 100%;
      }
    }
  }

  @include mobile {
    &__action {
      width: 100%;
      margin-top: 2.4rem;
      flex-direction: column;

      &--search {
        width: 100%;
        margin-bottom: 2.4rem;
      }
    }
  }
}

.check-list-folder {
  @include box-grid(repeat(auto-fill, minmax(29.8rem, 1fr)), initial, initial, 2.4rem);

  @include tablet {
    grid-gap: 1rem;
  }
}

.check-list-folder-item {
  padding: 1.6rem;
  box-shadow: 0 2px 2px rgba(43, 28, 54, 0.16);
  border-radius: 1.6rem;
  cursor: pointer;
  text-align: center;

  &__name {
    font-size: 1.6rem;
    font-weight: 500;
    line-height: 1.9rem;
    color: $seaz-text;
    margin-bottom: 0.8rem;
  }

  &__text {
    color: $seaz-text;
    &--hiden {
      visibility: hidden;
    }
  }
}

.check-list-folder-box {
  @include d-flex-center-content;
  position: relative;
}

.check-list-folder-edit {
  position: absolute;
  top: 0;
  right: 0;
}

.check-list-folder-container {
  position: relative;
}

.check-list-folder-count {
  position: absolute;
  top: 54%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-size: 1.6rem;
  font-weight: 500;
  line-height: 1.9rem;
  color: $light-color;
}

.boat-maintenance-checklist-folder {
  &__title {
    text-transform: uppercase;
  }
}

.boat-maintenance-checklist-folder-header {
  @include d-flex-space-between;
  align-items: center;
  margin-bottom: 2.4rem;

  @include laptop {
    flex-direction: column;
    align-items: flex-start;
    gap: 2.4rem 0;
  }
}

.check-list-folder-action-add {
  @include d-align-items-center;
  gap: 1.2rem;

  @include laptop {
    width: 100%;

    .check-list-add-btn {
      width: 33.3%;
    }
  }

  @include tablet {
    flex-direction: column;
    align-items: flex-start;

    .check-list-add-btn {
      width: 100%;
    }
  }
}

.checklist-folder-switch {
  border: 1px solid $seaz-head-arrow;
  background-color: initial;
  padding: 0.1rem;

  .active-link {
    background-color: $clr-primary-and-accent;
    color: $light-color;
  }

  .switch__btn {
    text-transform: uppercase;
    padding: 0.9rem 0.8rem;
  }

  @include laptop {
    width: 100%;
    margin-bottom: 2.6rem;

    .switch__btn {
      text-align: center;
    }
  }
}

.maintenance-checklist-container {
  background-color: $light-color;
  box-shadow: 0 2px 2px rgba(43, 28, 54, 0.16);
  border-radius: 1.6rem;
  padding: 3.2rem;

  @include laptop {
    padding: 1.2rem;
  }
}

.boat-maintenance-checklists-search {
  width: 100%;
  margin: 0 0 2.4rem;
}

.boat-maintenance-checklists-result {
  font-size: 12px;
  font-weight: 400;
  line-height: 120%;
  color: $profile-month;
  margin: 0 0 2.4rem;

  @include tablet {
    margin: 1.2rem 0;
  }
}

.boat-maintenance-checklist-templates-table {
  .maintenance-checklist-table-item {
    .maintenance-checklist-table-item__col-group--col-1 {
      width: 100%;
    }
  }
}

.checklist-form-modal-actions {
  @include d-align-items-center;
  flex-wrap: wrap;
  gap: 1.2rem;
}

.maintenance-check-list-form-modal {
  &__title {
    margin-bottom: 3rem;
  }

  @media screen and (max-width: 650px) {
    .form-field--wrapper__row .form-field--label {
      max-width: 12rem;
    }
  }
}

.maintenance-check-list-form-modal-radio-group {
  app-seazone-radio-group,
  .seazone-radio-group__wrapper,
  .seazone-radio-group__items,
  .seazone-radio-button__input--label,
  .seazone-radio-button__wrapper {
    width: 100%;
  }

  .seazone-radio-button__wrapper {
    padding: 0 !important;
  }

  .seazone-radio-group__label {
    max-width: unset;
    width: auto;
    margin-right: 0;
    padding: 0;
  }

  .seazone-radio-button__input--label {
    align-items: flex-start !important;

    @include mobile {
      flex-direction: column;
    }
  }

  .seazone-radio-group__items--column app-seazone-radio-button:not(:last-child) {
    margin-bottom: 0;
  }

  .seazone-radio-button-container,
  .radio-button-text {
    margin: 1rem 3.4rem 0 0;
  }

  .radio-button-text {
    display: block;
    min-width: 8.5rem;
  }

  .radio-button-field-wrap {
    width: 100%;

    app-seazone-input {
      margin-top: 1.2rem;
    }

    @include mobile {
      app-seazone-input {
        margin-top: 0;
      }

      app-seazone-select {
        margin-bottom: 1.2rem;
      }
    }
  }
}

.maintenance-check-list-form-modal-footer {
  @include d-flex-space-between;
  align-items: center;

  &__group {
    width: 100%;
    @include d-align-items-center;
    justify-content: flex-end;
  }

  &__btn {
    @include indent-not-last-of-child(0 1.2rem 0 0);
  }
}

.maintenance-check-list-form-modal-footer-not-group {
  @include footer-with-border-left-btn(0, 4.6rem 0 0 0);
  border: none;
  gap: 1.2rem;
}
