.card {
  background-color: white;
  box-shadow: 0 0.2rem 0.5rem rgba(0, 0, 0, 0.04);
  border-radius: 1.6rem;
  padding: 3.1rem 1.6rem 2.6rem;
  border: 0.05rem solid #e7e7eb;
  cursor: pointer;
  transition: all 0.4s;
  -webkit-tap-highlight-color: transparent;
  @include mobile {
    padding: 1.2rem;
  }
  &__status {
    margin-right: 0.8rem;
  }
  &__row {
    position: relative;
    display: flex;
    justify-content: space-between;
    width: 100%;
    flex-wrap: wrap;
    padding-top: 0.4rem;
    &-left {
      flex-grow: 1;
    }
    &-right {
      margin-left: 2.4rem;
      @media screen and (max-width: 1100px) {
        margin-left: 0;
      }
      @include laptop {
        width: 100%;
        margin: 0;
      }
    }
  }

  &__textarea {
    margin: 1.8rem 0 1.6rem;
    height: 4.8rem;
  }

  &__textarea + &__col_btns {
    margin-left: auto;
    min-width: 16.8rem;
    @include mobile {
      min-width: 100%;
    }
    .card__btn {
      margin-top: 0;
    }
  }

  &__block-icon {
    width: 1.3rem;
    height: 1.3rem;
    border-radius: 50%;
    background: #e7e7eb;
  }

  &__col_date {
    display: flex;
    flex-wrap: wrap;
    > .card__block {
      flex-grow: 1;
    }
    @include mobile {
      flex-direction: column;
    }
    .card__block {
      margin-top: 0.8rem;
      padding: 0 0.2rem;
      @media screen and (max-width: 1100px) {
        display: flex;
        justify-content: flex-end;
        flex-direction: column;
        width: 50%;
        margin-bottom: 2rem;
      }
      @include mobile {
        display: flex;
        align-items: center;
      }
    }
    .card__head {
      margin-bottom: 0.5rem;
      @include mobile {
        margin-bottom: 0;
        display: flex;
        align-items: center;
        justify-content: center;
        width: 1.7rem;
        height: 1.7rem;
        margin-right: 0.6rem;
      }
      &-icon {
        display: none;
        @include mobile {
          display: block;
          font-size: 1.4rem;
          opacity: 1;
        }
      }
    }
    .card__text {
      font-size: 1.5rem;
      @include mobile {
        font-size: 1.2rem;
      }
    }
  }

  &__col-union {
    position: relative;
    display: flex;
    justify-content: space-between;
    min-width: 24rem;
    @include mobile {
      align-self: flex-start;
      .card__block:last-child .card__head {
        display: none;
      }
    }
    &:before {
      content: '';
      position: absolute;
      width: calc(100% - 21rem);
      z-index: 1;
      left: 50%;
      bottom: 1rem;
      transform: translateX(-50%);
      border-top: 0.1rem solid #4d4e67;
    }
    .card__block {
      position: relative;
      &-icon {
        display: flex;
        align-items: center;
        justify-content: center;
        position: absolute;
        bottom: 0.4rem;
        i {
          font-size: 0.6rem;
          line-height: 1;
        }
      }
    }
    .card__text {
      position: relative;
      z-index: 1;
    }
    .card__block:first-child {
      padding-right: 2rem;
      width: 11.5rem;
      .card__block-icon {
        right: 0.5rem;
      }
    }
    .card__block:last-child {
      padding-left: 2rem;
      width: 11.5rem;
      .card__block-icon {
        left: 0.5rem;
      }
    }
  }

  &__col_btns {
    display: flex;
    flex-direction: column;
    min-width: 16.8rem;
    @media screen and (max-width: 1100px) {
      flex-direction: row;
      min-width: auto;
      align-items: flex-start;

      .card__btn {
        width: 100%;
        &:nth-last-of-type(2) {
          margin: 0 0.8rem;
        }
      }
    }
    @include tablet-small {
      flex-direction: column;
      .card__btn {
        &:nth-last-of-type(2) {
          margin: 0;
        }
      }
    }
    .card__btn {
      padding: 0.8rem;
      display: flex;
      align-items: center;
      justify-content: center;
      max-height: 3.6rem;
      white-space: nowrap;

      &--icon {
        margin-right: 1.2rem;
      }

      @include laptop {
        margin-top: 1.2rem;
      }
      @include mobile {
      }
      &:not(:last-child) {
        margin-bottom: 1rem;
        @include laptop {
          margin: 1.2rem 1rem 0rem 0;
        }
        @media screen and (max-width: 380px) {
          &:nth-child(2) {
            margin-right: 0;
          }
        }
      }
    }
  }

  &__col-wrap {
    display: flex;
  }
  &__notice {
    position: absolute;
    width: 100%;
    z-index: 2;
    top: -1.2rem;
    left: -0.7rem;
  }
  &__wrap {
    display: flex;
  }
  &__title {
    position: relative;
    font-size: 2.3rem;
    font-weight: bold;
    line-height: 2.5rem;
    margin-bottom: 0.9rem;
    @include mobile {
      font-size: 1.5rem;
      line-height: 1.6rem;
      margin-bottom: 0.1rem;
    }
    &-edit {
      display: inline-flex;
      position: absolute;
      right: 0;
      .icon-edit {
        color: #4d4e67;
        font-size: 1.7rem;
      }
    }
  }
  &__cash {
    font-size: 2.4rem;
    color: $clr-purple;
    @include mobile {
      font-size: 1.4rem;
    }
    &-currency {
      font-weight: bold;
      margin-right: 0.3rem;
    }
    &-num {
      font-weight: bold;
      margin-right: 0.6rem;
    }
    &-text {
      font-size: 1.4rem;
      color: $clr-main;
      @include mobile {
        font-size: 1rem;
      }
    }
  }
  &__upload {
    margin-bottom: 0.7rem;
    @include mobile {
      font-size: 1rem;
      line-height: 1.4rem;
      margin-bottom: 0.1rem;
    }
  }
  &__head {
    margin-bottom: 1.3rem;
    @include mobile {
      margin-right: 0.8rem;
    }
    &-icon {
      font-size: 1.8rem;
      margin-right: 0.5rem;
      opacity: 0.5;
      vertical-align: -0.3rem;
      @include mobile {
        margin-right: 0;
      }
    }
    span {
      font-size: 1.2rem;
      font-weight: 500;
      opacity: 0.5;
      @media screen and (max-width: 1100px) {
        font-weight: 400;
      }
    }
  }
  &__text {
    font-weight: bold;
    font-size: 1.6rem;
    @include mobile {
      font-size: 1.2rem;
      font-weight: 500;
    }
  }
  &:hover {
    box-shadow: 0 1rem 5rem rgba(0, 0, 0, 0.25);
  }
  &__btns {
    text-align: right;
  }
  &__btn {
    .icon-dollar {
      margin-right: 2.8rem;
    }
  }
  ///////////////////////---//
  &__btns {
    margin-top: 1.5rem;
  }
  &__team {
    margin-top: 1.5rem;
    @include mobile {
      margin-top: 0;
    }
  }
}
.crew-list {
  &__card {
    display: flex;
    padding-top: 1.5rem;
    @media screen and (max-width: 1100px) {
      flex-direction: column;
    }

    .card__wrap_head {
      display: flex;
    }
    .seaz-crew__card_upload_date {
      padding-top: 4px;
      @include tablet-small {
        padding-left: 0.8rem;
      }
    }
    .card__title,
    .seaz-crew__card_name {
      padding-left: 0.8rem;
    }
    .seaz-crew__card_name {
      @include tablet {
        padding-bottom: 1rem;
      }
    }
    .card__title {
      font-weight: 500;
      @include tablet {
        padding-bottom: 1rem;
      }
    }
    &_row {
      padding-left: 12.5rem;
      @media screen and (max-width: 1100px) {
        padding-left: 0;
      }
      &.desktop {
        padding-left: 0;
        padding-top: 2.9rem;
        @media screen and (max-width: 1100px) {
          display: none;
        }
      }
      &.mobile {
        display: none;
        @media screen and (max-width: 1100px) {
          display: flex;
        }
      }
    }
    &_info {
      width: 100%;
      position: relative;
      top: -3rem;
      @media screen and (max-width: 1100px) {
        top: 0;
      }
    }

    .seaz-crew__card_completion_title {
      @include tablet {
        padding-bottom: 0;
      }
    }
    .card__col_date {
      @media screen and (max-width: 1100px) {
        padding-top: 0;
      }
    }
    .card__wrap-img.seaz-crew__card_avatar {
      @include mobile {
        width: 7.6rem;
        height: 7.6rem;
      }
    }
    .card__block {
      .card__head {
        @include mobile {
          width: 100%;
          height: auto;
          justify-content: flex-start;
        }
        span {
          @include mobile {
            display: block;
          }
        }
      }
    }
    .card__col_date {
      @include mobile {
        flex-direction: row;
      }
      .card__block {
        @include mobile {
          align-items: flex-start;
        }
      }
    }
    .card__col_date .card__head-icon {
      @include mobile {
        display: none;
      }
    }
    .seaz-cv__badges_status {
      @include mobile {
        width: auto;
      }
    }
  }
}

.seazone-card {
  width: 100%;
  background: #ffffff;
  border-radius: 1.2rem;
  padding: 3.2rem;
  &--header {
    display: flex;
    flex-direction: row;
    &__title {
      flex: 1 auto;
      font-size: 2.4rem;
      line-height: 1.2;
      word-break: break-word;
      color: $seaz-mulberry;
    }
  }
  &--content {
    visibility: visible;
    opacity: 1;
    width: 100%;
    overflow: hidden;

    &.content--hidden {
      visibility: hidden;
      opacity: 0;
      height: 0;
    }
    &__wrapper {
      margin-top: 2.4rem;
    }
  }
  .btn-collapse {
    background-color: transparent;
    border-radius: 50%;
    .btn-collapse-icon {
      transition: transform 0.4s;
    }
    .collapsed {
      transform: rotate(180deg);
    }
  }
}

.seaz-system-notification {
  .seazone__toggle {
    margin: 1rem 0 0 0.1rem;
  }
}

.boat-card-view {
  border: 2px solid rgba(53, 31, 115, 0.2);
  box-shadow: 0 16px 32px rgba(43, 28, 54, 0.12);
  border-radius: 1.6rem;
  cursor: pointer;
}

.boat-card-view-content {
  display: grid;
  grid-template-columns: 9rem 1fr;
  grid-template-areas:
    'card-avatar card-header'
    'card-avatar card-info';
  padding: 2.4rem;
  grid-gap: 1.6rem 2.4rem;

  @include tablet {
    grid-template-areas:
      'card-avatar card-header'
      'card-info card-info';
    grid-gap: 1.6rem;
    padding: 1.6rem;
  }

  @include mobile {
    grid-template-columns: 7.3rem 1fr;
  }
}

.boat-card-view-avatar {
  grid-area: card-avatar;
  position: relative;
  width: 9rem;
  height: 9rem;
  border-radius: 1.6rem;

  &__img {
    display: block;
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 1.6rem;
  }

  .seaz-company__logo_boat {
    left: 50%;
    transform: translateX(-50%);
  }

  @include mobile {
    width: 7.3rem;
    height: 7.3rem;

    .seaz-company__logo_boat {
      top: 6rem;
    }
  }
}

.boat-card-view-header {
  grid-area: card-header;
  @include d-flex-space-between;
  align-items: center;
  border-bottom: 1px solid $seaz-bg;
  padding-bottom: 1.6rem;

  &__content {
    @include d-align-items-center;
  }

  @include tablet {
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    border-bottom: none;

    &__content {
      flex-direction: column;
      align-items: flex-start;
    }
  }
}

.boat-card-view-header-name-info {
  font-size: 2rem;
  font-weight: 700;
  line-height: 2.3rem;

  &__name {
    color: $clr-primary-and-accent;
  }

  &__point,
  &__boat-length {
    color: $seaz-border;
    text-transform: uppercase;
  }

  @include mobile {
    font-size: 1.6rem;
  }
}

.boat-card-view-brand-name {
  font-size: $base-font-size;
  line-height: 1.6rem;
  color: $seaz-document-count;
  background-color: $seaz-bg;
  border-radius: 10rem;
  padding: 0.4rem 1rem;
  margin-left: 1.2rem;

  @include tablet {
    margin: 0.8rem 0 0;
  }
}

.boat-card-view-header-position-info {
  font-size: 1.2rem;
  line-height: 1.4rem;
  color: $profile-month;

  &__time {
    font-weight: 500;
    color: $seaz-text;
    margin-left: 0.8rem;
  }

  @include tablet {
    margin: 0.8rem 0 0;
  }
}

.boat-card-view-info {
  grid-area: card-info;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-gap: 1.6rem 2.4rem;

  &__block {
    padding: 0 2rem 0 0;

    &:not(:last-of-type) {
      border-right: 1px solid $seaz-bg;
    }
  }

  &__title {
    font-size: $base-font-size;
    line-height: 1.6rem;
    color: $profile-month;
    margin-bottom: 0.6rem;
  }

  &__text {
    font-size: 1.6rem;
    font-weight: 500;
    line-height: 120%;
    color: $seaz-text;
    word-break: break-word;
  }

  @include tablet {
    display: block;
    border-top: 1px solid $seaz-bg;
    padding-top: 1.2rem;

    &__block {
      display: flex;
      padding: 0;

      &:not(:last-of-type) {
        border-right: none;
        margin-bottom: 1.5rem;
      }
    }

    &__title {
      min-width: 11.4rem;
    }
  }
}

.boat-card-view-info-block-location {
  &__container {
    @include d-flex-space-between;
    align-items: flex-start;
  }

  &__content {
    display: flex;
    align-items: flex-start;
    max-width: 27rem;
    .ais-error {
      font-size: 1.6rem;
      line-height: 1.9rem;
    }
  }

  app-seazone-icon {
    margin-right: 0.8rem;
  }

  @include tablet {
    display: block;
    border-bottom: 1px solid $seaz-bg;
    padding-bottom: 1.2rem;
  }
}

.boat-card-view-badge {
  flex-shrink: 0;
  font-size: 1.2rem;
  line-height: 1.4rem;
  color: $light-color;
  background-color: $seaz-primary-and-accent;
  border-radius: 10rem;
  padding: 0.4rem 0.8rem;
  margin-left: 0.4rem;
}

.boat-card-view-footer {
  @include d-flex-space-between;
  align-items: center;
  background-color: $seaz-bg;
  padding: 1.6rem 2.4rem;
  border-radius: 0 0 1.6rem 1.6rem;

  @include laptop {
    flex-direction: column;
    align-items: flex-start;
  }

  @include tablet {
    flex-direction: column;
    align-items: flex-start;
    padding: 1.6rem;
  }
}

.boat-card-view-footer-cash-box {
  display: grid;
  grid-template-columns: auto auto;
  grid-template-areas:
    'card-cash-title cash-upload'
    'card-cash card-cash';
  grid-gap: 0.4rem 2.8rem;

  .card-cash-title {
    grid-area: card-cash-title;
    font-size: $base-font-size;
    line-height: 1.6rem;
    color: $clr-light-grey;
  }

  .cash-upload {
    grid-area: cash-upload;
    font-size: 1.2rem;
    line-height: 120%;
    color: $clr-light-grey;
  }

  .card-cash {
    grid-area: card-cash;
    font-size: 2.4rem;
    font-weight: 500;
    line-height: 2.8rem;
    color: $clr-primary-and-accent;

    &__badge {
      margin-right: 0.8rem;
    }
  }

  .no-expenses {
    color: $seaz-text;
  }

  @include mobile {
    grid-template-columns: 1fr;
    grid-template-areas:
      'card-cash-title'
      'card-cash'
      'cash-upload';
    grid-gap: 0.4rem 0;

    .card-cash {
      margin-bottom: 0.8rem;
    }
  }
}

.boat-card-view-footer-action-box {
  @include d-align-items-center;

  &__btn {
    @include indent-not-last-of-type(0 1.2rem 0 0);
  }

  @include laptop {
    justify-content: flex-end;
    width: 100%;
    margin-top: 1.2rem;
  }

  @include mobile {
    flex-direction: column;

    &__btn {
      width: 100%;
      @include indent-not-last-of-type(0 0 1.2rem 0);
    }
  }
}
