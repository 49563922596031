.star-label {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 50px;
  height: 50px;
  cursor: pointer;

  &:hover,
  &:hover ~ label {
    svg {
      height: 46px;
      width: 46px;
      transition: 0.2s ease;
      filter: drop-shadow(3px 3px 7px rgba(0 0 0 / 0.1));
    }
  }

  &.disabled {
    pointer-events: none;
  }
}

.stars {
  display: flex;
  flex-direction: row-reverse;
  justify-content: flex-end;
  align-items: center;
  width: 300px;

  input {
    display: none;
  }
}

.stars input:checked {
  & + .s0,
  & + .s0 ~ label {
    svg {
      fill: #259912;
    }
  }

  & + .s1,
  & + .s1 ~ label {
    svg {
      fill: #9cc895;
    }
  }

  & + .s2,
  & + .s2 ~ label {
    svg {
      fill: #f7c948;
    }
  }

  & + .s3,
  & + .s3 ~ label {
    svg {
      fill: #f49345;
    }
  }

  & + .s4,
  & + .s4 ~ label {
    svg {
      fill: #d70000;
    }
  }
}

.rating-star-icon {
  svg {
    height: 40px;
    width: 40px;
    fill: #e0e0e0;
  }
}

.s0:hover,
.s0:hover ~ label {
  svg {
    fill: #259912 !important;
  }
}

.s1:hover,
.s1:hover ~ label {
  svg {
    fill: #9cc895 !important;
  }
}

.s2:hover,
.s2:hover ~ label {
  svg {
    fill: #f7c948 !important;
  }
}

.s3:hover,
.s3:hover ~ label {
  svg {
    fill: #f49345 !important;
  }
}

.s4:hover,
.s4:hover ~ label {
  svg {
    fill: #d70000 !important;
  }
}

.star-rating {
  display: flex;
  justify-content: center;
  flex-direction: row;
  align-items: center;
  padding: 0 5px;
  height: 2.4rem;
  &.rounded {
    border-radius: 2.4rem;
    &.red {
      background-color: #fbe7e8;
    }
    &.orange {
      background-color: #f49342;
    }
    &.yellow {
      background-color: #f7c948;
    }
    &.grass {
      background-color: #9cc895;
    }
    &.dark-green {
      background-color: #3aae27;
    }
  }

  &__rate {
    font-family: 'Roboto', 'sans-serif';
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 19px;
    color: #010202;
    padding-left: 6px;
    &.history {
      font-size: 12px;
      line-height: 16px;
      color: white;
      padding-left: 2px;
      &.red {
        color: #ff5f5f;
      }
    }
  }
}
