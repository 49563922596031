.ais-position-modal {
  &__title {
    margin-bottom: 1.2rem;
  }

  &__info-text {
    font-size: $base-font-size;
    line-height: 1.6rem;
    color: $profile-month;
    margin-top: 1.2rem;
  }
}

.ais-position-modal-info-label {
  .info-label {
    border-radius: 0.4rem;
  }
}

.ais-position-modal-form {
  margin-top: 3rem;

  .form-field--wrapper__row .form-field--label {
    max-width: 16rem;
  }
}

.ais-position-modal-form-footer {
  @include footer-with-border-left-btn(0 0 1rem 0, 3rem 0 0);
  border: none;

  &__btn {
    @include indent-not-last-of-type(0 1.2rem 0 0);
  }
}
