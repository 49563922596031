app-calendar-event-list {
  height: 100%;
}

.fleet-calendar-details-modal {
  height: 100%;

  app-no-data {
    height: 70vh;

    .boat-home__content {
      height: 100%;
    }
  }

  &__wrap app-overlay {
    max-width: 50.4rem;
  }

  .seaz-dashboard-charters-info__content {
    padding: 2.4rem 2rem 1.4rem;
    max-height: 100vh;
  }

  .fleet-calendar-details-modal__date {
    font-size: 2rem;
    font-weight: 500;
    line-height: 120%;
    color: $clr-primary-and-accent;
    padding: 2.4rem 2rem 3.4rem;
  }

  .seaz-dashboard-charters-info {
    width: 100%;
    margin: 0 1.6rem 0 0;
  }

  .charter-calendar-mode {
    border-bottom: 0;
  }

  .switch {
    width: 93%;
  }

  .light-bg-border-corners {
    border-radius: 0;
    border: none;
    box-shadow: none;
  }

  .seaz-dashboard-charters-info__warning {
    border-radius: 0;
  }

  .seaz-charter-card-badge {
    margin: 0 0.8rem 0 0;

    &.custom {
      background-color: $seaz-custom;
    }

    &.shipyard {
      background-color: $seaz-warning;
    }

    &.delivery {
      background-color: $seaz-delivery;
    }

    &.trip {
      background-color: $seaz-edit-hover;
    }
    &.charter-type-upcoming {
      color: $waiting-dark;
    }
  }

  .boat-calendar-card-event__button-edit {
    margin-right: 2.3rem;
  }

  @include tablet {
    .seaz-dashboard-charters-info {
      width: 100%;
      margin: 0;
    }

    .switch {
      width: 80%;
    }
  }
}
