.fleet-card-modal {
  display: block;
  @include indent-not-last-of-type(0 0 1.2rem 0);
}

.fleet-info-modal-search {
  margin-bottom: 1.2rem;
}

.fleet-info-modal-event-selection {
  margin-bottom: 4.6rem;

  @include mobile {
    margin-bottom: 1.6rem;
  }
}

.info-card {
  @include d-flex-space-between;
  align-items: flex-start;
  padding: 1.6rem;
  border: 2px solid $clr-ashy-grey;
  box-sizing: border-box;
  border-radius: 0.8rem;
  background-color: $light-color;

  &__count {
    @include d-align-items-center;
    font-size: 1.6rem;
    font-weight: 500;
    line-height: 120%;
    color: $seaz-text;
  }

  .shevron-icon {
    margin-left: 1.2rem;
  }
}

.fleet-boat-selection-list {
  max-height: 40.8rem;
  overflow-y: auto;
  @include custom-scroll-bar;
  padding-right: 1rem;
}

.app-fleet-boat-selection-item {
  display: block;
  @include indent-not-last-of-child(0 0 1.2rem 0);
}

.info-card-invitation {
  @media screen and (max-width: 560px) {
    flex-direction: column;
  }
}

.info-card-wrap {
  display: flex;
}

.info-card-avatar-boat {
  width: 8rem;
  height: 8rem;
  border-radius: 0.8rem;
  flex-shrink: 0;

  &__img {
    border-radius: 0.8rem;
  }
}

.info-card-avatar-user {
  width: 3.6rem;
  min-width: 3.6rem;
  height: 3.6rem;
  border-radius: 50%;

  &__img {
    border-radius: 50%;
  }
}

.info-card-avatar-img {
  display: block;
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.info-card-content {
  margin-left: 1.6rem;

  &__title {
    color: $seaz-text;
    margin-bottom: 0.8rem;
  }
}

.info-card-text {
  font-weight: 500;
  line-height: 1.6rem;
  word-break: break-word;
}

.info-card-content-text {
  color: $clr-primary-and-accent;
  text-transform: uppercase;

  &__info {
    color: $seaz-document-count;
  }
}

.info-card-location {
  @include d-align-items-center;
  margin-top: 1rem;

  @include mobile {
    flex-direction: column;
    align-items: flex-start;
  }
}

.info-card-location-text {
  @include d-align-items-center;
  @include indent-not-last-of-type(0 1.9rem 0 0);

  &__users {
    white-space: nowrap;
  }

  &__location {
    word-break: break-word;
  }

  @include mobile {
    &__location {
      margin-top: 0.9rem;
    }
  }
}

.info-card-location-icon {
  margin-right: 0.8rem;
}

.info-card-user-title {
  font-size: 1rem;
  font-weight: 500;
  line-height: 1.2rem;
  letter-spacing: 0.04em;
  text-transform: uppercase;
  color: $profile-month;
  margin: 0 1rem 0.8rem 0;
}

.info-card-user-text {
  font-size: 1.4rem;
  font-weight: 500;
  line-height: 1.6rem;
  color: $clr-primary-and-accent;
  margin-bottom: 0.8rem;
}

.info-card-user-email {
  @include d-align-items-center;
  line-height: 1.6rem;
  color: $seaz-about-text;

  &__icon {
    margin-right: 0.9rem;
  }
}

.info-card-user-delete {
  @media screen and (max-width: 560px) {
    width: 100%;
    margin-top: 1.2rem;
  }
}

.info-card-alert {
  align-items: center;
  cursor: pointer;

  .info-card-wrap {
    align-items: center;
  }

  .info-card-avatar-boat {
    width: 5.6rem;
    height: 5.6rem;
  }

  .fleet-job-offers-logo__text {
    margin-left: 0;
    font-weight: 500;
    margin-bottom: 0.8rem;
  }

  &__content {
    margin-left: 1.6rem;
  }

  .shevron-icon {
    margin-right: 1.2rem;
  }
}

.info-card-alert-info {
  &__text {
    font-size: 1.4rem;
    line-height: 1.6rem;
    color: $seaz-document-count;
    margin-right: 0.4rem;
  }

  &__val {
    font-weight: 500;
    color: $seaz-text;
  }

  &__expense {
    @include d-align-items-center;
  }

  &__outstanding {
    margin-bottom: 0.8rem;
  }
}
