.switch-tabs {
  display: flex;
  align-items: center;
  box-sizing: border-box;
  background-color: $seaz-head-arrow;
  border: 1px solid $seaz-head-arrow;
  border-radius: 0.8rem;

  &__item {
    width: 50%;
    display: block;
    font-family: $base-font;
    font-size: 1.2rem;
    font-weight: 500;
    letter-spacing: 0.1em;
    text-transform: uppercase;
    text-align: center;
    cursor: pointer;

    input[type='radio']:checked + * {
      display: block;
      color: $light-color;
      background-color: $clr-primary-and-accent;
      border-radius: 0.8rem;
      padding: 1rem;
    }
  }

  &__value {
    display: block;
    padding: 1rem;
    color: $clr-primary-and-accent;
  }

  &__input {
    display: none;
  }

  @include mobile {
    &__item {
      font-size: 1rem;
    }
  }
}
