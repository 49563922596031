app-unauth-supplier-header,
app-unauth-header {
  display: block;
  margin-top: 5.9rem;

  @include tablet {
    margin-top: 4.9rem;
  }
}

.unauth-header {
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  padding: 2.4rem 1rem;
  background-color: $clr-primary-and-accent;

  &:before {
    content: '';
    position: absolute;
    bottom: 0;
    left: 50%;
    transform: rotateZ(-45deg) translateX(-50%);
    height: 23px;
    width: 23px;
    background-color: $clr-primary-and-accent;
    z-index: 1;
  }

  &__text {
    position: relative;
    font-size: 3rem;
    font-weight: 500;
    text-transform: uppercase;
    color: $light-color;
    z-index: 3;

    &:first-of-type {
      color: $green-grey;
    }

    &:last-of-type {
      margin-left: 2.8rem;
    }
  }

  @include laptop {
    flex-direction: column;
    padding: 1.2rem;

    &__text {
      font-size: 2rem;

      &:last-of-type {
        margin: 0.4rem 0 0 0;
      }
    }
  }
}

.unauth-title {
  font-size: 4rem;
  font-weight: bold;
  line-height: 1.2;
  color: $clr-primary-and-accent;
  margin-top: 3.2rem;
  text-align: center;

  @include laptop {
    font-size: 2.4rem;
    padding: 0 1rem;
  }
}
