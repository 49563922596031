[role='checkbox'] {
  display: inline-block;
  position: relative;
  width: 1.8rem;
  height: 1.8rem;
  box-sizing: border-box;
  cursor: pointer;
  outline: none;
}

[role='checkbox']::before,
[role='checkbox']::after {
  content: '';
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  left: 0;
  top: 0;
  width: 1.8rem;
  height: 1.8rem;
  font-size: 1rem;
  line-height: normal;
  box-sizing: border-box;
  border: 0.2rem solid $seaz-primary-and-accent;
  border-radius: 0.2rem;
}

[role='checkbox']::before {
  border-radius: 0.2rem;
  background-color: $light-color;
}

[role='checkbox'][aria-checked='true']::before {
  border-color: $seaz-primary-and-accent;
  background-color: $seaz-primary-and-accent;
  content: '\e820';
  font-family: 'Fontello';
  color: $light-color;
}

[role='checkbox'][aria-checked='mixed']::before {
  align-items: flex-end;
  border-color: $seaz-primary-and-accent;
  background-color: $seaz-primary-and-accent;
  content: '\e838';
  font-family: 'Fontello';
  color: $light-color;
}

[role='checkbox'][aria-checked='mixed']::after {
  align-items: flex-end;
  border-color: $seaz-primary-and-accent;
  background-color: $seaz-primary-and-accent;
  content: '\e838';
  font-family: 'Fontello';
  color: $light-color;
}

[role='checkbox']:focus {
  outline: none;
}

//[role="checkbox"]:focus::before {
//  width: 16px;
//  height: 16px;
//  box-sizing: content-box;
//  border-color: hsl(216, 94%, 73%);
//  border-width: 3px;
//  border-radius: calc(0.2em + 3px);
//  box-shadow: inset 0 0 0 1px hsl(216, 80%, 50%);
//}
