.rage-selection-date-form {
  .form-wrapper,
  .flexible-form,
  .block {
    @include d-align-items-center;
  }

  .flexible-form {
    margin-right: 1.2rem;
  }

  .block {
    @include indent-not-last-of-type(0 1.2rem 0 0);
  }

  app-date-control {
    display: block;
    width: 15.6rem;
  }

  .field-label {
    font-size: $base-font-size;
    color: $seaz-text;
    line-height: 1.6rem;
    margin-right: 1.6rem;
  }

  &__btn {
    color: $green-grey;
    border-color: $green-grey;
    transition: all 0.2s;
    &:hover {
      opacity: 1;
      background-color: $green-grey;
      color: white;
    }
  }
}
