.contact-persons-modal {
  .branches-modal-item__header {
    margin-bottom: 0;
  }

  &__action {
    @include d-align-items-center;
  }

  &__text {
    margin-bottom: 0.8rem;
  }

  &__view-btn {
    transition: transform 0.3s;

    &:not(:last-child) {
      margin-right: 1.6rem;
    }

    &:hover {
      transform: translateY(-0.5rem);
    }
  }

  &__contacts-box {
    margin-top: 1.2rem;
  }

  &__row {
    display: flex;
    align-items: center;
  }

  &__phone {
    display: flex;
    &:not(:first-child) {
      margin-left: 1rem;
    }
  }

  .branches-modal-item__contacts {
    margin-bottom: 0;
  }

  .branches-modal-item__contacts:not(:last-child) {
    margin-bottom: 1.2rem;
  }
}
