.sidenav-modal {
  right: 0;
  height: 100%;
  app-overlay {
    transform: translateX(100%);
  }
  .modal__wrap {
    padding: 0;
    display: flex;
    justify-content: flex-end;
    height: 100%;
  }
}
.sidemenu {
  display: flex;
  flex-direction: column;
  height: 100%;
  overflow-y: auto;
  transition: all 0.5s ease-in 0s;
  background-color: white;
  width: 26rem;
  box-shadow: 0 0.1rem 0.4rem 0 rgba(0, 0, 0, 0.04);
  justify-content: space-between;
  &__info {
    padding: 1.4rem 1.6rem;
    background-size: cover;
    background-repeat: no-repeat;
    &_purple {
      background-image: url('/assets/img/bg_sidemenu_one.jpg');
    }
    &_green {
      background-image: url('/assets/img/bg_sidemenu_second.jpg');
    }
  }
  &__user {
    width: 5.6rem;
    height: 5.6rem;
    margin-bottom: 0.8rem;
    border: none;
  }
  &__btn {
    position: absolute;
    cursor: pointer;
    right: 1.6rem;
    top: 1.4rem;
    min-width: 2.4rem;
    min-height: 2.4rem;
    &-icon {
      color: white;
    }
  }
  &__name {
    font-weight: 500;
    font-size: 1.6rem;
    color: white;
    margin-bottom: 0.4rem;
  }
  &__email {
    color: rgba(white, 0.5);
    font-size: 1.2rem;
    margin-bottom: 0.4rem;
  }
  &__role {
    font-weight: 500;
    color: white;
    font-size: 1.2rem;
  }
  &__link {
    display: flex;
    align-items: center;
    font-size: 1.4rem;
    padding: 1.2rem 1.6rem;
    color: #4d4e67;
    &-icon {
      font-size: 2.2rem;
      margin-right: 1.7rem;
    }
    &.active {
      background: rgba(231, 231, 235, 0.5);
    }
    &:hover {
      background: rgba(231, 231, 235, 0.5);
    }
  }
  &__bottom {
    padding: 0 1.6rem 0.8rem;
    text-align: center;
  }
  &__language {
    margin-bottom: 1.7rem;
    .form__input {
      height: 4rem;
      font: 1.4rem/2.2rem $base-font;
      &::placeholder {
        font: 1.4rem/2.2rem $base-font;
      }
    }
  }
}
