.registration-stepper {
  width: 100%;
  max-width: 99.2rem;
  margin: 3.2rem auto 16.4rem;

  .main-btn {
    font-size: 1.6rem;
    letter-spacing: 0.1em;
  }
}

.registration-stepper-title {
  margin-top: 1rem;
  color: $clr-primary-and-accent;
}

.registration-role {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(46.6rem, 1fr));
  grid-gap: 2.4rem;
  margin-top: 3.2rem;

  @include laptop {
    grid-template-columns: repeat(auto-fill, minmax(35rem, 1fr));
  }

  @include tablet {
    grid-template-columns: 1fr;
    grid-gap: 1.2rem 0;
    margin-top: 2.4rem;
  }
}

.registration-item {
  display: flex;
  justify-content: space-between;
  align-items: center;
  align-self: end;
  width: 100%;
  height: 100%;
  background-color: $seaz-bg;
  border-radius: 1.2rem;
  padding: 2.4rem 2.6rem;

  &__container {
    display: flex;
    align-items: end;
  }

  &__content {
    margin-left: 1.7rem;
  }

  &__title {
    font-size: 2rem;
    font-weight: 500;
    line-height: 120%;
    text-align: left;
    color: $seaz-dark-color-text;
    margin-bottom: 0.4rem;
  }

  &__description {
    line-height: 1.6rem;
    color: $clr-light-grey;
    margin-bottom: 1.2rem;
  }

  &__btn {
    display: flex;
    height: 3.2rem;
    font-size: 1.2rem;
    line-height: 3.2rem;
    padding: 0 0.8rem;
  }

  &__icon {
    transform-origin: 50%;
    transform: rotateY(190deg);
    transition: all 0.3s;
  }

  &__btn-icon {
    display: block;
    transform-origin: center;
    transform: rotateZ(180deg);
    margin-left: 1.4rem;
  }

  @include mobile {
    &__container {
      align-items: center;
    }
  }
}

.registration-item:hover .registration-item__icon {
  transform: rotateY(190deg) translate(-1rem, 0);
}

.auth-method {
  font-size: 1.6rem;
  line-height: 1.9rem;
  color: #010202;

  &__link {
    font-weight: bold;
    text-decoration: underline;
    color: $seaz-primary-and-accent;
    margin-left: 1.2rem;

    &:hover {
      text-decoration: none;
    }
  }
}

.registration-email-form {
  margin-top: 3.2rem;
}
