.recovery {
  padding-top: 3.2rem;
  background-image: url('/assets/img/img_recovery.svg');
  background-repeat: no-repeat;
  background-size: 100% 22.3rem;
  background-position: center top;
  &__logo {
    text-align: center;
    margin-bottom: 3.2rem;
    &-img {
      margin-bottom: 1.2rem;
      img {
        width: 6.6rem;
      }
    }
    &-text img {
      width: 16.6rem;
    }
  }

  &__wrap {
    max-width: 46.6rem;
    margin: 0 auto;
    width: 100%;
  }

  &__block {
    background: #ffffff;
    border-radius: 1rem 1rem 0 0;
    &-top {
      padding: 2.4rem 3rem 3.5rem;
      &-title {
        font: 500 2.4rem/3.7rem $second-font;
        margin-bottom: 2.7rem;
      }
      &-text {
        margin-bottom: 2.5rem;
        line-height: 2rem;

        &:last-child {
          margin-bottom: 0;
        }
      }
      &-wrap {
        display: flex;
        justify-content: space-between;
      }
      &-date {
        color: #a9a9a9;
      }
      &-btn {
        width: 100%;
        margin-bottom: 2.5rem;
      }
    }
    &-bottom {
      border-radius: 0 0 1rem 1rem;
      background: $clr-main;
      padding: 1.9rem 3rem 0.6rem 3rem;

      &-text {
        font-size: 1.2rem;
        color: white;
        margin-bottom: 1.1rem;
      }

      &-title {
        font: 500 2rem/3.6rem $base-font;
        color: #e7e7eb;
      }

      &-link {
        display: block;
        color: #e7e7eb;
        line-height: 2.5rem;
      }
      &-copyright {
        margin-top: 1.9rem;
        text-align: center;
        font-size: 1.2rem;
        color: #a9a9a9;
        opacity: 0.5;
      }
    }
  }
  &__h1 {
    color: $clr-purple;
    margin-bottom: 3.7rem;
  }
}
