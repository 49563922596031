.boat-maintenance-dashboard-calendar {
  position: relative;

  &__header {
    margin-bottom: 2.2rem;
  }

  .charter-calendar-monthly {
    padding: 0;
    margin-right: 0;
  }

  .charter-calendar-header {
    position: absolute;
    top: 0;
    right: 0;
    width: 40%;
  }

  .charter-calendar-header-right {
    width: 47%;
  }

  .charter-calendar-header-left {
    display: none;
  }

  .charter-calendar-period-select {
    width: 100%;
    min-width: initial;
  }

  @include tablet {
    &__header {
      margin-bottom: 1.2rem;
    }

    .charter-calendar-header {
      position: static;
      width: 100%;
      margin-bottom: 1.2rem;
    }

    .charter-calendar-header-right {
      width: 100%;
    }
  }
}
