.fleet-expenses {
  &__title {
    text-transform: uppercase;
  }
}

.fleet-expenses-form {
  &__row {
    @include d-align-items-center;
  }

  .switch {
    padding: 0.36rem 0.3rem;
  }

  .switch-category {
    margin-left: 1.2rem;
  }

  &__select-group {
    @include d-align-items-center;
    width: 100%;
    margin-left: 2rem;
  }

  &__select-month {
    width: 19.3rem;
    margin-right: 1.6rem;
  }

  &__select-year {
    width: 9.1rem;
  }

  &__button {
    flex-shrink: 0;
    border-color: $seaz-mulberry;
    color: $seaz-mulberry;
  }

  &__search {
    width: 100%;
    max-width: 72.4rem;
    margin-left: 1.6rem;
  }

  @include laptop {
    .switch-category {
      width: 100%;
    }
  }

  @include tablet {
    &__row {
      flex-direction: column;
    }

    &__select-group {
      margin: 1.2rem 0 0;
    }

    &__select-month {
      width: 100%;
      margin-right: 1.2rem;
    }

    &__button {
      width: 100%;
      flex-shrink: 1;
    }

    &__search {
      margin: 1.6rem 0 0 0;
    }

    .switch,
    .switch-category {
      width: 100%;
    }

    .switch-category {
      margin: 1.6rem 0 0 0;
    }
  }
}

.expenses-info {
  @include d-flex-space-between;
  align-items: flex-start;

  &__col {
    width: 50%;

    @include indent-not-last-of-type(0 2.4rem 0 0);
  }

  @include laptop {
    flex-direction: column;

    &__col {
      width: 100%;

      @include indent-not-last-of-type(0 0 3.2rem 0);
    }
  }
}

.expenses-info-boats-header {
  @include d-flex-space-between;
  align-items: center;

  app-seazone-select {
    width: 24%;
  }

  @include tablet {
    app-seazone-select {
      width: 100%;
      margin-left: 1.2rem;
    }
  }
}

.expenses-info-categories-header {
  display: flex;
  justify-content: flex-end;

  app-seazone-select {
    width: 24%;
  }

  @include tablet {
    app-seazone-select {
      width: 100%;
    }
  }
}

.fleet-total-expenses-header {
  @include d-flex-space-between;
  align-items: flex-start;
  border-radius: 0.8rem;
  background-color: $seaz-bg;
  padding: 1.2rem;
  font-size: 1.6rem;
  font-weight: 500;
  line-height: 1.9rem;
  color: $seaz-primary-and-accent;

  &__currency {
    white-space: nowrap;
    margin-left: 0.5rem;
  }
}

.fleet-total-expenses-chart-wrapper {
  position: relative;

  @include tablet {
    padding-top: 3rem;
  }
}

.fleet-total-expenses-chart {
  max-height: 33.2rem;
  overflow-y: auto;
  padding-right: 1rem;
  @include custom-scroll-bar;
}

.fleet-total-expenses-chart-container {
  @include mobile {
    width: 50rem;
    overflow-x: hidden;
    padding-bottom: 1rem;
  }
}

.fleet-total-expenses-chart-list {
  @include d-align-items-center;
  @include indent-not-last-of-type(0 0 1.2rem);
}

.expenses-text-boat-info {
  display: inline-block;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}

.fleet-total-expenses-boat-chart-val {
  width: 25rem;

  .expenses-text-boat-info {
    width: 8rem;
  }

  @include mobile {
    width: 20rem;

    .expenses-text-boat-info {
      width: 5rem;
    }
  }
}

.fleet-total-expenses-category-chart-val {
  width: 28.5rem;

  .expenses-text-boat-info {
    width: 8rem;
    &--wide {
      width: 14rem;
    }
  }

  @include tablet {
    width: 24rem;

    .expenses-text-boat-info {
      width: 6rem;
      &--wide {
        width: 10rem;
      }
    }
  }
}

.fleet-total-expenses-chart-val {
  @include d-flex-space-between;
  flex-shrink: 0;
  flex-wrap: wrap;
  padding: 0.6rem 0.8rem;
  border-radius: 0.2rem;
  background-color: $clr-ashy-grey;
  line-height: 1.6rem;
  color: $clr-purple;
  margin-right: 1.6rem;

  &__text {
    @include d-align-items-center;
    margin-right: 1rem;
  }

  &__currency {
    white-space: nowrap;
  }

  &__info-text {
    @include d-align-items-center;
    color: $seaz-document-count;

    app-seazone-icon {
      transform: rotate(180deg);
      margin-right: 0.7rem;
    }

    &--sub-category {
      display: inline-block;
      width: 6rem;
      text-overflow: ellipsis;
      white-space: nowrap;
      overflow: hidden;
    }
  }

  @include tablet {
    &__info-text {
      &--sub-category {
        width: 4rem;
      }
    }
  }
}

.fleet-total-expenses-chart-percent {
  font-size: 1.2rem;
  font-weight: 500;
  line-height: 1.4rem;
  color: $light-color;
  padding: 0.6rem 0.8rem;
  border-radius: 0.4rem;

  &__boat {
    background-color: $clr-primary-and-accent;

    &.value {
      min-width: 50px;
    }
    &.zero-value {
      color: $seaz-primary-and-accent;
      padding: 0;
    }
  }

  &__category {
    background-color: $green-grey;
    &.value {
      min-width: 50px;
    }
    &.zero-value {
      color: $green-grey;
      padding: 0;
    }
  }
}

.expenses-form-row {
  &__col {
    width: 50%;
    @include d-align-items-center;
    @include indent-not-last-of-type(0 2.4rem 0 0);
  }

  @include laptop {
    flex-direction: column;
    align-items: flex-start;

    &__col {
      width: 100%;
      @include indent-not-last-of-type(0 0 1.6rem 0);
    }
  }

  @include tablet {
    &__col {
      flex-direction: column;
    }
  }
}
