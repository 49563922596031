.couple {
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 1.6rem;
  line-height: 1.4;
  color: $profile-month;
  text-align: center;

  &__icon {
    margin-right: 0.5rem;
  }

  &__sign {
    color: $seaz-border;
    margin-right: 0.5rem;
  }

  @include mobile {
    font-size: 1.4rem;
  }
}

.couple-active {
  color: $seaz-edit-hover;
}

.couple-row {
  display: flex;
  align-items: center;

  &__toggle-checkbox {
    display: flex;
    align-items: center;

    .form-field__label {
      display: block;
      width: 20rem;
      margin-right: 1.5rem;
    }
  }

  &__couple-wrap {
    margin-left: 1rem;
  }

  @include mobile {
    flex-direction: column;
    align-items: flex-start;

    &__toggle-checkbox {
      .form-field__label {
        width: auto;
      }
    }

    &__couple-wrap {
      margin: 1.5rem 0 0 0;
    }
  }
}

.couple-profile-preference-row {
  margin-top: 4.2rem;

  @include tablet {
    padding: 0 2rem;
  }
}

.couple-modal {
  &__title {
    font-size: 2.4rem;
    font-weight: 500;
    line-height: 1.4;
    color: $clr-primary-and-accent;
    margin-bottom: 3rem;
  }

  &__info-text {
    font-size: 1.4rem;
    line-height: 2rem;
    color: #010202;
    margin-bottom: 3rem;
  }

  &__field {
    display: block;
    margin-bottom: 4.8rem;

    input {
      background-color: inherit !important;
    }
  }

  &__footer {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    border-top: 1px solid $seaz-head-arrow;
    padding-top: 3rem;
  }

  &__btn {
    font-size: 1.4rem;
    line-height: 2rem;
    letter-spacing: 0.1rem;
    text-transform: uppercase;
    color: $light-color;
    background-color: $clr-primary-and-accent;
    box-sizing: border-box;
    border-radius: 4px;
    border: 1px solid $clr-primary-and-accent;
    padding: 1rem 2rem;

    &:disabled {
      border: 1px solid $seaz-border;
      background-color: $seaz-border;
      cursor: default;
    }

    &--outline {
      color: $clr-primary-and-accent;
      background-color: $light-color;
      margin-right: 1.6rem;
    }
  }
}
