$base-font: 'Roboto', sans-serif;
$second-font: 'Google Sans', sans-serif;
$helvetica-font: 'Helvetica', sans-serif;

$base-font-size: 1.4rem;

$clr-main: #4d4e67;
$clr-purple: #351f6f;
$clr-purple_hover: #432989;
$clr-white: #ffffff;
$clr-green: #46a6a4;
$clr-grey: #bdbdbd;
$clr-light-grey: #828282;
$clr-ashy-grey: #f5f5f5;
$clr-primary-and-accent: #351f73;
$clr-bg: #fafafb;
$tr_03: 0.3s;
$clr-blue: #348ece;

// seaz new

$seaz-head-arrow: #e0e0e0;
$seaz-border: #bdbdbd;
$seaz-navigation: #8476aa;
$seaz-logo-border: #fafafb;
$seaz-border-info: #dcdcdd;
$seaz-mulberry: #4f194a;
$seaz-document-count: #828282;
$seaz-edit-hover: #00b9ff;
$seaz-text: #010202;
$seaz-alarm: #d70000;
$seaz-alarm-light: #ff5f5f;
$seaz-error: #ff1f3a;
$seaz-alarm-bg: #fbe7e8;
$seaz-bg: #f5f5f5;
$dashed-border: #cacaca;
$button-main: #4f194a;
$button-hover: #64205d;
$readonly-bg: #ffffff7a;
$readonly-text: #0102027d;
$seaz-warning: #f49342;
$seaz-success: #3aae27;
$seaz-about-text: #4f4f4f;
$success-color: #2e8b1f;
$disabled-link: #a9a9a9;
$waiting-dark: #94792b;
$seaz-aqua: #00acdd;
$light-color: #fff;
$greeny-bg: #d8efd4;
$greeny-border-bg: #3aae27;
$profile-month: #828282;
$light-blue: #eef7f9;
$seaz-alert-info-bg: #e7e7eb;
$dark-green: #3f9795;
$seaz-primary-and-accent: #432791;
$seaz-dark-color-text: #000000;
$seaz-delivery: #259912;
$seaz-custom: #bf70b7;
$bg-registration-page: #03a09e;
$bg-grey-light: #ededed;
$green-grey: #46a6a4;
$fb-color: #0066ff;
$banner-bg: linear-gradient(180deg, #603e9f 0%, #442a5d 100%);
$banner-text-color: rgba(255, 255, 255, 0.6);
$yellow-color: #f7c948;
$label-bg: linear-gradient(0deg, #fbe7e8, #fbe7e8), #f5dadb;
$main-bg: #e5e5e5;
$m-orange: #fdefc8;
$installation: rgba(191, 112, 183, 0.15);
$bg-pages: #fbfbfb;
$chart-g: linear-gradient(0deg, #fbe7e8, #fbe7e8), #f5dadb;
$bar-color: #c2bed4;
