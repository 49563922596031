.shared-auth {
  .login__container {
    height: 100%;
  }

  .login__col-left-content {
    @include d-flex-center-content;
    max-width: 100%;
    height: 100%;
    margin: 0;
  }

  &__title {
    margin-top: 5rem;
  }

  &__description {
    font-size: 1.6rem;
    line-height: 1.9rem;
    color: $clr-light-grey;
    margin-top: 1.2rem;
  }

  @include tablet {
    .login__col_left.hidden__col {
      min-height: 14vh;
    }

    .login__col_left {
      background-position: 50% 32%;
    }

    .login__col_right {
      min-height: 86vh;
    }

    .login__header {
      background-image: none;
      z-index: 10;
    }

    &__title {
      margin-top: 0;
      text-transform: uppercase;
    }
  }

  @media screen and (min-height: 700px) {
    .login__col_right {
      padding: 1.6rem;
    }
  }
}

.shared-auth-form {
  margin-top: 3.2rem;

  .login__form-buttons {
    margin: 3rem auto 1rem;
  }

  &__btn {
    height: 4.8rem;
    line-height: 3rem;
    box-sizing: border-box;
    font-size: 1.6rem;
  }

  @include tablet {
    &__btn {
      width: 100%;
    }
  }
}
