.outstanding-view {
  padding: 3rem;

  &__header {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  &__title {
    font-weight: 500;
  }

  &__actions {
    display: flex;
    align-items: center;
  }

  &__action-btn {
    &:not(:last-of-type) {
      margin-right: 1.2rem;
    }
  }

  &__close-btn {
    margin-left: 3.7rem;
  }

  &__tabs {
    margin-top: 3rem;
  }

  @include tablet {
    padding: 1.5rem;

    &__title {
      font-size: 2.4rem;
    }
  }
}

.outstanding-view-tab {
  box-sizing: border-box;

  &__row {
    display: flex;
    padding: 1.2rem;

    &--with-border {
      &:not(:last-of-type) {
        border-bottom: 1px solid $seaz-head-arrow;
      }
    }

    &--bg {
      background-color: $clr-ashy-grey;
      border-bottom: 0;
      border-radius: 0.8rem;
    }
  }

  &__col {
    width: 50%;
    box-sizing: border-box;
  }

  &__text {
    font-size: 1.4rem;
    line-height: 1.6rem;
    word-break: break-all;
    color: $seaz-text;
  }

  &__sub-text {
    display: block;
    font-size: 1.2rem;
    line-height: 1.4rem;
    color: $seaz-about-text;
    margin-top: 0.2rem;
  }

  &__view-content {
    display: flex;
    align-items: center;
    font-size: 1.6rem;
    line-height: 1.4;
    text-align: left;
    color: $profile-month;
    word-break: break-all;
    transition: opacity 0.3s;

    &:hover {
      opacity: 0.7;
    }
    .seazone-icon {
      margin-right: 1rem;
    }
  }

  &__description {
    width: 100%;
  }

  &__description-title {
    color: $seaz-about-text;
    margin-bottom: 0.7rem;
  }

  @include tablet {
    &__row {
      padding: 1.2rem 0.7rem;
    }

    &__col {
      &:not(:last-of-type) {
        margin-right: 1.2rem;
      }
    }

    &__view-content {
      align-items: flex-start;
    }

    &__view-content-icon {
      margin-top: 1rem;
      margin-right: 0.8rem;
    }
  }
}

.history-view-table {
  width: 100%;
  margin: 0 auto;
  border-collapse: separate;
  padding-bottom: 1rem;

  &__row {
    &:nth-of-type(2n) {
      .history-view-table__cell {
        background-color: $clr-ashy-grey;
      }
    }

    &--body {
      &:nth-of-type(2n + 1) {
        .history-view-table__cell {
          padding: 2rem 0.8rem;
        }
      }
    }
  }

  &__cell {
    width: 20rem;
    text-align: left;
    vertical-align: top;
    padding: 0.8rem;
    font-size: 1.4rem;
    line-height: 1.6rem;

    &:first-of-type {
      width: 10.5rem;
      min-width: 10.5rem;
      border-radius: 1.2rem 0 0 1.2rem;
    }

    &:last-of-type {
      border-radius: 0 1.2rem 1.2rem 0;
    }

    &--with-arrow {
      width: 3.2rem;
      vertical-align: middle;
    }
    &-row {
      display: flex;
      align-items: flex-start;
      row-gap: 2rem;
      .history-view-table__text {
        flex: 1 auto;
      }
    }
  }

  &__title {
    color: $clr-light-grey;
  }

  &__text {
    color: $seaz-text;
    margin-bottom: 0.2rem;
    word-break: break-all;
  }

  &__sub-text {
    font-size: 1.2rem;
    line-height: 1.4;
    color: $seaz-about-text;
  }

  @include tablet {
    &__cell {
      min-width: 10rem;
    }
  }
}

.history-view-btn {
  margin-left: 1rem;
}
