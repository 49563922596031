.maintenance-filters-modal {
  .seaz-filters__head {
    margin-bottom: 1.6rem;
  }

  .seaz-filters__head_top {
    border-bottom: none;
  }

  .seaz-crew-search-all-filter__actions {
    max-width: 72rem;
  }
}

.maintenance-filters-modal-content {
  @include d-align-items-center;
  padding: 0 2.4rem;
  @include indent-not-last-of-type(0 0 4rem 0);

  &__special {
    align-items: flex-start;
  }

  @include tablet {
    flex-direction: column;
    align-items: flex-start;
    @include indent-not-last-of-type(0 0 3.2rem 0);
  }
}

.maintenance-filters-modal-title {
  width: 16rem;
  min-width: 16rem;
  font-size: $base-font-size;
  line-height: 1.6rem;
  color: $seaz-text;
  word-break: break-word;

  @include tablet {
    margin-bottom: 0.8rem;
  }
}

.maintenance-filters-modal-actions {
  @include d-align-items-center;
  flex-wrap: wrap;
  flex-grow: 1;

  .chips-item.selected {
    border: 1px solid $seaz-text;
  }
}
