.petty-cash-title {
  @include d-align-items-center;
  text-transform: uppercase;
  margin-bottom: 2rem;

  @include mobile {
    flex-direction: column;
    align-items: flex-start;
    margin-bottom: 2rem;
  }
}

.currencies-total {
  display: block;
  margin-left: 2.4rem;

  &.positive {
    color: $dark-green;
  }

  &.negative {
    color: $seaz-alarm;
  }

  @include mobile {
    margin: 0.1rem 0 0 0;
  }
}

.petty-cash-currency {
  margin-top: 4rem;
}

.petty-cash-currency-header {
  @include d-flex-space-between;
  align-items: flex-start;

  &__title {
    width: 100%;
    max-width: 59.7rem;
    word-break: break-word;
    font-weight: 700;
    line-height: 2.8rem;
  }

  app-petty-cash-balance-date-selection {
    display: block;
    flex-shrink: 0;
    margin-left: 1rem;
  }

  @include desktop {
    .rage-selection-date-form {
      .form-wrapper {
        align-items: flex-end;
      }

      .block {
        flex-direction: column;
        align-items: flex-start;
      }

      .field-label {
        margin: 0 0 0.8rem 0;
      }
    }
  }

  @include laptop {
    flex-direction: column;

    &__title {
      max-width: 100%;
    }

    app-petty-cash-balance-date-selection {
      margin: 1.6rem 0 0 0;
    }
  }

  @include tablet {
    app-petty-cash-balance-date-selection {
      width: 100%;
    }

    .rage-selection-date-form {
      .form-wrapper,
      .flexible-form {
        flex-direction: column;
        align-items: flex-start;
      }

      app-date-control,
      .flexible-form,
      .block,
      &__btn {
        width: 100%;
      }

      .block {
        margin-bottom: 1.2rem;
      }
    }
  }
}

.petty-cash-currency-statistic {
  margin-top: 4rem;
}

.petty-cash-balance {
  display: flex;
  align-items: stretch;

  app-petty-cash-statistic-chart {
    width: 100%;
  }

  .dashboard__board {
    height: 100%;
  }

  .negative .amount {
    color: $button-main;
  }

  .chart-container {
    height: 20rem !important;
  }

  @include laptop {
    flex-direction: column;
  }
}

.petty-cash-balance-transaction {
  margin-top: 4.3rem;

  &__title {
    text-transform: uppercase;
    margin-right: 1rem;

    @include tablet {
      margin: 0 0 1.2rem 0;
    }
  }

  .cover {
    overflow: auto;
    overflow-y: hidden;
  }

  app-petty-cash-balance-search .form-field--content {
    border-radius: 0.8rem;
  }

  .badge-close-btn {
    app-seazone-icon {
      margin-left: 1rem;
    }
  }
}

.petty-cash-balance-transaction-header {
  @include d-flex-space-between;
  align-items: center;

  @include tablet {
    flex-direction: column;
    align-items: flex-start;

    &__btn {
      width: 100%;
      margin-top: 1.2rem;
    }
  }
}

.petty-cash-balance-transaction-actions {
  &__btn {
    @include indent-not-last-of-type(0 1.2rem 0 0);
  }

  @include tablet {
    width: 100%;

    &__btn {
      width: 100%;
      @include indent-not-last-of-type(0 0 1.2rem 0);
    }
  }
}

.petty-cash-balance-transaction-table {
  .title label,
  .form-field--label {
    font-size: 1.2rem;
    font-weight: 400;
    line-height: 120%;
    color: $profile-month;
    margin-right: 0;
  }

  app-seazone-form-field {
    max-width: 100%;
  }

  .financial-data__table_row .financial-data__table--receipt app-seazone-form-field {
    max-width: 10.5rem;
  }

  .financial-data__table--method app-seazone-form-field {
    max-width: 10rem !important;
  }

  .financial-data__table_row .financial-data__table--status {
    max-width: initial;
  }

  .select-value {
    font-size: 1.4rem;
  }

  .financial-data__charter {
    max-width: 9rem;
  }

  .table__col-inner {
    font-size: $base-font-size;
    line-height: 1.6rem;
    color: $seaz-text;
    &.positive {
      color: $dark-green;
    }
  }

  .table__col-inner-text {
    font-size: 1.2rem;
    line-height: 120%;
    color: $seaz-about-text;
  }
}
