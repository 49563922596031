.boat-document {
  display: block;
  border: 1px solid $seaz-head-arrow;
  box-sizing: border-box;
  border-radius: 1.2rem;
  padding: 1.2rem 1.9rem 1.2rem 1.2rem;

  @include indent-not-last-of-type(0 0 1.2rem 0);

  @include mobile {
    padding: 1rem;
    border: none;

    &:nth-of-type(2n + 1) {
      background-color: $seaz-bg;
    }

    &:nth-of-type(2n) {
      .document-expired {
        padding-bottom: 0.8rem;
        border-bottom: 1px solid $seaz-bg;
      }

      .document-info {
        &__date {
          position: relative;

          &:before {
            content: '';
            position: absolute;
            top: 0;
            right: 0;
            width: 0.1rem;
            height: 3.4rem;
            background-color: $seaz-bg;
          }
        }
      }

      .document-action {
        padding-top: 1rem;
        border-top: 1px solid $seaz-bg;
      }
    }
  }
}

.boat-document-bread-crumbs {
  @include d-align-items-center;
  flex-wrap: wrap;
  // margin-bottom: 0.8rem;
  margin-bottom: 0.5rem;

  &__link,
  &__text {
    @include d-align-items-center;
    font-size: 1.2rem;
    font-weight: 400;
    line-height: 120%;
    color: $seaz-document-count;
    cursor: text;
  }

  &__link {
    &.active {
      cursor: pointer;
    }
    &:hover {
      color: $seaz-primary-and-accent;
    }
  }

  &__icon {
    transform: rotate(180deg);
    margin: 0 0.5rem;
  }
}

.boat-document-content {
  display: grid;
  grid-template-areas:
    'name info action arrow'
    'expired expired expired expired';
  grid-template-columns: 1fr 45rem 11% 2rem;
  align-items: center;
  grid-gap: 0 1rem;

  @include laptop {
    grid-template-columns: 1fr 37rem 11% 2rem;
  }

  @include tablet {
    grid-template-areas:
      'name arrow'
      'expired expired'
      'info info'
      'action action';
    grid-template-columns: 1fr auto;
    align-items: start;
    grid-gap: 0 0.8rem;
  }
}

.document-name {
  grid-area: name;
  display: flex;

  &__title {
    @include d-align-items-center;
    flex-wrap: wrap;
  }

  &__title-box {
    font-size: 1.6rem;
    font-weight: 500;
    line-height: 1.9rem;
    word-break: break-word;
    color: $seaz-text;
    span {
      font-size: 1.6rem;
      line-height: 1.9rem;
      color: $profile-month;
    }
  }

  @include laptop {
    &__title-box {
      span {
        display: block;
      }
    }
  }

  @include tablet {
    &__title {
      margin-bottom: 0;
    }

    &__title-box {
      font-size: 1.4rem;
    }
  }
}

.document-expired {
  grid-area: expired;
  margin-top: 0.8rem;
}

.document-info {
  grid-area: info;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  align-items: start;
  grid-gap: 0 1rem;
  margin-right: 2rem;

  &__label {
    font-size: 1.2rem;
    font-weight: 400;
    line-height: 120%;
    color: $profile-month;
  }

  .yaht-documents__info_col {
    font-weight: 400;

    span {
      @include d-align-items-center;

      app-seazone-icon {
        margin-right: 0.5rem;
      }
    }
  }

  .yaht-documents__info_col_label {
    font-size: 1.2rem;
    font-weight: 400;
    line-height: 120%;
    color: $profile-month;
  }

  @include tablet {
    margin: 1.6rem 0 0;
  }

  @include mobile {
    grid-template-columns: repeat(auto-fill, minmax(12.3rem, 1fr));
    grid-gap: 1.3rem 1rem;
  }
}

.document-action {
  grid-area: action;
  @include d-align-items-center;
  flex-shrink: 0;

  .yaht-documents__action_btn {
    @include indent-not-last-of-type(0 2rem 0 0);
  }

  @include laptop {
    .yaht-documents__action_btn {
      @include indent-not-last-of-type(0 1.2rem 0 0);
    }
  }

  @include tablet {
    justify-content: space-between;
    flex-shrink: 1;
    margin-top: 1.9rem;
  }
}

.document-arrow {
  grid-area: arrow;
  @include d-flex-center-content;
  height: 100%;

  app-seazone-icon {
    transform: rotate(180deg);
  }

  @include tablet {
    display: flex;
    justify-content: flex-end;
    align-items: flex-start;
  }
}

.expired {
  color: $seaz-alarm;
}

.expired-soon {
  color: $seaz-warning;
}
