.boat-systems-tasks {
  display: block;
}

.systems-tasks-container {
  margin-top: 4.4rem;

  @include box-grid(40rem minmax(60rem, 1fr), initial, initial, 0 3.2rem);

  @include desktop-m {
    @include box-grid(35rem minmax(60rem, 1fr), initial, initial, 0 2rem);
  }

  @include laptop {
    display: flex;
    flex-direction: column;
    margin-top: 1.6rem;
  }
}

.systems-items-systems-tasks {
  &.systems-items {
    margin-left: 0;
  }

  .boat-maintenance-task-log-header__container {
    width: 100%;
    margin-left: 0;
  }

  .boat-maintenance-task-log-form {
    grid-template-columns: 1fr auto auto;
    grid-gap: 1.6rem;
  }

  @include desktop-m {
    .boat-maintenance-task-log-form {
      grid-template-columns: 1fr auto auto;
      grid-gap: 1.6rem 0;
    }

    .boat-maintenance-task-log-form__search {
      margin-right: 1.2rem;
    }
  }

  @include mobile {
    .boat-maintenance-dashboard-systems-tasks {
      flex-wrap: nowrap;
    }
  }
}

.maintenance-equipment-tree-systems-tasks {
  align-self: baseline;
}
