button:not(:disabled) {
  cursor: pointer;
}

.main-btn {
  position: relative;
  display: inline-block;
  text-align: center;
  font: 500 1.4rem/2.2rem $base-font;
  border-radius: 0.3rem;
  padding: 1.2rem 0.8rem;
  text-transform: uppercase;
  cursor: pointer;
  transition: $tr_03;
  white-space: nowrap;
  &:disabled {
    opacity: 0.3;
    pointer-events: none;
    box-shadow: none;
    background: gray;
    border-color: gray;
  }
  @include mobile {
    font-size: 1rem;
    line-height: 1.6rem;
    padding: 0.8rem;
  }
  &_icon-left {
    padding: 0.8rem 0.8rem 0.8rem 3.8rem;
    @include mobile {
      padding-left: 3rem;
    }
  }
  &_icon-right {
    padding: 0.8rem 3.8rem 0.8rem 0.8rem;
  }
  &__icon {
    display: inline-block;
    position: absolute;
    min-width: 2.2rem;
    min-height: 2.2rem;
    &_left {
      left: 0.7rem;
    }
    &_right {
      right: 0.7rem;
    }
    & > .icon-anchor {
      font-size: 2rem;
    }
    @include mobile {
      min-width: 1.6rem;
      min-height: 1.6rem;
    }
  }
  &__text {
    display: inline-block;
    &_big {
      padding: 0 3.4rem;
      @include mobile {
        padding: 0 0.8rem;
      }
    }
    &_small {
      padding: 0 1rem;
    }
  }
  &_gradient {
    border: 0.1rem solid $clr-purple;
    color: white;
    background: linear-gradient(180deg, #4f194a 0%, $clr-purple 50%, #4f194a 100%);
    background-size: auto 200%;
    transition: 0.5s;
    &:hover,
    &:focus,
    &:active {
      background-position: center bottom;
    }
  }
  &_white {
    border: 0.1rem solid $clr-purple;
    color: $clr-purple;
    background-color: #fff;
    &:hover {
      background-color: rgba(53, 31, 111, 0.05);
      color: $clr-purple_hover;
      border-color: $clr-purple_hover;
    }
    &:focus,
    &:active {
      background-color: rgba(53, 31, 111, 0.18);
      color: #24105b;
      border-color: #24105b;
    }
  }
  &_white__transparent {
    border: 0.1rem solid $clr-white;
    color: $clr-white;
    background-color: transparent;
    &:hover {
      background-color: rgba(53, 31, 111, 0.05);
      color: $clr-white;
      border-color: $clr-white;
    }
    &:focus,
    &:active {
      background-color: rgba(53, 31, 111, 0.18);
      color: $clr-white;
      border-color: $clr-white;
    }
  }
  &_green {
    border: 0.1rem solid #46a6a4;
    color: white;
    background: #46a6a4;
    &.main-btn_form-icon {
      color: white;
    }
    &:hover {
      background-color: #55b5b3;
      border-color: #55b5b3;
    }
    &:focus,
    &:active {
      background-color: #3f9795;
      border-color: #3f9795;
    }
  }
  &_white-green {
    border: 0.1rem solid #46a6a4;
    color: #46a6a4;
    background: white;
    &:hover {
      background-color: rgb(246, 251, 250);
      color: #55b5b3;
      border-color: #55b5b3;
    }
    &:focus,
    &:active {
      background-color: rgb(222, 239, 239);
      color: #3f9795;
      border-color: #3f9795;
    }
  }
  &_gray {
    border: 0.1rem solid #898b8c;
    color: #898b8c;
    background: white;
    &:hover {
      background-color: rgb(243, 243, 243);
      color: #898b8c;
      border-color: #c4c5c5;
    }
    &:focus,
    &:active {
      background-color: rgb(243, 243, 243);
      color: #898b8c;
      border-color: #c4c5c5;
    }
  }
  &_team-gray {
    background: #e7e7eb;
    padding: 0.9rem 1.1rem 0.9rem 1.6rem;
    border-radius: 2rem;
    color: #4d4e67;
    &:hover {
      background-color: #f0f0f3;
    }
    &:focus,
    &:active {
      background-color: #d8d8db;
    }
  }
  &__auto-width {
    min-width: auto !important;
    padding: 1.2rem 1.4rem;
  }
  &_form-icon {
    color: #46a6a4;
    padding: 0.9rem 1.2rem;
  }
  //-------------OLD-------------------------//
  &_purple {
    border: 0.15rem solid $clr-purple;
    color: white;
    background: $clr-purple;
    &:hover {
      background-color: $clr-purple_hover;
      border-color: $clr-purple_hover;
    }
    &:focus,
    &:active {
      background-color: #24105b;
      border-color: #24105b;
    }
  }
  &_white-grayish {
    border: 0.1rem solid $clr-main;
    color: $clr-main;
    background: white;
    &:hover {
      background-color: rgba(77, 78, 103, 0.05);
    }
    &:focus,
    &:active {
      background-color: rgba(77, 78, 103, 0.18);
    }
  }
  &_white-red {
    border: 0.1rem solid #e36f84;
    color: #e36f84;
    background: #fff;
    &:hover {
      background-color: rgba(255, 85, 116, 0.05);
    }
    &:focus,
    &:active {
      background-color: rgba(255, 85, 116, 0.18);
    }
    &:disabled {
      color: #fff;
    }
  }
  &_form-green {
    padding: 0.9rem 1.2rem;
    color: white;
    background: #46a6a4;
    border: 0.1rem solid #46a6a4;
  }
  &_form-expense {
    background: #46a6a4;
    border: 0.1rem solid #46a6a4;
    color: white;
  }
}
.btn-up {
  position: absolute;
  width: 4rem;
  height: 4rem;
  line-height: 4rem;
  top: -2rem;
  left: 50%;
  transform: translateX(-50%);
  background-color: $clr-purple;
  color: #fff;
  box-shadow: 0 0.2rem 0.5rem rgba(0, 0, 0, 0.04);
  border-radius: 3rem;
  border: 0.2rem solid white;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
}

.btn-expand {
  align-items: center;
  justify-content: center;
  min-height: 2.8rem;
  min-width: 2.8rem;
  background-color: rgba(#e7e7eb, 0.5);
  border-radius: 0.9rem;
  i {
    font-size: 1.4rem;
    color: $clr-purple;
  }
}

// new buttons

.seaz__btn {
  text-transform: uppercase;
  color: $clr-white;
  padding: 1.2rem 3.2rem;
  font-size: 1.4rem;
  letter-spacing: 0.1em;
  cursor: pointer;
  border: 1px solid transparent;
  white-space: nowrap;
  @include mobile {
    padding: 1rem 3.2rem;
    font-size: 1.2rem;
  }

  &_primary {
    @extend .seaz__btn;
    background-color: $clr-primary-and-accent;
    &:hover {
      background-color: $clr-purple_hover;
    }
    &:disabled {
      background-color: $seaz-border;
      cursor: inherit;
    }

    &-arrows {
      @extend .seaz__btn;
      background-color: $clr-primary-and-accent;
      color: $clr-white;
      display: flex;
      &::before,
      &::after {
        content: '';
        background-image: url('/assets/img/btn-arrow-white.svg');
        display: block;
        width: 1.8rem;
        height: 1.7rem;
        background-position: center;
        background-repeat: no-repeat;
        background-size: contain;
      }
      &::before {
        margin-right: 1.8rem;
      }
      &::after {
        margin-left: 1.8rem;
      }

      &:hover {
        background-color: $clr-purple_hover;
      }
      &:disabled {
        background-color: $seaz-border;
        cursor: inherit;
      }

      @include mobile {
        &::before {
          display: none;
        }
        &::after {
          background-image: url('/assets/img/btn-plus-white.svg');
        }
      }
    }
  }
  &_primary.share {
    background-color: #00b9ff;
    &:hover {
      background-color: #14aee8;
    }
  }
  &_mulberry {
    @extend .seaz__btn;
    background-color: $button-main;
    &:hover {
      background-color: $button-hover;
    }
    &:disabled {
      background-color: $seaz-border;
      cursor: inherit;
    }
  }

  &_green {
    @extend .seaz__btn;
    background-color: $clr-green;
    &:hover {
      background-color: #55b5b3;
    }
    &:disabled {
      background-color: $seaz-border;
      cursor: inherit;
    }
  }

  &_light_green {
    @extend .seaz__btn;
    background-color: $seaz-success;
    opacity: 0.5;
    display: flex;
    align-items: center;
    justify-content: center;
    app-seazone-icon {
      margin-left: 0.8rem;
    }
    &:hover {
      opacity: 1;
    }
    &:disabled {
      background-color: $seaz-border;
      cursor: inherit;
    }
  }

  &_aqua {
    @extend .seaz__btn;
    background-color: $seaz-edit-hover;
    &:disabled {
      background-color: $seaz-border;
      cursor: inherit;
    }
  }

  &_outlined {
    @extend .seaz__btn;
    background-color: transparent;
    border: 1px solid $button-main;
    color: $button-main;
    &:hover {
      background-color: $button-main;
      color: $clr-white;
    }
    &.disabled {
      background-color: $seaz-border;
      cursor: inherit;
      border: 1px solid transparent;
      color: $clr-white;
    }
    &:disabled {
      background-color: $seaz-border;
      cursor: inherit;
      border: 1px solid transparent;
      color: $clr-white;
    }

    &--black {
      border: 1px solid $seaz-text;
      color: $seaz-text;
      &:hover {
        background-color: $seaz-text;
      }
    }
    &--blue {
      border: 1px solid $seaz-edit-hover;
      color: $seaz-edit-hover;
      &:hover {
        background-color: $seaz-edit-hover;
        color: $clr-white;
      }
    }
    &--primary {
      border: 1px solid $clr-primary-and-accent;
      color: $clr-primary-and-accent;
      &:hover {
        background-color: $clr-primary-and-accent;
        color: $clr-white;
      }
    }
    &-red {
      @extend .seaz__btn;
      background-color: transparent;
      border: 1px solid $seaz-alarm;
      color: $seaz-alarm;
      &:hover {
        background-color: $seaz-alarm;
        color: $clr-white;
      }
    }
    &-green {
      @extend .seaz__btn;
      background-color: transparent;
      border: 1px solid $seaz-success;
      color: $seaz-success;
      &:hover {
        background-color: $seaz-success;
        color: $clr-white;
      }
    }
    &_plus {
      @extend .seaz__btn_outlined;
      display: flex;
      align-items: center;
      justify-content: center;

      &::after {
        content: '';
        background-image: url('/assets/img/plus-purple.svg');
        width: 2rem;
        height: 2rem;
        background-position: center;
        background-repeat: no-repeat;
        background-size: contain;
        display: block;
        position: relative;
        top: 50%;
        transform: translate(-50%, 0);
        margin-left: 1.8rem;
      }
      &:hover {
        &::after {
          background-image: url('/assets/img/btn-plus-white.svg');
        }
      }
    }
  }

  &_outlined-arrows {
    @extend .seaz__btn;
    border: 1px solid $button-main;
    color: $button-main;
    display: flex;
    background-color: transparent;
    &::before,
    &::after {
      content: '';
      background-image: url('/assets/img/btn-arrow.svg');
      display: block;
      width: 1.8rem;
      height: 1.7rem;
      background-position: center;
      background-repeat: no-repeat;
      background-size: contain;
    }
    &::before {
      margin-right: 1.8rem;
    }
    &::after {
      margin-left: 1.8rem;
    }

    &:hover {
      border: 1px solid transparent;
      color: $clr-white;
      background-color: $button-hover;
      &::before,
      &::after {
        background-image: url('/assets/img/btn-arrow-white.svg');
      }
    }

    &:disabled {
      background-color: $seaz-border;
      cursor: inherit;
      border: 1px solid transparent;
      color: $clr-white;
      &::before,
      &::after {
        background-image: url('/assets/img/btn-arrow-white.svg');
      }
    }

    @include mobile {
      &::before {
        display: none;
      }
      &:hover {
        &::after {
          background-image: url('/assets/img/btn-plus-white.svg');
        }
      }
      &:disabled {
        &::after {
          background-image: url('/assets/img/btn-plus-white.svg');
        }
      }
    }
  }
  &_small {
    &-purple {
      @extend .seaz__btn;
      font-size: 1.2rem;
      padding: 0.9rem 1.5rem;
      background-color: $clr-primary-and-accent;
      color: $clr-white;
    }
    &-outlined {
      @extend .seaz__btn;
      font-size: 1.2rem;
      padding: 0.9rem 1.5rem;
      border: 1px solid $clr-primary-and-accent;
      background-color: transparent;
      color: $clr-primary-and-accent;
      color: #351f73;
      &.disabled {
        background-color: $seaz-border;
        cursor: inherit;
        border: 1px solid transparent;
        color: $clr-white;
      }

      &-plus {
        @extend .seaz__btn_small-outlined;
        display: flex;
        align-items: center;
        padding: 0.5rem 1.5rem;
        &::before {
          content: '';
          background-image: url('/assets/img/plus-purple.svg');
          width: 1.3rem;
          height: 1.3rem;
          background-position: center;
          background-repeat: no-repeat;
          background-size: contain;
          display: block;
          position: relative;
          top: -2px;
          margin-right: 0.8rem;
        }
      }
    }
  }
}
.capital {
  text-transform: uppercase;
}

.location__btn {
  display: flex;
  margin-left: 9rem;
  @include tablet-big {
    margin-left: 4rem;
  }
  @include mobile {
    margin-left: 0;
  }
  &--title {
    font-size: 1.4rem;
    text-decoration-line: underline;
    text-align: left;
    color: $seaz-edit-hover;
    cursor: pointer;
    margin-left: 1rem;
  }
}
.seaz-charter__btn {
  &_outlined {
    &_green {
      font-weight: 500;
      letter-spacing: 0.1em;
      text-transform: uppercase;
      color: $clr-green;
      padding: 1.1rem 1.6rem;
      border: 1px solid $clr-green;
      border-radius: 4px;
      cursor: pointer;
    }
    &_purple {
      font-weight: 500;
      letter-spacing: 0.1em;
      text-transform: uppercase;
      color: $clr-primary-and-accent;
      padding: 0.8rem 1.6rem;
      border: 1px solid $clr-primary-and-accent;
      border-radius: 4px;
      cursor: pointer;
      display: flex;
      align-items: center;
      app-seazone-icon {
        margin-right: 1rem;
      }
    }
    &_mulberry {
      font-weight: 500;
      letter-spacing: 0.1em;
      text-transform: uppercase;
      color: $seaz-mulberry;
      padding: 0.8rem 1.6rem;
      border: 1px solid $seaz-mulberry;
      border-radius: 4px;
      cursor: pointer;
    }
    &_primary {
      font-weight: 500;
      letter-spacing: 0.1em;
      text-transform: uppercase;
      color: $clr-primary-and-accent;
      padding: 0.8rem 1.6rem;
      border: 1px solid $clr-primary-and-accent;
      border-radius: 4px;
      cursor: pointer;
    }
  }

  &_primary {
    padding: 0.8rem 1.5rem;
    border-radius: 4px;
    display: flex;
    align-items: center;
    background-color: $clr-primary-and-accent;
    color: $clr-white;
    text-transform: uppercase;
    &:disabled {
      background-color: $seaz-border;
      cursor: default;
    }
    &--plus {
      padding: 0.8rem 1.5rem;
      border-radius: 4px;
      display: flex;
      align-items: center;
      background-color: $clr-primary-and-accent;
      color: $clr-white;
      text-transform: uppercase;
      letter-spacing: 0.2rem;
      font-weight: 500;

      &:disabled {
        background-color: $seaz-border;
        cursor: inherit;
      }
      &::before {
        content: '';
        background-image: url('/assets/img/btn-plus-white.svg');
        width: 1.4rem;
        height: 1.4rem;
        background-position: center;
        background-repeat: no-repeat;
        background-size: contain;
        display: block;
        margin-right: 1.3rem;
      }
    }
  }
}

.seaz-charter-btn-accent {
  background-color: $clr-blue;
}

.seaz-charter-form-add-btn {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 8.2rem;
  font-size: 1.2rem;
  font-weight: 500;
  letter-spacing: 0.1em;
  text-transform: uppercase;
  padding: 0.8rem;
  margin: 3rem 0;
  color: $button-main;
  border: 1px solid $button-main;
  box-sizing: border-box;
  transition: $tr_03;

  &:hover {
    opacity: 0.6;
  }

  &__icon {
    display: flex;
    justify-content: center;
    align-items: flex-end;
    margin-right: 0.9rem;
  }
}

.seaz-charter__apa_form .main-btn {
  font-size: 1.4rem;
}

.user-manage-btn {
  padding: 0.8rem;
}

.capitalize {
  text-transform: capitalize;
}

.seaz__btn_rounded {
  font-size: 1.4rem;
  font-weight: 500;
  letter-spacing: 0.1em;
  text-transform: uppercase;
  // line-height: 2rem;
  padding: 1rem 2rem;
  box-sizing: border-box;
  border-radius: 0.4rem;
  border: 1px solid $clr-primary-and-accent;
  background-color: $clr-primary-and-accent;
  color: $light-color;
  transition: opacity 0.2s;

  &:disabled {
    background-color: $clr-grey;
    cursor: inherit;
    border-color: $clr-grey;

    &:hover {
      opacity: 1;
    }
  }

  &:hover {
    opacity: 0.6;
  }

  &--outline {
    background-color: $light-color;
    color: $clr-primary-and-accent;

    &:disabled {
      background-color: initial;
      opacity: 0.4;

      &:hover {
        opacity: 0.4;
        cursor: default;
      }
    }
  }

  &--outline-plus {
    display: flex;
    align-items: center;

    &:after {
      content: '';
      background-image: url('/assets/img/plus-purple.svg');
      width: 2rem;
      height: 2rem;
      background-position: center;
      background-repeat: no-repeat;
      background-size: contain;
      display: block;
      position: relative;
      top: 50%;
      transform: translate(-50%, 0);
      margin-left: 1.8rem;
    }
  }

  &--with-icon {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  &--icon {
    margin-right: 1rem;
  }

  &--icon-right {
    margin-left: 1rem;
  }

  &--icon-rotate {
    transform: rotateZ(180deg);
  }

  &--delete {
    color: $seaz-alarm;
    border-color: $seaz-alarm;
    background-color: initial;

    &:hover {
      opacity: 1;
      background-color: $seaz-alarm;
      color: $light-color;
    }
  }

  &--outline-blue {
    background-color: $light-color;
    color: $seaz-edit-hover;
    border-color: $seaz-edit-hover;
    transition-property: background-color, color;

    &:disabled {
      background-color: initial;
      opacity: 0.4;

      &:hover {
        opacity: 0.4;
        cursor: default;
      }
    }

    &:hover {
      background-color: $seaz-edit-hover;
      color: $light-color;
      opacity: 1;
    }
  }

  &--outline-green {
    background-color: $light-color;
    color: $seaz-success;
    border-color: $seaz-success;
    transition-property: background-color, color;

    &:disabled {
      background-color: initial;
      opacity: 0.4;

      &:hover {
        opacity: 0.4;
        cursor: default;
      }
    }

    &:hover {
      background-color: $seaz-success;
      color: $light-color;
      opacity: 1;
    }
  }

  &--outline-main {
    color: $green-grey;
    border-color: $green-grey;
    transition-property: background-color, color;

    &:disabled {
      background-color: initial;
      opacity: 0.4;

      &:hover {
        opacity: 0.4;
        cursor: default;
      }
    }

    &:hover {
      background-color: $green-grey;
      color: $light-color;
      opacity: 1;
    }
  }

  &--main-btn {
    background-color: $button-main;
  }

  &--share,
  &--aqua {
    background-color: $seaz-edit-hover;
    color: $light-color;
    border-color: $seaz-edit-hover;
  }

  &--green {
    background-color: $green-grey;
    border-color: $green-grey;
  }
}

.seaz-btn-primary-small {
  width: 4rem;
  height: 4rem;
  background-color: $clr-grey;
  border-radius: 0.4rem;
  transition: opacity 0.3s;

  &:hover {
    opacity: 0.6;
  }
}

.action-button {
  display: block;
  width: 4rem;
  height: 4rem;
  text-align: center;
  line-height: 4rem;
  background-color: $seaz-bg;
  border-radius: 50%;
}

.main-btn-icon {
  margin-right: 0.9rem;
}
