.ratings-person {
  @include d-flex-space-between;
  align-items: center;
  width: 100%;

  &__btn {
    transition: opacity 0.3s;

    &:hover {
      @include hover-opacity(0.8);
    }
  }
}

.ratings-info {
  &__company {
    font-size: 1.6rem;
    font-weight: 500;
    line-height: 1.9rem;
    color: $seaz-about-text;
  }

  &__reviews {
    color: $profile-month;
    margin-top: 0.4rem;
  }
}

.rate-button {
  @include d-flex-center-content;
  width: 100%;
  font-size: 1.6rem;
  font-weight: 500;
  line-height: 1.9rem;
  color: $seaz-success;
  background-color: $clr-ashy-grey;
  border-radius: 0.8rem;
  padding: 1.2rem 1rem;
  transition: opacity 0.3s;

  &__icon {
    margin-right: 0.7rem;
  }

  &:hover {
    @include hover-opacity(0.8);
  }
}
