.maintenance-tree-modal {
  position: relative;
  height: 90vh;
  padding: 2.4rem 0;
  overflow-y: auto;
  overflow-x: hidden;
  @include custom-scroll-bar;

  .list-view-btn {
    top: 9rem;
    left: 26rem;

    app-seazone-icon {
      transform: rotate(90deg);
      margin-left: 1rem;
    }
  }

  .maintenance-equipment-tree {
    box-shadow: initial;
    padding: 2.4rem 0.8rem;
    border: none;
  }

  .maintenance-tree {
    max-height: 95%;
  }
}

.left-sidenav {
  app-overlay {
    max-width: 30rem;
    overflow: hidden;
  }

  .modal__wrap {
    padding: 0 1rem;
  }
}
