.seaz-charter-expenses {
  &__container {
    margin-top: 3.1rem;
  }

  &__form-currency {
    display: flex;
    justify-content: flex-end;
    align-items: center;

    &--btn {
      display: block;
      font-size: 1.4rem;
      font-weight: 500;
      line-height: 2rem;
      letter-spacing: 0.1rem;
      text-transform: uppercase;
      color: $clr-primary-and-accent;
      background-color: $light-color;
      border: 1px solid $clr-primary-and-accent;
      transition: opacity 0.3s;

      &:hover {
        @include hover-opacity(0.7);
      }
    }
  }

  &__switch-btn {
    padding: 1.4rem 1.6rem 1.5rem;
    font-size: 1.3rem;
    font-weight: bold;

    &.active {
      background-color: $clr-purple;
      color: $light-color;
    }
  }

  &__expenses-payment-box {
    display: flex;
    justify-content: space-between;
  }

  &__expenses-payment {
    width: 50%;
    margin-right: 3.2rem;

    .seaz-charter__list_payment {
      padding: 0;
    }

    .seaz-charter__list_payment_head {
      padding: 2rem 2.4rem;
    }

    .seaz-charter__list_payment_head_title {
      text-transform: none;
    }

    .seaz-charter__list_payment_main_list {
      width: 100%;
      padding: 2.4rem 2.4rem 1.2rem;
    }

    .seaz-charter__list_payment_main_list_item--net-income {
      border-bottom: none;

      .seaz-charter__list_payment_main_list_text {
        color: $dark-green;
      }

      .seaz-charter__list_payment_main_list_number {
        font-weight: bold;
      }
    }
  }

  &__profit {
    width: 50%;
    background-color: $light-color;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.04);
    border-radius: 1.6rem;

    &--title {
      font-size: 1.3rem;
      font-weight: 500;
      line-height: 1.5rem;
      color: $clr-primary-and-accent;
      padding: 2rem 2.4rem;
      border: 1px solid $seaz-bg;
    }

    &--chart {
      display: flex;
      justify-content: center;
      margin: 6.4rem 0 0;
    }
  }

  &__transaction-chart,
  &__wrap-table {
    display: block;
    background-color: $light-color;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.04);
    border-radius: 1.6rem;
    padding: 2.4rem;
  }

  &__wrap-table {
    .wrap-table__scroll {
      padding-bottom: 1rem;
    }

    .finance-search {
      margin-bottom: 3.2rem;
    }

    .financial-data__buttons {
      margin-bottom: 2rem;
    }

    .financial-data__buttons {
      justify-content: space-between;
    }

    .wrap-btn_financial-data {
      width: 100%;
    }

    .table__col-inner {
      line-height: 21px;
    }
  }

  @include laptop {
    &__expenses-payment-box {
      flex-direction: column;
    }

    &__expenses-payment,
    &__profit {
      width: 100%;
    }

    &__expenses-payment {
      margin: 0 0 3.1rem;
    }

    &__profit {
      width: 100%;

      &--chart {
        margin: 1.6rem auto;
      }
    }
  }

  @include tablet {
    &__form-currency {
      flex-direction: column;
      align-items: flex-start;

      &--btn {
        width: 100%;
        margin-top: 1.6rem;
      }
    }
  }

  @include mobile {
    .seaz-charter__list_payment_main_list_number {
      margin-left: 1rem;
    }
  }
}

.seaz-charter__list-currency-toggle {
  display: flex;

  &--label {
    font-size: 1.3rem;
    color: $seaz-text;
    margin-right: 0.8rem;
  }
}
