.boat-maintenance-statistic-filter-switch {
  width: 100%;
  max-width: 56.5rem;
  min-height: 4rem;
  margin-top: 2.4rem;
}

.boat-maintenance-statistic-filter-action {
  display: flex;
  align-items: flex-end;
  margin-top: 2.4rem;

  &__range {
    width: 100%;
    max-width: 22.6rem;
  }

  &__boats {
    width: 100%;
  }

  &__container {
    display: flex;
    align-items: flex-end;
    width: 100%;
  }

  &__types {
    @include d-align-items-center;
    flex-shrink: 0;

    .fleet-chips {
      margin-top: 0;

      app-chips-checkbox-item {
        margin-top: 0;
      }
    }

    .boat-tasks-menu__toggle-checkbox {
      margin-left: 1.6rem;
    }
  }

  &__range,
  &__select,
  &__types,
  &__boats {
    margin-right: 1.6rem;
  }

  &__btn {
    min-width: 9rem;
  }

  @include laptop {
    flex-direction: column;
    align-items: flex-start;

    &__container {
      margin-top: 1.6rem;
    }

    &__range {
      width: 100%;
      max-width: initial;
    }

    &__range,
    &__select,
    &__types,
    &__boats {
      margin: 0 0 1.6rem 0;
    }
  }

  @include tablet-md {
    &__container {
      flex-direction: column;
      align-items: flex-start;
      margin-top: 0;
    }
  }
}

.fleet-maintenance-dashboard-statistic {
  .boat-maintenance-statistic-filter-action__range {
    max-width: unset;
  }
}

.fleet-maintenance-dashboard-action-list {
  @include d-align-items-center;

  .dashboard-action {
    &:not(:last-of-type) {
      margin-right: 1.2rem;
    }

    &:disabled {
      background-color: $clr-grey;
      cursor: inherit;
      border-color: $clr-grey;

      &:hover {
        opacity: 1;
      }
    }

    &__event {
      background-color: $clr-blue;
      border-color: $clr-blue;
    }

    &__expense {
      background-color: $green-grey;
      border-color: $green-grey;
    }
  }

  @include laptop {
    margin-top: 1.6rem;
  }

  @include tablet-small {
    flex-direction: column;
    width: 100%;

    .dashboard-action {
      width: 100%;

      &:not(:last-of-type) {
        margin: 0 0 1.2rem 0;
      }
    }
  }
}
