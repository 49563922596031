.contact-person-edit-form {
  margin-top: 3rem;

  .supplier-edit-form-field-container__plug {
    width: 14rem;
    flex-shrink: 0;
  }

  &__phone {
    &.form__row {
      align-items: flex-start;
    }

    .supplier-edit-form__checkbox {
      margin-top: 1.6rem;
    }
  }

  &__phone-box {
    width: 100%;
  }

  &__footer {
    @include footer-with-border-left-btn(0 0 3rem 0, 2rem 0 0);
    border: none;
  }

  &__btn {
    &:not(:last-of-type) {
      margin-right: 1.2rem;
    }
  }

  @include tablet {
    .supplier-edit-form-field-container__plug,
    .form-field--wrapper__row .form-field--label {
      width: 11rem;
      max-width: 11rem;
      flex-shrink: 0;
      margin-right: 2rem;
    }
  }

  @include mobile {
    .supplier-edit-form-field-container__plug {
      display: flex;
      margin-bottom: 0.8rem;
    }
  }
}
