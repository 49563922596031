.search-card {
  width: 100%;
  box-sizing: border-box;
  border: 2px solid rgba(53, 31, 115, 0.2);
  box-shadow: 0 16px 32px rgba(43, 28, 54, 0.12);
  border-radius: 1.6rem;
  background-color: $light-color;
  cursor: pointer;

  &__person {
    display: grid;
    grid-template-columns: 16rem 1fr;
    grid-gap: 0 2.4rem;
    padding: 0 2.4rem;
    grid-template-areas:
      'info-person about-person'
      'info-person general-info';
  }

  &__info-person {
    grid-area: info-person;
  }

  &__about-person {
    grid-area: about-person;
    border-bottom: 1px solid $seaz-bg;
    padding-bottom: 1.6rem;
  }

  &__person-status {
    font-size: 2.4rem;
    font-weight: 500;
    text-transform: uppercase;
    line-height: 1.4;
    color: $clr-primary-and-accent;
    margin-bottom: 0.4rem;
  }

  &__info {
    display: flex;
    align-items: center;
    font-size: 1.6rem;
    font-weight: 500;
    line-height: 1.4;
    color: $seaz-text;

    &--text {
      font-weight: normal;
      text-decoration: underline;
      color: $seaz-edit-hover;
    }

    &--sign {
      font-weight: normal;
      color: $clr-grey;
      margin: 0 0.4rem;
    }
  }

  &__location {
    display: inline-flex;
    margin-top: 1.2rem;
    &.clickable:hover {
      .crew-search-card__location--point {
        color: $seaz-edit-hover;
      }
    }
  }

  &__location-point {
    margin-left: 0.9rem;
    color: $seaz-text;
    transition: color 0.3s;
  }

  &__location-size {
    color: $profile-month;
  }

  &__general-info {
    grid-area: general-info;
  }

  &__footer {
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: $clr-ashy-grey;
    border-radius: 0 0 1.6rem 1.6rem;
    padding: 1.6rem 2.4rem;
    margin-top: 2.4rem;
  }

  @include laptop {
    &__person {
      grid-gap: 0 1rem;
    }
  }

  @include tablet {
    &__person {
      grid-template-areas:
        'info-person about-person'
        'info-person work-days'
        'general-info general-info';
      grid-template-columns: 8.8rem 1fr;
      grid-gap: 0 1.6rem;
      padding: 0 1.6rem;
    }

    .work-days-area {
      grid-area: work-days;
    }

    &__info {
      flex-direction: column;
      align-items: flex-start;
    }

    &__about-person {
      border-bottom: 0;
    }

    &__person-status {
      font-size: 2rem;
      margin-bottom: 1.2rem;
    }

    &__footer {
      padding: 1.6rem;
    }
  }
}
