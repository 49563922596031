.shortcut-card {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: center;
  position: relative;
  width: 18.1rem;
  height: 100%;
  min-height: 18rem;
  border-radius: 1.6rem;
  font-size: 1.6rem;
  font-weight: 500;
  text-align: center;
  color: #fff;
  box-sizing: border-box;
  padding: 1.6rem;
  align-self: stretch;

  &__disabled {
    &:after {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      border-radius: 1.6rem;
      background-color: rgba(255, 255, 255, 0.4);
    }
  }

  &__tooltip {
    position: absolute;
    top: 2rem;
    left: 2rem;
    z-index: 1;
  }

  &__info-label {
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    transform: rotate(-42deg);
    transform-origin: 8.5rem 6rem;
    background-color: #fff;
    color: $clr-green;
    font-size: 1.2rem;
    font-weight: 500;
    text-transform: uppercase;
    padding: 0.5rem 2.8rem;
  }

  &__favorite-icon {
    position: absolute;
    top: 2rem;
    right: 2rem;

    svg {
      width: 2.5rem;
      height: 2.5rem;
      fill: #fff;
    }
  }

  &__info-icon {
    svg {
      width: 4rem;
      height: 4rem;
      fill: #fff;
    }
  }

  &__description {
    display: flex;
    align-items: center;
    min-height: 6rem;
    margin-top: 1rem;
  }

  @media screen and (max-width: 1260px) {
    width: 14rem;
    height: 100%;
    min-height: 14rem;
    padding: 0.8rem;
    font-size: 1.4rem;

    &__info-icon {
      svg {
        width: 2.6rem;
        height: 2.6rem;
      }
    }

    &__description {
      min-height: 5rem;
    }
  }

  @media screen and (max-width: 1024px) {
    width: 8.8rem;
    height: 8.8rem;
    min-height: auto;
    justify-content: center;

    &__info-label {
      transform-origin: 78% 164%;
      width: 130%;
      padding: 0.5rem 0;
    }

    &__favorite-icon {
      top: 0.8rem;
      right: 0.8rem;
    }

    &__tooltip {
      top: 0.8rem;
      left: 0.8rem;

      svg {
        width: 1.8rem;
        height: 1.8rem;
      }
    }

    &__description {
      display: none;
    }
  }

  @media screen and (max-width: 768px) {
    margin: 0 auto;
  }
}

.shortcut-charter-card {
  width: 17.6rem;
  background-color: #fff;
  color: #348ece;

  @media screen and (max-width: 1260px) {
    width: 14rem;
  }

  @media screen and (max-width: 1024px) {
    width: 8.8rem;
  }
}

.shortcut-finances {
  background-color: rgba(53, 31, 115, 1);
}

.shortcut-crew {
  background-color: rgba(79, 25, 74, 1);
}

.shortcut-boat {
  background-color: rgba(70, 166, 164, 1);
}

.shortcut-charters {
  background-color: rgba(52, 142, 206, 1);
}

.shortcut-suppliers {
  background-color: rgba(61, 6, 190, 1);
}

.shortcut-maintenance {
  background-color: rgba(244, 147, 66, 1);
}

.shortcut-settings {
  border: 1px solid #351f73;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.25);

  .shortcut-card__favorite-icon {
    position: absolute;
    top: 20px;
    right: 20px;

    svg {
      width: 1.8rem;
      height: 1.8rem;
      fill: #351f73;
    }
  }

  .shortcut-card__info-icon {
    svg {
      width: 4rem;
      height: 4rem;
      fill: $clr-primary-and-accent;
    }
  }

  .shortcut-card__description {
    color: $clr-primary-and-accent;
  }

  @media screen and (max-width: 1260px) {
    .shortcut-card__info-icon {
      svg {
        width: 2.6rem;
      }
    }
  }

  @media screen and (max-width: 1024px) {
    .shortcut-card__favorite-icon {
      top: 0.8rem;
      right: 0.8rem;
    }
  }
}

.shortcut-description-on-small-device {
  display: none;
  font-size: 1.4rem;
  line-height: 2rem;
  color: #4f4f4f;
  margin-top: 0.4rem;
  text-align: center;

  @media screen and (max-width: 1024px) {
    display: block;
  }
}

.shortcut-box {
  display: block;
  width: 18.1rem;

  @media screen and (max-width: 1260px) {
    width: 14rem;
  }
  @media screen and (max-width: 1024px) {
    width: 8.8rem;
  }
}
