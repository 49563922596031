.boat-task-form {
  &.mt-30 {
    margin-top: 3rem;
  }

  .form-field--wrapper__row .form-field--label,
  .seaz-uploader__header,
  .plug-label {
    max-width: 16rem;
    min-width: 16rem;
    margin-right: 2rem;
  }

  .mb-8 {
    margin-bottom: 0.8rem;
  }

  .mb-16 {
    margin-bottom: 1.6rem;
  }

  .seaz-uploader__choose_drop,
  app-seazone-media-uploader {
    width: 100%;
  }

  .checkbox-info-label {
    margin-top: 1.2rem;
  }

  @include tablet {
    .wrapper-rounded.form-field--wrapper__row .form-field--label,
    .seaz-uploader__header,
    .plug-label {
      max-width: 13rem;
      min-width: 13rem;
      margin-right: 1rem;
    }
  }

  @include mobile {
    .form__row {
      margin-bottom: 1.6rem;
    }

    &.mt-30 {
      margin-top: 1.6rem;
    }

    .plug-label-hidden {
      display: none;
    }
  }
  &-show-in-report {
    display: flex;
    flex-direction: row-reverse;
    align-items: center;
    .form__toggle {
      margin-right: 0.8rem;
    }

    @include mobile {
      justify-content: flex-end;
    }
  }
}

.boat-task-form-services {
  background-color: $clr-ashy-grey;
  padding: 1.2rem 1.2rem 2.8rem 1.2rem;
  border-radius: 1.2rem;

  &__row {
    @include d-align-items-center;

    &--checkbox {
      margin: 2.7rem 0 1.9rem;
    }
  }

  .services-required {
    display: none;
  }

  .wrapper-rounded.form-field--wrapper__row .form-field--label,
  .plug-label {
    margin-right: 1rem;
  }

  .info-label.purple {
    background-color: rgba(53, 31, 115, 0.08);
  }

  .info-label {
    font-size: 1.4rem;
    font-weight: 400;
  }

  &__checkbox {
    .form__checkbox-label {
      width: 0;
    }

    .form__checkbox-label-text {
      padding-left: 3rem;
    }

    app-seazone-checkbox {
      @include indent-not-last-of-child(0 1.2rem 0 0);
    }
  }

  @include mobile {
    &__row {
      @include d-align-items-center;
      flex-direction: row;
      align-items: flex-start;
    }

    &__row {
      &--checkbox {
        margin: 2.7rem 0 1.1rem;
      }
    }

    .done-by-label {
      margin-bottom: 0.8rem;
    }

    .services-required {
      display: block;
    }

    .form-field--label {
      display: none !important;
    }

    &__checkbox {
      app-seazone-checkbox {
        @include indent-not-last-of-child(0 0 1.2rem 0);
      }

      .form__checkbox-label-text {
        padding-left: 0;
      }
    }
  }
}

.boat-task-form-uploader {
  .seaz-uploader {
    flex-direction: row;
  }

  .seaz-uploader__label.seaz-h4 {
    font-size: 1.4rem;
    font-weight: 400;
  }

  .yaht-edit__upload_img_box {
    margin: 1rem 2rem 0 0;
  }

  @include tablet {
    .seaz-uploader {
      flex-direction: column;
      align-items: flex-start;
    }

    .seaz-uploader__box {
      align-items: flex-start;
    }

    .seaz-uploader__header {
      display: none;
    }

    .seaz-uploader__label {
      text-align: left;
    }

    .seaz-uploader__choose {
      justify-content: flex-start;
      order: -1;
      margin-bottom: 1.6rem;
    }

    app-seazone-image-file {
      order: -1;
    }

    .seaz-uploader__video_row {
      justify-content: flex-start;
    }

    app-seazone-document-file {
      margin: 1.3rem 0 0 0;
    }
  }

  @include mobile {
    .seaz-uploader {
      margin-top: 1.6rem;
    }

    .seaz-uploader__box {
      flex-direction: column;
      align-items: flex-start;
      justify-content: flex-start;
      flex-wrap: nowrap;
    }
  }
}

.boat-task-form-chips {
  @include d-align-items-center;
  flex-wrap: wrap;

  .task-type,
  .task-status-type,
  .task-priority-type {
    @include d-align-items-center;
    max-height: 4rem;
    box-sizing: border-box;
    border: 1px solid $seaz-head-arrow;
    border-radius: 4rem;
    background-color: $light-color;
    padding: 1rem 1.4rem;
    margin-top: 0.8rem;
    margin-right: 0.8rem;
    cursor: pointer;
  }

  app-seazone-icon {
    margin-right: 1rem;

    svg {
      fill: $seaz-head-arrow;
    }
  }

  .selected {
    .task-type,
    .task-status-type,
    .task-priority-type {
      box-shadow: 0 0 0 1px $seaz-mulberry;
      border-color: initial;
    }

    .task-type,
    .task-priority-type--owners {
      app-seazone-icon {
        svg {
          fill: $seaz-mulberry;
        }
      }
    }

    .task-priority-type--urgent {
      app-seazone-icon {
        svg {
          fill: $seaz-warning;
        }
      }
    }

    .task-priority-type--warranty {
      app-seazone-icon {
        svg {
          fill: $greeny-border-bg;
        }
      }
    }
  }
}

.boat-task-form-services-radio {
  app-seazone-radio-item {
    @include indent-not-last-of-type(0 0 2rem 0);
  }
}

.boat-task-form-text-area {
  .form-field__area {
    min-height: initial;
  }

  .wrapper-rounded .form-field--content {
    height: 8rem;
  }

  .wrapper-rounded.form-field--wrapper__row .form-field--label {
    justify-content: flex-start;
  }

  @include mobile {
    .form-field__area .form-field--label {
      padding-top: 0;
    }
  }
}

.boat-task-form-actions {
  @include tablet-small {
    flex-direction: column;
  }
}

.estimation-control-row {
  margin-bottom: 2.9rem;
}

.boat-task-form-btn {
  width: 100%;
  min-height: 3.2rem;
  font-size: 1.4rem;
  padding: 0.5rem 2rem;

  @include indent-not-last-of-type(0 1.6rem 0 0);

  @include tablet-small {
    @include indent-not-last-of-type(0 0 1.2rem 0);
  }

  &--selected {
    border-color: $dark-green;
    color: $dark-green;
    app-seazone-icon svg {
      fill: $dark-green;
    }
  }
}

.boat-task-form-selected {
  &__option {
    @include d-align-items-center;
    font-size: 1.4rem;
  }

  &__option-img {
    display: block;
    width: 2.4rem;
    height: 2.4rem;
    border-radius: 50%;
    margin-right: 0.8rem;
  }

  .select-html-container {
    @include d-align-items-center;

    img {
      display: block;
      width: 2.4rem;
      height: 2.4rem;
      border-radius: 50%;
      margin-right: 0.8rem;
    }
  }

  .form-field--content {
    padding: 0.4rem 1.2rem;
  }
}

.boat-task-form-tag-container {
  display: flex;
  flex-wrap: wrap;
}

.related-label {
  @include d-align-items-center;

  .form__checkbox-label-text {
    font-size: $base-font-size;
    font-weight: 400;
    color: $seaz-text;
    padding-left: 1.1rem;
  }

  @include mobile {
    &.plug-label {
      width: 100%;
      min-width: initial;
      max-width: initial;
      margin-bottom: 0.8rem;
    }

    .form__checkbox-label {
      width: auto;
    }

    .form__checkbox-label-text {
      padding-left: 0;
    }
  }
}

.boat-task-form-tag,
.boat-task-form-bread-crumbs-tag {
  @include d-align-items-center;
  background-color: $clr-ashy-grey;
  border-radius: 1.8rem;
  padding: 0.6rem 1.2rem;
  font-size: 1.6rem;
  color: $seaz-text;
  word-break: break-word;
  margin: 0.4rem 1.2rem 0 0;
}

.boat-task-form-bread-crumbs-tag {
  width: fit-content;
  font-size: 1.2rem;
  color: $seaz-about-text;
  line-height: 120%;
  border-radius: 2.4rem;
  padding: 0.5rem 0.8rem;

  app-seazone-icon {
    margin-left: 1.3rem;
  }
}

.boat-task-form-bread-crumbs-tag-actions {
  @include d-align-items-center;

  app-seazone-icon {
    margin-left: 0;
  }

  &__icon {
    app-seazone-icon {
      margin-left: 0.8rem;
    }
  }
}

.boat-task-form-tag-remove {
  margin-left: 0.6rem;
}

.boat-task-form-footer {
  @include footer-with-border-left-btn(0 0 2rem 0, 0);
  justify-content: space-between;
  border: 0;

  &__actions {
    display: flex;
    flex: 1 auto;
    justify-content: flex-end;
  }

  &__btn {
    @include indent-not-last-of-type(0 1.2rem 0 0);

    &--delete {
      padding: 0.7rem 2rem;
    }
  }
}

.maintenance-task-dates-form {
  margin-top: 3rem;
}

.boat-task-form-progress {
  margin-bottom: 2.4rem;
}

.boat-tasks-repeat-form {
  margin-top: 3rem;

  &__input-every {
    width: 100%;
    max-width: 11.6rem;
    margin-right: 1.6rem;
  }

  .data-controls-radio-item {
    margin-top: 2.7rem;
  }

  &__section {
    border-bottom: 1px solid $seaz-head-arrow;
    padding-bottom: 3rem;
    margin-bottom: 3rem;
  }

  .boat-task-form-footer {
    margin-top: 3rem;
  }

  @media screen and (max-width: 650px) {
    .form-field--wrapper__row .form-field--label {
      max-width: 14rem;
    }
  }

  @include tablet-small {
    .form__row {
      flex-direction: column;
      align-items: flex-start;
    }

    .form-field--wrapper__row {
      flex-direction: column;
    }

    .form-field--wrapper__row .form-field--label,
    .plug-label {
      margin: 0 0 0.8rem;
    }

    &__date-control {
      .form-field--content {
        width: 100%;
      }
    }
  }

  .status-task-hours-info {
    margin-top: 2rem;
  }
}

.input-data-controls {
  @include d-align-items-center;
  width: 100%;
}

.data-controls-radio {
  app-date-control .form-field--label {
    display: none;
  }

  .boat-tasks-repeat-form__date-control {
    margin-left: 2.4rem;
  }

  &__task {
    width: 100%;

    .seazone-radio-group__wrapper,
    .seazone-radio-group__items,
    app-seazone-radio-button,
    .seazone-radio-button__input--label,
    .seazone-radio-button__wrapper {
      width: 100%;
    }

    .seazone-radio-group__label {
      width: 14rem;
      min-width: 14rem;
      max-width: 14rem;
    }
  }

  @include tablet-small {
    .seazone-radio-group__wrapper {
      flex-direction: column;
    }
  }
}

.section-title {
  font-size: 2rem;
  font-weight: 500;
  line-height: 120%;
  color: $seaz-text;
  margin-bottom: 2rem;
}

.boat-tasks-repeat-form-running-hours {
  display: flex;
  flex-direction: column;
  &__hint-wrapper {
    display: flex;
    min-height: 3.6rem;
    flex-direction: column;
    width: 100%;
  }
  &__hint {
    display: inline-flex;
    justify-content: flex-end;
    width: 100%;
    text-align: end;
    font-size: 1.2rem;
    color: $clr-light-grey;
  }
}

.choose-tasks {
  margin-top: 5.8rem;

  &__title {
    margin-bottom: 3rem;

    &.seaz-title-h4 {
      color: $seaz-text;
    }
  }

  .seazone-radio-group__label {
    display: none;
  }

  @include mobile {
    margin-top: 3rem;

    &__title {
      margin-bottom: 1.8rem;
    }
  }
}

.task-form-info-label {
  font-size: $base-font-size;
  font-weight: 500;
  line-height: 1.6rem;
  color: $clr-purple;
  background-color: rgba(53, 31, 115, 0.08);
  border-radius: 0.4rem;
  margin-top: 2.6rem;
  padding: 1.2rem;

  &__text {
    &--bottom {
      margin-top: 1.6rem;
    }
  }

  @include mobile {
    margin-top: 1.6rem;
  }
}

.task-form-info-label-list {
  list-style-type: disc;
  padding-left: 1.6rem;
}

.estimation-control-row {
  @include mobile-big {
    margin-bottom: 3rem;
  }
}

.estimation-control-box {
  width: 50%;
  @include indent-not-last-of-child(0 1.6rem 0 0);

  @include mobile {
    width: 100%;
    @include indent-not-last-of-child(0 0 1.6rem 0);
  }
}

.task-visibility-icon {
  @include d-align-items-center;
  margin-right: 0.7rem;
}

.boat-task-repeat-info-modal {
  p:not(:last-child),
  .seaz-title-h3 {
    margin-bottom: 0.8rem;
  }
  @include mobile {
    .boat-task-form-footer__actions {
      flex-direction: column;
    }
    .boat-task-form-footer__btn:not(:last-of-type) {
      margin: 0 0 1.2rem 0;
    }
    .boat-task-repeat-info-modal-content {
      @include custom-scroll-bar;
      max-height: calc(100vh - 30rem);
      overflow: auto;
    }
  }
  .boat-task-form-footer {
    margin-top: 3rem;
  }
}
