.download-notification {
  &__title {
    margin-bottom: 3rem;
    color: $clr-primary-and-accent;
    font-size: 2.4rem;
    font-weight: bold;
    @include tablet {
      font-size: 1.8rem;
      padding-right: 2rem;
    }
  }
  &__content {
    margin-bottom: 3rem;
  }
  &__text {
    font-size: 1.6rem;
    line-height: 1.9rem;
    color: $seaz-about-text;
    &:not(:last-child) {
      margin-bottom: 1.6rem;
    }
  }
  &__controls {
    border-top: 1px solid $clr-grey;
    padding-top: 3rem;
    display: flex;
    justify-content: flex-end;
    button:not(:last-child) {
      margin-right: 1.6rem;
    }
  }
}
