.user-download-notification {
  .modal__wrap {
    padding: 3.2rem;
    overflow: hidden;
  }

  app-overlay {
    transform: translateY(100%);
  }
  &__wrapper {
    position: relative;
    width: 59.2rem;
    min-height: 18.6rem;
  }
  &__item {
    position: absolute;
    background-color: $clr-white;
    padding: 2.4rem;
    box-sizing: border-box;
    border-radius: 12px;
    width: 100%;
    height: 100%;

    &:first-child {
      box-shadow: 0 16px 32px rgba(43, 28, 54, 0.12);
    }
  }

  &__header {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  &__title {
    font-size: 2rem;
    font-weight: 500;
    line-height: 1.4;
    color: $clr-primary-and-accent;
  }

  &__close-btn {
    app-seazone-icon {
      transition: transform 0.3s;
    }

    &:hover {
      app-seazone-icon {
        transform: rotateZ(90deg);
      }
    }
  }

  &__text {
    font-size: 1.6rem;
    color: $seaz-dark-color-text;
    margin-top: 0.8rem;
  }

  &__footer {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    margin-top: 0.8rem;
  }

  &__download-btn {
    font-size: 1.4rem;
    font-weight: 500;
    letter-spacing: 0.1em;
    text-transform: uppercase;
    color: $light-color;
    background-color: $clr-primary-and-accent;
    border-radius: 0.4rem;
    padding: 1rem 2rem;
    margin-bottom: 1.2rem;
  }

  &__link {
    text-decoration: underline;
    color: $seaz-edit-hover;
  }

  &__download-btn,
  &__link {
    width: max-content;
    transition: opacity 0.3s;

    &:hover {
      opacity: 0.6;
    }
  }

  @include tablet {
    width: 100%;

    .modal__wrap {
      padding: 1.2rem;
    }

    &__wrapper {
      width: 100%;
    }

    &__item {
      padding: 1.2rem;
    }

    &__title {
      font-size: 1.6rem;
      margin-right: 1rem;
    }

    &__text {
      font-size: 1.4rem;
    }
  }
}
