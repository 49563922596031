@import '@angular/cdk/overlay-prebuilt.css';
@import 'scss/main';

// TODO: refactor
.contract-wrapper {
  display: block;
  &:not(:last-child) {
    border-bottom: 0.1rem solid rgba(53, 31, 111, 0.2);
    padding-bottom: 1rem;
  }
  &:not(:first-child) {
    padding-top: 1rem;
  }
}

.spending-table,
.comparison-table {
  position: relative;
}

html {
  .cdk-global-scrollblock {
    top: 0 !important;
  }
}

.search-cluster__wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 58px;
  height: 58px;
  border-radius: 50%;
  cursor: pointer;
  background-position: center;
  background-size: contain;
}

.search-cluster {
  display: flex;
  align-items: center;
  font-family: 'Roboto', sans-serif;
  font-weight: 500;
  text-align: center;
  font-size: 16px;
  line-height: 20px;
  color: rgba(255, 255, 255, 0.9);
}

.search-current-location {
  &__button {
    background-color: #fff;
    border: none;
    outline: none;
    width: 28px;
    height: 28px;
    border-radius: 2px;
    box-shadow: '0 1px 4px rgba(0,0,0,0.3)';
    cursor: pointer;
    margin-right: 7px;
    padding: 0;
  }
  &__icon {
    background: url('/assets/img/current-location-icon.svg') no-repeat center;
    margin: 3px;
    width: 22px;
    height: 22px;
    display: inline-block;
  }
}

// TODO: Remove after charter-download-crew-guest layout add
.charter-download-crew-guest-modal {
  app-overlay {
    max-width: 70rem;
  }
}

.boat-maintenance-tasks-actions {
  width: 100%;
}
.leaflet-tooltip-own {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 100%;
  display: flex;
  align-items: center;
  letter-spacing: 0.01em;
  color: #828282;
  padding: 8px;
}
