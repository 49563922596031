app-stash-items {
  display: flex;
}

.stash-items-wrapper {
  display: flex;
  align-items: center;
  max-width: 100%;
  position: relative;
  padding-right: 2.4rem;
}

.stash-items {
  display: flex;
  flex-wrap: wrap;
  position: relative;
  overflow: hidden;
  height: 5.2rem;
}

.stash-items-overflow-count {
  color: #432791;
  font-weight: 500;
  font-size: 20px;
  line-height: 120%;
  position: absolute;
  cursor: pointer;
}
