.form {
  width: 100%;

  .single-checkbox.hidden {
    display: none;
  }

  &__block {
    position: relative;
    display: flex;
    flex-direction: column;
    width: 100%;

    &.form__block--row {
      flex-direction: row;

      &.seazone-form-field--wrapper {
        @include mobile {
          flex-direction: column;
        }
      }
    }

    &-text {
      display: block;
      font: 1.2rem/2.4rem $base-font;
      text-align: left;
      color: $clr-purple;

      & > span {
        white-space: nowrap;
      }

      @include mobile {
        font-size: 1.1rem;
      }
    }

    &-error {
      width: 100%;
      position: absolute;
      font: 1.2rem/1.4rem $base-font;
      text-align: left;
      color: #ff1f3a;
      margin-bottom: -2.4rem;
      @include mobile {
        font-size: 1.1rem;
      }
    }

    &-link {
      position: absolute;
      top: 0.4rem;
      right: 0;
      font-weight: bold;
      text-transform: uppercase;
    }

    .dropdown-arrow {
      //background-image: url('/assets/img/select-arrow.svg');
      width: 2.4rem;
      height: 2.4rem;
      position: absolute;
      right: 0.8rem;
      top: 50%;
      display: flex;
      justify-content: center;
      align-items: center;
      font-size: 1.1rem;

      &_position {
        position: inherit;
        right: auto;
      }
    }

    &_date {
      width: 16.4rem;
    }

    &-prefix {
      position: absolute;
      font-size: 1.6rem;
      top: 3.9rem;
      color: #4d4e67;
      z-index: 2;
      font-family: 'Roboto';
      left: 1.2rem;
      @include mobile {
        top: 3.5rem;
      }
    }
  }

  &__row {
    display: flex;
    margin-bottom: 2.4rem;
    position: relative;
    @include mobile {
      flex-direction: column;
    }

    &_external {
      flex-direction: column;
    }

    &_hor {
      @include mobile {
        flex-direction: row;
      }
    }

    @include tablet-big {
      &_general {
        flex-direction: column;
      }
    }

    &_wrap {
      flex-wrap: wrap;

      > .form__block {
        margin-top: 2.4rem;
      }
    }

    &_filter-wrap {
      overflow: auto;
      justify-content: space-between;
      @include large {
        flex-wrap: wrap;
      }
    }

    &:last-child {
      margin-bottom: 0;
    }

    &_hidden {
      visibility: hidden;
      height: 0;
      margin: 0;
      //@include large {
      //  //display: none;
      //  visibility: hidden;
      //
      //}
    }
  }

  &__col {
    width: 50%;
    @include mobile {
      width: 100%;
      margin-right: 0;
      margin-bottom: 2.4rem;
      &:last-child {
        margin-bottom: 0;
      }
      &:empty {
        margin: 0;

        &:last-child {
          margin-top: -2.4rem;
        }
      }
    }

    &-left {
      width: 50%;
      margin-right: 8rem;
      @include desktop {
        margin-right: 4rem;
      }
      @include tablet-big {
        width: 100%;
        margin: 0;
      }
    }

    &-right {
      width: 50%;
      @include tablet-big {
        width: 100%;
        margin-top: 2.4rem;
        &:empty {
          margin-top: 0;
        }
      }
    }

    &:not(:last-child) {
      margin-right: 1rem;
      @include mobile {
        margin-right: 1.6rem;
      }
    }

    &_date {
      display: flex;

      .form__checkbox {
        align-items: center;
        margin: 2.3rem 0 0 2rem;

        &-input:checked + label::before {
          background-color: #46a6a4;
          border: 0.2rem solid #46a6a4;
        }
      }
    }

    &--dashboard {
      @include mobile {
        margin-bottom: 0;
      }
    }
  }

  &__union {
    position: relative;
    width: 100%;
    margin-right: 1rem;

    .form__block:not(:last-child) {
      margin-bottom: 2.4rem;
    }

    &:before {
      content: '';
      position: absolute;
      right: -0.8rem;
      bottom: 2.4rem;
      width: 100%;
      height: calc(100% - 7.3rem);
      border-right: 0.1rem solid #a9a9a9;
      border-top: 0.1rem solid #a9a9a9;
      border-bottom: 0.1rem solid #a9a9a9;
    }
  }

  &__additional {
    &-fields {
      position: relative;
      padding-left: 1rem;
      margin-left: 4rem;
      max-width: 100%;
      width: auto;
      margin-bottom: 2.4rem;

      &:before {
        content: '';
        position: absolute;
        height: calc(100% - 2.4rem);
        left: 0;
        bottom: 0;
        border-radius: 3rem;
        border-left: 0.3rem solid #786de4;
      }

      @include mobile {
        width: auto;
      }
    }

    &-item:not(:last-child) {
      margin-bottom: 2.4rem;
    }
  }

  &__document {
    width: 100%;
    border-bottom: 0.1rem solid #a9a9a9;

    &-title {
      position: relative;
      display: flex;
      align-items: center;
      font-weight: 500;
      font-size: 1.8rem;
      margin-bottom: 2rem;
    }

    &-notice {
      margin-right: 1rem;
    }

    &-wrap {
      display: flex;
      margin-bottom: 1.2rem;
    }

    &-img {
      display: inherit;
      margin-right: 1.2rem;
    }

    &-btn {
      padding: 0.9rem 4.2rem;
      margin: 0 0 2.4rem auto;

      .icon-upload-doc {
        margin-right: 2rem;
      }
    }

    &-icons {
      display: flex;
      align-items: center;
      margin-left: auto;

      &-btn {
        display: inline-flex;
        cursor: pointer;

        .icon-file-delete {
          color: #e36f84;
        }

        i {
          font-size: 2rem;
        }

        &:not(:last-child) {
          margin-right: 3rem;
        }
      }
    }

    &-name {
      margin-bottom: 0.3rem;
    }

    &-date {
      font-size: 1.1rem;
      color: #a9a9a9;
    }
  }

  &__desc {
    text-align: left;
    color: $clr-purple;

    &-text {
      font-size: 1.6rem;
      @include mobile {
        font-size: 1.4rem;
      }
    }

    a {
      position: relative;
      z-index: 10;
      font-weight: bold;
      font-size: 1.8rem;
      color: $clr-purple;
      margin-left: 0.5rem;
      @include mobile {
        font-size: 1.4rem;
      }
    }
  }

  &__wrap-input {
    position: relative;
  }

  &__input {
    position: relative;
    height: 4.8rem;
    font: 1.6rem/2.8rem $base-font;
    width: 100%;
    padding: 0.8rem 3.2rem 0.8rem 1.2rem;
    box-sizing: border-box;
    @include mobile {
      height: 4rem;
      font-size: 1.4rem;
      line-height: 2.2rem;
    }

    &::placeholder {
      font: 1.6rem/2.8rem $base-font;
      color: #a9a9a9;
      @include mobile {
        font-size: 1.4rem;
        line-height: 2.2rem;
      }
    }

    &:disabled {
      background-color: #e7e7eb;
      cursor: default;
    }

    &_prefix {
      padding: 0.8rem 3.2rem 0.8rem 2.6rem;
    }
  }

  &__textarea {
    min-height: 4.8rem;
    padding: 0.8rem 3.2rem 0.8rem 1.2rem;
    width: 100%;
    font: 1.6rem/2.8rem $base-font;
    box-sizing: border-box;
    @include mobile {
      font-size: 1.4rem;
      line-height: 2.2rem;
    }

    &::placeholder {
      font: 1.6rem/2.8rem $base-font;
      color: #a9a9a9;
      @include mobile {
        font-size: 1.4rem;
        line-height: 2.2rem;
      }
    }

    &:disabled {
      background-color: #e7e7eb;
      cursor: default;
    }

    &_expense {
      min-height: 11.5rem;
    }
  }

  &__btn {
    width: 100%;
    margin-bottom: 2.4rem;

    &:last-child {
      margin-bottom: 0;
    }
  }

  &__btns_placing {
    align-self: flex-end;
    flex-shrink: 0;
    margin-left: 2rem;

    .form__btn {
      min-height: 4rem;
      min-width: 8rem;
    }
  }

  &__back {
    display: block;
    position: relative;
    font: bold 1.3rem $second-font;
    text-transform: uppercase;
    text-align: left;
    color: $clr-purple;
    padding-left: 3.6rem;
    margin-top: 3rem;

    &:before {
      content: '';
      background-image: url('/assets/img/arrow-left.svg');
      position: absolute;
      width: 1.6rem;
      height: 1.6rem;
      left: 0;
    }
  }

  &__forgot {
    display: inline-block;
    margin-left: auto;
    text-decoration: underline;
    font-size: 1.6rem;
    color: $clr-purple;
    transition: $tr_03;

    &:hover {
      color: $clr-purple_hover;
    }
  }

  &__progress {
    display: flex;
    text-align: center;
    justify-content: center;
    margin-bottom: 2.6rem;
    @include mobile {
      margin: 0 -4rem 0.6rem;
    }

    &-head {
      display: inline-block;
      font-weight: 500;
      font-size: 2rem;
      background: #fff;
      color: #46a6a4;
      border: 0.2rem solid #46a6a4;
      min-width: 3.8rem;
      padding: 0.8rem;
      border-radius: 50%;
      margin-bottom: 0.2rem;
      @include mobile {
        min-width: 2.6rem;
        height: 2.6rem;
        width: 2.6rem;
        font-size: 1rem;
        padding: 0;
        display: inline-flex;
        align-items: center;
        justify-content: center;
      }
    }

    &.hidden {
      visibility: hidden;
      height: 0;
    }

    &-item {
      position: relative;
      width: 27%;
      @include mobile {
        width: 24%;
      }

      &:not(:last-child)::after {
        content: '';
        position: absolute;
        width: calc(100% - 6rem);
        top: 1.8rem;
        height: 0.2rem;
        border-radius: 0.1rem;
        background-color: #46a6a4;
        left: 100%;
        transform: translateX(-50%);

        @include mobile {
          top: 1.2rem;
        }
      }
    }

    &-text {
      color: #46a6a4;
    }

    .active-item &-head,
    .completed-item &-head {
      color: white;
      background: #46a6a4;
      border-color: #46a6a4;
    }

    .active-item &-text,
    .completed-item &-text {
      color: #46a6a4;
    }
  }

  &__seaz-checkbox {
    font: 1.6rem/1.8rem $base-font;
    display: flex;
    text-align: left;
    flex-direction: row;
    align-items: center;
    color: $clr-purple;

    &-control {
      display: grid;
      grid-template-columns: 1fr 1fr;
      gap: 1.2rem;
      cursor: pointer;

      input[type='checkbox'] {
        background-color: transparent;
        color: transparent;
        appearance: none;
        width: 1.8rem;
        box-sizing: border-box;
        height: 1.8rem;
        border: 0.2rem solid #351f6f;
        border-radius: 0.2rem;
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 1rem;
        line-height: normal;
        transition: 0.3s;
        position: relative;
      }

      input[type='checkbox']::before {
        content: '\e820';
        font-family: 'Fontello';
        position: absolute;
        box-sizing: border-box;
        left: 0;
        top: 0;
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 1rem;
        line-height: normal;
        //transition: $tr_03;
        width: 100%;
        height: 100%;
      }

      input[type='checkbox']:checked {
        color: white;
        background-color: $clr-purple;
      }
    }
  }

  &__checkbox {
    font: 1.4rem/1.8rem $base-font;
    display: flex;
    text-align: left;
    flex-direction: row;
    //justify-content: center;
    align-items: center;
    //white-space: initial;
    color: $clr-purple;

    &-input:checked + label:before {
      color: white;
      background-color: $clr-purple;
    }

    &-input {
      display: none;

      &:disabled {
        & ~ label {
          cursor: default;

          &:before {
            border-color: rgba($clr-purple, 0.5);
          }
        }

        &:checked ~ label:before {
          background-color: rgba($clr-purple, 0.7);
        }

        & ~ span {
          color: rgba($clr-purple, 0.7);
        }
      }
    }

    &-label {
      position: relative;
      display: inline-block;
      line-height: 2.8rem;
      cursor: pointer;
      height: 1.8rem;
      width: 1.8rem;
      flex-shrink: 0;
      @include mobile {
        padding: 0 0 0 2.4rem;
        line-height: 2rem;
      }

      &:before {
        content: '\e820';
        font-family: 'Fontello';
        color: transparent;
        position: absolute;
        width: 1.8rem;
        box-sizing: border-box;
        height: 1.8rem;
        border: 0.2rem solid $clr-purple;
        border-radius: 0.2rem;
        left: 0;
        top: 0;
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 1rem;
        line-height: normal;
        transition: $tr_03;
      }

      &-text {
        font-size: 1.6rem;
        padding-left: 1.8rem;
        @include mobile {
          font-size: 1.2rem;
        }

        a {
          position: relative;
          color: $clr-purple;
          font-weight: 500;
          z-index: 10;
        }
      }
    }
  }

  &__radio {
    font: 1.4rem/1.8rem $base-font;
    display: flex;
    text-align: left;
    color: #351f6f;

    &-input {
      display: none;
    }

    &-input:checked + label:before {
      color: #351f6f;
    }

    &-input:checked + label:after {
      content: '';
      width: 10px;
      height: 10px;
      background: #351f6f;
      display: block;
      position: absolute;
      left: 4px;
      border-radius: 50%;
      top: 4px;
    }

    &-label {
      position: relative;
      display: inline-block;
      padding: 0 0 0 2.8rem;
      line-height: 2.8rem;
      cursor: pointer;
      @include mobile {
        padding: 0 0 0 2.4rem;
        line-height: 2rem;
      }

      &:before {
        content: '\E83A';
        text-align: center;
        font-family: 'Fontello';
        position: absolute;
        width: 1.8rem;
        box-sizing: border-box;
        height: 1.8rem;
        border: 0.2rem solid #351f6f;
        border-radius: 50%;
        left: 0;
        top: 0;
        font-size: 0.9rem;
        color: transparent;
        display: flex;
        align-items: center;
        justify-content: center;
        transition: $tr_03;
      }

      &-text {
        font-size: 1.6rem;
        @include mobile {
          font-size: 1.2rem;
        }

        a {
          position: relative;
          color: #351f6f;
          text-decoration: underline;
          font-weight: 500;
          z-index: 10;
        }
      }
    }
  }

  &__cash {
    display: flex;
    width: 100%;
    flex-direction: column;

    .form__row {
      width: 100%;
    }

    .form__col {
      flex-grow: 1;
    }

    &_row--amount {
      flex-wrap: wrap;
    }

    @media screen and (min-width: 480px) {
      &_col {
        &--currency,
        &--radio {
          margin-bottom: 2.4rem;
        }

        &--currency {
          &.form__col {
            margin-right: 0;
          }
        }

        &--narrow,
        &--wide,
        &--currency,
        &--radio,
        &--amount {
          width: calc(50% - 1rem);
        }
      }
    }
    @media screen and (min-width: 1200px) {
      &_row--amount {
        flex-wrap: wrap;

        .form__cash_col--currency,
        .form__cash_col--radio {
          width: calc(30% - 0.5rem);
        }

        .form__cash_col--currency.form__col {
          margin-right: 1rem;
        }

        .form__cash_col--radio {
          &.form__col {
            margin-right: 0;
          }
        }

        .form__cash_col--amount {
          margin-left: auto;
          max-width: 60%;
        }
      }
      &_col {
        &--narrow.form__col {
          width: calc(40% - 1rem);
        }

        &--wide.form__col {
          width: 60%;
        }
      }
    }

    .form__row:first-child {
      margin-right: 1rem;
      @include large {
        margin-right: 0;
      }
    }
  }

  &_pass .form__btns {
    margin-top: 3rem;
  }

  &__files {
    display: flex;
    width: 100%;
    @include mobile {
      flex-direction: column;
      .form__col {
        min-width: 25rem;

        &:not(:last-child) {
          margin: 0 0 2.4rem 0;
        }
      }
    }
  }

  &__wrap-title {
    align-items: center;
    margin-bottom: 1.5rem;
  }

  &__filter {
    width: 100%;

    &-title {
      font: bold 1.8rem/2.1rem $base-font;
      display: flex;
      cursor: pointer;

      .filter-title {
        margin-left: 1.2rem;
      }
    }

    @include tablet-big {
      .form__row_general .form__col {
        width: 100%;
        margin-right: 0;

        &:not(:last-child) {
          margin: 0 0 2.4rem 0;
        }
      }
    }

    &-union {
      display: flex;

      &:not(:last-child) {
        margin-right: 2rem;
        @include mobile {
          max-width: 95%;
        }
        @include large {
          margin: 0 3rem 2.4rem 0;
        }
        @include tablet {
          margin-right: 0;
        }
      }

      @include large {
        &:last-child {
          margin: 0 0 2.4rem 0;
        }
      }
      @include laptop {
        &:last-child {
          margin-bottom: 0;
        }
      }

      .form__col {
        position: relative;
        width: 100%;

        &:first-child::before {
          content: '';
          position: absolute;
          border-top: 0.1rem solid #a9a9a9;
          top: 65%;
          left: 50%;
          transform: translateX(-25%);
          width: 100%;
        }
      }

      .form__btns {
        align-self: flex-end;
        flex-shrink: 0;
        @include mobile {
          margin: auto;
        }
      }

      .form__btn {
        min-height: 4.8rem;
        min-width: 4.8rem;
        @include mobile {
          min-height: 4rem;
          min-width: 4rem;
        }

        .icon-update {
          font-size: 2rem;
        }
      }
    }

    &-btn {
      min-height: 2.4rem;
      min-width: 2.4rem;
      cursor: pointer;
      font-size: 0.8rem;

      i {
        color: rgba(black, 0.5);
      }
    }

    .form__col_show {
      min-width: 7.5rem;
      width: 7.5rem;
    }

    .form__col_min-width {
      min-width: 13.5rem;
    }

    .form__col_btn {
      align-self: flex-end;

      & .main-btn {
        padding-top: 0;
        padding-bottom: 0;
        line-height: 1.4rem;
      }
    }
  }

  //--------Other forms modificator--------//
  &_expense {
    padding-top: 2.8rem;
    @include mobile {
      padding-top: 1.4rem;
    }

    &__tabs {
      display: flex;
      flex-grow: 1;

      &_wrap {
        max-width: 100%;
        overflow: auto;
        display: flex;
        margin-bottom: 3.5rem;

        app-scroll-controls .controls-container {
          top: 3rem;
        }
      }

      .boat__tabs {
        margin-bottom: 0;
        width: 100%;
      }

      .tabs__item {
        max-width: none;
      }
    }
  }

  &_financial {
    margin-bottom: 2rem;
    border-top: 0.1rem solid #a9a9a9;

    .form__wrap-title {
      margin-top: 1.2rem;
      margin-bottom: 1.2rem;
    }

    .form__filter {
      //padding: 1.2rem 0 2.4rem;
      padding-bottom: 2.4rem;
      border-bottom: 0.1rem solid #a9a9a9;

      &_hidden {
        padding: 0;
        max-height: 0;
        overflow: hidden;
      }
    }
  }

  &__compare {
    position: relative;
    max-width: 23rem;
    margin: 0 0 2rem auto;
    @include mobile {
      max-width: 20rem;
      margin-bottom: 1.6rem;
    }

    .form__block .dropdown-arrow {
      transform: translateY(-50%);
    }

    .form__input {
      padding-left: 2.2rem;
      box-sizing: border-box;
    }

    .form__col {
      position: relative;
      @include mobile {
        &:not(:last-child) {
          margin-right: 0.9rem;
        }
      }

      &:before {
        content: '';
        position: absolute;
        width: 1rem;
        height: 1rem;
        z-index: 2;
        border-radius: 50%;
        left: 0.6rem;
        top: 50%;
        transform: translateY(-50%);
      }

      &:first-child::before {
        background: $clr-purple;
      }

      &:last-child::before {
        background: #46a6a4;
      }
    }

    &__hidden {
      visibility: hidden;
      height: 0;
      margin: 0;
    }
  }

  &__dashboard--row {
    flex-direction: row;
  }

  &__toggle {
    display: flex;

    &-input {
      display: none;
    }

    &-input:checked + label:after {
      background-color: #46a6a4;
      right: -1px;
      left: inherit;
    }

    &-input:checked + label:before {
      content: '';
      background-color: #b6dada;
    }

    &-label {
      position: relative;
      cursor: pointer;
      display: block;
    }

    &-label:after {
      content: '';
      width: 1.8rem;
      height: 1.8rem;
      background-color: #9b9b9b;
      display: block;
      box-shadow:
        0px 0px 2px rgba(0, 0, 0, 0.14),
        0px 2px 2px rgba(0, 0, 0, 0.12),
        0px 1px 3px rgba(0, 0, 0, 0.2);
      border-radius: 50%;
      position: absolute;
      top: -2px;
      left: -1px;
    }

    &-label:before {
      content: '';
      border: none;
      display: block;
      width: 3rem;
      height: 1.4rem;
      background-image: none;
      background-color: #b6b6b6;
      border-radius: 13px;
    }
  }
}

.expense-title {
  padding-bottom: 1rem;
}

.apa-transaction-department {
  width: 100%;
  box-sizing: border-box;

  &__container {
    box-sizing: border-box;
    border-left: 1px solid #bdbdbd;
    padding: 0 0 2rem 1.6rem;
  }

  .form-field--label span {
    color: $clr-purple;
  }

  .seaz-charter__apa_department_text {
    margin-top: 0.4rem;
  }
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
textarea:-webkit-autofill,
textarea:-webkit-autofill:hover,
textarea:-webkit-autofill:focus,
select:-webkit-autofill,
select:-webkit-autofill:hover,
select:-webkit-autofill:focus {
  -webkit-box-shadow: 0 0 0 1000px transparent inset;
  transition: background-color 5000s ease-in-out 0s;
}

input:-internal-autofill-selected {
  -webkit-box-shadow: 0 0 0 1000px transparent inset;
  transition: background-color 5000s ease-in-out 0s;
}

.seazone__form-input {
  height: 100%;
  width: 100%;
  position: relative;
  font: 1.6rem/2.8rem $base-font;
  box-sizing: border-box;
  border: none;

  &::placeholder {
    font: 1.6rem/2.8rem $base-font;
    color: #a9a9a9;
    @include mobile {
      font-size: 1.4rem;
      line-height: 2.2rem;
    }
  }

  &:disabled,
  &:read-only {
    background-color: #e7e7eb;
    cursor: default;
  }

  &:focus {
    border: none;
  }
}

span.form__block-text {
  white-space: nowrap;
}

.login .form__checkbox {
  margin-top: 1rem;
}

.upload-file__btn {
  cursor: pointer;
  position: absolute;
  z-index: 10;
  background: #ffffff;
  top: 0.6rem;
  right: 0.6rem;
  border-radius: 50%;
  min-height: 3.2rem;
  min-width: 3.2rem;

  i {
    font-size: 1.6rem;
    color: #ff1f3a;
  }
}

.file-upload {
  font: 1.4rem/1.8rem $base-font;
  @include mobile {
    font-size: 1.2rem;
  }
}

.file-upload_add .file-upload-area,
.file-upload_add .file-upload__result {
  min-height: 25rem;
}

.file-upload_expense .file-upload-area {
  padding: 2.5rem 1rem 3.2rem;

  &__text br {
    display: none;
  }
}

.holder-form {
  &__row {
    display: flex;
    align-items: flex-end;
    justify-content: flex-end;
    @include tablet {
      flex-wrap: wrap;
      justify-content: flex-start;
    }
  }

  &__col {
    margin-right: 1.2rem;
    max-width: 10.6rem;
    @include tablet {
      margin-bottom: 1.2rem;
    }

    &:last-child {
      margin-right: 0;
    }

    & .main-btn_icon-left {
      padding: 1.22rem 0.8rem 1.22rem 3.8rem;
      @include mobile {
        padding: 1.1rem 0.8rem 1.1rem 3.8rem;
      }
    }
  }

  &__head {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 0.5rem;
  }

  &__btn {
    cursor: pointer;

    &_glass {
      color: #4d4e67;
    }

    &_close {
      color: #ff5574;
    }
  }
}

// TODO: replace
.select {
  &-placeholder,
  &-value {
    font: 1.6rem/2.8rem $base-font;
    @include mobile {
      font-size: 1.4rem;
      line-height: 2.2rem;
    }
  }

  &-content {
    overflow: hidden;
    white-space: nowrap;
    width: 100%;
    min-width: 2.4rem;
  }

  &-placeholder {
    color: #a9a9a9;

    :first-letter {
      text-transform: uppercase;
    }
  }

  &-value {
    color: #000000;
    width: 18rem;
    text-align: left;
  }

  &-img-container {
    display: flex;
    width: 2.4rem;
    height: 2.4rem;
    align-items: center;
  }
}

.form__col.form__col--report-month {
  @include huge {
    min-width: calc(10% - 1rem);
    width: calc(10% - 1rem);
    max-width: calc(10% - 1rem);
    margin-right: 1rem;
  }

  @include desktop {
    min-width: 13%;
    width: 13%;
    max-width: 13%;
    margin-right: 1rem;
  }

  @include laptop {
    width: calc(50% - 1rem);
    max-width: calc(50% - 1rem);
    min-width: calc(50% - 1rem);
    margin-right: 0;
  }

  @include tablet-big {
    max-width: 100%;
    min-width: 100%;
    width: 100%;
    margin-right: 0;
    margin-bottom: 2.4rem;
  }
}

.form__filter-union.form__filter-union--amount {
  @include huge {
    width: calc(25% - 1rem);
    margin-right: 1rem;
  }

  @include desktop {
    width: 48.4%;
    margin-right: 0;
  }

  @include laptop {
    width: calc(50% - 1rem);
    margin-right: 1.2rem;
  }
  @include tablet-big {
    width: 100%;
    max-width: 100%;
    margin-right: 0;
  }
  @include mobile {
    margin-bottom: 0;
  }
}

.form__filter-union.form__filter-union--date {
  @include huge {
    width: calc(27% - 1rem);
    margin-right: 1rem;
  }

  @include desktop {
    width: 34%;
    margin-right: 1rem;
  }
  @include laptop {
    width: calc(50% - 1rem);
    margin-right: 1.2rem;
  }
  @include tablet-big {
    width: 100%;
    max-width: 100%;
    margin-right: 0;
  }
  @include mobile {
    margin-bottom: 0;
  }
}

.form__filter-union.form__filter-union--keywords {
  .form__col:first-child::before {
    border-top: 0;
  }

  @include huge {
    width: calc(18% - 1rem);
    max-width: calc(18% - 1rem);
    margin-right: 1rem;
  }

  @include desktop {
    width: 16%;
    max-width: 16%;
    margin-right: 1rem;
  }
  @include laptop {
    width: calc(23% - 1.2rem);
    max-width: calc(23% - 1.2rem);
    margin-right: 1.2rem;
  }
  @include tablet-big {
    width: 100%;
    max-width: 100%;
    margin-right: 0;

    & > .form__col:before {
      max-width: 80%;
    }
  }
  @include mobile {
    margin-bottom: 0;
  }
}

.form__col.form__col--view {
  @include huge {
    width: calc(12% - 1rem);
    max-width: calc(12% - 1rem);
    margin-right: 1rem;
  }

  @include desktop {
    width: calc(15% - 1.2rem);
    max-width: calc(15% - 1.2rem);
    margin-right: 1rem;
  }
  @include laptop {
    width: calc(15% - 2rem);
    max-width: calc(15% - 2rem);
    margin-right: 1rem;
  }
  @include tablet-big {
    max-width: calc(70% - 2rem);
    width: calc(70% - 2rem);
    margin-bottom: 2.4rem;

    &::before {
      display: none;
    }
  }
}

.form__col.form__col--show {
  @include huge {
    width: 7%;
    margin-right: 0;
  }

  @include desktop {
    width: 9%;
    margin-right: 0;
  }
  @include laptop {
    width: 10%;
    margin-right: 0;
  }

  @include tablet-big {
    width: 30%;
    margin-right: 0;
  }
}

.form__col.form__col--save {
  & button {
    height: 48px;
  }

  @media screen and (min-width: 1440px) {
    margin-top: 2.6rem;
  }

  @include huge {
    width: 112px;
    margin-bottom: 0;
    margin-left: auto;
  }
  @include laptop {
    width: calc(100% - 48px - 2rem);
    margin-bottom: 0;
  }
}

.form__filter--first {
  flex-wrap: wrap;
  flex-direction: row;
  display: flex;
  justify-content: space-between;

  &.form__filter {
    border-bottom: none;
  }
}

.form__row.form__row--filter-select {
  justify-content: flex-end;

  .form__col.form__col--filter-select {
    width: 25%;
    @include large {
      width: 33%;
    }
    @include laptop {
      width: 50%;
    }
    @include tablet {
      width: 100%;
    }
  }
}

.form__col.form__col--express,
.form__col.form__col--category,
.form__col.form__col--subcategory {
  @include huge {
    min-width: calc(14% - 1rem);
    width: calc(14% - 1rem);
    margin-bottom: 2.4rem;
    margin-right: 1rem;
  }

  @include desktop {
    min-width: calc(20% - 1.2rem);
    width: calc(20% - 1.2rem);
    margin-bottom: 2.4rem;
    margin-right: 1rem;
  }

  @include laptop {
    min-width: calc(33.1% - 1.2rem);
    width: calc(33.1% - 1.2rem);
    margin-bottom: 2.4rem;

    &:nth-of-type(3n) {
      margin-right: 0;
    }
  }

  @include tablet-big {
    min-width: calc(100%);
    width: calc(100%);
    margin-right: 0;
    margin-bottom: 2.4rem;
  }
}

.form__col.form__col--department {
  @include huge {
    min-width: calc(12% - 1rem);
    width: calc(12% - 1rem);
    margin-bottom: 2.4rem;
    margin-right: 1rem;
  }

  @include desktop {
    min-width: calc(20% - 1.2rem);
    width: calc(20% - 1.2rem);
    margin-bottom: 2.4rem;
    margin-right: 1rem;
  }

  @include laptop {
    min-width: calc(24.4% - 2rem);
    width: calc(24.4% - 2rem);
    margin-bottom: 2.4rem;
    margin-right: 1rem;
  }

  @include tablet-big {
    min-width: calc(100%);
    width: calc(100%);
    margin-right: 0;
    margin-bottom: 2.4rem;
  }
}

.form__col.form__col--payment-method {
  @include huge {
    min-width: calc(14% - 1rem);
    width: calc(14% - 1rem);
    margin-bottom: 2.4rem;
    margin-right: 1rem;
  }

  @include desktop {
    min-width: calc(20% - 2.2rem);
    width: calc(20% - 2.2rem);
    margin-bottom: 2.4rem;
    margin-right: 0;
  }

  @include laptop {
    min-width: calc(24.4% - 2rem);
    width: calc(24.4% - 2rem);
    margin-bottom: 2.4rem;
    margin-right: 1rem;
  }

  @include tablet-big {
    min-width: calc(100%);
    width: calc(100%);
    margin-right: 0;
    margin-bottom: 2.4rem;
  }
}

.form__col.form__col--crew {
  @include huge {
    min-width: calc(12% - 1rem);
    width: calc(12% - 1rem);
    margin-bottom: 2.4rem;
    margin-right: 1rem;
  }

  @include desktop {
    min-width: calc(28% - 1.2rem);
    width: calc(28% - 1.2rem);
    margin-bottom: 2.4rem;
    margin-right: 1rem;
  }

  @include laptop {
    min-width: calc(24.4% - 2rem);
    width: calc(24.4% - 2rem);
    margin-bottom: 2.4rem;
    margin-right: 1rem;
  }

  @include tablet-big {
    min-width: calc(100%);
    width: calc(100%);
    margin-right: 0;
    margin-bottom: 2.4rem;
  }
}

.form__col.form__col--checkbox-select,
.form__col.form__col--currency-select {
  width: auto;
  @include desktop {
    min-width: calc(12% - 2.2rem);
    width: calc(12% - 2.2rem);
    margin-bottom: 2.4rem;
    margin-right: 1rem;
  }

  @include laptop {
    min-width: calc(14.5% - 2rem);
    width: calc(14.5% - 2rem);
    margin-right: 1rem;
  }

  @include tablet-big {
    min-width: calc(50% - 1rem);
    width: calc(50% - 1rem);
  }
}

.form__col.form__col--checkbox-select {
  @include huge {
    margin-right: 0;
  }

  @include desktop {
    margin-right: 1rem;
  }

  @include laptop {
    margin-right: 0;
  }
}

.form__btns--reset {
  @media screen and (min-width: 1440px) {
    margin-top: 2.6rem;
  }

  .icon-update {
    @include laptop {
      font-size: 20px;
    }
  }

  height: 48px;
  width: 48px;
  display: flex;
}

.form__row.form__row--filters {
  flex-direction: row;
}

.form__col--checkbox-select .form-field__content-outline[_ngcontent-seazoneServer-c21] {
  @include mobile {
    height: 4rem;
  }
}

.cdk-overlay-container .modal-background.rotated ~ div .container-fixed {
  @include mobile {
    transform: rotate(-90deg);
  }
}

.cdk-overlay-container .modal-background.rotated ~ div .dropdown-options-container.container-fixed {
  @include mobile {
    margin-left: 0.5rem;
    margin-top: -6rem;
  }
}

.expense-split {
  &__radio {
    display: flex;
    flex-direction: column;
    height: 100%;

    &_title {
      display: block;
      font: 1.2rem/2.4rem $base-font;
      text-align: left;
      color: #351f6f;
    }

    &_form {
      display: flex;
      height: 100%;
    }

    &_item {
      display: none;
    }

    &_inner {
      display: flex;
      align-items: center;
      justify-content: center;
      margin-right: 1.4rem;
    }

    &_item + .expense-split__radio_inner {
      display: inline-flex;
      height: 2rem;
      width: 2rem;
      border: 0.2rem solid $clr-main;
      border-radius: 50%;
    }

    &_item:checked + .expense-split__radio_inner::before {
      content: '';
      display: inline-flex;
      width: 1rem;
      height: 1rem;
      background-color: $clr-green;
      border-radius: 50%;
    }

    &_item:checked + .expense-split__radio_inner {
      border-color: $clr-green;
    }

    &_label {
      display: flex;
      align-items: center;
      margin-right: 3.4rem;
      text-transform: uppercase;
      cursor: pointer;
    }
  }

  &__currency {
    &_label {
      margin-top: 2.4rem;
      display: flex;
      height: 4.8rem;
      align-items: center;
      justify-content: flex-end;
    }

    &-text {
      color: $clr-light-grey;
      font-size: 1.6rem;
      line-height: 1.9rem;
    }

    @include desktop {
      display: flex;
      flex-direction: column;
      &_label {
        justify-content: flex-start;
        height: auto;
        margin-top: 0;
      }
      .form__cash_col--wide {
        width: 100%;
      }
    }
    @include mobile {
      &_label {
        margin-bottom: 1rem;
      }
    }
  }
}

.splits__wrapper {
  height: auto;
  overflow: hidden;

  &.show {
    height: 0;
  }
}

.split-item {
  padding-top: 2.4rem;
  overflow: hidden;
}

.split-head {
  padding-bottom: 1.1rem;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;

  &__title {
    line-height: 28px;
    font-size: 2.4rem;
    font-weight: 500;
    flex-grow: 1;
    flex-shrink: 0;
    flex-basis: 50%;
  }

  &__info {
    display: flex;
    align-items: center;
    flex-grow: 1;
    flex-shrink: 0;
    justify-content: flex-end;
  }

  &__sum {
    color: $clr-green;
    font-weight: bold;
    font-size: 1.8rem;
    margin-right: 1.7rem;
    white-space: nowrap;
  }

  &__cancel {
    margin-right: 1.7rem;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  &__accordion {
    display: flex;
    background-color: #e7e7eb;
    border-radius: 50%;
    min-width: 2.4rem;
    height: 2.4rem;
    align-items: center;
    justify-content: center;
    cursor: pointer;

    img.show {
      transform: rotate(-180deg);
    }
  }
}

.payment-form {
  height: 0;

  &.show {
    height: auto;
  }
}

.form__block-error__box {
  display: inline-flex;
  width: 100%;
}

.dropdown-options-container {
  width: 100%;
  min-width: 20rem;
  max-height: 27.5rem;
  border-radius: 0.3rem;
  box-shadow: 0 0.1rem 0.4rem 0 rgba(0, 0, 0, 0.24);
  display: block;
  overflow: hidden;
  background-color: white;
}

.dropdown-container-counter {
  position: absolute;
  top: 2rem;
  right: 1rem;
  z-index: 101;
  font-size: 1.4rem;
  font-weight: 400;
  color: #010202;
}

.seazone-range-slider__inputs app-seazone-input ~ app-control-error {
  display: none;
}

.required::after {
  color: red;
  content: '*';
}

app-phone-number .seazone-form-field--wrapper .phone-control-container .form__block-text__select {
  display: none;
}

.seazone__form-icon.hidden {
  display: none;
}

.crew-contract-form {
  .form__col {
    @include mobile {
      margin-right: 0 !important;
    }
  }
}

.seaz-crew-search-all-filter {
  position: relative;
  padding-bottom: 4rem;

  .seaz-filters__form .form__block-error {
    width: 100%;
  }

  &__actions {
    display: flex;
    justify-content: flex-end;
    position: fixed;
    bottom: 0;
    right: 0;
    width: 100%;
    max-width: 73.5rem;
    padding: 1.6rem;
    background-color: #fff;

    .seaz__btn_rounded {
      font-weight: 500;
      border-radius: 0.4rem;
      padding: 1rem 2rem;
    }

    .seaz__btn_reset-all {
      align-self: center;
      font-size: 1.4rem;
      font-weight: 500;
      text-transform: none;
      line-height: 1rem;
      border: none;
      color: $seaz-edit-hover;
      margin-right: 1.6rem;
      border-bottom: 1px dashed $seaz-edit-hover;
      padding: 0 0 0.5rem 0;
      transition: opacity 0.3s;

      &:hover {
        background-color: initial;
        opacity: 0.6;
        color: $seaz-edit-hover;
      }
    }
  }
}

.seazone-form-field--wrapper {
  .form-field--content-suffix {
    .icon-seazone-select-arrow::before {
      content: '\e83b';
      font-size: 1.4rem;
      transform: rotateZ(180deg) !important;
    }
  }
}

.seaz-company-profile-form {
  @include tablet {
    .seaz-uploader,
    .seaz-uploader__box {
      align-items: flex-start;
    }

    .seaz-uploader__label {
      text-align: left;
    }

    .seaz-uploader__choose {
      justify-content: flex-start;
    }
  }
}

.radio__row-wrapper {
  display: flex;
  flex-direction: row;

  .form__block_label {
    max-width: 14rem;
    width: 100%;
    margin-right: 2rem;
    padding-top: 1.1rem;
    font: 1.4rem/2rem $base-font;
  }

  .form__radio {
    padding: 1.1rem 0;
    cursor: pointer;
  }

  .form__radio-label-text,
  .form__radio-label-text--description {
    font-size: 1.4rem;
  }

  .form__radio-label-text--description {
    color: $clr-grey;
  }
}
