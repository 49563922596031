app-seazone-colored-select,
app-seazone-colored-select-option {
  display: block;
}

.colored-select,
app-seazone-colored-select-option {
  .status-select-container,
  .select-option div {
    display: flex;
    align-items: center;
    width: max-content;
    background-color: inherit;
    padding: 0.5rem 0.8rem;
    border: none;
    border-radius: 1.8rem;
  }
}

.colored-select,
app-seazone-colored-select-option {
  .select-status-value,
  .select-option div {
    font-family: 'Roboto', sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 1.2rem;
    line-height: 120%;
    text-align: center;
    color: $light-color;
  }
}

.delete-color .select-status-value {
  color: $seaz-alarm;
}

app-seazone-colored-select-option {
  &:not(:last-of-type) {
    margin-bottom: 0.4rem;
  }
}

app-seazone-colored-select-option {
  .select-option {
    cursor: pointer;
    padding: 0;
    min-height: 0;

    &:hover:not(.not-selectable) {
      background: inherit;
    }
  }
}

.dropdown-status {
  width: fit-content;
  padding: 1.2rem;
  box-shadow: 0 10px 50px rgba(0, 0, 0, 0.25);
  border-radius: 1.2rem;
  background-color: $light-color;
}

.icon-status-arrow {
  display: flex;
  align-items: center;
  height: 1.4rem;
  margin-left: 0.6rem;
  &::before {
    content: '\e83b';
    font-size: 0.6rem;
    color: $light-color;
    transform: rotateZ(180deg) !important;
  }
}
