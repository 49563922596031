.boat-type-container {
  @include d-align-items-center;
  margin-left: 0.4rem;
}

.boat-type {
  display: inline-block;
  margin-left: 1rem;

  @include mobile {
    margin-left: 0.3rem;
  }
}

.boat-type-tooltip {
  &__text {
    font-size: 1.2rem;
    font-weight: 500;
    line-height: 100%;
    text-align: center;
    letter-spacing: 0.01em;
    color: $profile-month;
  }
}
