.seaz-budget-title {
  font-size: 4rem;
  font-weight: bold;
  color: $clr-primary-and-accent;
  margin: 4.8rem 0 6.4rem;
}

.seaz-budget-form {
  border-top: 1px solid $seaz-head-arrow;
  padding-top: 4rem;

  &__column {
    display: flex;
    align-items: center;
    width: 100%;
    margin-bottom: 2.4rem;
  }

  &__label {
    width: 100%;
    max-width: 14rem;
    margin-right: 7rem;
    color: $seaz-text;
    font-size: 1.4rem;
  }

  .seaz-charter-overview__tabs-wrap {
    display: flex;
    align-items: center;
    width: 100%;
    margin-right: 0;
  }

  .switch__btn {
    width: 50%;
  }

  &__month-range {
    width: 100%;
  }

  @include desktop {
    .form-field--wrapper.form-field--wrapper__row.seazone-form-field--wrapper .form-field--label,
    &__label {
      max-width: 5rem;
      margin-right: 3rem;
    }
  }

  @include mobile {
    &__column {
      flex-direction: column;
      align-items: flex-start;
    }

    &__label {
      margin: 0 0 0.8rem;
    }
  }
}

.seaz-budget-skip-btn {
  width: 14.3rem;
  font-weight: 500;
  font-size: 1.6rem;
  margin-bottom: 4.8rem;

  @include mobile {
    width: 100%;
    font-size: 1rem;
  }
}
