.label-badges {
  &__container {
    display: inline;

    &:not(:last-of-type) {
      margin-right: 0.8rem;
    }
  }

  &__title {
    font-size: 1.4rem;
    font-weight: 550;
    text-transform: uppercase;
    margin-right: 0.8rem;
  }

  &__label {
    display: inline-block;
    font-size: 1.2rem;
    color: $seaz-text;
    background-color: $seaz-bg;
    border-radius: 2.5rem;
    padding: 0.5rem 0.8rem;
    margin-bottom: 0.8rem;

    &:not(:last-of-type) {
      margin-right: 0.8rem;
    }
  }

  &__value {
    display: inline;
    margin-left: 0.7rem;
    word-break: break-all;
  }
}
