.fleets {
  width: 100%;
  max-width: 127rem;
  margin: 6.2rem auto 12.4rem;
  padding: 0 1.6rem;
  box-sizing: border-box;

  @include desktop {
    margin: 5.4rem auto 12.4rem;
  }

  @include tablet {
    margin: 1.6rem auto 9rem;
  }
}

.fleets-header {
  @include d-flex-space-between;
  align-items: center;
  margin-bottom: 4.8rem;

  @include tablet {
    margin-bottom: 2.4rem;
  }

  @include mobile {
    flex-direction: column;
    align-items: flex-start;

    &__btn {
      width: 100%;
    }
  }
}

.fleets-header-title {
  text-transform: uppercase;
  margin-right: 1rem;

  @include mobile {
    margin: 0 0 1.2rem;
  }
}

.fleet-card-box {
  display: block;
  cursor: pointer;
  @include indent-not-last-of-type(0 0 2.4rem 0);
}

.fleets-paginator {
  margin-top: 5.1rem;
}

.mt-indent {
  margin-top: 1.6rem;
}

.fleet-shortcuts {
  margin-top: 2rem;
}

.fleet-tasks-filters-form {
  margin-top: 4rem;
}

.fleet-tasks-filters-form-search-action {
  @include d-flex-space-between;
  align-items: center;
  gap: 1.2rem;

  @include tablet-small {
    flex-direction: column;
    align-items: flex-start;

    &__btn {
      width: 100%;
    }
  }
}

.fleet-tasks-filters-form-search {
  width: 100%;
  max-width: 51.1rem;
}

.fleet-tasks-filters-form-actions {
  background-color: $light-color;
  box-shadow: 0 2px 2px rgba(43, 28, 54, 0.16);
  border-radius: 2.4rem;
  padding: 2.4rem;
  margin-top: 2.4rem;

  @include tablet {
    padding: 1.6rem;
  }
}

.fleet-tasks-filters-form-actions-header {
  @include d-flex-space-between;
  align-items: center;
}

.fleet-tasks-filters-form-toggle {
  @include d-align-items-center;
  width: fit-content;
  font-size: 1.6rem;
  font-weight: 500;
  line-height: 1.9rem;
  gap: 0 0.8rem;

  &__btn {
    @include d-align-items-center;
    gap: 0 1rem;
    color: $seaz-mulberry;
  }
}

.fleet-tasks-filters-form-switch-box {
  @include d-align-items-center;
  gap: 0 1.4rem;
  margin: 3rem 0;

  .boat-maintenance-statistic-filter-switch {
    margin-top: 0;
  }

  @include tablet {
    flex-direction: column;
    align-items: flex-start;
    gap: 1.2rem 0;
    margin: 3.2rem 0 1.2rem 0;

    .boat-maintenance-statistic-filter-switch {
      max-width: initial;
    }

    .fleet-tasks-filters-form-date-range-picker {
      width: 100%;
    }
  }
}

.fleet-tasks-filters-form-row {
  @include d-align-items-center;

  .plug-label {
    flex-shrink: 0;
    width: auto;
    min-width: initial;
  }

  .boat-task-form-chips {
    gap: 0.8rem;
  }

  .task-status-type,
  .task-type {
    margin: 0;
  }

  @include tablet {
    flex-direction: column;
    align-items: flex-start;

    .plug-label {
      margin-bottom: 0.2rem;
    }
  }
}

.fleet-tasks-filters-form-footer-actions {
  .seaz__btn_rounded {
    margin-right: 2.4rem;
  }
}

.expired-filter-form {
  @include d-align-items-center;
  gap: 0 1rem;
  margin-left: 1rem;
}

.fleet-tasks-filters-form-chips {
  @include d-align-items-center;
  flex-wrap: wrap;
  gap: 1.2rem 6.4rem;

  .fleet-tasks-filters-form-select {
    width: 100%;
    max-width: 27rem;
  }

  @include laptop {
    flex-direction: column;
    align-items: flex-start;
    gap: 1.2rem 0;

    .fleet-tasks-filters-form-select {
      max-width: inherit;
    }
  }
}

.fleet-tasks-filters-form-chips-wrap {
  @include d-align-items-center;
  gap: 0.8rem;
  .fleet-chips {
    margin-top: 0;
  }
}

.fleet-tasks-filters-form-reset-all {
  margin-top: 3rem;

  @include tablet {
    margin-top: 2.8rem;
  }
}
