.charter-tasks-title {
  text-transform: uppercase;

  @include tablet {
    &.seaz-title-h2 {
      font-size: 3rem;
    }
  }
}

.charter-tasks-header,
.charter-tasks-filter-form {
  @include d-flex-space-between;
  align-items: center;
  gap: 1.6rem 1rem;

  @include tablet-md {
    flex-direction: column;
    align-items: flex-start;
  }
}

.charter-tasks-header {
  margin-top: 3.2rem;

  @include tablet-md {
    &__btn {
      width: 100%;
    }
  }
}

.charter-tasks-filter {
  margin-top: 2.4rem;

  .fleet-chips {
    margin-top: 0;
  }

  @include tablet-md {
    margin-top: 1.6rem;
  }
}

.charter-tasks-filter-form-search {
  width: 100%;
  max-width: 29rem;

  @include tablet-md {
    max-width: initial;
  }
}

.charter-tasks-list {
  background-color: $light-color;
  border-radius: 1.6rem;
  padding: 2.4rem;
  margin-top: 2.4rem;

  app-charter-tasks-list-item {
    @include indent-not-last-of-child(0 0 1.2rem 0);
  }
}

.charter-tasks-item {
  .charter-tasks-item-body {
    @include laptop {
      grid-template-areas:
        'status assignee'
        'system system'
        'estimated estimated';
      grid-template-columns: 1fr 1fr;
      grid-gap: 1.2rem;
    }
  }
}
