html {
  font-size: 62.5%;
  line-height: 1.5;
}

body,
html {
  height: 100%;
  display: block;
}

app-root {
  position: absolute;
  display: -webkit-box;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  flex-direction: column;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  overflow: hidden;
}

.page-wrapper {
  app-header {
    display: block;
    position: sticky;
    top: 0;
    left: 0;
    width: 100%;
    z-index: 1001;
  }
}

.main-wrap {
  display: flex;
  flex-direction: column;
  min-height: 100%;
  font:
    1.4rem/1.8rem $base-font,
    $second-font;
  margin: 0 auto;
  color: $clr-main;
  background: #fafafb;
  height: 100%;
  width: 100%;
  overflow-y: auto;
  pointer-events: none;

  * {
    box-sizing: border-box;
  }

  &_height {
    height: 100%;
  }

  &__scrollable {
    -webkit-overflow-scrolling: touch;
    overflow-y: auto;
    overflow-x: hidden;
    pointer-events: auto;

    .header {
      z-index: 1001;
    }
  }

  &__events {
    app-wrapper {
      pointer-events: auto;
    }
  }

  @include mobile {
    background: white;
    font-size: 1.2rem;
  }
}

app-wrapper {
  display: contents;
}

.main-title {
  background-image: url('/assets/img/bg_user_info.svg');
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
  padding: 5.8rem 0 2.2rem;
  @include tablet {
    padding: 3.8rem 0 2.2rem;
  }
  @include mobile {
    padding: 3.2rem 0 1rem;
  }

  &-profile {
    padding: 0 5rem;
    @include laptop {
      padding: 0;
    }
  }
}

.my-profile-wrap {
  padding: 0 5rem;
  @include laptop {
    padding: 0;
  }
}

.main-content {
  flex: 1 0 auto;
  min-height: 71.5vh;
  // margin-top: 4.8rem;

  &:before {
    content: '';
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background-color: rgba($color: #000, $alpha: 0.5);
    z-index: 99998;
    opacity: 0;
    visibility: hidden;
    transition: all 0.5s ease-in 0s;
  }
}

.container {
  position: relative;
  margin: 0 auto;
  padding: 0 8rem;

  @media screen and (max-width: 1544px) {
    padding: 0 2rem;
  }

  @include desktop {
    padding: 0 4rem;
  }
  @include tablet {
    padding: 0 2rem;
  }
  @include mobile {
    padding: 0 1rem;
  }
}

//---------------------------main styles----------------------//
.title-h1 {
  font: 500 3.4rem/4rem $base-font;

  &.warning {
    color: #ff5574;
  }

  &_white {
    color: #fff;
  }

  @include mobile {
    font-size: 2.4rem;
    line-height: 2.8rem;
  }
}

.title-h2 {
  font: 500 3rem/5.3rem $base-font;
  @include tablet {
    font-size: 1.8rem;
    line-height: 2.1rem;
  }
}

.title-h3 {
  font: bold 2.4rem/2.8rem $base-font;
  color: $clr-purple;
  display: inline-flex;
  align-items: center;
  gap: 1rem;
  @include tablet {
    font-size: 1.4rem;
    line-height: 1.8rem;
  }
  @include mobile {
    font-size: 1.3rem;
  }
}

.title-h4 {
  font: 500 2rem/2.3rem $base-font;

  &_opacity {
    opacity: 0.5;
  }

  @include mobile {
    font-size: 1.5rem;
  }
}

.title-h5 {
  font: 500 1.8rem/2.1rem $base-font;
  @include tablet {
    font-size: 1.4rem;
    line-height: 1.8rem;
  }
  @include mobile {
    font-size: 1.2rem;
  }
}

.title-h6 {
  font: 400 1.6rem/2.2rem $base-font;
  @include tablet {
    font-size: 1.4rem;
    line-height: 1.8rem;
  }
  @include mobile {
    font-size: 1.2rem;
  }
}

.link {
  color: #7d42d2;
}

input,
textarea {
  border: 0.1rem solid #a9a9a9;
  background-color: white;
  border-radius: 0.2rem;
  resize: none;

  &:focus {
    border: 0.1rem solid #7d42d2;
  }

  &.ng-invalid.ng-dirty {
    border: 0.1rem solid red;
  }
}

.text-error,
.text-red {
  color: #ff1f3a;
}

.text-dark-red {
  color: #d70000;
}

.text-gray {
  color: #a9a9a9;
}

.text-purple-dark {
  color: $clr-purple;
}

.wrap-btn_financial-data {
  text-align: right;

  @media screen and (max-width: 1024px) {
    margin-bottom: 2rem;
    width: 49%;
  }

  @media screen and (max-width: 600px) {
    width: 100%;
  }

  &:not(:last-child) {
    margin-right: 1.2rem;

    @media screen and (max-width: 1024px) {
      margin-right: 0;
    }
  }
}

.text-green {
  color: #46a6a4 !important;
}

.text-opacity_9 {
  opacity: 0.9;
}

.text-left {
  text-align: left;
}

.text-right {
  text-align: right;
}

.text-center {
  text-align: center;
}

.text-nowrap {
  white-space: nowrap;
}

.text-wrap {
  white-space: normal;
}

.text-bold {
  font-weight: bold;
}

.text-normal {
  font-weight: 400;
}

.text-medium {
  font-weight: 500;
}

.text-up {
  text-transform: uppercase;
}

.width {
  &_80 {
    width: 80% !important;
  }

  &_85 {
    width: 85% !important;
  }
}

.bckg-table-col {
  background: #fafafb;
}

.bckg-col-details {
  background: #e7e7eb;
}

.hidden {
  display: none;
}

.transparent {
  &_03 {
    opacity: 0.3;
  }
}

.flex-center {
  display: flex;
  align-items: center;
  justify-content: center;
}

.sort-down {
  &:before {
    content: '\e82a';
    transform: rotate(0deg);
  }
}

.details-open i {
  transform: rotate(-180deg);
}

.details-hidden i {
  transform: rotate(0deg);
}

.filter-hidden {
  transform: rotate(-180deg);
}

.filter-open {
  transform: rotate(0);
}

.wrap-file {
  display: inline-block;
  position: relative;
  margin-bottom: 2.4rem;

  .form__document-btn {
    margin-bottom: 0;
  }
}

.wrap-table {
  position: relative;
  z-index: 2;

  &__scroll {
    overflow: auto;
    overflow-y: hidden;
  }

  &_crew {
    margin-top: 1rem;
  }
}

.notice {
  color: #ff1f3a;

  &__block {
    display: inline-flex;
    cursor: pointer;
    font-size: 2rem;

    &:hover .notice__text {
      opacity: 1;
      visibility: visible;
    }

    & .icon-notification {
      color: #ff5574;
    }

    @include tablet {
      font-size: 1.5rem;
    }
    @include mobile {
      font-size: 1rem;
    }
  }

  &__text {
    opacity: 0;
    visibility: hidden;
    font-weight: normal;
    position: absolute;
    border-radius: 0.8rem;
    background-color: #ffe1e7;
    box-shadow: 0 0.2rem 0.8rem rgba(0, 0, 0, 0.1);
    padding: 0.9rem 0.8rem;
    font-size: 1.2rem;
    line-height: normal;
    color: #ff1f3a;
    top: -3.9rem;
    left: -0.5rem;
    z-index: 1;
    white-space: nowrap;

    &:before {
      content: '';
      position: absolute;
      width: 1.4rem;
      height: 1.4rem;
      background: #ffe1e7;
      bottom: -0.6rem;
      left: 0.8rem;
      transform: rotate(-45deg);
    }

    @include tablet {
      left: -0.6rem;
    }
    @include mobile {
      padding: 0.5rem;
      top: -2.5rem;
      &:before {
        width: 0.7rem;
        height: 0.7rem;
        bottom: -0.4rem;
      }
    }
  }
}

.add-image {
  height: 4rem;
  width: 4rem;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  border: 0.1rem solid #e7e7eb;
  background-color: #fff;
  box-shadow: 0 0.2rem 0.5rem rgba(0, 0, 0, 0.04);
  color: $clr-purple;
  cursor: pointer;
  font-size: 2.1rem;
  transition: $tr_03;

  &:hover {
    background-color: #f5f4f8;
  }

  &:focus,
  &:active {
    background-color: #f5f4f8;
  }
}

.visually-hidden {
  position: absolute;
  opacity: 0;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  cursor: pointer;
  overflow: hidden;
}

.hide_tablet {
  @include tablet {
    display: none !important;
  }
}

.show_tablet {
  display: none !important;
  @include tablet {
    display: block !important;
  }
}

.circle-icon {
  display: inline-block;
  width: 3rem;
  height: 3rem;
  min-width: 3rem;
  min-height: 3rem;
  box-shadow: 0 0.2rem 0.2rem rgba(9, 189, 186, 0.1);
  text-align: center;
  border-radius: 50%;
  vertical-align: 0.5rem;

  i {
    vertical-align: -0.6rem;
  }

  &_green {
    background-color: #46a6a4;
  }

  &_red {
    background-color: #ff5574;
  }

  @include tablet {
    width: 2rem;
    height: 2rem;
    min-width: 2rem;
    min-height: 2rem;
    i {
      vertical-align: -0.3rem;
    }
  }
}

.non-bg-icon {
  display: inline-block;

  & > i {
    font-size: 1.7rem;
  }

  &_green > i {
    color: #46a6a4;
  }
}

.avatar {
  position: relative;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  border: 0.15rem solid #ffffff;
  box-sizing: content-box;

  &__img {
    display: block;
    border-radius: 50%;
    width: inherit;
    height: inherit;
    object-fit: cover;

    img {
      width: 100%;
      height: 100%;

      &[src=''],
      &[src='null'] {
        display: none;
      }
    }

    &[src=''],
    &[src='null'] {
      display: none;
    }
  }

  &_num {
    border: none;
    background: $clr-purple;
    color: white;
  }
}

.tabs {
  &__header {
    &-wrap {
      display: flex;
      justify-content: space-between;
      align-items: center;
      @include tablet-small {
        flex-direction: column;
        align-items: baseline;

        .main-btn {
          margin-top: 1rem;
        }
      }
    }
  }

  &__body {
    position: relative;
  }

  &__item-content {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    z-index: -1;
    bottom: 0;
    opacity: 0;

    &.active {
      z-index: 1;
      position: relative;
      opacity: 1;
    }
  }
}

.switch {
  display: flex;
  border-radius: 0.9rem;
  background-color: rgba(#e7e7eb, 0.5);
  padding: 0.3rem;

  &__btn {
    padding: 0.7rem 0.8rem;
    cursor: pointer;
    min-height: 3.3rem;
    min-width: 3.3rem;
    flex-grow: 1;
    border-radius: 0.7rem;
    transition: $tr_03;
    color: #351f6f;

    &-icon {
      font-size: 1.7rem;
    }

    &:hover {
      background-color: #e7e7eb;
    }

    &:not(:last-child) {
      margin-right: 0.3rem;
    }

    &_active,
    &_active:hover {
      background-color: $clr-purple;

      .switch__btn-icon {
        color: white;
      }

      .switch__btn-text {
        color: white;
      }
    }
  }

  &_width {
    min-width: 26rem;
    @include mobile {
      min-width: 12rem;
    }
  }

  &_hidden {
    display: none;
    @include mobile {
      display: block;
    }
  }

  &_huge {
    & .switch__btn {
      padding: 1.32rem 1.29rem;
      @include mobile {
        padding: 1.12rem 1.29rem;
      }
    }
  }
}

.wrap-title {
  display: flex;
  justify-content: space-between;

  &__col {
    max-width: 50%;

    @include laptop {
      &:first-child {
        width: 40%;
      }
      &:last-child {
        width: 100%;
        max-width: 100%;
      }
    }
    @include tablet-big {
      max-width: 100%;
      width: 100% !important;
    }
  }
}

.financial-report {
  &.wrap-title {
    align-items: flex-end;
    flex-wrap: wrap;
    justify-content: space-between;
    position: relative;
    gap: 2.4rem;

    &.boat__wrap-title {
      margin-bottom: 0;
      @include mobile {
        margin-bottom: 1rem;
      }
    }

    @include laptop {
      flex-direction: column;
      align-items: flex-start;
    }
  }

  & .wrap-title__col {
    max-width: none;
    @include laptop {
      width: auto;
    }
  }

  &__filter {
    .switch__btn {
      display: flex;
      justify-content: center;
      gap: 0.6rem;
    }

    .custom-period-row {
      display: flex;
      align-items: flex-end;

      .form__col {
        flex: 1 1 auto;
      }

      @include tablet {
        gap: 1.2rem;
      }
    }

    .form__row_down {
      align-items: flex-end;
    }

    @include tablet {
      .form__row_down {
        flex-direction: column;

        .form__col {
          width: 100%;
          margin: 0;
        }
      }
    }
  }

  .main-btn {
    padding: 1.2rem 2.4rem;
    @include tablet {
      margin-top: 1.2rem;
    }
  }
}

.wrap-btns {
  display: flex;
}

.nav-panel {
  display: flex;
  align-items: center;
  justify-content: center;

  &__list {
    display: flex;
    margin: 0 1.2rem;
    @include mobile {
      margin: 0 0.5rem;
    }

    .active-item .nav-panel__link {
      color: white;
      background: $clr-main;
      border-radius: 50%;
    }
  }

  &__btn {
    display: flex;
    background: white;
    border-radius: 50%;
    padding: 0.9rem 1.1rem;
    transition: $tr_03;

    & path {
      transition: $tr_03;
    }

    &:hover {
      background: $clr-main;

      path {
        stroke: white;
        opacity: 1;
      }
    }
  }

  &__link {
    display: inline-block;
    height: 3rem;
    line-height: 3rem;

    text-align: center;
    font-size: 1.6rem;
    color: $clr-main;
    padding: 0 1.1rem;
    border-radius: 50%;
    transition: $tr_03;

    &:hover {
      color: white;
      background: $clr-main;
    }
  }

  &__item {
    margin-right: 1rem;
    @include mobile {
      margin-right: 0;
    }

    &_padding {
      padding: 0.6rem 0;
    }

    &:last-child {
      margin-right: 0;
    }
  }
}

.team {
  display: flex;
  align-items: center;

  &__avatar {
    width: 3.8rem;
    margin-right: -1.2rem;
    height: 3.8rem;
    @include mobile {
      width: 3rem;
      height: 3rem;
      margin-right: -1rem;
    }
  }

  &__btn {
    display: flex;
    align-items: center;
    z-index: 2;
    white-space: nowrap;
    @include mobile {
      padding: 0.6rem 1.1rem;
      font-size: 0.9rem;
      line-height: 2rem;
    }

    &-icon {
      font-size: 1.9rem;
      margin-left: 1rem;
      @include mobile {
        margin-left: 1rem;
      }
    }
  }
}

.file-list {
  font: 1.4rem/1.8rem $base-font;
  @include mobile {
    font-size: 1.2rem;
  }

  &__wrap {
    display: flex;
    flex: 1 auto;

    &-img {
      display: flex;
      align-items: center;
      justify-content: center;
      overflow: hidden;
      width: 4.8rem;
      height: 4.8rem;
      margin-right: 1.6rem;

      & > div {
        width: 100%;
        height: 100%;
        background-color: $clr-ashy-grey;
        border-radius: 8px;
      }
    }
  }

  &__item {
    display: flex;
    align-items: center;
    border-bottom: 0.05rem solid #e7e7eb;
    padding-bottom: 1.2rem;
    margin-bottom: 1.2rem;

    .file-list__wrap-img {
      img {
        height: 100%;
        border-radius: 8px;
      }
    }
  }

  &__info {
    flex: 1 auto;
    margin-right: 1.6rem;
  }

  &__text {
    line-height: 2.5rem;
    display: flex;
    justify-content: space-between;
  }

  &__progress {
    width: 100%;
    margin-bottom: 2.4rem;
  }

  &__name {
    padding-bottom: 1rem;
    color: #4d4e67;
  }

  &__img {
    width: 100%;
    height: auto;
  }

  &__icon {
    height: 100%;
  }

  &__btn {
    cursor: pointer;
    margin-left: auto;

    &:not(:last-child) {
      margin-right: 1rem;
    }

    &:hover > i {
      transform: rotate(90deg);
    }
  }

  &__close {
    color: #ff1f3a;
    display: inline-block;
    transition: transform 0.4s cubic-bezier(0.23, 1, 0.32, 1);
  }

  &__size {
    color: #a9a9a9;
    opacity: 0.5;
  }
}

.tabs {
  display: flex;
  border-bottom: 0.1rem solid #a9a9a9;
  overflow-x: auto;

  &__item:not(:last-child) {
    margin-right: 1rem;
  }

  &__link {
    position: relative;
    display: block;
    text-align: center;
    cursor: pointer;
    font: bold 1.8rem/2.7rem $base-font;
    color: $clr-main;
    white-space: nowrap;

    &:before {
      content: '';
      position: absolute;
      height: 0.3rem;
      background-color: currentColor;
      right: 0;
      width: 0;
      bottom: 0;
      transition-property: width;
      transition-duration: 0.4s;
      transition-timing-function: cubic-bezier(0.58, 0.3, 0.005, 1);
    }

    &:hover {
      &:before {
        left: 0;
        right: auto;
        width: 100%;
      }
    }

    &_purple {
      color: #4d4e67;

      &:hover {
        &:before {
          left: 0;
          right: auto;
          width: 100%;
        }
      }
    }

    & .notice__block {
      position: absolute;
      left: 0.5rem;
      top: calc(50% - 0.4rem);
      transform: translate(0, -50%);
    }
  }

  .active-item .tabs__link {
    color: #46a6a4;

    &:before {
      left: 0;
      right: auto;
      width: 100%;
    }
  }

  .active-item .tabs__link_purple {
    color: $clr-purple;

    &:before {
      left: 0;
      right: auto;
      width: 100%;
    }
  }
}

.card-form {
  padding: 2.7rem 2.8rem 4.7rem;
  @include card-block();
  @include desktop {
    padding: 3rem 1.6rem 4.8rem;
  }
  @include tablet {
    padding: 2.5rem 1.5rem;
  }
  @include mobile {
    padding: 2rem 0 0;
    box-shadow: none;
    border: none;
  }
}

.card-form--transparent {
  width: 100%;
  max-width: 131.4rem;
  margin: 4rem auto;
  padding: 0 1.2rem;
  box-sizing: border-box;

  @include desktop {
    .profile__col_pretty-cash {
      margin-top: 2.4rem;
    }

    .profile__col-price {
      margin-right: 0.5rem;
    }

    .profile__col-right {
      padding-left: 1.2rem;
    }

    &__cards-balance-col {
      flex-shrink: 1;
    }

    .boat__card-wrap.profile {
      flex-direction: column;
    }
  }
}

.card-dashboard {
  padding: 1.7rem 2.4rem;
  z-index: 1;
  @include card-block();

  &.dashboard__aside {
    max-height: 100%;
    border-top: none;
  }
}

//------------------new password--------------------------//
.new-pass {
  padding: 6rem 0 15.7rem;
  @include mobile {
    padding: 3rem 0 6.6rem;
  }

  &__wrap {
    max-width: 40.6rem;
    margin: 0 auto;
    text-align: center;

    &-img {
      margin-bottom: 1.6rem;
    }
  }

  &__title {
    margin-bottom: 2.1rem;
    color: $clr-purple;
  }
}

//--------------------Conditions---------------------//
.conditions {
  padding: 2.6rem 0 8rem;
  background: #fafafb;
  @include mobile {
    padding: 1rem 0 5rem;
  }

  &__title {
    margin-bottom: 1.9rem;
    @include mobile {
      font-size: 1.6rem;
    }
  }

  &__update {
    color: #a9a9a9;
    margin-bottom: 4.3rem;
    @include mobile {
      margin-bottom: 2.1rem;
    }
  }

  &__text {
    padding-right: 10.5rem;
    opacity: 0.7;
    line-height: 2.5rem;
    @include tablet {
      padding-right: 0;
    }

    @include mobile {
      ol,
      ul {
        padding-left: 1.5rem;
      }
    }

    div {
      margin-bottom: 3.4rem;
      @include mobile {
        margin-bottom: 0;
      }

      &:last-child {
        margin-bottom: 0;
      }
    }
  }

  &__wrap {
    display: flex;
    @include mobile {
      flex-direction: column;
    }

    &-link {
      margin-top: 0.6rem;
      border-right: 0.1rem solid #a9a9a9;
      @include mobile {
        margin-bottom: 1.4rem;
        border-right: none;
      }
    }
  }

  &__col-left {
    margin-right: 3rem;
    @include mobile {
      margin-right: 0;
    }
  }

  &__link {
    padding: 0.4rem 3rem;
    display: inline-flex;
    @include mobile {
      padding: 0.4rem 0.5rem;
    }

    &-icon {
      color: $clr-purple;
      font-size: 1.6rem;
    }
  }
}

.status {
  display: inline-block;
  border-radius: 3rem;
  padding: 0.3rem 1.1rem 0.3rem 0.3rem;

  &-icon {
    display: inline-flex;
    border-radius: 3rem;
    align-items: center;
    margin-right: 0.4rem;
    justify-content: center;
    min-height: 2.1rem;
    min-width: 2.1rem;
    @include mobile {
      min-width: 1.8rem;
      min-height: 1.8rem;
    }

    i {
      color: white;
      line-height: 1;
      font-size: 1.5rem;
    }
  }

  &_purple {
    background-color: rgba($clr-purple, 0.1);
    color: $clr-purple;

    .status-icon {
      background: $clr-purple;
    }
  }

  &_grayish {
    background-color: rgba(#4d4e67, 0.1);

    .status-icon {
      background: #4d4e67;
    }
  }

  &_green {
    background-color: rgba(#46a6a4, 0.1);
    color: #46a6a4;

    .status-icon {
      background: #46a6a4;
    }
  }

  &_green-white {
    background-color: transparent;
    color: #46a6a4;

    .status-icon {
      background: #46a6a4;
    }
  }

  &_waiting-light {
    background-color: #f49342;
    color: #ffffff;
    padding: 0.4rem 1.2rem;
    text-transform: uppercase;
    letter-spacing: 1.2px;
    font-weight: 500;
    border-radius: 0.2rem;
  }

  &_orange {
    background-color: transparent;
    color: #f7aa38;
  }
}

.fullwidth-logo {
  width: 100%;
  padding: 1.75rem 0;

  &__image {
    display: flex;
    align-items: center;

    & > img {
      padding: 0 3rem;
      @include tablet {
        padding: 0 2rem;
        max-height: 2rem;
      }
      @include mobile {
        max-height: 1.5rem;
      }
    }

    &:before {
      content: '';
      display: block;
      height: 0.3rem;
      background-color: #432989;
      flex-grow: 1;
    }

    &:after {
      content: '';
      display: block;
      height: 0.3rem;
      background-color: #432989;
      flex-grow: 1;
    }
  }

  &_holders {
    padding-top: 8.5rem;
    margin-bottom: -4rem;
    @include tablet {
      padding-top: 5rem;
      margin-bottom: -3rem;
    }
    @include mobile {
      margin-bottom: 0;
    }
  }
}

.budget-lock {
  &:after {
    content: '';
    display: block;
    width: 2.7rem;
    height: 3.5rem;
    background-image: url('/assets/img/budget-lock.svg');
    background-position: center;
    background-repeat: no-repeat;
    background-size: contain;
    position: relative;
    bottom: 8px;
    margin-right: 2.2rem;
  }
}

.budget-table__currency {
  color: #4f194a;
}

.subscription {
  &__head {
    padding: 0 4rem;
  }

  &__title {
    margin-bottom: 2.4rem;
    color: #4d4e67;
    font-size: 2.4rem;
    line-height: 2.8rem;
    font-weight: bold;
  }

  &__description {
    margin-bottom: 4.8rem;
    color: #a9a9a9;
    font-size: 1.6rem;
    line-height: 19px;
  }

  &__box {
    display: flex;
    justify-content: space-between;
    padding: 0 4rem;
    margin-bottom: 2rem;
    @include laptop {
      flex-direction: column;
      align-items: center;
    }
    @include mobile {
      padding: 0 1rem;
    }

    app-subscription-mode {
      width: 49%;
      @include laptop {
        width: 100%;
        order: 2;
        &:last-of-type {
          order: 1;
        }
      }
    }
  }

  &__saving {
    position: absolute;
    background-color: #f7aa38;
    border-radius: 1.2rem;
    padding: 0.8rem;
    color: #fff;
    line-height: 2rem;
    display: flex;
    flex-direction: column;
    right: 1.2rem;
    top: 1.2rem;

    &_price {
      font-weight: bold;
    }
  }

  &__item {
    border: 2px solid #e7e7eb;
    border-radius: 24px;
    padding: 3.2rem;
    position: relative;

    .subscription__item_advantages {
      margin-right: 0;
    }

    &.subscription-highlight {
      border-color: #f7aa38;
      box-shadow: 0 10px 50px rgba(0, 0, 0, 0.25);
      position: relative;
      top: -25px;
      @include laptop {
        top: 0;
        box-shadow: none;
        margin-bottom: 2.6rem;
      }
      @include tablet-small {
        padding-top: 7rem;
      }
    }

    &.subscription__item_advantages {
      margin-right: 0;
    }

    &_head {
      position: relative;
    }

    &_title {
      color: #4d4e67;
      margin-bottom: 0.8rem;
      font-size: 4.8rem;
      text-align: center;
      line-height: 5.6rem;
    }

    &_description {
      margin-bottom: 1.6rem;
      color: #a9a9a9;
      font-size: 1.6rem;
      text-align: center;
      display: block;
      padding: 0.8rem 0 0 0;
    }

    &_price {
      color: #351f6f;
      font-size: 3.5rem;
      text-align: center;
      margin-bottom: 1.2rem;
      font-weight: bold;
      line-height: 4.1rem;

      &--old {
        color: #a9a9a9;
        text-decoration-line: line-through;
        width: 100%;
      }

      &_label {
        display: flex;
        justify-content: center;
        flex-direction: column;
        width: 26%;
        margin: 0 auto;
        line-height: 1.6rem;
        color: #a9a9a9;
        text-align: center;
        margin-bottom: 2.9rem;
        padding-top: 1.2rem;
        border-top: 1px solid #e7e7eb;
        @include mobile {
          width: 56%;
        }
      }
    }

    &_advantages {
      display: flex;
      justify-content: space-between;
      padding: 1.2rem 1.6rem;
      background: #fafafb;
      border-radius: 8px;
      color: #4d4e67;
      font-size: 1.6rem;
      margin: 0 2rem 1rem 0;

      &--user {
        flex-direction: column;

        div {
          display: flex;
          justify-content: space-between;
        }
      }

      &--coast {
        margin-top: 0.4rem;
        color: #a9a9a9;
        line-height: 1.6rem;
      }
    }

    &_btn {
      width: 100%;
      background-color: #46a6a4;
      border-radius: 3px;
      margin-top: 2.9rem;
      box-sizing: border-box;
      padding: 1rem 0.8rem;
      color: #fff;
      text-transform: uppercase;
      cursor: pointer;
    }

    &_btn:disabled {
      background-color: #e7e7eb;
      cursor: default;
    }
  }

  &__plan {
    &_title {
      padding-bottom: 1.6rem;
      color: #351f6f;
      font-weight: 500;
      font-size: 3.4rem;
      line-height: 4rem;
    }

    &_price {
      color: #351f6f;
      font-weight: bold;
      font-size: 3.5rem;
      margin-bottom: 1.6rem;
      line-height: 4.1rem;
      display: flex;
      align-items: center;

      &_label {
        margin-left: 1.2rem;
        color: #a9a9a9;
        font-size: 1.6rem;
        font-weight: 400;
      }

      &--old {
        margin-left: 1.6rem;
        padding-left: 1.6rem;
        color: #a9a9a9;
        font-size: 2.4rem;
        text-decoration-line: line-through;
        border-left: 1px solid #e7e7eb;
      }

      &_box {
        display: flex;
      }
    }

    &_row {
      display: flex;
      align-items: flex-end;
      justify-content: space-between;

      &.form__row:last-child {
        margin-bottom: 4.8rem;
      }

      app-seazone-input {
        width: 80%;
        @include mobile {
          width: 65%;
        }
      }

      .card__btn {
        width: 15%;
        @include mobile {
          line-height: 2.2rem;
          width: 31%;
        }
      }

      @include mobile {
        &.form__row {
          flex-direction: row;
        }
      }
    }

    &_btns {
      display: flex;
      justify-content: flex-end;

      .card__btn {
        width: 25%;

        &:not(:last-of-type) {
          margin-right: 1rem;
        }
      }
    }
  }

  &-banner {
    width: 100%;
    margin-bottom: 48px;
    padding: 16px;
    text-align: center;
    text-transform: uppercase;
    color: #3f9795;
    font-size: 20px;
    letter-spacing: 0.1em;
    font-weight: 500;
    background-color: #d4f9f8;
    border-radius: 8px;
    position: relative;
    box-sizing: border-box;

    &::before {
      content: '';
      border: 20px solid transparent;
      border-top: 17px solid #d4f9f8;
      position: absolute;
      bottom: -36px;
      left: 0;
      right: 0;
      margin: auto;
      height: 12px;
      display: block;
      box-sizing: border-box;
      width: 30px;
    }
  }

  &-banner.settings {
    margin-bottom: 24px;
  }
}

.subscription,
.add-boat {
  .user-note {
    display: flex;

    &__before {
      min-width: 2rem;
      display: inline-block;
    }
  }
}

.info-label {
  padding: 1rem 2.4rem;
  background-color: #d4f9f8;
  border-radius: 8px;
  color: #3f9795;
  font-weight: 500;
  font-size: 1.5rem;

  &.purple {
    background-color: #d7d3ff;
    color: #351f6f;
  }

  &.error {
    background-color: rgba(227, 111, 132, 0.2);
    color: #ff5574;

    & > a {
      color: $seaz-edit-hover;
    }
  }

  &.light-purple {
    background-color: rgba(53, 31, 115, 0.08);
    color: $clr-purple;
    border-radius: 0.4rem;
    font-size: $base-font-size;
  }

  &.yellow {
    background-color: #fdefc8;
    color: #f49342;
    border-radius: 0;
  }

  &.gold {
    background-color: #fdefc8;
    color: $waiting-dark;
    border-radius: 0;
    font-size: 1.6rem;
  }
}

.payment-result {
  &__box {
    display: flex;
    align-items: center;
    flex-direction: column;
    margin-top: 4rem;
  }

  &__img {
    margin-bottom: 6rem;
  }

  &__title {
    margin-bottom: 2.4rem;
    color: #4d4e67;
    font-weight: 500;
    font-size: 3.4rem;
    line-height: 4rem;
  }

  &__text {
    padding-bottom: 5.6rem;
    color: #a9a9a9;
    font-size: 1.6rem;
    line-height: 1.9rem;
    width: 57%;
    text-align: center;
  }

  &__btn {
    background-color: #46a6a4;
    border-radius: 3px;
    font-weight: 500;
    font-size: 1.4rem;
    line-height: 2.4rem;
    color: #ffffff;
    padding: 1.2rem 1rem;
    text-transform: uppercase;
  }
}

.form-payment__title {
  margin-bottom: 2.4rem;
  color: #351f6f;
  font-weight: 500;
  font-size: 3.4rem;
  line-height: 4rem;
}

.settings-subscription {
  button:disabled {
    &.main-btn_green,
    &.subscription__item_btn {
      background-color: $clr-green;
      opacity: 0.3;
      border-color: transparent;
    }
  }

  &__row {
    @include tablet-big {
      flex-direction: column;
    }
  }

  &__col {
    &.form__col {
      @include laptop {
        width: 65%;
      }
      @include tablet-big {
        width: 100%;
        margin-bottom: 2rem;
      }
    }
  }

  &__card_col {
    &.form__col {
      @include laptop {
        width: 34%;
      }
      @include tablet-big {
        width: 100%;
      }
    }
  }

  &__box {
    border: 2px solid #e7e7eb;
    border-radius: 8px;
    padding: 3.2rem;
    margin-right: 2.4rem;
    @include laptop {
      margin-right: 1rem;
    }
  }

  &__title {
    color: #4d4e67;
    font-size: 2.4rem;
    padding-bottom: 1.6rem;
    line-height: 2.8rem;
    font-weight: bold;
    text-align: center;
    border-bottom: 1px solid #e7e7eb;
  }

  &__currency {
    display: flex;
    justify-content: center;
    padding: 1.2rem 0 0.8rem;
    align-items: center;

    &--year {
      padding-top: 0;
    }

    &_price {
      font-size: 3.5rem;
      color: #351f6f;
      line-height: 4.1rem;
      font-weight: bold;

      &--year {
        color: #4d4e67;
        font-size: 2.4rem;
      }
    }

    &_label {
      margin-left: 1.2rem;
      color: #a9a9a9;
      font-weight: 500;
      font-size: 1.8rem;
    }
  }

  &__trial {
    padding: 3.2rem;
    background-color: #fafafb;
    border-radius: 8px;
    margin-bottom: 3.2rem;

    &_text {
      font-size: 1.8rem;
      font-weight: 700;
      text-align: center;
      line-height: 21px;
    }

    &_info {
      margin-top: 3.2rem;
      display: block;

      .info-label {
        text-align: center;
      }
    }
  }

  &__payment {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    padding: 0 2rem;
    line-height: 3rem;
    @include tablet {
      flex-direction: column;
      padding: 2rem;
    }

    &_title {
      color: #a9a9a9;
      font-weight: 500;
      font-size: 2rem;
      padding-right: 1rem;
    }

    &_price {
      color: #351f6f;
      font-size: 2rem;
      font-weight: 500;
    }
  }

  &__btn {
    border-radius: 3px;
    padding: 1rem 0.8rem;
    line-height: 2rem;
    text-transform: uppercase;
    font-weight: 500;
    width: 100%;
    cursor: pointer;
    margin: 1rem 0;

    &_cancel {
      width: 28%;
      margin-right: 3%;
    }

    &_text {
      text-align: center;
      color: #a9a9a9;
      font-size: 1.6rem;
      line-height: 1.9rem;
      width: 63%;
      margin: 0 auto;
      padding-top: 1rem;

      button {
        color: #46a6a4;
        text-decoration: underline;
        cursor: pointer;
      }
    }
  }
}

.payment-card {
  background-color: #fff;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.25);
  border-radius: 8px;
  padding: 2.4rem;
  margin-bottom: 2.9rem;

  &__box {
    background-color: #fafafb;
    border-radius: 8px;
    padding: 3.2rem;
    box-sizing: border-box;
    @include tablet-small {
      padding: 2.2rem 1.2rem;
    }

    &_text {
      font-size: 1.3rem;
      text-align: center;
      color: #4d4e67;
      line-height: 1.6rem;
      padding-bottom: 2.9rem;
      display: block;
      font-weight: 100;
    }

    &_data {
      color: #313247;
      font-size: 1.8rem;
      font-weight: bold;
      text-align: center;
    }
  }

  &__type {
    width: 5rem;
  }

  &__title {
    font-weight: bold;
    color: #46a6a4;
    font-size: 1.8rem;
  }

  &__head {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-bottom: 2.7rem;

    &.icon-close {
      cursor: pointer;
    }
  }

  &__number {
    font-size: 2.4rem;
    line-height: 2.8rem;
    color: #4d4e67;
  }

  &__type {
    padding-top: 3.1rem;
  }

  &__expire {
    text-align: center;
    font-weight: bold;
    color: #313247;
    font-size: 1.8rem;
    padding-bottom: 2rem;
  }

  &__add {
    display: flex;
    flex-direction: column;
    align-items: center;

    &_text {
      margin-top: 3.3rem;
      line-height: 2.2rem;
      color: #313247;
      text-align: center;
    }

    &_btn {
      padding: 1rem 1.5rem;
      margin: 2.9rem 0;
    }
  }
}

// seaz new fotns

.seaz {
  &-h1 {
    font-weight: bold;
    font-size: 4rem;
    line-height: 4rem;
    @include mobile {
      font-size: 2.6rem;
      line-height: 2.6rem;
    }
  }

  &-h2 {
    font-weight: 500;
    font-size: 3rem;
    line-height: 3rem;
    @include mobile {
      font-size: 2.2rem;
      line-height: 2.2rem;
    }
  }

  &-h3 {
    font-weight: 500;
    font-size: 2.4rem;
    line-height: 2.4rem;
    @include mobile {
      font-size: 2rem;
      line-height: 2rem;
    }

    &-caps {
      text-transform: uppercase;
      @extend .seaz-h3;
      @include mobile {
        font-size: 2rem;
        line-height: 2rem;
      }
    }
  }

  &-h4 {
    font-weight: 500;
    font-size: 2rem;
    line-height: 2rem;
    @include mobile {
      font-size: 1.8em;
      line-height: 2.8rem;
    }
  }

  &-h5 {
    font-weight: 500;
    font-size: 1.6rem;
    line-height: 1.6rem;
    @include mobile {
      font-size: 1.4rem;
      line-height: 1.4rem;
    }

    &-caps {
      @extend .seaz-h5;
      text-transform: uppercase;
      @include mobile {
        font-size: 1.4rem;
        line-height: 1.4rem;
      }
    }
  }

  &-h6 {
    font-weight: 500;
    font-size: 1.4rem;
    line-height: 1.4rem;
    word-break: break-word;
    @include mobile {
      font-size: 1.2rem;
      line-height: 1.2rem;
    }
  }

  &-subtitle {
    font-weight: 400;
    font-size: 1.8rem;
    line-height: 1.8rem;
    @include mobile {
      font-size: 1.6rem;
      line-height: 1.6rem;
    }
  }

  &-text-XLlarge {
    font-weight: 400;
    font-size: 2rem;
    line-height: 2rem;

    &-light {
      @extend .seaz-text-XLlarge;
      font-weight: 300;
    }
  }

  &-text-large {
    font-weight: 400;
    font-size: 1.8rem;
    line-height: 2.4rem;
    @include mobile {
      font-size: 1.6rem;
      line-height: 2.2rem;
    }
  }

  &-text-medium {
    font-weight: 400;
    font-size: 1.6rem;
    line-height: 1.6rem;
    @include mobile {
      font-size: 1.4rem;
      line-height: 1.4rem;
    }

    &-light {
      @extend .seaz-text-medium;
      font-weight: 300;
      @include mobile {
        font-size: 1.4rem;
        line-height: 1.4rem;
      }
    }
  }

  &-text-small {
    font-weight: 400;
    font-size: 1.4rem;
    line-height: 2rem;
    @include mobile {
      font-size: 1.2rem;
      line-height: 1.8rem;
    }
  }

  &-text-XSsmall {
    font-weight: 400;
    font-size: 1.2rem;
    line-height: 1.2rem;
    @include mobile {
      font-size: 1rem;
      line-height: 1rem;
    }
  }

  &-text-quote {
    font-weight: 500;
    font-style: italic;
    font-size: 1.6rem;
    @include mobile {
      font-size: 1.4rem;
      line-height: 1.4rem;
    }
  }
}

.seaz__return_btn {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 4rem;
  min-width: 4rem;
  height: 4rem;
  cursor: pointer;
  background-color: $seaz-head-arrow;
  border-radius: 0.4rem;
}

.seaz-yaht {
  &__head {
    display: flex;
    position: relative;
    color: $clr-primary-and-accent;
    text-transform: uppercase;
    padding: 3.4rem 0;
    width: 126.4rem;
    margin: 0 auto;
    align-items: center;
    justify-content: center;
    @media screen and (max-width: 1300px) {
      justify-content: space-between;
      width: 100rem;
    }
    @include laptop {
      width: 100%;
    }
    @include tablet {
      width: 100%;
      padding: 2.4rem 1.6rem;
    }

    @include mobile {
      padding: 2.4rem 1rem 3rem;
    }

    .seaz-h2 {
      @include tablet {
        font-size: 2.2rem;
        text-align: center;
      }
      @include mobile {
        width: 100%;
        padding: 0 6rem;
      }
    }

    .seaz__return_btn {
      position: absolute;
      left: 0;
      @media screen and (max-width: 1300px) {
        position: relative;
        margin: 0 2rem;
      }
      @include tablet {
        flex-shrink: 0;
        top: 1.3rem;
        position: absolute;
      }

      @include mobile {
        margin-right: 0;
      }
    }

    .dot {
      font-size: 2rem;
      margin: 0 1.6rem;
    }

    &_btn {
      position: absolute;
      right: 0;
      @media screen and (max-width: 1300px) {
        position: relative;
        flex-shrink: 0;
        margin-left: 2rem;
      }
      @include laptop {
        padding-top: 1rem;
      }
      @media screen and (max-width: 370px) {
        flex-direction: column;
        display: flex;
        width: 80%;
        margin: 2rem 0 0 0;
        button {
          margin-right: 0;
          margin-bottom: 1rem;
        }
      }
    }

    &_content {
      display: flex;
      align-items: center;
      width: 100%;
      padding: 0 25rem;
      text-align: center;
      justify-content: center;
      word-break: break-word;
      @media screen and (max-width: 1300px) {
        width: 100%;
        padding: 0;
      }
      @include laptop {
        flex-direction: column;
      }
      @include mobile {
        .seaz-cv__crew_head_name,
        .dot {
          display: none;
        }
      }

      .seaz-h2 {
        display: flex;
        flex-wrap: wrap;
        text-align: center;
        align-items: center;
        justify-content: center;
      }
    }
  }

  &__navigation {
    padding: 3rem 0;
    display: flex;
    justify-content: center;
    border-top: 1px solid $seaz-border;
    border-bottom: 1px solid $seaz-border;
    width: 65%;
    margin: 0 auto;
    @include laptop {
      width: 80%;
    }
    @include tablet {
      width: 96%;
      padding: 2.4rem 0;
    }
    @include mobile {
      width: 100%;
    }

    &_item {
      text-decoration: none;
      color: $seaz-navigation;
      display: flex;
      align-items: center;
      @include mobile {
        text-align: center;
      }

      &:after {
        content: '';
        display: block;
        width: 1px;
        height: 100%;
        background-color: $seaz-navigation;
        margin: 0 2.4rem;
      }

      &:last-of-type:after {
        display: none;
        content: '';
      }

      &__active {
        color: $clr-primary-and-accent;
        text-decoration: underline;
        font-weight: 500;
      }
    }
  }

  &__images {
    display: flex;
    justify-content: center;
    position: relative;
    margin: 3rem 0 10rem 0;
    @include mobile {
      margin: 3rem 0 5rem 0;
    }

    &_item {
      &--boat {
        width: 256px;
        height: 256px;
        object-fit: cover;
        border-radius: 15px;
        @include mobile {
          width: 156px;
          height: 156px;
        }
      }

      &_box {
        position: absolute;
        bottom: -115px;
        border-radius: 50%;
        padding: 1rem;
        background-color: $seaz-logo-border;
        @include mobile {
          bottom: -56px;
          padding: 0.5rem;
        }
      }

      &--logo {
        width: 164px;
        height: 164px;
        object-fit: cover;
        border-radius: 50%;
        @include mobile {
          width: 80px;
          height: 80px;
        }
      }
    }
  }

  &__info {
    width: 83.2rem;
    margin: 0 auto;
    padding-bottom: 6rem;
    border-bottom: 1px solid $seaz-border;
    @include laptop {
      width: 70rem;
    }
    @include tablet {
      width: 96%;
      padding-bottom: 1rem;
    }

    .information-card--header {
      position: relative;

      .information-card--header__title {
        display: flex;
        justify-content: center;
        @media screen and (max-width: 700px) {
          display: block;
          max-width: 80%;
        }
        @include mobile {
          font-size: 22px;
        }
      }

      .edit-button {
        position: absolute;
        right: 0;
        top: 5.7rem;
        @include tablet {
          top: 3.2rem;
        }
        @include mobile {
          position: relative;
          top: 0;
        }
      }
    }

    &_company_box {
      @include tablet {
        display: flex;
        justify-content: space-between;
        padding: 4rem 1.6rem;
        border-bottom: 1px solid #bdbdbd;
      }
      @media screen and (max-width: 700px) {
        flex-direction: column;
        justify-content: center;
        padding-bottom: 8rem;
      }
    }

    &_main_box {
      @media screen and (max-width: 700px) {
        position: relative;
      }
    }

    &_company_box {
      @include tablet {
        position: relative;
      }

      .edit-button {
        display: none;
        @include tablet {
          display: flex;
          position: absolute;
          right: 1.6rem;
        }
        @media screen and (max-width: 700px) {
          top: 4.4rem;
        }
        @include mobile {
          top: 3.4rem;
        }
      }
    }

    &_wrap {
      display: flex;
      width: 100%;
      justify-content: space-between;
      max-width: 83.2rem;
      margin: 0 auto;
      @media screen and (max-width: 900px) {
        width: 70rem;
      }
      @include tablet {
        position: relative;
        justify-content: center;
        width: 100%;
        padding: 0 1.6rem;
      }
      @media screen and (max-width: 700px) {
        flex-direction: column;
        align-items: center;
      }

      &_center {
        display: inline-flex;
        flex-direction: column;
        align-items: center;
      }

      &_header {
        color: #351f73;
        font-size: 3rem;
        line-height: 4.35rem;
        font-weight: 500;
        padding-top: 6rem;
        padding-bottom: 1.6rem;
        text-align: center;
        text-transform: uppercase;
        @include tablet {
          padding-top: 0;
        }
        @include mobile {
          font-size: 2.4rem;
          text-align: left;
          line-height: 2.4rem;
        }
      }
    }

    &_organization {
      width: 83.2rem;
      margin: 0 auto;
      padding-bottom: 3.3rem;
      border-bottom: 1px solid #bdbdbd;
      @media screen and (max-width: 900px) {
        width: 70rem;
      }
      @include tablet {
        width: 50%;
        padding: 0;
        border-bottom: none;
      }
      @media screen and (max-width: 700px) {
        padding-bottom: 0;
        width: 100%;
        order: 1;
      }

      &_head {
        @media screen and (max-width: 700px) {
          display: flex;
          flex-direction: column;
          justify-content: center;
          width: 100%;
        }
        @include mobile {
          top: 23rem;
          .seaz-yaht__info_wrap_header {
            display: flex;
            width: 80%;
          }
        }
      }
    }

    &_main {
      &_left {
        display: flex;
        align-items: flex-end;
        width: 22%;

        &.seaz-yaht__info--mobile {
          display: none;
        }

        @media screen and (max-width: 900px) {
          width: 30%;
        }
        @include tablet {
          flex-direction: column;
          width: 26%;
          padding-top: 7.2rem;
          &.seaz-yaht__info--mobile {
            display: flex;
          }

          app-information-card-item {
            width: 100%;
          }
        }
        @media screen and (max-width: 700px) {
          width: 100%;
          order: 2;
          padding-top: 0;
        }

        &.seaz-yaht__info--desktop {
          @include tablet {
            display: none;
          }
        }
      }

      &_left > &_col {
        width: 100%;
      }

      &_right {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-items: flex-end;
        width: 22%;
        padding-top: 3rem;

        &.seaz-yaht__info--mobile {
          display: none;
        }

        @media screen and (max-width: 900px) {
          width: 30%;
        }
        @include tablet {
          flex-direction: column;
          width: 26%;
          padding-top: 7.2rem;

          &.seaz-yaht__info--mobile {
            display: flex;
            position: relative;

            .edit-button {
              position: absolute;
              top: 0;
            }
          }
          app-information-card-item {
            width: 100%;
          }
          .edit-button {
            margin-bottom: 2.1rem;
          }
        }
        @media screen and (max-width: 700px) {
          padding-top: 0;
          width: 100%;
          order: 2;
          .edit-button {
            position: absolute;
            top: 2rem;
          }
        }

        &.seaz-yaht__info--desktop {
          @include tablet {
            display: none;
          }
        }
      }

      &_right > &_col {
        width: 100%;
        display: flex;
        flex-direction: column;
        height: 100%;
        justify-content: flex-end;
      }

      &_col {
        width: 22%;
        @include tablet {
          width: 32%;
        }
        @media screen and (max-width: 700px) {
          width: 100%;
        }
      }
    }

    &_center {
      &_col {
        width: 37%;
        @media screen and (max-width: 700px) {
          order: -1;
          width: 100%;
        }

        .team {
          justify-content: center;
        }
      }
    }

    &_item {
      display: flex;
      justify-content: space-between;
      padding: 1.3rem 0;
      border-bottom: 1px solid $seaz-border-info;
      @include mobile {
        border: none;
        padding: 0.8rem 0;
      }

      img {
        width: 2.8rem;
        height: 1.9rem;
        margin-left: 1rem;
      }

      &_country {
        display: flex;
        align-items: center;
        color: $seaz-text;
        word-break: break-all;
        text-align: end;
        padding-left: 0.5rem;
        @include tablet {
          display: flex;
          text-align: end;
        }
        @include mobile {
          font-weight: bold;
          align-items: center;
          display: flex;
        }
      }
    }

    &_content {
      display: flex;
      justify-content: space-between;
      @media screen and (max-width: 768px) {
        flex-direction: column;
        position: relative;
        padding-bottom: 4rem;
      }
    }

    &_location {
      .seaz-yaht__info_item {
        border: none;
        justify-content: center;
        padding: 0.5rem 0;
        align-items: center;
        @media screen and (max-width: 700px) {
          justify-content: space-between;
          padding: 1.3rem 0;
          border-bottom: 1px solid #dcdcdd;
        }
        @include mobile {
          border: none;
          padding: 0.8rem 0;
        }
      }

      .seaz-yaht__info_label {
        display: flex;
        align-items: center;
        margin-right: 1rem;
      }

      @media (min-width: 699px) and (max-width: 768px) {
        .seaz-yaht__info_item {
          flex-direction: column;
          flex-shrink: 0;
          align-items: center;
          padding: 0 1rem;
        }

        .seaz-yaht__info_label {
          margin: 0 0 1rem;
        }

        .seaz-yaht__info_item_country {
          text-align: center;
        }
      }
    }

    &_flag {
      .seaz-yaht__info_item {
        border: none;
        justify-content: center;
        padding: 0.5rem 0;
        align-items: center;
        @media screen and (max-width: 700px) {
          justify-content: space-between;
          padding: 1.3rem 0;
          // border-bottom: 1px solid #dcdcdd;
        }
        @include mobile {
          border: none;
          padding: 0.8rem 0;
        }
      }

      .seaz-yaht__info_label {
        display: flex;
        align-items: center;
        margin-right: 1rem;
      }

      @media (min-width: 699px) and (max-width: 768px) {
        .seaz-yaht__info_item {
          flex-direction: column;
          flex-shrink: 0;
          align-items: center;
          padding: 0 1rem;
          margin-top: 1rem;
        }

        .seaz-yaht__info_label {
          margin: 0 0 1rem;
        }

        .seaz-yaht__info_item_country {
          text-align: center;
        }
      }
    }

    &_crew {
      display: flex;
      flex-direction: column;
      align-items: center;
      padding: 1.1rem 1rem;
      color: #fff;
      width: 140px;

      &_box {
        display: flex;
        justify-content: center;
        margin-top: 2rem;
        @media screen and (max-width: 700px) {
          position: absolute;
          bottom: 2.4rem;
          left: 1.6rem;
        }
      }
    }

    &_boat {
      &_box {
        display: flex;
        justify-content: space-between;
      }

      width: 45%;

      @include tablet {
        width: 100%;
      }

      .seaz-yaht__info_item {
        border: none;
        justify-content: inherit;
        @include tablet {
          border-bottom: 1px solid #dcdcdd;
        }
        @include mobile {
          border: none;
          width: 100%;
        }

        .seaz-yaht__info_label,
        span {
          width: 50%;
        }
      }

      @media (min-width: 481px) and (max-width: 765px) {
        width: 100%;

        .seaz-yaht__info_item {
          justify-content: space-between;

          .seaz-yaht__info_label,
          span {
            width: auto;
          }
        }
      }
    }

    &_boat-general-info {
      width: 100%;

      @include mobile {
        .seaz-yaht__info_item {
          justify-content: inherit;

          .seaz-yaht__info_label {
            margin-right: 0;
          }

          .seaz-yaht__info_label,
          span {
            width: 50%;
            text-align: left;
          }
        }
      }
    }

    &_company {
      .seaz-yaht__info_item {
        border: none;
        justify-content: flex-start;
        @include tablet {
          justify-content: space-between;
          border-bottom: 1px solid #dcdcdd;
        }
        @include mobile {
          border: none;
        }
      }

      &_col {
        width: 100%;
      }

      .seaz-yaht__info_label {
        width: 17rem;
        margin-right: 4.6rem;
        line-height: 1.8rem;
        @include tablet {
          width: auto;
        }
        @include mobile {
          width: 43%;
          margin-right: 0;
        }
      }

      .seaz-yaht__info_item_country {
        @include mobile {
          width: 48%;
          text-align: inherit;
        }
      }
    }

    &_label {
      color: $seaz-text;
      flex-shrink: 0;
    }
  }
}

.boat-profile-header {
  position: relative;
  width: 100%;
  max-width: 126.4rem;
  margin: 0 auto;

  &__head-content {
    text-align: center;
  }

  &__title {
    width: 100%;
    max-width: 65%;
  }

  .seaz-yaht__head_content {
    padding: 0 1rem;
  }

  app-ais-position-toggle {
    display: block;
    position: absolute;
    right: 0;
    min-width: 15.5rem;
  }

  @media screen and (max-width: 1300px) {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-template-areas:
      'button action'
      'header header';
    grid-gap: 1.6rem 0;

    .seaz-yaht__head_content {
      grid-area: header;
    }

    .seaz__return_btn {
      grid-area: button;
    }

    app-ais-position-toggle,
    .seaz__return_btn {
      position: relative;
      margin: 0 1rem;
    }

    app-ais-position-toggle,
    .seaz-yaht__head_btn {
      grid-area: action;

      .ais-position-toggle {
        justify-content: flex-end;
      }
    }
  }

  .seaz-yaht__head_btn {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    margin: 0 1rem;

    @include mobile {
      margin: 0;
    }

    @media screen and (max-width: 370px) {
      flex-direction: row;
      width: 100%;
      margin: 0;
      button {
        @include indent-not-last-of-type(0 1rem 0 0);
        margin-bottom: 0;
      }
    }
  }

  @include tablet {
    .seaz-h2 {
      max-width: 100%;
    }

    app-ais-position-toggle,
    .seaz__return_btn {
      top: 0;
      margin: 0;
    }
  }

  @include mobile {
    .seaz-h2 {
      padding: 0;
    }
  }
}

.boat-profile-nav {
  .seaz-yaht__navigation {
    width: 100%;
    max-width: 126.4rem;
  }

  @include tablet {
    padding: 0 1rem;
  }
}

.ais-position-toggle {
  @include d-align-items-center;

  &__label {
    display: block;
    font-size: $base-font-size;
    line-height: 1.6rem;
    color: $seaz-text;
    text-transform: initial;
    margin-left: 1rem;
  }
}

.boat-profile-general-location {
  &__container {
    @include d-flex-space-between;
    align-items: flex-start;
    margin-bottom: 2.4rem;
  }

  &__col {
    width: 33.3%;
    font-size: 1.6rem;
    text-align: center;
    @include indent-not-last-of-type(0 1rem 0 0);

    &--location {
      max-width: 27.2rem;
    }
  }

  &__title {
    font-size: 1.6rem;
    line-height: 1.9rem;
    color: $profile-month;
    margin-bottom: 0.6rem;
  }

  &__text {
    display: flex;
    align-items: flex-start;
    font-weight: 500;
    line-height: 120%;
    color: $seaz-text;
    margin-bottom: 0.6rem;
    justify-content: center;

    app-seazone-icon {
      margin-right: 0.8rem;
    }
  }

  &__text-update {
    display: inline-flex;
    font-size: 1.2rem;
    font-weight: 400;
    line-height: 1.4rem;
    color: $profile-month;

    &--val {
      font-weight: 500;
      color: $seaz-text;
      margin-left: 0.8rem;
    }
  }

  @include tablet {
    &__container {
      flex-direction: column;
      align-items: center;
    }

    &__col {
      width: 100%;
      @include indent-not-last-of-type(0 0 1.2rem 0);
    }
  }

  @media screen and (max-width: 700px) {
    &__container {
      align-items: flex-start;
    }

    &__col {
      width: 100%;
      text-align: left;
      padding: 1.3rem 0;
      border-bottom: 1px solid $seaz-border-info;

      &--location {
        max-width: initial;
      }
    }
  }

  @include mobile {
    &__text {
      justify-content: flex-start;
    }
  }
}

.yaht-documents {
  &__head {
    display: flex;
    align-items: center;
    width: 65%;
    margin: 0 auto;
    padding: 4.7rem 0 3.6rem 0;
    @include desktop {
      width: 100rem;
    }
    @include laptop {
      width: 96%;
    }

    &_count {
      display: block;
      margin-left: 0.8rem;
      padding: 0.1rem;
      min-width: 2.4rem;
      min-height: 2.4rem;
      border-radius: 50%;
      text-align: center;
      color: $seaz-document-count;
      background-color: $seaz-head-arrow;
      line-height: 2.4rem;
      font-size: 1.2rem;
      font-weight: 550;
      letter-spacing: 0.01em;
    }
  }

  &__title {
    color: $clr-primary-and-accent;
  }

  &__box {
    &--short {
      max-height: 370px;
      overflow-y: scroll;
      padding-right: 15px;

      @include laptop {
        padding-right: 10px;
      }

      @include tablet {
        padding-right: 4px;
      }

      @include custom-scroll-bar;
    }

    &_head {
      display: flex;
      width: 65%;
      margin: 0 auto;
      padding: 1rem;
      box-sizing: border-box;
      padding-right: 3rem;
      @include desktop {
        width: 100rem;
      }
      @include laptop {
        width: 96%;
      }
      @include mobile {
        display: none;
      }

      &_name {
        width: 28%;
        box-sizing: border-box;
        @include laptop {
          width: 23%;
        }
        @include tablet {
          width: 25%;
        }
        @media screen and (max-width: 600px) {
          width: auto;
          padding-right: 10px;
        }
      }

      &_date {
        width: 18%;
        @include laptop {
          width: 22.4%;
        }
        @include tablet {
          width: 19%;
        }
        @media screen and (max-width: 600px) {
          width: auto;
          padding-right: 10px;
        }
      }

      &_snooze {
        width: 16%;
        @include tablet {
          width: 18%;
        }
        @media screen and (max-width: 600px) {
          width: auto;
          padding-right: 10px;
        }
      }

      &_item {
        color: $seaz-text;
        display: flex;
        align-items: center;
        @include mobile {
          flex-shrink: 0;
          margin-right: 1rem;
        }

        .sort--container__icons {
          display: flex;

          svg {
            fill: $clr-light-grey;
          }
        }
      }
    }
  }

  &__row {
    &--content {
      display: flex;
      width: 65%;
      margin: 0 auto;
      align-items: center;
      padding: 2.5rem 1rem;
      box-sizing: border-box;
      @include desktop {
        width: 100rem;
      }
      @include laptop {
        width: 96%;
      }
      @media screen and (max-width: 600px) {
        position: relative;
      }
    }

    &--left {
      display: flex;
      width: 100%;
      align-items: center;
      @include tablet {
        align-items: flex-start;
      }
      @media screen and (max-width: 600px) {
        flex-direction: column;
      }
    }

    &--long {
      .yaht-documents__row {
        &:nth-of-type(2n) {
          background-color: $clr-white;
          @include mobile {
            background-color: $seaz-bg;
          }
        }
      }
    }

    &--short {
      .yaht-documents__row {
        &:nth-of-type(2n - 1) {
          background-color: $clr-white;
          @include mobile {
            background-color: $seaz-bg;
          }
        }
      }

      .yaht-documents__row .yaht-documents__row--content {
        width: 100%;
      }
    }

    &_clip {
      margin-right: 1rem;
    }
  }

  &__snooze {
    width: 16%;
    color: $clr-primary-and-accent;
  }

  &__name,
  &__date {
    color: $clr-primary-and-accent;
    width: 23%;
    display: flex;
  }

  &__name {
    &_crew {
      color: $seaz-document-count;
      font-weight: 400;
      font-size: 1.4rem;
      line-height: 1.6rem;
    }
  }

  &__action {
    width: 24%;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    @include tablet {
      width: 19%;
    }
    @media screen and (max-width: 600px) {
      position: absolute;
      top: 13px;
      right: 0;
      width: 46%;
    }

    &_btn {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 3.2rem;
      height: 3.2rem;
      border-radius: 50%;
      cursor: pointer;

      app-seazone-icon {
        width: 100%;
        height: 100%;
      }

      &-send:hover {
        background-color: $clr-primary-and-accent;

        svg {
          fill: $clr-white;
        }
      }

      &-star:hover,
      &-history:hover {
        background-color: $seaz-mulberry;

        svg {
          fill: $clr-white;
        }
      }

      &-edit:hover {
        background-color: $seaz-edit-hover;

        svg {
          fill: $clr-white;
        }
      }

      &-archive:hover {
        background-color: $seaz-alarm;

        svg {
          fill: $clr-white;
        }
      }

      &-show {
        transform: rotate(180deg);
      }
    }

    &_send {
      width: 2.4rem;
      height: 1.8rem;
    }

    &_star {
      width: 2.6rem;
      height: 2.6rem;
    }

    &_edit {
      width: 2.6rem;
      height: 2rem;
    }

    &-show {
      width: 2.6rem;
      height: 2rem;
    }

    &_send,
    &_star,
    &_edit,
    &_show {
      background-position: center;
      background-repeat: no-repeat;
      background-size: contain;
      display: block;
      cursor: pointer;
    }
  }

  &__folders {
    margin-bottom: 8rem;

    &_head {
      display: flex;
      justify-content: space-between;
      border-top: 1px solid $seaz-border;
      @media screen and (max-width: 600px) {
        flex-direction: column;
        align-items: flex-start;
      }

      &-title {
        display: flex;
        @media screen and (max-width: 600px) {
          padding-bottom: 3rem;
        }
      }
    }

    &_action {
      display: flex;
      align-items: center;
      @media screen and (max-width: 600px) {
        // width: 97%;
      }

      .form__row {
        margin-bottom: 0;
        @media screen and (max-width: 600px) {
          width: 100%;
        }
        @include mobile {
          margin-right: 1rem;
        }
      }

      .form-field--content-outline.form-field--content {
        height: 4rem;
        margin-right: 3.2rem;
        border: 1px solid $seaz-head-arrow;
        background-color: transparent;
        position: relative;
        padding-right: 4rem;

        .seazone__form-input {
          background-color: transparent;
        }

        .form-field--content-suffix {
          position: absolute;
          right: 0;
          top: 0;
          max-width: 4rem;
          width: 4rem;
          display: flex;
          background-color: $clr-primary-and-accent;
          justify-content: center;
          padding: 0;

          svg {
            path {
              fill: #ffffff;
            }

            circle {
              stroke: #ffffff;
            }
          }
        }

        @include tablet {
          margin-right: 0;
        }
      }
    }

    &_new {
      padding: 1.2rem 1.2rem;
      background-color: $seaz-edit-hover;
      margin-right: 1.6rem;
      position: relative;
    }

    &_file {
      padding: 1.3rem 1.2rem;
      background-color: $clr-primary-and-accent;
      position: relative;
    }

    &_row {
      display: flex;
      width: 70%;
      margin: 0 auto;
      padding-bottom: 2rem;
      @include desktop {
        width: 100rem;
      }
      @include laptop {
        width: 96%;
      }
    }

    &_item {
      height: 100%;
      background-color: $clr-white;
      display: flex;
      flex-direction: column;
      align-items: center;
      position: relative;
      cursor: pointer;
      box-sizing: border-box;
      color: $seaz-text;
      border: 1px solid $seaz-head-arrow;
      border-radius: 1.2rem;
      padding: 2.6rem 1.6rem;

      @include indent-not-last-of-type(0 0 1.2rem 0);

      @include tablet {
        padding: 1.4rem 1rem 2.4rem 1rem;
      }

      &.empty {
        .yaht-documents__folders_item-count {
          color: $seaz-text;
        }

        .yaht-documents__folders_item_img svg {
          fill: $seaz-head-arrow;
        }
      }

      &.alarm {
        .yaht-documents__folders_item_img svg {
          fill: $seaz-alarm;
        }
      }

      &.warning {
        .yaht-documents__folders_item_img svg {
          fill: $seaz-warning;
        }
      }

      &-header {
        position: relative;
        width: 8.4rem;
        height: 8.4rem;
      }

      &-count {
        color: $clr-white;
        z-index: 2;
        position: absolute;
        bottom: 2.8rem;
        left: 50%;
        transform: translate(-50%, 0);
        font-size: 1.6rem;
        font-weight: 500;
        line-height: 1.9rem;
      }

      &_img {
        svg {
          fill: $clr-primary-and-accent;
        }
      }

      &-text {
        text-align: center;
        color: $seaz-text;
        user-select: none;
      }

      &-lock {
        position: absolute;
        right: 1.6rem;
        top: 1.6rem;
      }
    }
  }

  &__info {
    &_col {
      display: flex;
      flex-direction: column;
      align-items: flex-start;

      &_label {
        display: block;
        font-size: 1rem;
        color: #828282;
        letter-spacing: 0.5px;
        text-transform: uppercase;
        padding-bottom: 5px;
      }
    }
  }

  &__box--edit {
    .yaht-documents__row:nth-of-type(2n) {
      .yaht-documents__row--content {
        background-color: $clr-white;
        @include mobile {
          background-color: $seaz-logo-border;
        }
      }
    }
  }
}

.seaz-uploader {
  display: flex;
  flex-direction: column;
  margin-top: 3rem;
  @include tablet {
    align-items: center;
  }
  @include mobile {
    align-items: flex-start;
  }

  &__divider {
    border-top: 1px solid #bdbdbd;
    padding-top: 4rem;
    @include mobile {
      padding-top: 0;
    }

    &.seaz-crew__edit_row_upload,
    &.seaz-crew__edit_additional_photo {
      margin-top: 4rem;

      @include tablet {
        width: 94%;
        margin: 4rem auto 0;
        align-items: center;
      }
    }
  }

  @include mobile {
    flex-direction: column;
  }

  &__header {
    margin-bottom: 2.3rem;
  }

  &__label {
    width: 100%;
    line-height: 2rem;
    color: $seaz-text;
    margin-right: 7rem;
    display: block;
    @include tablet {
      text-align: center;
      margin-right: 0;
    }
    @include mobile {
      margin-right: 0;
      padding: 1rem 0;
      text-align: inherit;
    }
  }

  &__box {
    display: flex;
    flex-direction: column;
    align-items: baseline;
    width: 100%;
    @include tablet {
      align-items: center;
    }
    @include mobile {
      flex-direction: row;
      align-items: center;
      justify-content: space-between;
      flex-wrap: wrap;
    }
  }

  &__video {
    position: relative;
    margin-right: 1rem;

    &_text {
      border: 1px dashed $seaz-border;
      box-shadow: 0 15px 35px rgba(0, 0, 0, 0.06);
      background-color: $clr-white;
      padding: 1.3rem 1.6rem;
      position: relative;
      margin-bottom: 3rem;

      &::before {
        content: '';
        height: 20px;
        width: 20px;
        position: absolute;
        left: 4rem;
        bottom: -11px;
        top: auto;
        background-color: $clr-white;
        margin: auto;
        transform: rotate(45deg);
        border: 1px dashed $seaz-border;
        border-top: none;
        border-left: none;
        @include tablet {
          left: 0;
          right: 0;
        }

        @include mobile {
          margin: 0;
          left: 7rem;
        }
      }

      @include tablet {
        width: 90%;
      }
      @include mobile {
        width: 100%;
      }
    }

    &_row {
      display: flex;
      flex-wrap: wrap;
      @include tablet-small {
        justify-content: center;
      }

      app-seazone-image-file {
        &:last-of-type {
          @include tablet {
            .yaht-edit__upload_img_box {
              margin-right: 0;
            }
          }
          @include tablet-small {
            .yaht-edit__upload_img_box {
              margin-right: 2rem;
            }
          }
        }
      }

      .yaht-edit__upload_img_box .seaz-uploader__video_btn {
        right: -10px;
        top: -7px;
      }

      app-seazone-document-file {
        display: flex;
        width: 100%;
        margin-bottom: 1rem;
      }
    }
  }

  &__choose {
    width: 100%;
    @include tablet {
      display: flex;
      justify-content: center;
      .seaz-uploader__rejected {
        position: absolute;
        right: 0;
        left: 0;
        margin: auto;
        text-align: center;
      }
    }
    @include mobile {
      width: fit-content;
    }

    &_btn {
      display: block;
      margin: 1.5rem 0;
      width: fit-content;
      font-weight: 500;
      padding: 1rem 2.4rem;

      &.disabled {
        background-color: #e7e7eb;
        color: $seaz-text;
        border-color: #a9a9a9;
        cursor: default;
        pointer-events: none;
      }

      @include tablet {
        margin-top: 2.4rem;
      }
      @include mobile {
        margin: 0;
      }
    }

    &_text {
      text-transform: uppercase;
      color: $seaz-document-count;
      padding-bottom: 1.6rem;
      display: block;
      @include tablet {
        display: none;
      }
    }

    &_drop {
      height: 160px;
      background: #fcfcfc;
      border: 1.5px dashed $dashed-border;
      width: 46rem;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      color: $seaz-document-count;
      text-align: center;
      @include tablet {
        display: none;
      }
    }
  }

  &__rejected {
    @extend .seaz-text-small;
    color: $seaz-alarm;
  }

  &__video {
    &_item {
      width: 14rem;
      height: 9rem;
    }

    &_btn {
      position: absolute;
      display: flex;
      align-items: center;
      justify-content: center;
      width: 2.4rem;
      height: 2.4rem;
      border-radius: 50%;
      background-color: $clr-white;
      border: 2px solid $seaz-logo-border;
      cursor: pointer;
      z-index: 2;
      right: -10px;
      top: -7px;

      &.icon-close::before {
        color: $seaz-alarm;
      }
    }

    &_play {
      position: absolute;
      cursor: pointer;
      background: $seaz-mulberry;
      border: 1.5px solid $clr-white;
      width: 5rem;
      height: 5rem;
      border-radius: 50%;
      right: 0;
      left: 0;
      margin: auto;
      top: 22%;
      z-index: 1;

      &::before {
        content: '';
        border: 10px solid transparent;
        border-left: 16px solid $clr-white;
        display: block;
        position: absolute;
        top: 30%;
        left: 40%;
      }
    }
  }

  &__cover {
    display: flex;
    position: relative;
    margin-bottom: 1.5rem;
    cursor: pointer;
    flex-wrap: wrap;
    @media screen and (max-width: 700px) {
      flex-direction: column;
    }

    &_item {
      position: relative;
      margin-right: 2rem;
      margin-bottom: 3rem;
      @media screen and (max-width: 700px) {
        margin-right: 0;
      }

      .icon-close {
        top: -0.9rem;
        right: -1rem;
        position: absolute;
        width: 2.4rem;
        height: 2.4rem;
        border-radius: 50%;
        background-color: $clr-white;
        border: 2px solid $seaz-bg;
        display: flex;
        -webkit-box-align: center;
        align-items: center;
        -webkit-box-pack: center;
        justify-content: center;
        font-size: 1rem;
        color: $seaz-alarm;
        z-index: 2;
      }
    }

    &_img {
      width: 29.2rem;
      height: 9.2rem;
      object-fit: cover;
      @include mobile {
        width: 100%;
        height: 9.9rem;
      }

      &_box {
        &:hover {
          &::before {
            content: '';
            background-image: url('/assets/img/resize-photo.svg');
            background-color: #351f73cc;
            display: block;
            width: 100%;
            position: absolute;
            height: 9.2rem;
            border-radius: 0;
            background-repeat: no-repeat;
            background-position: center;
            background-size: auto;
          }
        }
      }
    }

    &_checkbox {
      position: absolute;
      bottom: -2.3rem;
      left: 0.8rem;

      app-seazone-radio-item {
        display: flex;
        align-items: center;

        .form__radio-label {
          width: 1.8rem;
          height: 1.8rem;

          &::before {
            border-color: $seaz-border;
          }
        }

        .form__radio-label-text {
          color: $seaz-text;
        }

        .form__radio-input:checked + label:before {
          color: $seaz-mulberry;
          border-color: $seaz-mulberry;
        }

        .form__radio-input:checked + label:after {
          background: $seaz-mulberry;
        }
      }

      .form__checkbox-label {
        width: 2.4rem;
        height: 2.4rem;

        &:before {
          width: 2.4rem;
          height: 2.4rem;
          border: 1.5px solid $seaz-border;
          border-radius: 0;
        }
      }

      .form__checkbox-input:checked + label:before {
        color: $clr-primary-and-accent;
        background-color: $clr-white;
        border-color: $clr-white;
      }
    }
  }
}

.location__row {
  display: flex;
  flex-direction: column;

  .form__checkbox-label-text {
    color: $seaz-text;
  }

  .form__checkbox-label {
    width: 2.4rem;
    height: 2.4rem;

    &::before {
      width: 2.4rem;
      height: 2.4rem;
      border: 1.5px solid $seaz-border;
      border-radius: 0;
    }
  }

  .form__checkbox-input:checked + label:before {
    background-color: $seaz-mulberry;
    border-color: $seaz-mulberry;
  }

  app-seazone-checkbox {
    padding-bottom: 1rem;
    @include tablet-small {
      padding: 0 2rem;
    }
  }

  @include mobile {
    margin-left: 0;
  }

  .location__btn {
    display: flex;
    align-items: center;
  }
}

.yaht-edit {
  &__form {
    width: 50%;
    margin: 6rem auto;
    @include laptop {
      width: 70%;
    }
    @include tablet-big {
      width: 80%;
    }
    @include mobile {
      width: 90%;
    }

    .yaht-edit__location_row {
      flex-direction: column;

      .location__row {
        margin-top: 1rem;
      }
    }

    app-location-autocomplete {
      width: 100%;
      display: flex;
      flex-direction: column;

      .location__row {
        // margin-left: 21rem;

        //@include tablet {
        //  margin-left: 16rem;
        //}
        //@include tablet-small {
        //  margin-left: 13.4rem;
        //}
        @include mobile {
          margin: 0.8rem 0 0;
        }
      }

      .location__btn {
        margin-left: 0;
        align-self: flex-end;
      }
    }

    .seazone-form-field--wrapper.form-field--wrapper__row.form-field--wrapper .form-field--label {
      @media screen and (max-width: 650px) {
        max-width: 14rem;
      }
    }

    @include mobile {
      app-country-select {
        .form__block .dropdown-arrow {
          align-items: initial;
        }
      }
    }

    .media-uploader {
      @include tablet {
        .seaz-uploader,
        .seaz-uploader__box {
          align-items: flex-start;
        }

        .seaz-uploader__choose {
          justify-content: flex-start;
        }
      }
    }
  }

  &__company {
    &_row {
      &.form__row {
        @include mobile {
          margin-bottom: 0;
          .form__block-text span {
            max-width: initial;
            margin-right: 0;
          }
        }
      }
    }
  }

  &__btn {
    margin-right: 1.6rem;

    &_box {
      width: 65%;
      margin: 0 auto;
      border-top: 1px solid $seaz-border;
      padding: 3rem 0;
      display: flex;
      justify-content: flex-end;
      @include laptop {
        width: 70%;
      }
      @include tablet-big {
        width: 85%;
      }
      @include mobile {
        width: 90%;
        button {
          width: 48%;
        }
        .yaht-edit__btn {
          margin-right: 4%;
        }
      }
    }
  }

  &__upload {
    &_img {
      width: 12.3rem;
      height: 12.3rem;
      border-radius: 8px;
      object-fit: cover;

      &_box {
        position: relative;

        .seaz-uploader__video_btn {
          right: 1px;
          top: 5px;
        }

        &.editable {
          position: relative;
          cursor: pointer;

          &:hover {
            &::before {
              content: '';
              background-image: url('/assets/img/resize-photo.svg');
              background-color: #351f73cc;
              display: block;
              width: 100%;
              position: absolute;
              height: 100%;
              border-radius: 0;
              background-repeat: no-repeat;
              background-position: center;
              background-size: auto;
            }
          }
        }

        &.rounded {
          .yaht-edit__upload_img {
            border-radius: 50%;
          }

          &.editable:hover::before {
            border-radius: 50%;
          }
        }
      }

      &_btn {
        position: absolute;
        width: 2.4rem;
        height: 2.4rem;
        border-radius: 50%;
        background-color: $clr-white;
        border: 2px solid whitesmoke;
        display: flex;
        align-items: center;
        justify-content: center;
        top: -8px;
        right: -9px;

        &.icon-close::before {
          color: $seaz-alarm;
          font-size: 1rem;
        }
      }
    }
  }
}

.time-zone-row {
  display: block;
  width: 100%;

  &__container {
    @include d-align-items-center;
    margin-top: 1.6rem;

    .plug-label {
      @include plug-label(14rem, 0 7rem 0 0);
    }
  }

  @include tablet-big {
    &__container {
      .plug-label {
        @include plug-label(14rem, 0 2rem 0 0);
      }
    }
  }

  @include mobile {
    &__container {
      .plug-label {
        display: none;
      }
    }
  }
}

app-edit-boat {
  display: block;
  background-color: $seaz-bg;
}

app-phone-number.seaz-crew__phone {
  .form__row {
    width: 100%;
  }

  .form__block-text {
    color: $seaz-text;
    display: flex;
    align-items: center;
  }

  .phone-control-container {
    background: $clr-white;

    @include tablet-small {
      max-width: 100%;
    }
  }

  .phone-control-wrapper {
    @include tablet-small {
      margin-bottom: 2rem;
    }
  }

  .phone-code-container {
    border-left: none;
    margin-right: 0;
  }
}

.seaz-crew {
  &__edit_box {
    width: 66%;
    margin: 0 auto;

    .seaz__btn_outlined.seaz-crew__btn {
      font-weight: 500;
    }

    @include desktop {
      width: 100rem;
    }

    @include laptop {
      padding: 0 2rem;
      width: 100%;
    }

    @include tablet {
      padding: 0 0.5rem;
    }

    app-location-autocomplete {
      width: 100%;
      display: flex;
      flex-direction: column;

      .location__row app-seazone-checkbox {
        margin-left: 11.5rem;
        @include tablet {
          margin-left: 23.5rem;
        }
        @include mobile {
          margin-left: 0;
        }

        app-seazone-checkbox {
          padding-bottom: 0;
        }
      }

      .form__checkbox {
        margin: 2rem 0 1rem;
      }
    }

    &.seaz-crew__edit_skills {
      .seaz-crew__edit_row.form__row app-seazone-select .form-field--wrapper,
      .seaz-crew__edit_row.form__row app-seazone-checkbox-group-alternate .form-field--wrapper {
        width: 55.3%;
        flex-direction: row;
        align-items: center;

        .form-field--label {
          max-width: 8.6rem;
          min-width: 8.6rem;
        }

        @media screen and (max-width: 1500px) {
          width: 54rem;
        }
        @media screen and (max-width: 600px) {
          width: 40rem;
        }
        @include mobile {
          padding: 1rem 0;
          width: 100%;
          display: flex;
          flex-direction: column;
          align-items: flex-start;

          .form-field--label {
            max-width: 100%;
          }
        }
      }

      .seaz-crew__edit_row_small {
        .form-field--content {
          width: 57%;
          @include mobile {
            width: 100%;
          }

          &.disabled {
            background: $readonly-bg;
            border: 1px solid $seaz-head-arrow;

            .select-value {
              color: $readonly-text;
            }
          }
        }
      }

      .form-field--content-outline {
        &.active {
          border-color: $clr-primary-and-accent;
        }
      }
    }
  }

  &__courses_list {
    padding-bottom: 4rem;
    display: block;

    app-edit-profile-course-item:nth-of-type(2n-1) {
      background-color: $clr-white;
      display: block;
    }

    .seaz-edit__course_btns {
      width: 7rem;
      display: flex;
      align-items: center;
    }
  }

  &__bank_list {
    app-edit-bank-details-item:nth-of-type(2n-1) {
      .seaz-edit__bank_row {
        background-color: $clr-white;
        @include mobile {
          background-color: #fafafb;
        }
      }
    }
  }

  &__bank_note {
    color: $seaz-document-count;
    margin-bottom: 1.6rem;
    font-weight: 500;
    font-size: 1.6rem;

    &.foot-note {
      margin-bottom: 4rem;
    }

    @include tablet {
      font-size: 1.4rem;
    }
  }

  &__edit_row {
    &.form__row {
      margin-bottom: 1.6rem;
    }
  }

  &__edit_location {
    flex-direction: column;

    .location-autocomplete {
      align-items: flex-start;
    }
  }

  &__edit_row_upload {
    &--cover {
      app-crew-general-cover-uploader {
        width: 100%;
        @include tablet {
          width: 94%;
          margin: 0 auto;
        }
      }

      @include mobile {
        .seaz-uploader__label {
          padding-top: 3rem;
        }
        .seaz-uploader__choose {
          width: 100%;
          justify-content: flex-end;
        }
        .seaz-uploader__choose_btn_box {
          width: 100%;
        }
      }
    }

    app-seazone-media-uploader {
      width: 100%;
    }

    .yaht-edit__upload {
      border-top: 1px solid $seaz-border;
      padding-top: 5rem;
      flex-direction: column;
    }

    .yaht-edit__upload_label {
      @extend .seaz-h4;
      padding-bottom: 2rem;
      max-width: 100%;
    }

    .yaht-edit__upload_img_btn {
      top: 5px;
      right: 1px;
    }

    .yaht-edit__upload_choose_drop {
      width: 46rem;
      @include tablet {
        width: 80%;
      }
    }

    &--cover {
      .yaht-edit__upload_box {
        @include mobile {
          justify-content: flex-end;
        }
      }

      .seaz-gallery__item {
        position: relative;
        margin-bottom: 1.5rem;
        cursor: pointer;

        &:hover {
          &::before {
            content: '';
            background-image: url('/assets/img/resize-photo.svg');
            background-color: #351f73cc;
            display: block;
            width: 95%;
            position: absolute;
            height: 93px;
            background-repeat: no-repeat;
            background-position: center;
            background-size: auto;
          }
        }
      }
    }
  }

  &__edit_row_gallery {
    display: flex;
    flex-direction: column;

    @include tablet {
      width: 80%;
      margin: 0 auto;
    }
    @include mobile {
      width: 93%;
    }
  }

  &__edit_gallery {
    &_title {
      text-transform: uppercase;
      color: $seaz-document-count;
      padding-bottom: 2.4rem;
      @include mobile {
        text-align: center;
      }
    }

    &_box {
      display: flex;

      app-image-selection {
        display: flex;
        width: 100%;
        flex-wrap: wrap;
      }

      app-image-selection-item {
        @include mobile {
          width: 48%;
          margin-right: 4%;
          &:nth-of-type(2n) {
            margin-right: 0;
          }
        }
      }
    }
  }

  &__btn {
    margin-right: 1.6rem;

    &_box {
      width: 100%;
      margin: 0 auto;
      border-top: 1px solid $seaz-border;
      padding: 3rem 0;
      display: flex;
      justify-content: flex-end;
      @include tablet {
        width: 94%;
      }
      @include mobile {
        &.form__row {
          flex-direction: row;

          button {
            width: 49%;
          }
        }
      }
    }
  }

  &__gallery {
    &_list.form__row {
      display: inline;
    }
  }

  &__contacts {
    &_row {
      width: 508px;
      align-items: center;
      @include tablet-small {
        flex-direction: column;
        align-items: flex-start;
        position: relative;
        width: 100%;
        &.form__row {
          margin-bottom: 0;
        }
        .form__block.form__block--row {
          flex-direction: column;
        }
        .seaz-red-close {
          position: absolute;
          right: 0;
          top: 0;
        }
      }

      app-phone-number.seaz-crew__phone {
        width: auto;
        @include tablet-small {
          width: 100%;
        }

        .form__block-text {
          width: 11rem;
          box-sizing: border-box;
          padding-right: 1rem;
          @include tablet-small {
            padding-bottom: 0.8rem;
          }
        }

        .phone-control-container {
          max-width: 30rem;

          @include tablet-small {
            max-width: 100%;
          }
        }
      }

      &.form__row {
        app-seazone-input {
          flex-grow: 0;
          flex-shrink: 1;
          width: auto;
          @include tablet-small {
            width: 100%;
            .form-field--wrapper__row {
              flex-direction: column;

              .form-field--label {
                padding-bottom: 1rem;
              }
            }
          }

          .seazone-form-field--wrapper {
            .form-field--label {
              margin-right: 0;
              max-width: 11rem;
              min-width: 11rem;
              box-sizing: border-box;
              padding-right: 1rem;
            }
          }

          .form-field--content {
            max-width: 30rem;
            min-width: 30rem;
            @include tablet-small {
              max-width: 100%;
              min-width: 100%;
              margin-bottom: 2rem;
            }

            &.disabled {
              background: $readonly-bg;
              border: 1px solid $seaz-head-arrow;

              .seazone__form-input {
                background: $readonly-bg;
                color: $readonly-text;
              }
            }
          }

          .seaz-crew__contacts_add {
            flex-grow: 1;
            width: 100%;
          }
        }
      }
    }

    &_add {
      margin-left: 1.7rem;
      @include tablet-small {
        position: initial;
        margin-left: 0;
        font-weight: 500;
        width: 100%;
        display: flex;
        justify-content: center;
        flex-direction: row-reverse;
        padding: 1.4rem 0;
        margin-bottom: 2rem;
        &::before {
          margin-right: 0;
          margin-left: 0.8rem;
        }
      }
    }

    &_social {
      &_row {
        margin-left: 11rem;
        @include tablet-small {
          margin-left: 0;
        }
        @include mobile {
          &.form__row {
            flex-direction: row;
            margin-bottom: 0.8rem;
            flex-wrap: wrap;

            app-seazone-checkbox {
              margin-bottom: 1rem;
            }
          }
        }

        &.form__row:last-child {
          margin-bottom: 4rem;
          @include mobile {
            margin-bottom: 2rem;
          }
        }

        app-seazone-checkbox {
          .form__checkbox {
            margin-right: 2.4rem;
          }

          .form__checkbox-label {
            width: 2.4rem;
            height: 2.4rem;

            &:before {
              border: 1.5px solid $seaz-border;
              border-radius: 0;
              width: 2.4rem;
              height: 2.4rem;
            }
          }

          .form__checkbox-label-text {
            color: $seaz-text;
          }

          .form__checkbox-input:checked + label:before {
            background-color: $seaz-mulberry;
            border-color: $seaz-mulberry;
          }
        }

        app-seazone-radio-item {
          display: flex;
        }
      }
    }

    &_box {
      border-bottom: 1px solid $seaz-border;
      padding-top: 4rem;
      @include tablet {
        width: 94%;
        margin: 0 auto;
      }
      @include tablet-small {
        position: relative;
        padding-bottom: 6rem;
      }
      @include mobile {
        padding-top: 2.4rem;
      }
    }

    &_other {
      width: 50.8rem;
      @include tablet-small {
        width: 100%;
      }

      &_box {
        padding: 4rem 0 6rem;
        @include tablet {
          width: 94%;
          margin: 0 auto;
        }
      }

      &.form__row {
        app-seazone-input {
          .form-field--wrapper__row .form-field--label {
            max-width: 11rem;
            margin-right: 0;
          }
        }
      }
    }
  }

  &__edit {
    &_documents_list {
      .yaht-documents__name,
      .yaht-documents__date {
        width: 50%;
        padding-right: 1rem;
      }

      .yaht-documents__date {
        width: 80%;
        @include tablet {
          width: 40%;
        }
      }

      .yaht-documents__name {
        @include tablet {
          padding-bottom: 1.9rem;
        }
        @include mobile {
          width: 88%;
        }
      }

      .yaht-documents__snooze {
        width: 60%;
        padding-right: 1rem;
        @include tablet {
          width: 70%;
        }
      }

      .yaht-documents__row--content {
        padding: 2.5rem 2rem;
        @include tablet {
          width: 100%;
        }
        @include mobile {
          align-items: baseline;
        }
      }

      .yaht-documents__action {
        width: 15%;
        @include tablet {
          width: 20%;
        }
        @include mobile {
          width: 23%;
        }
      }

      .yaht-documents__row--left {
        @include tablet {
          flex-direction: column;
        }
      }

      .yaht-documents__row_info--left {
        display: flex;
        width: 69%;
        align-items: center;
        @include mobile {
          width: 100%;
        }
      }
    }

    &_preferences {
      .form-field--content.form-field--content-outline {
        border-color: $seaz-head-arrow;
      }

      .seaz-crew__edit_title {
        @include tablet {
          padding: 0 2rem 3rem 2rem;
        }
      }
    }

    &_title {
      color: $seaz-text;
      padding-bottom: 3rem;
    }

    &_box {
      &.seaz-crew__edit_preferences {
        .seaz-crew__edit_row.form__row {
          app-seazone-select {
            .form-field--wrapper {
              width: 60rem;
              @include tablet {
                width: 100%;
              }
            }

            .form-field--label {
              max-width: 20rem;
              @include tablet-small {
                max-width: 13rem;
              }
              @include mobile {
                max-width: 100%;
              }
            }
          }
        }
      }
    }

    &_length {
      &_box {
        @include tablet {
          display: flex;
          align-items: center;
          flex-wrap: wrap;
          .form__checkbox {
            margin-left: 1rem;
          }
        }
        @include mobile {
          flex-direction: column;
          align-items: flex-start;
        }
      }

      &_row {
        display: flex;
        align-items: center;
        margin-bottom: 2rem;
        @include mobile {
          width: 100%;
          align-items: flex-end;
          span {
            padding-bottom: 1.4rem;
          }
        }

        app-seazone-form-field {
          padding-right: 1rem;

          .form-field--wrapper.form-field--wrapper__row {
            .form-field--label {
              margin-right: 2rem;
              width: auto;
            }
          }
        }

        app-seazone-input {
          @include tablet {
            .form-field--content {
              width: 6.8rem;
            }
          }
          @include mobile {
            .form-field--content {
              width: 100%;
            }
          }
        }
      }
    }

    &_row--length {
      width: 60rem;
      @include tablet {
        width: 100%;
        padding: 0 2rem;
      }

      .form__block-error {
        margin-bottom: 0;
        bottom: 6px;
      }

      .seaz-crew__edit_label {
        width: 20rem;
        margin-right: 1.5rem;
        flex-shrink: 0;
        color: $seaz-text;
        @include tablet-small {
          max-width: 13rem;
        }
        @include mobile {
          max-width: 100%;
          margin-bottom: 0.8rem;
          font-size: 1.4rem;
        }
      }

      .form__checkbox {
        padding-bottom: 2.5rem;

        .form__checkbox-label {
          width: 2.4rem;
          height: 2.4rem;
        }

        .form__checkbox-label-text {
          color: $seaz-mulberry;
        }

        .form__checkbox-label:before {
          width: 2.4rem;
          height: 2.4rem;
          border-color: $seaz-mulberry;
        }

        .form__checkbox-input:checked + label:before {
          background-color: $seaz-mulberry;
        }
      }

      .form__block-error {
        white-space: nowrap;
      }
    }

    &_row_content--salary {
      display: flex;
      @include mobile {
        display: flex;
        align-items: flex-end;
        width: 100%;
        .form-field--content.form-field--content-outline {
          width: 100%;
        }
      }
    }

    &_row--salary {
      width: 60rem;
      align-items: center;
      @include tablet {
        width: 100%;
        padding: 0 2rem;
      }
      @include mobile {
        align-items: flex-start;
        width: 100%;
      }

      &.form__row {
        margin-bottom: 3rem;
      }

      .form__block-error {
        white-space: nowrap;
        padding: 4px 0;
      }

      .seaz-crew__edit_label {
        width: 20rem;
        margin-right: 1.5rem;
        flex-shrink: 0;
        color: $seaz-text;
        @include tablet-small {
          max-width: 13rem;
        }
        @include mobile {
          max-width: 100%;
          font-size: 1.4rem;
          margin-bottom: 0.8rem;
        }
      }

      app-seazone-form-field {
        .seazone-form-field--wrapper.form-field--wrapper__row .form-field--label {
          color: $seaz-document-count;
          margin-right: 2rem;
          width: auto;
        }
      }

      app-seazone-input {
        width: auto;

        .form-field--content {
          margin-right: 2rem;
          width: 10rem;
        }

        @include mobile {
          width: 100%;
          .form-field--content.form-field--content-outline {
            width: 100%;
          }
        }
      }

      app-seazone-select {
        .form-field--content {
          width: 10rem;
          color: $seaz-text;
        }
      }
    }

    &_head_row {
      display: flex;
      align-items: center;
      margin-bottom: 2rem;
      justify-content: space-between;
      padding-bottom: 1.7rem;
      padding-top: 3rem;

      .seaz__btn_outlined_plus {
        font-weight: 500;
      }

      @include tablet {
        padding: 3rem 2rem 1.7rem 2rem;
        flex-direction: column;
        align-items: flex-start;

        .seaz__btn_outlined_plus {
          width: 100%;
          display: flex;
          justify-content: center;
        }
      }

      &--right {
        display: flex;
        align-items: center;

        app-seazone-checkbox {
          margin-right: 2rem;
        }
      }

      &--docs {
        display: flex;
        align-items: center;

        @include tablet {
          align-items: flex-start;
        }
      }

      &--actions {
        @include d-align-items-center;
        margin-left: 1rem;

        .seaz__btn_outlined {
          height: 4rem;
          margin-left: 1.2rem;
        }

        .seaz__btn_outlined_plus::after {
          top: 0;
        }

        @include tablet {
          width: 100%;
          flex-direction: column;
          margin-left: 0;

          .seaz__btn_outlined,
          .seaz__btn_rounded {
            width: 100%;
            margin: 1.2rem 0 0 0;
          }
        }
      }

      app-seazone-form-field {
        .form-field--wrapper {
          width: auto;
        }

        .form-field--wrapper.form-field--wrapper__row .form-field--label {
          margin-right: 1rem;
          width: auto;
        }

        .form-field--content {
          margin-right: 1rem;
        }
      }

      .seaz-crew__edit_title {
        padding-bottom: 0;
        @include tablet {
          padding-bottom: 2.4rem;
        }
      }
    }

    &_language {
      &_row {
        display: flex;
        justify-content: space-between;
        padding-bottom: 1.7rem;
        align-items: center;

        .seaz-crew__edit_title {
          padding-bottom: 0;
        }
      }
    }

    &_additional_photo {
      app-seazone-media-uploader {
        width: 100%;
        @include mobile {
          padding-bottom: 4rem;
        }

        app-seazone-image-file {
          .yaht-edit__upload_img_box {
            position: relative;
            margin-right: 2rem;
            margin-bottom: 1rem;
          }

          .yaht-edit__upload_img {
            width: 14rem;
            height: 10rem;
            border-radius: 0;
          }
        }
      }
    }

    &_bank {
      &_reminder {
        padding-bottom: 3rem;
        color: $seaz-about-text;
        line-height: 2.6rem;
        @include tablet {
          padding: 0 2rem 3rem 2rem;
          box-sizing: border-box;
        }

        &_save {
          position: absolute;
          color: $seaz-about-text;
          line-height: 2.6rem;
          top: 0;
          right: 0;
        }
      }

      &_details {
        .seaz-crew__edit_title {
          padding-top: 4rem;
        }

        .seaz-uploader__document {
          width: 55%;
          @media screen and (max-width: 1000px) {
            width: 70%;
          }
          @include tablet {
            width: 100%;
            padding: 0 2rem;
            box-sizing: border-box;
          }
          @include mobile {
            flex-direction: column;
          }

          .seaz-uploader__document_label {
            width: 10rem;
            margin-right: 2rem;
            color: $seaz-text;
            @include tablet {
              width: 20rem;
            }
            @include tablet-small {
              width: 10rem;
            }
            @include mobile {
              width: inherit;
              font-size: 1.4rem;
              margin-bottom: 0.8rem;
            }
          }

          .seaz-uploader__document_file .icon-close {
            margin-left: 0.6rem;
            width: 1.6rem;
            height: 1.6rem;
            font-size: 0.8rem;
            display: flex;
            align-items: center;
            justify-content: center;
          }

          .seaz-uploader__document_file {
            padding-bottom: 0.8rem;
          }
        }

        .seaz-uploader__document_box {
          @include mobile {
            .seaz-uploader__document_box_text {
              display: none;
            }
            .seaz-uploader__document_box_drop {
              display: none;
            }
          }
        }
      }
    }
  }

  &__navigation {
    display: flex;
    width: 100%;
    padding-bottom: 3rem;

    &_item {
      width: 33%;
      border: 1px solid $seaz-border;
      position: relative;
      @media screen and (max-width: 1100px) {
        width: 34%;
      }

      a {
        display: flex;
        justify-content: center;
        width: 100%;
        padding: 1.6rem 3rem;
        align-items: center;
        white-space: nowrap;
        color: $seaz-document-count;
        font-weight: 500;
        text-transform: uppercase;
      }

      &.active-item {
        border-color: $clr-primary-and-accent;

        a {
          color: $clr-primary-and-accent;
        }
      }
    }
  }

  &__list {
    &-preview {
      &:nth-child(2n + 1) {
        .boat__wrap-card {
          background-color: #f5f5f5;
        }
      }

      .seaz-crew__card_avatar_box {
        display: flex;
        flex-direction: column;
        align-items: center;
      }

      .seaz-cv__badges_status {
        @include laptop {
          width: auto;
        }
      }
    }
  }

  &__controls {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 1.6rem 3rem 0;
    box-sizing: border-box;
    @media screen and (max-width: 1200px) {
      flex-direction: column;
      padding-right: 0;
      align-items: flex-start;
    }

    &_left {
      display: flex;
      @media screen and (max-width: 1200px) {
        width: 100%;
      }
      @include tablet-small {
        flex-direction: column;
      }
    }

    &_search {
      width: 25.7rem;
      @media screen and (max-width: 1200px) {
        width: 100%;
      }

      app-seazone-form-field {
        .form-field--content-outline {
          border-radius: 0;
          border: 0.1rem solid $seaz-head-arrow;
          background-color: initial;

          .seazone__form-input {
            background-color: initial;
          }

          .form-field--content-suffix {
            position: absolute;
            top: 0;
            right: 0;
            background-color: $clr-primary-and-accent;
            max-width: 4rem;
            width: 4rem;
            display: flex;
            align-items: center;
            justify-content: center;
            padding: 0;
          }
        }
      }
    }

    &_badges {
      display: flex;
      margin-left: 2.4rem;
      align-items: center;
      @media screen and (max-width: 900px) {
        flex-shrink: 0;
      }
      @include tablet-small {
        margin: 1rem 0 0 0;
        justify-content: center;
      }

      &_item {
        background-color: $clr-white;
        padding: 0.7rem 1.6rem 0.4rem;
        display: flex;
        align-items: center;
      }

      &_count {
        color: $clr-primary-and-accent;
      }

      &_text {
        color: $seaz-document-count;
        margin-left: 1.2rem;
      }
    }

    &_btns {
      display: flex;
      align-items: center;
      @media screen and (max-width: 1200px) {
        width: 100%;
        margin-top: 2.4rem;
      }
      @media screen and (max-width: 600px) {
        flex-direction: column;
      }

      :not(:first-child) {
        margin-left: 1.6rem;
      }
    }

    &_add {
      display: flex;
      align-items: center;
      padding: 1.2rem 3.2rem;
      font-size: 1.4rem;
      @media screen and (max-width: 900px) {
        width: 100%;
        justify-content: center;
      }

      span {
        margin-left: 1rem;
      }
    }

    &_download {
      background-color: $seaz-bg;
      color: $clr-primary-and-accent;
      display: flex;
      align-items: center;
      justify-content: center;
      cursor: pointer;
      position: relative;
      font-size: 1.4rem;
      font-weight: 500;
      padding: 1.3rem 3.2rem;
      text-transform: uppercase;
      white-space: nowrap;

      app-seazone-icon {
        margin-right: 1.5rem;
      }

      @media screen and (max-width: 900px) {
        width: 100%;
      }
      @media screen and (max-width: 600px) {
        margin: 2.4rem 0 0;
      }
    }
  }

  &__card {
    &_avatar {
      &.card__wrap-img.avatar {
        border: none;
        position: relative;
      }

      &_position {
        position: absolute;
        width: 3.2rem;
        height: 3.2rem;
        border: 2px solid $clr-white;
        border-radius: 50%;
        bottom: 0.5rem;
        right: 0;
      }

      &_box {
        margin-right: 2.4rem;
      }
    }

    &_completion {
      padding-top: 0.8rem;
      padding-bottom: 0.6rem;

      .seaz-profile__info_completion {
        align-items: center;
        flex-direction: row-reverse;
      }

      &_title {
        padding: 0 0.6rem 0.4rem 0;
      }
    }

    &_title {
      &.card__title {
        color: $clr-primary-and-accent;
        margin-bottom: 0.4rem;
      }
    }

    &_name {
      color: $seaz-text;
      padding-bottom: 0.4rem;
    }

    &_row {
      display: flex;
      align-items: center;
      padding-bottom: 2rem;
    }

    &_upload_date {
      margin-bottom: 0;
      color: $seaz-document-count;
      text-transform: uppercase;
    }

    &_btns {
      .card__btn {
        font-weight: 500;
        font-size: 1.2rem;
        width: 18.4rem;

        .seaz__btn_primary {
          padding-left: 1.7rem;
        }
      }
    }

    &_contract {
      .card__head {
        text-transform: uppercase;
        padding-bottom: 0.5rem;
        color: $seaz-document-count;
      }
    }

    &_info_text {
      color: $clr-primary-and-accent;
    }

    &_date {
      &_group {
        width: 35.7rem;
        display: flex;
        justify-content: space-between;
        align-items: flex-end;
        @media screen and (max-width: 1100px) {
          width: 100%;
          order: 2;
        }

        app-seazone-icon {
          padding-bottom: 0.6rem;
          @media screen and (max-width: 1100px) {
            display: none;
          }
        }

        .card__head {
          text-transform: uppercase;
          padding-bottom: 0.5rem;
          color: $seaz-document-count;
        }
      }
    }

    &_salary {
      display: flex;
      justify-content: flex-end;
      @media screen and (max-width: 1100px) {
        order: 1;
      }

      .card__head {
        text-transform: uppercase;
        padding-bottom: 0.5rem;
        color: $seaz-document-count;
      }
    }

    &_pending {
      text-transform: uppercase;
      letter-spacing: 0.1em;
      font-weight: 500;
      padding: 0.8rem 0.8rem 0.6rem;
      background-color: $seaz-warning;
      color: $clr-white;
      display: inline-flex;
      align-items: center;
      justify-content: center;
      margin-right: 0.8rem;
    }

    &_info_box {
      padding-top: 1rem;
    }

    &_status {
      &_box {
        display: flex;
        align-items: center;
        @include tablet-small {
          flex-direction: column;
          align-items: flex-start;
          margin-bottom: 1rem;
        }
      }
    }
  }

  &__status {
    text-transform: uppercase;
    letter-spacing: 0.1em;
    font-weight: 500;

    div {
      padding: 0.8rem 0.8rem 0.6rem;
    }

    .work-day {
      background-color: #d8efd4;
      color: $success-color;
    }

    .vacation {
      background-color: #e1dfe8;
      color: $clr-primary-and-accent;
    }

    .unpaid-vacation {
      background-color: $seaz-document-count;
      color: $clr-white;
    }

    .unknown {
      background-color: $seaz-alarm-bg;
      color: $seaz-alarm;
    }

    .sick-day {
      background-color: #fdefc8;
      color: #94792b;
    }

    .rest-day {
      background-color: $seaz-head-arrow;
      color: #4f4f4f;
    }

    .available {
      background-color: $seaz-edit-hover;
      color: $clr-white;
    }
  }

  &__download {
    &_title {
      color: $clr-primary-and-accent;
      padding-bottom: 6rem;
    }

    &_all {
      .form__checkbox {
        flex-direction: row-reverse;
        padding-right: 2.8rem;
        padding-bottom: 1rem;

        .form__checkbox-label-text {
          color: $seaz-text;
          margin-right: 1.6rem;
          font-weight: 500;
        }

        .form__checkbox-label {
          width: 2.4rem;
          height: 2.4rem;
        }

        .form__checkbox-label:before {
          width: 2.4rem;
          height: 2.4rem;
          border: 1.5px solid $seaz-border;
        }

        .form__checkbox-input:checked + label:before {
          background-color: $seaz-mulberry;
          border-color: $seaz-mulberry;
        }
      }
    }

    &_crew_item {
      &:nth-child(even) {
        background-color: $clr-ashy-grey;
      }

      .form__checkbox {
        display: flex;
        flex-direction: row-reverse;
        align-items: center;
        justify-content: space-between;
        padding: 1.3rem 2.8rem 1.3rem 1.3rem;

        .form__checkbox-label {
          width: 2.4rem;
          height: 2.4rem;
        }

        .form__checkbox-label:before {
          width: 2.4rem;
          height: 2.4rem;
          border: 1.5px solid $seaz-border;
        }

        .form__checkbox-input:checked + label:before {
          background-color: $seaz-mulberry;
          border-color: $seaz-mulberry;
        }
      }

      &_info {
        display: flex;
        align-items: center;
      }

      &_img {
        width: 4.8rem;
        height: 4.8rem;
        object-fit: cover;
        margin-right: 1.6rem;
        border-radius: 50%;
      }

      &_text {
        display: flex;
        flex-direction: column;
      }

      &_title {
        color: $clr-primary-and-accent;
        padding-bottom: 0.8rem;
      }

      &_name {
        color: $seaz-document-count;
      }
    }

    &_btns {
      width: 100%;
      margin: 0 auto;
      padding: 3rem 0;
      display: flex;
      justify-content: flex-end;

      button:not(:last-child) {
        margin-right: 1.2rem;
      }

      @include tablet {
        flex-direction: column;
        button:not(:last-child) {
          margin: 0 0 1.2rem;
        }
      }
    }
  }

  &__cv {
    .seaz-cv__info_progress {
      margin-top: 8rem;
      width: 22%;
      @include laptop {
        margin-top: 13rem;
        width: 20rem;
        flex-shrink: 0;
        .seaz-profile__info_line {
          display: none;
        }
      }
      @include tablet-big {
        width: 17rem;
      }
      @media screen and (max-width: 670px) {
        width: 15rem;
        margin-top: 0;
        align-items: center;
      }
    }

    .seaz-cv__info_main {
      width: 35%;
      flex-shrink: 0;
      padding-top: 4rem;
      @media screen and (max-width: 1300px) {
        width: 38%;
      }
      @include laptop {
        padding-top: 16rem;
        width: 21.3rem;
      }
      @include tablet-big {
        width: 18rem;
      }
      @media screen and (max-width: 670px) {
        padding-top: 0;
        width: 67%;
      }
      @include mobile-big {
        width: 50%;
      }
      @media screen and (max-width: 350px) {
        width: 45%;
      }
    }

    .seaz-cv__details {
      width: 35%;
      @media screen and (max-width: 1300px) {
        width: 38%;
      }
      @include laptop {
        width: 55%;
        padding-left: 15px;
      }
      @media screen and (max-width: 670px) {
        width: 100%;
        justify-content: flex-end;
      }
    }

    .seaz-cv__wrapper,
    .seaz-cv__info,
    .seaz-cv__slider,
    .seaz-cv__navigation,
    .seaz-cv__head {
      width: 122.4rem;

      @include desktop {
        width: 92.4rem;
      }
      @include laptop {
        width: 100%;
      }
    }

    .seaz-cv__navigation {
      @include desktop {
        overflow: auto;
        justify-content: flex-start;
      }
      @include tablet {
        padding: 0 0 3.2rem 3rem;
      }
      @include mobile {
        padding: 0 0 2.4rem 0;
      }
    }

    .seaz-cv__navigation_item {
      padding: 0 1.2rem;
      @include desktop {
        flex-shrink: 0;
      }
      @include mobile {
        font-size: 1.2rem;
      }
    }

    .seaz-cv__documents_show_text {
      background-color: $seaz-logo-border;
    }

    .seaz-cv__slider,
    .seaz-cv__info {
      @media screen and (max-width: 1300px) {
        width: 100rem;
      }
      @include laptop {
        width: 100%;
      }
      @media screen and (max-width: 670px) {
        padding-top: 1.7rem;
      }
    }

    .seaz-cv__slider {
      @include laptop {
        height: 268px;
        app-image-carousel {
          height: 100%;
        }
      }
      @media screen and (max-width: 670px) {
        height: 160px;
      }
    }
  }
}

.seaz-red-close {
  background-color: $clr-white;
  width: 2.4rem;
  height: 2.4rem;
  border-radius: 50%;
  font-size: 1rem;
  color: $seaz-alarm;
  margin-left: 1.6rem;
  cursor: pointer;
}

.seaz-crew__edit_row {
  app-location-select {
    .form__block {
      width: 40.1%;
      @media screen and (max-width: 1440px) {
        width: 55%;
      }
      @media screen and (max-width: 1000px) {
        width: 70%;
      }
      @include tablet {
        width: 100%;
        padding: 0 2rem;
        box-sizing: border-box;

        .dropdown-arrow {
          right: 3.3rem;
        }
      }
      @include mobile {
        padding-bottom: 0.8rem;
      }

      .form__block-text span {
        font-size: 1.4rem;
        line-height: 2rem;
        color: $seaz-text;
        margin-right: 1.5rem;
        width: 100%;
        max-width: 10rem;
        @include tablet {
          max-width: 20rem;
        }
        @include tablet-small {
          max-width: 10rem;
        }
      }
    }
  }

  .location-region {
    &.location-autocomplete {
      display: block;
    }
  }
}

.seaz-crew__edit_row.form__row.seaz-crew-text-area-box {
  margin-bottom: 3rem;
}

.form-field--label.toggle-label {
  font-size: 1.4rem;
  font-weight: 400;
  color: $seaz-text;
}

.seaz-modal__offer .seaz-crew__edit_box .seaz-crew__edit_row.form__row.seaz-crew__edit_row-covid {
  @include tablet {
    align-items: center;
    margin-top: 1.6rem;
  }

  @include mobile {
    flex-direction: row !important;

    .seaz-crew__edit_flexible {
      margin-top: 0;
    }

    .form-field--label,
    app-seazone-checkbox .form__checkbox {
      margin-bottom: 0;
    }
  }
}

.seaz-crew__edit_box {
  .seaz-crew__edit_row.form__row {
    @include mobile {
      margin-bottom: 0.6rem;
    }

    app-seazone-input,
    app-date-control,
    app-seazone-select,
    app-seazone-checkbox-group-alternate,
    app-toggle-checkbox,
    app-seazone-text-area,
    app-location-autocomplete,
    app-location-region-autocomplete {
      .form-field--wrapper {
        width: 40.1%;
        align-items: center;
        @media screen and (max-width: 1440px) {
          width: 55%;
        }
        @media screen and (max-width: 1000px) {
          width: 70%;
        }
        @include tablet {
          width: 100%;
          padding: 0 2rem;
          box-sizing: border-box;
        }
        @include mobile {
          padding-bottom: 0.8rem;
        }
      }

      .form-field--label,
      .form__block-text,
      .form-field__label {
        font-size: 1.4rem;
        line-height: 2rem;
        color: $seaz-text;
        margin-right: 1.5rem;
        width: 100%;
        max-width: 10rem;
        @include tablet {
          max-width: 20rem;
        }
        @include tablet-small {
          max-width: 10rem;
        }
        @include mobile {
          max-width: initial;
          margin-right: 0;
        }
      }

      .form__block {
        flex-direction: row;
        @include mobile {
          flex-direction: column;
        }
      }
    }

    .location__row {
      width: 40.1%;
      @media screen and (max-width: 1440px) {
        width: 55%;
      }
      @media screen and (max-width: 1000px) {
        width: 70%;
      }
      @include tablet {
        width: 100%;
      }
      @include mobile {
        padding-bottom: 0.8rem;
      }
    }

    app-date-control {
      max-width: 100%;

      .form-field--content-suffix {
        max-width: 3.5rem;
        @include mobile {
          height: 2.4rem;
        }
      }

      @include tablet {
        max-width: 100%;
      }
      @include mobile {
        max-width: 100%;
        padding-bottom: 0.8rem;
      }

      .picker-toggle {
        top: 26%;
        @include mobile {
          top: 50%;
        }
      }
    }

    app-toggle-checkbox {
      display: flex;
      align-items: center;

      .form-field__label {
        max-width: fit-content;
      }
    }

    app-location-select {
      .form__row {
        margin-bottom: 1rem;
      }
    }
  }

  .seaz-crew__edit_row--textarea {
    app-seazone-text-area {
      width: 40.1%;
      @media screen and (max-width: 1440px) {
        width: 55%;
      }
      @media screen and (max-width: 1000px) {
        width: 70%;
      }
      @include tablet {
        width: 100%;
        padding: 0 2rem;
        box-sizing: border-box;
      }
    }

    .form-field--wrapper__row.form-field--wrapper {
      .form-field--label {
        max-width: 10rem;
        margin-right: 1.5rem;
        @include tablet {
          max-width: 20rem;
        }
        @include tablet-small {
          max-width: 10rem;
        }
      }

      .form-field--content {
        height: 15rem;
      }
    }
  }

  .seaz-crew__edit_row--caver-letter {
    width: 40.1%;

    .form-field--wrapper__row .form-field--label,
    .seaz-uploader__document_label {
      max-width: 10rem;
      margin-right: 1.5rem;
    }

    .seaz-uploader__document_file span {
      word-break: break-word;
    }

    @media screen and (max-width: 1440px) {
      width: 55%;
    }

    @media screen and (max-width: 1000px) {
      width: 70%;
    }

    @include tablet {
      width: 100%;
      padding: 0 2rem;
      box-sizing: border-box;

      .form-field--wrapper__row .form-field--label {
        max-width: 20rem;
      }

      .seaz-uploader__document_label {
        width: 20rem;
        max-width: 20rem;
      }
    }

    @media screen and (max-width: 560px) {
      .form-field--wrapper__row .form-field--label {
        max-width: 10rem;
      }

      .seaz-uploader__document_label {
        width: 10rem;
        max-width: 10rem;
      }
    }

    @include mobile {
      margin-top: 4rem;

      .seaz-modal__document_uploader {
        padding-bottom: 0;
      }

      .seaz-uploader__document {
        flex-direction: column;
      }

      .seaz__btn_small-outlined {
        width: 100%;
        text-align: center;
      }
    }
  }
}

app-gallery-item {
  @include mobile {
    width: 48%;
    margin-right: 3%;
    &:nth-of-type(2n) {
      margin-right: 0;
    }
  }
}

.seaz-gallery {
  &__box {
    display: flex;
    flex-wrap: wrap;
  }

  &__item {
    display: flex;
    flex-direction: column-reverse;
    margin-bottom: 1.6rem;

    app-seazone-radio-item {
      display: flex;
      align-items: center;
      padding-top: 0.5rem;

      .form__radio-label {
        width: 1.8rem;
        height: 1.8rem;
        padding-top: 0.5rem;

        &::before {
          border-color: $seaz-border;
        }
      }

      .form__radio-input:checked + label:before {
        border-color: $seaz-mulberry;
        color: $seaz-mulberry;
      }

      .form__radio-input:checked + label:after {
        background: $seaz-mulberry;
      }
    }

    img {
      width: 14rem;
      height: 10rem;
      object-fit: cover;
      margin-right: 1.6rem;
      @include mobile {
        width: 100%;
        margin-bottom: 0.6rem;
        margin-right: 0;
      }
    }

    &.active {
      img {
        border: 1.5px solid $clr-primary-and-accent;
        padding: 0.8rem;
      }
    }
  }
}

.seaz-edit {
  &__skills {
    &_box {
      padding: 6rem 0;

      &:not(:last-of-type) {
        border-bottom: 1px solid $seaz-border;
      }

      .form-field--content.form-field--content-outline {
        max-width: 42.4rem;
        width: 42.4rem;
        @media screen and (max-width: 1000px) {
          max-width: 36.4rem;
          width: 36.4rem;
        }
        @media screen and (max-width: 600px) {
          max-width: 25rem;
          width: 25rem;
        }
        @include mobile {
          width: 100%;
          max-width: 100%;
        }
      }
    }

    &_select_text {
      padding-bottom: 3rem;
      display: block;
      color: $seaz-document-count;
      @include mobile {
        padding-bottom: 1.2rem;
        line-height: 1.8rem;
      }
    }
  }

  &__photo_text {
    padding-top: 1rem;
    color: $seaz-document-count;

    @include tablet {
      margin-bottom: 2rem;
    }
  }
}

.seaz-language {
  &__list {
    margin-bottom: 6rem;

    app-edit-profile-language-item {
      &:nth-of-type(2n - 1) {
        .seaz-language__list_item_box {
          background-color: $clr-white;
        }
      }
    }

    &_item {
      display: flex;
      width: 66%;
      margin: 0 auto;
      align-items: center;
      padding: 3rem 0;
      @include tablet {
        width: 80%;
      }
      @include mobile {
        width: 95%;
      }

      &_language,
      &_level {
        display: flex;
        flex-direction: column;
        width: 25%;
        @include tablet {
          width: 40%;
        }
        @include mobile {
          width: 27%;
        }
      }

      &_label {
        color: $seaz-document-count;
        padding-bottom: 0.5rem;
        width: 25%;
      }

      &_title {
        color: $clr-primary-and-accent;
        text-transform: uppercase;
        @include mobile {
          text-transform: inherit;
        }

        &.error {
          color: $seaz-alarm;
        }
      }

      &_icon {
        margin: 0 0 0 auto;
      }
    }
  }
}

.seaz-edit {
  &__skills {
    &_box {
      padding: 4rem 0;
      @include tablet {
        padding: 4rem 2rem;
      }
      @include mobile {
        padding-top: 3rem;
        padding-bottom: 1.2rem;
      }
    }
  }

  &__course {
    &_row {
      display: flex;
      width: 66%;
      margin: 0 auto;
      justify-content: space-between;
      align-items: center;
      padding: 3rem 0;
      line-height: 1.6rem;
      @include tablet {
        width: 100%;
        padding: 3rem 2rem;
      }
      @include mobile {
        padding: 3rem 1rem;
      }

      &_title {
        color: $clr-primary-and-accent;
      }
    }

    &_name {
      width: 41%;

      &_title {
        display: flex;

        app-seazone-icon {
          margin-right: 1rem;
        }
      }
    }

    &_date {
      width: 50.7%;
      display: flex;
      flex-direction: column;
    }

    &_label {
      text-transform: uppercase;
      color: $seaz-document-count;
      padding-bottom: 1.2rem;
      display: block;
      letter-spacing: 0.5px;
    }

    &_btns {
      button {
        cursor: pointer;

        &:not(:last-of-type) {
          margin-right: 2rem;
        }
      }
    }
  }

  &__bank {
    &_row {
      display: flex;
      width: 66%;
      margin: 0 auto;
      justify-content: space-between;
      align-items: center;
      padding: 3rem;
      line-height: 1.6rem;
      @include tablet {
        width: 100%;
        padding: 3rem 2rem;
      }
      @include tablet-small {
        padding: 1rem;
      }
      @include mobile {
        padding: 3rem 1rem;
      }

      &_title {
        color: $clr-primary-and-accent;
        max-width: 180px;
        text-overflow: ellipsis;
        overflow: hidden;
        @include mobile {
          max-width: 100px;
        }
      }

      &--left {
        display: flex;
        width: 100%;
        @include tablet-small {
          flex-direction: column;
        }
      }
    }

    &--account {
      display: flex;
      width: 100%;
      @include tablet-small {
        .seaz-edit__bank_name {
          padding-bottom: 0;
          width: 32%;
          padding-right: 1rem;
        }
        .seaz-edit__bank_label {
          padding-bottom: 0;
        }
        .seaz-edit__bank_row_title {
          font-size: 1.2rem;
        }
      }
      @include mobile {
        .seaz-edit__bank_name {
          width: auto;
        }
      }
    }

    &_name {
      width: 54%;
      @include tablet-small {
        padding-bottom: 1rem;
      }

      .seaz-edit__bank_row_title {
        @include mobile {
          max-width: 300px;
        }
      }

      &_title {
        display: flex;

        app-seazone-icon {
          margin-right: 1rem;
        }
      }
    }

    &_date {
      width: 50.7%;
      display: flex;
      flex-direction: column;
    }

    &_label {
      text-transform: uppercase;
      color: $seaz-document-count;
      padding-bottom: 1.2rem;
      display: block;
      letter-spacing: 0.5px;
      font-size: 10px;
    }

    &_btns {
      width: 7rem;
      display: flex;
      align-items: center;

      button {
        cursor: pointer;

        &:not(:last-of-type) {
          margin-right: 2rem;
        }
      }
    }
  }

  &__exprerience {
    &:nth-of-type(2n - 1) {
      background-color: $clr-white;
    }

    .seaz-edit__exprerience_row_info--right {
      @media screen and (max-width: 650px) {
        flex-wrap: wrap;
      }
    }

    &_box {
      width: 100%;
      padding-bottom: 6rem;
      @include tablet {
        padding-bottom: 4rem;
      }

      &--yaht,
      &--work {
        app-yacht-exprerience-item,
        app-other-work-experience-item {
          .seaz-edit__exprerience {
            background-color: initial;
          }

          &:nth-of-type(2n - 1) {
            .seaz-edit__exprerience {
              background-color: $clr-white;
            }
          }
        }
      }
    }

    &_img {
      &_box {
        position: relative;

        app-seazone-icon.seaz-edit__exprerience_img_icon {
          @include mobile {
            display: none;
          }
        }
      }

      &_icon {
        width: 2.2rem;
        height: 2.2rem;
        background-color: $seaz-success;
        position: absolute;
        top: -4px;
        left: -4px;
      }
    }

    &_row {
      width: 66%;
      margin: 0 auto;
      display: flex;
      align-items: center;
      padding: 1.6rem 0;

      &_img {
        border-radius: 8px;
        width: 7rem;
        height: 7rem;
        @include mobile {
          display: none;
        }
      }

      @include laptop {
        width: 80%;
      }
      @include tablet {
        width: 100%;
        padding: 1.6rem 2rem;
      }
      @include mobile {
        padding: 1.6rem 2rem 1.6rem 0.4rem;
        align-items: flex-start;
      }

      &_info {
        display: flex;
        width: 80%;

        &--right {
          display: flex;
          width: 80%;
          @include tablet {
            margin-left: 1.6rem;
            width: 95%;
          }
          @include mobile {
            width: 100%;
          }
        }

        @include tablet {
          flex-direction: column;
        }
      }
    }

    &_name {
      display: flex;
      flex-direction: column;
      margin-left: 1.6rem;
      width: 45%;

      &_title {
        color: $clr-primary-and-accent;
      }

      @include tablet {
        margin-bottom: 1rem;
      }
    }

    &_label {
      font-size: 1rem;
      text-transform: uppercase;
      color: $seaz-document-count;
    }

    &_position,
    &_date {
      display: flex;
      flex-direction: column;
      width: 50%;

      .seaz-edit__exprerience_row_title {
        color: $clr-primary-and-accent;
        font-weight: 500;
        @include tablet {
          font-size: 1.2rem;
          line-height: 2rem;
        }
      }
    }

    &_position {
      @include tablet {
        width: 26%;
      }
      @include mobile {
        width: 33%;
        padding-bottom: 1rem;
      }
      padding-right: 1rem;
    }

    &_date {
      padding-right: 1rem;
      @include mobile {
        width: 75%;
      }
    }

    &_country {
      @include tablet {
        width: 57%;
      }
    }

    &_btns {
      display: flex;
      align-items: center;
      width: 18%;
      justify-content: flex-end;

      button:not(:last-of-type) {
        margin-right: 2rem;
      }

      button {
        cursor: pointer;
      }

      app-toggle-checkbox {
        margin-left: 2rem;
      }
    }
  }
}

.seaz__info_label {
  .info-label {
    margin: 0 0 4rem;
    border-radius: 0;
    padding: 1.2rem 1.2rem;
    @extend .seaz-h6;
    line-height: 1.8rem;
    @include tablet-small {
      margin-bottom: 2rem;
    }
  }
}

.seaz-input__document_other {
  display: flex;
  justify-content: flex-end;

  app-seazone-input {
    padding-left: 21.1rem;
    @include tablet {
      padding-left: 16rem;
    }
    @include tablet-small {
      padding-left: 0;
    }
  }
}

.seaz-input__row {
  .form-field--content-outline.form-field--content.disabled {
    background-color: $clr-white;
    border: 1px solid $seaz-head-arrow;

    .seazone__form-date-input {
      background-color: $clr-white;
    }
  }
}

.cropper-modal {
  background-color: $clr-primary-and-accent;
  opacity: 0.7;
}

.crop-image__wrapper {
  .crop-image__container {
    align-items: end;

    .crop-image__result {
      width: 165px;
      height: 165px;
    }
  }

  .rotate-controls {
    padding-left: 14.5rem;
    box-sizing: border-box;

    .main-btn {
      font-size: 0;
      background-color: transparent;
      border: none;
    }

    span {
      font-size: 2.2rem;
      color: $seaz-text;
    }

    .main-btn:not(:last-child) {
      margin-right: 0;
    }
  }
}

.seaz-profile {
  &__navigation {
    display: flex;
    overflow: auto;
    max-width: 100%;
    padding: 2rem 0;

    &::-webkit-scrollbar {
      display: none;
    }

    &_wrapper {
      position: relative;
    }

    &_list {
      display: flex;
      margin: 0 2.4rem;
    }

    app-scroll-controls .controls-container {
      position: static;

      button {
        height: 5.2rem;
        width: 2.4rem;
        display: inline-block;
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        background-color: white;
        border-radius: 0;
        z-index: 1;
        @include mobile {
          height: 3.6rem;
        }

        &:hover,
        &:active {
          background-color: $clr-purple;

          .icon-arrow-left,
          .icon-arrow-rights {
            &::before {
              color: white;
            }
          }
        }

        &:nth-child(1) {
          left: 0;

          .icon-arrow-left::before {
            transform: rotate(180deg);
          }
        }

        &:nth-child(2) {
          right: 0;
        }

        .icon-arrow-left,
        .icon-arrow-rights {
          &::before {
            content: '\e823';
            font-weight: bold;
            font-size: 1.2rem;
          }
        }
      }
    }

    &_item {
      display: flex;
      align-items: center;
      border: 1px solid $seaz-border;
      position: relative;
      cursor: pointer;
      flex-wrap: nowrap;
      @include mobile {
        border: none;
        border-bottom: 2px solid $seaz-border;
      }

      a {
        display: flex;
        padding: 1.4rem 3rem;
        align-items: center;
        white-space: nowrap;
        color: $seaz-border;
        font-weight: 500;
        text-transform: uppercase;

        app-seazone-icon {
          position: absolute;
          top: -1.2rem;
          right: 0;
          @include mobile {
            width: 16px;
            height: 16px;
            top: -2px;
            right: -3px;
          }
        }

        @include mobile {
          padding: 0.8rem 1rem 0.8rem;
          color: $seaz-document-count;
        }

        span {
          margin-left: 5px;

          span {
            color: green;
          }
        }
      }

      &.active-item {
        border-color: $clr-primary-and-accent;

        a {
          color: $clr-primary-and-accent;
        }
      }

      &.unfilled {
        app-seazone-icon svg {
          fill: #d70000;
        }
      }

      &.incompleted {
        app-seazone-icon svg {
          fill: #f49342;
        }
      }
    }
  }

  &__header {
    display: flex;
    justify-content: space-between;
    padding: 5.4rem;
    @include laptop {
      padding: 2.4rem;
    }
    @media screen and (max-width: 720px) {
      padding: 2.4rem 0;
      width: 94%;
      margin: 0 auto;
      border-bottom: 1px solid #bdbdbd;
      .seaz__return_btn {
        width: 3rem;
        height: 3rem;

        app-seazone-icon {
          width: 8px;
        }
      }
    }
    @media screen and (max-width: 720px) {
      flex-direction: column;
    }
    @include mobile {
      border-bottom: none;
    }
  }

  &__title {
    color: $clr-primary-and-accent;
    margin-left: 3.5rem;
    text-transform: uppercase;
    @include laptop {
      margin-left: 1.5rem;
      font-size: 2rem;
    }
    @include tablet {
      flex-shrink: 0;
    }

    &_wrapper {
      display: flex;
      align-items: center;
      @media screen and (max-width: 720px) {
        margin-bottom: 2rem;
      }
    }
  }

  &__info {
    display: flex;
    flex-direction: column;

    &.invert {
      flex-direction: column-reverse;
    }

    &_completion {
      display: flex;
      justify-content: center;
      @media screen and (max-width: 720px) {
        justify-content: flex-start;
      }
      @include tablet {
        justify-content: space-between;
      }
      @include mobile {
        flex-direction: column;
      }
    }

    &_wrapper {
      position: relative;
      display: flex;
      align-items: center;
      @include laptop {
        width: 57%;
      }
      @include tablet {
        width: 63%;
        app-completion-status {
          width: 100%;
        }
      }
      @media screen and (max-width: 720px) {
        width: 100%;
        app-completion-status {
          width: 100%;
        }
      }
    }

    &_line {
      margin-top: 0.4rem;
      width: 100%;
      height: 0.4rem;
      background-color: $seaz-head-arrow;

      &_progress {
        height: 0.4rem;
      }
    }

    &_text {
      margin-left: 5rem;
      @include laptop {
        margin-left: 2rem;
        font-size: 1.2rem;
      }
      @include mobile {
        margin-left: 0;
        margin-top: 1rem;
      }
    }

    &.unfilled {
      .seaz-h5-caps {
        color: $seaz-alarm;
      }

      .seaz-profile__info_line_progress {
        background-color: $seaz-alarm;
      }
    }

    &.incompleted {
      .seaz-h5-caps {
        color: $seaz-warning;
      }

      .seaz-profile__info_line_progress {
        background-color: $seaz-warning;
      }
    }

    &.completed {
      .seaz-h5-caps {
        color: $success-color;
      }

      .seaz-profile__info_line_progress {
        background-color: $success-color;
      }
    }
  }
}

app-edit-profile-wrapper {
  display: block;
  background-color: $seaz-bg;
}

.seaz-cv__wrapper {
  width: 126.4rem;
  margin: 0 auto;
  @media screen and (max-width: 1340px) {
    width: 110rem;
  }
  @include desktop {
    width: 90rem;
  }
  @include laptop {
    width: 100%;

    .seaz-h2 {
      font-size: 2.2rem;
    }
  }
}

.seaz-cv {
  &__head {
    @extend .seaz-cv__wrapper;
    display: flex;
    align-items: center;
    padding: 3.6rem 0 3rem;
    justify-content: center;
    position: relative;
    border-bottom: 1px solid #bdbdbd;

    .edit-button {
      width: 5rem;
      height: 5rem;
    }

    @include laptop {
      padding: 3rem 1.6rem 2.8rem;
      border-bottom: 0;
    }
    @include mobile {
      padding: 2.4rem 1.6rem 2.4rem;
    }

    &_title {
      color: $clr-primary-and-accent;
      text-transform: uppercase;
    }

    &_reminder {
      width: 18rem;
      background-color: $clr-white;
      border: 1px solid $seaz-head-arrow;
      box-sizing: border-box;
      padding: 1.1rem 1.2rem;
      color: $seaz-document-count;
      font-size: 1.2rem;
      line-height: 1.7rem;
      position: relative;
      @media screen and (max-width: 1340px) {
        display: none;
      }

      &::after {
        content: '';
        height: 8px;
        width: 8px;
        position: absolute;
        right: -5px;
        top: 44%;
        background: $clr-white;
        margin: auto;
        transform: rotate(-45deg);
        border: 1px solid $seaz-head-arrow;
        border-top: none;
        border-left: none;
      }
    }

    &_btns {
      display: flex;
      align-items: center;
      position: absolute;
      right: 0;

      @include laptop {
        position: fixed;
        bottom: 2.1rem;
        z-index: 10;
        right: 0;
        left: 0;
        display: flex;
        justify-content: center;
      }
    }

    &_btn {
      &_jump {
        background-color: $seaz-edit-hover;
        color: $clr-white;
        text-transform: uppercase;
        display: flex;
        align-items: center;
        height: 5rem;
        padding: 0 1.4rem;
        cursor: pointer;
        margin-right: 1.6rem;
        margin-left: 1.3rem;

        @include mobile {
          margin-left: 0;
        }

        span {
          margin-left: 1rem;
        }
      }

      &_download {
        background-color: $seaz-mulberry;
        color: $clr-white;
        width: 5rem;
        height: 5rem;
        display: flex;
        align-items: center;
        justify-content: center;
        margin-left: 1.6rem;
        cursor: pointer;
      }
    }
  }

  &__navigation {
    @extend .seaz-cv__wrapper;
    display: flex;
    justify-content: center;
    padding: 3rem 0;

    &_item {
      padding: 0 1.6rem;
      color: $clr-primary-and-accent;
      opacity: 0.7;

      &:hover {
        opacity: 1;
        text-decoration: underline;
      }

      &:not(:last-of-type) {
        border-right: 1px solid $clr-primary-and-accent;
      }
    }
  }

  &__empty_btn {
    position: relative;
    right: 0;
    left: 0;
    margin: 0 auto 2rem;
    display: flex;
  }

  &__slider {
    @extend .seaz-cv__wrapper;
    pointer-events: none;

    &_btn {
      background-color: $clr-white;
      width: 5rem;
      height: 5rem;
      cursor: pointer;
    }

    &_box {
      position: relative;

      .carousel-indicators {
        display: none;
      }
    }
  }

  &__photo {
    position: absolute;
    bottom: -44%;
    width: 40rem;
    right: 0;
    left: 0;
    margin: 0 auto;
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    @media screen and (max-width: 1300px) {
      bottom: -28%;
    }
    @include laptop {
      right: inherit;
      left: 13rem;
      bottom: -48%;
    }
    @include tablet-big {
      left: 8rem;
    }
    @media screen and (max-width: 670px) {
      left: inherit;
      right: 0;
      width: 17.6rem;
      bottom: -70%;
    }

    &_img {
      width: 30rem;
      height: 30rem;
      border-radius: 50%;
      border: 1rem solid $seaz-logo-border;

      @media screen and (max-width: 1300px) {
        width: 22rem;
        height: 22rem;
      }
      @include laptop {
        width: 15.6rem;
        height: 15.6rem;
      }
    }

    &_icon {
      width: 7.2rem;
      height: 7.2rem;
      border-radius: 50%;
      background-color: $seaz-mulberry;
      border: 0.3rem solid $clr-white;
      display: flex;
      align-items: center;
      justify-content: center;
      position: absolute;
      bottom: 3rem;
      right: 6rem;
      @media screen and (max-width: 1300px) {
        bottom: 2rem;
        right: 8rem;
      }
      @include laptop {
        width: 4.8rem;
        height: 4.8rem;
        bottom: 1rem;
        right: 13rem;
        border: 0.4rem solid $clr-white;
        img {
          width: 2rem;
          height: 2rem;
        }
      }
      @media screen and (max-width: 670px) {
        right: 1rem;
      }
    }
  }

  &__info {
    @extend .seaz-cv__wrapper;
    padding-top: 4rem;
    @include laptop {
      padding: 2.5rem 1.6rem 0 1.6rem;
      position: relative;
    }

    @include mobile {
      padding: 2.5rem 1rem;
    }

    &_box {
      display: flex;
      justify-content: space-between;
      @include laptop {
        justify-content: flex-start;
      }
      @media screen and (max-width: 670px) {
        padding-top: 7rem;
        flex-wrap: wrap;
        justify-content: space-between;
      }
      @media screen and (max-width: 400px) {
        padding-top: 9rem;
      }
    }

    &_main {
      width: 40%;
    }

    &_name {
      padding-bottom: 1.8rem;
      color: $clr-primary-and-accent;
      @include laptop {
        font-size: 2.6rem;
        padding-bottom: 0;
      }
    }

    &_location {
      display: flex;
      align-items: center;
      font-size: 2.1rem;
      color: $seaz-text;
      padding-bottom: 3.7rem;
      @include laptop {
        font-size: 1.1rem;
        padding-bottom: 1rem;
      }

      app-seazone-icon {
        margin-right: 1rem;
        margin-bottom: 0.5rem;
        @include laptop {
          margin-right: 0.6rem;
          width: 1.1rem;
          margin-bottom: 0.2rem;
        }
      }
    }

    &_salary {
      display: flex;
      flex-direction: column;

      &_label {
        color: $clr-primary-and-accent;
        padding-bottom: 0.8rem;
        @include laptop {
          font-size: 1.2rem;
        }
      }

      &_amount {
        color: $clr-primary-and-accent;
        font-weight: 500;
        @include laptop {
          font-size: 2.6rem;
          line-height: 2rem;
        }
      }

      &_period {
        color: $clr-primary-and-accent;
        @include laptop {
          font-size: 1.2rem;
          line-height: 1.5rem;
        }
      }
    }

    &_btns {
      padding-top: 4.2rem;
      display: flex;
      align-items: center;
      @include laptop {
        position: absolute;
        bottom: 2rem;
        left: 30rem;
        &.seaz-cv__info_btns_desktop {
          display: none;
        }
      }
      @include tablet-big {
        left: 26rem;
      }
      @media screen and (max-width: 670px) {
        bottom: inherit;
        top: 16rem;
        left: inherit;
        right: 6.4rem;
      }
      @include mobile {
        top: 15rem;
      }
      @media screen and (max-width: 400px) {
        top: 17rem;
      }

      &_share,
      &_like,
      &_download,
      &_cover-l {
        width: 6rem;
        height: 6rem;
        border-radius: 50%;
        margin-right: 1.6rem;

        &:disabled {
          background-color: #e7e7eb;
        }

        @include laptop {
          width: 2.4rem;
          height: 2.4rem;
          display: flex;
          justify-content: center;
          align-items: center;
          margin-right: 0.5rem;
        }
      }

      &_share {
        background-color: $seaz-text;
        color: $clr-white;
        @include laptop {
          app-seazone-icon {
            width: 69%;
          }
        }
      }

      &_like {
        @include laptop {
          app-seazone-icon {
            width: 55%;
          }
        }
      }

      &_download {
        background-color: $button-main;
        color: $clr-white;
      }

      &_cover-l {
        background-color: $clr-green;
      }

      &_contacts {
        background-color: #3aae27;
        width: 6rem;
        height: 6rem;
        border-radius: 50%;
        margin-right: 1.6rem;
        cursor: pointer;

        &:disabled {
          background-color: #e0e0e0;
        }
      }

      &_play {
        width: 6rem;
        height: 6rem;
        border-radius: 50%;
        background-color: $seaz-mulberry;
        position: relative;
        display: inline-flex;
        align-items: center;
        justify-content: center;
        margin-right: 1.6rem;
        @include laptop {
          width: 2.4rem;
          height: 2.4rem;
          margin-right: 0.5rem;
        }

        &::before {
          content: '';
          border: 13px solid transparent;
          border-left: 19px solid $clr-white;
          display: inline-flex;
          position: absolute;
          right: 5px;
          @include laptop {
            right: 0;
            border: 6px solid transparent;
            border-left: 10px solid $clr-white;
          }
        }

        &:disabled {
          background-color: #e7e7eb;
          cursor: inherit;

          &::before {
            border-left-color: #a2a2a5;
          }
        }
      }
    }

    &_progress {
      width: 24%;
      margin-top: 5rem;
      display: flex;
      flex-direction: column;
      align-items: center;
      @include laptop {
        width: 21rem;
      }
      @include tablet-big {
        width: 16rem;
      }

      &_title {
        padding-bottom: 3.6rem;
        color: $clr-primary-and-accent;
        text-transform: uppercase;
        text-align: center;
        @include laptop {
          font-size: 1.6rem;
          padding-bottom: 1.2rem;
        }
        @media screen and (max-width: 670px) {
          text-align: center;
        }
      }

      &_box {
        width: 100%;

        .seaz-profile__info_completion {
          padding-top: 1.2rem;
          @include laptop {
            padding-top: 0;
            .seaz-h5-caps {
              font-size: 1.2rem;
            }
          }
          @include tablet {
            justify-content: center;
          }
          @media screen and (max-width: 670px) {
            justify-content: center;
            align-items: center;
          }
        }
      }
    }

    &_badges {
      width: 22rem;
      @media screen and (max-width: 1300px) {
        width: 13.4rem;
      }
      @media screen and (max-width: 670px) {
        width: 47%;
      }
    }
  }

  &__badges {
    display: flex;
    justify-content: space-between;
    padding-top: 5rem;
    align-items: flex-start;
    @media screen and (max-width: 1300px) {
      padding-top: 0;
    }
    @include laptop {
      justify-content: flex-start;
      flex-direction: column;
      position: absolute;
    }
    @media screen and (max-width: 670px) {
      width: 50%;
    }

    &_status {
      text-transform: uppercase;
      color: $clr-white;
      background-color: $seaz-edit-hover;
      padding: 0.4rem 1.2rem 0.6rem;
      letter-spacing: 0.1em;
      min-height: 35px;
      display: flex;
      justify-content: center;
      align-items: center;
      @include laptop {
        margin-bottom: 0.5rem;
        width: 21.3rem;
        line-height: 2rem;
        padding: 0.6rem 1.2rem 0.5rem;
        text-align: center;
      }
      @include tablet-big {
        font-size: 1.1rem;
        width: 17rem;
      }
      @media screen and (max-width: 670px) {
        width: 17rem;
        padding: 0.3rem 1.2rem 0.2rem;
      }

      @media screen and (max-width: 480px) {
        width: 14rem;
        padding: 0.6rem 0.5rem 0.5rem;
      }

      &_job {
        padding: 0.8rem 1.2rem 0.6rem;
        min-height: 35px;
        letter-spacing: 0.1em;
        text-transform: uppercase;
        color: $clr-white;
        min-width: 21.3rem;
        text-align: center;
        font-weight: 500;

        &.looking-job {
          background-color: $seaz-edit-hover;

          @include mobile {
            width: 14rem;
            min-width: 14rem;
            padding: 0.9rem 0.5rem 0.8rem;
          }
        }

        &.vacation {
          background-color: $clr-green;
        }

        &.on-board {
          background-color: $seaz-mulberry;
        }

        &.not-active {
          background-color: $clr-grey;
        }

        &.no-show {
          color: $seaz-alarm;
          border: 1px solid $seaz-alarm;
          @media screen and (max-width: 670px) {
            padding: 0.3rem 1.2rem 0.2rem;
          }
          @media screen and (max-width: 400px) {
            width: 14rem;
          }
        }

        @include tablet-big {
          font-size: 1.1rem;
          min-width: 17rem;
        }
        @media screen and (max-width: 400px) {
          min-width: 16rem;
          width: 16rem;
        }
      }

      &_update {
        font-size: 1.2rem;
        padding-top: 0.8rem;
        color: #4f4f4f;
        display: block;
        @media screen and (max-width: 1300px) {
          text-align: end;
        }
        @include laptop {
          text-align: inherit;
        }
      }

      &_change {
        border-bottom: 1px dashed #351f73;
        color: $clr-primary-and-accent;
        margin-left: 1.6rem;
        padding-top: 0.6rem;
        padding-bottom: 5px;
        @include laptop {
          margin-left: 0;
        }
      }
    }

    &_right {
      display: flex;
      margin-left: auto;
      @media screen and (max-width: 1300px) {
        padding-right: 0;
        flex-direction: column;
        align-items: flex-end;
      }
      @include laptop {
        margin: 0;
        align-items: flex-start;
      }
    }
  }

  &__badge {
    &_greeny {
      background-color: #d8efd4;
      display: flex;
      align-items: center;
      padding: 1.4rem 1.6rem;
      justify-content: space-between;
      margin-bottom: 0.8rem;
      @media screen and (max-width: 1300px) {
        app-seazone-icon {
          width: 26px;
        }
      }

      &_title {
        color: $seaz-success;
        padding-bottom: 0.8rem;
        text-transform: uppercase;
        @media screen and (max-width: 1300px) {
          font-size: 1.4rem;
          line-height: 1.4rem;
        }
      }

      &_text {
        color: $seaz-success;
        @media screen and (max-width: 1300px) {
          font-size: 1rem;
        }
      }

      &_box {
        display: flex;
        flex-direction: column;
      }
    }

    &_main {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      padding: 1.4rem 1rem;
      margin-bottom: 0.8rem;
      width: 100%;
      background-color: $clr-white;
      text-align: center;

      &_title {
        color: $clr-primary-and-accent;
        padding-bottom: 0.8rem;
        font-weight: bold;
        font-size: 1.2rem;
        text-transform: uppercase;
        line-height: 2rem;
      }

      &_text {
        color: $seaz-document-count;
      }
    }
  }

  &__details {
    display: flex;
    width: 39%;
    justify-content: flex-end;
    padding-top: 2.6rem;
    @media screen and (max-width: 670px) {
      padding-top: 2rem;
    }

    &_list {
      width: 18.4rem;
      margin-right: 2rem;
      @include laptop {
        width: 64%;
      }
      @include tablet-big {
        width: 44%;
        margin-right: 1rem;
      }
      @media screen and (max-width: 670px) {
        width: 50%;
        margin-right: 3%;
      }

      &_item {
        display: flex;
        justify-content: space-between;
        border-bottom: 1px solid #82828245;
        padding: 1.2rem 0;
        color: $seaz-text;

        .seaz-text-medium {
          text-align: end;
        }

        .seaz-text-medium-light {
          padding-right: 0.8rem;
          text-align: left;
        }
      }
    }
  }

  &__contact {
    margin: 4rem auto 0;
    width: 83.1rem;
    padding: 4.8rem 2.4rem;
    box-sizing: border-box;
    background-color: $clr-white;
    border: 1px dashed $dashed-border;
    position: relative;
    @include laptop {
      width: 60rem;
    }
    @include tablet {
      width: 95%;
      margin-bottom: 4rem;
    }
    @media screen and (max-width: 600px) {
      padding: 2.8rem 1rem;
    }

    &::before {
      content: '';
      height: 2.5rem;
      width: 2.5rem;
      position: absolute;
      right: 0;
      top: -14px;
      left: 0;
      background: #ffffff;
      margin: auto;
      transform: rotate(225deg);
      border: 1px dashed $dashed-border;
      border-top: none;
      border-left: none;
    }

    &_head {
      display: flex;
      position: relative;
      align-items: center;
      justify-content: center;
      padding-bottom: 4rem;
      @include mobile {
        padding-bottom: 2.4rem;
      }

      &_title {
        color: $clr-primary-and-accent;
        text-transform: uppercase;
        @include tablet {
          font-size: 2.2rem;
        }
        @include mobile {
          font-size: 2rem;
        }
      }
    }

    &_edit {
      &_btn {
        position: absolute;
        right: 0;
      }
    }

    &_box {
      width: 60rem;
      margin: 0 auto;
      display: flex;
      flex-wrap: wrap;
      @include laptop {
        width: 100%;
      }
      @include tablet {
        flex-direction: column;
        align-items: center;
      }
    }

    &_item {
      display: flex;
      align-items: center;
      width: 50%;
      box-sizing: border-box;
      padding-right: 1rem;
      margin-bottom: 1.7rem;
      @include tablet {
        width: 100%;
      }

      &:nth-of-type(2n) {
        padding-left: 50px;
        padding-right: 0;
        @include tablet {
          padding-left: 0;
        }
      }

      &_text {
        display: flex;
        align-items: center;
        word-break: break-all;
        color: $seaz-text;
        margin-left: 1rem;
      }

      .contact-link {
        display: flex;

        &.social {
          padding: 0.5rem;
          border-radius: 0.6rem;
          margin-left: 1rem;
        }

        &.viber {
          background-color: $clr-primary-and-accent;
        }

        &.whatsApp {
          background-color: $seaz-success;
        }
      }

      &.linkedin span {
        padding-top: 0.8rem;
      }
    }
  }

  &__content {
    width: 83.2rem;
    margin: 0 auto;
    @include laptop {
      width: 59.1rem;
    }
    @include tablet {
      width: 95%;
    }

    &_head {
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding-bottom: 1.8rem;
      padding-top: 6rem;
      @extend .seaz-cv__content;

      &_title {
        text-transform: uppercase;
        color: $clr-primary-and-accent;
      }

      &_badge {
        display: flex;
        align-items: center;
        width: 100%;

        .seaz-cv__content_head_title {
          @include tablet {
            max-width: 74%;
          }
          @include tablet-small {
            margin-bottom: 1rem;
            line-height: 3.4rem;
          }
        }

        @include tablet-small {
          flex-direction: column;
          align-items: flex-start;
        }

        &_item {
          &:not(:empty) {
            background-color: $clr-primary-and-accent;
            color: $clr-white;
            padding: 0.8rem 1.2rem;
            margin: 0 0 0.8rem 2.4rem;
            text-transform: uppercase;
            @include tablet-small {
              margin-left: 0;
            }
          }
        }
      }
    }
  }

  &__about_text {
    word-break: break-word;
    line-height: 2.6rem;
    color: $seaz-about-text;
    @extend .seaz-cv__content;
    border-bottom: 1px solid $seaz-border;
    padding-bottom: 6rem;
    @include tablet {
      padding-bottom: 4rem;
    }
  }

  &__preferences {
    @extend .seaz-cv__content;
    border-bottom: 1px solid $seaz-border;
    padding-bottom: 6rem;

    &_wrapper {
      display: flex;
      flex-wrap: wrap;
    }

    &_item {
      background-color: $clr-white;
      width: 18.4rem;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      padding: 1.6rem 0;
      margin: 0 3.2rem 3rem 0;
      flex-shrink: 0;
      @include laptop {
        width: 16.4rem;
        margin: 0 1.2rem 2rem 0;
      }
      @include mobile {
        margin-right: 0;
        width: 48%;
      }
      @include mobile {
        &:nth-of-type(2n - 1) {
          margin-right: 4%;
        }
      }

      &:nth-of-type(4n) {
        margin-right: 0;
        @include laptop {
          margin-right: 1.2rem;
        }
        @include mobile {
          margin-right: 0;
        }
      }

      &_label {
        padding-bottom: 0.8rem;
        color: $seaz-document-count;
        text-transform: uppercase;
        font-size: 1rem;
        line-height: 1rem;
        @include tablet {
          font-size: 1rem;
        }
      }

      &_title {
        color: $clr-primary-and-accent;
        text-align: center;
        word-break: break-word;
        padding: 0 1rem;
      }
    }

    &_note {
      font-style: normal;
      font-weight: normal;
      font-size: 1.6rem;
      color: $seaz-about-text;
    }

    &_boat {
      display: flex;
      color: $clr-primary-and-accent;
    }
  }

  &__check_badge {
    display: flex;
    align-items: center;
    padding: 0.6rem 1.6rem 0.9rem;
    background-color: $clr-white;
    margin: 0 1.6rem 1.6rem 0;
    @include mobile {
      padding: 1.3rem 1.6rem 1.3rem 1.6rem;
      width: 100%;
    }

    &_box {
      display: flex;
      @extend .seaz-cv__content;
      border-bottom: 1px solid $seaz-border;
      padding-bottom: 6rem;
      flex-wrap: wrap;
      @include laptop {
        flex-wrap: wrap;
      }
    }

    &_text {
      padding-top: 0.5rem;
      margin-left: 1.6rem;
    }
  }

  &__documents {
    &_wrapper {
      max-height: 444px;
      overflow: hidden;
      @media screen and (max-width: 600px) {
        max-height: 587px;
      }
      @include mobile {
        max-height: 569px;
      }

      &.opened {
        max-height: 100%;
      }
    }

    &:nth-of-type(2n - 1) {
      background-color: $clr-white;
      @include mobile {
        background-color: $seaz-logo-border;
      }
    }

    &_row {
      @extend .seaz-cv__content;
      display: flex;
      align-items: center;
      padding: 2rem 0;
      min-height: 7.4rem;
      @media screen and (max-width: 600px) {
        min-height: 10rem;
      }
      @include mobile {
        min-height: 9.7rem;
      }
    }

    &_type {
      width: 40%;
      @media screen and (max-width: 600px) {
        width: 85%;
        margin-bottom: 2rem;
      }

      &_name {
        color: $clr-primary-and-accent;
      }

      &_text {
        color: $seaz-document-count;
        margin-left: 0.8rem;

        &::before {
          content: '(';
        }

        &::after {
          content: ')';
        }
      }
    }

    &_right {
      display: flex;
      width: 50%;
      align-items: center;
      @include tablet {
        padding-top: 4px;
      }
      @media screen and (max-width: 600px) {
        width: 100%;
      }
    }

    &_rows--box {
      display: flex;
      width: 90%;
      align-items: center;
      @media screen and (max-width: 600px) {
        flex-direction: column;
        align-items: flex-start;
      }
      @include mobile {
        width: 80%;
      }
    }

    &_date {
      width: 60%;
      color: $clr-primary-and-accent;
      @media screen and (max-width: 600px) {
        width: auto;
        padding-right: 15px;
        color: $clr-light-grey;
      }

      .expried {
        color: $seaz-alarm;
      }
    }

    &_number {
      width: 40%;
      color: $clr-primary-and-accent;
      word-break: break-all;
    }

    &_btns {
      width: 10%;
      display: flex;
      align-items: center;
      justify-content: space-between;
      @include mobile {
        width: 20%;
      }

      svg {
        width: 3.4rem;
        height: 3.4rem;
      }
    }

    &_show {
      @extend .seaz-cv__content;
      position: relative;
      margin-top: 3.2rem;
      display: flex;
      justify-content: center;

      &:before {
        content: '';
        width: 100%;
        display: block;
        border-top: 1px dashed #bdbdbd;
        height: 1px;
        position: absolute;
        top: 5px;
      }

      &_text {
        text-transform: uppercase;
        color: $seaz-edit-hover;
        background-color: $seaz-logo-border;
        z-index: 2;
        padding: 0 1.7rem;
        cursor: pointer;
      }
    }
  }

  &__experience {
    @extend .seaz-cv__content;
    border-bottom: 1px solid $seaz-border;
    margin-top: 4rem;
    padding-bottom: 4rem;
    @include tablet {
      .seaz-cv__preferences_item:nth-of-type(4n) {
        margin-right: 0;
      }
      .seaz-cv__preferences_item {
        margin: 0 1.7rem 2rem 0;
      }
    }

    &.isHidden {
      .seaz-cv__experience_head_title,
      .seaz-cv__experience_head_contract {
        color: $seaz-document-count;
      }

      .seaz-cv__preferences_item {
        background-color: $seaz-head-arrow;

        .seaz-cv__preferences_item_title {
          color: $seaz-document-count;
        }
      }

      .seaz-cv__experience_duties {
        background-color: $seaz-head-arrow;

        .seaz-cv__experience_duties_title {
          color: $seaz-document-count;
        }
      }

      .seaz-cv__reference_request {
        background-color: $seaz-head-arrow;
        border-color: $seaz-border;

        .seaz-cv__reference_request_text {
          color: $seaz-document-count;
        }
      }

      .seaz-cv__reference {
        background-color: $seaz-head-arrow;
        padding: 3rem;

        .seaz-cv__reference_letter_title,
        .seaz-cv__reference_info_title,
        .seaz-cv__reference_letter_position,
        .seaz-cv__reference_info_contacts,
        .seaz-cv__reference_info_contacts {
          color: $seaz-document-count;
        }
      }
    }

    &_head {
      display: flex;
      align-items: center;
      padding-bottom: 2.4rem;
      position: relative;

      app-toggle-checkbox {
        position: absolute;
        top: 0;
        right: 0;
      }

      &_img {
        width: 10rem;
        height: 10rem;
        border-radius: 16px;
        object-fit: cover;
        margin-right: 2.4rem;
        @media screen and (max-width: 600px) {
          width: 5.6rem;
          height: 5.6rem;
          border-radius: 8px;
        }
      }

      &_box {
        display: flex;
        align-items: center;
        width: 90%;
        padding-bottom: 1rem;
        @media screen and (max-width: 600px) {
          width: 80%;
          padding-bottom: 0.8rem;
        }

        &--container {
          width: 100%;
        }
      }

      &_verified {
        padding: 5px 5px;
        height: fit-content;
        background-color: $seaz-success;
        position: relative;
        margin-left: 0.8rem;
        display: inline-block;
      }

      &_hided {
        margin-left: 0.8rem;
        text-transform: uppercase;
        color: $seaz-document-count;
        padding: 0.8rem 1.2rem;
        background-color: $seaz-head-arrow;
        letter-spacing: 0.1em;
        position: relative;
        top: -0.7rem;
        font-weight: 500;
        @media screen and (max-width: 600px) {
          font-size: 1rem;
          padding: 0.6rem 1rem;
          top: -1.1rem;
        }
      }

      &_title {
        color: $seaz-text;
        display: block;
        text-transform: uppercase;

        @media screen and (max-width: 600px) {
          font-size: 1.3rem;
        }
      }

      &_month {
        color: $seaz-text;
        @media screen and (max-width: 600px) {
          font-size: 1.3rem;
        }
      }

      &_period {
        color: $seaz-document-count;
        @media screen and (max-width: 600px) {
          font-size: 1.3rem;
        }
      }

      &_contract {
        color: $clr-primary-and-accent;
        letter-spacing: 0.1em;
        text-transform: uppercase;
        font-size: 1.6rem;
        font-weight: 500;
        padding-top: 1rem;
      }

      &_company {
        color: $seaz-document-count;
        padding-bottom: 1rem;
      }
    }

    &_work {
      &_head {
        flex-direction: column;
        align-items: baseline;
      }
    }

    &_preferences {
      display: flex;
      flex-wrap: wrap;
      @media screen and (max-width: 600px) {
        .seaz-cv__preferences_item {
          width: 49%;
          margin-right: 2%;
          margin-bottom: 1rem;

          &:nth-of-type(2n) {
            margin-right: 0;
          }
        }
      }
    }

    &_duties {
      background-color: $clr-white;
      padding: 3rem;
      box-sizing: border-box;
      display: flex;
      flex-direction: column;
      margin-bottom: 1rem;

      &_title {
        color: $clr-primary-and-accent;
        padding-bottom: 1.6rem;
        @media screen and (max-width: 600px) {
          font-size: 1.4rem;
          padding-bottom: 0.8rem;
        }
      }

      &_text {
        color: $seaz-about-text;
        line-height: 2.6rem;
        @media screen and (max-width: 600px) {
          font-size: 1.4rem;
        }
      }
    }
  }

  &__courses {
    &_item {
      padding: 2.2rem 0;
      @extend .seaz-cv__content;
      display: flex;
      align-items: center;

      &_box {
        &:nth-of-type(2n - 1) {
          background-color: $clr-white;
        }
      }
    }

    &--left {
      display: flex;
      width: 80%;
      @include mobile {
        flex-direction: column;
      }
    }

    &_name {
      color: $clr-primary-and-accent;
      width: 40%;
      @include mobile {
        width: 100%;
        margin-bottom: 1.9rem;
      }
    }

    &_date {
      display: flex;
      flex-direction: column;
      width: 40%;

      &_label {
        padding-bottom: 0.8rem;
        color: $seaz-document-count;
        text-transform: uppercase;
        @include mobile {
          padding-bottom: 0.4rem;
        }
      }

      &_text {
        color: $clr-primary-and-accent;
      }
    }

    &_btns {
      width: 20%;
      display: flex;
      justify-content: flex-end;

      svg {
        width: 4rem;
        height: 4rem;
      }

      &_item {
        &:not(:last-of-type) {
          margin-right: 3rem;
        }
      }
    }
  }

  &__languages {
    margin-bottom: 6rem;
    background-color: $clr-white;
    width: 100%;
    padding: 3.2rem 0;
    @include laptop {
      padding: 3.2rem 0 2.2rem;
    }

    &_box {
      display: flex;
      width: 83.2rem;
      margin: 0 auto;
      flex-wrap: wrap;
      @include laptop {
        width: 59.1rem;
      }
      @include tablet {
        width: 95%;
      }
    }

    &_item {
      width: 19.3rem;
      @include laptop {
        width: 50%;
        margin-bottom: 1rem;
      }
    }

    &_col {
      display: flex;
      flex-direction: column-reverse;
    }

    &_name {
      padding-bottom: 0.4rem;
      color: $clr-primary-and-accent;
    }

    &_level {
      color: $clr-light-grey;
      text-transform: uppercase;
      font-size: 1rem;
    }
  }

  &__additional {
    &_box {
      display: flex;
      width: 83.2rem;
      margin: 0 auto;
      flex-wrap: wrap;
      @include laptop {
        width: 59.1rem;
      }
      @include tablet {
        width: 97%;
      }
      @include mobile {
        width: 100%;
      }
    }

    &_item {
      width: calc(50% - 1.6rem);
      height: 27.7rem;
      display: flex;
      justify-content: center;
      margin-right: 3.2rem;
      margin-bottom: 3.2rem;
      @include laptop {
        margin-right: 2%;
        margin-bottom: 1.6rem;
        width: calc(50% - 1%);
      }
      @include mobile {
        width: 100%;
        margin-right: 0;
        margin-bottom: 1.2rem;
        height: 15rem;
      }

      &:nth-of-type(2n) {
        margin-right: 0;
      }

      &_img,
      &_video {
        max-width: 100%;
        object-fit: contain;
        @include laptop {
          width: 100%;
          height: 100%;
        }
      }
    }
  }

  &__additional_photo {
    padding-bottom: 3rem;
  }

  &__bank {
    .seaz-cv__content_head {
      border-top: 1px solid $seaz-border;
    }

    &_box {
      width: 100%;

      &:nth-of-type(2n - 1) {
        background-color: $clr-white;
      }

      @include tablet {
        width: 95%;
        margin: 0 auto;
      }
    }

    &_row {
      width: 83.2rem;
      margin: 0 auto;
      display: flex;
      padding: 3.1rem 0;
      justify-content: space-between;

      app-info-label {
        display: block;
        width: 100%;
      }

      @include laptop {
        width: 59.1rem;
      }
      @include tablet {
        width: 95%;
      }

      &_main {
        display: flex;
        width: 90%;
        @include mobile {
          flex-wrap: wrap;
        }
      }
    }

    &_label {
      font-size: 10px;
      color: $seaz-document-count;
      letter-spacing: 0.5px;
      padding-bottom: 0.5rem;
      text-transform: uppercase;
    }

    &_col {
      display: flex;
      flex-direction: column;
      width: 35%;
      @include mobile {
        width: 50%;
      }

      &--details {
        @include mobile {
          width: 100%;
          padding-bottom: 3.2rem;
          .seaz-cv__bank_label {
            display: none;
          }
          .seaz-cv__bank_name {
            text-transform: capitalize;
            font-size: 2rem;
          }
        }
      }
    }

    &_name {
      text-transform: uppercase;
      font-size: 1.6rem;
      font-weight: 500;
      color: $clr-primary-and-accent;
    }
  }
}

.edit-button {
  width: 4rem;
  height: 4rem;
  background-color: #e0e0e0;
  border-radius: 0.4rem;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  flex-shrink: 0;
}

.seaz-crew__edit_preferences,
.seaz-crew__edit_characteristics {
  padding-top: 4rem;
}

.salary-box {
  .salary-box-text {
    margin-left: 0.8rem;
  }

  &.seaz-crew__contacts_row.form__row app-seazone-input .form-field--content {
    width: 25.5rem;
    min-width: 25.5rem;
  }

  .salary-box-toggle {
    display: flex;
    flex-shrink: 0;
    margin-left: 1rem;
  }

  @include tablet {
    .form-field--wrapper {
      padding: 0 !important;
    }

    &.seaz-crew__contacts_row.form__row app-seazone-input .form-field--content {
      width: 100%;
      min-width: 100%;
      margin-bottom: 0;
    }

    &.seaz-crew__contacts_row,
    &.seaz-crew__contacts_row.form__row app-seazone-input {
      width: 100%;
    }
  }

  @media screen and (max-width: 560px) {
    &.seaz-crew__contacts_row {
      flex-direction: row;
      align-items: center;
    }
  }
}

.salary-period {
  .salary-period-title {
    margin-bottom: 0.2rem;
  }

  app-seazone-input .form-field--content {
    width: 25.5rem;
    min-width: 25.5rem;
  }

  @include tablet {
    margin-top: 1.6rem;

    .form-field--wrapper {
      padding: 0 !important;
    }

    app-seazone-input .form-field--content {
      width: 100%;
      min-width: 25.5rem;
    }
  }
}

.seaz-crew__edit_preferences-box {
  padding-bottom: 6rem;
}

.seaz-crew__edit_characteristics {
  .form-field--wrapper {
    width: 100% !important;
    justify-content: space-between;

    @include mobile {
      align-items: flex-start !important;
    }
  }

  .form-field--label {
    max-width: 16rem !important;
  }
}

.seaz-crew__edit_characteristics {
  @include tablet {
    padding: 4rem 2rem 0 2rem;
  }
  @include mobile {
    &.seaz-crew__edit_box .seaz-crew__edit_row.form__row app-seazone-select .form-field--wrapper,
    &.seaz-crew__edit_box .seaz-crew__edit_row.form__row app-seazone-checkbox-group-alternate .form-field--wrapper {
      padding: 0 0 2rem 0;
    }
    .seaz-crew__btn_box {
      width: 100%;
    }
  }
}

.user-note {
  color: #a9a9a9;
  font-size: 1.4rem;
  line-height: 19px;

  b {
    font-weight: bold;
    color: $clr-main;
  }
}

.pretty-cash {
  position: relative;

  &__back {
    padding: 0.6rem 0.8rem;
    margin-bottom: 1.45rem;
    min-width: 10rem;
    text-align: left;

    &_icon {
      margin-right: 1.5rem;
    }
  }

  &__title {
    font-size: 3.4rem;
    padding-bottom: 1rem;
    color: #4d4e67;
    line-height: 4rem;
    font-weight: bold;
    padding-right: 8rem;
    @include mobile {
      font-size: 1.8rem;
      padding-bottom: 4rem;
      line-height: 2.1rem;
    }

    &_wrap {
      display: flex;

      .btn-expand {
        display: none;
        @include tablet-big {
          display: flex;
          position: absolute;
          top: 25px;
          right: 0;
        }
        @include mobile {
          top: 0;
        }
      }
    }
  }

  &__table {
    width: 100%;
    overflow: scroll;
    padding-bottom: 1rem;

    &_wrap {
      position: relative;

      app-scroll-controls .controls-container {
        top: -3.5rem;
      }
    }

    table {
      width: fit-content;
    }

    &_row {
      &:nth-of-type(2n) {
        background-color: #fafafb;

        .pretty-cash__table_head_name {
          background-color: #fafafb;
        }
      }

      &:not(:last-of-type) {
        border-bottom: 1px solid #e7e7eb;
      }

      .pretty-cash__table_head_name {
        text-align: inherit;
        border-right: none;
        min-width: 30rem;
        font-weight: bold;
        font-size: 1.4rem;
        color: #4f194a;
        padding: 2rem 1.2rem;

        @include tablet {
          min-width: 17rem;
        }
        @include mobile {
          min-width: 10.5rem;
        }
      }

      td {
        width: 50%;
        text-align: end;
        box-sizing: border-box;
        padding: 1.2rem;
        vertical-align: middle;
        color: #4f194a;

        &:not(:last-of-type) {
          border-right: 1px solid #e7e7eb;

          &.pretty-cash__table_head_name {
            border-right: none;
          }
        }

        &:nth-of-type(2n + 1) {
          border-color: #a9a9a9;
        }
      }
    }

    &_head {
      &_name {
        box-shadow: 10px 1px 8px -2px rgba(0, 0, 0, 0.1);
        position: sticky;
        left: 0;
        background-color: $clr-white;
      }

      tr {
        &:nth-of-type(2n) {
          background-color: #fafafb;

          .pretty-cash__table_head_name {
            background-color: #fafafb;
          }
        }
      }

      td {
        &:first-of-type {
          border-right: none;
        }
      }

      &_title {
        text-align: center;
        font-size: 15px;
        color: #4f194a;
        padding: 2rem 1.2rem;
        font-weight: 500;
        min-width: 30.7rem;

        &:not(:last-of-type) {
          border-right: 1px solid #a9a9a9;
        }

        @include mobile {
          min-width: 17.6rem;
        }
      }

      &_row {
        border-bottom: 1px solid #e7e7eb;
      }
    }

    &_title_row {
      border-bottom: 1px solid #a9a9a9;

      td {
        width: 50%;
        text-align: end;
        box-sizing: border-box;
        padding: 1.2rem;
        vertical-align: middle;
        color: #4f194a;
        border-right: 1px solid #e7e7eb;

        &:nth-of-type(2n + 1) {
          border-color: #a9a9a9;
        }

        &:last-of-type {
          border: none;
        }
      }
    }
  }

  &__report {
    &_table {
      width: 100%;
      position: relative;

      .credit-table__col {
        padding: 0.5rem 0;
        @include mobile {
          &:first-child {
            max-width: none;
          }
        }
      }

      .credit-table__row--cash {
        margin-top: 3rem;
      }

      &_row {
        display: flex;
        align-items: center;
        padding: 0.2rem 0;
        @media screen and (max-width: 1100px) {
          width: min-content;
        }

        &:nth-of-type(2n) {
          background-color: #fafafb;
        }

        &-total {
          .pretty-cash__report_table_label,
          .pretty-cash__report_table_item {
            font-weight: 500;
            color: #4d4e67;
          }
        }
      }

      &_label,
      &_item {
        &.green {
          color: #46a6a4;
        }

        &.red {
          color: #ff5574;
        }
      }

      &_label {
        width: 30.6rem;
        padding: 0.6rem 1.2rem;
        flex-shrink: 0;
        @include mobile {
          width: 14.6rem;
        }
      }

      &_item {
        width: 13rem;
        text-align: end;
        flex-shrink: 0;

        &-title {
          color: #4d4e67;
        }
      }
    }
  }
}

.profile__col_pretty {
  &_box {
    display: flex;
    align-items: center;
    @include mobile {
      flex-direction: column;
      align-items: flex-start;
    }
  }

  &_btn {
    border: 1px solid $clr-green;
    box-sizing: border-box;
    border-radius: 4px;
    color: $clr-green;
    padding: 0.8rem 1.6rem;
    font-weight: 500;
    font-size: 1.2rem;
    text-transform: uppercase;
    margin-left: 0.4rem;
    display: none;

    @include tablet {
      display: block;
      margin-left: 0;
      margin-top: 0.4rem;
    }
  }
}

.profile__col_box-price {
  display: flex;
  flex-wrap: wrap;
  margin-top: 0.8rem;

  @include tablet {
    &.currency-list {
      display: none;
    }
  }

  &.total {
    display: none;
    @include tablet {
      display: flex;
      flex-direction: column;
    }
  }

  .profile__col-price {
    min-width: 9.3rem;
    margin-right: 0.8rem;
    margin-bottom: 0.8rem;
    gap: 0.4rem;
    display: flex;
    flex-direction: column;
    background-color: #f0f0f3;
    border-radius: 8px;
    padding: 0.8rem;
    font-size: 1.4rem;

    @include tablet {
      min-width: 100%;
      margin-right: 0;
    }
  }

  .profile__col_label-price {
    color: #a9a9a9;
    font-weight: normal;
    font-size: 1.2rem;
  }

  .profile__col_label-price__value {
    font-size: 1.4rem;
    line-height: 1.4rem;
  }
}

.profile__col-right {
  .profile__col-month {
    @include mobile {
      margin-left: 0;
    }
  }
}

.profile__permissions {
  .setting-permission__form_btns {
    width: 32rem;
  }
}

.boat__card-wrap.profile {
  flex-wrap: nowrap;
  @include laptop {
    flex-direction: column;
  }

  .profile__col_pretty-cash {
    flex-direction: column;
    @include laptop {
      width: 100%;
    }

    .main-btn_green {
      margin-top: 2rem;
      @include mobile {
        margin-top: 0;
      }
    }
  }
}

.table__section_box {
  width: 100%;

  th {
    color: #351f6f;
    font-size: 14px;
    padding: 0.9rem;
  }

  .text-left {
    width: 14.5rem;
  }

  .text-right {
    padding: 0.5rem 0.9rem;
    padding-left: 0;

    &.text-right-btn {
      width: 4rem;
    }
  }
}

.table_board-sub-category {
  .text-right-btn {
    width: 4rem;
  }
}

.profile__col_pretty-cash {
  @include mobile {
    .profile__col-title {
      width: 100%;
      height: 3.6rem;
      margin-bottom: 0;
    }
    //.profile__col-month {
    //  margin-top: 0;
    //}
    //.profile__upload {
    //  margin-top: 0;
    //}
  }

  .profile__col-month {
    @media screen and (max-width: 359px) {
      height: 3.5rem;
    }
  }
}

.seaz-dashboard {
  width: 126.4rem;
  margin: 0 auto;
  @media screen and (max-width: 1400px) {
    width: 100rem;
  }
  @include laptop {
    width: 100%;
    padding: 0 1.6rem;
  }

  &__title {
    text-align: center;
    padding: 6rem 0 3rem;
    color: $clr-primary-and-accent;
    border-bottom: 1px solid $seaz-border;
    text-transform: uppercase;
    @include mobile {
      padding: 3rem 0 2.4rem;
    }
  }

  &__content {
    margin-top: 3rem;
    display: flex;
    align-items: flex-start;
    padding-bottom: 3rem;
    @media screen and (max-width: 1400px) {
      flex-direction: column;
    }
  }

  app-user-cv-preview {
    @media screen and (max-width: 1400px) {
      width: 100%;
    }
  }

  &__job {
    display: flex;
    background-color: $clr-white;
    position: relative;
    padding: 0 3rem;
    width: 83.2rem;
    @media screen and (max-width: 1400px) {
      width: 100%;
    }
    @media screen and (max-width: 600px) {
      padding: 0;
      flex-direction: column;
    }

    &_search {
      width: 50%;
      border-right: 1px dashed $seaz-head-arrow;
      padding: 2.4rem 0;
      flex-shrink: 0;
      padding-right: 3rem;
      box-sizing: border-box;
      @media screen and (max-width: 600px) {
        width: 90%;
        border-right: none;
        padding: 2.4rem 0;
        border-bottom: 1px dashed #e0e0e0;
        margin: 0 auto;
      }

      .seaz-cv__badges_status_update {
        @media screen and (max-width: 1300px) {
          text-align: left;
        }
      }
    }

    &_icon {
      margin-right: 1rem;

      &.active {
        position: relative;

        .seaz-dashboard__job_offer_new {
          width: 0.8rem;
          height: 0.8rem;
          background-color: $seaz-success;
          border-radius: 50%;
          position: absolute;
          right: 1px;
          top: 1px;
          border: 1px solid $clr-white;
        }
      }
    }

    &_title {
      display: flex;
      color: $clr-primary-and-accent;
      padding-bottom: 2.4rem;
      text-transform: uppercase;
      align-items: center;
    }

    &_matches {
      padding-bottom: 1.2rem;
      border-bottom: 1px solid $seaz-head-arrow;
      display: flex;
      justify-content: space-between;
      align-items: center;

      &_count {
        color: $seaz-success;
        margin-right: 0.8rem;
      }

      &_text {
        color: $seaz-document-count;
        text-transform: uppercase;
        @include tablet {
          font-size: 1.4rem;
        }

        &_box {
          display: flex;
          align-items: center;
        }
      }

      app-seazone-icon {
        transform: rotate(180deg);
      }
    }

    &_controls {
      display: flex;
      flex-direction: column;
      margin-top: 2.4rem;

      &_btns {
        display: flex;
        align-items: center;
        @media screen and (max-width: 1400px) {
          flex-direction: column;
          align-items: flex-start;
        }
      }

      &_jump {
        background-color: $seaz-edit-hover;
        color: $clr-white;
        text-transform: uppercase;
        width: 100%;
        height: 3rem;
        text-align: center;
        cursor: pointer;
        font-size: 1.2rem;
        font-weight: 500;
        margin-top: 1.5rem;
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 0 1.5rem;

        app-seazone-icon {
          height: 16px;
          width: 16px;
          margin-right: 1rem;
        }
      }
    }

    &_offer {
      width: 50%;
      padding: 2.4rem 0 2.3rem 3rem;
      @media screen and (max-width: 600px) {
        width: 100%;
        padding: 2.4rem;
      }

      &_box {
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding-bottom: 0.9rem;
        border-bottom: 1px solid $seaz-head-arrow;
        margin-top: 1rem;

        &_text {
          color: $seaz-document-count;
          text-transform: uppercase;
          @include tablet {
            font-size: 1.4rem;
          }
        }
      }

      &_right {
        display: flex;
        align-items: center;

        app-seazone-icon {
          transform: rotate(180deg);
        }
      }

      &_badge {
        color: $success-color;
        background-color: #d8efd4;
        padding: 0.3rem 0.8rem 0.7rem;
        text-transform: uppercase;
        font-weight: 400;
        margin-right: 1.5rem;
        @include tablet {
          font-size: 1.2rem;
        }
      }
    }
  }

  &__cv {
    background-color: $clr-white;
    padding: 2.4rem 3rem;
    width: 40rem;
    margin-left: 3.2rem;
    @media screen and (max-width: 1400px) {
      width: 100%;
      margin-top: 2.4rem;
      margin-left: 0;
    }

    &_head {
      display: flex;
      align-items: center;
      justify-content: space-between;

      button {
        cursor: pointer;
      }
    }

    &_title {
      text-transform: uppercase;
      color: $clr-primary-and-accent;
    }

    &_content {
      display: flex;
      flex-direction: column;
      align-items: center;

      app-completion-status {
        width: 69%;
        @include mobile {
          width: 87%;
        }
      }

      .seaz-profile__info {
        margin-top: 2rem;
        @include tablet {
          justify-content: center;
          align-items: center;
        }
      }

      .seaz-profile__info_line {
        @include tablet {
          width: 84%;
        }
      }

      .seaz-profile__info_completion {
        margin-top: 1.2rem;
      }
    }

    &_img {
      position: relative;

      &_avatar {
        width: 11.5rem;
        height: 11.5rem;
        border-radius: 50%;
      }

      &_badge {
        position: absolute;
        border-radius: 50%;
        border: 2px solid $clr-white;
        bottom: -3px;
        right: 2px;
        width: 4.9rem;
        height: 4.9rem;
        display: flex;
        align-items: center;
        justify-content: center;

        img {
          width: 4.7rem;
          height: 4.7rem;
          border-radius: 50%;
        }
      }
    }

    &_btns {
      display: flex;
      justify-content: center;
      margin-top: 1.6rem;

      &_btn {
        width: 4.8rem;
        height: 4.8rem;
        border-radius: 50%;
        display: flex;
        align-items: center;
        justify-content: center;
        margin: 0 0.5rem;
        cursor: pointer;

        &.edit {
          background-color: $seaz-mulberry;
        }

        &.download {
          background-color: $clr-primary-and-accent;
        }

        &.share {
          background-color: #010202;
        }

        &.jump {
          background-color: #00b9ff;
        }
      }
    }
  }

  &__not_ready {
    position: absolute;
    width: 100%;
    height: 100%;
    background: #ffffff91;
    display: flex;
    align-items: center;
    justify-content: center;
    left: 0;

    &_text {
      padding: 2.3rem;
      background-color: $clr-white;
      box-shadow: 0 4px 24px rgba(0, 0, 0, 0.25);
      font-size: 1.6rem;
      color: $clr-primary-and-accent;
      user-select: none;
      text-align: center;
      @include mobile {
        width: 80%;
      }
    }
  }

  .seaz-cv__badges_status_change {
    @include laptop {
      margin-left: 1rem;
    }
    @media screen and (max-width: 1400px) {
      margin-left: 0;
      margin-top: 1rem;
    }
  }

  .seaz-profile__info_line {
    height: 0.8rem;

    &_progress {
      height: 0.8rem;
    }
  }

  .seaz-cv__badges_status_job {
    @include mobile {
      padding: 0.7rem 1.2rem 0.6rem;
    }
    @media screen and (max-width: 400px) {
      width: auto;
    }
  }
}

app-user-dashboard {
  display: block;
  background-color: #f5f5f5;
  min-height: calc(100% - 62px);
}

.seaz-crew__documents-header {
  padding: 3rem 4.8rem 0;
  @include laptop {
    padding: 3rem 2rem;
  }
  @include mobile {
    padding: 1.6rem 1.2rem;
  }
}

.seaz-crew__edit_box--docs {
  .seaz-crew__edit_head_row--docs {
    @include mobile {
      padding-bottom: 2.4rem;
      .seaz-crew__edit_title {
        padding-bottom: 0;
      }
    }
  }

  &__header {
    display: flex;
    flex-direction: row;
    align-items: center;

    .seaz__return_btn {
      margin-right: 2rem;
    }
  }

  @include tablet-big {
    .seaz-crew__edit_head_row {
      padding: 3rem 2rem 1.7rem 2rem;
      flex-direction: column;
      align-items: flex-start;

      .seaz__btn_outlined_plus {
        width: 100%;
        display: flex;
        justify-content: center;
      }
    }

    .seaz-crew__edit_head_row--actions {
      width: 100%;
      flex-direction: column;
      margin-left: 0;

      .seaz__btn_outlined,
      .seaz__btn_outlined_plus {
        width: 100%;
        margin-left: 0;
        margin-top: 1.2rem;
      }
    }
  }
}

.yaht-boat__document_list {
  .yaht-documents__name {
    width: 33.4%;
    padding-right: 1.5rem;
    @include laptop {
      width: 27.4%;
    }
    @include tablet {
      width: 30.4%;
    }
    @media screen and (max-width: 600px) {
      width: 46%;
      padding-bottom: 4rem;
    }
  }

  .yaht-documents__date {
    width: 34.2%;
    @include laptop {
      width: 43.2%;
    }
    @include tablet {
      width: 34.6%;
    }
    @media screen and (max-width: 600px) {
      width: auto;
    }
  }

  .yaht-documents__snooze {
    width: 30.2%;
    display: flex;
    @include tablet {
      width: 32.2%;
    }
    @media screen and (max-width: 600px) {
      width: auto;
    }
  }

  .yaht-documents__row_info--left {
    display: flex;
    width: 64%;
    @media screen and (max-width: 600px) {
      width: 100%;
      justify-content: space-between;
    }
  }
}

.yaht-boat__document_list--short {
  .yaht-documents__name {
    align-items: center;
    width: 37%;
    @include laptop {
      width: 28%;
    }
    @include tablet {
      width: 31%;
    }
    @media screen and (max-width: 600px) {
      width: 54%;
      padding-bottom: 2rem;
    }
  }

  .yaht-documents__row_info--left {
    display: flex;
    width: 71%;
    @media screen and (max-width: 600px) {
      width: 100%;
      justify-content: space-between;
    }
  }

  .yaht-documents__date {
    width: 33.2%;
    @include laptop {
      width: 38.2%;
    }
    @include tablet {
      width: 33.2%;
    }
    @media screen and (max-width: 600px) {
      width: auto;
    }
  }

  .yaht-documents__snooze {
    width: 28%;
    display: flex;
    @include tablet {
      width: 31%;
    }
    @media screen and (max-width: 600px) {
      width: auto;
    }
  }
}

.form__union {
  .form__union_select {
    padding-top: 1.4rem;
  }
}

.seaz-cv__content_head--about {
  @include tablet {
    .seaz-cv__content_head {
      padding-top: 4rem;
      border-top: 1px solid #bdbdbd;
    }
  }
}

.seaz-crew__cv {
  .seaz-cv__badges {
    @media screen and (max-width: 670px) {
      position: relative;
    }
  }

  .seaz-cv__info_box {
    @media screen and (max-width: 670px) {
      padding-top: 6px;
    }
  }

  .seaz-cv__badges_status_job.on-board {
    @include laptop {
      min-width: 17rem;
    }
    @include tablet {
      min-width: auto;
    }
  }
}

.seaz-cv__my_crew {
  &.seaz-cv__crew {
    .seaz-cv__info_box {
      @media screen and (max-width: 670px) {
        padding-top: 7rem;
      }
    }

    .seaz-cv__photo {
      @media screen and (max-width: 900px) {
        left: 5rem;
      }
      @media screen and (max-width: 670px) {
        left: inherit;
      }
    }

    @include laptop {
      .seaz-cv__info {
        padding: 2.5rem 0 0;
      }
    }
  }
}

.seaz-crew__cv-preview {
  .seaz-crew__cv {
    .seaz-cv__info_box {
      @media screen and (max-width: 670px) {
        padding-top: 3rem;
      }
    }

    .seaz-cv__info {
      .seaz-cv__badges_status {
        font-size: 1.4rem;
      }
    }

    .seaz-cv__info_progress {
      margin-top: 15rem;
      @media screen and (max-width: 1300px) {
        margin-top: 9rem;
      }
      @include laptop {
        margin-top: 11rem;
      }
      @media screen and (max-width: 670px) {
        margin-top: 0;
      }
    }
  }

  .seaz-crew__cv .seaz-cv__slider {
    width: 126.4rem;
    @media screen and (max-width: 1300px) {
      width: 100rem;
    }
    @include laptop {
      width: 100%;
    }
  }
}

.seaz-cv__crew_download {
  display: flex;
  align-items: center;
  position: absolute;
  right: 0;
  padding: 1.4rem 3.1rem;
  font-weight: 500;
  font-size: 1.4rem;
  letter-spacing: 0.1em;
  text-transform: uppercase;
  color: #fff;
  background-color: $clr-primary-and-accent;
  border-radius: 0.4rem;

  app-seazone-icon {
    margin-right: 1.5rem;
  }

  &--text {
    padding-left: 1.2rem;
  }

  @media screen and (max-width: 1024px) {
    &--text {
      display: none;
    }

    app-seazone-icon {
      margin-right: 0;
    }
  }

  @media screen and (max-width: 480px) {
    padding: 1.5rem;
  }
}

.seaz-cv__crew {
  .seaz-crew__cv .seaz-cv__slider,
  .seaz-crew__cv .seaz-cv__info {
    @include extra-large {
      width: 100rem;
    }
    @media screen and (max-width: 1120px) {
      width: 80rem;
    }
    @media screen and (max-width: 900px) {
      width: 67rem;
    }
    @media screen and (max-width: 740px) {
      width: 60rem;
    }
    @media screen and (max-width: 670px) {
      width: 100%;
    }
  }

  .seaz-crew__cv .seaz-cv__info_main {
    @media screen and (max-width: 1120px) {
      width: 27%;
    }
    @include laptop {
      padding-top: 12rem;
      width: 15.3rem;
    }
    @media screen and (max-width: 900px) {
      width: 14rem;
    }
    @media screen and (max-width: 670px) {
      padding-top: 0;
      width: 50%;
    }
    @include mobile {
      width: 45%;
    }
  }

  .seaz-crew__cv .seaz-cv__info_progress {
    width: 14rem;
  }

  .seaz-cv__info_box {
    justify-content: space-between;
  }

  .seaz-cv__photo {
    @media screen and (max-width: 1120px) {
      right: 19rem;
    }
    @include laptop {
      right: inherit;
      left: 7rem;
    }
    @media screen and (max-width: 900px) {
      left: 2rem;
    }
    @media screen and (max-width: 670px) {
      left: inherit;
      right: 0;
    }
  }

  .seaz-crew__cv .seaz-cv__details {
    @media screen and (max-width: 1120px) {
      width: 46%;
      margin-left: 3%;
    }
    @include laptop {
      width: 55%;
      margin-left: 0;
    }
    @media screen and (max-width: 670px) {
      padding-top: 2rem;
      width: 100%;
    }
  }

  .seaz-cv__info_btns {
    @media screen and (max-width: 900px) {
      left: 20rem;
      bottom: 8rem;
    }
    @media screen and (max-width: 670px) {
      top: 16rem;
      left: inherit;
      right: 10%;
    }
  }

  .seaz-cv__slider_box {
    @include laptop {
      width: 80rem;
      margin: 0 auto;
    }
    @media screen and (max-width: 900px) {
      width: 67rem;
    }
    @media screen and (max-width: 670px) {
      width: 100%;
    }
  }

  .seaz-cv__info_badges {
    @media screen and (max-width: 740px) {
      width: 10.4rem;
    }
    @media screen and (max-width: 670px) {
      width: 47%;
    }
  }

  .seaz-cv__badge_greeny {
    @media screen and (max-width: 740px) {
      padding: 1.4rem 0.6rem;
    }
    @media screen and (max-width: 670px) {
      padding: 1.4rem 1.6rem;
    }
  }

  .seaz-cv__details_list {
    @media screen and (max-width: 740px) {
      width: 58%;
    }
    @media screen and (max-width: 670px) {
      width: 50%;
    }
  }

  .seaz-crew__cv .seaz-cv__info_progress {
    @media screen and (max-width: 670px) {
      width: 15rem;
      margin-top: 9.4rem;
    }
    @include mobile {
      padding-left: 0;
      width: 13rem;
    }
  }
}

.seaz-cv__crew_dot {
  display: inline-flex;
  width: 6px;
  height: 6px;
  border-radius: 50%;
  background-color: $clr-primary-and-accent;
  margin: 0 1.6rem;
  margin-bottom: 5px;
  flex-shrink: 0;
}

.seaz-cv__crew_head {
  position: relative;
  justify-content: center;

  .seaz-h2 {
    display: flex;
    align-items: center;
  }

  .seaz__return_btn {
    position: absolute;
    @media screen and (max-width: 600px) {
      position: relative;
    }
  }

  &.seaz-yaht__head {
    @include laptop {
      width: 97%;
    }

    .seaz__return_btn {
      flex-shrink: 0;
      @media screen and (max-width: 600px) {
        position: absolute;
      }
    }

    .seaz-h2 {
      text-align: center;
      padding: 0 1rem;
      @media screen and (max-width: 700px) {
        width: 100%;
        text-align: center;
        justify-content: center;
        padding: 0 6rem;
      }

      .seaz-cv__crew_head_name,
      .seaz-cv__crew_dot {
        @media screen and (max-width: 700px) {
          display: none;
        }
      }
    }
  }
}

.seaz-crew__navigation_item {
  @include mobile {
    width: 33.3%;
    flex-shrink: 0;
    border: none;
    border-bottom: 2px solid #dbdbdb;
    a {
      padding: 0.8rem;
    }
    &.active-item {
      border-color: #351f73;
    }
  }
}

.seaz-crew__navigation {
  @include mobile {
    overflow: auto;
    padding-bottom: 0;
    margin-bottom: 3rem;
  }
}

.seaz-cv__crew {
  .seaz-cv__badge_main {
    background-color: $seaz-bg;
  }

  .seaz-cv__contact {
    background-color: $seaz-bg;

    &::before {
      background-color: $seaz-bg;
    }
  }

  .seaz-cv__preferences_item {
    background-color: $seaz-bg;
  }

  .seaz-cv__check_badge_box {
    flex-wrap: wrap;
  }

  .seaz-cv__check_badge {
    background-color: $seaz-bg;
  }

  .seaz-cv__experience_duties {
    background-color: $seaz-bg;
  }

  .seaz-cv__courses_item_box:nth-of-type(2n - 1) {
    background-color: $seaz-bg;
  }

  .seaz-cv__languages {
    background-color: $seaz-bg;
  }

  .seaz-cv__documents:nth-of-type(2n - 1) {
    background-color: $seaz-bg;
  }

  .seaz-cv__reference_request {
    background-color: $seaz-bg;
  }
}

.seaz-crew__cv {
  .seaz-cv__contact {
    .hide__contract {
      color: #351f73;
      size: 24px;
      line-height: 27px;
      text-align: center;
      text-transform: uppercase;
      font-weight: 600;
    }

    &_show_contacts {
      @include d-flex-center-content;
    }
  }
}

.seaz-crew__bank_item {
  margin-bottom: 5rem;
}

.offerred-status {
  &-new {
    background-color: #48b2ff;
    color: $clr-white;
  }

  &-pending {
    background-color: #f49342;
    color: $clr-white;
  }

  &-process {
    background-color: #3aae27;
    color: $clr-white;
  }

  &-rejected {
    background-color: #d70000;
    color: $clr-white;
  }

  &-applied {
    background-color: #033810;
  }

  &-declined {
    background-color: #d70000;
    color: $clr-white;
  }

  &-hired {
    background-color: #46a6a4;
    color: $clr-white;
  }

  &-closed {
    background-color: $seaz-document-count;
    color: $seaz-alarm-bg;
  }

  &-shared {
    background-color: transparent;
    color: #3aae27 !important;
    border: solid 1px #3aae27;
  }
}

.seaz-cv__info_btns_mobile {
  display: none;
  @include laptop {
    display: flex;
    &.seaz-cv__info_btns {
      position: static;
      padding: 0;
      padding-top: 1.9rem;
    }
  }
}

.seaz-cv__my_crew {
  .seaz-crew__cv .seaz-cv__info_progress {
    margin-top: 15rem;
    width: 24rem;
    @media screen and (max-width: 1300px) {
      width: 20rem;
    }
    @media screen and (max-width: 800px) {
      margin-top: 11rem;
    }
    @media screen and (max-width: 740px) {
      width: 15rem;
    }
    @media screen and (max-width: 670px) {
      margin-top: 3rem;
    }
  }

  .seaz-cv__info_progress_title {
    text-align: center;
  }

  .seaz-cv__crew {
    .seaz-cv__info_btns {
      left: 24rem;
      @media screen and (max-width: 900px) {
        left: 23rem;
      }
      @media screen and (max-width: 740px) {
        left: 21rem;
        top: 15rem;
      }
    }

    .seaz-cv__photo {
      @media screen and (max-width: 900px) {
        left: 6rem;
      }
      @media screen and (max-width: 670px) {
        left: inherit;
      }
    }

    &.seaz-crew__cv .seaz-cv__info_main {
      @media screen and (max-width: 340px) {
        width: 33%;
      }
    }
  }
}

.seaz-crew__edit_characteristics {
  app-seazone-form-field {
    .form-field--content-outline.form-field--content {
      width: 76%;
      max-width: 76%;
      @media screen and (max-width: 1300px) {
        width: 70%;
        max-width: 70%;
      }
      @include tablet {
        width: 60%;
        max-width: 60%;
      }
      @include mobile {
        width: 100%;
        max-width: 100%;
      }
    }
  }
}

.seaz-crew__edit_preferences .currency-box {
  .form-field--content {
    width: 25.5rem;

    @include tablet {
      width: 100%;
    }
  }
}

.seaz-crew__edit_box.seaz-crew__edit_preferences {
  .seaz-crew__edit_preferences_multiselect {
    .form-field--content.form-field--content-outline {
      max-width: 38.5rem;
      width: 38.5rem;
      @include tablet {
        max-width: 100%;
        width: calc(100% - 21.5rem);
      }
      @include mobile {
        width: 100%;
        max-width: 100%;
      }
    }
  }

  .seaz-crew__edit_row--contract {
    width: 60rem;
    @include tablet {
      width: 100%;
      padding: 0 2rem;
    }

    .seaz-crew__edit_label {
      max-width: 20rem;
      width: 20rem;
      margin-right: 1.5rem;
      flex-shrink: 0;
      color: #010202;
      @include tablet-small {
        max-width: 13rem;
        width: 13rem;
      }
      @include mobile {
        max-width: 100%;
        width: auto;
        margin-right: 0;
        margin-bottom: 0.8rem;
      }
    }

    app-seazone-select {
      margin-bottom: 1.2rem;

      .form-field--wrapper.form-field--wrapper__row {
        width: 100%;
        padding: 0;

        .form-field--content.disabled {
          background-color: #fff;
          opacity: 0.3;
        }
      }
    }

    .seaz-crew__edit_contract_box {
      width: 100%;

      app-seazone-checkbox {
        .form__checkbox {
          padding-bottom: 0.5rem;

          .form__checkbox-label {
            width: 2.4rem;
            height: 2.4rem;

            &::before {
              width: 2.4rem;
              height: 2.4rem;
              border-color: #4f194a;
              border-radius: 0;
            }
          }

          .form__checkbox-label-text {
            color: #4f194a;
          }

          .form__checkbox-input:checked + label:before {
            background-color: #4f194a;
          }
        }
      }
    }
  }
}

.seaz-crew__edit_row--lenght {
  app-seazone-input {
    .form-field--wrapper.form-field--wrapper__row {
      @include mobile {
        flex-direction: row;
        .form-field--label {
          margin-bottom: 0;
        }
      }
    }
  }

  .seaz-crew__edit_length_box {
    app-seazone-checkbox {
      .form__checkbox-label:before {
        border-radius: 0;
      }

      .form__checkbox {
        @include mobile {
          margin-left: 0;
        }
      }
    }
  }
}

.seazone__toggle {
  &-label.form__toggle-label {
    &::before {
      width: 2.9rem;
      height: 1.6rem;
      border: 2px solid $seaz-mulberry;
      border-radius: 1.8rem;
      background-color: $clr-white;
    }

    &::after {
      height: 1.6rem;
      width: 1.6rem;
      border: 2px solid $seaz-mulberry;
      background-color: $clr-white;
      top: 0;
      box-shadow: none;
    }
  }

  .form__toggle-input:checked + label:before {
    background-color: $seaz-mulberry;
    border-color: $seaz-mulberry;
  }

  .form__toggle-input:checked + label:after {
    background-color: $clr-white;
  }

  &.disabled {
    opacity: 0.5;

    .seazone__toggle-label {
      cursor: default;
    }
  }
}

.dashboard {
  .toggle-button {
    border-left: 0.1rem solid rgba($color: #351f6f, $alpha: 0.1);
    font-size: 0.6rem;
    padding: 0.6rem 1.5rem;
    margin-right: -2rem;
    margin-left: 1.2rem;
    cursor: pointer;

    &.active:before {
      transform: rotate(180deg);
    }
  }

  .item-content {
    .hidden {
      height: 0;
      overflow: hidden;
    }
  }
}

.seaz-icon__note {
  margin-top: 2.2rem;
  @include laptop {
    margin-top: 1rem;
  }

  .seaz-cv__info_btns_document {
    position: relative;
    width: 5rem;
    height: 5rem;
    @include laptop {
      width: 4rem;
      height: 4rem;
    }
  }

  .seaz-search__card_controls--new {
    @include laptop {
      top: 2px;
      right: 0;
    }
  }
}

.seaz-preview__cv {
  .seaz-crew__cv .seaz-cv__info_progress {
    margin-top: 17rem;
    @include laptop {
      margin-top: 13rem;
    }
    @media screen and (max-width: 670px) {
      margin-top: 4rem;
    }
  }
}

.seaz-search__offer_header {
  flex-direction: column;
  align-items: center;

  &_btns {
    margin-top: 2rem;
    display: flex;
    width: 100%;
    align-items: center;
    justify-content: center;
    @include mobile {
      flex-wrap: wrap;
      flex-direction: column;
    }

    .seaz__btn_outlined {
      @include mobile {
        margin-top: 1.2rem;
      }
    }

    button {
      &:not(:last-of-type) {
        margin-right: 2%;
        @include mobile {
          margin-right: 0;
          margin-bottom: 1rem;
        }
      }

      @include mobile {
        width: 48%;
        padding: 1rem 1.2rem;
      }
    }

    .seaz-search_relevant_btn {
      margin: initial;
      border-bottom: 1px dashed #351f73;
      padding: 0 10px;

      &::after {
        border-bottom: none;
      }

      @include mobile {
        width: auto;
        margin-top: 1rem;
      }
    }
  }
}

.seaz-job__no_date {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.seaz-cv__sharing {
  .seaz-cv__navigation {
    @include tablet {
      margin-top: 3rem;
    }
  }

  .seaz-crew__cv .seaz-cv__info_progress {
    margin-top: 15rem;
    @media screen and (max-width: 1300px) {
      margin-top: 11rem;
    }
    @media screen and (max-width: 670px) {
      margin-top: 9rem;
    }
  }
}

.seaz-toggle {
  .form__toggle-label:before {
    background-color: transparent;
    border: 2px solid $seaz-mulberry;
    width: 2.9rem;
    height: 1.6rem;
    border-radius: 18px;
  }

  .form__toggle-label:after {
    top: 0;
    width: 1.6rem;
    height: 1.6rem;
    border: 2px solid $seaz-mulberry;
    background-color: transparent;
    left: 0;
    box-shadow: none;
  }

  .form__toggle-input:checked + label:before {
    background-color: $seaz-mulberry;
  }

  .form__toggle-input:checked + label:after {
    background-color: $clr-white;
    border: 2px solid $seaz-mulberry;
    width: 1.6rem;
    height: 1.6rem;
  }
}

.seaz-cv__offer-information {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 4rem;
  @include tablet {
    margin-top: 2rem;
  }

  button {
    width: 100%;
    margin-bottom: 3rem;
    padding: 1.2rem;

    @include tablet {
      margin-bottom: 1.2rem;
      padding: 1.2rem 0;
    }
  }
}

@keyframes highlighter {
  from {
    background-color: rgba(53, 31, 111, 0.15);
  }
}

.highlight {
  animation-name: highlighter;
  animation-duration: 5s;
  animation-fill-mode: forwards;
}

.error {
  &__no-data {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    min-height: 70vh;
    padding: 2rem;

    &-icon {
      width: 37.6rem;
      height: 28.2rem;
      margin-bottom: 6.4rem;
      max-width: 100%;
      display: flex;
      @include tablet {
        margin-bottom: 2rem;
      }

      img {
        max-width: 100%;
      }
    }

    &-text {
      color: $clr-primary-and-accent;
      text-transform: uppercase;
      line-height: 43.5px;
      text-align: center;
      max-width: 55rem;
    }
  }
}

.sharing-modal {
  &__title {
    color: $clr-primary-and-accent;
  }

  &__content {
    margin-top: 3rem;
    display: flex;
  }
}

.seaz-crew__cv_search {
  .seaz-crew__cv {
    .seaz-cv__info_main {
      padding-top: 10rem;
      @include laptop {
        padding-top: 12rem;
      }
      @media screen and (max-width: 670px) {
        padding-top: 0;
      }
    }
  }
}

.crew-id {
  &__box {
    display: flex;
    align-items: center;
    margin-top: 2rem;
  }

  &__title {
    text-transform: uppercase;
    margin-right: 0.4rem;
    letter-spacing: 0.5px;
    color: $seaz-document-count;
    font-size: 1rem;
    padding-top: 0.4rem;
  }

  &__number {
    color: $seaz-document-count;
    font-weight: 500;
    padding-top: 0.4rem;

    &_box {
      display: flex;
      align-items: center;
      background-color: $seaz-bg;
      padding: 0.3rem 0.4rem;
      border-radius: 4px;
      cursor: pointer;
    }

    &_icon {
      margin-left: 0.5rem;
    }
  }
}

app-location-region-autocomplete,
app-location-timezone-autocomplete {
  input {
    border: none;
    width: 100%;
    font: 1.6rem/2.8rem $base-font;
  }

  input:focus,
  input.ng-invalid.ng-dirty {
    border: none;
  }

  .form-field--content-wrapper {
    display: flex;
  }
}

.company {
  position: absolute;
  height: 50px;
  width: 40px;
  background: #eee;
  top: 60px;
}

.company__block {
  position: absolute;
  display: none;
  z-index: 100;
}

.company__block.active {
  display: block;
}

.main-btn_green {
  display: flex;
  align-items: center;
  justify-content: center;
}

.settings-user__col.form__col {
  align-items: center;
  @media screen and (max-width: 800px) {
    width: 100%;
    justify-content: flex-start;
    padding-top: 1rem;
  }

  button {
    margin-right: 1rem;
  }
}

.boat-settings__title_row.form__row {
  @media screen and (max-width: 800px) {
    flex-direction: column;
    align-items: flex-start;
  }
}

.rotate-controls {
  display: flex;
}

.progress-bar-container {
  width: 100%;
  height: 6px;
  border-radius: 3px;
  position: relative;
  overflow: hidden;
  background-color: $seaz-bg;

  .progress-bar {
    position: absolute;
    height: 100%;
    transition:
      width 0.3s ease,
      color 0.1s ease-in;
    background-color: $clr-primary-and-accent;

    &.done {
      background-color: $clr-green;
    }
  }
}

.seaz-input__row_docs_cv.form__row {
  margin-bottom: 1.5rem;
}

.currency-spaces {
  white-space: nowrap;
}

.seaz-tabs-wrap {
  padding: 0.2rem;
  border-radius: 0.9rem;
  background-color: #e7e7eb;
  font-size: 1.3rem;
  font-weight: 700;
}

.light-bg-border-corners {
  @include light-bg-border-corners;
}

.seaz-region-autocomplete {
  width: 100%;
}

.boat-user-manage {
  @media screen and (max-width: 768px) {
    .form__col {
      flex-direction: column;
    }

    .settings-user__col.form__col button {
      margin-right: 0;
    }

    .main-btn_green,
    .user-manage-btn {
      width: 100%;
      margin-top: 1rem;
    }
  }
}

.seaz-general-cover-uploader {
  .seaz-uploader__choose {
    justify-content: flex-start;
  }
}

.seaz-edit-profile-other-experience {
  &__header-row {
    @include tablet {
      flex-direction: column;
      align-items: flex-start;

      &--right {
        width: 100%;
        flex-direction: column;

        .seaz__btn_outlined_plus {
          width: 100%;
          margin-top: 1.6rem;
        }
      }
    }
  }
}

.scroll-top__btn {
  position: fixed;
  bottom: 2.4rem;
  right: 2.4rem;
  z-index: 100;
  width: 4rem;
  height: 4rem;
  background-color: $clr-primary-and-accent;
  padding: 1.2rem;
  border-radius: 0.4rem;
  opacity: 0;
  transform: translateY(100px);
  transition: all 0.5s ease;

  svg {
    transform: rotateZ(-90deg);
  }

  &.active {
    opacity: 1;
    transform: translateY(0);
  }

  @media screen and (max-width: 1024px) {
    display: none;
  }
}

.settings-wrapper {
  max-width: 126.4rem;
  width: 100%;
  margin: 0 auto;
  padding: 6.4rem 0;

  &--header {
    &__title {
      font: 500 3rem/3rem $base-font;
      color: $clr-primary-and-accent;
      text-transform: uppercase;
      margin-bottom: 3.4rem;

      @media screen and (max-width: 1267px) {
        margin-left: 1.2rem;
      }
    }
  }

  &--content {
    &__row {
      margin-top: 2.8rem;
    }
  }

  .seazone-card--header__title {
    font-weight: 500;
  }

  .form-field--label span {
    font-size: 1.4rem;
  }

  .form__row .form-field {
    &--wrapper {
      &__row {
        .form-field--label {
          width: 100%;
          max-width: 16rem !important;
          margin-right: 2rem !important;
        }
      }
    }
  }

  app-toggle-checkbox {
    display: flex;
    align-items: center;

    .form-field__label {
      width: 100%;
      max-width: 16rem;
      margin-right: 2rem;
    }
  }

  app-seazone-radio {
    .radio__row-wrapper .form__block_label {
      width: 100%;
      max-width: 16rem;
    }
  }

  .settings-btn {
    font-size: 1.6rem;
    font-weight: 550;
  }

  @include laptop {
    .change-password-form .form__col {
      width: 100%;
    }
  }

  @include mobile {
    &--header {
      &__title {
        margin: 0 0 1.2rem 1.2rem;
      }
    }

    .settings-btn {
      font-size: 1.4rem;
    }

    &--content {
      &__row {
        margin-top: 1rem;
      }
    }

    .seazone-card {
      padding: 1.6rem;
    }

    .form__row.form__row--actions {
      flex-direction: row;
    }

    app-seazone-radio {
      .radio__row-wrapper .form__block_label {
        margin: 0 0 1.4rem 0;
      }

      .radio__row-wrapper {
        flex-direction: column;
      }
    }
  }
}

.form__select-group .select-group {
  font-size: 1.4rem;
  border: 1px solid $seaz-head-arrow;

  &__title {
    display: block;
    min-height: 4rem;
    font-family: $base-font;
    font-size: 1.4rem;
    font-weight: 500;
    line-height: 4rem;
    padding: 0 2.4rem;
    color: $clr-light-grey;
  }

  .select-option {
    display: block;
    font-size: 1.4rem;
  }
}

.seaz-edit-profile {
  .seaz-crew__edit_head_row--right {
    @include tablet {
      flex-direction: column;
      align-items: flex-start;
      width: 100%;

      app-seazone-checkbox {
        margin-bottom: 1.6rem;
      }
    }
  }
}

.crew-id-box {
  display: flex;
  justify-content: center;
  color: $profile-month;
  font-size: 1.2rem;
  margin-top: 1.7rem;

  &__number {
    display: flex;
    align-items: center;
    font-weight: 500;
    letter-spacing: 0.1em;
    text-transform: uppercase;
    background-color: $clr-ashy-grey;
    border-radius: 2.4rem;
    padding: 0.4rem 0.8rem 0.4rem 1.2rem;
    margin-left: 0.8rem;
    cursor: pointer;
    transition: background-color 0.3s;

    &--icon {
      margin-left: 0.3rem;
    }

    &:hover {
      background-color: #ccc;
    }
  }

  @include tablet {
    text-align: center;
    flex-direction: column;
    margin-top: 0.8rem;

    &__number {
      justify-content: center;
      margin-top: 0.2rem;
    }
  }
}

.seaz-cv__couple {
  margin-bottom: 1.4rem;
}

.dropdown-side-menu {
  width: 100%;
  max-width: 17rem;
  box-sizing: border-box;
  background-color: $light-color;
  box-shadow: 0 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 1.2rem;
  padding: 2.6rem;
}

.search-service {
  display: flex;
  align-items: center;

  .wrapper-rounded .form-field--content {
    border-radius: 0.4rem 0 0 0.4rem;
  }

  &__btn {
    font-size: 1.4rem;
    font-weight: 500;
    letter-spacing: 0.1em;
    text-transform: uppercase;
    word-break: break-word;
    color: $clr-white;
    min-width: 5.2rem;
    height: 4rem;
    padding: 0.2rem;
    border-radius: 0 0.4rem 0.4rem 0;
    background-color: $clr-primary-and-accent;

    &:disabled {
      background-color: $clr-grey;
    }
  }
}

.form-field-row {
  align-items: center;

  @include mobile {
    align-items: flex-start;
  }
}

.supplier-profile-header {
  border-bottom: none;

  .seaz-cv__head_btn_jump {
    border-radius: 0.4rem;
  }
}

.cv-general-experience {
  &__title {
    font:
      500 2.4rem/1.4 'Roboto',
      sans-serif;
    color: $seaz-text;
  }
}

.edit-general-yacht-experience {
  &__tooltip {
    display: block;
    margin-left: 1.2rem;
  }

  .seaz-crew__edit_services {
    display: flex;
    align-items: center;
  }

  @include tablet {
    .seaz-crew__edit_head_row--right {
      flex-direction: row;
      align-items: center;
    }

    .seaz-crew__edit_head_row--right {
      flex-direction: column;
      align-items: flex-start;
    }
  }

  @include mobile {
    .seaz__btn_outlined_plus {
      padding: 1rem;
    }
  }
}

.seaz-cv__tooltip.general-experience-tooltip .tooltip .tooltip__text {
  color: $profile-month;
  text-transform: inherit;
  letter-spacing: 0.01em;
}

.info_boat-general-info {
  @include d-align-items-center;
  justify-content: center;

  @media screen and (max-width: 700px) {
    flex-direction: column;
  }

  @include mobile {
    align-items: flex-start;

    .seaz-yaht__info_item {
      flex-direction: column;
      align-items: flex-start;
      width: 100%;
    }

    .seaz-yaht__info_label {
      padding-bottom: 0.8rem;
    }

    .seaz-yaht__info_item_country {
      padding-left: 0;
    }

    .seaz-text-medium.seaz-yaht__info_item_country,
    .seaz-yaht__info_label {
      width: 100%;
    }
  }
}

.info_crew_box-separator {
  border-top: 1px solid $clr-grey;
  margin: 2.6rem 0 2.4rem 0;

  @media screen and (max-width: 700px) {
    display: none;
  }
}

.documents_list {
  display: block;
  margin-bottom: 4rem;
}

.dropdown-status {
  .hidden-option {
    display: none;
  }
}

.seazone-colored-select-container {
  display: flex;
  width: fit-content;
}

.status-task-content {
  margin-top: 25px;

  &__task-name {
    @include multiline-truncation(1);
    margin-bottom: 0.4rem;
    font-weight: 500;
    color: $clr-primary-and-accent;
  }
}

@media (hover: none) and (pointer: coarse) {
  .seaz__btn_rounded:hover {
    opacity: 1;
  }
}
