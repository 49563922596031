.invite-friends-modal {
  &__title {
    margin-bottom: 2.4rem;
  }

  &__text {
    font-size: 1.6rem;
    line-height: 1.9rem;
    color: $seaz-about-text;
  }

  .bottom-indent {
    margin-bottom: 1.6rem;
  }
}

.copy-zone {
  @include d-flex-space-between;
  align-items: flex-start;
  border: 1px solid $clr-grey;
  border-radius: 0.4rem;
  background-color: $clr-ashy-grey;
  padding: 1rem 1.5rem;
  margin: 2.4rem 0;

  &__content {
    width: 50%;
  }

  &__text {
    line-height: 1.6rem;
    color: $seaz-text;
  }

  &__link {
    display: block;
    font-size: 1.4rem;
    line-height: 1.6rem;
    margin: 1.6rem 0;
    word-break: break-word;
  }

  @include tablet {
    &__content {
      width: 80%;
    }
  }
}
