.menu {
  display: flex;
  &__item {
    flex-grow: 1;
    &.blocked {
      pointer-events: none;
      a {
        opacity: 0.3;
      }
    }
    &.activated {
      display: none;
      @include desktop {
        display: flex;
        align-items: center;
        color: white;
        margin-left: 1rem;
        opacity: 0;
        transition: all 0.3s linear;
        &.show {
          opacity: 1;
        }
      }
    }
  }

  > .active-item > .menu__link {
    opacity: 1;
  }
  &__link {
    position: relative;
    display: flex;
    align-items: center;
    color: white;
    // padding: 1.2rem 0;
    opacity: 0.65;
    transition: $tr_03;
    min-height: 40px;
    &-text {
      white-space: nowrap;
    }
    &-icon {
      font-size: 2rem;
      &::before {
        content: none;
      }
      svg {
        fill: white;
      }
      &.icon-search svg {
        stroke: white;
      }
    }
    &:hover {
      opacity: 1;
      cursor: pointer;
    }
    &-sub-text {
      opacity: 0.5;
    }
    @include tablet {
      &:hover,
      &:active {
        opacity: 1;
        background-color: #3f9795;
      }
    }
  }
}

.top-navigation {
  background: $clr-green;
  padding: 1rem 0;
  // position: fixed;
  width: 100%;
  z-index: 900;
  min-height: 6rem;
  @include desktop {
    transition: all 0.2s ease-in-out;
  }
  &__header {
    width: 100%;
    display: block;
  }
  .menu__item {
    &_list {
      .menu__link {
        position: relative;
        &::before {
          content: '';
          border: 6px solid transparent;
          border-top: 6px solid white;
          position: absolute;
          right: 7px;
          top: 43%;
          @include desktop {
            display: none;
          }
        }
      }
      .top-navigation__sub-menu {
        .menu__link {
          padding-right: 0;
          &::before {
            display: none;
          }
        }
      }
      .menu__link {
        padding-right: 2rem;
      }

      @include desktop {
        &::before {
          content: '\e823';
          position: absolute;
          font-family: 'fontello';
          right: 2rem;
          top: 22px;
          font-size: 1rem;
          color: white;
          opacity: 0.5;
          z-index: 1;
          transform: rotateZ(90deg);
        }
      }
    }
    &.active-item {
      border-radius: 8px;
      background: rgba(53, 31, 111, 0.3);
      @include desktop {
        background: #3f9795;
        border-radius: 0;
      }
    }

    .top-navigation__sub-menu {
      &::before {
        content: '';
        border: 7px solid transparent;
        border-bottom: 6px solid $clr-white;
        position: absolute;
        top: -1.2rem;
        left: 33px;
        @include desktop {
          display: none;
        }
      }
      .menu__item {
        &.active-item {
          background-color: inherit;
        }
        &.blocked {
          .menu__link {
            color: $disabled-link;
            @include desktop {
              color: $clr-white;
              opacity: 0.5;
            }
          }
        }
      }
    }
    .menu__link {
      justify-content: center;
      @include desktop {
        justify-content: flex-start;
        padding: 2rem 4rem;
      }
    }
    .menu__link-icon {
      margin-right: 1rem;

      @include mobile {
        margin-right: 0.5rem;
      }
    }
    .top-navigation__sub-menu {
      bottom: -5.4rem;
      @include desktop {
        bottom: auto;
      }
    }
  }
  &__wrap {
    display: flex;
    align-items: center;
    justify-content: center;
    @include desktop {
      padding: 0.6rem 0;
      justify-content: space-between;
    }
  }
  &__menu {
    max-width: 127.1rem;
    // margin: 0 0.5rem;
    margin: 0 auto;
    flex-grow: 1;

    @include desktop {
      max-height: 0;
      overflow: hidden;
      position: absolute;
      order: 1;
      background: #46a6a4;
      left: 0;
      z-index: 10;
      padding: 0;
      min-width: 100%;
      flex-wrap: wrap;
      top: 6.1rem;
      .menu__item {
        position: relative;
        width: 100%;
      }
      .menu__link {
        flex-direction: row;
        padding: 2.2rem 2rem;
        &-icon {
          margin-right: 1.3rem;
        }
        &:before {
          display: none;
        }
        &:hover:before {
          display: none;
        }
      }
      .menu__item:before {
        right: 1.5rem;
      }
      .menu__link {
        padding: 1.2rem 1.5rem;
      }
    }
    @include mobile {
      top: 5.3rem;
    }
  }
  &.agency-top-navigation {
    .top-navigation__menu {
      max-width: 60rem;
    }
  }
  .menu__item.opened {
    .top-navigation__sub-menu {
      opacity: 1;
      visibility: visible;
      transform: translateY(0);
      transition: all 0.3s ease-in;
      @include desktop {
        max-height: 100%;
        transition: all 0.3s ease-in;
      }
    }
    &:before {
      transform: rotateZ(-90deg);
      opacity: 1;
    }
  }

  &__sub-menu {
    position: absolute;
    opacity: 0;
    background: #fff;
    border-radius: 12px;
    box-shadow: 0 10px 50px rgba(0, 0, 0, 0.25);
    transform: translateY(-10%);
    transition: all 0.1s ease-out;
    visibility: hidden;
    z-index: 1;
    .menu__link {
      margin: 0 20px;
      color: $clr-purple;
      opacity: 1;
      @include desktop {
        margin: 0 30px;
      }
      &.blocked {
        color: #a9a9a9;
        a {
          opacity: 1;
        }
      }
      .menu__link-icon {
        opacity: 0.5;
        svg {
          fill: $clr-purple;
        }
        &.icon-search svg {
          stroke: $clr-purple;
        }
      }
      &:hover {
        .menu__link-icon {
          opacity: 1;
        }
      }
    }
    .menu > .active-item .menu__link {
      color: $clr-green;
      .menu__link-icon {
        opacity: 1;
        svg {
          fill: $clr-green;
        }
        &.icon-search svg {
          stroke: $clr-green;
        }
        @include desktop {
          svg {
            fill: white;
          }
          &.icon-search svg {
            stroke: white;
          }
        }
      }
    }
    @include desktop {
      position: relative;
      max-height: 0;
      background: transparent;
      overflow: hidden;
      transform: translateY(0);
      border-radius: 0;
      box-shadow: none;
      .menu {
        flex-direction: column;
        > .active-item {
          opacity: 1;
          .menu__link {
            opacity: 1;
            color: #fff;
          }
        }
      }
      .menu__item {
        opacity: 1;
        &::before {
          content: none;
        }
        &.blocked {
          color: $clr-purple;
          a {
            opacity: 0.3;
          }
        }
      }
      .menu__link {
        color: #fff;
        .menu__link-icon {
          svg {
            fill: #fff;
          }
          &.icon-search svg {
            stroke: #fff;
          }
        }
      }
    }
  }
  &__hamburger {
    display: none;
    @include desktop {
      display: inline-flex;
      cursor: pointer;
      margin-right: auto;
    }
  }
  &__btn {
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    background-color: #fff;
    box-shadow: 0 0.4rem 0.4rem rgba(37, 77, 76, 0.2);
    border-radius: 1rem;
    min-height: 3.9rem;
    min-width: 3.9rem;
    position: relative;
    overflow: hidden;
    &:before {
      content: '';
      position: absolute;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%) scale(0);
      border-radius: 50%;
      height: 130%;
      width: 130%;
      background-color: #d4f9f8;
      z-index: 1;
    }
    &:active {
      &:before {
        transition: 0.3s;
        transform: translate(-50%, -50%) scale(1);
      }
    }
    .icon-plus {
      color: #46a6a4;
      z-index: 2;
    }
    @include mobile {
      min-height: 3rem;
      min-width: 3rem;
    }
  }

  .menu__item_charters {
    position: relative;
    .top-navigation__sub-menu {
      right: -3rem;
      &:before {
        left: 73%;
      }
      @include desktop {
        right: auto;
      }
    }
    &.active-item .top-navigation__sub-menu::before {
      left: 60%;
      @include desktop {
        right: auto;
      }
    }
  }

  .menu__item_search {
    position: relative;
    .top-navigation__sub-menu {
      right: 0;

      &:before {
        left: 73%;
      }
      @include desktop {
        right: auto;
      }
    }
    &.active-item .top-navigation__sub-menu::before {
      left: 53%;
      @include desktop {
        right: auto;
      }
    }
  }

  .menu__item_finance,
  .menu__item_maintenance {
    @include large {
      &.opened .top-navigation__sub-menu {
        left: 10%;
      }

      .top-navigation__sub-menu::before {
        left: 30%;
      }
    }

    @include desktop {
      &.opened .top-navigation__sub-menu {
        left: 0;
        transform: translateX(0);
      }
    }
  }

  .menu__item_maintenance {
    @include large {
      .top-navigation__sub-menu::before {
        left: 50%;
      }
    }
  }
}

.active-menu .top-navigation__menu {
  max-height: max-content;
}
