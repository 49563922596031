.filter-by-offer {
  @include d-align-items-center;

  &__container {
    @include d-align-items-center;
    margin-right: 1.6rem;
  }

  &__label {
    background-color: $green-grey;
    color: $light-color;
    margin-right: 1.2rem;
  }

  &__text {
    font-size: 2rem;
    font-weight: 500;
    line-height: 120%;
    white-space: nowrap;
    color: $clr-primary-and-accent;
  }

  &__create-btn {
    display: block;
    width: 100%;
    max-width: 44.1rem;
  }

  @include tablet {
    &__create-btn {
      max-width: 100%;
    }
  }

  @include mobile {
    display: block;

    &__container {
      @include d-align-items-center;
      margin: 0 0 1.6rem;
    }
  }
}
