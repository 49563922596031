@import 'variable';

.release-info {
  display: flex;
  flex-direction: column;
  height: 100%;
  overflow: hidden;

  &__header {
    padding: 2.4rem;
    .title {
      font-size: 2rem;
      font-weight: 500;
      color: $clr-primary-and-accent;
    }
  }

  &__content--wrapper {
    flex: 1 auto;
    overflow: auto;
    border: 1px solid $seaz-head-arrow;
    font-size: 1.6rem;
    line-height: 2.5rem;
    @include custom-scroll-bar;
  }

  &__content {
    padding: 2.4rem 1.6rem 1.6rem 1.6rem;

    h1,
    h2,
    h3,
    h4,
    h5,
    h6 {
      color: $clr-primary-and-accent;
      margin-top: 1.2rem;
    }

    h1 {
      font-size: 4rem;
      font-weight: 700;
    }

    h2 {
      font-size: 3rem;
      font-weight: 500;
    }

    h3 {
      font-size: 2.4rem;
      font-weight: 500;
    }

    h4 {
      font-size: 2rem;
      font-weight: 500;
    }

    h5 {
      font-size: 1.6rem;
      font-weight: 500;
    }

    h6 {
      font-size: 1.4rem;
      font-weight: 500;
    }

    p {
      font-size: 1.6rem;
      line-height: 2.6rem;
      margin-bottom: 1.2rem;
    }

    a {
      font-size: 1.6rem;
      line-height: 2.6rem;
      color: $clr-blue;
      &:active,
      &:visited {
        color: $clr-blue;
      }
    }

    ol,
    ul {
      padding-left: 3rem;
      font-size: 1.6rem;
    }

    ol {
      list-style-type: decimal;
    }

    ul {
      list-style-type: disc;
    }

    b {
      font-weight: 500;
    }

    i {
      font-style: italic;
    }
  }

  &__footer {
    padding: 1.6rem 2.4rem;

    &--controls {
      display: flex;
      width: 100%;
      justify-content: flex-end;
      .seaz__btn_rounded {
        border-radius: 0.4rem;
        padding: 1rem 2rem;
      }
    }
  }
}
