app-job-offers-wrapper {
  .boat-home__nav {
    padding-bottom: 3rem;
  }
}

.job-offer {
  padding-bottom: 5rem;

  &__header {
    display: flex;
    justify-content: space-between;
    padding: 3rem 0;
    width: 126.4rem;
    margin: 0 auto;
    align-items: center;
    @media screen and (max-width: 1300px) {
      width: 100rem;
    }
    @include laptop {
      width: 100%;
      padding: 2.4rem 1.6rem;
    }
    @media screen and (max-width: 600px) {
      flex-direction: column;
    }
    @include mobile {
      padding-bottom: 0;
    }
    &_input {
      width: 25.7rem;
      position: relative;
      @include laptop {
        width: 50%;
      }
      @media screen and (max-width: 600px) {
        width: 100%;
        margin-bottom: 1.6rem;
      }

      .form-field--content.form-field--content-outline {
        border-color: $seaz-head-arrow;
        height: 4rem;

        input {
          &::placeholder {
            color: $seaz-document-count;
          }
        }
        .form-field--content-suffix {
          background-color: $clr-primary-and-accent;
          max-width: 4rem;
          width: 4rem;
          height: 4rem;
          display: flex;
          justify-content: center;
          align-items: center;
          padding: 0;
          position: absolute;
          top: -0.1rem;
          right: 0;
        }
        .form-field--content-wrapper {
          padding-right: 4rem;
        }
      }
    }
    &_btns {
      display: flex;
      @include laptop {
        width: 49%;
      }
      @media screen and (max-width: 600px) {
        width: 100%;
        flex-direction: column;
      }
      button {
        display: flex;
        align-items: center;
        padding: 0.6rem 3.2rem 0.8rem;
        @include laptop {
          width: 49%;
          justify-content: center;
          padding: 0.8rem 0 0.6rem;
        }
        @media screen and (max-width: 600px) {
          width: 100%;
          margin-bottom: 1.6rem;
          height: 4rem;
        }
        &:not(:last-of-type) {
          margin-right: 1.2rem;
        }
        app-seazone-icon {
          margin-right: 1.2rem;
        }
      }
    }
  }
  &__title {
    padding-bottom: 1rem;
    color: #000;
    font-weight: 500;
    font-size: 2.4rem;
    width: 126.4rem;
    margin: 0 auto;
    line-height: 2.8rem;
  }
  &__card {
    width: 126.4rem;
    margin: 0 auto;
    display: flex;
    padding: 3rem 0;
    justify-content: space-between;
    @media screen and (max-width: 1300px) {
      width: 100rem;
    }
    @include laptop {
      width: 100%;
      padding: 2.4rem;
      flex-direction: column;
      position: relative;
    }
    @include mobile {
      padding: 1.6rem 1.2rem;
    }
    &--left {
      display: flex;
      width: 72%;
      @include laptop {
        width: 100%;
        flex-direction: column;
      }
    }
    &_name {
      margin-right: 4%;
      flex-shrink: 0;
      width: 30%;
      @media screen and (max-width: 1300px) {
        margin-right: 2%;
      }
      @include laptop {
        margin-right: 0;
        margin-bottom: 1.5rem;
        width: 100%;
      }
    }
    &_btns {
      display: flex;
      flex-direction: column;
      width: 20%;
      @include laptop {
        width: 100%;
        flex-direction: row;
        position: relative;
        flex-wrap: wrap;
        justify-content: space-between;
      }
      .job-offer__card_jump {
        display: flex;
        align-items: center;
        justify-content: center;
        background-color: $seaz-edit-hover;
        color: $clr-white;
        text-transform: uppercase;
        padding: 1rem 1.2rem;
        app-seazone-icon {
          margin-right: 1rem;
          width: 1.7rem;
          height: 1.7rem;
        }
        span {
          height: 14px;
        }
      }
    }
    &_head {
      display: flex;
      align-items: center;
      padding-bottom: 1rem;
      &_wrapper {
        display: flex;
        align-items: center;
        @media screen and (max-width: 1300px) {
          margin-right: 0.8rem;
        }
      }
      &_position {
        width: 32px;
        height: 32px;
      }
      @media screen and (max-width: 1300px) {
        flex-direction: column;
        align-items: flex-start;
      }
      @include laptop {
        flex-direction: row;
        align-items: center;
      }
      @include mobile {
        width: 78%;
      }
      &_title {
        margin-right: 0.8rem;
        color: $clr-primary-and-accent;
        @media screen and (max-width: 1300px) {
          margin-bottom: 1rem;
        }
        @include laptop {
          margin-bottom: 0;
        }
      }
      &_status {
        color: $clr-white;
        text-transform: uppercase;
        letter-spacing: 0.1em;
        font-size: 1.4rem;
        font-weight: 500;
        padding: 0.6rem 1.2rem 0.9rem;
        margin-left: 0.8rem;
        background-color: $seaz-success;

        @media screen and (max-width: 1300px) {
          margin-left: 0;
        }
      }
      &_offer {
        color: $seaz-text;
        padding-bottom: 3.8rem;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;

        @include laptop {
          padding-bottom: 1.2rem;
        }
      }
    }
    &_publish {
      display: flex;
      align-items: center;
      app-toggle-checkbox {
        position: relative;
        top: 3px;
      }
      &_label {
        text-transform: capitalize;
        color: $seaz-mulberry;
        margin-right: 0.8rem;
      }
      .form__toggle-label:before {
        width: 2.9rem;
        height: 1.6rem;
        border-radius: 18px;
      }
      .form__toggle-input:checked + label:before {
        background-color: $seaz-mulberry;
      }
      .form__toggle-label:after {
        top: 0;
        width: 1.6rem;
        height: 1.6rem;
        border: 2px solid $seaz-mulberry;
        background-color: transparent;
        left: 0;
        box-shadow: none;
      }
      .form__toggle-input:checked + label:after {
        background-color: $clr-white;
        border: 2px solid $seaz-mulberry;
        width: 1.6rem;
        height: 1.6rem;
      }
      .form__toggle-label:before {
        background-color: transparent;
        border: 2px solid $seaz-mulberry;
      }
    }
    &_id {
      display: flex;
      align-items: center;
      margin-top: 2.7rem;
      &_title {
        text-transform: uppercase;
        margin-right: 0.4rem;
        letter-spacing: 0.5px;
        color: $seaz-document-count;
        font-size: 1rem;
      }
      &_number {
        color: $seaz-document-count;
        font-weight: 500;
        &_box {
          display: flex;
          align-items: center;
          background-color: $seaz-bg;
          padding: 0.3rem 0.4rem;
          border-radius: 4px;
          cursor: pointer;
        }
        &_icon {
          margin-left: 0.5rem;
        }
      }
    }
    &_share {
      width: 2.8rem;
      height: 2.8rem;
      margin-right: 10px;
      display: flex;
      justify-content: center;
      align-items: center;
      border-radius: 50%;
      cursor: pointer;
      background-color: #4cceff;
      &_icon {
        width: 14px;
      }
    }
    &_info {
      width: 100%;
      &--top,
      &--bottom {
        display: flex;
      }
      &--top {
        padding-bottom: 4.8rem;
        @include laptop {
          justify-content: space-between;
        }
        .job-offer__card_info_col {
          width: 24.7%;
          @include laptop {
            width: 50%;
          }
        }
      }
      &--bottom {
        @include laptop {
          flex-wrap: wrap;
          justify-content: space-between;
        }
        .job-offer__card_info_col {
          margin-right: 1.6rem;
          @include laptop {
            order: 1;
            width: 49%;
            margin-right: 0;
            margin-bottom: 1.4rem;
          }
        }
        .job-offer__card_info--contract {
          width: 25%;
          @include laptop {
            width: 49%;
          }
        }
      }
      &_col {
        display: flex;
        flex-direction: column;
      }
      &_label {
        font-size: 1rem;
        text-transform: uppercase;
        padding-bottom: 0.5rem;
        color: $seaz-document-count;
        letter-spacing: 0.5px;
      }
      &_text {
        color: $clr-primary-and-accent;
        &.new-match {
          color: $seaz-success;
        }
      }
      &_actions {
        color: #3aae27;
        margin-left: 5px;
      }
    }
    &_controls {
      display: flex;
      align-items: flex-start;
      margin-right: 2%;
      @include laptop {
        position: absolute;
        top: 2.8rem;
        right: 2rem;
      }
      button:not(:last-of-type) {
        margin-right: 2rem;
      }
    }
    &_btns {
      button {
        padding: 0.8rem 1.2rem 1rem;
        margin-bottom: 1rem;
        @include laptop {
          width: 49%;
        }
      }
    }
    &_update {
      text-align: center;
      font-size: 1rem;
      color: #4f4f4f;
      letter-spacing: 0.5px;
      @include laptop {
        position: absolute;
        bottom: -1.1rem;
        white-space: nowrap;
      }
    }
    &_date {
      &_box {
        display: flex;
        width: 45%;
        justify-content: space-between;
        margin-right: 2rem;
        @include laptop {
          width: 100%;
          order: 2;
          margin-right: 0;
          justify-content: space-between;
          .job-offer__card_info_col {
            order: initial;
          }
          app-seazone-icon {
            @include laptop {
              display: none;
            }
          }
        }
      }
    }
  }
}

// seaz job offer detail page

.seaz-offer {
  display: flex;
  flex-direction: column;
  width: 126.4rem;
  margin: 0 auto;
  margin-top: 6rem;
  @media screen and (max-width: 1300px) {
    width: 100rem;
  }
  @include laptop {
    width: 96%;
  }
  &__header {
    display: flex;
    justify-content: center;
    width: 126.4rem;
    margin: 0 auto;
    position: relative;
    padding: 6.3rem 0 3.3rem;
    border-bottom: 1px solid $seaz-border;
    @media screen and (max-width: 1300px) {
      width: 100rem;
    }
    @include laptop {
      width: 96%;
    }
    .seaz__return_btn {
      position: absolute;
      left: 0;
      top: 5.6rem;
    }
    .seaz-crew__contract_head_title {
      img {
        width: 4.4rem;
        height: 4.4rem;
        border-radius: 50%;
        object-fit: cover;
        margin-top: -8px;
      }
    }
  }

  &__filters {
    margin-top: 4rem;

    app-seazone-input {
      width: 45% !important;
    }

    .seaz-search-crew-filters__main-filters,
    .seaz-search-crew-filters__location-filters {
      width: 50%;
    }

    .chips-distance-desktop-row {
      .seaz-search-chips-filter__distance {
        flex-shrink: 0;
      }
    }

    .chips-distance {
      flex-shrink: 0;

      .seaz-search-chips-filter__chips--item {
        margin: 0 0.4rem 0.8rem 0;
      }

      .seaz-search-chips-filter__label {
        margin: 0 1rem 0.8rem 0;
      }

      .seaz-search-chips-filter__chips {
        flex-wrap: nowrap;
      }
    }

    @media screen and (max-width: 1300px) {
      &.main-container {
        max-width: 100rem;
      }
    }

    @include laptop {
      &.main-container {
        width: 96%;
      }

      .seaz-search-crew-filters__main-filters,
      .seaz-search-crew-filters__location-filters {
        width: 100%;
      }
    }
  }

  &__main-details {
    display: flex;
    justify-content: space-between;
    margin-left: 10.6rem;
    @include laptop {
      margin-left: 0;
    }
    @include tablet {
      flex-direction: column;
    }
  }
  &__details {
    width: 53%;
    margin-right: 1rem;

    @include tablet {
      width: 100%;
      margin-right: 0;
    }
    &_title {
      padding-bottom: 3rem;
      color: $clr-primary-and-accent;
      padding-top: 3rem;
    }
    &_row {
      display: flex;
      padding-bottom: 3rem;
    }
    &_label {
      width: 21.5rem;
      @extend .seaz-text-medium-light;
      color: $seaz-text;
      padding-right: 1rem;
      flex-shrink: 0;
      line-height: 2.6rem;
      @include laptop {
        width: 18.9rem;
      }
    }
    &_id {
      display: flex;
      align-items: center;
      cursor: pointer;
      &_icon {
        margin-left: 0.9rem;
      }
    }
    &_text {
      @extend .seaz-text-medium;
      color: $seaz-text;
      line-height: 2.6rem;
      &.seaz-offer__details_text--years {
        display: flex;
        align-items: center;
        flex-wrap: wrap;
        span {
          margin-right: 1.2rem;
        }
      }
      &_list {
        display: flex;
        flex-wrap: wrap;
      }
    }
    &_text.list {
      margin-right: 5px;
    }
    &_header {
      display: flex;
      justify-content: space-between;
      align-items: flex-end;
    }
  }
  &__edit-btn {
    background-color: #e0e0e0;
    width: 5rem;
    height: 5rem;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 3rem;
  }
  &__boat {
    width: 40rem;
    padding: 3rem;
    background-color: $clr-white;
    height: fit-content;
    &_wrapper {
      .seaz-offer__boat:not(:last-child) {
        margin-bottom: 2.5rem;
      }
    }
    @include laptop {
      width: 36rem;
    }

    @include tablet {
      width: 100%;
      padding: 2.4rem;
    }
    &_title {
      padding-bottom: 1.5rem;
      color: $clr-primary-and-accent;
    }
    &_col {
      display: flex;
      flex-direction: column;
      padding-bottom: 1.3rem;
    }
    &_label {
      @extend .seaz-text-medium;
      color: $seaz-document-count;
      line-height: 2.6rem;
    }
    &_text {
      @extend .seaz-text-medium;
      color: $seaz-text;
      line-height: 2.6rem;
    }
    &_row {
      display: flex;
      align-items: center;
      margin-bottom: 1.5rem;
      &_text {
        text-transform: uppercase;
        color: $clr-primary-and-accent;
      }
    }
    &_img,
    &_company_img {
      width: 6rem;
      height: 6rem;
      margin-right: 1.6rem;
      object-fit: cover;
      border-radius: 8px;
    }
    &_company {
      margin-right: 3rem;
      &_text {
        font-size: 2.4rem;
        font-weight: 500;
        text-transform: capitalize;
        color: #351f73;
      }
    }
    &_flag {
      display: flex;
      align-items: baseline;
      &_icon {
        width: 2.2rem;
        height: 1.4rem;
        object-fit: cover;
        margin-right: 0.8rem;
      }
    }
  }
  &__separator {
    width: 100%;
    height: 0.1rem;
    background-color: $clr-primary-and-accent;
    position: relative;
    @media screen and (max-width: 600px) {
      width: 96%;
      margin: 0 auto;
      margin-top: 3rem;
    }
    &::before {
      content: '';
      height: 2.5rem;
      width: 2.5rem;
      position: absolute;
      right: 0;
      top: -12px;
      left: 0;
      background: $seaz-logo-border;
      margin: auto;
      transform: rotate(45deg);
      border: 1px solid $clr-primary-and-accent;
      border-top: none;
      border-left: none;

      @include mobile {
        background: $light-color;
      }
    }
  }
  &__users {
    position: relative;
    width: 126.4rem;
    margin: 0 auto;
    padding-bottom: 5rem;
    @media screen and (max-width: 1300px) {
      width: 100rem;
      display: flex;
      flex-direction: column;
    }
    @include laptop {
      width: 96%;
    }
    .seaz-job__search_applied_nav {
      @include laptop {
        order: 1;
      }
    }
    app-offered-users-list {
      @include laptop {
        order: 3;
      }
    }
    .seaz-search__relevant_clear {
      top: 2.8rem;
      @include laptop {
        position: relative;
        top: inherit;
        right: inherit;
        justify-content: flex-start;
        order: 2;
        padding-bottom: 2rem;
      }
      .seaz-search__relevant_clear_text {
        text-transform: inherit;
        font-size: 1.6rem;
        font-weight: 500;
        color: $clr-primary-and-accent;
        width: 100%;
        @include laptop {
          text-align: left;
        }
      }
    }
    &_nav {
      width: 126.4rem;
      margin: 0 auto;
      margin-top: 7rem;
      display: flex;
      align-items: center;
      justify-content: space-between;
      @media screen and (max-width: 1300px) {
        width: 100rem;
      }
      @include laptop {
        width: 96%;
        .seaz-profile__navigation_list {
          margin: 0;
        }
      }
      @media screen and (max-width: 820px) {
        flex-direction: column;
        align-items: flex-start;
        .seaz-offer__users_nav--right {
          margin-bottom: 1rem;
        }
      }
      .seaz-profile__navigation {
        padding-bottom: 0;
      }
      .seaz-offer__users_nav_go {
        display: flex;
        @include mobile {
          width: 100%;
        }
      }
      &--right {
        display: flex;
        align-items: center;
        @include mobile {
          flex-direction: column;
          width: 100%;
          align-items: flex-start;
          app-scrollable-navigation-links {
            width: 100%;
            .seaz-profile__navigation_list {
              margin-right: 0;
              width: 100%;
              .seaz-profile__navigation_item {
                width: 50%;
                justify-content: center;
              }
            }
          }
        }
        .seaz-profile__navigation {
          padding-top: 0;
        }
        .seaz-profile__navigation_list {
          padding-right: 0;
          @include laptop {
            margin-right: 2.4rem;
          }
        }
        .seaz-filters__row_select {
          margin-right: 0;
          width: 19rem;
          @media screen and (max-width: 1150px) {
            display: flex;
          }
          @include mobile {
            width: 100%;
          }
          .form-field--content-outline {
            height: 5rem;
            padding: 1rem 1.2rem 0.8rem 1.2rem;
          }
        }
      }

      .sharing__offers-btn {
        margin-right: 1.8rem;
        display: flex;
        align-items: center;
        padding: 1.5rem 3.2rem 1.5rem;
        background-color: #00b9ff;
        box-sizing: border-box;
        color: white;
        text-transform: uppercase;
      }
    }
    .seaz-search__card {
      margin-right: 0;
      .seaz-search__card_head_badges {
        @media screen and (max-width: 600px) {
          flex-direction: column;
          justify-content: center;
          align-items: center;
        }
      }
    }
    .seaz-search__card_controls {
      display: flex;
    }
    app-offered-status div {
      font-weight: 500;
      font-size: 1.4rem;
      letter-spacing: 0.1em;
      padding: 0.8rem 1.2rem;
      text-transform: uppercase;
      margin-right: 1rem;
      color: $clr-white;
      width: fit-content;
      @include mobile {
        font-size: 1rem;
      }
    }
    .seaz-search__card_name {
      margin-bottom: 1.6rem;
      @media screen and (max-width: 600px) {
        margin-right: 0;
        width: 100%;
        text-align: center;
      }
    }

    .seaz-search__card_btns {
      width: 25.4rem;
      @media screen and (max-width: 1200px) {
        width: 100%;
      }
    }
    .seaz-search__card_rating {
      margin-top: 1rem;
      @media screen and (max-width: 1500px) {
        margin-top: 0;
      }
      .job-offer__card_id {
        margin-top: 1rem;
      }
    }
    .seaz-search__card_info {
      @media screen and (max-width: 600px) {
        padding-bottom: 16.8rem;
      }
    }
    .seaz-search__card_head .header__controls {
      @media screen and (max-width: 600px) {
        bottom: 9.4rem;
      }
    }
  }

  &__detail {
    &_header {
      display: flex;
      flex-direction: column;
      @include mobile {
        padding: 6.3rem 1.6rem 3.3rem;
        width: 100%;
      }
      .job-offer__card_share {
        margin-right: 0;
        margin-left: 3.2rem;
        width: 5rem;
        height: 5rem;
        background-color: $seaz-edit-hover;
        .job-offer__card_share_icon {
          width: 20px;
        }
      }
      .seaz-offer__detail_header_toggle_row {
        display: flex;
        align-items: center;
      }
      &_wrap {
        display: flex;
        flex-direction: column;
        align-items: center;
      }
      &_dates {
        margin-top: 1rem;
        text-transform: uppercase;
      }
      &_top {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        width: 100%;
        margin: 0 auto;
        position: relative;
      }
      &_date {
        padding-top: 0.8rem;
        text-transform: uppercase;
        letter-spacing: 0.5px;
        color: $seaz-about-text;
      }
      &_toggle {
        &_box {
          position: absolute;
          right: 0;
          display: flex;
          align-items: center;
          @include laptop {
            position: relative;
            margin-top: 2.4rem;
          }
          @media screen and (max-width: 700px) {
            margin-top: 1.2rem;
          }
        }
        &_text {
          text-transform: capitalize;
          color: $seaz-mulberry;
          margin-right: 0.8rem;
        }
      }
      &.seaz-offer__header .seaz__return_btn {
        top: -0.8rem;
        @media screen and (max-width: 700px) {
          top: 0;
          width: 3.2rem;
          height: 3.2rem;
          svg {
            width: 9px;
          }
        }
      }
      &_btns {
        display: flex;
        align-items: center;
        margin-top: 5rem;
        justify-content: space-between;
        @include laptop {
          margin-top: 3rem;
        }
        @media screen and (max-width: 700px) {
          flex-direction: column;
        }
        button {
          width: 33%;
          @media screen and (max-width: 700px) {
            width: 100%;
            margin-bottom: 0.8rem;
          }
        }
        .seaz__btn_jump {
          text-transform: uppercase;
          color: $clr-white;
          padding: 1.4rem 3.2rem;
          font-size: 1.4rem;
          letter-spacing: 0.1em;
          border: 1px solid transparent;
          background-color: $seaz-edit-hover;
          display: flex;
          align-items: center;
          justify-content: center;
          @include mobile {
            font-size: 1.2rem;
            padding: 1rem 3.2rem;
          }
          &_icon {
            background-image: url('/assets/img/arrow-top.svg');
            width: 1.4rem;
            height: 1.4rem;
            display: flex;
            background-position: center;
            background-repeat: no-repeat;
            background-size: cover;
            margin-right: 1.7rem;
          }
        }
      }
      .seaz-crew__contract_head_title {
        @include laptop {
        }
        @include mobile {
          padding: 0 3.2rem;
        }
      }
      &_status {
        padding-top: 3.4rem;
        display: flex;
        flex-direction: column;
        justify-content: center;
        &_row {
          display: flex;
          align-items: center;
          justify-content: center;
          margin-bottom: 0.8rem;
        }
        &_badge {
          padding: 0.5rem 0.8rem;
          background-color: $seaz-head-arrow;
          color: $seaz-about-text;
          text-transform: uppercase;
          font-weight: 500;
          letter-spacing: 0.1em;
          font-size: 1.2rem;
          margin-right: 0.8rem;
        }
        &_date {
          font-size: 1.2rem;
          letter-spacing: 0.01em;
          color: $seaz-about-text;
        }
        &_text {
          padding: 1.2rem;
          color: $seaz-about-text;
          text-align: center;
          &.success {
            background-color: #d8efd4;
            color: #3aae27;
          }
        }
      }

      .couple {
        margin: 1.2rem 0;
      }
    }
  }
  &__add-crew {
    margin-top: 3rem;
    .setting-permission__header {
      margin-top: 0;
    }
  }
}

.seaz-modal {
  &__offers_history {
    width: 87rem;
    @include laptop {
      width: 70rem;
    }
    @include tablet {
      width: 100%;
    }
    .modal__wrap {
      padding: 4.6rem;
      @include tablet {
        padding: 2.6rem;
      }
    }
  }
  &__history {
    padding-bottom: 4.8rem;
    &_title {
      color: $clr-primary-and-accent;
    }
    &_card {
      display: flex;
      align-items: flex-start;
      padding: 2.9rem 3.2rem;
      @include mobile {
        align-items: center;
        padding: 1.9rem 2rem;
      }
      &_list {
        padding-top: 3rem;
        app-job-offers-history-item:nth-child(2n - 1) {
          .seaz-modal__history_card {
            background-color: $seaz-bg;
          }
        }
      }
      &_img {
        margin-right: 3.2rem;
        @include mobile {
          margin-right: 2rem;
        }
        .incoming {
          transform: rotate(180deg);
          padding-bottom: 1rem;
        }
      }
      &_content {
        display: flex;
        flex-direction: column;
        width: 100%;

        .job-offer__card_id {
          margin-top: 0.7rem;
        }
      }
    }
    &_position {
      display: flex;
      align-items: center;
      margin-bottom: 0.4rem;
      &_text {
        font-size: 2rem;
        margin-right: 0.8rem;
        font-weight: 500;
        color: $clr-primary-and-accent;
      }
      &_icon {
        width: 3.2rem;
        height: 3.2rem;
      }
    }
    &_name {
      margin-bottom: 1.2rem;
      line-height: 2.6rem;
      display: block;
    }
    &_info {
      display: flex;
      justify-content: space-between;
      align-items: flex-end;
      flex-wrap: wrap;
      @include laptop {
        justify-content: flex-start;
      }
      &_col {
        display: flex;
        flex-direction: column;
      }
      &_row {
        display: flex;
        align-items: flex-end;
        @include laptop {
          order: 1;
          flex-wrap: wrap;
          width: 100%;
          margin: 1rem 0;
          .seaz-modal__history_info_arrow {
            display: none;
          }
        }
      }
      &_item {
        margin: 0 1rem 1rem 0;
        @include laptop {
          margin: 0;
          width: 49%;
        }
      }
      &_label {
        padding-bottom: 0.4rem;
        font-size: 1rem;
        text-transform: uppercase;
        letter-spacing: 0.5px;
        color: $seaz-document-count;
      }
      &_title {
        color: $clr-primary-and-accent;
        font-size: 1.6rem;
        font-weight: 500;
      }
      &_arrow {
        margin: 0 3.5rem 2rem;
      }
      &_date {
        color: #4f4f4f;
        font-size: 1.2rem;
        margin-top: 1.5rem;
      }
    }
  }
  &__offer {
    &_create {
      &_content {
        &--salary {
          display: flex;
          align-items: center;

          @media screen and (max-width: 850px) {
            flex-direction: column;
            align-items: flex-start;
          }
          @include mobile {
            align-items: flex-start;
          }
          .seaz-crew__edit_label {
            max-width: 17rem;
            margin-right: 1.5rem;
            width: 17rem;
            flex-shrink: 0;
            @include laptop {
              width: 12rem;
              max-width: 12rem;
              word-break: break-all;
            }
            @media screen and (max-width: 850px) {
              width: 100%;
              max-width: 100%;
              padding-bottom: 1rem;
            }
            @include mobile {
              width: 100%;
              max-width: 100%;
              margin-left: 0;
              padding-bottom: 1rem;
            }
          }
          .seaz-modal__offer_create--salary app-seazone-input .form-field--wrapper__row .form-field--label {
            display: none;
          }
        }
      }
      &--salary {
        display: flex;
        align-items: center;
        @include laptop {
          width: 34.5rem;
        }
        @include mobile {
          width: 100%;
        }
        app-seazone-input {
          width: 50%;
          .form-field--label {
            display: none;
          }
        }
      }
      &_text {
        &--salary {
          padding-right: 1.2rem;
          font-size: 1.4rem;
          color: $seaz-document-count;
          &:not(:first-of-type) {
            padding-left: 1.2rem;
          }
        }
      }
    }
  }
}

app-sharing-job-offer {
  background-color: $seaz-bg;
  display: block;
  .seaz-offer__details_label {
    padding-right: 3rem;
  }
  .seaz-offer__main-details {
    padding-bottom: 5rem;
  }
  .seaz-offer__details_title {
    padding-top: 5rem;
  }
}

.job-offer__confirmation-form {
  .form__radio-container {
    display: flex;
    flex-direction: column;
  }
  .form__radio:not(:last-child) {
    margin-bottom: 26px;
  }
  .form__radio-label-text {
    font-size: 1.6rem;
    color: #010202;
    cursor: pointer;
  }
}

.seaz-search {
  .job-offer__navigation .seaz-profile__navigation_wrapper .seaz-profile__navigation_list {
    width: 60%;
    margin: auto;
  }

  .seaz-profile__navigation_item,
  .seaz-profile__navigation_link {
    width: 100%;
  }

  .seaz-profile__navigation_link {
    justify-content: center;
  }
}

.seaz-offer__details_toggle {
  display: none;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 2rem;
  &_edit {
    color: $seaz-mulberry;
    border: 1px solid $seaz-mulberry;
    padding: 0.5rem 1.5rem;
    text-transform: uppercase;
    font-size: 1.2rem;
    letter-spacing: 0.1em;
  }
  .toggle-button {
    transform: rotateX(180deg);
    &.active {
      transform: rotateX(0deg);
    }
  }
}

.seaz-offer.seaz-offer__details_toggle_open {
  .seaz-offer__details_toggle {
    display: flex;
    @include mobile {
      flex-direction: row;
      align-items: center;
    }
  }
  .seaz-offer__main-details {
    display: none;
    &.active {
      display: flex;
    }
  }
  .seaz-dashboard__head_title_box {
    cursor: pointer;
  }
}

.seaz-offer__users {
  @media screen and (max-width: 600px) {
    .seaz-search__card_head_status {
      text-align: center;
    }

    .offerred-status {
      margin: 0 auto;
    }
  }
}

.job-offer-form {
  @media screen and (max-width: 850px) {
    .seaz-modal__offer_radio app-seazone-radio .form__block .form__block_label {
      max-width: 12rem;
      width: 12rem;
    }

    .seaz-modal__offer_create_content--salary {
      flex-direction: row;
      align-items: center;
    }

    .seaz-modal__offer_create_content--salary .seaz-crew__edit_label {
      width: 12rem;
      max-width: 12rem;
      margin-right: 1.5rem;
    }

    .seaz-modal__offer_create--salary {
      width: 100%;
    }
  }

  @include mobile {
    .seaz-modal__offer_create_content--salary {
      flex-direction: column;
      align-items: flex-start;
    }
  }
}

.seaz-offer-info {
  .seaz-profile__navigation_list {
    margin-left: 0;
  }

  .seaz-search__relevant_clear_text {
    margin-right: 0;
  }

  .seaz-job__search_applied_nav_link {
    &:first-of-type {
      padding-left: 0;
    }
  }

  @media screen and (max-width: 820px) {
    .seaz-offer__users_nav--right {
      width: 100%;
      justify-content: space-between;
    }

    .seaz-offer__users_nav_go {
      width: 100%;
    }

    .sharing__offers-btn,
    .go__search {
      width: 50%;
      justify-content: center;
    }
  }

  @media screen and (max-width: 600px) {
    .seaz-filters__row_select {
      margin-top: 0;
    }

    .seaz-search__relevant_clear {
      margin: 2rem 0 0;
    }

    .seaz-search__relevant_clear_text {
      text-align: center !important;
    }
  }

  @include mobile {
    .seaz-offer__users_nav {
      margin-top: 3rem;
    }

    .seaz-filters__row_select {
      margin-top: 1.2rem;
    }

    .seaz-offer__users_nav_go {
      flex-direction: column;
    }

    .sharing__offers-btn,
    .go__search {
      width: 100%;
    }

    .go__search {
      margin-top: 1rem;
    }

    .seaz-job__search_applied_nav_link {
      &:first-of-type {
        padding-left: 2.4rem;
      }
    }
  }
}

app-job-search-wrapper {
  app-large-navigation-links {
    display: block;
    padding: 0 4.8rem;
  }

  app-job-search-list {
    width: 100%;
  }
}

.job-offer {
  padding-bottom: 5rem;

  &__main_navigation {
    display: flex;
    justify-content: center;
    width: 100%;
    border-bottom: 1px solid $seaz-border;
    padding: 3.5rem 0 2rem;

    &_item {
      text-transform: uppercase;
      color: $clr-primary-and-accent;
      opacity: 0.6;
      display: flex;

      &.blocked {
        cursor: default;
        color: #bdbdbd;
      }

      @media screen and (max-width: 600px) {
        font-size: 2.5rem;
      }
      @include mobile {
        text-align: center;
        line-height: 27px;
      }

      &:not(:last-of-type) {
        &::after {
          content: '';
          display: block;
          width: 1px;
          height: 100%;
          background-color: #8476aa;
          margin: 0 4rem;
          @media screen and (max-width: 600px) {
            margin: 0 2rem;
          }
        }
      }

      &.active-item {
        text-decoration: underline;
        opacity: 1;
      }
    }

    .seaz-search__icon {
      margin-left: 10px;
      position: relative;
      top: -6px;
    }
  }
}

.job-search {
  .seaz-profile__navigation_list {
    margin: 0 2.4rem 0 0;
  }

  .toggle-checkbox {
    @include d-align-items-center;
    margin-right: 1.6rem;

    .seazone__toggle {
      order: -1;
      margin-right: 0.8rem;
    }
  }

  .seaz-search-crew-filters__toggle-btn {
    margin: 2rem 0 0 0;
  }

  @include tablet {
    .seaz-search-crew-filters__title-box .seaz-search-crew-filters__search-result-label {
      display: none;
    }
  }
}

.job-search-card {
  .avatar-box {
    position: relative;
  }

  .seaz-search__card_name {
    font-size: 2.4rem;
    font-weight: 500;
  }

  .seaz-search__card_photo_status {
    position: absolute;
    top: -6px;
    left: -5px;
  }

  .search-card__info-person .job-offer-id-box {
    margin-top: 3.5rem;
  }

  .offerred-status {
    font-size: 1.2rem;
    font-weight: 500;
    line-height: 1.6rem;
    text-transform: uppercase;
    letter-spacing: 0.1em;
    white-space: nowrap;
    padding: 0.4rem 0.8rem;
    border-radius: 2.4rem;
  }

  .search-card__about-person {
    @include d-flex-space-between;
    align-items: flex-start;
  }

  .about-person-services-info {
    @include d-align-items-center;
  }

  .update-label {
    font-size: 1.2rem;
    font-weight: 400;
    color: $clr-light-grey;
    margin: 0 1.2rem 0 1rem;
  }

  .seaz-search__card_name {
    margin-right: 1.2rem;
  }

  .seaz-search__card_info_head_row {
    padding: 0 2rem 0 0;
  }

  .jo-btn {
    &:not(:last-of-type) {
      margin-right: 1.2rem;
    }
  }

  @include tablet {
    .offerred-status {
      font-size: 0.9rem;
      line-height: 1.2rem;
      padding: 0.2rem 0.4rem;
    }

    .seaz-company__offer_logo {
      width: 7.1rem;
      height: 3.2rem;

      img {
        width: 7.1rem;
        height: 3.2rem;
      }

      .seaz-company__offer_logo_agency {
        font-size: 0.9rem;
      }
    }

    .search-card__person {
      grid-gap: 1rem 1.6rem !important;
    }

    .search-card__info-person .job-offer-id-box {
      margin-top: 3rem;
    }

    .search-card__about-person,
    .about-person-services-info {
      flex-direction: column;
    }

    .about-person-services-info {
      align-items: flex-start;
      order: -1;
      margin-bottom: 1.2rem;
    }

    .update-label {
      margin: 0 0 0.4rem;
    }

    .jo-btn {
      &:not(:last-of-type) {
        margin: 0 0 1.2rem 0;
      }
    }

    .search-card__footer {
      flex-wrap: wrap !important;
    }

    .search-card__footer .crew-search-card__footer-btn-group:last-of-type {
      width: 100%;
      margin-top: 1.2rem;

      .jo-btn {
        width: 100%;
      }
    }
  }

  @include mobile {
    .job-search-head-row {
      padding: 0;
    }
  }
}

.job-offers-search-nav-box {
  @include d-flex-space-between;
  align-items: flex-start;

  &.main-container {
    margin: 3.5rem auto;
  }

  .seaz-profile__navigation_list {
    margin: 0 2.4rem 0 0;
  }

  .seaz-search__relevant_clear {
    position: static;
  }

  @media screen and (max-width: 1300px) {
    padding: 0 1.6rem;
  }

  @include tablet {
    flex-direction: column;
    align-items: center;

    .seaz-search__relevant_clear {
      margin-bottom: 0;
    }
  }
}

app-job-search-received,
app-job-search-applied {
  @include tablet {
    .main-container.job-offers-search-nav-box {
      margin: 2rem auto 0;
    }
  }
}
