.table {
  width: 100%;

  th,
  td {
    vertical-align: middle;
  }

  &__col {
    vertical-align: middle;
    padding: 0.9rem;

    &-btn {
      cursor: pointer;
      min-height: 2.4rem;
      min-width: 2.4rem;
      font-size: 2.1rem;

      i {
        color: #4d4e67;
      }

      .icon-down-arrow-sm {
        font-size: 1rem;
      }

      &-sort {
        position: relative;
        cursor: pointer;
        color: rgba(53, 31, 111, 0.5);

        .sort--container {
          padding-right: 0.5rem;

          &__icons {
            position: absolute;
            right: 0;
          }

          &.sort-down app-seazone-icon {
            transform: rotate(-180deg);
          }

          &.sort-up,
          &.sort-down {
            app-seazone-icon {
              opacity: 1 !important;
            }
          }

          app-seazone-icon {
            opacity: 0;

            &:not(:first-child) {
              display: none;
            }

            svg {
              fill: rgba($clr-purple, 0.5);
              height: 1rem;
              width: 1rem;
            }
          }
        }
      }
    }

    @include mobile {
      padding: 0.5rem;
    }
  }

  &_documents {
    width: 100%;

    .table__row {
      border-radius: 0.8rem;

      &:nth-child(2n-1) > .table__col {
        background-color: #fafafb;
      }

      & .table__col {
        &:first-child {
          font-weight: 500;
          border-radius: 0.8rem 0 0 0.8rem;
        }

        &:last-child {
          border-radius: 0 0.8rem 0.8rem 0;
        }
      }
    }

    .table__col-block {
      display: flex;
      align-items: center;
    }

    .table__col-block-content {
      display: flex;
      flex-direction: column;
    }

    .table__col-icon {
      font-size: 1.35rem;
      margin-right: 1.6rem;
      color: white;

      .icon-table-error::before {
        vertical-align: 0.2rem;
      }

      &.non-bg-icon {
        padding-left: 0.6rem;
      }

      @include tablet {
        font-size: 0.8rem;
      }
      @include mobile {
        margin-right: 0.8rem;
      }
    }

    .table__col-date {
      display: none;
      @include tablet {
        display: inline-block;
        font-size: 1rem;
        line-height: 1.2rem;
      }
    }

    .table__col-btns {
      text-align: right;
    }

    .table__col-btn:not(:last-child) {
      margin-right: 2.4rem;
    }
  }

  &_payment {
    .table__col-btn {
      transition: $tr_03;
      color: $clr-purple;

      &:hover {
        color: $clr-purple_hover;
      }

      .icon-circle-right {
        font-size: 1.9rem;
        vertical-align: -0.1rem;
      }
    }

    .table__col:last-child {
      padding-left: 0;
    }

    .table__body .table__row:nth-child(2n-1) {
      background: #fafafb;
    }

    .table__body .table__row:nth-child(2n) .table__col {
      padding-top: 2rem;
      padding-bottom: 2rem;
      @include mobile {
        padding-top: 1rem;
        padding-bottom: 1rem;
      }
    }

    .table__head .table__col {
      color: #a9a9a9;
      padding-bottom: 2.5rem;
      @include mobile {
        padding-bottom: 1rem;
      }
    }

    .table__body .table__col:first-child {
      color: $clr-purple;
    }

    .table__body .table__col:last-child {
      font-weight: 500;
    }

    .table__foot .table__col {
      font-weight: bold;
      color: $clr-purple;
      padding: 1.2rem 0.9rem;
      @include mobile {
        padding: 0.8rem 0.5rem;
      }
    }

    .table__foot .table__row {
      background: #fafafb;
    }

    .table__col {
      white-space: nowrap;
    }
  }

  &_board {
    @include tablet {
      font-size: 1.2rem;
    }
    @include mobile {
      font-size: 0.9rem;
    }

    .table__col {
      font-weight: 500;
      color: $clr-purple;

      &.text-gray {
        color: #a9a9a9;
      }

      &.text-normal {
        font-weight: 400;
      }
    }

    .table__col-btn {
      color: #4d4e67;

      .icon-circle-right {
        font-size: 1.9rem;
        vertical-align: -0.1rem;
      }
    }

    .table__head .table__row:last-child {
      background-color: rgba(#4f194a, 0.1);

      .table__col {
        font-weight: bold;
        color: #4f194a;
        padding: 1.2rem 0.9rem;
        @include mobile {
          padding: 0.8rem 0.5rem;
        }
      }
    }

    .table__body .table__row:nth-child(2n) {
      background-color: #fafafb;
    }
  }

  &_prof-general {
    display: flex;
    flex-direction: column;

    .table__row {
      display: flex;
      margin-bottom: 0.8rem;

      &:last-child {
        margin-bottom: 0;
      }

      @include tablet {
        margin-bottom: 0;
      }
      @include mobile {
        flex-direction: column;
        margin-bottom: 1rem;
        &:last-child {
          margin-bottom: 0;
        }
      }
    }

    .table__col {
      padding: 0.9rem 0;
      @include mobile {
        padding: 0.2rem 0;
      }
    }

    .table__col:first-child {
      width: 34%;
      @include mobile {
        width: 100%;
      }
    }
  }

  &_financial {
    margin-top: 3rem;
    color: $clr-purple;

    .table__body {
      .table__row {
        &.last-expense {
          outline: solid 2px $clr-green;
          outline-offset: -2px;
        }

        &.active-row {
          border-top-color: transparent;
        }
      }

      .table__row.collapsible:not(.active-row) {
        border: none;
      }
    }

    .table__avatar {
      border: none;

      .avatar__img {
        width: 3rem;
        height: 3rem;
      }
    }

    .table__col-inner {
      max-height: 4rem;
      line-height: 20px;
    }

    .table__col-btns-wrap {
      display: flex;
    }

    .table__col-btns {
      display: flex;
      justify-content: space-between;
      padding-left: 0.6rem;
      width: 100%;
      border-left: 0.1rem solid rgba($clr-purple, 0.3);
    }

    .table__col-btn:not(:last-child) {
      margin-right: 0.9rem;
    }

    .table__head .table__col {
      padding: 1.2rem 0.8rem;
      text-align: left;
      color: rgba($clr-purple, 0.5);
    }

    .table__body .table__col {
      padding: 1.3rem 0.8rem;
      background: #fafafb;

      &_details {
        padding: 0;
      }
    }

    .table__body .table__row:nth-child(4n-2) + .table__row .table__col {
      background: white;
    }

    .table__col-btn {
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }

  &_credit {
    @include tablet {
      font-size: 1.2rem;
    }
    @include mobile {
      font-size: 1rem;
    }

    & .table__head .table__col {
      background-color: #e7e7eb;
      color: #418dff;
    }

    & .table__col {
      color: #351f6f;
    }

    .table__body .table__row:nth-child(2n) > .table__col {
      background-color: #fafafb;
    }

    .table__body .table__row:first-child > .table__col {
      padding-top: 3rem;
    }
  }

  &__col-inner {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    max-width: 25rem;

    &--control {
      button {
        span {
          white-space: normal;
        }

        min-height: auto;
        font-size: inherit;
        color: inherit;
        display: flex;

        i {
          color: inherit;
          margin-left: 0.2rem;
        }
      }
    }

    &-wrap {
      overflow: visible;
      white-space: normal;
      text-overflow: inherit;
      max-height: inherit !important;
    }
  }

  &__col-inner-multiple {
    display: flex;
    -webkit-box-pack: justify;
    justify-content: space-between;
    width: 100%;
    align-items: center;
  }
}

.table-buttons {
  text-align: right;
  position: relative;

  &__expand {
    display: none;
    cursor: pointer;

    &:before {
      color: #4d4e67;
    }

    &.active ~ [class*='__wrap'] {
      opacity: 1;
      visibility: visible;
    }

    @include tablet-big {
      display: block;
      margin-left: auto;
    }
  }

  &__wrap {
    @include tablet-big {
      display: flex;
      flex-direction: column;
      position: absolute;
      top: -0.2rem;
      right: -0.2rem;
      background-color: #fff;
      padding: 1.9rem 1.2rem;
      border-radius: 0.8rem;
      box-shadow: 0 0.4rem 0.8rem rgba(0, 0, 0, 0.25);
      opacity: 0;
      visibility: hidden;
      z-index: 100;
    }
  }

  &__btn {
    cursor: pointer;
    min-height: 2.4rem;
    min-width: 2.4rem;
    font-size: 2.1rem;
    margin-right: 2.4rem;

    & > i {
      transition: $tr_03;
      color: #4d4e67;
    }

    &:hover > i {
      color: $clr-purple;
    }

    &:last-child {
      margin-right: 0;
    }

    @include laptop {
      margin-right: 1rem;
      &:last-child {
        margin-right: 0;
      }
    }
    @include tablet-big {
      display: flex;
      align-items: center;
      margin-right: 0;
      margin-bottom: 2.1rem;
      &:last-child {
        margin-bottom: 0;
      }
    }
  }

  &__btn-text {
    display: none;
    min-width: 8.6rem;
    text-align: left;
    font-size: 1.2rem;
    line-height: 1.4rem;
    @include tablet-big {
      display: inline-block;
      margin-left: 0.9rem;
    }
  }
}

.details {
  overflow: hidden;
  max-height: 0;
  padding: 0;

  &__title {
    font: bold 1.8rem/2.1rem $base-font;
    margin-bottom: 1.5rem;
  }

  &__head {
    color: rgba($clr-purple, 0.5);
    margin-bottom: 0.8rem;
  }

  &__col {
    &:not(:last-child) {
      margin-bottom: 1.1rem;
      padding-right: 9rem;
    }
  }

  &__row {
    display: flex;
    flex-wrap: wrap;
  }

  &__text {
    color: $clr-purple;
  }
}

.active-row {
  .table__col-btn .icon-down-arrow-sm {
    transform: rotate(-180deg);
  }

  .details {
    max-height: max-content;
    transition: max-height 0.5s ease-in;
    padding: 1.3rem 3.6rem 1.3rem 4.6rem;
  }
}

.credit-table {
  font-size: 1.6rem;
  line-height: 1.9rem;
  @include tablet {
    font-size: 1.4rem;
    line-height: 1.6rem;
  }
  @include mobile {
    font-size: 1.3rem;
  }

  &__col {
    padding: 0.5rem 1.2rem;

    &:first-child {
      min-width: 27rem;
      width: 27rem;
      @include tablet {
        width: 18rem;
        min-width: 18rem;
      }
      @include mobile {
        width: auto;
        max-width: 18rem;
        min-width: auto;
      }
    }
  }

  & + .table {
    margin-top: 2rem;
  }
}

.financial-data__description {
  .table__col-inner {
    max-width: 17.4rem;

    .text {
      overflow: hidden;
      text-overflow: ellipsis;
      max-width: 100%;
      display: inline-block;
    }
  }
}

.financial-data__usd {
  .table__col-inner {
    max-width: 6rem;
  }
}

.comparison-report {
  &__title {
    font-size: 34px;
    color: #351f6f;
    font-weight: bold;
    padding-bottom: 3.4rem;
    line-height: 4rem;
    @include mobile {
      font-size: 2.2rem;
      padding-bottom: 2rem;
      line-height: 2.4rem;
    }
  }

  &__form {
    .form__row {
      @include tablet-small {
        flex-direction: column;
      }
    }
  }

  &__select {
    width: 12rem;
    @include tablet-small {
      width: 100%;
    }

    &--long {
      width: 22.1rem;
      @include tablet-small {
        width: 100%;
      }
    }
  }

  &__filters {
    &_vs {
      margin: 0 1.2rem;
      display: flex;
      align-items: center;
      font-size: 1.6rem;
      color: #a9a9a9;
      @include tablet-small {
        justify-content: center;
        margin: 1rem 0;
      }
    }

    &_types {
      display: flex;
      height: 100%;
      align-items: center;
      border-radius: 9px;
      background: #e7e7eb;
      margin-right: 0.8rem;
      @include tablet-small {
        &.form__row {
          flex-direction: row;
          margin: 0 0 1.2rem 0;
        }
      }

      .switch__btn {
        padding: 1.5rem 0.8rem;
        min-width: 8.5rem;
        text-align: center;
      }
    }
  }

  &__table {
    .budget-table__head {
      min-width: 22.5rem;
      @include tablet-small {
        min-width: 14.5rem;
      }
    }

    .budget-table__head,
    .budget-table__head_item {
      color: #4d4e67;
      font-weight: bold;
    }

    .budget-table__head,
    .budget-table__total,
    .budget-table__title_item,
    .budget-table__row_item {
      box-shadow: none;
      border-right: 1px solid #e7e7eb;
    }

    &.budget-table {
      td:nth-child(4) {
        border-right: 1px solid #e7e7eb;
      }

      .budget-table__item_center.positive {
        color: #46a6a4;
      }

      .budget-table__item_center.negative {
        color: #ff1f3a;
      }
    }

    .compare-budget__row-category .budget-table__item_center,
    .compare-budget__row-category .budget-table__item-right {
      font-weight: bold;
      @media screen and (max-width: 800px) {
        white-space: nowrap;
      }
    }

    &.budget-table td {
      @media screen and (max-width: 800px) {
        min-width: 12rem;
      }
      @include mobile {
        min-width: 9rem;
      }
    }

    &_box {
      @media screen and (max-width: 800px) {
        .monthly-budget_header.boat-budget__head {
          margin-bottom: 6rem;
        }
      }
      @include tablet {
        .boat-budget__head_title {
          width: 80%;
          line-height: 2.4rem;
        }
      }
      @include mobile {
        .monthly-budget_header.boat-budget__head {
          margin-bottom: 3rem;
        }
      }
    }
  }

  &__chart {
    margin: 5rem 0 3.5rem;
    @include tablet {
      margin: 3rem 0 1.5rem;
    }
  }
}

.table_financial .financial-data__table_row {
  .table__col {
    padding: 1.2rem 0.5rem 3rem;
    vertical-align: bottom;
  }

  .financial-data__table_joined .form__col:not(:last-child) {
    margin-right: 0.37rem;
  }

  .financial-data__table {
    &--month {
      width: 8.4rem;
      @include tablet {
        padding-left: 3rem;
      }

      app-filter-group-container {
        max-width: 8.4rem;
        @media screen and (max-width: 1700px) {
          width: 7.6rem;
        }
      }
    }

    &--receipt {
      width: 10.2rem;

      app-seazone-form-field {
        max-width: 10.2rem;
        @media screen and (max-width: 1700px) {
          max-width: 8.2rem;
        }
      }
    }

    &--method {
      max-width: 10.6rem;

      app-seazone-form-field {
        max-width: 11.6rem;
        @media screen and (max-width: 1700px) {
          max-width: 9.6rem;
        }
      }
    }

    &--category {
      width: 17.8rem;

      app-filter-group-container {
        width: 17.8rem;
        @media screen and (max-width: 1700px) {
          width: 13.8rem;
        }
      }
    }

    &--supplier {
      width: 16.3rem;

      app-seazone-form-field {
        width: 16.3rem;
        max-width: 16.3rem;
        @media screen and (max-width: 1700px) {
          width: 14.3rem;
          max-width: 14.3rem;
        }
      }
    }

    &--description {
      width: 18.4rem;

      app-seazone-form-field {
        max-width: 18.4rem;
        width: 18.4rem;
      }
    }

    &--currency {
      max-width: 14.1rem;

      .form__row {
        @include mobile {
          flex-direction: row;
        }
      }
    }

    &_col--currency {
      width: 44%;
      margin-right: 0.4rem;

      &.form__col {
        @include mobile {
          margin-bottom: 0;
        }
      }
    }

    &_col--amount {
      width: 55%;
    }

    &_amount {
      width: 7.9rem;

      app-seazone-form-field {
        width: 8.9rem;
        @media screen and (max-width: 1700px) {
          width: 7.9rem;
        }
      }
    }

    &--status {
      max-width: 6.5rem;

      app-seazone-form-field {
        max-width: 6.5rem;
      }
    }

    &--receipts {
      app-seazone-form-field {
        max-width: 6.6rem;
      }
    }
  }

  .financial-data__table_joined {
    max-width: 18.4rem;

    .form__col {
      width: 49%;
    }
  }
}

.financial-data__month-date {
  .form-field--content-outline {
    border: none !important;
    background-color: inherit !important;
    height: 100%;
    padding: 0 !important;
  }

  .form-field--label,
  .seazone__form-month-input {
    display: none;
  }

  .form-field--content-wrapper {
    width: 0;
  }

  > span {
    min-width: 2.6rem;
  }
}

.financial-data__method {
  &.table__col {
    max-width: 9.6rem;

    .table__col-inner {
      white-space: pre-wrap;
      max-width: 100%;

      &:first-letter {
        text-transform: uppercase;
      }
    }
  }
}

.financial-data__table .financial-data__category {
  max-width: 14.3rem;
}

.financial-data__status {
  max-width: 6.5rem;
}

.financial-data__col_btns.table__col-btns {
  padding-left: 1.6rem;

  .form-field--content {
    padding: 0.8rem 0;
  }
}

.financial-data__table .financial-data__usd {
  .table__col-inner {
    max-width: 10rem;
  }
}

.financial-data__buttons {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  @media screen and (max-width: 1024px) {
    flex-direction: row;
    margin-left: auto;
    flex-wrap: wrap;
    justify-content: space-between;
  }

  .main-btn {
    width: 100%;
  }
}

.boat-pretty-cash-table-wrap {
  .controls-container {
    top: 0 !important;
  }
}

.boat-pretty-cash-table {
  font-family: $base-font;

  &__wrapper {
    position: relative;

    .info-label {
      margin-top: 2.2rem;

      a {
        text-decoration: underline;
      }
    }
  }

  .bold {
    font-weight: bold;
  }

  .table_item-with-icon {
    display: flex;
    align-items: center;

    app-seazone-icon {
      margin-left: 1.2rem;
    }

    &.red {
      app-seazone-icon {
        transform: rotateZ(90deg);
      }
    }
  }

  .credit-table__row--cash {
    width: max-content;

    @include desktop {
      width: 100%;
      overflow-x: auto;
    }
  }

  &__header {
    background-color: initial !important;
  }

  &__title {
    font-size: 2rem;
    font-weight: 600;
    line-height: 2.3rem;
    color: $seaz-text;
    background-color: initial;
    margin-top: 1.2rem;
    padding: 0.6rem 1.2rem;
  }

  .pretty-cash__report_table_item {
    padding: 0.6rem 1.2rem;
  }

  .pretty-cash__report_table_row:nth-of-type(2n + 1) {
    background-color: $clr-bg;
  }

  .pretty-cash__report_table_row:nth-of-type(2n) {
    background-color: initial;
  }

  .row-color-violet {
    color: $clr-main;
  }

  .pretty-cash__report_table_row-total {
    .pretty-cash__report_table_label,
    .pretty-cash__report_table_item {
      font-weight: 400;
    }
  }

  .info-label {
    margin-top: 1.2rem;
  }
}

.boat-pretty-cash-table-transactions-wrapper {
  box-shadow: 0 2px 2px rgba(43, 28, 54, 0.16);
  border-radius: 1.6rem;
  padding: 2.4rem;
  margin-top: 3rem;
}

.boat-pretty-cash-table-transactions {
  .credit-table__row--cash {
    margin-top: 0;
  }

  &__light-border {
    border-bottom: 1px solid $seaz-head-arrow;
    padding-bottom: 0.7rem;
  }

  &__dark-border {
    border-bottom: 1px solid $seaz-document-count;
  }

  &__position {
    .pretty-cash__report_table_item {
      text-align: left;
    }
  }

  &__currency {
    .pretty-cash__report_table_label,
    .pretty-cash__report_table_item {
      color: $clr-primary-and-accent;
    }
  }

  .pretty-cash__report_table_row:nth-of-type(2n + 1) {
    background-color: initial;
  }

  .pretty-cash__report_table_label {
    width: 26rem;
  }

  .pretty-cash__report_table_item {
    width: 10.2rem;
  }

  .pretty-cash__report_table_row-total {
    padding: 0.7rem 0;

    .bold {
      font-size: 1.8rem;
      font-weight: 700;
      line-height: 2.1rem;
    }

    .pretty-cash__report_table_item {
      text-align: left;
    }
  }
}
