.holders {
  &__item {
    margin-bottom: 5.9rem;
    &:last-child {
      margin-bottom: 0;
    }
    @include mobile {
      margin-bottom: 4rem;
      &:last-child {
        margin-bottom: 0;
      }
    }
  }
  &__item_hidden .holder-content,
  &__item_hidden .holder-head__col_left,
  &__item_hidden .holder-head__col_right .title-h3,
  &__item_hidden .holder-head__col_right .holder-head__collapse {
    opacity: 0.3;
  }
}

.holder-head {
  padding: 0.5rem 1.2rem;
  background-color: rgba($color: #e7e7eb, $alpha: 0.5);
  border-radius: 0.4rem;
  display: flex;
  align-items: center;
  &_transparent {
    background-color: transparent;
    padding-right: 5.6rem;
    &:not(:first-child) {
      margin-top: 2rem;
    }
    & [class*='__col'] {
      max-width: 50%;
      @include tablet {
        max-width: none;
      }
    }
    & [class*='__col_left'] {
      display: flex;
      justify-content: space-between;
    }
    & [class*='__col_right'] {
      @include tablet {
        flex-grow: 0;
      }
    }
    @include tablet {
      padding-right: 4.1rem;
    }
    @include mobile {
      padding-right: 3.6rem;
    }
  }
  &__col {
    flex-grow: 1;
    & > [class*='title'] {
      white-space: nowrap;
    }
    &_left {
      padding: 0.1rem 0;
      overflow: hidden;
      & > [class*='title'] {
        overflow: hidden;
        text-overflow: ellipsis;
      }
    }
    &_right {
      display: flex;
      align-items: center;
      justify-content: flex-end;
      padding-left: 1rem;
    }
  }
  &__col-container {
    display: flex;
    align-items: stretch;
    padding: 0 1.2rem 0 0;
    @include tablet {
      padding: 0 1rem 0 0;
    }
    @include mobile {
      padding: 0 0.7rem 0 0;
    }
  }
  &__collapse {
    border-left: 0.1rem solid rgba($color: #351f6f, $alpha: 0.1);
    font-size: 0.6rem;
    padding: 0.6rem 1.5rem;
    margin-right: -2rem;
    margin-left: 1.2rem;
    cursor: pointer;
    &.active:before {
      transform: rotate(180deg);
    }
    @include tablet {
      padding: 0.6rem 1rem;
      margin-left: 1rem;
    }
    @include mobile {
      margin-left: 0.7rem;
    }
  }
}

.holder-content {
  padding-top: 1.8rem;
  &__row {
    display: flex;
    & .switch {
      align-self: flex-end;
      @include tablet {
        align-self: flex-start;
      }
    }
  }
  &__col {
    flex-grow: 1;
    width: 100%;
    margin-right: 2.2rem;
    &:last-child {
      margin-right: 0;
    }
    @include tablet {
      margin-right: 1rem;
    }
  }
  &.content__hidden {
    margin-bottom: -2.1rem;
    height: 0;
    overflow: hidden;
    padding: 0;
  }
}

.block-title {
  margin-bottom: 1.8rem;
}

.bar-horizontal {
  &_percent {
    & .bar-horizontal__item {
      background: linear-gradient(89.69deg, #46a6a4 4.87%, rgba(70, 166, 164, 0.42) 99.99%);
      cursor: pointer;
      &.default {
        cursor: default;
      }
    }
  }
  &__item {
    border-radius: 0.2rem;
    display: flex;
    align-items: center;
    font-size: 1.4rem;
    line-height: 1.6rem;
    color: #351f6f;
    margin-bottom: 1.6rem;
    background-color: #e7e7eb;
    padding: 0.4rem 0;
    &:last-child {
      margin-bottom: 0;
    }
    & > :first-child {
      padding-left: 0.9rem;
      padding-right: 0.9rem;
    }
    & > :last-child {
      padding-right: 0.9rem;
    }
  }
  &__cash {
    width: 12rem;
    text-align: right;
  }
  &__month {
    flex-grow: 1;
  }
}

.holder-footer {
  text-align: right;
  padding-top: 4rem;
}

.switch-control {
  display: flex;
  align-items: center;
  padding-left: 1.2rem;
  margin-left: 1.6rem;
  border-left: 0.1rem solid rgba($color: #351f6f, $alpha: 0.1);
  @include tablet {
    margin-left: 1rem;
    padding-left: 1rem;
  }
  @include mobile {
    margin-left: 0.7rem;
    padding-left: 0.7rem;
  }
  &__text {
    font-size: 1.6rem;
    line-height: 1.9rem;
    margin-right: 1rem;
    @include tablet {
      font-size: 1.4rem;
      line-height: 1.6rem;
    }
    @include mobile {
      font-size: 1.2rem;
      line-height: 1.4rem;
      margin-right: 0.7rem;
    }
  }
  &__block {
    position: relative;
    width: 3.4rem;
    height: 1.4rem;
    cursor: pointer;
    @include tablet {
      width: 3rem;
      height: 1.2rem;
    }
  }
  &__checkbox {
    opacity: 0;
    height: 0;
    width: 0;
    &:checked ~ .switch-control__round {
      background-color: rgba($color: #46a6a4, $alpha: 0.38);
      &:after {
        left: 1.5rem;
        background-color: #46a6a4;
        @include tablet {
          left: 1.3rem;
        }
      }
    }
  }
  &__round {
    position: absolute;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    border-radius: 0.7rem;
    background-color: rgba($color: #4d4e67, $alpha: 0.38);
    transition: $tr_03;
    &:after {
      content: '';
      position: absolute;
      top: 50%;
      left: -0.1rem;
      transform: translate(0, -50%);
      height: 2rem;
      width: 2rem;
      border-radius: 50%;
      background-color: #4d4e67;
      box-shadow:
        0rem 0.1rem 0.3rem rgba(0, 0, 0, 0.2),
        0rem 0.2rem 0.2rem rgba(0, 0, 0, 0.12),
        0rem 0rem 0.2rem rgba(0, 0, 0, 0.14);
      transition: $tr_03;
      @include tablet {
        height: 1.8rem;
        width: 1.8rem;
      }
    }
  }
}
