.boat-fleets-header {
  @include d-flex-space-between;
  align-items: center;

  &__row {
    @include d-align-items-center;
  }

  &__chevron-button {
    background-color: $seaz-head-arrow;
    margin-right: 2.4rem;
  }

  @include laptop {
    flex-direction: column;
    align-items: flex-start;
  }
}
