.fleet-charter-card-wrapper {
  display: block;
  @include indent-not-last-of-type(0 0 2.4rem 0);
}

.fleet-charter-card {
  background-color: $light-color;
  border: 2px solid rgba(53, 31, 115, 0.2);
  box-shadow: 0 16px 32px rgba(43, 28, 54, 0.12);
  border-radius: 1.6rem;
  padding: 2.4rem;

  @include tablet {
    padding: 1.6rem;
  }
}

.fleet-charter-card-grid {
  display: grid;
  grid-template-areas:
    'avatar badge card-date'
    'avatar card-info card-info'
    'avatar card-list card-list';
  grid-template-columns: 16rem 1fr 1fr;
  grid-gap: 0 2.4rem;

  @include tablet {
    grid-template-areas:
      'badge badge'
      'avatar card-date'
      'avatar card-info'
      'card-list card-list';
    grid-template-columns: 6.6rem 1fr;
    grid-gap: 0 1.6rem;
  }
}

.fleet-charter-card-avatar {
  grid-area: avatar;
  width: 16rem;
  height: 16rem;
  border-radius: 1.6rem;

  &__img {
    display: block;
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 1.6rem;
  }

  @include tablet {
    width: 6.6rem;
    height: 6.6rem;
  }
}

.fleet-charter-card-badges {
  grid-area: badge;
  @include d-align-items-center;
  margin-bottom: 1.6rem;

  @include tablet {
    margin-bottom: 1.2rem;
  }
}

.fleet-charter-card-date {
  grid-area: card-date;
  @include d-align-items-center;
  justify-content: flex-end;
  font-size: 1.2rem;
  line-height: 1.4rem;
  color: $seaz-document-count;
  margin-bottom: 1.6rem;

  @include tablet {
    justify-content: flex-start;
    margin-bottom: 0.8rem;
  }
}

.fleet-charter-card-info {
  grid-area: card-info;

  &__boat-info {
    @include d-align-items-center;
    font-size: 1.6rem;
    font-weight: 700;
    line-height: 1.6rem;
    color: $clr-primary-and-accent;
  }

  &__flag {
    display: block;
    width: 2.5rem;
    height: 1.6rem;
    margin-right: 1.2rem;
  }

  &__boat-name {
    word-break: break-word;
    padding-left: 0.5rem;
  }

  &__boat-length {
    white-space: nowrap;
    color: $seaz-document-count;
  }

  &__charter-info {
    width: max-content;
    font-size: 2rem;
    font-weight: 500;
    line-height: 2.3rem;
    word-break: break-word;
    color: $clr-primary-and-accent;
    margin-top: 1.4rem;
  }

  @include tablet {
    &__boat-info {
      font-size: 1.4rem;
    }

    &__boat-name {
      max-width: 16rem;
      @include cut-text-add-dots;
    }

    &__charter-info {
      width: auto;
      font-size: 1.6rem;
    }
  }

  @include mobile {
    &__boat-name {
      max-width: 8rem;
    }
  }
}

.fleet-charter-card-list {
  grid-area: card-list;
  @include d-align-items-center;
  font-size: 1.4rem;
  line-height: 1.6rem;
  border-top: 1px solid $seaz-bg;
  margin-top: 1.6rem;
  padding-top: 1.6rem;

  &__col-period {
    width: 10%;
    margin-right: 2.4rem;
  }

  &__col-date {
    flex-grow: 1;
    margin-right: 2.4rem;
  }

  &__guests {
    width: 8%;
    margin-right: 2.4rem;
  }

  &__income {
    width: 14%;
  }

  &__title {
    color: $profile-month;
    margin-bottom: 0.6rem;
  }

  &__text {
    color: $seaz-text;
  }

  @include tablet {
    flex-direction: column;
    margin-top: 1.2rem;
    padding-top: 1.2rem;

    &__col {
      @include d-align-items-center;
      width: 100%;
      @include indent-not-last-of-type(0 0 1.2rem 0);
    }

    &__title {
      min-width: 11.4rem;
      margin: 0 0.4rem 0 0;
    }

    &__text {
      font-weight: 500;
    }
  }
}
