.map-info {
  &__header {
    justify-content: flex-start;

    img {
      margin-left: 0.9rem;
    }
  }

  .person-info-contacts__info-work {
    height: 2.4rem;
    line-height: 2.4rem;
    margin-left: 0;
    padding: 0 0.6rem;
  }

  .star-rating {
    margin-right: 1rem;
  }

  .info-window__profile {
    font-size: 1.6rem;
    font-weight: bold;
    text-decoration: none;
    margin-bottom: 0.8rem;
  }

  .info-window__supplier-info {
    font-size: 1.4rem;
    font-weight: 400;
    line-height: 1.6rem;
  }

  .info-window__supplier-working {
    color: $seaz-text;
  }

  .info-window__supplier-break,
  .info-window__supplier-utc {
    color: $clr-grey;
  }

  @media screen and (max-width: 1300px) {
    .info-window__header {
      flex-direction: row;
    }
  }
}
