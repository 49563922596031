.boat-assignee-selection-modal {
  .boat-assignee-form {
    margin-top: 3rem;
  }

  .boat-assignee-form-search {
    margin-bottom: 2.8rem;
  }

  .boat-assignee-form-footer {
    padding: 3rem 0 2rem 0;
  }

  @include tablet {
    .boat-assignee-form {
      margin-top: 1.6rem;
    }
  }
}
