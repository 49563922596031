.contacts-form {
  &__checkbox {
    &:not(:last-of-type) {
      margin-right: 4rem;
    }
  }

  &__checkbox-group {
    margin-top: 2.7rem;
  }

  &__field-container {
    &:not(:last-of-type) {
      margin-bottom: 2.7rem;
    }

    app-phone-number {
      width: auto;

      .phone-control-wrapper {
        width: 41.3rem;
      }
    }

    .form-field--label {
      width: 16rem;
    }
  }

  &__info-icon {
    margin-right: 1.2rem;

    svg {
      width: 2rem;
      height: 2rem;
    }
  }

  @include tablet {
    &__field-container {
      app-phone-number {
        width: 100%;

        .phone-control-wrapper {
          width: 100%;
        }
      }

      .form-field--label {
        width: 7rem;
      }
    }

    &__info {
      .form-field--label {
        max-width: 7rem;
      }
    }
  }

  @include mobile {
    &__info {
      .form__row {
        position: relative;
      }

      .form-field--label {
        padding-left: 2.5rem;
      }
    }

    &__checkbox-group {
      // justify-content: space-between;
    }

    &__checkbox {
      &:not(:last-of-type) {
        margin-right: 1rem;
      }
    }

    &__info-icon {
      justify-content: flex-start !important;
      position: absolute;
      top: 0;
      left: 0;
    }
  }
}
