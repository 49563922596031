.boat-maintenance-equipment-tree {
  &__title {
    margin-bottom: 3rem;
  }

  .maintenance-equipment-tree-toggle {
    padding: 1.6rem 0 1.4rem 1.2rem;
  }
}

.boat-maintenance-equipment-tree-subtitle {
  border-bottom: 1px solid $seaz-head-arrow;
  text-align: right;
  line-height: 16px;
  color: $seaz-document-count;
  padding: 0 4.5rem 0.4rem 0;
  margin-bottom: 1.2rem;
}

.boat-maintenance-equipment-tree-content {
  @include d-flex-space-between;
  align-items: flex-start;
  width: 100%;

  &__title {
    cursor: pointer;
  }

  &__actions {
    @include d-align-items-center;
  }
}

.maintenance-equipment-tree-modal {
  .maintenance-tree {
    max-height: 38rem;
    padding-bottom: 8rem;
  }
}

.boat-maintenance-equipment-tree-footer {
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  @include footer-with-border-left-btn(2rem, 1rem 0 0 0);
  background-color: $light-color;
  box-sizing: border-box;
  border: none;
  border-radius: 0 0 1.6rem 1.6rem;

  &__btn {
    @include indent-not-last-of-type(0 1.2rem 0 0);
  }
}
