.fleet-job-news {
  @include d-flex-space-between;
  align-items: stretch;
}

app-fleet-job-offers {
  display: block;

  @include desktop {
    margin: 0 0 2.4rem;
  }
}

.fleet-job-offers {
  width: 100%;
  height: 100%;

  &__title {
    text-transform: uppercase;
  }

  .boat-home__content {
    height: 100%;
  }
}

.fleet-job-offers-header {
  @include d-flex-space-between;
  align-items: center;

  &__counts {
    @include d-align-items-center;
  }
}

.fleet-job-offers-header-count {
  &:not(:last-of-type) {
    margin-right: 1.1rem;
  }

  @include d-align-items-center;
  font-size: 2rem;
  line-height: 120%;
  color: $seaz-text;

  &:last-of-type {
    color: $seaz-edit-hover;
  }

  app-seazone-icon {
    margin-right: 1.1rem;
  }
}

.fleet-job-offers-list {
  height: 45.6rem;
  overflow-y: auto;
  margin-top: 2rem;
  @include custom-scroll-bar;
  padding-right: 2.4rem;

  @include tablet {
    padding-right: 0;
  }
}

.fleet-job-offers-item {
  display: grid;
  grid-template-columns: 26% 1fr 25% 1fr 1fr 1.5rem;
  grid-gap: 0 1rem;
  justify-content: space-between;
  align-items: center;
  border: 1px solid $seaz-head-arrow;
  box-sizing: border-box;
  box-shadow: 0 2px 2px rgba(43, 28, 54, 0.16);
  border-radius: 1.6rem;
  padding: 2.4rem;

  @include indent-not-last-of-type(0 0 2.4rem);

  @include extra-large {
    grid-template-areas:
      'logo logo logo logo arrow'
      'person location info date arrow';
    grid-template-columns: 1fr 1fr 1fr 1fr 1.5rem;
    grid-gap: 1rem;
    padding: 1.6rem;

    &__logo {
      grid-area: logo;
    }

    &__person {
      grid-area: person;
    }

    &__location {
      grid-area: location;
    }

    &__info {
      grid-area: info;
    }

    &__date {
      grid-area: date;
    }

    &__arrow {
      grid-area: arrow;
    }
  }

  @include tablet {
    grid-template-areas:
      'logo'
      'person'
      'location'
      'info'
      'date'
      'arrow';
    grid-template-columns: 1fr;
    grid-gap: 1.2rem 0;

    &__arrow {
      display: none;
    }
  }
}

.fleet-job-offers-logo {
  @include d-align-items-center;

  &__avatar {
    display: block;
    width: 4.8rem;
    height: 4.8rem;
    border-radius: 1.2rem;
    object-fit: contain;
    margin-right: 2.9rem;
  }

  &__container {
    @include d-align-items-center;
  }

  &__text {
    font-size: 1.6rem;
    font-weight: 700;
    line-height: 1.9rem;
    margin-left: 0.8rem;
    color: $clr-primary-and-accent;
  }

  &__size {
    color: $seaz-border;
  }

  @include mobile {
    justify-content: space-between;

    &__avatar {
      width: 3.7rem;
      height: 3.7rem;
      margin: 0 0 0 1rem;
    }

    &__container {
      order: -1;
    }
  }
}

.fleet-job-offers-col {
  &__title {
    font-size: 1.6rem;
    font-weight: 500;
    line-height: 1.9rem;
    color: $clr-primary-and-accent;

    &--new {
      color: $greeny-border-bg;
    }
  }

  &__info {
    @include d-align-items-center;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.4rem;
    letter-spacing: 0.5px;
    text-transform: uppercase;
    word-break: break-word;
    color: $seaz-document-count;
    margin-top: 0.4rem;
  }

  &__location {
    margin-right: 0.7rem;
  }
}

.fleet-job-offers-col-group {
  @include d-align-items-center;

  &__item {
    @include indent-not-last-of-type(0 2rem 0 0);
  }
}

.fleet-job-offers-arrow {
  display: block;
  transform: rotate(180deg);
}
