.fleet-expenses-filter-form-box {
  &__search {
    margin-top: 1.2rem;
  }

  @include mobile {
    .form__checkbox-label {
      width: auto;
    }
  }
}

.fleet-expenses-filter-form-top-indent {
  margin-top: 3rem;
}

.fleet-chips {
  margin-top: 1.2rem;

  &__label {
    display: block;
    font-weight: 500;
    line-height: 1.4rem;
    color: $seaz-text;
  }

  app-chips-checkbox-item {
    //margin-top: 0.8rem;
    //
    //&:not(:last-of-type) {
    //  margin-right: 0.8rem;
    //}
  }

  .chips-item {
    display: inline-block;
    line-height: 2rem;
    color: $seaz-text;
    padding: 1rem 1.6rem;
    border: 1px solid $seaz-head-arrow;
    box-sizing: border-box;
    border-radius: 5.6rem;
    cursor: pointer;

    &.selected {
      box-shadow: 0 0 0 1px $clr-primary-and-accent;
      border-color: initial;
    }
  }
}

.fleet-expenses-selection-form {
  margin-top: 2.3rem;

  &__checkbox-box {
    position: relative;
    @include d-align-items-center;
    line-height: 1.6rem;
    color: $seaz-text;

    @include indent-not-last-of-type(0 0 3rem 0);

    .form__checkbox-label:before {
      border: 0.2rem solid $button-main;
    }

    .form__checkbox-input:checked + label:before {
      background-color: $button-main;
    }

    .form__checkbox-label-text {
      padding-left: 0;
      margin-right: 1.1rem;
    }
  }

  &__checkbox {
    margin-right: 1.1rem;

    &:after,
    &:before {
      border: 0.2rem solid $button-main;
    }
  }

  [role='checkbox'][aria-checked='true']:before {
    border-color: $button-main;
    background-color: $button-main;
  }

  [role='checkbox']::after {
    border-color: $button-main;
  }

  [role='checkbox'][aria-checked='mixed']::after {
    background-color: $button-main;
  }

  &__pagination {
    margin-top: 2.3rem;
  }
}

.fleet-expenses-selection-form-footer {
  @include footer-with-border-left-btn(0 0 1rem, 3rem 0 0);
  border: none;

  &__btn {
    @include indent-not-last-of-type(0 1.2rem 0 0);
  }
}

.fleet-accordion-expenses {
  app-accordion {
    @include indent-not-last-of-type(0 0 3rem 0);
  }

  .accordion {
    width: 100%;
    cursor: pointer;

    .accordion__label_right {
      transform: rotate(180deg);
    }

    &.active {
      .accordion__label_right {
        transform: rotate(0);
      }
    }
  }

  .accordion__label {
    @include d-flex-space-between;
    align-items: center;
    width: 100%;
  }

  .accordion__content {
    margin: 3rem 0 0 3rem;
  }

  @include mobile {
    .accordion__content {
      margin: 3rem 0 0 1.8rem;
    }
  }
}
