.expense-view {
  &__tabs {
    margin-top: 3rem;
  }

  &__action-btn {
    &:not(:last-of-type) {
      margin-right: 0;
    }
  }

  &__sub-title {
    font-size: 2rem;
  }
}
