.boat-documents {
  width: 100%;
  max-width: 130rem;
  box-sizing: border-box;
  margin: 0 auto;
  padding: 4.8rem 1.6rem 12rem;

  &__title {
    text-transform: uppercase;
    color: $clr-primary-and-accent;
    margin-bottom: 3.8rem;
  }

  &__container {
    margin-top: 3.8rem;
  }

  &__documents {
    padding: 3.2rem;
    margin-top: 2.4rem;
    @include box-bg($light-color, 0px 2px 2px rgba(43, 28, 54, 0.16), 1.6rem);
    border: 1px solid $seaz-head-arrow;
  }

  &__list {
    display: block;
    margin-top: 2.4rem;
  }

  @include laptop {
    &__documents {
      padding: 2rem;
    }
  }

  @include tablet {
    padding: 1.6rem 1.6rem 8rem;

    &__title {
      font-size: 2.4rem;
      margin-bottom: 2.4rem;
    }

    &__container {
      margin-top: 2.4rem;
    }

    &__documents {
      padding: 1.6rem;
    }
  }
}

.boat-documents-head {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  margin-bottom: 3.8rem;

  &__container {
    display: flex;
    align-items: flex-start;
  }

  .boat-documents__title {
    margin: 0 1rem 0 3.8rem;
    line-height: 145%;
  }

  .boat-document-bread-crumbs {
    margin: 0.4rem 0 0 3.8rem;
  }

  &__btn,
  .seaz__return_btn {
    flex-shrink: 0;
  }

  @include tablet {
    .boat-documents__title {
      margin: 0 1rem 0 1.6rem;
    }

    .boat-document-bread-crumbs {
      margin: 0.4rem 0 0 1.6rem;
    }

    &__btn {
      padding: 1rem;
    }

    .seaz__btn_rounded--icon {
      margin-right: 0;
    }

    .header-btn-text {
      display: none;
    }
  }
}
