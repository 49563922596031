.service-brands-modal {
  margin-top: 3rem;

  &__toggle-select-buttons {
    margin-bottom: 1.6rem;
  }

  &__footer {
    display: flex;
    justify-content: flex-end;
    border-top: 1px solid $seaz-head-arrow;
    padding: 3rem 0 1rem 0;
    margin-top: 3rem;
  }

  @include tablet {
    .add-brands__brands-item {
      flex-direction: row;
      align-items: center;

      &:not(:last-of-type) {
        margin-bottom: 0;
      }
    }
  }
}

.add-brands {
  &__search {
    display: flex;
    align-items: center;
  }

  &__search-btn {
    display: flex;
    align-items: center;
    flex-shrink: 0;
    margin-left: 1.2rem;
  }

  &__brands-list {
    margin-top: 1.6rem;
  }

  app-supplier-service-dictionary-brand-form,
  app-no-data {
    display: block;
    min-height: 40rem;
  }

  &__brands-item {
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-radius: 0.8rem;
    padding: 1.2rem 2.1rem 1.2rem 1.2rem;

    &:nth-child(2n + 1) {
      background-color: $clr-bg;
    }
  }

  &__brands-item-wrap {
    display: flex;
    align-items: center;
  }

  &__checkbox {
    margin-left: 1.9rem;

    .form__checkbox-label-text {
      padding-left: 1.1rem;
    }
  }

  &__brands-close-btn {
    margin-left: 1rem;
  }

  &__list-box {
    margin-top: 3.2rem;
  }

  @include tablet {
    &__brands-item {
      flex-direction: column;
      align-items: flex-start;

      &:not(:last-of-type) {
        margin-bottom: 1rem;
      }
    }

    &__brands-item-wrap {
      flex-direction: column;
      align-items: flex-start;
    }

    &__checkbox {
      margin: 1.6rem 0 0 0;
    }

    &__brands-close-btn {
      order: -1;
      margin: 0 0 1rem 0;
    }
  }

  @include mobile {
    .form__checkbox-label {
      width: auto;
    }
  }
}

.all-services-catalog {
  &__list-services {
    margin-top: 1.6rem;
  }

  &__toggle-box {
    margin-bottom: 1.6rem;
  }

  &__count {
    font-size: 1.6rem;
    line-height: 1.9rem;
    color: $seaz-document-count;
    margin-bottom: 1.6rem;
  }

  &__count-add {
    margin-right: 1rem;
  }

  &__chips-container {
    margin: 0 1.2rem 1.6rem 0;
  }

  @include tablet {
    .list-services__col {
      &:first-of-type {
        padding-right: 0.5rem;
      }

      &:last-of-type {
        padding-left: 0;
      }
    }

    .list-services__item {
      padding: 0.5rem;
    }
  }

  @include mobile {
    .form__checkbox-label {
      padding: 0;
    }
  }
}

.list-services {
  display: flex;
  box-sizing: border-box;

  &__col {
    width: 50%;

    &:first-of-type {
      border-right: 1px solid $seaz-head-arrow;
      padding-right: 1.6rem;
    }

    &:last-of-type {
      padding-left: 1.6rem;
    }
  }

  &__scroll-box {
    height: 30rem;
    overflow-y: auto;

    @include custom-scroll-bar;
  }

  &__item {
    display: block;
    border-radius: 0.4rem;
    padding: 1.6rem;
    cursor: pointer;
  }

  &__text {
    line-height: 1.6rem;
  }

  &__role {
    &.selected {
      background-color: #351f73;
      color: $light-color;
    }
  }

  &__role-input {
    display: none;
  }

  &__check-item {
    &.checked {
      background-color: $clr-ashy-grey;
    }

    .form__checkbox {
      flex-direction: row-reverse;
      justify-content: space-between;
    }

    .form__checkbox-label-text {
      padding: 0 1rem 0 0;
    }
  }
}

.additional-video-modal {
  &__media-uploader {
    app-seazone-media-uploader,
    .seaz-uploader__choose_drop {
      width: 100%;
    }
  }

  &__label {
    flex-shrink: 0;
    width: 14rem;
    margin-right: 2rem;
    padding-top: 3rem;
  }

  .seaz-uploader {
    margin-top: 0;
  }

  .seaz-uploader__video_item {
    border-radius: 1rem;
  }
  .seaz-uploader__video_play {
    background: $clr-primary-and-accent;
  }

  .seaz-uploader__choose_btn {
    border-radius: 0.4rem;
  }

  .seaz-uploader__choose_drop {
    border-radius: 0.8rem;
  }

  @include tablet {
    &__label {
      padding-top: 0;
    }

    .seaz-uploader__box {
      align-items: flex-start;
    }

    .seaz-uploader__choose {
      justify-content: flex-start;
    }
  }

  @media screen and (max-width: 650px) {
    .form-field--wrapper__row .form-field--label {
      max-width: 14rem;
    }
  }

  @include mobile {
    .seaz-uploader__choose,
    .seaz-uploader__choose_btn {
      width: 100%;
      text-align: center;
    }

    .seaz-uploader__header {
      margin-bottom: 0.8rem;
    }

    .seaz-uploader__label {
      padding: 0;
    }
  }
}

.supplier-view-services-modal {
  max-width: 78.5rem;

  &__search {
    margin: 3rem 0;
  }
}
