.boat-equipment-mileage-header {
  @include d-align-items-center;

  .seaz__return_btn {
    margin-right: 1.6rem;
  }

  .boat__card-wrap {
    margin-bottom: 0;
  }
}

.boat-equipment-mileage {
  width: 100%;
  max-width: 77.2rem;
  margin: 6.6rem auto 18.6rem;
  &__info {
    display: block;
    margin-top: 3.2rem;
    .info-label {
      font-weight: 400;
    }
  }
  @include tablet {
    margin: 1.5rem auto 5rem;
    &__info {
      margin-top: 2.3rem;
    }
  }
}

.boat-equipment-mileage-actions {
  @include d-flex-space-between;
  align-items: center;
  margin-top: 3.2rem;

  @include tablet {
    flex-direction: column;
    align-items: flex-start;
    margin-top: 2.3rem;

    app-boat-equipment-mileage-search {
      width: 100%;
      margin-top: 2.3rem;
    }
  }
}

.boat-equipment-mileage-item {
  display: block;
  margin-top: 2.4rem;
  border: 2px solid $seaz-head-arrow;
  border-radius: 1.2rem;
  padding: 2.4rem;

  &.level-1,
  &.level-4 {
    background-color: $seaz-bg;
  }

  &.level-2,
  &.level-3 {
    background-color: $light-color;

    .accordion-header {
      align-items: baseline;
    }
  }

  &.level-2 {
    .accordion-mileage {
      position: relative;
      padding-left: 2rem;

      .boat-equipment-mileage-item-line-box {
        position: absolute;
        top: 0;
        left: 0;
        width: 0.1rem;
        height: 100%;
      }
    }
  }

  &.level-3 {
    .accordion-mileage {
      padding-left: 2.5rem;

      .boat-equipment-mileage-item-line-box {
        width: 1rem;
      }
    }
  }

  &.level-4 {
    .accordion-mileage {
      padding-left: 3rem;

      .boat-equipment-mileage-item-line-box {
        width: 2rem;
      }
    }
  }

  @include tablet {
    padding: 0.8rem;

    &.level-2 {
      .accordion-mileage {
        padding-left: 1rem;
      }
    }

    &.level-3 {
      .accordion-mileage {
        padding-left: 2rem;
      }
    }

    &.level-4 {
      .accordion-mileage {
        padding-left: 3rem;
      }
    }
  }
}

.accordion-mileage {
  position: relative;

  .accordion-header {
    display: flex;
    align-items: center;
    height: 100%;
  }

  .accordion__btn_box {
    display: block;
    margin-left: 1.2rem;
    transform: rotate(180deg);
  }

  &.active {
    .accordion__btn_box {
      transform: rotate(0);
    }
  }

  @include tablet {
    .accordion__btn_box {
      margin-left: 0;
    }
  }
}

.boat-equipment-mileage-item-line-box {
  display: flex;
  margin-right: 1.6rem;
}

.boat-equipment-mileage-item-line {
  width: 0.1rem;
  height: 100%;
  background-color: $clr-primary-and-accent;
  @include indent-not-last-of-type(0 0.8rem 0 0);
}

.boat-equipment-mileage-info {
  @include d-flex-space-between;
  align-items: center;
  width: 100%;
  font-size: $base-font-size;
  line-height: 1.6rem;

  &__brand {
    color: $seaz-text;
    margin-top: 0.8rem;
  }

  &__update {
    color: $profile-month;
    margin-top: 0.8rem;

    &--date {
      margin-left: 0.8rem;
    }
  }

  @include tablet {
    flex-direction: column;
    align-items: flex-start;

    &__title {
      margin-right: 1rem;
    }
  }
}

.boat-equipment-mileage-form {
  display: grid;
  grid-template-columns: 10.8rem 10.8rem 6rem;
  grid-gap: 0 1.2rem;
  align-items: end;
  margin-left: 1.2rem;
  padding-bottom: 1.2rem;

  @include tablet {
    grid-template-areas:
      'field-last-hours field-last-hours'
      'field-running-hours form-btn';
    grid-template-columns: 1fr 6rem;
    grid-gap: 1.2rem;
    width: 100%;
    margin: 0.4rem 0 0;
  }
}

@include tablet {
  .field-last-hours {
    grid-area: field-last-hours;
  }

  .field-running-hours {
    grid-area: field-running-hours;
  }

  .form-btn {
    grid-area: form-btn;
  }
}

.boat-equipment-mileage-form-field {
  position: relative;

  .form-field--content-outline {
    height: 4rem;
    border-radius: 0.4rem;
  }

  .form-field--label {
    font-size: 1.4rem;
    color: $seaz-text;
  }

  &__search {
    .form-field--label {
      color: $profile-month;
    }
  }

  .warning-alert {
    position: absolute;
    bottom: -3rem;
    left: 0;
    font-size: 1.2rem;
    line-height: 120%;
    color: $seaz-warning;
  }

  @include tablet {
    .warning-alert {
      bottom: -1.6rem;
    }
  }
}

.boat-equipment-mileage-form-btn {
  height: 4rem;
}
