.charters-table-wrapper {
  position: relative;
}

.charters-table {
  border-collapse: separate;

  .total {
    background-color: $clr-primary-and-accent;
    color: $light-color;
  }

  &__row {
    &--average {
      .charters-table__td {
        background-color: $clr-ashy-grey;

        &.total {
          background-color: $clr-primary-and-accent;
        }
      }
    }
  }

  &__td {
    font-size: 1.6rem;
    font-weight: 500;
    line-height: 1.9rem;
    padding: 1.6rem 2.4rem;
    color: $seaz-text;
    background-color: $light-color;

    &--header {
      font-size: 2rem;
      font-weight: 500;
      line-height: 120%;
      color: $clr-primary-and-accent;
      background-color: $seaz-bg;

      &:first-of-type {
        border-radius: 1.6rem 0 0 0;
      }

      &:last-of-type {
        border-radius: 0 1.6rem 0 0;
      }

      &.total {
        border-radius: 1.6rem 1.6rem 0 0;
      }
    }
  }

  .table__body {
    tr:last-of-type {
      td.total {
        border-radius: 0 0 1.6rem 1.6rem;
      }
    }

    .charters-table__td {
      border-top: 1px solid $seaz-head-arrow;
    }
  }

  .gross-val {
    color: $seaz-document-count;
  }

  @include desktop {
    .gross-val {
      display: block;
    }
  }

  @include desktop {
    padding: 6rem 0 1rem;

    .table__head,
    .table__body {
      .charters-table__td:first-of-type {
        position: sticky;
        left: 0;
        z-index: 100;
        width: 25.8rem;
        min-width: 25.8rem;
      }
      .charters-table__td:nth-of-type(2) {
        position: sticky;
        left: 25.8rem;
        z-index: 100;
        width: 19rem;
        min-width: 19rem;
      }
    }

    &__td {
      min-width: 20rem;
    }
  }

  @include tablet {
    .table__head,
    .table__body {
      .charters-table__td:first-of-type {
        position: static;
      }
      .charters-table__td:nth-of-type(2) {
        position: static;
      }
    }
  }
}
