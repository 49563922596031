.alert-info {
  width: 98%;
  max-width: 126.4rem;
  margin: 8.8rem auto;
  padding: 6.4rem;
  border: 0.05rem solid $seaz-alert-info-bg;
  box-sizing: border-box;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.04);
  border-radius: 1.6rem;
  background-color: $light-color;

  &__title {
    font-size: 3rem;
    font-weight: 500;
    line-height: 1.4;
    color: $seaz-text;
  }

  &__text {
    font-size: 1.6rem;
    color: $seaz-about-text;
    margin-top: 2.6rem;
  }

  &__btn-box {
    margin-top: 5rem;
  }

  &__btn {
    font-size: 1.6rem;
    font-weight: 500;
    letter-spacing: 0.1em;
    text-transform: uppercase;
    line-height: 2.8rem;
    padding: 1rem 2.4rem;
    border-radius: 0.4rem;
    transition: opacity 0.3s;

    &:hover {
      opacity: 0.6;
    }

    &--outline {
      color: $clr-primary-and-accent;
      background-color: $light-color;
      border: 1px solid $clr-primary-and-accent;
      margin-right: 1.2rem;
    }

    &--primary {
      color: $light-color;
      background-color: $clr-primary-and-accent;
    }
  }

  @include mobile {
    padding: 3rem;

    &__title {
      font-size: 2.4rem;
      text-align: center;
    }

    &__text {
      font-size: 1.6rem;
      text-align: center;
    }

    &__btn {
      display: block;
      width: 100%;
      font-size: 1.2rem;
      padding: 0.8rem 1.4rem;

      &--outline {
        margin: 0 0 1rem 0;
      }
    }
  }
}
