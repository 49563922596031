.tooltip-pane {
  &.right {
    .tooltip {
      &::before {
        content: '';
        border: 6px solid transparent;
        border-top: 6px solid $clr-white;
        position: absolute;
        top: 50%;
        left: -12px;
        transform: translateY(-50%) rotate(90deg);
      }
    }
  }

  &.top {
    .tooltip {
      &::before {
        content: '';
        border: 6px solid transparent;
        border-top: 6px solid $clr-white;
        position: absolute;
        left: 50%;
        transform: translateX(-50%);
        bottom: -12px;
      }
    }
  }

  &.bottom {
    .tooltip {
      &::before {
        content: '';
        border: 6px solid transparent;
        border-top: 6px solid $clr-white;
        position: absolute;
        top: -12px;
        left: 50%;
        transform: translateX(-50%) rotate(180deg);
      }
    }
  }
  &.left {
    .tooltip {
      &::before {
        content: '';
        border: 6px solid transparent;
        border-top: 6px solid white;
        position: absolute;
        top: 50%;
        right: -12px;
        transform: translateY(-50%) rotate(-90deg);
      }
    }
  }
}

.tooltip {
  background-color: #fff;
  padding: 1rem;
  &__text {
    color: $clr-main;
    font: 1.4rem/1.6rem $base-font;
  }
}

.shortcuts__tooltip.tooltip-pane {
  box-shadow: (0px 0px 12px rgba(53, 31, 115, 0.22));
  .tooltip {
    padding: 1.2rem;
    border-radius: 4px;
    position: relative;
    display: inline-block;
    box-sizing: content-box;
    .tooltip__text {
      color: #4f4f4f;
      letter-spacing: 0.01em;
      font-weight: 500;
    }
  }
}
