app-boat-maintenance-equipment-list {
  display: block;
  width: 100%;
}

.maintenance-card-list {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(33rem, 1fr));
  grid-gap: 1.6rem 0;

  .boat-maintenance-dashboard-systems-content {
    border-top: 1px solid $seaz-head-arrow;
    padding-top: 1.6rem;
    margin-top: 1.6rem;
  }

  @media screen and (min-width: 480px) {
    grid-gap: 2.4rem;
  }
}

.maintenance-card {
  @include d-align-items-center;
  height: 100%;
  overflow: hidden;

  @include card-layout(1px solid $seaz-head-arrow, 0px 2px 2px rgba(43, 28, 54, 0.16), 1.6rem, $light-color, 1.2rem);

  &__box {
    width: 100%;
  }

  &__container {
    @include d-align-items-center;
  }

  &__content {
    flex-grow: 1;
    margin-left: 1.2rem;
    font-size: $base-font-size;
  }

  @media screen and (min-width: 768px) {
    padding: 2.4rem;
  }
}

.maintenance-card-photo-box {
  position: relative;
  width: 9.2rem;
  min-width: 9.2rem;
  height: 9.2rem;
}

.maintenance-card-photo {
  display: block;
  width: 100%;
  height: 100%;
  border-radius: 1.2rem;
  object-fit: cover;
}

.maintenance-card-photo-label-box {
  @include d-flex-center-content;
  position: absolute;
  right: -0.6rem;
  bottom: -0.6rem;
  width: 3.2rem;
  height: 3.2rem;
  border-radius: 50%;
}

.maintenance-card-photo-label {
  display: block;
}

.maintenance-card-breadcrumbs {
  margin-bottom: 0.4rem;

  &__text {
    font-weight: 500;
    line-height: 1.4rem;
    color: $profile-month;
  }
}

.maintenance-card-name {
  @include d-flex-space-between;
  font-size: 2rem;
  font-weight: 500;
  line-height: 120%;
  word-break: break-word;
  color: $seaz-primary-and-accent;
  margin-bottom: 0.4rem;

  &__text {
    width: 100%;
    max-width: 28.2rem;
    word-break: break-word;
  }
}

.maintenance-card-brand {
  word-break: break-word;
  line-height: 1.6rem;
  color: $seaz-text;
  margin-bottom: 0.4rem;
}

.maintenance-card-schedule {
  @include d-align-items-center;
  @include indent-not-last-of-child(0 0 0.4rem 0);

  &__title {
    line-height: 1.6rem;
    color: $profile-month;
    margin-right: 0.8rem;
  }

  &__val {
    word-break: break-word;
    line-height: 1.6rem;
  }
}

.maintenance-card-actions {
  @include d-align-items-center;
  margin-left: 1rem;

  &__btn {
    @include indent-not-last-of-type(0 1rem 0 0);
  }

  @include tablet {
    margin-left: 0.5rem;

    &__btn {
      @include indent-not-last-of-type(0 0.8rem 0 0);
    }
  }
}

.systems-level-lines {
  @include d-align-items-center;
  height: 100%;
  margin-right: 1.2rem;
}

.systems-level-line {
  width: 0.1rem;
  height: 100%;
  background-color: $clr-primary-and-accent;

  @include indent-not-last-of-type(0 0.8rem 0 0);
}
