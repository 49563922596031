.charter-available-days {
  &__title {
    font-size: 3rem;
    font-weight: 500;
    color: $clr-primary-and-accent;
    line-height: 1.4;
    text-transform: uppercase;
    margin-bottom: 2.4rem;
  }

  &__header {
    display: flex;
    justify-content: space-between;
    align-items: flex-end;

    &--row {
      display: flex;
      justify-content: space-between;
      align-items: flex-end;
      width: 100%;

      &:first-of-type {
        margin-right: 2rem;
      }
    }
  }

  &__tabs-wrap {
    display: flex;
  }

  &__tabs-title {
    font-size: 1.4rem;
    line-height: 2rem;
    margin-bottom: 0.2rem;
  }

  &__tabs-button {
    border-radius: 0.7rem;
    display: block;
    padding: 1rem 3.1rem;
    cursor: pointer;
  }

  &__btn-apply {
    font-size: 1.4rem;
    font-weight: 500;
    line-height: 2rem;
    letter-spacing: 0.1em;
    text-transform: uppercase;
    padding: 1rem 2rem;
  }

  @media screen and (max-width: 1300px) {
    &__header {
      flex-direction: column;

      &--row {
        &:first-of-type {
          margin: 0 0 2rem 0;
        }

        &:last-of-type {
          justify-content: flex-start;
        }
      }
    }

    &__tabs-container,
    &__available-days {
      margin-right: 2rem;
    }
  }

  @include tablet {
    &__header {
      &--row {
        &:last-of-type {
          flex-wrap: wrap;
        }
      }
    }

    &__btn-apply {
      width: 100%;
      margin-top: 2rem;
      justify-content: center;
    }
  }

  @include tablet-small {
    &__header {
      &--row {
        app-duration-mode-control {
          width: 100%;
        }
      }
    }
  }

  @media screen and (max-width: 485px) {
    &__available-days {
      margin-top: 2rem;
    }
  }
}

.charter-available-days-list {
  display: block;
  margin-top: 4.8rem;

  &__header {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  &__title {
    font-size: 2.4rem;
    font-weight: 500;
    color: $clr-primary-and-accent;
    line-height: 1.4;
  }

  &__btn-download {
    background-color: $light-color;
  }

  &__list-container {
    background-color: $light-color;
    border-radius: 1.6rem;
    margin-top: 2.2rem;
    padding: 1.6rem 3rem;
  }

  &__list {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 1.4rem 0;

    &:not(:last-of-type) {
      border-bottom: 1px solid $seaz-head-arrow;
    }
  }

  &__item {
    width: 100%;
    max-width: 39.1rem;
    font-weight: 500;

    &:nth-of-type(2) {
      text-align: center;
    }

    &:last-of-type {
      text-align: end;
      max-width: 24rem;
    }

    &--title {
      font-size: 2rem;
      color: $seaz-border;
      margin-right: 1.6rem;
    }

    &--title-total {
      display: none;
    }

    &--val {
      font-size: 2.4rem;
      color: $seaz-text;
    }

    &--dash {
      width: 0.1rem;
      height: 3.2rem;
      background-color: $seaz-head-arrow;
    }
  }

  @include laptop {
    &__item {
      &:last-of-type {
        max-width: 18rem;
      }
    }
  }

  @include tablet {
    &__list {
      flex-direction: column;
    }

    &__item {
      display: flex;
      justify-content: space-between;
      align-items: center;
      max-width: 100%;

      &:not(:last-of-type) {
        margin-bottom: 1.6rem;
      }

      &:nth-of-type(2) {
        text-align: start;
      }

      &:last-of-type {
        text-align: start;
        max-width: 100%;
      }

      &--title {
        flex-shrink: 0;
        display: block;
        width: 6rem;
        text-align: end;
      }

      &--val {
        display: block;
        width: 60%;
        text-align: start;
      }

      &--title-total {
        display: inline;
      }

      &--dash {
        display: none;
      }
    }

    &__icon {
      display: none !important;
    }
  }

  @include mobile {
    &__item {
      &--val {
        width: 70%;
      }
    }
  }
}
